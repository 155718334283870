import { SimulateInsuranceClick } from '../../../../domain/models'
import { Dispatcher, Service } from '../../../protocols'

export type ISimulateInsuranceClickService = Service<SimulateInsuranceClick>

/**
 * Simulate insurance click
 *
 * @category Home
 */
export class SimulateInsuranceClickService
  implements ISimulateInsuranceClickService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   * @param {SimulateInsuranceClick} data
   */
  execute(data: SimulateInsuranceClick): void {
    this.dispatcher.execute('Area logada web - Clique em simular agora', data)
  }
}
