import router, { useRouter } from 'next/router'

export const useQuery = () => {
  const { pathname, query } = useRouter()

  const getQueryParam = (key: string): string => {
    return query?.[key]?.toString() || ''
  }

  const removeQueryParam = (key: string) => {
    delete query[key]
    router.replace({ pathname, query }, undefined, {
      shallow: true,
    })
  }

  return {
    getQueryParam,
    removeQueryParam,
  }
}
