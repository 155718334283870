import { SWRService } from '@azos/core'
import useSWR, { SWRConfiguration } from 'swr'
import { MutatorCallback } from 'swr/dist/types'

interface IApiResponse<T> {
  data: T | undefined
  error?: any
  mutate: (
    data?: T | Promise<T> | MutatorCallback<T> | undefined,
    shouldRevalidate?: boolean | undefined,
  ) => Promise<T | undefined>
}

export const useFetch = <T>(
  service: SWRService,
  options?: SWRConfiguration,
): IApiResponse<T> => {
  const { data, error, mutate } = useSWR<T>(
    service.url,
    (_url: string) => service.execute(),
    options,
  )

  return { data, error, mutate }
}
