import React from 'react'

import { PaymentMethod } from '@azos/core'
import { EmptyState, Spinner, useMediaQuery } from '@azos/shared'
import { BillingServiceSource } from '@domain/models'
import Alert from '@presentation/components/atoms/Alert'
import { useFetchPolicyDetails } from '@presentation/hooks/useFetchPolictDetails'
import { useBanners } from '@presentation/providers/BannersProvider'
import { usePayment } from '@presentation/providers/PaymentProvider'

import { InfoCard } from '../../ImportantInfos/components'
import { PaymentHistory } from '../../Payments'
import { CardPolicyCreditCard } from '../PolicyEditPayment/components'
import { PolicyPaymentDetails } from '../PolicyPaymentDetails'
import { PolicyPaymentProps } from './PolicyPayment.props'
import { Root } from './PolicyPayment.styles'

const PolicyPayment: React.VFC<PolicyPaymentProps> = ({ policyId }) => {
  const {
    data: policyDetails,
    isLoading,
    isError,
  } = useFetchPolicyDetails(policyId)

  const { isPaymentChangeActive } = usePayment()
  const { isMobile } = useMediaQuery()
  const { changePaymentBanner } = useBanners()

  const [modalChangePayment, setModalChangePayment] = React.useState(false)
  const [modalCreditCard, setModalCreditCard] = React.useState(false)

  const hasInvoices = React.useMemo(
    () => policyDetails?.invoices && policyDetails?.invoices.length > 0,
    [policyDetails?.invoices],
  )

  const isPolicyError = React.useMemo(
    () => isError && !hasInvoices,
    [isError, hasInvoices],
  )

  const isCreditCard = React.useMemo(
    () => policyDetails?.payments?.paymentMethod === PaymentMethod.CREDIT_CARD,
    [policyDetails?.payments],
  )

  const canShowPaymentChange = React.useMemo(
    () =>
      policyDetails?.policy.status[0].type === 'success' &&
      !isCreditCard &&
      isPaymentChangeActive,
    [isCreditCard, isPaymentChangeActive, policyDetails?.policy.status],
  )

  return (
    <Root
      $hasPaymentMethod
      $isLoadingOrError={isPolicyError || isLoading}
      $background={isMobile}
    >
      {isError && (
        <div className="empty-state-wrapper">
          <EmptyState
            align="center"
            icon="icon-policy-cancel"
            iconColor="error"
            title="Erro ao carregar pagamento"
          >
            Por favor, tente novamente ou entre em contato com nosso suporte.
          </EmptyState>
        </div>
      )}

      {isLoading && (
        <div className="empty-state-wrapper">
          <EmptyState
            align="center"
            icon={<Spinner />}
            title="Carregando pagamento"
          >
            Estamos carregando as informações de pagamento.
          </EmptyState>
        </div>
      )}

      {!isError && !isLoading && policyDetails && (
        <>
          {policyDetails?.policy.status[0].type !== 'success' && (
            <div className="alert-wrapper">
              <Alert>
                Existe uma fatura aberta. Só é possível alterar o pagamento após
                o pagamento da fatura.
              </Alert>
            </div>
          )}

          {canShowPaymentChange && changePaymentBanner && (
            <InfoCard
              item={changePaymentBanner}
              onClick={() => setModalCreditCard(true)}
            />
          )}

          <PolicyPaymentDetails
            billingValue={policyDetails.billing.value}
            billingDueDate={policyDetails.billing.dueDate}
            billingDueDateFrequency={policyDetails.billing.dueDateFrequency}
            payment={policyDetails.payments}
            policyId={policyId}
            proposalId={policyDetails.policy.proposalId}
            status={policyDetails.policy.status[0]}
            modalChangePayment={modalChangePayment}
            setModalChangePayment={setModalChangePayment}
            billingServiceSource={policyDetails.policy.billingServiceSource}
            invoices={policyDetails.invoices}
            paymentFrequency={policyDetails.billing.frequency}
          />

          {hasInvoices ? (
            <div className="history">
              <div className="header">
                <h2>Histórico de pagamento</h2>
                <p>Gerencie as faturas de suas apólices</p>
              </div>

              <PaymentHistory invoices={policyDetails.invoices} />
            </div>
          ) : (
            <div className="empty-state-wrapper">
              <EmptyState
                align="center"
                icon="icon-policy-cancel"
                iconColor="error"
                title="Erro ao carregar histórico de pagamento"
              >
                Por favor, tente novamente ou entre em contato com nosso
                suporte.
              </EmptyState>
            </div>
          )}

          <CardPolicyCreditCard
            open={modalCreditCard}
            onClose={() => setModalCreditCard(false)}
            billingServiceSource={policyDetails.policy.billingServiceSource}
            id={policyId}
          />
        </>
      )}
    </Root>
  )
}

export default PolicyPayment
