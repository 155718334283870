import { ClickPoliciesCards } from '../../../../../domain/models'
import { Dispatcher, Service } from '../../../../protocols'

export type IClickPoliciesCardsService = Service<ClickPoliciesCards>

/**
 * Click Policies Cards
 *
 * @category Policies
 */
export class ClickPoliciesCardsService implements IClickPoliciesCardsService {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   * @param {ClickPoliciesCards} data
   */
  execute(data: ClickPoliciesCards): void {
    this.dispatcher.execute(
      'Area logada web - Clique nos cards de apólices ou propostas',
      data,
    )
  }
}
