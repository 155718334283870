import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled('div')(
  ({ theme }) => css`
    padding: ${theme.spacing[4]}px ${theme.spacing[3]}px;
    background: ${theme.palette.neutral[50]};
    margin-bottom: 16px;

    .waiting-approval-card {
      &__header {
        cursor: pointer;
        display: flex;
        align-items: center;

        & > * + * {
          margin-left: 16px;
        }

        span {
          color: ${theme.palette.neutral[400]};
        }
      }

      &__background-icon {
        background: #fef6da;
        min-height: 48px;
        min-width: 48px;
        border-radius: 50%;
        display: grid;
        place-items: center;

        i {
          font-size: 1.5rem;
          color: #f9bf09;
        }
      }
      &__info {
        display: flex;
        flex-direction: column;
      }
    }
  `,
)
