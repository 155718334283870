const utms = {
  general: {
    utm_source: 'referral',
    utm_medium: 'menu',
  },
  guardians: {
    utm_source: 'referral',
    utm_medium: 'area-log-guard',
  },
  store: {
    utm_source: 'azos',
    utm_medium: 'mobile',
    utm_campaign: 'aquisicao_download',
  },
}

export default utms
