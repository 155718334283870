import { css, styled } from '@azos/shared'

export const Root = styled('div')(
  ({ theme }) => css`
    background: ${theme.palette.neutral[50]};
    width: 100%;

    .onboarding-topic {
      &__header {
        padding: ${theme.spacing[3]}px 12px;
        border-bottom: 1px solid ${theme.palette.neutral[100]};

        display: flex;

        &--icon {
          border-radius: 56px;
          background: ${theme.palette.neutral[100]};
          width: 40px;
          height: 40px;
          display: grid;
          place-items: center;

          i {
            font-size: 1.5rem;
          }
        }

        &--content {
          margin-left: 12px;
          display: flex;
          flex-direction: column;
          flex: 1;

          * + * {
            margin-top: ${theme.spacing[2]}px;
          }
        }

        &--title {
          font-weight: ${theme.typography.fontWeightBold};
        }
      }

      &__instructions-list {
        padding: 18px;
        border-bottom: 1px solid ${theme.palette.neutral[100]};
      }

      &__actions-list {
        padding: 12px ${theme.spacing[3]}px;

        &--text {
          display: block;
          font-weight: ${theme.typography.fontWeightBold};
          margin-bottom: ${theme.spacing[2]}px;
        }
      }
    }
  `,
)
