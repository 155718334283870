import React from 'react'

import DatePicker from '@mui/lab/DesktopDatePicker'
import dayjs from 'dayjs'
import parseFormat from 'dayjs/plugin/customParseFormat'

import { Icon } from '../../../icons'
import { theme } from '../../../styles'
import { Input } from '../Input'
import { InputDateProps } from './InputDate.props'

dayjs.extend(parseFormat)

const InputDate: React.FCC<InputDateProps> = ({
  inputProps,
  name,
  onBlur,
  onChange,
  parentName,
  ...rest
}) => {
  const handleChange = React.useCallback(
    (value: any, inputText?: string) => {
      const data = dayjs(value)
      const dateValue = data.isValid() ? data.toDate() : null
      onChange(dateValue, inputText)
    },
    [onChange],
  )

  const handleBlur = React.useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      const value = event.target.value
      const data = dayjs(value, 'DD/MM/YYYY', true)
      onChange(data.toDate(), value)
      onBlur?.(event)
    },
    [onBlur, onChange],
  )

  return (
    <DatePicker
      ignoreInvalidInputs
      openTo="day"
      views={['day', 'year']}
      {...rest}
      toolbarTitle="Selecione"
      components={{
        OpenPickerIcon: () => (
          <Icon.Calendar
            style={{
              color: theme.palette.grey[300],
              fontSize: '1.38rem',
            }}
          />
        ),
      }}
      onChange={handleChange}
      renderInput={params => (
        <>
          <Input
            {...params}
            {...inputProps}
            name={name}
            inputProps={{
              ...params.inputProps,
              placeholder: 'dd/mm/aaaa',
            }}
            parentName={parentName}
            onBlur={handleBlur}
          />
        </>
      )}
    />
  )
}

export default InputDate
