import { Mapper } from '../../../../data/protocols'
import { LoadQuestionnaire } from '../../../../domain/usecases'
import { SWRServices } from '../../../protocols/service'

export type ILoadQuestionnaireService = SWRServices<
  void,
  LoadQuestionnaire.Response
>

export class LoadQuestionnaireService implements ILoadQuestionnaireService {
  constructor(
    private readonly dpsRepository: LoadQuestionnaire,
    private readonly responseMapper: Mapper,
  ) {}

  getUrl() {
    return '/dps_questionnaire'
  }

  async execute(): Promise<LoadQuestionnaire.Response> {
    const response = await this.dpsRepository.loadQuestionnaire()

    return this.responseMapper.map(response)
  }
}
