import React from 'react'

import { makeStorageProvider } from '../main'
import { Utils } from '../utils'

export type UTMData = {
  [key: string]: string | string[] | null
}

type GetUTMProps = {
  qs: UTMData
}

const storageProvider = makeStorageProvider({}, { crossDomain: true })

export const useUTM = ({ qs }: GetUTMProps): void => {
  React.useEffect(() => {
    const entries = Object.entries(qs)

    const utm: UTMData = entries
      .filter(([key]) => key.startsWith('utm'))
      .reduce((acc, [key, value]) => {
        return Object.assign(acc, {
          [key]: Utils.sanitizer.formatUTM(value),
        })
      }, {})

    const expires = new Date(Date.now())
    expires.setDate(expires.getDate() + 30)

    if (Object.entries(utm).length) {
      const savedUTM = storageProvider.getJSON('utm')

      utm.utm_source_first = savedUTM?.utm_source_first || utm.utm_source
      utm.utm_medium_first = savedUTM?.utm_medium_first || utm.utm_medium
      utm.utm_campaign_first = savedUTM?.utm_campaign_first || utm.utm_campaign

      storageProvider.set('utm', utm, { expires: expires })
    }

    // eslint-disable-next-line
  }, [])
}
