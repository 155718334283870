import { GuardianKinship } from '@data/models'

export const initialValues = {
  name: '',
  email: '',
  phone: '',
  kinship: '',
  hasAddress: false,
  zipCode: '',
  state: '',
  city: '',
  street: '',
  number: '',
  complement: '',
  neighborhood: '',
}

export const guardianKinshipOptions = [
  { value: 'Irmã(o)', label: 'Irmã(o)' },
  { value: 'Amiga(o)', label: 'Amiga(o)' },
  { value: 'Prima(o)', label: 'Prima(o)' },
  { value: 'Pais', label: 'Pais' },
  { value: 'Outros familiares', label: 'Outros familiares' },
  { value: 'Cônjuge', label: 'Cônjuge' },
  { value: 'Outros', label: 'Outros' },
]
