import React from 'react'

import { useNotification } from '@Azos-Seguros/apollo'
import analytics from '@azos/analytics'
import { ViewPersonalDataBottomSheetStatus } from '@azos/analytics/src/domain/models'
import {
  useNotify,
  CardCopyText,
  Modal,
  useMediaQuery,
  withModal,
} from '@azos/shared'
import { Button, ButtonSelect, Text } from '@azos/shared/src/components/v2'
import { azosEmail, azosPhone, formattedAzosPhone } from '@main/config/contact'

import { SensitiveDataModalProps } from './SensitiveDataModal.props'
import { Root } from './SensitiveDataModal.styles'

const SensitiveDataModal: React.FCC<SensitiveDataModalProps> = ({
  onClose,
  ...props
}) => {
  const { isMobile } = useMediaQuery()
  const { addNotification } = useNotification()

  React.useEffect(() => {
    analytics.dashboard.personalData.viewPersonalDataBottomSheet.execute({
      status: ViewPersonalDataBottomSheetStatus.SENSITIVE_DATA,
    })
  }, [])

  const onSuccessCopy = (copyType: string) =>
    addNotification({
      variant: 'success',
      description: `${copyType} copiado com sucesso`,
    })

  return (
    <Modal
      {...props}
      onClose={onClose}
      variant={isMobile ? 'bottomDrawer' : 'modal'}
      maxWidth={isMobile ? undefined : '480px'}
      showCloseButton={false}
      showHeader={false}
    >
      <Root>
        <div className="sensitive-data-modal__title">
          <Text
            className="sensitive-data-modal__title-content"
            variant="bodyBold"
          >
            Dados sensíveis
          </Text>
          {isMobile && <i className="icon-exit" onClick={onClose} />}
        </div>
        <Text variant="body2" className="sensitive-data-modal__description">
          Esses dados são sensíveis e não podem ser alterados, pois afetam na
          precificação da sua apólice.
          <br />
          <br />
          Para alterá-los, entre em contato com a Central de Atendimento da
          Azos:
        </Text>

        <div className="sensitive-data-modal__content">
          <div className="sensitive-data-modal__copy-content">
            <CardCopyText
              startIcon="icon-dial-phone"
              value={azosPhone}
              customLabel={formattedAzosPhone}
              className="sensitive-data-modal__card-copy"
              shouldShowSuccessLabel={false}
              onClick={() => onSuccessCopy('Telefone')}
            />
            <CardCopyText
              startIcon="icon-email"
              value={azosEmail}
              className="sensitive-data-modal__card-copy"
              shouldShowSuccessLabel={false}
              onClick={() => onSuccessCopy('E-mail')}
            />
          </div>
          <ButtonSelect
            title="Conversar no whatsapp"
            startIcon={<i className="icon-whatsapp" />}
            className="sensitive-data-modal__button-select"
            onClick={() =>
              window.open(`https://api.whatsapp.com/send?phone=${azosPhone}`)
            }
          />
        </div>

        <Button
          onClick={onClose}
          fullWidth
          className="sensitive-data-modal__button"
        >
          Fechar
        </Button>
      </Root>
    </Modal>
  )
}

export default withModal<SensitiveDataModalProps>(SensitiveDataModal)
