import { Services } from '@azos/core'
import { IPoliciesRepository } from '@data/repositories'
import { LoadPoliciesUseCase } from '@domain/usecases'

type Params = LoadPoliciesUseCase.Params
type Response = LoadPoliciesUseCase.Response

export type ILoadPoliciesService = Services<Params, Response>

export class LoadPoliciesService implements ILoadPoliciesService {
  constructor(private readonly policiesRepository: IPoliciesRepository) {}

  async execute(params: Params): Promise<Response> {
    return await this.policiesRepository.loadPoliciesAndProposals(params)
  }
}
