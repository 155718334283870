import React from 'react'

import {
  CssBaseline,
  ThemeProvider as MuiThemeProvider,
  GlobalStyles,
} from '@mui/material'

import { theme, css } from '../../styles'
import { MaterialProvider } from '../MaterialProvider'
import { ThemeProviderProps } from './ThemeProvider.props'

const ThemeProvider = ({ children }: ThemeProviderProps) => {
  return (
    <MuiThemeProvider theme={theme}>
      <MaterialProvider>{children}</MaterialProvider>
      <CssBaseline />
      <GlobalStyles
        styles={css`
          body {
            font-family: 'Dazzed', sans-serif;
          }
          body [class^='icon-'] {
            font-size: 1.25rem;
          }
        `}
      />
    </MuiThemeProvider>
  )
}

export type { ThemeProviderProps }

export default ThemeProvider
