import { Analytics } from '../data/protocols'
import { env } from './config/env'
import {
  makeActionsCancellationValidationService,
  makeActionsFeedbackModalService,
  makeActionsReasonCancellationService,
  makeActionsReduceValueService,
  makeAddBeneficiaryModalService,
  makeAddToCartService,
  makeBenefitsBannerService,
  makeBenefitsCodeAccessService,
  makeBenefitsCodeService,
  makeBenefitsPageDetailsService,
  makeBenefitsPageViewService,
  makeCalculatorShareService,
  makeCallpageActivateService,
  makeCancelPolicyService,
  makeChangePaymentModalDetailsService,
  makeClearSaleResponseTimeService,
  makeClickBrokerShare,
  makeClickButtonPolicyCancelService,
  makeClickCheckPoliciesReadjustmentService,
  makeClickConfirmUpdateChangesService,
  makeClickContactBrokerHelpService,
  makeClickContactHelpService,
  makeClickCoverageDetailsButtonsService,
  makeClickCoverageDetailsService,
  makeClickHelpReadjustmentDetailsService,
  makeClickImportantInfoBannerService,
  makeClickInsertDueDateService,
  makeClickPoliciesCardsService,
  makeClickPoliciesTabsService,
  makeClickPolicyCardService,
  makeClickReadjustmentBadgeService,
  makeClickReadjustmentDetailsService,
  makeClickRedirectReadjustmentDetailsService,
  makeClickRequestBrokerGuardianService,
  makeClickRequestHelpGuardianRoleResponsabilityService,
  makeClickTalkAzosCancellationService,
  makeClickUpdateChangesService,
  makeCustomService,
  makeDownloadGeneralConditionsService,
  makeDownloadPolicyService,
  makeDownloadProposalService,
  makeDPSIllnessSevice,
  makeDPSSelectionService,
  makeDPSSubmitService,
  makeEditBeneficiaryModalService,
  makeExitPolicyCancelService,
  makeFinishCashbackWithCreditCardService,
  makeGobackSharePageService,
  makeGuardianHomepageService,
  makeHomeFastQuotationService,
  makeInteractBrokerContactCardService,
  makeLoginService,
  makeMixpanelDispatcher,
  makeMixpanelLogout,
  makePolicyCardHomepageService,
  makePolicyExpirationDateService,
  makePolicyPaymentHomepageService,
  makePolicyScreenViewService,
  makeProposalAcceptedService,
  makeProposalChangedService,
  makeProposalDeniedService,
  makeProposalRefusedService,
  makePurchaseService,
  makeQuotationAllowReceiveByEmailService,
  makeQuotationDeclineReceiveByEmailService,
  makeReferralCopyCode,
  makeReferralPageView,
  makeReferralShareCode,
  makeRegistrationService,
  makeRemoveFromCartService,
  makeSaveBeneficiaryChangesService,
  makeSaveDueDateChangeService,
  makeSaveEditedBeneficiaryModalService,
  makeSaveEditGuardianService,
  makeSaveGuardianService,
  makeSaveNewBeneficiaryModalService,
  makeSaveNewCreditCardService,
  makeSaveProposalPdfService,
  makeSelectCreditCardCashbackService,
  makeSelectDueDateService,
  makeSelectPaymentFrequencyService,
  makeSelectPixCashbackService,
  makeSelectPolicyCancelReasonService,
  makeSideMenuHomepageService,
  makeSimulateInsuranceClickService,
  makeSimulationAnswerService,
  makeSimulationEditCoverageService,
  makeSimulationIncomeService,
  makeSimulationLeadService,
  makeSimulationSubmitService,
  makeSimulationSummaryService,
  makeStartCashbackWithCreditcardService,
  makeStartSimulationService,
  makeUpsellAddCoverageProductService,
  makeUpsellChangeCoverageRangeService,
  makeUpsellClearSaleResponseTimeService,
  makeUpsellClickCoverageDetailsService,
  makeUpsellClickCoverageValueInputService,
  makeUpsellClickDetailsAddCoverageService,
  makeUpsellClickDetailsCoverageService,
  makeUpsellClickGeneralConditionsLinkService,
  makeUpsellClickHelpCoverageService,
  makeUpsellClickInsertDueDateService,
  makeUpsellClickOtherDiseasesService,
  makeUpsellClickPaymentTypeService,
  makeUpsellClickSubmitCalculatorService,
  makeUpsellCloseDetailsModalService,
  makeUpsellContinueDiseasesQuestionService,
  makeUpsellContinueGeneralConditionsQuestionService,
  makeUpsellContinueLifeStyleQuestionService,
  makeUpsellContinueOtherDiseasesQuestionService,
  makeUpsellContinueRevisionService,
  makeUpsellContinueSimulationPageProfessionSalaryService,
  makeUpsellContinueSimulationPageSmokerIMCService,
  makeUpsellCopyShareContentService,
  makeUpsellDurationTimeService,
  makeUpsellExpandCheckoutDetailsService,
  makeUpsellExpandCoverageDetailsService,
  makeUpsellFinishCreditcardPaymentService,
  makeUpsellFinishPixPaymentService,
  makeUpsellGoBackCalculatorPage,
  makeUpsellGoBackDetailsCoverageService,
  makeUpsellGoBackService,
  makeUpsellGobackSimulationPageProfessionSalary,
  makeUpsellGobackSimulationPageSmokerIMC,
  makeUpsellLoadingPixPaymentService,
  makeUpsellRemoveCoverageProductService,
  makeUpsellSaveDueDateChangeService,
  makeUpsellSelectContractTypeService,
  makeUpsellSelectDueDateService,
  makeUpsellSendReferralFormService,
  makeUpsellSharePageService,
  makeUpsellViewCalculatorPage,
  makeUpsellViewCheckoutPage,
  makeUpsellViewDiseasesContentService,
  makeUpsellViewDPSResumeService,
  makeUpsellViewDueDateSelectDrawerService,
  makeUpsellViewGeneralConditionsContentService,
  makeUpsellViewLifeStyleContentService,
  makeUpsellViewOtherDiseasesContentService,
  makeUpsellViewSelectPageService,
  makeUpsellViewSimulationPageProfessionSalary,
  makeUpsellViewSimulationPageSmokerIMC,
  makeViewAboutAzosService,
  makeViewAddCreditCardModalService,
  makeViewAddGuardianService,
  makeViewAppDownloadModal,
  makeViewAzosContactService,
  makeViewBannerChangePaymentService,
  makeViewBrokerContactCardService,
  makeViewCancellationValidationService,
  makeViewCashbackModal,
  makeViewCoverageDetailsService,
  makeViewCoverageQuestionsService,
  makeViewDueDateSelectDrawerService,
  makeViewEditGuardianService,
  makeViewEditPersonalDataService,
  makeViewExperimentService,
  makeViewFeedbackModalService,
  makeViewGuardianHomepageService,
  makeViewGuardianInstructionsService,
  makeViewGuardianRoleResponsabilityService,
  makeViewHomepageService,
  makeViewImportantInfoBannerService,
  makeViewOnboardingHomepageService,
  makeViewOnboardingPageService,
  makeViewOnboaringBeneficiaryContentService,
  makeViewOnboaringGuardianContentService,
  makeViewOnboaringPolicyContentService,
  makeViewPaymentDetailsModalService,
  makeViewPersonalDataBottomSheetService,
  makeViewPersonalDataService,
  makeViewPoliciesDetailsService,
  makeViewPolicyCancelReasonService,
  makeViewPolicyCancelService,
  makeViewPolicyReadjustmentDetailsService,
  makeViewPolicyReadjustmentModalService,
  makeViewProductCoverageDetailsService,
  makeViewProtectedListHelpService,
  makeViewProtectedService,
  makeViewReasonCancellationService,
  makeViewReduceValueService,
  makeViewRequestBrokerProtectedHelp,
  makeViewRequestProtectedHelp,
  makeViewResetPasswordService,
} from './factories'

const setupAnalytics = (): Analytics => {
  if (env.isDev) {
    console.log('Analytics started!')
  }

  return {
    initializeLoggedUser: makeMixpanelDispatcher,
    logout: makeMixpanelLogout,
    account: {
      login: makeLoginService(),
      registration: makeRegistrationService(),
    },
    calculator: {
      share: makeCalculatorShareService(),
    },
    callpage: {
      activate: makeCallpageActivateService(),
    },
    cart: {
      add: makeAddToCartService(),
      remove: makeRemoveFromCartService(),
    },
    checkout: {
      clickInsertDueDate: makeClickInsertDueDateService(),
      finishCashbackWithCreditcard: makeFinishCashbackWithCreditCardService(),
      purchase: makePurchaseService(),
      saveDueDateChange: makeSaveDueDateChangeService(),
      saveProposalPdf: makeSaveProposalPdfService(),
      selectDueDate: makeSelectDueDateService(),
      startCashbackWithCreditcard: makeStartCashbackWithCreditcardService(),
      viewDueDateSelectDrawer: makeViewDueDateSelectDrawerService(),
      clearSaleResponseTime: makeClearSaleResponseTimeService(),
      selectPaymentFrequency: makeSelectPaymentFrequencyService(),
      viewCashbackModal: makeViewCashbackModal(),
      selectPixCashbackService: makeSelectPixCashbackService(),
      selectCreditCardCashbackService: makeSelectCreditCardCashbackService(),
    },
    dashboard: {
      addBeneficiaryModal: makeAddBeneficiaryModalService(),
      home: {
        simulateInsuranceClick: makeSimulateInsuranceClickService(),
        viewAboutAzos: makeViewAboutAzosService(),
        viewAzosContact: makeViewAzosContactService(),
        viewCoverageQuestions: makeViewCoverageQuestionsService(),
        viewHomepage: makeViewHomepageService(),
        viewProductCoverageDetails: makeViewProductCoverageDetailsService(),
      },
      banner: {
        viewImportantInfoBanner: makeViewImportantInfoBannerService(),
        clickImportantInfoBanner: makeClickImportantInfoBannerService(),
      },
      broker: {
        viewBrokerContactCard: makeViewBrokerContactCardService(),
        interactBrokerContactCard: makeInteractBrokerContactCardService(),
      },
      benefits: {
        code: {
          benefitsCode: makeBenefitsCodeService(),
          benefitsCodeAccess: makeBenefitsCodeAccessService(),
        },
        view: {
          benefitsPageView: makeBenefitsPageViewService(),
          benefitsPageDetails: makeBenefitsPageDetailsService(),
          benefitsBanner: makeBenefitsBannerService(),
        },
      },
      editBeneficiaryModal: makeEditBeneficiaryModalService(),
      policyCardHomepage: makePolicyCardHomepageService(),
      policyPaymentHomepage: makePolicyPaymentHomepageService(),
      onboarding: {
        viewOnboardingHomepage: makeViewOnboardingHomepageService(),
        viewOnboardingPage: makeViewOnboardingPageService(),
        viewOnboardingPolicyContent: makeViewOnboaringPolicyContentService(),
        viewOnboardingBeneficiaryContent:
          makeViewOnboaringBeneficiaryContentService(),
        viewOnboardingGuardianContent:
          makeViewOnboaringGuardianContentService(),
      },
      guardian: {
        clickContactBrokerHelp: makeClickContactBrokerHelpService(),
        clickContactHelp: makeClickContactHelpService(),
        clickRequestBrokerGuardian: makeClickRequestBrokerGuardianService(),
        clickRequestHelpGuardianRoleResponsability:
          makeClickRequestHelpGuardianRoleResponsabilityService(),
        guardianHomepage: makeGuardianHomepageService(),
        saveEditGuardian: makeSaveEditGuardianService(),
        saveGuardian: makeSaveGuardianService(),
        viewAddGuardian: makeViewAddGuardianService(),
        viewEditGuardian: makeViewEditGuardianService(),
        viewGuardianHomepage: makeViewGuardianHomepageService(),
        viewGuardianInstructions: makeViewGuardianInstructionsService(),
        viewGuardianRoleResponsability:
          makeViewGuardianRoleResponsabilityService(),
        viewProtectedListHelp: makeViewProtectedListHelpService(),
        viewProtected: makeViewProtectedService(),
        viewRequestBrokerProtectedHelp: makeViewRequestBrokerProtectedHelp(),
        viewRequestProtectedHelp: makeViewRequestProtectedHelp(),
      },
      referral: {
        code: {
          referralShareCode: makeReferralShareCode(),
          referralCopyCode: makeReferralCopyCode(),
        },
        view: {
          referralPageView: makeReferralPageView(),
        },
        clickBrokerShare: makeClickBrokerShare(),
      },
      saveBeneficiaryChanges: makeSaveBeneficiaryChangesService(),
      saveEditedBeneficiaryModal: makeSaveEditedBeneficiaryModalService(),
      sideMenuHomepage: makeSideMenuHomepageService(),
      saveNewBeneficiaryModal: makeSaveNewBeneficiaryModalService(),
      policies: {
        clickCheckPoliciesReadjustment:
          makeClickCheckPoliciesReadjustmentService(),
        clickHelpReadjustmentDetails: makeClickHelpReadjustmentDetailsService(),
        clickPoliciesCards: makeClickPoliciesCardsService(),
        clickPoliciesTabs: makeClickPoliciesTabsService(),
        clickReadjustmentBadge: makeClickReadjustmentBadgeService(),
        clickReadjustmentDetails: makeClickReadjustmentDetailsService(),
        clickRedirectReadjustmentDetails:
          makeClickRedirectReadjustmentDetailsService(),
        downloadProposal: makeDownloadProposalService(),
        viewPoliciesDetails: makeViewPoliciesDetailsService(),
        viewPolicyReadjustmentDetails:
          makeViewPolicyReadjustmentDetailsService(),
        viewPolicyReadjustmentModal: makeViewPolicyReadjustmentModalService(),
      },
      policy: {
        cancel: {
          cancelPolicy: makeCancelPolicyService(),
          viewPolicyCancel: makeViewPolicyCancelService(),
          viewPolicyCancelReason: makeViewPolicyCancelReasonService(),
          selectPolicyCancelReason: makeSelectPolicyCancelReasonService(),
          exitPolicyCancel: makeExitPolicyCancelService(),
          clickPolicyCancel: makeClickButtonPolicyCancelService(),
          viewReasonCancellation: makeViewReasonCancellationService(),
          actionsReasonCancellation: makeActionsReasonCancellationService(),
          viewReduceValue: makeViewReduceValueService(),
          actionsReduceValue: makeActionsReduceValueService(),
          viewCancellationValidation: makeViewCancellationValidationService(),
          actionsCancellationValidation:
            makeActionsCancellationValidationService(),
          viewFeedbackModal: makeViewFeedbackModalService(),
          actionsFeedbackModal: makeActionsFeedbackModalService(),
          talkAzosCancellation: makeClickTalkAzosCancellationService(),
        },
        view: {
          clickPolicyCard: makeClickPolicyCardService(),
          policyScreenView: makePolicyScreenViewService(),
        },
        clickCoverageDetails: makeClickCoverageDetailsService(),
        clickCoverageDetailsButtons: makeClickCoverageDetailsButtonsService(),
        downloadGeneralConditions: makeDownloadGeneralConditionsService(),
        downloadPolicy: makeDownloadPolicyService(),
        policyExpirationDate: makePolicyExpirationDateService(),
        viewCoverageDetails: makeViewCoverageDetailsService(),
      },
      payments: {
        change: {
          changePaymentModalDetails: makeChangePaymentModalDetailsService(),
        },
        save: {
          saveNewCreditCard: makeSaveNewCreditCardService(),
        },
        view: {
          viewBannerChangePayment: makeViewBannerChangePaymentService(),
          viewPaymentDetailsModal: makeViewPaymentDetailsModalService(),
          viewAddCreditCardModal: makeViewAddCreditCardModalService(),
        },
      },
      personalData: {
        clickConfirmUpdateChanges: makeClickConfirmUpdateChangesService(),
        clickUpdateChanges: makeClickUpdateChangesService(),
        viewEditPersonalData: makeViewEditPersonalDataService(),
        viewPersonalDataBottomSheet: makeViewPersonalDataBottomSheetService(),
        viewPersonalData: makeViewPersonalDataService(),
        viewResetPassword: makeViewResetPasswordService(),
      },
      viewAppDownloadModal: makeViewAppDownloadModal(),
      upsell: {
        calculator: {
          addCoverageProduct: makeUpsellAddCoverageProductService(),
          removeCoverageProduct: makeUpsellRemoveCoverageProductService(),
          viewCalculatorPage: makeUpsellViewCalculatorPage(),
          clickCoverageValueInput: makeUpsellClickCoverageValueInputService(),
          clickCoverageDetails: makeUpsellClickCoverageDetailsService(),
          clickHelpCoverage: makeUpsellClickHelpCoverageService(),
          clickDetailsCoverage: makeUpsellClickDetailsCoverageService(),
          clickSubmitCalculator: makeUpsellClickSubmitCalculatorService(),
          expandCoverageDetails: makeUpsellExpandCoverageDetailsService(),
          goBackCalculator: makeUpsellGoBackCalculatorPage(),
          changeCoverageRange: makeUpsellChangeCoverageRangeService(),
          clickDetailsAddCoverage: makeUpsellClickDetailsAddCoverageService(),
          clickGeneralConditionsLink:
            makeUpsellClickGeneralConditionsLinkService(),
          closeDetailsModal: makeUpsellCloseDetailsModalService(),
          goBackDetailsCoverage: makeUpsellGoBackDetailsCoverageService(),
        },
        checkout: {
          clickInsertDueDate: makeUpsellClickInsertDueDateService(),
          viewCheckoutPage: makeUpsellViewCheckoutPage(),
          clearSaleResponseTime: makeUpsellClearSaleResponseTimeService(),
          clickPaymentType: makeUpsellClickPaymentTypeService(),
          expandCheckoutDetails: makeUpsellExpandCheckoutDetailsService(),
          finishCreditcardPayment: makeUpsellFinishCreditcardPaymentService(),
          finishPixPayment: makeUpsellFinishPixPaymentService(),
          saveDueDateChange: makeUpsellSaveDueDateChangeService(),
          selectDueDate: makeUpsellSelectDueDateService(),
          loadingPixPayment: makeUpsellLoadingPixPaymentService(),
          viewDueDateSelectDrawer: makeUpsellViewDueDateSelectDrawerService(),
          selectPaymentFrequency: makeSelectPaymentFrequencyService(),
        },
        contract: {
          gobackSharePage: makeGobackSharePageService(),
          sendReferralForm: makeUpsellSendReferralFormService(),
          copyShareContent: makeUpsellCopyShareContentService(),
          selectContractType: makeUpsellSelectContractTypeService(),
          viewSelectPage: makeUpsellViewSelectPageService(),
          upsellDurationTime: makeUpsellDurationTimeService(),
          viewUpsellSharePage: makeUpsellSharePageService(),
        },
        DPS: {
          continueLifeStyleQuestion:
            makeUpsellContinueLifeStyleQuestionService(),
          continueDiseasesQuestion: makeUpsellContinueDiseasesQuestionService(),
          continueGeneralConditionsQuestion:
            makeUpsellContinueGeneralConditionsQuestionService(),
          continueOtherDiseasesQuestion:
            makeUpsellContinueOtherDiseasesQuestionService(),
          continueRevision: makeUpsellContinueRevisionService(),
          viewLifeStyleContent: makeUpsellViewLifeStyleContentService(),
          viewDiseasesContent: makeUpsellViewDiseasesContentService(),
          viewGeneralConditions:
            makeUpsellViewGeneralConditionsContentService(),
          viewOtherDiseasesContent: makeUpsellViewOtherDiseasesContentService(),
          viewDPSResumeService: makeUpsellViewDPSResumeService(),
          goBack: makeUpsellGoBackService(),
        },
        simulation: {
          clickOtherDpsOtherDiseasesQuestion:
            makeUpsellClickOtherDiseasesService(),
          continueSimulationPageSmokerIMC:
            makeUpsellContinueSimulationPageSmokerIMCService(),
          continueSimulationPageProfessionSalary:
            makeUpsellContinueSimulationPageProfessionSalaryService(),
          goBackSimulationPageProfessionSalary:
            makeUpsellGobackSimulationPageProfessionSalary(),
          goBackSimulationPageSmokerIMC:
            makeUpsellGobackSimulationPageSmokerIMC(),
          viewSimulationPageProfessionSalary:
            makeUpsellViewSimulationPageProfessionSalary(),
          viewSimulationPageSmokerIMC: makeUpsellViewSimulationPageSmokerIMC(),
        },
      },
    },
    dps: {
      dpsIllness: makeDPSIllnessSevice(),
      selection: makeDPSSelectionService(),
      submit: makeDPSSubmitService(),
    },
    proposal: {
      accepted: makeProposalAcceptedService(),
      changed: makeProposalChangedService(),
      denied: makeProposalDeniedService(),
      refused: makeProposalRefusedService(),
    },
    quotation: {
      allowReceiveByEmail: makeQuotationAllowReceiveByEmailService(),
      declineReceiveByEmail: makeQuotationDeclineReceiveByEmailService(),
    },
    simulation: {
      answer: makeSimulationAnswerService(),
      editCoverage: makeSimulationEditCoverageService(),
      income: makeSimulationIncomeService(),
      lead: makeSimulationLeadService(),
      start: makeStartSimulationService(),
      submit: makeSimulationSubmitService(),
      summary: makeSimulationSummaryService(),
      viewExperiment: makeViewExperimentService(),
    },
    custom: {
      action: makeCustomService(),
    },
    widget: {
      homeFastQuotation: makeHomeFastQuotationService(),
    },
  }
}

export const analytics = setupAnalytics()
