import React from 'react'

import { Modal, ModalProps, withModal } from '@azos/shared'

import { CancellationValidation } from '../../PolicyCancel/components/CancellationValidation'
import { ReasonCancellation } from '../../PolicyCancel/components/ReasonCancellation'
import { ReduceValue } from '../../PolicyCancel/components/ReduceValue'
import { PolicyCancelContext } from '../../PolicyCancel/PolicyCancelContext'

type Props = ModalProps & {
  policyId: string
}

const PolicyCardCancel: React.VFC<Props> = ({ policyId, open, onClose }) => {
  const [step, setStep] = React.useState<number>(0)
  const [reason, setReason] = React.useState<string>('')
  const [otherReason, setOtherReason] = React.useState('')

  const renderContentSteps = () => {
    const renderSteps = {
      0: () => <ReasonCancellation onStepChange={setStep} onClose={onClose} />,
      1: () => <ReduceValue onStepChange={setStep} onClose={onClose} />,
      2: () => (
        <CancellationValidation
          onStepChange={setStep}
          onClose={onClose}
          policyId={policyId}
        />
      ),
    }
    return renderSteps[step]()
  }

  return (
    <PolicyCancelContext.Provider
      value={{
        reason,
        setReason,
        setOtherReason,
        otherReason,
        setStep,
        step,
      }}
    >
      <Modal
        open={open}
        onClose={onClose}
        maxWidth="493px"
        showCloseButton={false}
        variant="drawer"
        noGutter
        showHeader={false}
      >
        {renderContentSteps()}
      </Modal>
    </PolicyCancelContext.Provider>
  )
}

export default withModal<Props>(PolicyCardCancel)
