import { Dispatcher, Service } from '../../protocols'

export type IPolicyPaymentHomepageService = Service<void>

/**
 * Policy Payment homepage
 *
 * @category Callpage
 */
export class PolicyPaymentHomepageService
  implements IPolicyPaymentHomepageService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @param
   * @function
   */
  execute(): void {
    this.dispatcher.execute(
      'Area logada web - Clique em pagamento no card de apólice na home page',
    )
  }
}
