import React from 'react'

import { useNotification } from '@Azos-Seguros/apollo'
import { useAuth } from '@azos/account'
import analytics from '@azos/analytics'
import { ViewPersonalDataStatus } from '@azos/analytics/src/domain/models'
import {
  EmptyState,
  Spinner,
  useTheme,
  useWindowSize,
  withModal,
} from '@azos/shared'
import { Button, ButtonSelect, Text } from '@azos/shared/src/components/v2'
import {
  PoliciesResponse,
  TextPolicyStatus,
  UserManagementStatus,
} from '@domain/models'
import { makeUserManagementStatusService } from '@main/factories/services'
import { useMyPolicies } from '@presentation/providers/MyPoliciesProvider'
import { env } from 'main/config/env'
import { useRouter } from 'next/router'

import { UserEditPersonalDataCard, UserPhoto } from '../User'
import { PersonalDataChangeable } from './PersonalDataChangeable'
import { WaitingApprovalModal } from './PersonalDataChangeable/WaitingApprovalModal'
import { ResetPasswordModal } from './ResetPasswordModal'
import { WaitingApprovalPasswordModal } from './ResetPasswordModal/WaitingApprovalPasswordModal'
import { UserAccountModalProps } from './UserAccountModal.props'
import { Modal, Root } from './UserAccountModal.styles'
import { WaitingApprovalCard } from './WaitingApprovalCard'

const getUserManagementStatus = makeUserManagementStatusService()

const UserAccountModal: React.VFC<UserAccountModalProps> = ({
  open,
  onClose,
}) => {
  const theme = useTheme()
  const { user } = useAuth()
  const { policies } = useMyPolicies()
  const { width } = useWindowSize()

  const [isLoading, setIsLoading] = React.useState(false)

  const [isUpdatePhotoOpen, setIsUpdatePhotoOpen] = React.useState(false)
  const [resetPasswordOpen, setResetPasswordOpen] = React.useState(false)
  const [waitingApprovalPassword, SetWaitingApprovalPassword] =
    React.useState(false)
  const [waitingApproval, setWaitingApproval] = React.useState(false)
  const [waitingApprovalData, setWaitingApprovalData] = React.useState<
    UserManagementStatus | undefined
  >(undefined)

  const [activePolicies, setActivePolicies] = React.useState<
    PoliciesResponse[]
  >([])

  const Router = useRouter()

  const [isShowingUserEditData, setIsShowingUserEditData] =
    React.useState(false)

  const getViewPersonalData = React.useCallback(
    (status: UserManagementStatus) => {
      const statusCases: { [key: string]: ViewPersonalDataStatus } = {
        [UserManagementStatus.ok]: ViewPersonalDataStatus.DEFAULT,
        [UserManagementStatus.pending]: ViewPersonalDataStatus.WAITING_APPROVAL,
        [UserManagementStatus.error]: ViewPersonalDataStatus.REFUSED,
      }

      if (statusCases[status])
        analytics.dashboard.personalData.viewPersonalData.execute({
          status: statusCases[status],
        })
    },
    [],
  )

  const fetchUserManagementStatus = React.useCallback(() => {
    setIsLoading(true)
    getUserManagementStatus
      .execute()
      .then(({ status }) => {
        setWaitingApprovalData(status)
        getViewPersonalData(status)
      })
      .finally(() => setIsLoading(false))
  }, [getViewPersonalData])

  const filterPoliciesByActive = React.useCallback(() => {
    const inactivePoliciesStatus = [
      TextPolicyStatus.canceled,
      TextPolicyStatus.expired,
      TextPolicyStatus.pre_canceled,
    ]
    const policiesFilteredByActive = policies.filter(
      policy =>
        !inactivePoliciesStatus.includes(
          policy.status[0].label as TextPolicyStatus,
        ),
    )
    setActivePolicies(policiesFilteredByActive)
  }, [policies])

  React.useEffect(() => {
    if (policies.length > 0) {
      filterPoliciesByActive()
    }
  }, [policies, filterPoliciesByActive])

  const isElegibleToEdit = React.useMemo(
    () => user?.isInsured && activePolicies.length > 0,
    [activePolicies.length, user?.isInsured],
  )

  React.useEffect(() => {
    if (isElegibleToEdit) {
      fetchUserManagementStatus()
    } else {
      getViewPersonalData(UserManagementStatus.ok)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isElegibleToEdit])

  const isPendingForApproval =
    waitingApprovalData === UserManagementStatus.pending

  const onButtonClick = () => {
    if (isElegibleToEdit) {
      if (isPendingForApproval) {
        setWaitingApproval(true)
        return
      }

      setIsShowingUserEditData(true)
    } else {
      Router.push(env.SIMULATION_URL)
    }
  }

  const onButtonClickPassword = () => {
    if (isPendingForApproval) {
      SetWaitingApprovalPassword(true)
    } else {
      setResetPasswordOpen(true)
    }
  }

  const isMobile = React.useMemo(
    () => width < theme.breakpoints.sm,
    [width, theme.breakpoints.sm],
  )

  return (
    <>
      <Modal
        variant="drawer"
        exitBgColor={
          !isMobile ? theme.palette.neutral[100] : theme.palette.neutral[400]
        }
        backgroundColor={theme.palette.neutral[400]}
        showBackButton={isMobile}
        open={open}
        onClose={onClose}
        title="Sua conta"
        noGutter
      >
        <Root $waitingApprovalDataActive={isPendingForApproval}>
          <div className="user-account-modal">
            <div className="user-account-modal__user-photo">
              <UserPhoto
                user={user}
                size="md"
                isOpen={isUpdatePhotoOpen}
                setIsOpen={setIsUpdatePhotoOpen}
              />
              <div
                className="user-account-modal__user-photo--text"
                onClick={() => setIsUpdatePhotoOpen(true)}
              >
                <Text
                  variant="body2"
                  className="user-account-modal__user-photo--text-content"
                >
                  Alterar imagem
                </Text>
              </div>
            </div>
          </div>

          {isLoading ? (
            <div className="user-account-modal__empty-state-wrapper">
              <EmptyState
                align="center"
                icon={<Spinner />}
                title="Carregando informações"
              >
                Estamos carregando os seus dados.
              </EmptyState>
            </div>
          ) : (
            <>
              <div className="user-account-modal__content">
                {isElegibleToEdit && isPendingForApproval && (
                  <WaitingApprovalCard />
                )}
                <PersonalDataChangeable
                  waitingApproval={isPendingForApproval}
                  isElegibleToEdit={isElegibleToEdit}
                />
                <div className="user-account-modal__content--button">
                  <ButtonSelect
                    title="Redefinir senha"
                    startIcon={<i className="icon-password" />}
                    endIcon={
                      <i
                        className={
                          isPendingForApproval
                            ? 'icon-padlock'
                            : 'icon-chevron-right'
                        }
                      />
                    }
                    className="user-account-modal__content--button-select"
                    onClick={onButtonClickPassword}
                  />
                </div>
              </div>
              <div className="user-account-modal__button-edit">
                <Button
                  fullWidth
                  className="user-account-modal__button-edit--float"
                  onClick={onButtonClick}
                >
                  {!isElegibleToEdit ? 'Simule um seguro' : 'Editar dados'}
                </Button>
              </div>
            </>
          )}
        </Root>
      </Modal>
      <ResetPasswordModal
        open={resetPasswordOpen}
        onClose={() => setResetPasswordOpen(false)}
      />

      <WaitingApprovalPasswordModal
        open={waitingApprovalPassword}
        onClose={() => SetWaitingApprovalPassword(false)}
      />

      <WaitingApprovalModal
        open={waitingApproval}
        onClose={() => setWaitingApproval(false)}
      />

      <UserEditPersonalDataCard
        open={isShowingUserEditData}
        onClose={() => setIsShowingUserEditData(false)}
        onUpdate={fetchUserManagementStatus}
      />
    </>
  )
}

export default withModal<UserAccountModalProps>(UserAccountModal)
