import { css, styled } from '../../styles'
import { Utils } from '../../utils'

type ReferralCardStyleProps = {
  $backgroundColor: string
}

export const Root = styled(
  'div',
  Utils.transient.transientStyleProps,
)<ReferralCardStyleProps>(
  ({ theme, $backgroundColor }) => css`
    .referral-card {
      width: 100%;
      background: ${$backgroundColor};
      padding: 24px;
      margin-bottom: 24px;

      &__image-header {
        width: 100%;
        height: 104px;
        position: relative;
      }

      &__content {
        i {
          font-size: 1.5rem;
          margin-bottom: 12px;
          color: ${theme.palette.primary.main};
        }

        &--title {
          font-weight: ${theme.typography.fontWeightBold};
          line-height: 24px;
          font-size: 1rem;
          color: '#090A0B';
          margin-top: 12px;

          strong {
            color: ${theme.palette.primary.main};
          }
        }

        &--description {
          margin: 8px 0 16px;
          font-weight: ${theme.typography.fontWeightMedium};
          font-size: 0.75rem;
        }
      }
    }
  `,
)
