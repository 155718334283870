import React from 'react'

import { useNotification } from '@Azos-Seguros/apollo'
import { Modal, ModalProps, useNotify, withModal } from '@azos/shared'
import { ContractType } from '@domain/models'
import {
  makeChangePaymentMethodService,
  makeChangePaymentMethodV2Service,
  makeLoadProposalDetailsService,
} from '@main/factories/services'
import { makeLoadPolicyDetailsService } from '@main/factories/services'
import { usePolicyPaymentStatus } from '@presentation/hooks/usePolicyPaymentStatus'

import { CreditCardAdd, CreditCardAddProps } from '../../../CreditCardAdd'

type Props = ModalProps &
  Omit<
    CreditCardAddProps,
    | 'changePaymentMethodService'
    | 'type'
    | 'onPaymentChangeSuccess'
    | 'onPaymentChangeError'
  >

const changePaymentMethodService = makeChangePaymentMethodService()
const changePaymentMethodV2Service = makeChangePaymentMethodV2Service()
const loadPolicyDetailsService = makeLoadPolicyDetailsService()
const loadProposalDetailsService = makeLoadProposalDetailsService()

const CardPolicyCreditCard: React.VFC<Props> = ({
  onClose,
  open,
  id,
  billingServiceSource,
}) => {
  const { onPaymentChangeSuccess } = usePolicyPaymentStatus()
  const { addNotification } = useNotification()

  const handlePaymentChangeSuccess = () => {
    onPaymentChangeSuccess()

    addNotification({
      variant: 'success',
      description: 'Método de pagamento alterado com sucesso.',
    })
  }

  const handlePaymentChangeError = () => {
    addNotification({
      variant: 'error',
      description:
        'Falha ao alterar método de pagamento. Por favor, tente novamente ou entre em contato com nosso suporte.',
    })
  }

  return (
    <Modal
      showBackButton
      variant="drawer"
      open={open}
      closeOnOverlayClick={false}
      onClose={onClose}
    >
      <CreditCardAdd
        id={id}
        type={ContractType.policy}
        onPaymentChangeError={handlePaymentChangeError}
        onPaymentChangeSuccess={handlePaymentChangeSuccess}
        billingServiceSource={billingServiceSource}
        loadPolicyDetailsService={loadPolicyDetailsService}
        loadProposalDetailsService={loadProposalDetailsService}
        changePaymentMethodService={changePaymentMethodService}
        changePaymentMethodV2Service={changePaymentMethodV2Service}
      />
    </Modal>
  )
}

export default withModal(CardPolicyCreditCard)
