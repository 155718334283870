import React from 'react'

import { GuardianContext, GuardianContextData } from './GuardianProvider'

const useGuardian = (): GuardianContextData => {
  const context = React.useContext(GuardianContext)

  if (!context) {
    throw new Error('useGuardian must be used within a GuardianProvider')
  }

  return context
}

export { useGuardian }
