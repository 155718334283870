import React from 'react'

import { useAuth } from '@azos/account'
import { useGuardian } from '@presentation/providers/GuardianProvider'

import ProtectedBrokerHelpModal from './ProtectedBrokerHelpModal'
import { ProtectedBrokerHelpModalAdapterProps } from './ProtectedBrokerHelpModal.props'

const ProtectedBrokerHelpModalAdapter: React.VFC<
  ProtectedBrokerHelpModalAdapterProps
> = props => {
  const { user } = useAuth()
  const { isGuardian } = useGuardian()

  return (
    <ProtectedBrokerHelpModal user={user} isGuardian={isGuardian} {...props} />
  )
}

export default ProtectedBrokerHelpModalAdapter
