import React from 'react'

import { Button, Utils } from '@azos/shared'
import { Checkbox, Typography } from '@mui/material'
import clsx from 'clsx'

import { ItemProps } from './Item.props'
import { Label } from './Item.styles'

export const Item = ({
  name,
  label,
  //
  value,
  onChange,
  //
  checked = false,
  disabled,
  parentName,
}: ItemProps) => {
  const handleChange = React.useCallback(() => {
    if (value) {
      onChange(value)
    }
  }, [onChange, value])

  return (
    <Label htmlFor={name}>
      <Button
        className={clsx('list--choice', { ['active']: checked })}
        onClick={handleChange}
        disabled={disabled}
        data-action={Utils.dataNames.renderDataName(
          value?.toString(),
          parentName,
        )}
      >
        <Typography variant="body2" className="list--choice__typography">
          {label}
        </Typography>
        <Checkbox
          id={name}
          name={name}
          value={value?.toString()}
          onChange={() => {}}
          checked={checked}
          disabled={disabled}
        />
      </Button>
    </Label>
  )
}

export default Item
