import React from 'react'
import QRCode from 'react-qr-code'

import { Skeleton } from '@Azos-Seguros/apollo'

import { CardQRCodeProps } from './CardQRCode.props'
import { Root } from './CardQRCode.styles'

const CardQRCode: React.VFC<CardQRCodeProps> = ({
  value,
  loading = false,
  skeletonProps,
  ...rest
}) => {
  return (
    <Root {...rest}>
      {loading ? (
        <Skeleton width={188} height={188} {...skeletonProps} />
      ) : (
        <div className="card-qr-code__wrapper">
          <QRCode value={value} size={188} />
        </div>
      )}
    </Root>
  )
}

export default CardQRCode
