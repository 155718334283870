import React from 'react'

import { Text } from '@azos/shared'
import { Divider } from '@mui/material'

import { MenuItem, MenuItemProps, MenuTopic } from './Menu.props'
import Root from './Menu.styles'

const Menu: React.VFC<MenuItemProps> = ({ title, topics, onClick }) => {
  const renderTopics = (topic: MenuTopic, topicKey: number) => {
    const { label, items } = topic

    const renderItem = (item: MenuItem, key: number) => {
      const { value, icon, label: labelItem, onClick: onItemClick } = item
      const click = onClick?.((_path?: string) => onItemClick?.())

      return (
        <div
          className="topic"
          key={`topic-item-${key}`}
          onClick={() => click?.(value)}
        >
          <i className={icon} />
          <div>{labelItem || value}</div>
        </div>
      )
    }
    return (
      <div className="topic__label" key={`topic-${topicKey}`}>
        {topic !== topics[0] && (
          <div className="topic__divider">
            <Divider />
          </div>
        )}
        {label && (
          <Text variant="body2" component="p">
            {label}
          </Text>
        )}
        <span>{items.map(renderItem)}</span>
      </div>
    )
  }

  return (
    <Root>
      <div className="topic__title">
        <Text variant="h4">{title}</Text>
        {topics.map(renderTopics)}
      </div>
    </Root>
  )
}

export default Menu
