import { animated } from 'react-spring'

import { css, styled } from '../../styles'
import { Utils } from '../../utils'

export const Root = styled(
  animated.div,
  Utils.transient.transientStyleProps,
)<any>(
  ({ $variant, theme }) => css`
    position: fixed;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overscroll-behavior: contain;
    z-index: ${theme.zIndex.modal};

    ${$variant === 'drawer' &&
    css`
      justify-content: flex-end;
    `}

    ${$variant === 'bottomDrawer' &&
    css`
      align-items: flex-end;
    `}
  `,
)

export const Overlay = styled(animated.span)`
  position: fixed;
  inset: 0;
  z-index: -1;
  background: #00000047;
  backdrop-filter: blur(2px);
  will-change: opacity;
`

export const Content = styled(
  animated.div,
  Utils.transient.transientStyleProps,
)<any>(
  ({
    theme,
    $variant,
    $showBackButton,
    $maxWidth,
    $backgroundColor,
    $exitBgColor,
    $noGutter,
  }) => css`
    background: ${$backgroundColor};
    min-width: 100%;
    height: 100%;
    overflow-y: auto;
    overscroll-behavior: contain;
    position: relative;

    @media (min-width: 600px) {
      height: unset;
      min-width: ${$maxWidth ? 'unset' : '520px'};
      max-width: ${$maxWidth ? $maxWidth : '800px'};
    }

    ${$variant === 'modal'
      ? css`
          @media (min-width: 600px) {
            max-height: 95vh;
          }

          > .modal-content-wrapper {
            margin: ${$noGutter ? 0 : '0 24px 24px 24px'};
            flex: 1;
          }
        `
      : ``}

    ${$variant === 'drawer'
      ? css`
          width: 100%;
          border-radius: 0;
          display: flex;
          flex-direction: column;

          > .modal-content-wrapper {
            margin: ${$noGutter ? 0 : '0 16px 16px 16px'};
            flex: 1;
          }

          @media (min-width: 600px) {
            width: ${$maxWidth ? $maxWidth : '520px'};
            height: 100vh;
            max-height: 100vh;
            border-radius: 0;

            > .modal-content-wrapper {
              margin: ${$noGutter ? 0 : '0 44px 40px 34px'};
            }
          }
        `
      : ``}

    ${$variant === 'bottomDrawer'
      ? css`
          width: 100%;
          border-radius: 16px 16px 0 0;
          display: flex;
          flex-direction: column;
          height: max-content;
          max-height: 95vh;

          > .modal-content-wrapper {
            margin: ${$noGutter ? 0 : '0 16px 16px 16px'};
            flex: 1;
          }
        `
      : ``}

    .modal-header {
      padding: 16px 16px 24px 16px;
      background: ${$backgroundColor};
      position: sticky;
      top: 0;
      z-index: 1100;

      display: flex;
      align-items: center;

      .title {
        font-size: 18px;
        font-weight: 600;
        margin: 0;
        margin-right: ${$showBackButton ? '0' : '16px'};
        margin-left: ${$showBackButton ? '16px' : '0'};
        padding: 0;

        & + .subtitle {
          margin-top: 8px;
        }
      }

      .subtitle {
        margin: 0;
        margin-right: ${$showBackButton ? '0' : '16px'};
        margin-left: ${$showBackButton ? '16px' : '0'};
        padding: 0;
      }

      .exit-button {
        cursor: pointer;
        background: ${$exitBgColor ? $exitBgColor : $backgroundColor};
        border: 0;
        border-radius: 50%;
        font-size: 24px;
        padding: 0;
        position: relative;
        height: 48px;
        width: 48px;
        color: #090a0b;

        display: flex;
        align-items: center;
        justify-content: center;

        span {
          font-size: 24px;
        }

        ${!$showBackButton
          ? css`
              background: ${$exitBgColor ? $exitBgColor : $backgroundColor};
              width: 48px;
              margin-left: auto;
            `
          : ``}
      }

      @media (min-width: 600px) {
        padding: 24px;
      }
    }
  `,
)
