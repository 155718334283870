import React from 'react'

import { WizardContext, WizardContextData } from '../providers'

const useWizard = (): WizardContextData => {
  const context = React.useContext(WizardContext)

  if (!context) {
    throw new Error('The useWizard should within WizardProvider')
  }

  return context
}

export { useWizard }
