import {
  ClickPolicyCardService,
  PolicyScreenViewService,
} from '../../../../../../data/services'
import { makeMixpanelDispatcherEvent } from '../../../../infra'

export const makeClickPolicyCardService = () =>
  new ClickPolicyCardService(makeMixpanelDispatcherEvent())

export const makePolicyScreenViewService = () =>
  new PolicyScreenViewService(makeMixpanelDispatcherEvent())
