import {
  AddToCartService,
  RemoveFromCartService,
} from '../../../data/services/cart'
import { makeDispatcherEvent } from '../infra/dispatcher-event-factory'

export const makeAddToCartService = () =>
  new AddToCartService(makeDispatcherEvent())

export const makeRemoveFromCartService = () =>
  new RemoveFromCartService(makeDispatcherEvent())
