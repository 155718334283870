export enum OnboardingActionType {
  policy = 'policy',
  guardian = 'guardian',
  beneficiary = 'beneficiary',
}

type OnboardingAction = {
  label: string
  variant: 'primary' | 'secondary'
  isManuallyCompleted: boolean
  externalLink: string | null
}

export enum WizardTutorialTag {
  policy = 'policy',
  beneficiaries = 'beneficiaries',
  guardians = 'guardians',
}

export type WizardListActionContent = {
  id: number
  title: string
  description: string
  type: OnboardingActionType
  slug: string
  actions: OnboardingAction[]
  mobileImage: string
  desktopImage: string
}

export type WizardListAction = {
  tag: string
  executed: boolean
  content: WizardListActionContent
}

export type WizardListMainActionContent = {
  id: number
  title: string
  description: string
  type: OnboardingActionType
  slug: string
  actions: OnboardingAction[]
  mobileImage: string
  desktopImage: string
}

export type WizardListMainActionActions = {
  tag: string
  locked: boolean
  executed: boolean
  content: WizardListMainActionContent
}

export type WizardListMainAction = {
  title: string
  actions: WizardListMainActionActions[]
}

export type WizardListTutorial = {
  tag: WizardTutorialTag
  title: string
  icon: string
  total: number
  color: string
  actions: WizardListAction[]
  mainAction: WizardListMainAction | null
}

export type WizardListHeader = {
  total: string
  color: string
  title: string
  description: string
}

export type WizardList = {
  header: WizardListHeader
  tutorial: WizardListTutorial[]
}
