import { HttpClient, UnauthorizedError, UnexpectedError } from '@azos/core'
import {
  LoadBillingDateUseCase,
  LoadProposalByIdUseCase,
} from '@domain/usecases'
import HttpStatusCode from 'http-status-codes'

export type IProposalsRepository = LoadProposalByIdUseCase &
  LoadBillingDateUseCase

export class ProposalsRepository implements IProposalsRepository {
  constructor(private readonly httpClient: HttpClient) {}

  async loadById({
    proposalId,
  }: LoadProposalByIdUseCase.Params): Promise<LoadProposalByIdUseCase.Response> {
    const httpResponse = await this.httpClient.request({
      url: `/proposals/${proposalId}`,
      method: 'get',
      body: null,
    })

    const response = httpResponse.data

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
        return response as LoadProposalByIdUseCase.Response
      case HttpStatusCode.UNAUTHORIZED:
        throw new UnauthorizedError(response?.message)
      default:
        throw new UnexpectedError()
    }
  }

  async loadBillingDate({
    proposalId,
    billingDay,
  }: LoadBillingDateUseCase.Params): Promise<LoadBillingDateUseCase.Response> {
    const httpResponse = await this.httpClient.request({
      url: `/proposals/${proposalId}/simulate-billing-date`,
      method: 'post',
      body: {
        billingDay,
      },
    })

    const response = httpResponse.data

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
        return response as LoadBillingDateUseCase.Response
      default:
        throw new UnexpectedError()
    }
  }
}
