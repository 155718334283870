import { css, Utils } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled(
  'div',
  Utils.transient.transientStyleProps,
)<any>(
  ({ theme, $noText }) => css`
    display: flex;
    align-items: center;

    .navigation-icon {
      align-items: center;
      display: flex;
      justify-content: center;
      cursor: pointer;

      margin-left: 0;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: ${theme.palette.neutral[100]};

      i {
        font-size: 24px;
      }
    }

    .navigation-title {
      ${$noText === true
        ? css`
            span {
              display: none;
            }
          `
        : css`
            margin-left: 24px;
          `}
    }
  `,
)
