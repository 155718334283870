import React from 'react'

import { Typography } from '@Azos-Seguros/apollo'
import analytics from '@azos/analytics'
import { Utils } from '@azos/shared'

import { TextProps } from './Text.props'

const Text: React.FCC<TextProps> = ({
  children,
  variant = 'body2',
  parentName,
  onClick,
  ...rest
}) => {
  const handleClick = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
  ) => {
    if (parentName) {
      analytics.custom.action.execute({
        dataAction: Utils.dataNames.renderDataName('text', parentName),
      })
    }
    onClick?.(event)
  }

  return (
    <Typography
      variant={variant}
      onClick={handleClick}
      {...rest}
      data-action={Utils.dataNames.renderDataName('text', parentName)}
    >
      {children}
    </Typography>
  )
}

export default Text
