import { authCookies, Storage } from '@azos/core'

export type CookieData = {
  proposalId?: string
  quotationId?: string
}

export interface ITokenService {
  getToken(): string
  getRefreshToken(): string
  setToken(accessToken: string, refreshToken: string): ITokenService
  removeToken(): ITokenService
}

export class TokenService implements ITokenService {
  constructor(private readonly storage: Storage) {}

  public getToken(): string {
    return this.storage.get(authCookies.COOKIE_TOKEN_KEY)
  }

  public getRefreshToken(): string {
    return this.storage.get(authCookies.COOKIE_REFRESH_TOKEN_KEY)
  }

  public setToken(accessToken: string, refreshToken: string): ITokenService {
    this.storage.set(authCookies.COOKIE_TOKEN_KEY, accessToken)
    this.storage.set(authCookies.COOKIE_REFRESH_TOKEN_KEY, refreshToken)
    return this
  }

  public removeToken(): ITokenService {
    this.storage.remove(authCookies.COOKIE_TOKEN_KEY)
    this.storage.remove(authCookies.COOKIE_REFRESH_TOKEN_KEY)
    this.storage.remove(authCookies.COOKIE_REDIRECT_FROM_KEY)
    this.storage.remove(authCookies.COOKIE_USER_KEY)
    return this
  }
}
