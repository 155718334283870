import { AppError } from './app-error'

export class DuplicateInvoiceError extends AppError {
  constructor() {
    super(
      'Houve um problema em gerar uma 2˚ via de fatura, por favor, tente novamente',
    )
    this.name = 'DuplicateInvoiceError'
  }
}
