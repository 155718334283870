import React from 'react'

import { AvatarProps } from './Avatar.props'
import { Root } from './Avatar.styles'

const getUserInitials = (user: string) =>
  (user.match(/\b(\w)/g) || []).join('').substring(0, 2)

const Avatar: React.VFC<AvatarProps> = ({ name, photoUrl, ...rest }) => {
  return (
    <Root {...rest}>
      {photoUrl ? (
        <img src={photoUrl} alt={name} referrerPolicy="no-referrer" />
      ) : (
        <span>{getUserInitials(name)}</span>
      )}
    </Root>
  )
}

export default Avatar
