import React from 'react'

import { FormControl, InputLabel } from '@mui/material'
import FormHelperText from '@mui/material/FormHelperText'
import MenuItem from '@mui/material/MenuItem'
import MUISelect, { SelectChangeEvent } from '@mui/material/Select'

import { Utils } from '../../../utils'
import { SelectProps, SelectItemValue } from './Select.props'

const Select: React.VFC<SelectProps> = ({
  parentName,
  options,
  name,
  variant = 'standard',
  value,
  label,
  onChange,
  error,
  helperText,
  fullWidth,
  className,
  ...props
}) => {
  const [isOpen, setIsOpen] = React.useState(false)

  const handleChange = React.useCallback(
    (event: SelectChangeEvent<unknown>) => {
      onChange(event.target.value as SelectItemValue)
    },
    [onChange],
  )

  const handleOpen = (event: React.SyntheticEvent) => {
    setIsOpen(true)
    props.onOpen?.(event)
  }

  const handleClose = (event: React.SyntheticEvent) => {
    setIsOpen(false)
    props.onClose?.(event)
  }

  return (
    <FormControl
      variant={variant}
      error={!!error}
      fullWidth={fullWidth}
      className={className}
    >
      <InputLabel htmlFor={name}>{label}</InputLabel>

      <MUISelect
        {...props}
        name={name}
        variant={variant}
        onOpen={handleOpen}
        onClose={handleClose}
        onChange={handleChange}
        value={value ?? ''}
        fullWidth={fullWidth}
        sx={{ flex: 1 }}
        data-action={Utils.dataNames.renderDataName('select', parentName)}
        error={error}
        IconComponent={() =>
          isOpen ? (
            <span className="icon icon-expand-less" />
          ) : (
            <span className="icon icon-expand-more" />
          )
        }
      >
        {options.map(item => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </MUISelect>

      {error && (
        <FormHelperText error className="container__helper-text">
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default Select
