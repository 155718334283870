import React from 'react'

import { Skeleton } from '@Azos-Seguros/apollo'
import analytics from '@azos/analytics'
import { Utils } from '@azos/shared'
import clsx from 'clsx'
import copy from 'copy-to-clipboard'

import { CardCopyTextHandle, CardCopyTextProps } from './CardCopyText.props'
import { Tooltip, Root } from './CardCopyText.styles'

const CardCopyText: React.ForwardRefRenderFunction<
  CardCopyTextHandle,
  CardCopyTextProps
> = (
  {
    value,
    buttonRef,
    onClick,
    disabled = false,
    loading = false,
    successLabel = 'Código copiado com sucesso. Agora só compartilhar com seus amigos e família.',
    parentName,
    shouldShowTooltip = true,
    isTooltipOpen = false,
    customLabel,
    className,
    startIcon,
  },
  ref,
) => {
  const timer = React.useRef<NodeJS.Timeout | null>(null)

  const [showTooltip, setShowTooltip] = React.useState(false)

  React.useEffect(() => {
    setShowTooltip(isTooltipOpen)
  }, [isTooltipOpen])

  React.useEffect(() => {
    return () => {
      if (timer.current) clearTimeout(timer.current)
    }
  }, [])

  const handleCopy = () => {
    copy(value)

    if (shouldShowTooltip) {
      setShowTooltip(true)

      timer.current = setTimeout(() => {
        setShowTooltip(false)
      }, 3000)
    }

    onClick?.()

    if (parentName) {
      analytics.custom.action.execute({
        dataAction: Utils.dataNames.renderDataName(
          'card-copy-text',
          parentName,
        ),
      })
    }
  }

  React.useImperativeHandle(ref, () => ({
    handleCopy,
  }))

  const RenderCopy = React.memo(function renderCardCopy({
    isTooltipOpen,
    shouldShowTooltip,
    children,
  }: {
    isTooltipOpen: boolean
    shouldShowTooltip: boolean
    children: React.ReactElement
  }) {
    if (shouldShowTooltip) {
      return (
        <Tooltip open={isTooltipOpen} title={successLabel}>
          {children}
        </Tooltip>
      )
    }

    return children
  })

  return (
    <RenderCopy
      isTooltipOpen={showTooltip}
      shouldShowTooltip={shouldShowTooltip}
    >
      <Root
        ref={buttonRef}
        className={clsx('card_copy_text', className)}
        disabled={disabled}
        onClick={handleCopy}
        data-action={Utils.dataNames.renderDataName(
          'card-copy-text',
          parentName,
        )}
      >
        <div className="card_copy_text__text-content">
          {startIcon && <i className={startIcon} />}
          <span className="card_copy_text__value">
            {loading ? (
              <Skeleton height={25} width="100%" />
            ) : (
              customLabel || value
            )}
          </span>
        </div>

        <span className="icon-copy" />
      </Root>
    </RenderCopy>
  )
}

CardCopyText.displayName = 'CardCopyText'

export default React.forwardRef(CardCopyText)
