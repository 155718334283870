import { Utils } from '@azos/shared'
import axios from 'axios'

interface ICityFetch {
  nome: string
}

export interface ICityOption {
  name: string
}

interface IStateFetch {
  id: number
  sigla: string
  nome: string
  regiao: { id: number; sigla: string; nome: string }
}

export interface IStateOption {
  acronym: string
  name: string
}

export type ICepFetch = {
  cep: string
  logradouro: string
  complemento: string
  bairro: string
  localidade: string
  uf: string
  ibge: string
  gia: string
  ddd: string
  siafi: string
}

export const loadCities = async (value: string): Promise<ICityOption[]> => {
  return new Promise(resolve => {
    axios
      .get<ICityFetch[]>(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${value}/municipios`,
      )
      .then(({ data }) => data.map(item => ({ name: item.nome })))
      .then(resolve)
      .catch(() => resolve([]))
  })
}

export const loadStates = async (): Promise<IStateOption[]> => {
  return new Promise(resolve => {
    axios
      .get<IStateFetch[]>(
        'https://servicodados.ibge.gov.br/api/v1/localidades/estados',
      )
      .then(({ data }) => {
        return data
          .map(item => ({
            acronym: item.sigla,
            name: item.nome,
          }))
          .sort((a, b) => {
            if (a.acronym < b.acronym) {
              return -1
            }
            if (a.acronym > b.acronym) {
              return 1
            }
            return 0
          })
      })
      .then(resolve)
      .catch(() => resolve([]))
  })
}

export const loadByZipcode = (zipcode: string): Promise<ICepFetch | null> => {
  return new Promise(resolve => {
    const sanitizedCep = Utils.sanitizer.onlyNumber(zipcode)
    if (Utils.validator.validateCep(sanitizedCep)) {
      axios
        .get<ICepFetch>(`https://viacep.com.br/ws/${sanitizedCep}/json/`)
        .then(response => resolve(response.data))
        .catch(() => resolve(null))
    } else {
      return resolve(null)
    }
  })
}
