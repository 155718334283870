import { ClickDpsOtherDiseasesQuestion } from '../../../../../domain/models'
import { Dispatcher, Service } from '../../../../protocols'

export type IUpsellClickOtherDiseasesService =
  Service<ClickDpsOtherDiseasesQuestion>

/**
 * Click other diseases
 *
 * @category Upsell
 */
export class UpsellClickOtherDiseasesService
  implements IUpsellClickOtherDiseasesService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   * @param {ClickDpsOtherDiseasesQuestion} data
   */
  execute(data: ClickDpsOtherDiseasesQuestion): void {
    this.dispatcher.execute(
      'Upsell - Clique em DPS, doença não mencionada',
      data,
    )
  }
}
