export const formatCEP = (cep?: string) => {
  if (cep) {
    return cep
      .replace(/\D/g, '')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{3})\d+?$/, '$1')
  }

  return ''
}

export const formatCPF = (value: string | number): string => {
  const cpf = value.toString().replace(/[^\d]/g, '')
  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
}

export const formatCPFCNPJ = (value: string | number): string => {
  let formatter = value.toString().replace(/\D/g, '')

  if (formatter.length <= 11) formatter = formatCPF(value)
  else {
    formatter = formatter.replace(/^(\d{2})(\d)/, '$1.$2')
    formatter = formatter.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
    formatter = formatter.replace(/\.(\d{3})(\d)/, '.$1/$2')
    formatter = formatter.replace(/(\d{4})(\d)/, '$1-$2')
  }

  return formatter
}

export const formatPhone = (value: string) => {
  let formatter = value.replace(/\D/g, '')

  if (formatter.length === 0) return ''

  formatter = formatter.replace(/^0/, '')
  if (formatter.length > 10) {
    formatter = formatter.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3')
  } else if (formatter.length > 5) {
    formatter = formatter.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, '($1) $2-$3')
  } else if (formatter.length > 2) {
    formatter = formatter.replace(/^(\d\d)(\d{0,5})/, '($1) $2')
  } else {
    formatter = formatter.replace(/^(\d*)/, '($1')
  }
  return formatter
}
