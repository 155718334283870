import { ActionsFeedbackModal } from '../../../../../domain/models'
import { Dispatcher, Service } from '../../../../protocols'

export type IActionsFeedbackModalService = Service<ActionsFeedbackModal>

/**
 * Actions Feedback Modal
 *
 * @category Dashboard
 */
export class ActionsFeedbackModalService
  implements IActionsFeedbackModalService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @param {ActionsFeedbackModal} data
   * @function
   */
  execute(data: ActionsFeedbackModal): void {
    this.dispatcher.execute(
      'Area logada web - Segurado clicou para entrar em contato durante feedback negativo no cancelamento de apólice',
      data,
    )
  }
}
