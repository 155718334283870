import { PROPOSAL_COOKIE, Storage, SyncServices } from '@azos/core'

type Request = void
type Response = {
  proposalId: string
  quotationId: string
}

export type IProposalConsumer = SyncServices<Request, Response>

export class ProposalConsumer implements IProposalConsumer {
  constructor(private readonly storage: Storage) {}

  execute(): Response {
    const data = this.storage.getJSON<Response>(PROPOSAL_COOKIE)
    return typeof data === 'object' ? data : ({} as Response)
  }
}
