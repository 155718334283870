import { GuardianFlags } from '../../../../domain/models'
import { Dispatcher, Service } from '../../../protocols'

export type IViewProtectedService = Service<GuardianFlags>

/**
 * View Protected Service
 *
 * @category Home
 */
export class ViewProtectedService implements IViewProtectedService {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   * @param {GuardianFlags} data
   */
  execute(data: GuardianFlags): void {
    this.dispatcher.execute(
      'Area logada web - Visualização de seus protegidos',
      data,
    )
  }
}
