import React from 'react'

import { Spinner } from '@Azos-Seguros/apollo'
import analytics from '@azos/analytics'
import {
  Counter,
  EmptyState,
  Modal,
  useWindowSize,
  Utils,
  withModal,
} from '@azos/shared'
import { Button } from '@azos/shared/src/components/v2'
import { Beneficiaries } from '@domain/models'

import {
  BeneficiariesEditDeletedBeneficiary,
  BeneficiariesEditProps,
} from './BeneficiariesEdit.props'
import { ModalDeleteRoot, Root } from './BeneficiariesEdit.styles'

interface Beneficiary extends Beneficiaries {
  index: number
  locked: boolean
}

const BeneficiariesEdit: React.VFC<BeneficiariesEditProps> = ({
  open,
  initialBeneficiaries,
  lifeInsuranceCoverage,
  onSubmit,
  handleAddMore,
  loading,
  handleDelete,
}) => {
  const { width } = useWindowSize()

  const [delBeneficiary, setDelBeneficiary] =
    React.useState<BeneficiariesEditDeletedBeneficiary>({
      open: false,
      index: -1,
    })

  const [beneficiaries, setBeneficiaries] = React.useState<Beneficiary[]>(() =>
    initialBeneficiaries.map((b, i) => ({ ...b, locked: false, index: i })),
  )

  const totalQuota = React.useMemo(
    () =>
      initialBeneficiaries.reduce(
        (prev, beneficiary) => prev + beneficiary?.quota || 0,
        0,
      ),
    [initialBeneficiaries],
  )

  const totalPercentage = React.useMemo(
    () =>
      beneficiaries.reduce(
        (prev, beneficiary) => prev + beneficiary.percentage,
        0,
      ),
    [beneficiaries],
  )

  React.useEffect(() => {
    analytics.dashboard.editBeneficiaryModal.execute()
  }, [])

  React.useEffect(() => {
    if (beneficiaries.length === 0) {
      onSubmit([])
    }
  }, [beneficiaries, onSubmit])

  React.useEffect(() => {
    if (open) {
      setBeneficiaries(
        initialBeneficiaries.map((b, i) => ({ ...b, locked: false, index: i })),
      )
    }
  }, [open, initialBeneficiaries])

  const changeBeneficiaryInfo = (
    beneficiary: Beneficiary,
    action: 'add' | 'remove',
    isCurrent: boolean,
  ) => {
    const percentage =
      action === 'add'
        ? beneficiary.percentage + (isCurrent ? 1 : -1)
        : beneficiary.percentage - (isCurrent ? 1 : -1)

    const quota = totalQuota * (percentage / 100)

    return {
      ...beneficiary,
      percentage,
      quota,
    }
  }

  const handlePercentage = (action: 'add' | 'remove', index: number) => {
    let beneficiaryWithHigherPercentage: Beneficiary

    if (totalPercentage === 100 && action === 'add') {
      const unlockedBeneficiaries = beneficiaries.filter(
        (b, i) => !b.locked && i !== index && b.percentage > 1,
      )

      if (unlockedBeneficiaries.length === 0) return

      beneficiaryWithHigherPercentage = unlockedBeneficiaries.sort(
        (a, b) => b.percentage - a.percentage,
      )[0]
    }

    const newBeneficiaries = beneficiaries.map((beneficiary, _index) => {
      if (_index === index) {
        return changeBeneficiaryInfo(beneficiary, action, true)
      }

      if (
        beneficiaryWithHigherPercentage &&
        _index === beneficiaryWithHigherPercentage.index
      ) {
        return changeBeneficiaryInfo(beneficiary, action, false)
      }

      return beneficiary
    })

    setBeneficiaries(newBeneficiaries)
  }

  const handleLockBeneficiary = (index: number) => {
    const newBeneficiaries = beneficiaries.map((beneficiary, _index) => {
      if (_index === index) {
        return {
          ...beneficiary,
          locked: !beneficiary.locked,
        }
      }

      return beneficiary
    })

    setBeneficiaries(newBeneficiaries)
  }

  const handleDeleteBeneficiary = () => {
    let newBeneficiaries = beneficiaries.filter(
      (_, index) => index !== delBeneficiary.index,
    )

    newBeneficiaries = newBeneficiaries.map((beneficiary, index) => ({
      ...beneficiary,
      index,
      quota:
        newBeneficiaries.length > 1 ? beneficiary.quota : lifeInsuranceCoverage,
      percentage: newBeneficiaries.length > 1 ? beneficiary.percentage : 100,
    }))

    setBeneficiaries(newBeneficiaries)
    setDelBeneficiary(prev => ({ ...prev, open: false }))

    handleDelete?.(delBeneficiary)
  }

  const handleSubmit = () => {
    let newBeneficiaries = [] as any

    beneficiaries.forEach(item => {
      newBeneficiaries.push({ ...item, locked: undefined, index: undefined })
    })

    // This will remove undefined values
    newBeneficiaries = JSON.stringify(newBeneficiaries)
    newBeneficiaries = JSON.parse(newBeneficiaries)

    onSubmit(newBeneficiaries)
  }

  return (
    <>
      <Root>
        {loading && (
          <div className="loading">
            <EmptyState
              align="center"
              icon={<Spinner />}
              title="Salvando beneficiários"
            >
              Por favor, não feche essa janela enquanto salvamos os
              beneficiários.
            </EmptyState>
          </div>
        )}
        <div className="heading">
          <h2>
            Alterar <strong>beneficiários</strong>
          </h2>

          <p>
            Você tem{' '}
            {totalPercentage === 100 ? (
              '0%'
            ) : (
              <strong>{100 - totalPercentage}%</strong>
            )}{' '}
            da cobertura para dividir entre seus benefícios
          </p>
        </div>

        <ul>
          {beneficiaries.map((beneficiary, index) => (
            <li className="beneficiary" key={index}>
              <div className="heading">
                <p>{beneficiary.socialName || beneficiary.fullName}</p>

                <button
                  className={beneficiary.locked ? 'locked' : 'unlocked'}
                  disabled={beneficiaries.length === 1}
                  onClick={() => handleLockBeneficiary(index)}
                >
                  <span className="icon-padlock" />
                </button>
              </div>

              <div>
                <p>{Utils.currency.format(beneficiary.quota)}</p>

                <Counter
                  value={beneficiary.percentage}
                  onChange={action => handlePercentage(action, index)}
                  disabled={beneficiary.locked || beneficiaries.length === 1}
                  min="1"
                  max={101 - beneficiaries.length}
                  sufix="%"
                />
              </div>

              <div>
                <p>{beneficiary.kinship}</p>

                <button
                  disabled={beneficiary.locked}
                  onClick={() => setDelBeneficiary({ open: true, index })}
                >
                  <span className="icon-delete" />
                </button>
              </div>
            </li>
          ))}
        </ul>

        <Button
          className="add-beneficiaries-button"
          onClick={handleAddMore}
          variant="outline"
        >
          Adicionar beneficiários
        </Button>

        <Button onClick={handleSubmit} disabled={totalPercentage !== 100}>
          Salvar alterações
        </Button>
      </Root>

      <Modal
        open={delBeneficiary.open}
        title="Deseja remover essa pessoa dos seus beneficiários?"
        subtitle="Essa pessoa não receberá o valor da cobertura."
        onClose={() => setDelBeneficiary({ ...delBeneficiary, open: false })}
        showCloseButton={false}
        variant={width < 768 ? 'bottomDrawer' : 'modal'}
      >
        <ModalDeleteRoot>
          <Button fullWidth color="error" onClick={handleDeleteBeneficiary}>
            Remover
          </Button>

          <Button
            fullWidth
            color="inherit"
            variant="outline"
            onClick={() =>
              setDelBeneficiary({ ...delBeneficiary, open: false })
            }
          >
            Cancelar
          </Button>
        </ModalDeleteRoot>
      </Modal>
    </>
  )
}

export default withModal<BeneficiariesEditProps>(BeneficiariesEdit)
