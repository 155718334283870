import Box, { BoxProps } from '@mui/material/Box'

import { styled } from '../../styles'

export const Root = styled(Box)<BoxProps>(({ theme }) => ({
  position: 'relative',

  '.overlay': {
    position: 'absolute',
    zIndex: 10,
    width: '100%',
    top: '100%',
    backgroundColor: 'white',
    border: `1px solid #D0D4DC`,
  },

  '.list': {
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },

  '.list-item': {
    borderBottomWidth: 1,
    padding: '0.5rem 1rem',
    cursor: 'pointer',
    display: 'flex',

    '.gray': {
      color: '#F3F4F6',
    },

    '.white': {
      color: 'white',
    },
  },
}))

export const FieldContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  '& > div': {
    marginTop: '1rem',
  },
}))
