import React from 'react'

import { useAuth } from '@azos/account'
import { useGuardian } from '@presentation/providers/GuardianProvider'

import ProtectedB2CHelpModal from './ProtectedB2CHelpModal'
import { ProtectedB2CHelpModalAdapterProps } from './ProtectedB2CHelpModal.props'

const ProtectedB2CHelpModalAdapter: React.VFC<
  ProtectedB2CHelpModalAdapterProps
> = props => {
  const { user } = useAuth()
  const { isGuardian } = useGuardian()

  return (
    <ProtectedB2CHelpModal user={user} isGuardian={isGuardian} {...props} />
  )
}

export default ProtectedB2CHelpModalAdapter
