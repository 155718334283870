export const formatDate = (date: Date): string => {
  return date.toISOString()
}

export const formatResetDate = (date: Date): string => {
  return formatDate(resetTime(date))
}

export const formatOnlyDate = (date: Date): string => {
  return resetTime(date).toISOString().split('T')[0]
}

export function resetTime(date: Date): Date {
  const data = new Date(date)
  data.setUTCHours(0, 0, 0, 0)
  return data
}
