import { SWRServices } from '@azos/core'
import { ICmsRepository } from '@data/repositories'
import { ListNewsBannersUseCase } from '@domain/usecases'

type Params = ListNewsBannersUseCase.Params
type Response = ListNewsBannersUseCase.Response

export type IListNewsBannersService = SWRServices<Params, Response>

export class ListNewsBannersService implements IListNewsBannersService {
  constructor(private readonly cmsRepository: ICmsRepository) {}

  getUrl(): string {
    return `/news-banners`
  }

  async execute(): Promise<ListNewsBannersUseCase.Response> {
    const response = await this.cmsRepository.listNewsBanners()

    return response
  }
}
