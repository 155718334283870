import { AppError, HttpClient } from '@azos/core'
import { ListWizardUseCase, UpdateWizardUseCase } from '@domain/usecases'
import HttpStatusCode from 'http-status-codes'

export type IWizardRepository = ListWizardUseCase & UpdateWizardUseCase

export class WizardRepository implements IWizardRepository {
  constructor(private readonly httpClient: HttpClient) {}

  async update(
    params: UpdateWizardUseCase.Params,
  ): Promise<UpdateWizardUseCase.Response> {
    const httpResponse = await this.httpClient.request({
      url: `/onboarding/wizard`,
      method: 'post',
      body: params,
    })

    const response = httpResponse.data

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
        return response as UpdateWizardUseCase.Response
      default:
        throw new AppError(response?.message)
    }
  }

  async list(): Promise<ListWizardUseCase.Response> {
    const httpResponse = await this.httpClient.request({
      url: `/onboarding/wizard`,
      method: 'get',
      body: null,
    })

    const response = httpResponse.data

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
        return response as ListWizardUseCase.Response
      default:
        throw new AppError(response?.message)
    }
  }
}
