import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled('div')(
  ({ theme }) => css`
    background: #fff;
    min-height: 100vh;

    @media (min-width: 1024px) {
      background: url('/assets/jpg/login_bg.jpg') no-repeat;
      background-size: cover;
    }

    > main {
      display: flex;
      min-height: calc(100vh - 64px);
      align-items: center;
      justify-content: flex-end;
      padding: 42px;

      @media (max-width: ${theme.breakpoints.md}px) {
        padding: 22px;
      }
    }

    > main > div {
      background: #fff;
      border-radius: 8px;
      padding: 42px;
      width: 100%;

      @media (max-width: ${theme.breakpoints.md}px) {
        padding: 0;
      }

      @media (min-width: 1024px) {
        width: 465px;
      }

      @media (min-width: 1200px) {
        width: 608px;
      }

      h1 {
        font-size: 32px;
      }

      a {
        font-size: 16px;
      }
    }
  `,
)

export default Root
