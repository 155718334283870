import React from 'react'

import { GuardiansTableProps } from './GuardiansTable.props'
import { Root } from './GuardiansTable.styles'

const GuardiansTable: React.VFC<GuardiansTableProps> = ({ items }) => {
  const renderItem = (item, index) => {
    const { name, city, state, kinship } = item

    return (
      <li key={index} className="guardians-table__beneficiary-item">
        <div className="guardians-table__beneficiary-item__content">
          <div>
            <div className="guardians-table__beneficiary-item__content__heading">
              <p className="guardians-table__beneficiary-item__content__heading__title">
                {name}
              </p>
            </div>

            <div className="guardians-table__beneficiary-item__info">
              <p>{kinship}</p>
              {city && (
                <>
                  <span className="guardians-table__beneficiary-item__info__dot" />
                  <p className="guardians-table__beneficiary-item__info__city">
                    {state}
                  </p>
                </>
              )}
            </div>
          </div>
        </div>

        {index < items.length - 1 && <hr />}
      </li>
    )
  }
  return (
    <Root>
      <div className="guardians-table">
        <ul className="guardians-table__beneficiary-list">
          {items.map((item, index) => renderItem(item, index))}
        </ul>
      </div>
    </Root>
  )
}

export default GuardiansTable
