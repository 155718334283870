import { Card } from '@Azos-Seguros/apollo'
import { css, styled, Utils } from '@azos/shared'

type RootProps = {
  $backgroundColor?: string | null
  $titleColor?: string | null
  $descriptionColor?: string | null
}

export const Root = styled(
  Card,
  Utils.transient.transientStyleProps,
)<RootProps>(
  ({ theme, $backgroundColor, $titleColor, $descriptionColor }) => css`
    width: 100%;

    @media (min-width: ${theme.breakpoints.md}px) {
      width: 100%;
    }
    display: flex;
    padding: 21px 12px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    border-radius: 0;

    ${$backgroundColor
      ? css`
          background: ${$backgroundColor};
        `
      : ''}

    cursor: pointer;

    i {
      font-weight: ${theme.typography.fontWeightMedium};
      line-height: unset;
    }
    .info-card {
      &__info {
        &--icon {
          i {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #e9fcf2;
            font-size: 24px;
            color: ${theme.palette.primary.main};
          }
        }

        &--wrapper {
          margin-top: 12px;
          display: flex;
          flex-direction: row;

          @media (min-width: ${theme.breakpoints.md}px) {
            flex-direction: column;

            & > div + div {
              margin-left: 16px;
            }
          }
        }
      }

      &__text {
        &--wrapper {
          margin-left: 12px;
          display: flex;
          flex-direction: column;
        }

        &--title {
          margin-bottom: ${theme.spacing[1]}px;

          ${$titleColor
            ? css`
                color: ${$titleColor};
              `
            : ''}
        }

        &--description {
          color: ${$descriptionColor
            ? $descriptionColor
            : theme.palette.neutral[500]};
        }
      }
    }
  `,
)

export default Root
