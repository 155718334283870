import { CoveragesCode } from '@azos/core'

export enum DashboardSliderCardsType {
  DOWNLOAD_APP = 'download_app',
  ONBOARDING = 'onboarding',
  BENEFICIARIES = 'beneficiaries',
  GUARDIANS = 'guardians',
  OPEN_INVOICE = 'open_invoice',
  CHANGE_PAYMENT_TYPE = 'change_payment_type',
  READJUSTMENT_POLICY = 'readjustment_policy',
}

export enum DashboardSliderCardsIconType {
  CIRCLE = 'circle',
  ONBOARDING = 'onboarding',
  INVOICE = 'invoice',
  NUMBER = 'number',
}

export type DashboardSliderCardsIcon = {
  type: DashboardSliderCardsIconType
  iconName: string
  iconColor: string
  iconBackgroundColor: string
  colorProgressIndicator: string | null
  valueProgressIndicator: number | null
  badgeColor: string | null
  badgeNumber: number | null
}

export enum DashboardSliderCardsRedirectType {
  DIRECT = 'direct',
  LIST = 'list',
}

export type DashboardSliderCardsRedirectPoliciesType = {
  policyId: string
  valueLabel: string
  coveragesLabel: string
  badgeColor: string
  badgeLabel: string
}

export type DashboardSliderCardsRedirect = {
  type: DashboardSliderCardsRedirectType
  title: string | null
  description: string | null
  basePathWeb: string | null
  basePathApp: string | null
  tab: string | null
  policies: DashboardSliderCardsRedirectPoliciesType[]
}

export type DashboardSliderCardsResponse = {
  type: DashboardSliderCardsType
  title: string
  description: string
  backgroundCard: string | null
  titleColor: string | null
  descriptionColor?: string | null
  icon: DashboardSliderCardsIcon
  redirect: DashboardSliderCardsRedirect
}

export type CoverageModalImage = {
  large?: {
    url: string
  }
  thumbnail?: {
    url: string
  }
  small?: {
    url: string
  }
  medium?: {
    url: string
  }
}

export type CoverageModalDetailsResponse = {
  id: number
  icon: string
  description: string
}

export type CoverageGracePeriodResponse = {
  id: number
  icon: string
  description: string
}

export type CoverageModalFunctionalitiesResponse = {
  id: number
  icon: string
  description: string
}

export type CoverageModalLinksResponse = {
  id: number
  title: string
  url: string
  icon: string | null
  isGeneralCondition: boolean
}

export type CoverageModalResponse = {
  icon: string
  title: string
  description: string
  coverageValue: string
  coverageCode: CoveragesCode
  image: CoverageModalImage
  coverageDetails: CoverageModalDetailsResponse[]
  coverageGracePeriod: CoverageGracePeriodResponse[]
  functionalities: CoverageModalFunctionalitiesResponse[]
  coverageLinks: CoverageModalLinksResponse[]
}

export type CoverageDetailsProductCard = {
  iconName: string
  title: string
  order: number
  description: string | null
}

export type GetCoverageDetailsResponse = {
  iconName: string
  title: string
  description: string
  buttonText: string | null
  productCode: string
  productCards: CoverageDetailsProductCard[]
  image: string
}
