export type IuguConfig = {
  url: string
  id: string
  testMode: boolean
}

export const iuguConfig: IuguConfig = {
  url: 'https://js.iugu.com/v2',
  id: process.env.REACT_APP_IUGU_ID || 'D0753E5661D340FBBAB4C7888FFCDB1B',
  testMode: process.env.REACT_APP_IUGU_MODE !== 'production',
}
