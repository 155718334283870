import HttpStatusCode from 'http-status-codes'

import { HttpClient } from '../../../data/protocols'
import { UnexpectedError } from '../../../domain/errors'
import {
  CreateIllnesses,
  CreateQuestionnaire,
  LoadQuestionnaire,
} from '../../../domain/usecases'

export type IDPSRepository = LoadQuestionnaire &
  CreateQuestionnaire &
  CreateIllnesses

export class DPSRepository implements IDPSRepository {
  constructor(private readonly httpClient: HttpClient) {}

  async loadQuestionnaire(): Promise<LoadQuestionnaire.Response> {
    const httpResponse = await this.httpClient.request({
      url: '/dps_questionnaire',
      method: 'get',
    })

    const response = httpResponse.data

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
        return response as LoadQuestionnaire.Response
      case HttpStatusCode.NOT_FOUND:
        return { questionnaire_order: [], questions: {} }
      default:
        throw new UnexpectedError()
    }
  }

  async createQuestionnaire(
    params: CreateQuestionnaire.Params,
  ): Promise<CreateQuestionnaire.Response> {
    const httpResponse = await this.httpClient.request({
      url: '/dps_questionnaire',
      method: 'post',
      body: params,
    })

    const response = httpResponse.data

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
      case HttpStatusCode.CREATED:
        return response as CreateQuestionnaire.Response
      default:
        throw new UnexpectedError()
    }
  }

  async createIllnesses(
    params: CreateIllnesses.Params,
  ): Promise<CreateIllnesses.Response> {
    const httpResponse = await this.httpClient.request({
      url: '/dps_illnesses',
      method: 'post',
      body: params,
    })

    const response = httpResponse.data

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
      case HttpStatusCode.CREATED:
        return response as CreateQuestionnaire.Response
      default:
        throw new UnexpectedError()
    }
  }
}
