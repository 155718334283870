import { IUsersRepository } from '../../../../data/repositories'
import { UserProfile } from '../../../../domain'
import { Services } from '../../../protocols'

type Response = UserProfile

export type ILoadUserProfileService = Services<void, Response>

export class LoadUserProfileService implements ILoadUserProfileService {
  constructor(private readonly usersRepository: IUsersRepository) {}

  async execute(): Promise<Response> {
    const user = await this.usersRepository.loadUserProfile()

    return user
  }
}
