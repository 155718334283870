import React from 'react'

import { ProgressBar } from '@azos/shared'
import { Text } from '@azos/shared/src/components/v2'
import { WizardListAction } from '@data/models'
import { OnboardingActionDetailsCard } from '@presentation/components/organisms/OnboardingManagement/components'
import { useOnboardingWizard } from '@presentation/providers/OnboardingWizardProvider'

import { ModalBuyPolicies } from '../../ModalBuyPolicies'
import { OnboardingAction } from '../OnboardingAction'
import { OnboardingTopicProps } from './OnboardingTopic.props'
import { Root } from './OnboardingTopic.styles'

const OnboardingActions: React.FCC<OnboardingTopicProps> = ({
  action,
  onMainAction,
  onSecondaryAction,
}) => {
  const { updateSummary } = useOnboardingWizard()

  const [isOnboardingActionDetailsOpen, setIsOnboardingActionDetailsOpen] =
    React.useState(false)
  const [isModalBuyPoliciesOpen, setIsModalBuyPoliciesOpen] =
    React.useState(false)
  const [selectedAction, setSelectedAction] =
    React.useState<WizardListAction | null>()

  const handleClick = async (
    title: string,
    locked: boolean,
    actionItem?: WizardListAction,
  ) => {
    if (!actionItem) {
      if (locked) {
        setIsModalBuyPoliciesOpen(true)
        return
      }

      return onMainAction(action.tag, title)
    }

    setIsOnboardingActionDetailsOpen(true)
    setSelectedAction(actionItem)

    if (!actionItem.executed) {
      await updateSummary({
        entity: action.tag,
        action: actionItem.tag,
      })
    }
  }

  const handleRedirect = (subAction: string, executed: boolean) => {
    onSecondaryAction(action.tag, subAction, executed)
    setIsOnboardingActionDetailsOpen(false)
  }

  return (
    <>
      <Root>
        <div className="onboarding-topic__header">
          <div className="onboarding-topic__header--icon">
            <i className={action.icon} />
          </div>
          <div className="onboarding-topic__header--content">
            <Text variant="body2" className="onboarding-topic__header--title">
              {action.title}
            </Text>
            <ProgressBar
              percentage={action.total}
              bar={{ barColor: action.color }}
            />
          </div>
        </div>
        <div className="onboarding-topic__instructions-list">
          {action.actions.map(actionItem => (
            <OnboardingAction
              key={actionItem.tag}
              action={actionItem}
              onClick={handleClick}
              isMainAction={false}
            />
          ))}
        </div>

        {action.mainAction && (
          <div className="onboarding-topic__actions-list">
            <Text
              variant="body2"
              className="onboarding-topic__actions-list--text"
            >
              {action.mainAction.title}
            </Text>

            {action.mainAction.actions.map(actionItem => (
              <OnboardingAction
                key={actionItem.tag}
                action={actionItem}
                locked={actionItem.locked}
                onClick={handleClick}
                isMainAction
              />
            ))}
          </div>
        )}
      </Root>
      <OnboardingActionDetailsCard
        tag={action.tag}
        open={isOnboardingActionDetailsOpen}
        onClose={() => setIsOnboardingActionDetailsOpen(false)}
        action={selectedAction}
        onManualComplete={handleClick}
        onRedirect={handleRedirect}
      />
      <ModalBuyPolicies
        open={isModalBuyPoliciesOpen}
        onClose={() => setIsModalBuyPoliciesOpen(false)}
        title="Beneficiários"
        description="A funcionalidade Beneficiários é exclusiva para que tem uma apólice com a cobertura de Seguro de Vida."
      />
    </>
  )
}

export default OnboardingActions
