import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled('div')(
  ({ theme }) => css`
    padding: 0 16px 16px 16px;

    .responsible-broker-phone-modal {
      &__content {
        display: grid;
        gap: ${theme.spacing[2]}px;
      }

      &__button-select {
        border: none;
        background: ${theme.palette.neutral[100]};
        padding: 12px 18px;

        .button-select__content {
          align-items: center;
        }

        i {
          font-size: 1.5rem;
        }
      }
    }
  `,
)
