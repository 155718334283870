import React from 'react'

import { Spinner } from '../Spinner'
import { Root } from './Loading.styles'

const Loading: React.FCC = () => {
  return (
    <Root data-testid="loader">
      <div className="loading-spinner__backdrop">
        <Spinner size={48} backgroundColor="rgba(0, 0, 0, 0.2)" />
      </div>
    </Root>
  )
}

export default Loading
