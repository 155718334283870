import {
  ExitPolicyCancelService,
  SelectPolicyCancelReasonService,
  ViewPolicyCancelReasonService,
  ViewPolicyCancelService,
  ClickButtonPolicyCancelService,
  ViewReasonCancellationService,
  ActionsReasonCancellationService,
  ViewReduceValueService,
  ActionsReduceValueService,
  ViewCancellationValidationService,
  ActionsCancellationValidationService,
  ViewFeedbackModalService,
  ActionsFeedbackModalService,
  ClickTalkAzosCancellation,
} from '../../../../../../data/services'
import { makeMixpanelDispatcherEvent } from '../../../../infra'

export const makeViewPolicyCancelService = () =>
  new ViewPolicyCancelService(makeMixpanelDispatcherEvent())

export const makeViewPolicyCancelReasonService = () =>
  new ViewPolicyCancelReasonService(makeMixpanelDispatcherEvent())

export const makeSelectPolicyCancelReasonService = () =>
  new SelectPolicyCancelReasonService(makeMixpanelDispatcherEvent())

export const makeExitPolicyCancelService = () =>
  new ExitPolicyCancelService(makeMixpanelDispatcherEvent())

export const makeClickButtonPolicyCancelService = () =>
  new ClickButtonPolicyCancelService(makeMixpanelDispatcherEvent())

export const makeViewReasonCancellationService = () =>
  new ViewReasonCancellationService(makeMixpanelDispatcherEvent())

export const makeActionsReasonCancellationService = () =>
  new ActionsReasonCancellationService(makeMixpanelDispatcherEvent())

export const makeViewReduceValueService = () =>
  new ViewReduceValueService(makeMixpanelDispatcherEvent())

export const makeActionsReduceValueService = () =>
  new ActionsReduceValueService(makeMixpanelDispatcherEvent())

export const makeViewCancellationValidationService = () =>
  new ViewCancellationValidationService(makeMixpanelDispatcherEvent())

export const makeActionsCancellationValidationService = () =>
  new ActionsCancellationValidationService(makeMixpanelDispatcherEvent())

export const makeViewFeedbackModalService = () =>
  new ViewFeedbackModalService(makeMixpanelDispatcherEvent())

export const makeActionsFeedbackModalService = () =>
  new ActionsFeedbackModalService(makeMixpanelDispatcherEvent())

export const makeClickTalkAzosCancellationService = () =>
  new ClickTalkAzosCancellation(makeMixpanelDispatcherEvent())
