import { Services } from '@azos/core'
import { IPoliciesRepository } from '@data/repositories'
import { CancelPolicyUseCase } from '@domain/usecases'

type Params = CancelPolicyUseCase.Params
type Response = CancelPolicyUseCase.Response

export type ICancelPolicyService = Services<Params, Response>

export class CancelPolicyService implements ICancelPolicyService {
  constructor(private readonly policiesRepository: IPoliciesRepository) {}

  async execute(params: Params): Promise<Response> {
    return await this.policiesRepository.cancelPolicy(params)
  }
}
