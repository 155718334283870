import React from 'react'

import { useRouter } from 'next/router'

import { useWizard } from '../../hooks'

const WizardRouter: React.FCC = ({ children }) => {
  const router = useRouter()
  const { step, max, setStep } = useWizard()
  const [loaded, setLoaded] = React.useState<boolean>(false)

  const stepByQuery = React.useMemo<number>(() => {
    const stepQs = 1
    const stepNum = stepQs > max ? 1 : stepQs
    return Math.max(Math.min(stepNum, max), 1)
  }, [max])

  React.useEffect(() => {
    if (!router.isReady || loaded) return

    const stepValue = stepByQuery - 1
    setLoaded(true)

    if (stepValue !== step) {
      setStep(stepValue)
    }
  }, [loaded, router.isReady, setStep, step, stepByQuery])

  React.useEffect(() => {
    if (!router.isReady || !loaded) return

    const url = router.asPath
    const stepValue = step + 1
    const route = `${router.pathname}?s=${stepValue}`

    if (url === route) return

    router.replace(url, route, {
      shallow: true,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded, router.isReady, step])

  return <>{children}</>
}

export default WizardRouter
