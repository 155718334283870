import { css, styled } from '../../styles'

export const Root = styled('div')(
  ({ theme }) => css`
    width: 100%;
    max-width: 440px;
    padding: ${theme.spacing[3]}px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;

    @media (min-width: 600px) {
      padding: 0;
    }

    .chat-avatar-card {
      width: 100%;
      height: auto;
      background: ${theme.palette.neutral[50]};
      display: flex;
      flex-direction: column;
      padding: 24px;
      border-radius: none;

      &__wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: start;
        position: relative;
      }

      &__specialist {
        margin-bottom: ${theme.spacing[4]}px;
        margin-left: ${theme.spacing[3]}px;

        @media (min-width: ${theme.breakpoints.md}px) {
          position: absolute;
          left: -140px;
          top: 0px;
        }
      }
      &__arrow {
        position: relative;

        &:before {
          content: '';
          position: absolute;
          width: 20px;
          height: 20px;
          left: 2px;
          top: -34px;
          background: ${theme.palette.neutral[50]};
          transform: rotate(45deg);
        }
      }

      &__title {
        font-weight: 600;
      }

      &__subtitle {
        font-weight: 600;
      }

      &__description {
        margin: 12px 0 0 0;
        line-height: 150%;
      }

      &__button-group {
        margin-top: 24px;
        display: flex;
        flex-direction: column;
      }

      @media (min-width: ${theme.breakpoints.md}px) {
        &__arrow {
          position: relative;

          &:before {
            content: ' ';
            position: absolute;
            width: 20px;
            height: 20px;
            left: -35px;
            top: -4px;
            background: ${theme.palette.neutral[50]};
            transform: rotate(45deg);
          }
        }
      }
    }
  `,
)

export default Root
