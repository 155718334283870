import { SimulationEditCoverage } from '../../../domain/models'
import { Dispatcher, Service } from '../../protocols'

export type ISimulationEditCoverageService = Service<SimulationEditCoverage>

/**
 * Edit coverage simulation
 *
 * @category Simulation
 */
export class SimulationEditCoverageService
  implements ISimulationEditCoverageService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @param {SimulationEditCoverage} data
   * @function
   */
  execute(data: SimulationEditCoverage): void {
    this.dispatcher.execute('edit_coverage_simulation', data)
  }
}
