import React from 'react'

import { Button, Modal, Typography } from '@Azos-Seguros/apollo'
import analytics from '@azos/analytics'
import { ClickContactBrokerHelpType } from '@azos/analytics/src/domain/models'
import { useMediaQuery, withModal } from '@azos/shared'
import copy from 'copy-to-clipboard'

import { ResponsibleBrokerEmailModal } from '../../ResponsibleBrokerEmailModal'
import { ResponsibleBrokerPhoneModal } from '../../ResponsibleBrokerPhoneModal'
import { ProtectedBrokerHelpModalProps } from './ProtectedBrokerHelpModal.props'
import { Root } from './ProtectedBrokerHelpModal.styles'

const ProtectedBrokerHelpModal: React.VFC<ProtectedBrokerHelpModalProps> = ({
  broker,
  user,
  isGuardian,
  ...props
}) => {
  const { isMobile } = useMediaQuery()
  const timer = React.useRef<NodeJS.Timeout | null>(null)

  React.useEffect(() => {
    return () => {
      if (timer.current) clearTimeout(timer.current)
    }
  }, [])

  React.useEffect(() => {
    analytics.dashboard.guardian.viewRequestBrokerProtectedHelp.execute({
      isInsured: !!user?.isInsured,
      isGuardian,
    })
  }, [isGuardian, user?.isInsured])

  const [isPhoneModalOpen, setIsPhoneModalOpen] = React.useState(false)
  const [isEmailModalOpen, setIsEmailModalOpen] = React.useState(false)

  const [isShowingSuccess, setIsShowingSuccess] = React.useState(false)

  const handleValueClick = (value: string) => {
    setIsShowingSuccess(true)
    copy(value)

    timer.current = setTimeout(() => {
      setIsShowingSuccess(false)
    }, 3000)
  }

  const handlePhoneClick = (value: string) => {
    analytics.dashboard.guardian.clickContactBrokerHelp.execute({
      isInsured: !!user?.isInsured,
      isGuardian,
      tipo: ClickContactBrokerHelpType.CALL,
    })
    if (isMobile) {
      setIsPhoneModalOpen(true)
    } else {
      handleValueClick(value)
    }
  }

  const handleEmailClick = (value: string) => {
    analytics.dashboard.guardian.clickContactBrokerHelp.execute({
      isInsured: !!user?.isInsured,
      isGuardian,
      tipo: ClickContactBrokerHelpType.EMAIL,
    })
    if (isMobile) {
      setIsEmailModalOpen(true)
    } else {
      handleValueClick(value)
    }
  }

  return (
    <>
      <Modal {...props} maxWidth="392px" noGutter>
        <Root>
          <Typography
            variant="h4"
            className="protected-broker-help-modal__title"
          >
            Solicitar <strong>ajuda</strong> ao corretor
          </Typography>
          <Typography
            variant="body3"
            className="protected-broker-help-modal__description"
          >
            Para solicitar ajuda, acione o corretor ou corretora do seu
            protegido ou protegida através dos contatos abaixo:
          </Typography>

          {broker && (
            <div className="protected-broker-help-modal__broker">
              <div className="protected-broker-help-modal__broker--header">
                <i className="icon-briefcase" />
                <Typography variant="bodyBold3">{broker.name}</Typography>
              </div>
              <div className="protected-broker-help-modal__broker--actions">
                <div
                  className="protected-broker-help-modal__broker--actions-item"
                  role="button"
                  onClick={() => handlePhoneClick(broker.phone)}
                >
                  <i className="icon-dial-phone" />
                  <Typography variant="captionBold">Ligar</Typography>
                </div>

                <div
                  className="protected-broker-help-modal__broker--actions-item"
                  role="button"
                  onClick={() => handleEmailClick(broker.email)}
                >
                  <i className="icon-email" />
                  <Typography variant="captionBold">E-mail</Typography>
                </div>
              </div>
            </div>
          )}

          {isShowingSuccess && (
            <div className="protected-broker-help-modal__success">
              <div className="protected-broker-help-modal__success-header">
                <div className="protected-broker-help-modal__success-header-title">
                  <i className="icon-check" />

                  <Typography
                    variant="bodyBold3"
                    className="protected-broker-help-modal__success-title"
                  >
                    Copiado com sucesso!
                  </Typography>
                </div>
              </div>
            </div>
          )}

          <Button
            variant="outline"
            fullWidth
            type="button"
            onClick={props.onClose}
            className="protected-broker-help-modal__close-button"
          >
            Fechar
          </Button>
        </Root>
      </Modal>
      <ResponsibleBrokerPhoneModal
        open={isPhoneModalOpen}
        phone={broker?.phone}
        onClose={() => setIsPhoneModalOpen(false)}
      />
      <ResponsibleBrokerEmailModal
        open={isEmailModalOpen}
        email={broker?.email}
        onClose={() => setIsEmailModalOpen(false)}
      />
    </>
  )
}

export default withModal<ProtectedBrokerHelpModalProps>(
  ProtectedBrokerHelpModal,
)
