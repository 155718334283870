import { LoadingPixPayment } from '../../../../../domain/models'
import { Dispatcher, Service } from '../../../../protocols'

export type IUpsellLoadingPixPaymentService = Service<LoadingPixPayment>

/**
 * Loading Pix Payment
 *
 * @category Upsell
 */
export class UpsellLoadingPixPaymentService
  implements IUpsellLoadingPixPaymentService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   * @param {LoadingPixPayment} data
   */
  execute(data: LoadingPixPayment): void {
    this.dispatcher.execute(
      'Upsell - Tempo de carregamento PIX em checkout',
      data,
    )
  }
}
