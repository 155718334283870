import { css } from '@azos/shared'
import styled from '@emotion/styled'
import { Form as StyledForm } from 'formik'

export const Form = styled(StyledForm)(
  ({ theme }) => css`
    .user-edit-personal-data-form {
      &__content {
        padding: ${theme.spacing[4]}px ${theme.spacing[4]}px
          ${theme.spacing[4]}px;
        background: ${theme.palette.neutral[100]};
      }

      &__subtitle {
        display: block;
        margin-top: ${theme.spacing[5]}px;
        margin-bottom: ${theme.spacing[2]}px;
      }

      &__input {
        margin-top: ${theme.spacing[4]}px;
        padding: 12px ${theme.spacing[3]}px 0;

        input {
          padding-bottom: 12px;
          border-bottom: 2px solid ${theme.palette.neutral[200]};
        }
      }

      &__select {
        margin-top: ${theme.spacing[4]}px;
        padding: 12px 16px 0;
      }

      &__input-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: ${theme.spacing[4]}px;
      }

      &__submit {
        &-wrapper {
          position: sticky;
          bottom: 0;
          padding: ${theme.spacing[4]}px ${theme.spacing[4]}px
            ${theme.spacing[6]}px;
          background: ${theme.palette.neutral[50]};
        }

        &-button {
          background: ${theme.palette.neutral[500]};
          height: 48px;
          border: none;

          &:hover {
            background: ${theme.palette.neutral[500]};
          }

          &.disabled {
            background: ${theme.palette.neutral[100]};
            color: ${theme.palette.neutral[300]};
          }
        }
      }
    }
  `,
)
