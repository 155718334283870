export enum ReferralUserType {
  B2C = 'B2C',
  BROKER = 'BROKER',
}

/**
 * Referral page View
 *
 * @alias ReferralPageView
 */

export type ReferralPageView = {
  /**
   * User Type
   */
  type: ReferralUserType
}
