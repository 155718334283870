import React from 'react'
import ReactDOM from 'react-dom'
import { useTransition } from 'react-spring'

import { Utils } from '@azos/shared'
import { NoSsr } from '@mui/material'

import { ModalProps } from '.'
import { useWindowSize, usePrevious } from '../../hooks'
import { Content, Overlay, Root } from './Modal.styles'

const TRANSITION_CONFIG = {
  mass: 1,
  tension: 210,
  friction: 26,
}

const DRAWER_OVERLAY_TRANSITION = {
  from: { opacity: 0 },
  enter: { opacity: 1 },
  leave: { opacity: 0 },
}

const DRAWER_CONTENT_TRANSITION = {
  from: { transform: 'translateX(520px)' },
  enter: { transform: 'translateX(0px)' },
  leave: { transform: 'translateX(520px)' },
}

const DRAWER_BOTTOM_CONTENT_TRANSITION = {
  from: { transform: 'translateY(520px)' },
  enter: { transform: 'translateY(0px)' },
  leave: { transform: 'translateY(520px)' },
}

const Modal: React.FCC<ModalProps> = ({
  open = false,
  children,
  title,
  subtitle,
  maxWidth,
  noGutter = false,
  variant = 'modal',
  className,
  closeOnOverlayClick = true,
  closeOnEsc = true,
  parentName,
  preventScroll = true,
  showCloseButton = true,
  showBackButton = false,
  showHeader = true,
  backgroundColor = '#fff',
  exitBgColor = '#fff',
  onClose,
}) => {
  if (typeof window === 'undefined') {
    return null
  }

  const modalRef = React.useRef<HTMLDivElement>(null)
  const { width } = useWindowSize()

  const timer = React.useRef<NodeJS.Timeout>()
  const prevOpen = usePrevious(open)

  const [scrollY, setScrollY] = React.useState(0)

  const modalVariant = React.useMemo(() => {
    if (width >= 768 && variant === 'bottomDrawer') return 'drawer'
    return variant
  }, [width, variant])

  const showModal = React.useCallback(
    (value: boolean) => {
      const body = window.document.body

      if (value) {
        setScrollY(window.pageYOffset)

        if (body.scrollHeight > window.innerHeight && width >= 768) {
          body.style.paddingRight = '15px'
        }

        body.style.overflow = 'hidden'
        body.style.top = `-${window.pageYOffset}px`
        body.style.position = 'fixed'
        body.style.width = '100%'
        body.style.height = '100vh'
      } else {
        body.style.overflow = ''
        body.style.paddingRight = ''
        body.style.position = ''
        body.style.width = ''
        body.style.height = ''
        body.style.top = ''

        window.scrollTo(0, scrollY)
      }
    },
    [scrollY, width],
  )

  React.useEffect(() => {
    if (preventScroll && (open || prevOpen !== undefined)) {
      timer.current = setTimeout(() => {
        const modals = document.querySelectorAll('[role="dialog"]')
        const hasMoreModals = modals.length > 1

        if (!hasMoreModals) {
          showModal(open)
        }
      }, 50)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, preventScroll])

  React.useEffect(() => {
    return () => {
      if (timer.current) clearTimeout(timer.current)
      const modals = document.querySelectorAll('[role="dialog"]')
      const hasModal = modals.length

      if (!hasModal) showModal(false)
    }
  }, [showModal])

  const stopEventPropagation = (event: MouseEvent | KeyboardEvent) => {
    event.stopPropagation()
  }

  const handleModalFocus = () => {
    if (open) {
      modalRef.current?.focus()
    } else {
      const focusable = document.querySelectorAll('[role="dialog"]')
      const lastFocusable = focusable[focusable.length - 2] as
        | HTMLElement
        | undefined
      lastFocusable?.focus()
    }
  }

  const handleOverlayClick = (event: MouseEvent) => {
    stopEventPropagation(event)

    if (closeOnOverlayClick && onClose) {
      onClose()
    }
  }

  const handleEscKey = (event: KeyboardEvent) => {
    stopEventPropagation(event)

    if (event.key === 'Escape' && closeOnEsc && onClose) {
      onClose()
    }
  }

  const modalTransition = useTransition(open, {
    enter: { event: 1 },
    leave: { event: 0 },
    config: TRANSITION_CONFIG,
    onStart: handleModalFocus,
  })

  const overlayTransition = useTransition(open, {
    ...DRAWER_OVERLAY_TRANSITION,
    config: TRANSITION_CONFIG,
  })

  const contentTransition = useTransition(open, {
    ...(modalVariant === 'drawer'
      ? DRAWER_CONTENT_TRANSITION
      : modalVariant === 'bottomDrawer'
      ? DRAWER_BOTTOM_CONTENT_TRANSITION
      : DRAWER_OVERLAY_TRANSITION),
    config: TRANSITION_CONFIG,
  })

  return ReactDOM.createPortal(
    modalTransition(
      (styles, item) =>
        item && (
          <NoSsr>
            <Root
              className={className}
              tabIndex="-1"
              role="dialog"
              ref={modalRef}
              style={styles}
              onClick={handleOverlayClick}
              onKeyUp={handleEscKey}
              $variant={modalVariant}
              data-action={Utils.dataNames.renderDataName('modal', parentName)}
            >
              {overlayTransition(
                (overlayStyles, overlay) =>
                  overlay && <Overlay style={overlayStyles} />,
              )}

              {contentTransition(
                (contentStyles, content) =>
                  content && (
                    <Content
                      style={contentStyles}
                      className="modal-content"
                      onClick={stopEventPropagation}
                      $variant={modalVariant}
                      $showBackButton={showBackButton}
                      $maxWidth={maxWidth}
                      $backgroundColor={backgroundColor}
                      $exitBgColor={exitBgColor}
                      $noGutter={noGutter}
                    >
                      {showHeader && (
                        <div className="modal-header">
                          {showBackButton && (
                            <button
                              type="button"
                              onClick={onClose}
                              className="exit-button"
                            >
                              <span className="icon-arrow-left" />
                            </button>
                          )}

                          {(title || subtitle) && (
                            <div>
                              {title && <p className="title">{title}</p>}
                              {subtitle && (
                                <p className="subtitle">{subtitle}</p>
                              )}
                            </div>
                          )}

                          {showCloseButton && !showBackButton && (
                            <button
                              type="button"
                              onClick={onClose}
                              className="exit-button"
                            >
                              <span className="icon-exit" />
                            </button>
                          )}
                        </div>
                      )}

                      <div className="modal-content-wrapper">{children}</div>
                    </Content>
                  ),
              )}
            </Root>
          </NoSsr>
        ),
    ),
    window.document.body,
  )
}

export default Modal
