import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled('div')(
  ({ theme }) => css`
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;

    .guardian-instructions-modal {
      &__header {
        padding: ${theme.spacing[4]}px;
        position: sticky;
        top: 0;
        z-index: ${theme.zIndex.header};
        background: ${theme.palette.neutral[50]};

        &--exit-button {
          cursor: pointer;
          background: ${theme.palette.neutral[100]};
          border: 0;
          border-radius: 50%;
          font-size: 1.5rem;
          padding: 0;
          position: relative;
          height: 48px;
          width: 48px;
          position: absolute;
          top: 24px;
          right: 24px;

          display: flex;
          align-items: center;
          justify-content: center;

          i {
            font-size: 1.5rem;
          }
        }

        .icon-content {
          font-size: 2.5rem;
          display: block;
          margin-top: 88px;
        }

        &--title {
          margin-top: 12px;

          label {
            color: ${theme.palette.primary.main};
          }
        }

        &--description {
          margin-top: ${theme.spacing[2]}px;
        }
      }

      &__instructions {
        display: flex;
        flex-direction: column;
        flex: 1;

        padding: 0 ${theme.spacing[4]}px ${theme.spacing[4]}px;

        & > * + * {
          margin-top: ${theme.spacing[5]}px;
        }

        &--item {
          display: flex;
          flex-direction: column;

          i {
            font-size: 1.5rem;
            display: block;
            margin-bottom: ${theme.spacing[2]}px;
          }

          &-description {
            margin-top: ${theme.spacing[2]}px;
            color: ${theme.palette.neutral[400]};
          }
        }
      }

      &__button {
        height: 48px;
      }

      &__footer {
        position: sticky;
        bottom: 0px;

        width: 100%;
        background: ${theme.palette.neutral[50]};
        z-index: ${theme.zIndex.notification};
        box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.08);
        padding: ${theme.spacing[4]}px;
      }
    }
  `,
)
