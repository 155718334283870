import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled('div')(
  ({ theme }) => css`
    .sensitive-data-modal {
      &__title {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &__title-content {
        display: block;
        margin: ${theme.spacing[4]}px 0 ${theme.spacing[3]}px;
      }

      &__description {
        display: block;
        margin-bottom: ${theme.spacing[4]}px;
      }

      &__content {
        display: grid;
        gap: ${theme.spacing[4]}px;
        margin-bottom: ${theme.spacing[2]}px;
      }

      &__copy-content {
        display: grid;
        gap: ${theme.spacing[2]}px;
      }

      &__card-copy {
        background: ${theme.palette.neutral[100]};
        border: none;
        justify-content: space-between;
        font-size: 0.875rem;
        padding: 14px;

        .card_copy_text__text-content {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .card_copy_text__value {
          flex: 1;
          text-align: start;
        }
      }

      &__button-select {
        background: ${theme.palette.neutral[100]};
        border: none;
      }

      &__button {
        @media (min-width: ${theme.breakpoints.sm}px) {
          height: 48px;
          display: block;
          background: ${theme.palette.neutral[500]};
          border: none;
        }
        display: none;
      }
    }
  `,
)
