import React from 'react'

import analytics from '@azos/analytics'
import { ReferralUserType } from '@azos/analytics/src/domain/models'
import { Modal, useTheme, PageHeader, withModal } from '@azos/shared'

import { BrokerReferralContent, InsuredReferralContent } from './components'
import { B2BHeaderContent, B2CHeaderContent } from './ModalReferral.config'
import { ModalReferralProps } from './ModalReferral.props'
import { Root } from './ModalReferral.styles'

const ModalReferral: React.VFC<ModalReferralProps> = ({
  open,
  onClose,
  utm,
  url,
  whatsAppMessage,
  isBroker = false,
}) => {
  const theme = useTheme()

  const [userTypeContent, setUserTypeContent] = React.useState(B2CHeaderContent)

  React.useEffect(() => {
    analytics.dashboard.referral.view.referralPageView.execute({
      type: isBroker ? ReferralUserType.BROKER : ReferralUserType.B2C,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    setUserTypeContent(isBroker ? B2BHeaderContent : B2CHeaderContent)
  }, [isBroker])

  return (
    <Modal
      variant="drawer"
      backgroundColor={theme.palette.neutral[400]}
      exitBgColor={theme.palette.neutral[300]}
      noGutter
      open={open}
      onClose={onClose}
    >
      <Root>
        <PageHeader
          icon="icon-share-default-40"
          title={userTypeContent.title}
          description={userTypeContent.description}
        />

        <div className="modal-referral__content">
          {isBroker ? (
            <BrokerReferralContent />
          ) : (
            <InsuredReferralContent
              utm={utm}
              url={url}
              whatsAppMessage={whatsAppMessage}
            />
          )}
        </div>
      </Root>
    </Modal>
  )
}

export default withModal<ModalReferralProps>(ModalReferral)
