import ArrowLeft from './ArrowLeft'
import Azos from './Azos'
import AzosCorretores from './AzosCorretores'
import AzosLogo from './AzosLogo'
import AzosPrimary from './AzosPrimary'
import AzosWhite from './AzosWhite'
import Calendar from './Calendar'
import Check from './Check'
import * as Checkout from './Checkout'
import ChevronRight from './ChevronRight'
import Close from './Close'
import Heart from './Heart'
import Page404 from './Page404'
import PolicyCancelError from './PolicyCancelError'
import PolicyReadjustment from './PolicyReadjustment'
import PolicyReduceDuotone from './PolicyReduceDuotone'
import SignOut from './SignOut'
import User from './User'
import Warning from './Warning'

export const Icon = {
  Azos,
  AzosCorretores,
  AzosLogo,
  AzosPrimary,
  AzosWhite,
  ArrowLeft,
  Calendar,
  Check,
  Checkout,
  ChevronRight,
  Close,
  Heart,
  Page404,
  SignOut,
  PolicyCancelError,
  PolicyReduceDuotone,
  PolicyReadjustment,
  User,
  Warning,
}
