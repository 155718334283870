import React from 'react'

import { AppProvider } from '../providers'

export function withAuth(WrappedComponent: any) {
  // eslint-disable-next-line react/display-name
  return (props: any) => (
    <AppProvider>
      <WrappedComponent {...props} />
    </AppProvider>
  )
}
