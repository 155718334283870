import { css } from '@emotion/react'
import { styled } from '@mui/material/styles'

import { Utils } from '../../utils'

interface CounterStyleProps {
  $value: number
  $min: number
  $max: number
}

export const Root = styled(
  'div',
  Utils.transient.transientStyleProps,
)<CounterStyleProps>(
  () => css`
    display: flex;
    align-items: center;
    max-width: max-content;

    .value {
      min-width: 48px;
      margin: 0 14px;
      font-size: 18px;
      font-weight: 600;
      text-align: center;
    }

    button {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0;
      background: transparent;
      padding: 0;
      height: 23px;
      width: 23px;

      &:disabled {
        cursor: not-allowed;
      }

      span {
        font-size: 23px;
        color: #090a0b;
      }
    }
  `,
)
