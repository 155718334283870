import { ClickReadjustmentBadge } from '../../../../../domain/models'
import { Dispatcher, Service } from '../../../../protocols'

export type IClickReadjustmentBadgeService = Service<ClickReadjustmentBadge>

/**
 * Click Readjustment Badge
 *
 * @category Policies
 */
export class ClickReadjustmentBadgeService
  implements IClickReadjustmentBadgeService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   * @param {ClickReadjustmentBadge} data
   */
  execute(data: ClickReadjustmentBadge): void {
    this.dispatcher.execute(
      'Area logada web - Clique em "Reajuste X dias" (badge) na tela de apólice',
      data,
    )
  }
}
