import { css, styled } from '@azos/shared'

export const Root = styled('div')(
  ({ theme }) => css`
    padding: ${theme.spacing[5]}px;

    .protected-broker-help-modal {
      &__title {
        strong {
          color: ${theme.palette.primary.main};
        }
      }

      &__description {
        margin-top: ${theme.spacing[4]}px;
      }

      &__actions,
      &__close-button,
      &__broker,
      &__success {
        margin-top: ${theme.spacing[5]}px;
      }

      &__actions {
        &--item {
          padding: 14px 0;
          display: flex;
          justify-content: space-between;
          cursor: pointer;

          i {
            font-size: 1.5em;
          }

          .redirect-icon {
            color: ${theme.palette.neutral[300]};
          }

          &-content {
            display: flex;
            align-items: center;

            & > * + * {
              margin-left: ${theme.spacing[2]}px;
            }
          }
        }
      }

      &__broker {
        background: ${theme.palette.neutral[100]};

        &--header {
          display: flex;
          padding: ${theme.spacing[3]}px;

          i {
            font-size: 1.5rem;
          }

          & > * + * {
            margin-left: ${theme.spacing[3]}px;
          }
        }

        &--actions {
          border-top: 1px solid ${theme.palette.neutral[200]};

          display: flex;
          align-items: center;

          &-item {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: ${theme.spacing[3]}px;
            cursor: pointer;

            i {
              font-size: 1.5rem;
            }

            & > * + * {
              margin-left: ${theme.spacing[2]}px;
            }
          }
        }
      }

      &__success {
        background: ${theme.palette.primary[50]};
        padding: ${theme.spacing[3]}px;
        cursor: pointer;
        margin-bottom: ${theme.spacing[4]}px;

        i {
          font-size: 1.5rem;

          & > i {
            font-family: 'azos-icons';
            all: unset;
          }
        }

        &-header {
          display: flex;
          justify-content: space-between;

          &-title {
            display: flex;
            align-items: center;

            i {
              margin-right: ${theme.spacing[3]}px;
            }
          }
        }
      }
    }
  `,
)
