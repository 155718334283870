import React from 'react'

import { WizardItemConfig } from '../Wizard.props'
import {
  TransitionType,
  WizardContextData,
  WizardProviderProps,
} from './WizardProvider.props'

const defaultWizardItemConfig: WizardItemConfig = {
  disableControls: false,
  disabledNextButton: false,
  isSubmittingForm: false,
  hideNextButton: false,
  requiredValid: true,
}

export const WizardContext = React.createContext({} as WizardContextData)

const WizardProvider: React.FCC<WizardProviderProps> = ({
  children,
  //
  step = 0,
  max,
  //
  onChange,
}) => {
  const [transition, setTransition] = React.useState<TransitionType>('forward')
  const [currentStep, setStep] = React.useState<number>(step)

  const [currentConfig, setCurrentConfig] = React.useState<WizardItemConfig>(
    defaultWizardItemConfig,
  )

  const isFirstStep = React.useMemo<boolean>(
    () => currentStep === 0,
    [currentStep],
  )
  const isLastStep = React.useMemo<boolean>(
    () => currentStep === max - 1,
    [max, currentStep],
  )

  const prevStep = React.useCallback(() => {
    setTransition('backward')
    setStep(state => Math.max(--state, 0))
  }, [])
  const nextStep = React.useCallback(() => {
    setTransition('forward')
    setStep(state => Math.min(++state, max - 1))
  }, [max])

  React.useEffect(() => {
    onChange?.(currentStep)
  }, [currentStep, onChange])

  const value: WizardContextData = {
    step: currentStep,
    max,
    //
    isFirstStep,
    isLastStep,
    //
    setStep,
    setConfig: (data: Partial<WizardItemConfig>) =>
      setCurrentConfig(Object.assign(defaultWizardItemConfig, data)),
    stepConfig: currentConfig,
    //
    transition,
    setTransition,
    isTransition: !!transition,
    //
    prevStep,
    nextStep,
  }

  return (
    <WizardContext.Provider value={value}>{children}</WizardContext.Provider>
  )
}

export default WizardProvider
