import { UpsellDurationTime } from '../../../../../domain/models'
import { Dispatcher, Service } from '../../../../protocols'

export type IUpsellDurationTimeService = Service<UpsellDurationTime>

/**
 * Upsell Duration Time
 *
 * @category Upsell
 */
export class UpsellDurationTimeService implements IUpsellDurationTimeService {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   * @param {UpsellDurationTime} data
   */
  execute(data: UpsellDurationTime): void {
    this.dispatcher.execute('Upsell - Tempo de duração no Upsell', data)
  }
}
