import { Dispatcher, Service } from '../../../protocols'

export type IClickUpdateChangesService = Service<void>

/**
 * Click Update Changes
 *
 * @category PersonalData
 */
export class ClickUpdateChangesService implements IClickUpdateChangesService {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @param
   * @function
   */
  execute(): void {
    this.dispatcher.execute(
      'Web - clique em salvar alterações na edição de dados cadastrais',
    )
  }
}
