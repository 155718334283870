import React from 'react'

import { Text } from '@azos/shared/src/components/v2'

import { Root } from './EmptyStateCard.styles'

const EmptyStateCard = () => {
  return (
    <Root>
      <div className="guardian__empty-state">
        <i className={'icon-search'} />
        <Text variant="body2" className="guardian__empty-state-title">
          Nada por aqui
        </Text>
        <Text variant="caption" className="guardian__empty-state-description">
          Você ainda não adicionou nenhum guardião ou guardiã.
        </Text>
      </div>
    </Root>
  )
}

export default EmptyStateCard
