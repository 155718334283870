import React from 'react'

export const referralConfig = {
  B2C: {
    others: {
      background: '/assets/png/referral_general_image.png',
      title: (
        <>
          <strong>Ajude a proteger</strong> também mais pessoas que você ama!
        </>
      ),
      description: (
        <>
          Convide <strong>toda a sua família</strong> para ter um seguro na Azos
          através do <strong>código abaixo</strong>:
        </>
      ),
    },
    married: {
      background: '/assets/png/referral_married_image.png',
      title: (
        <>
          <strong>Fortaleça ainda mais</strong> a proteção da sua família
        </>
      ),
      description: (
        <>
          Convide a sua <b>parceira</b> ou seu <b>parceiro</b> para também ter
          um seguro na Azos através do <b>código abaixo:</b>
        </>
      ),
    },
  },
  Broker: {
    others: {
      background: '/assets/png/referral_general_image.png',
      title: (
        <>
          <strong>Ajude a proteger</strong> também mais pessoas que você ama!
        </>
      ),
      description: (
        <>
          Convide <b>toda a sua família</b> para ter um seguro na Azos. Indique
          o seu corretor ou corretora:
        </>
      ),
    },
    married: {
      background: '/assets/png/referral_married_image.png',
      title: (
        <>
          <strong>Fortaleça ainda mais</strong> a proteção da sua família
        </>
      ),
      description: (
        <>
          Convide a sua <b>parceira</b> ou seu <b>parceiro</b> para também ter
          um seguro na Azos. Indique o seu corretor ou corretora:
        </>
      ),
    },
  },
}
