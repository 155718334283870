import React from 'react'

import { Modal, useTheme } from '@azos/shared'

import { PaymentHistoryItems } from '../PaymentHistoryItems'
import { PaymentHistoryModalProps } from './PaymentHistoryModal.props'
import { Root } from './PaymentHistoryModal.styles'

const PaymentHistoryModal: React.VFC<PaymentHistoryModalProps> = ({
  open,
  onClose,
  invoices,
}) => {
  const theme = useTheme()

  return (
    <Modal
      variant="drawer"
      open={open}
      backgroundColor={theme.palette.neutral[100]}
      onClose={onClose}
    >
      <Root>
        <header>
          <h3>Histórico de pagamento</h3>
        </header>

        <div className="payment-history-modal__wrapper">
          <PaymentHistoryItems invoices={invoices} showAll />
        </div>
      </Root>
    </Modal>
  )
}

export default PaymentHistoryModal
