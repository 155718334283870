import { onlyNumber } from './sanitizer'

export const validateCellphone = (value = ''): boolean => {
  const phone = value.replace(/[()\s[-]/g, '')
  return phone.length === 11
}

export const validatePhone = (value = ''): boolean => {
  if (value) {
    value = value.replace(/\D/g, '')

    if (!(value.length >= 10 && value.length <= 11)) return false

    if (parseInt(value.substring(2, 3)) !== 9) return false

    for (let n = 0; n < 10; n++) {
      if (
        value == new Array(11).join(n.toString()) ||
        value == new Array(12).join(n.toString())
      )
        return false
    }

    const codigosDDD = [
      11, 12, 13, 14, 15, 16, 17, 18, 19, 21, 22, 24, 27, 28, 31, 32, 33, 34,
      35, 37, 38, 41, 42, 43, 44, 45, 46, 47, 48, 49, 51, 53, 54, 55, 61, 62,
      64, 63, 65, 66, 67, 68, 69, 71, 73, 74, 75, 77, 79, 81, 82, 83, 84, 85,
      86, 87, 88, 89, 91, 92, 93, 94, 95, 96, 97, 98, 99,
    ]

    if (codigosDDD.indexOf(parseInt(value.substring(0, 2))) == -1) return false

    if (new Date().getFullYear() < 2017) return true

    if (
      value.length == 10 &&
      [2, 3, 4, 5, 7].indexOf(parseInt(value.substring(2, 3))) == -1
    )
      return false

    return true
  }
  return false
}

export const validateCep = (value = ''): boolean => {
  const validateCep = /^[0-9]{8}$/
  return validateCep.test(onlyNumber(value))
}

export const validateCpf = (cpf: string): boolean => {
  try {
    const formattedCpf = cpf.replace(/[^\d]+/g, '')
    let numbers
    let digits
    let sum
    let i
    let result
    let equalDigits
    equalDigits = 1
    if (formattedCpf.length < 11) {
      return false
    }
    for (i = 0; i < formattedCpf.length - 1; i += 1) {
      if (formattedCpf.charAt(i) !== formattedCpf.charAt(i + 1)) {
        equalDigits = 0
        break
      }
    }
    if (!equalDigits) {
      numbers = formattedCpf.substring(0, 9)
      digits = formattedCpf.substring(9)
      sum = 0
      for (i = 10; i > 1; i -= 1) {
        sum += Number(numbers.charAt(10 - i)) * i
      }
      result = sum % 11 < 2 ? 0 : 11 - (sum % 11)
      if (result !== parseInt(digits.charAt(0), 10)) {
        return false
      }
      numbers = formattedCpf.substring(0, 10)
      sum = 0
      for (i = 11; i > 1; i -= 1) {
        sum += Number(numbers.charAt(11 - i)) * i
      }
      result = sum % 11 < 2 ? 0 : 11 - (sum % 11)
      return result === parseInt(digits.charAt(1), 10)
    }
    return false
  } catch (e) {
    return false
  }
}

export const validateCnpj = (cnpj: string): boolean => {
  cnpj = cnpj.replace(/[^\d]+/g, '')

  if (cnpj == '') return false
  if (cnpj.length != 14) return false
  if (
    cnpj == '00000000000000' ||
    cnpj == '11111111111111' ||
    cnpj == '22222222222222' ||
    cnpj == '33333333333333' ||
    cnpj == '44444444444444' ||
    cnpj == '55555555555555' ||
    cnpj == '66666666666666' ||
    cnpj == '77777777777777' ||
    cnpj == '88888888888888' ||
    cnpj == '99999999999999'
  )
    return false

  let size = cnpj.length - 2
  let numbers = cnpj.substring(0, size)
  const digits = cnpj.substring(size)
  let sum = 0
  let pos = size - 7

  for (let i = size; i >= 1; i--) {
    sum += Number(numbers.charAt(size - i)) * pos--
    if (pos < 2) pos = 9
  }

  let result = sum % 11 < 2 ? 0 : 11 - (sum % 11)
  if (result != Number(digits.charAt(0))) return false

  size = size + 1
  numbers = cnpj.substring(0, size)
  sum = 0
  pos = size - 7

  for (let i = size; i >= 1; i--) {
    sum += Number(numbers.charAt(size - i)) * pos--
    if (pos < 2) pos = 9
  }

  result = sum % 11 < 2 ? 0 : 11 - (sum % 11)
  if (result != Number(digits.charAt(1))) return false

  return true
}
