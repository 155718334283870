export enum ClickContactHelpType {
  CALL = 'ligacao',
  WHATSAPP = 'whatsapp',
  EMAIL = 'email',
}

/**
 * Click Contact Help
 *
 * @alias ClickContactHelp
 */

export type ClickContactHelp = {
  /**
   * Check if user is insured
   */
  isInsured: boolean
  /**
   * Check if user is guardian
   */
  isGuardian: boolean
  /**
   * Status
   */
  tipo: ClickContactHelpType
}
