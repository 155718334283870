import React from 'react'

import { Utils } from '@azos/shared'
import {
  Input,
  InputNumber,
  InputZipcode,
  Select,
  SelectOption,
} from '@azos/shared/src/components/v2'
import { GuardianFormValue } from '@presentation/components/molecules/Guardians'
import { useFormikContext } from 'formik'
import {
  makeListCitiesService,
  makeListStatesService,
  makeLoadByZipCodeService,
} from 'main/factories/services'

import { GuardianAddressDataFormProps } from './GuardianAddressDataForm.props'
import { Root } from './GuardianAddressDataForm.styles'

const delay = (delay = 100) =>
  new Promise(resolve => setTimeout(resolve, delay))

const GuardianAddressDataForm: React.VFC<GuardianAddressDataFormProps> = ({
  className,
  hasAddressFilled = false,
}) => {
  const inputZipcodeRef = React.useRef<HTMLInputElement>(null)
  const inputNumberRef = React.useRef<HTMLInputElement>(null)

  const {
    errors,
    handleBlur,
    handleChange,
    isSubmitting,
    setFieldValue,
    touched,
    initialValues,
    values,
    setTouched,
  } = useFormikContext<GuardianFormValue>()

  const [states, setStates] = React.useState<SelectOption[]>([])
  const [cities, setCities] = React.useState<SelectOption[]>([])
  const [lastCep, setLastCep] = React.useState<string | undefined>(
    initialValues?.zipCode,
  )

  const [isLoading, setIsLoading] = React.useState(false)
  const [disabledFields, setDisabledFields] = React.useState<string[]>([])

  const filterPropsWithMaxChars = ([_prop, value]: [string, string]) => {
    const hasLessThanMaxChars = value.length <= 50

    return hasLessThanMaxChars
  }

  const fetchStates = React.useCallback(() => {
    setIsLoading(true)
    makeListStatesService()
      .execute()
      .then(response =>
        setStates(
          response.map(state => ({
            value: state.acronym,
            label: state.name,
          })),
        ),
      )
      .then(() => setIsLoading(false))
      .catch(() => {})
  }, [])

  const fetchAddressInfo = React.useCallback(
    async (cep: string) => {
      setIsLoading(true)

      const addressData = await makeLoadByZipCodeService()
        .execute(cep)
        .then(response => {
          setIsLoading(false)
          return response
        })
        .catch(() => null)

      if (!!addressData && addressData.state && addressData.uf) {
        setFieldValue('state', addressData.uf, true)
        setFieldValue('uf', addressData.uf, true)
        setFieldValue('neighborhood', addressData.neighborhood, true)
        setFieldValue('city', addressData.city, true)
        setFieldValue('street', addressData.street, true)
        setCities([
          {
            label: addressData.city,
            value: addressData.city,
          },
        ])
        setStates([
          {
            label: addressData.state,
            value: addressData.uf,
          },
        ])

        const { filtered: disabledAddresses, unfiltered: activeAddresses } =
          Utils.array.getFilteredUnfiltered(
            Object.entries(addressData),
            filterPropsWithMaxChars,
          )

        setDisabledFields(
          disabledAddresses.reduce((acc: string[], [prop, value]: string[]) => {
            if (value) acc.push(prop)
            return acc
          }, []),
        )

        const touchedFields: { [key: string]: boolean } = {}

        activeAddresses.forEach(([prop, value]) => {
          touchedFields[prop] = true
        })

        setTouched(touchedFields)

        inputNumberRef.current?.focus()
      } else {
        setFieldValue('state', '', true)
        setFieldValue('uf', '', true)
        setFieldValue('neighborhood', '', true)
        setFieldValue('city', '', true)
        setFieldValue('street', '', true)
        setFieldValue('number', '', true)
        setFieldValue('complement', '', true)

        setDisabledFields([
          'city',
          'street',
          'number',
          'complement',
          'neighborhood',
        ])
        fetchStates()
      }
    },
    [setFieldValue, setTouched, fetchStates],
  )

  const handleStateChange = React.useCallback((state: string) => {
    setIsLoading(true)
    makeListCitiesService()
      .execute(state)
      .then(response =>
        setCities(
          response.map(cities => ({
            value: cities.name,
            label: cities.name,
          })),
        ),
      )
      .then(() => {
        setIsLoading(false)
        setDisabledFields([])
      })
      .catch(() => {})
  }, [])

  React.useEffect(() => {
    const zipCodeValue = Utils.sanitizer.onlyNumber(values.zipCode)
    const zipCodeHasChanged =
      Utils.sanitizer.onlyNumber(lastCep) !==
      Utils.sanitizer.onlyNumber(zipCodeValue)

    if (zipCodeValue && zipCodeValue.length === 8 && zipCodeHasChanged) {
      fetchAddressInfo(zipCodeValue)
      setLastCep(zipCodeValue)
    }
  }, [values.zipCode, fetchAddressInfo, lastCep])

  React.useEffect(() => {
    ;(async () => {
      await delay()
      inputZipcodeRef.current?.focus()
    })()
  }, [])

  React.useEffect(() => {
    if (
      hasAddressFilled &&
      !!initialValues.state &&
      !!initialValues.uf &&
      !!initialValues.city
    ) {
      setDisabledFields(['state', 'city'])

      setStates([
        {
          label: initialValues.state,
          value: initialValues.uf,
        },
      ])
      setCities([
        {
          label: initialValues.city,
          value: initialValues.city,
        },
      ])
    }

    if (!hasAddressFilled) {
      setDisabledFields([
        'state',
        'city',
        'street',
        'number',
        'complement',
        'neighborhood',
      ])
    }
  }, [hasAddressFilled, initialValues])

  React.useEffect(() => {
    if (values.uf) {
      setFieldValue(
        'state',
        states.find(state => state.value === values.uf)?.label ?? values.uf,
        true,
      )
    }
  }, [values.uf, states, setFieldValue])

  const isDisabled = React.useMemo<boolean>(
    () => isSubmitting || isLoading,
    [isSubmitting, isLoading],
  )

  const onOpen = () => {
    if (inputZipcodeRef && inputZipcodeRef.current) {
      inputZipcodeRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }

  return (
    <Root open={values.hasAddress} className={className}>
      <InputZipcode
        innerRef={inputZipcodeRef}
        id="zipCode"
        autoFocus
        name="zipCode"
        value={values.zipCode}
        onChange={event => {
          handleChange(event)
        }}
        onBlur={handleBlur}
        error={touched.zipCode && !!errors.zipCode}
        helperText={touched.zipCode && errors.zipCode}
        disabled={isDisabled}
        fullWidth
        className="guardian-address-data__input"
      />

      <Select
        name="state"
        label="Estado"
        options={states}
        value={values.uf}
        onBlur={handleBlur}
        onChange={state => {
          setFieldValue('uf', state, true)
          handleStateChange(state.toString())
        }}
        error={touched.state && !!errors.state}
        helperText={touched.state && errors.state}
        disabled={isDisabled || disabledFields.includes('state')}
        fullWidth
        className="guardian-address-data__input"
      />

      <Select
        name="city"
        label="Cidade"
        options={cities}
        value={values.city}
        onBlur={handleBlur}
        onChange={city => {
          setFieldValue('city', city, true)
        }}
        error={touched.city && !!errors.city}
        helperText={touched.city && errors.city}
        disabled={isDisabled || disabledFields.includes('city')}
        fullWidth
        className="guardian-address-data__input"
      />

      <Input
        name="street"
        label="Rua"
        value={values.street}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.street && !!errors.street}
        helperText={touched.street && errors.street}
        disabled={isDisabled || disabledFields.includes('street')}
        fullWidth
        className="guardian-address-data__input"
      />

      <div className="guardian-address-data__input-wrapper">
        <InputNumber
          innerRef={inputNumberRef}
          name="number"
          label="Número"
          value={values.number}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.number && !!errors.number}
          helperText={touched.number && errors.number}
          disabled={isDisabled || disabledFields.includes('number')}
          fullWidth
          className="guardian-address-data__input"
        />

        <Input
          name="complement"
          label="Complemento"
          value={values.complement}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.complement && !!errors.complement}
          helperText={touched.complement && errors.complement}
          disabled={isDisabled || disabledFields.includes('complement')}
          fullWidth
          className="guardian-address-data__input"
        />
      </div>

      <Input
        name="neighborhood"
        label="Bairro"
        value={values.neighborhood}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.neighborhood && !!errors.neighborhood}
        helperText={touched.neighborhood && errors.neighborhood}
        disabled={isDisabled || disabledFields.includes('neighborhood')}
        fullWidth
        className="guardian-address-data__input"
      />
    </Root>
  )
}

export default GuardianAddressDataForm
