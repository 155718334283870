import { BeneficiaryViewModel } from '../../../../data/models'
import { Mapper } from '../../../../data/protocols'
import { LoadBeneficiariesV2 } from '../../../../domain/usecases'
import { SWRServices } from '../../../protocols/service'

type Response = BeneficiaryViewModel[]
export type ILoadBeneficiariesV2Service = SWRServices<string, Response>

export class LoadBeneficiariesV2Service implements ILoadBeneficiariesV2Service {
  constructor(
    private readonly beneficiariesRepository: LoadBeneficiariesV2,
    private readonly beneficiariesMapper: Mapper,
  ) {}

  public getUrl(proposal_id: string) {
    return `/proposals/${proposal_id}/beneficiaries`
  }

  async execute(proposal_id: string): Promise<Response> {
    const response = await this.beneficiariesRepository.loadBeneficiaries(
      proposal_id,
    )

    return this.beneficiariesMapper.map(response)
  }
}
