import React from 'react'

import { useAuth } from '@azos/account'
import analytics from '@azos/analytics'
import { infoBanner } from '@azos/analytics/src/domain/models'
import { Text } from '@azos/shared/src/components/v2'
import {
  DashboardSliderCardsRedirect,
  DashboardSliderCardsRedirectPoliciesType,
  DashboardSliderCardsRedirectType,
  DashboardSliderCardsType,
} from '@data/models'
import utms from '@main/config/utms'
import { GuardiansModal } from '@presentation/components/molecules/Guardians'
import { ModalAppStore } from '@presentation/components/molecules/ModalAppStore'
import { ModalPolicyReadjustment } from '@presentation/components/molecules/ModalPolicyReadjustment'
import {
  PolicySelectViewType,
  PolicyViewManagement,
} from '@presentation/components/organisms/PolicyViewManagement'
import { useRouter } from '@presentation/providers/RouterProvider'
import clsx from 'clsx'

import { InfoCardIcon } from './components'
import { InfoCardProps } from './InfoCard.props'
import { Root } from './InfoCard.styles'

const InfoCard: React.VFC<InfoCardProps> = ({ item, className, onClick }) => {
  const Router = useRouter()
  const { user } = useAuth()

  const [isModalPolicyReadjustment, setIsModalPolicyReadjustment] =
    React.useState(false)
  const [isPolicyViewMangementOpen, setIsPolicyViewManagementOpen] =
    React.useState(false)
  const [isGuardianOpen, setIsGuardianOpen] = React.useState(false)
  const [isAppStoreOpen, setIsAppStoreOpen] = React.useState(false)

  const [policyViewManagementHeader, setPolicyViewManagementHeader] =
    React.useState<Partial<{ title: string; subtitle: string }>>({})

  const [viewPolicyType, setViewPolicyType] =
    React.useState<PolicySelectViewType>(PolicySelectViewType.details)

  const [policiesList, setPoliciesList] = React.useState<
    DashboardSliderCardsRedirectPoliciesType[]
  >([])

  const isB2B = React.useMemo(
    () => !!user?.isBroker || !!user?.isInsuredByBroker,
    [user],
  )

  const dispatchAnalytics = (type: DashboardSliderCardsType) => {
    const analyticsEvents: { [key: string]: infoBanner } = {
      [DashboardSliderCardsType.CHANGE_PAYMENT_TYPE]:
        infoBanner.CHANGE_PAYMENT_TYPE,
      [DashboardSliderCardsType.OPEN_INVOICE]: infoBanner.OPEN_INVOICE,
      [DashboardSliderCardsType.GUARDIANS]: infoBanner.GUARDIANS,
      [DashboardSliderCardsType.BENEFICIARIES]: infoBanner.BENEFICIARIES,
      [DashboardSliderCardsType.ONBOARDING]: infoBanner.ONBOARDING,
      [DashboardSliderCardsType.DOWNLOAD_APP]: infoBanner.DOWNLOAD_APP,
      [DashboardSliderCardsType.READJUSTMENT_POLICY]:
        infoBanner.READJUSTMENT_POLICY,
    }
    analytics.dashboard.banner.clickImportantInfoBanner.execute({
      type: analyticsEvents[type] as infoBanner,
    })
  }

  const handlePolicyListView = (
    tab: PolicySelectViewType,
    redirect: DashboardSliderCardsRedirect,
  ) => {
    setViewPolicyType(tab)
    setPoliciesList(redirect.policies)
    setIsPolicyViewManagementOpen(true)
    setPolicyViewManagementHeader({
      title: redirect.title ?? undefined,
      subtitle: redirect.description ?? undefined,
    })
  }

  const handleOnClick = async () => {
    dispatchAnalytics(item.type)

    // Temporary handler until bff fix on next week
    if (onClick) return onClick()

    const redirect = item.redirect

    const typeCases: { [key: string]: () => void } = {
      [DashboardSliderCardsType.GUARDIANS]: () => setIsGuardianOpen(true),
      [DashboardSliderCardsType.DOWNLOAD_APP]: () => setIsAppStoreOpen(true),
      [DashboardSliderCardsType.READJUSTMENT_POLICY]: () =>
        setIsModalPolicyReadjustment(true),
      [DashboardSliderCardsType.CHANGE_PAYMENT_TYPE]: () => {
        if (redirect.tab) {
          handlePolicyListView(PolicySelectViewType.change_payment, redirect)
        }
      },
    }

    if (typeCases[item.type]) return typeCases[item.type]()

    if (
      redirect.basePathWeb &&
      redirect.type === DashboardSliderCardsRedirectType.DIRECT
    ) {
      await Router.push(redirect.basePathWeb)
      return
    }

    if (redirect.tab && redirect.tab in PolicySelectViewType) {
      handlePolicyListView(redirect.tab as PolicySelectViewType, redirect)
      return
    }
  }

  return (
    <>
      <Root
        className={clsx('info-card__info--content', className)}
        $backgroundColor={item.backgroundCard}
        $titleColor={item.titleColor}
        $descriptionColor={item.descriptionColor}
        onClick={handleOnClick}
      >
        <InfoCardIcon icon={item.icon} />
        <div className="info-card__text--wrapper">
          <Text variant="bodyBold3" className="info-card__text--title">
            {item.title}
          </Text>
          <Text variant="caption" className="info-card__text--description">
            {item.description}
          </Text>
        </div>
      </Root>
      <PolicyViewManagement
        open={isPolicyViewMangementOpen}
        onClose={() => setIsPolicyViewManagementOpen(false)}
        viewType={viewPolicyType}
        policiesList={policiesList}
        title={policyViewManagementHeader.title}
        subtitle={policyViewManagementHeader.subtitle}
      />
      <GuardiansModal
        open={isGuardianOpen}
        onClose={() => setIsGuardianOpen(false)}
        hideShareLink={isB2B}
      />
      <ModalAppStore
        open={isAppStoreOpen}
        onClose={() => setIsAppStoreOpen(false)}
        utm={utms.store}
      />
      <ModalPolicyReadjustment
        open={isModalPolicyReadjustment}
        onGoBack={() => setIsModalPolicyReadjustment(false)}
        onClose={() => setIsModalPolicyReadjustment(false)}
      />
    </>
  )
}

export default InfoCard
