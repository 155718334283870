import React from 'react'

import { InputMask } from '../InputMask'
import { InputCardDateProps } from './InputCardDate.props'

const InputCardDate = ({ children, ...rest }: InputCardDateProps) => {
  return (
    <InputMask
      label="Data de vencimento"
      placeholder="mm/aa"
      {...rest}
      mask="99/99"
      type="tel"
    />
  )
}

export default InputCardDate
