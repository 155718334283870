import React from 'react'

import { ModalProps } from './Modal.props'

type Props = ModalProps & any

export const MODAL_DELAY = 500

export function withModal<T = Props>(
  WrappedComponent: React.FunctionComponent<T>,
) {
  // eslint-disable-next-line react/display-name
  return ({ open, ...rest }: Props): any => {
    const interval = React.useRef<NodeJS.Timeout>()

    const [show, setShow] = React.useState<boolean>(open)

    const resetTimer = () => {
      if (interval.current) clearTimeout(interval.current)
    }

    React.useEffect(() => {
      if (!open) {
        interval.current = setTimeout(() => setShow(false), MODAL_DELAY)
      } else {
        resetTimer()
        setShow(true)
      }
      return resetTimer
    }, [open])

    return show ? <WrappedComponent open={open} {...rest} /> : null
  }
}

export default withModal
