import { Dispatcher, Service } from '../../protocols'

export type IViewDueDateSelectDrawerService = Service<void>

/**
 * View Due Date Select Drawer
 *
 * @category Checkout
 */
export class ViewDueDateSelectDrawerService
  implements IViewDueDateSelectDrawerService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   */
  execute(): void {
    this.dispatcher.execute(
      'Visualizou drawer de escolha de data de vencimento no fluxo de contratação',
    )
  }
}
