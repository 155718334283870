export enum DownloadProposalType {
  POLICIES = 'Apólices',
  PROPOSALS = 'Propostas',
}

/**
 * Download Proposal
 *
 * @alias DownloadProposal
 */

export type DownloadProposal = {
  /**
   * proposal type
   */
  type: DownloadProposalType
}
