import React from 'react'

import { Text } from '@azos/shared'

import { MenuItem } from '../../components'
import { MenuItemDashboardProps } from './MenuDashboard.props'
import { Root } from './MenuDashboard.styles'

const MenuDashboard = ({ onClick, menuItems }: MenuItemDashboardProps) => {
  const itemsLen = menuItems.length
  const itemsToRender = menuItems.slice(0, itemsLen - 1)
  const lastItem = menuItems[menuItems.length - 1]
  const renderItem = ({
    icon,
    value,
    label,
    onClick: onItemClick,
  }: MenuItem) => {
    const click = onClick?.((_path?: string) => onItemClick?.())
    return (
      <div key={label} className="menu_item" onClick={() => click?.(value)}>
        <i className={icon} />
        <Text component="p">{label}</Text>
      </div>
    )
  }

  const renderMenu = (
    <div className="menu_item--render">{itemsToRender.map(renderItem)}</div>
  )

  const renderLastButton = () => {
    const click = onClick?.((_path?: string) => onItemClick?.())
    const { label, icon, value, onClick: onItemClick } = lastItem
    return (
      <div onClick={() => click?.(value)} className="menu_item--lastbutton">
        <i className={icon} />
        {label}
      </div>
    )
  }

  return (
    <Root>
      {renderMenu}
      {renderLastButton()}
    </Root>
  )
}

export default MenuDashboard
