import { HttpClient, UnexpectedError } from '@azos/core'
import { LoadCoverages2PremiumsUseCase } from '@domain/usecases'
import HttpStatusCode from 'http-status-codes'

export type ICoveragesRepository = LoadCoverages2PremiumsUseCase

export class CoveragesRepository implements ICoveragesRepository {
  constructor(private readonly httpClient: HttpClient) {}

  async loadCoverages2Premiums(
    params: LoadCoverages2PremiumsUseCase.Params,
  ): Promise<LoadCoverages2PremiumsUseCase.Response> {
    const { proposalId } = params

    const httpResponse = await this.httpClient.request({
      url: `/coverages/${proposalId}`,
      method: 'get',
    })

    const response = httpResponse.data

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
        return response as LoadCoverages2PremiumsUseCase.Response
      default:
        throw new UnexpectedError()
    }
  }
}
