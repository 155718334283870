import { PolicyCancelUserType } from './policy-cancel'

/**
 * View Feedback Modal
 *
 * @alias ViewFeedbackModal
 */

export enum ViewFeedbackModalType {
  ENVIADA = 'ENVIADA',
  NAO_ENVIADA = 'NAO_ENVIADA',
}

export type ViewFeedbackModal = {
  type: PolicyCancelUserType
  feedback: ViewFeedbackModalType
}
