import React from 'react'

import { Text } from '@azos/shared/src/components/v2'

import { WaitingApprovalModal } from '../PersonalDataChangeable/WaitingApprovalModal'
import { Root } from './WaitingApprovalCard.styles'

const WaitingApprovalCard = () => {
  const [waitingApproval, SetWaitingApproval] = React.useState(false)

  return (
    <Root>
      <div
        className="waiting-approval-card__header"
        onClick={() => SetWaitingApproval(true)}
      >
        <div className="waiting-approval-card__background-icon">
          <i className="icon-clock" />
        </div>
        <div className="waiting-approval-card__info">
          <Text variant="bodyBold3">Edição de dados em aprovação</Text>
          <Text variant="caption">Clique aqui para saber mais</Text>
        </div>
      </div>
      <WaitingApprovalModal
        open={waitingApproval}
        onClose={() => SetWaitingApproval(false)}
      />
    </Root>
  )
}

export default WaitingApprovalCard
