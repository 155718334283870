import React from 'react'

import { BadgeProps } from './Badge.props'
import { Root } from './Badge.styles'

const Badge: React.FCC<BadgeProps> = ({
  className,
  children,
  color,
  textColor,
  type = 'info',
}) => {
  return children ? (
    <Root
      $type={type}
      $color={color}
      $textColor={textColor}
      className={className}
    >
      {children}
    </Root>
  ) : null
}

export default Badge
