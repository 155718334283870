import React from 'react'

import { Utils } from '@azos/shared'

import { CounterProps } from './Counter.props'
import { Root } from './Counter.styles'

const Counter: React.VFC<CounterProps> = ({
  value,
  min = 1,
  max = 100,
  prefix,
  sufix,
  disabled,
  useWheel = false,
  parentName,
  onChange,
}) => {
  const _min = React.useMemo(() => Number(min), [min])
  const _max = React.useMemo(() => Number(max), [max])
  const _value = React.useMemo(() => Number(value), [value])

  const handleOnChange = React.useCallback(
    (action: 'add' | 'remove') => {
      if (action === 'add' && _value < _max) {
        onChange(action)
      }

      if (action === 'remove' && _value > _min) {
        onChange(action)
      }
    },
    [_value, _min, _max, onChange],
  )

  const handleWheel = React.useCallback(
    (event: React.WheelEvent<HTMLDivElement>) => {
      event.preventDefault()
      event.stopPropagation()

      if (!disabled && useWheel) {
        handleOnChange(event.deltaY > 0 ? 'add' : 'remove')
      }
    },
    [disabled, handleOnChange, useWheel],
  )

  return (
    <Root
      $min={_min}
      $max={_max}
      $value={_value}
      onWheel={handleWheel}
      data-action={Utils.dataNames.renderDataName('counter', parentName)}
    >
      <button
        disabled={_value === _min || disabled}
        onClick={() => handleOnChange('remove')}
        data-action={Utils.dataNames.renderDataName(
          'counter-decrease',
          parentName,
        )}
      >
        <span className="icon-remove" />
      </button>

      <span
        className="value"
        data-action={Utils.dataNames.renderDataName(
          'counter-value',
          parentName,
        )}
        data-value={value.toString()}
      >
        {prefix && prefix}
        {value}
        {sufix && sufix}
      </span>

      <button
        disabled={_value === _max || disabled}
        onClick={() => handleOnChange('add')}
        data-action={Utils.dataNames.renderDataName(
          'counter-increase',
          parentName,
        )}
      >
        <span className="icon-add" />
      </button>
    </Root>
  )
}

export default Counter
