import React from 'react'

export const TOPICS = [
  {
    icon: 'icon-user-users',
    title: 'Ombro amigo e suporte',
    text: (
      <>
        Sabemos que se acontecer algum imprevisto nossos segurados podem não ser
        capazes, física ou emocionalmente, de lidar com o processo de
        acionamento do seguro. Você dará auxílio durante o momento da perda.
      </>
    ),
  },
  {
    icon: 'icon-hand-heart',
    title: 'Ser um ponto de contato da Azos',
    text: (
      <>
        Seu papel é entrar em contato com a Azos ou corretor(a), para avisar
        caso aconteça alguma imprevisto, podendo também auxiliar no processo de
        abertura de um sinistro, se for da vontade dos beneficiários.
      </>
    ),
  },
  {
    icon: 'icon-paste',
    title: 'Auxílio no envio de documentação',
    text: (
      <>
        Se necessário, você poderá auxiliar na apresentação dos documentos
        necessários para que possamos realizar a liberação da indenização junto
        a Azos ou corretor(a) do protegido ou protegida.
      </>
    ),
  },
  {
    icon: 'icon-show',
    title: 'Privacidade',
    text: (
      <>
        Como pessoa guardiã do seguro, você não terá acesso a nenhuma informação
        relativa a valores e a beneficiários do seguro do seu protegido.
      </>
    ),
  },
]
