import React from 'react'
import { isMobile } from 'react-device-detect'

import breakpoints from '../styles/breakPoint'

type Sizes = {
  width: number | undefined
  height: number | undefined
}

const useMediaQuery = () => {
  const [windowSize, setWindowSize] = React.useState<Sizes>({
    width: undefined,
    height: undefined,
  })

  const noSSR = typeof window !== 'undefined'

  React.useEffect(() => {
    if (noSSR) {
      const handleResize = () => {
        setWindowSize({
          width: window?.innerWidth || 0,
          height: window?.innerHeight || 0,
        })
      }

      window.addEventListener('resize', handleResize)

      handleResize()

      return () => window.removeEventListener('resize', handleResize)
    }
  }, [])

  const theme = breakpoints
  if (!windowSize.width) {
    return {
      isMobile,
    }
  }
  const windowWidth = windowSize.width

  const isMobileClient = windowWidth < theme.breakpoints.values.sm
  return {
    isMobile: isMobileClient,
  }
}

export default useMediaQuery
