import { SimulationSummary } from '../../../domain/models'
import { Dispatcher, Service } from '../../protocols'

export type ISimulationSummaryService = Service<SimulationSummary>

/**
 * Summary simulation
 *
 * @category Simulation
 */
export class SimulationSummaryService implements ISimulationSummaryService {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   * @param {SimulationSummary} data
   */
  execute(data: SimulationSummary): void {
    this.dispatcher.execute('simulation_summary', data)
  }
}
