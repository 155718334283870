import React from 'react'

import { GoogleButton } from '../../atoms/GoogleButton'
import { Root } from './SocialLoginButtons.styles'

const SocialLoginButtons: React.VFC = () => (
  <Root>
    <GoogleButton />
  </Root>
)

export default SocialLoginButtons
