import { HttpClient, UnexpectedError } from '@azos/core'
import {
  CoverageModalUseCase,
  GetCoverageDetailsUseCase,
  ListBenefitsUseCase,
  ListNewsBannersUseCase,
  ListProductCardsUseCase,
  ListSliderCardsByIdUseCase,
  ListSliderCardsUseCase,
} from '@domain/usecases'
import HttpStatusCode from 'http-status-codes'

export type ICmsRepository = ListBenefitsUseCase &
  ListNewsBannersUseCase &
  ListSliderCardsUseCase &
  ListSliderCardsByIdUseCase &
  CoverageModalUseCase &
  GetCoverageDetailsUseCase &
  ListProductCardsUseCase

export class CmsRepository implements ICmsRepository {
  constructor(private readonly httpClient: HttpClient) {}

  async listBenefits(): Promise<ListBenefitsUseCase.Response> {
    const httpResponse = await this.httpClient.request({
      url: `/benefits`,
      method: 'get',
      body: null,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
        return httpResponse.data as ListBenefitsUseCase.Response
      default:
        return []
    }
  }

  async listNewsBanners(): Promise<ListNewsBannersUseCase.Response> {
    const httpResponse = await this.httpClient.request({
      url: `/news-banners`,
      method: 'get',
      body: null,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
        return httpResponse.data as ListNewsBannersUseCase.Response
      default:
        return []
    }
  }

  async listSliderCards(): Promise<ListSliderCardsUseCase.Response> {
    const httpResponse = await this.httpClient.request({
      url: `/dashboard-slider-cards`,
      method: 'get',
      body: null,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
        return httpResponse.data as ListSliderCardsUseCase.Response
      default:
        return []
    }
  }

  async listSliderCardsById({
    cardId,
  }: ListSliderCardsByIdUseCase.Params): Promise<ListSliderCardsByIdUseCase.Response> {
    const httpResponse = await this.httpClient.request({
      url: `/dashboard-slider-cards/${cardId}`,
      method: 'get',
      body: null,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
        return httpResponse.data as ListSliderCardsByIdUseCase.Response
      default:
        throw new UnexpectedError()
    }
  }

  async CoverageModal({
    coverageCode,
  }: CoverageModalUseCase.Params): Promise<CoverageModalUseCase.Response> {
    const httpResponse = await this.httpClient.request({
      url: `/coverages-modal/${coverageCode}`,
      method: 'get',
      body: null,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
        return httpResponse.data as CoverageModalUseCase.Response
      default:
        throw new UnexpectedError()
    }
  }

  async getCoverageDetails({
    coverageCode,
  }: GetCoverageDetailsUseCase.Params): Promise<GetCoverageDetailsUseCase.Response> {
    const httpResponse = await this.httpClient.request({
      url: `/coverage-details?productCode=${coverageCode}`,
      method: 'get',
      body: null,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
        return httpResponse.data as GetCoverageDetailsUseCase.Response
      default:
        throw new UnexpectedError()
    }
  }

  async listProductCards(): Promise<ListProductCardsUseCase.Response> {
    const httpResponse = await this.httpClient.request({
      url: `/products-cards`,
      method: 'get',
      body: null,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
        return httpResponse.data as ListProductCardsUseCase.Response
      default:
        return []
    }
  }
}
