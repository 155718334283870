import Box, { BoxProps } from '@mui/material/Box'

import { css, styled } from '../../styles'

export const Root = styled(Box)<BoxProps>(
  ({ theme }) => css`
    background: ${theme.palette.common.white};
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.12);
    padding: ${theme.spacing(3)};
    position: relative;
    width: 100%;
    z-index: ${theme.zIndex.appBar};

    display: flex;
    align-items: center;

    ${theme.breakpoints.up('md')} {
      padding-left: 42px;
      padding-right: 42px;
    }

    .header {
      &-logo {
        flex: 0;
        display: flex;
        align-items: center;
        margin-right: ${theme.spacing(4)};
        height: 32px;

        > a {
          height: inherit;
        }
      }

      &-menu {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        nav {
          margin-right: ${theme.spacing(4)};

          @media (min-width: ${theme.breakpoints.values.md}px) {
            margin-right: ${theme.spacing(7)};
          }

          button {
            cursor: pointer;
            position: relative;
            width: 18px;
            height: 16px;
            background: none;
            top: -4px;
            border: 0;

            @media (min-width: ${theme.breakpoints.values.md}px) {
              display: none;
            }

            &:before,
            &:after {
              content: '';
              position: absolute;
              width: 18px;
              height: 2px;
              left: 0;
              background: ${theme.palette.grey[400]};
            }

            &:before {
              top: 0;
              box-shadow: 0 7px 0 ${theme.palette.grey[400]};
            }

            &:after {
              bottom: 0;
            }
          }

          ul {
            position: absolute;
            left: 0;
            top: 100%;
            background: ${theme.palette.common.white};
            width: 100%;
            transform-origin: top;

            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            list-style: none;
            padding: ${theme.spacing(0)};
            margin: ${theme.spacing(0)};

            @media (min-width: ${theme.breakpoints.values.md}px) {
              position: relative;
              left: unset;
              top: unset;
              width: unset;
              background: transparent;
              flex-direction: row;
              gap: ${theme.spacing(7)};
            }

            li {
              border-top: 1px solid ${theme.palette.grey[100]};

              @media (min-width: ${theme.breakpoints.values.md}px) {
                border: 0;
              }

              a {
                display: block;
                color: ${theme.palette.grey[600]};
                font-size: ${theme.typography.body1.fontSize};
                line-height: ${theme.spacing(4)};
                text-decoration: none;
                text-transform: uppercase;
                padding: ${theme.spacing(3)} ${theme.spacing(4)};
                transition: background 0.3s, opacity 0.3s;

                &:hover {
                  opacity: 0.75;
                  background: ${theme.palette.grey[100]};
                }

                @media (min-width: ${theme.breakpoints.values.md}px) {
                  padding: ${theme.spacing(0)};

                  &:hover {
                    background: transparent;
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
)

Root.defaultProps = {
  component: 'header',
}

export default Root
