import React from 'react'

import { Utils } from '@azos/shared'
import clsx from 'clsx'

import { TooltipProps } from './Tooltip.props'
import { Container } from './Tooltip.styles'

const Tooltip: React.FCC<TooltipProps> = ({
  children,
  placement = 'top',
  tooltipContent,
  parentName,
  className,
  ...rest
}) => {
  return (
    <Container
      placement={placement}
      data-action={Utils.dataNames.renderDataName('tooltip', parentName)}
    >
      <div className="container__content">
        {children}
        <div className={clsx('container__tooltip', className)} {...rest}>
          {tooltipContent}
        </div>
      </div>
    </Container>
  )
}

export default Tooltip
