import { ProposalChanged } from '../../../domain/models'
import { Dispatcher, Service } from '../../protocols'

export type IProposalChangedService = Service<ProposalChanged>

/**
 * ProposalChangedService
 *
 * @category Proposals
 */
export class ProposalChangedService implements IProposalChangedService {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   * @param {ProposalChanged} data
   */
  execute(data: ProposalChanged): void {
    this.dispatcher.execute('premium_increase', data)
  }
}
