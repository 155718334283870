import React from 'react'

import { useAuth } from '@azos/account'

import ModalPolicyReadjustmentValue from './ModalPolicyReadjustmentValue'
import { ModalPolicyReadjustmentValueProps } from './ModalPolicyReadjustmentValue.props'

const PolicyReadjustmentAdapter: React.VFC<
  ModalPolicyReadjustmentValueProps
> = props => {
  const { user } = useAuth()

  return <ModalPolicyReadjustmentValue {...props} user={user} />
}

export default PolicyReadjustmentAdapter
