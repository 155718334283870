import { css, Utils } from '@azos/shared'
import styled from '@emotion/styled'

type PolicyDetailsProps = {
  $isLoadingOrError: boolean
  $hasPaymentMethod: boolean
  $background: boolean
}

export const Root = styled(
  'div',
  Utils.transient.transientStyleProps,
)<PolicyDetailsProps>(
  ({ theme, $hasPaymentMethod, $isLoadingOrError, $background }) => css`
    flex: 1;
    margin: 0 -34px -40px;
    padding: 24px 40px;

    > .empty-state-wrapper {
      display: grid;
      place-items: center;
      height: 100%;
    }

    ${$isLoadingOrError
      ? css`
          background: ${theme.palette.neutral[50]};
          height: 100%;
        `
      : css`
          background: ${$background
            ? `${theme.palette.neutral[100]}`
            : `${theme.palette.neutral[50]}`};
        `}

    .alert-wrapper {
      margin-bottom: 24px;
    }
    .info-card {
      width: 100%;
    }

    .history {
      margin-top: ${$hasPaymentMethod ? '24px' : 0};

      .header {
        margin-bottom: 24px;

        h2 {
          font-size: 18px;
          padding-bottom: 8px;
          font-weight: 600;
          margin: 0;
        }

        > p {
          font-size: 16px;
          margin: 0;
        }
      }
    }
  `,
)
