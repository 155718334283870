import {
  BeneficiariesRepository,
  BeneficiariesV2Repository,
} from '../../../data/repositories'
import { makeAxiosHttpClient } from '../http'

export const makeBeneficiariesRepository = () =>
  new BeneficiariesRepository(makeAxiosHttpClient())

export const makeBeneficiariesV2Repository = () =>
  new BeneficiariesV2Repository(makeAxiosHttpClient())
