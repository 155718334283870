import React from 'react'

import Box from '@mui/material/Box'

import { CardProps } from './Card.props'
import { Root } from './Card.styles'

const Card: React.FCC<CardProps> = ({ children, ...rest }) => {
  return (
    <Root component={Box} {...rest}>
      {children}
    </Root>
  )
}

export default Card
