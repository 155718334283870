import { css, Modal as StyledModal, ModalProps, Utils } from '@azos/shared'
import styled from '@emotion/styled'

type waitingApprovalData = {
  $waitingApprovalDataActive: boolean
}

export const Modal = styled(StyledModal)<ModalProps>(
  ({ theme }) => css`
    .modal-header {
      .title {
        @media (min-width: ${theme.breakpoints.sm}px) {
          color: ${theme.palette.neutral[50]};
          font-size: 1.25rem !important;
        }
        color: ${theme.palette.neutral[50]};
        font-size: 1.125rem !important;
      }

      span {
        @media (min-width: ${theme.breakpoints.sm}px) {
          color: ${theme.palette.neutral[500]};
        }
        color: ${theme.palette.neutral[50]};
      }
    }
  `,
)

export const Root = styled(
  'div',
  Utils.transient.transientStyleProps,
)<waitingApprovalData>(
  ({ theme, $waitingApprovalDataActive }) => css`
    display: flex;
    min-height: calc(100vh - 88px);
    flex-direction: column;

    @media (min-width: ${theme.breakpoints.sm}px) {
      min-height: calc(100vh - 96px);
    }

    .user-account-modal {
      &__empty-state-wrapper {
        background: ${theme.palette.neutral[100]};
        flex: 1;
        display: grid;
        place-items: center;
      }

      &__user-photo {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin: 14px 0 26px 0;

        @media (min-width: ${theme.breakpoints.sm}px) {
          margin: 14px 0 34px 0;
        }

        &--text {
          &-content {
            display: inline-block;
            margin-top: 18px;
            color: ${theme.palette.neutral[50]};
            font-size: 0.875rem;
            font-weight: ${theme.typography.fontWeightBold};
            cursor: pointer;
          }
        }
      }
      &__content {
        background: ${theme.palette.neutral[100]};
        width: 100%;
        height: calc(100% - 340px);
        position: relative;
        padding: ${theme.spacing[4]}px;
        min-height: calc(100vh - 365px);

        &--button {
          ${$waitingApprovalDataActive
            ? css`
                &-select {
                  width: 100%;
                  margin: 24px 0 14px;
                  border: none;

                  i {
                    color: #a3aab9;
                    font-size: 22px;
                  }

                  p {
                    color: #a3aab9;
                    font-size: 0.875rem;
                    font-weight: ${theme.typography.fontWeightBold};
                    margin-left: 4px;
                  }
                }
              `
            : css`
                &-select {
                  width: 100%;
                  margin: 24px 0 14px;
                  border: none;

                  p {
                    font-size: 0.875rem;
                    font-weight: ${theme.typography.fontWeightBold};
                    margin-left: 4px;
                  }
                }
              `}
        }
      }
    }
    .user-account-modal__button-edit {
      position: sticky;
      bottom: 0;
      width: 100%;
      padding: ${theme.spacing[4]}px;
      background: ${theme.palette.neutral[50]};

      &--float {
        ${$waitingApprovalDataActive
          ? css`
              background: rgba(0, 0, 0, 0.08);
              color: rgba(0, 0, 0, 0.32);
              padding: 12px;
              border: none;
            `
          : ''}
      }
    }
  `,
)
