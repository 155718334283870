import { ViewReduceValue } from '../../../../../domain/models'
import { Dispatcher, Service } from '../../../../protocols'

export type IViewReduceValueService = Service<ViewReduceValue>

/**
 * View Reduce Value
 *
 * @category Dashboard
 */
export class ViewReduceValueService implements IViewReduceValueService {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @param {ViewReduceValue} data
   * @function
   */
  execute(data: ViewReduceValue): void {
    this.dispatcher.execute(
      'Area logada web - Visualização da drawer de redução de cobertura',
      data,
    )
  }
}
