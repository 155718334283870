import React from 'react'

import { BaseComponent } from '@azos/shared'
import { ListProps } from '@mui/material/List'

export type ListActionData = string | number | boolean

type BaseProps = BaseComponent & ListProps

export type ListActionOption = {
  id?: string
  //
  name: string
  label: React.ReactNode
  description?: string
  //
  title?: string
  icon?: React.ReactNode
  //
  onClick?: () => void
}

export type ListActionProps = BaseProps & {
  name: string
  options: ListActionOption[]
  //
  icon?: React.ReactNode
}
