import { useContext } from 'react'

import { NotifyContext, NotifyContextData } from '../providers'

const useNotify = (): NotifyContextData => {
  const context = useContext(NotifyContext)

  if (!context) {
    throw new Error('useNotify must be used within a NotifyProvider')
  }

  return context
}

export default useNotify
