import {
  ClickCheckPoliciesReadjustmentService,
  ClickHelpReadjustmentDetailsService,
  ClickPoliciesCardsService,
  ClickPoliciesTabsService,
  ClickReadjustmentBadgeService,
  ClickReadjustmentDetailsService,
  ClickRedirectReadjustmentDetailsService,
  DownloadProposalService,
  ViewPoliciesDetailsService,
  ViewPolicyReadjustmentDetailsService,
  ViewPolicyReadjustmentModalService,
} from '../../../../../data/services'
import { makeMixpanelDispatcherEvent } from '../../../infra'

export const makeClickCheckPoliciesReadjustmentService = () =>
  new ClickCheckPoliciesReadjustmentService(makeMixpanelDispatcherEvent())

export const makeClickHelpReadjustmentDetailsService = () =>
  new ClickHelpReadjustmentDetailsService(makeMixpanelDispatcherEvent())

export const makeClickPoliciesCardsService = () =>
  new ClickPoliciesCardsService(makeMixpanelDispatcherEvent())

export const makeClickPoliciesTabsService = () =>
  new ClickPoliciesTabsService(makeMixpanelDispatcherEvent())

export const makeClickReadjustmentBadgeService = () =>
  new ClickReadjustmentBadgeService(makeMixpanelDispatcherEvent())

export const makeClickReadjustmentDetailsService = () =>
  new ClickReadjustmentDetailsService(makeMixpanelDispatcherEvent())

export const makeClickRedirectReadjustmentDetailsService = () =>
  new ClickRedirectReadjustmentDetailsService(makeMixpanelDispatcherEvent())

export const makeDownloadProposalService = () =>
  new DownloadProposalService(makeMixpanelDispatcherEvent())

export const makeViewPoliciesDetailsService = () =>
  new ViewPoliciesDetailsService(makeMixpanelDispatcherEvent())

export const makeViewPolicyReadjustmentDetailsService = () =>
  new ViewPolicyReadjustmentDetailsService(makeMixpanelDispatcherEvent())

export const makeViewPolicyReadjustmentModalService = () =>
  new ViewPolicyReadjustmentModalService(makeMixpanelDispatcherEvent())
