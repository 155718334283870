import { css, styled } from '@azos/shared'

export const Root = styled('div')(
  ({ theme }) => css`
    .guardian-management-card {
      background: ${theme.palette.neutral[100]};
      padding: 16px;
      position: relative;
      overflow: hidden;
      margin: 24px 0;

      &__beneficiary-list {
        margin: 0;
        padding: 0;

        & > * + * {
          border-top: 1px solid ${theme.palette.neutral[100]};
        }
      }

      &__beneficiary-item {
        list-style-type: none;

        &__content {
          display: flex;
          justify-content: space-between;
          align-items: center;

          &__heading {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &__title {
              font-weight: ${theme.typography.fontWeightBold};
              font-size: 1rem;
              display: block;
              word-break: break-word;
              margin: 0;
            }
          }
        }

        hr {
          margin: 16px 0;
          border-top: 1px solid #d0d4dc;
        }

        &__button-group {
          display: flex;
        }

        &__button {
          border: none;
          width: 40px;
          height: 40px;
          cursor: pointer;
          background: #f3f4f6;

          display: flex;
          align-items: center;
          justify-content: center;

          &:disabled {
            cursor: not-allowed;

            span {
              color: ${theme.palette.neutral[300]};
            }
          }

          span {
            color: ${theme.palette.neutral[400]};
            font-size: 24px;
          }
        }

        &__info {
          display: flex;

          p {
            margin: 0;
          }

          &__dot::after {
            content: '\\00B7';
            font-size: 32px;
            color: ${theme.palette.neutral[300]};
            line-height: 20px;
            margin: 0 6px;
          }

          &__city {
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      &__empty-state-wrapper {
        opacity: 0.4;
        margin: 40px auto 12px;
        color: ${theme.palette.neutral[500]};
        max-width: 280px;

        p {
          font-size: 1rem;
        }

        &__icon {
          margin-bottom: 8px;
        }
      }

      &__error-state-wrapper {
        margin: 50px auto;
        color: ${theme.palette.neutral[500]};
        text-align: center;
      }
    }
  `,
)
