import ButtonBase, { ButtonBaseProps } from '@mui/material/ButtonBase'
import { InputLabelProps } from '@mui/material/InputLabel'
import Radio, { RadioProps } from '@mui/material/Radio'
import { styled } from '@mui/material/styles'
import TypographyBase, { TypographyProps } from '@mui/material/Typography'

import { Utils } from '../../../../../utils'

type InputProps = RadioProps & {
  $showInput?: boolean
}

type ButtonProps = ButtonBaseProps & {
  $showInput?: boolean
}

export const Button = styled(
  ButtonBase,
  Utils.transient.transientStyleProps,
)<ButtonProps>(({ theme, $showInput }) => ({
  display: $showInput ? 'flex' : 'inline-flex',
  justifyContent: $showInput ? 'space-between' : 'center',
  alignItems: 'center',

  backgroundColor: theme.palette.common.white,
  borderColor: theme.palette.common.white,
  borderRadius: 8,
  borderStyle: 'solid',
  borderWidth: 1,

  color: theme.palette.common.black,
  padding: theme.spacing(4),
  width: '100%',
  height: '100%',

  transition: 'border-color 0.2s, color 0.2s',

  '&:disabled': {
    color: theme.palette.grey[200],
  },
  '&:hover': {
    borderColor: theme.palette.common.black,
  },
  '&.checked, &:disabled.checked': {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.dark,
  },

  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(5),
  },

  '& > .text-content': {
    fontFamily: ['Dazzed', 'sans-serif'].join(','),
    fontSize: '0.875rem',
    fontWeight: theme.typography.fontWeightBold,
    margin: '0',
  },
}))

export const Label = styled('label')<InputLabelProps>(() => ({}))

export const Input = styled(
  Radio,
  Utils.transient.transientStyleProps,
)<InputProps>(({ $showInput }) => ({
  display: $showInput ? 'inline-block' : 'none',
  visibility: $showInput ? 'visible' : 'hidden',
  padding: 0,
  marginLeft: 16,
}))

export const Typography = styled(TypographyBase)<
  TypographyProps & { component: string }
>(
  ({ theme }) => `
  font-weight: ${theme.typography.fontWeightBold};
`,
)
