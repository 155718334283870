import {
  DPSIllnessService,
  DPSSelectionService,
  DPSSubmitService,
} from '../../../data/services/dps'
import { makeDispatcherEvent } from '../infra/dispatcher-event-factory'

export const makeDPSIllnessSevice = () =>
  new DPSIllnessService(makeDispatcherEvent())

export const makeDPSSelectionService = () =>
  new DPSSelectionService(makeDispatcherEvent())

export const makeDPSSubmitService = () =>
  new DPSSubmitService(makeDispatcherEvent())
