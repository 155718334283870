import React from 'react'

import { Utils } from '@azos/shared'
import MuiCheckbox from '@mui/material/Checkbox'

import { CheckboxProps } from './Checkbox.props'

const Checkbox = ({ parentName, ...rest }: CheckboxProps) => {
  const inputProps = {
    inputProps: {
      ...rest.inputProps,
      'data-action': Utils.dataNames.renderDataName('checkbox', parentName),
    },
  }

  return <MuiCheckbox {...rest} {...inputProps} />
}

export default Checkbox
