import React from 'react'

import { Root } from './Divider.styles'

type DividerProps = {
  label?: string
}

const Divider: React.VFC<DividerProps> = ({ label }) => {
  return <Root>{label && <span>{label}</span>}</Root>
}

export default Divider
