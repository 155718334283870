import Box, { BoxProps } from '@mui/material/Box'

import { css, styled } from '../../../styles'

export const Root = styled(Box)<BoxProps>(
  () => css`
    display: flex;
    align-items: center;
    justify-content: center;
  `,
)
