import { css, styled } from '@azos/shared'

export const Root = styled('div')(
  ({ theme }) => css`
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
  `,
)
