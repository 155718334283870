import React from 'react'

import { InputMask } from '../InputMask'
import { InputZipcodeProps } from './InputZipcode.props'

const InputZipcode = ({ children, ...rest }: InputZipcodeProps) => {
  return (
    <InputMask
      label="Endereço CEP"
      placeholder="99999-999"
      {...rest}
      mask={'99999-999'}
      type="tel"
    />
  )
}

export default InputZipcode
