import React from 'react'

import { ClearsaleContext } from './ClearSaleProvider'

export const useClearsale = () => {
  const context = React.useContext(ClearsaleContext)

  if (!context) {
    throw new Error('The useClearsale should within ClearSaleProvider')
  }

  return context
}
