import { Utils } from '@azos/shared'
import dayjs from 'dayjs'
import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
  full_name: Yup.string()
    .test({
      name: 'full_name',
      test: value => {
        const splitValue = value?.trim().split(' ')
        return (splitValue && splitValue?.length >= 2) || false
      },
      message: 'O nome e sobrenome são obrigatórios',
    })
    .required('O nome é obrigatório'),
  birth_date: Yup.date()
    .max(
      dayjs().subtract(18, 'y').toDate(),
      'Atualmente asseguramos pessoas maiores que 18 anos',
    )
    .min(
      dayjs().subtract(66, 'y').toDate(),
      'Atualmente asseguramos pessoas menores que 66 anos',
    )
    .required('Sua data de nascimento é utilizada para calcular seu seguro')
    .nullable()
    .typeError('Insira uma data válida'),
  gender: Yup.string()
    .required(
      'Precisamos dessa informação, pois ela impacta na cotação de seu seguro',
    )
    .nullable(),
  phone: Yup.string()
    .test({
      name: 'Phone',
      message: 'O telefone não é válido',
      test: value => Utils.validator.validatePhone(value as string),
    })
    .required('O telefone é obrigatório'),
  authorizes_whatsapp_contact: Yup.boolean(),
  cpf: Yup.string()
    .test({
      name: 'Cpf',
      message: 'O CPF não é válido',
      test: value => Utils.validator.validateCpf(value as string),
    })
    .required('O CPF é obrigatório'),
  marital_status: Yup.string().required('O estado civil é obrigatório'),
  is_politically_exposed_person: Yup.boolean()
    .nullable()
    .required('Precisamos dessa informação para continuarmos sua contratação'),
})
