import React from 'react'

import { IconAttributes } from '../components/SvgIcon'

const Icon = ({ as, style, ...rest }: IconAttributes) => {
  return (
    <i
      className="icon-check"
      style={{ fontSize: '1.5rem', color: '#090A0B', ...style }}
      {...rest}
    />
  )
}

export default Icon
