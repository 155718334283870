import { Mapper } from '../../../../data/protocols'
import { Gender } from '../../../../domain'
import { AppError, UnprocessableEntityError } from '../../../../domain/errors'
import { CreateInsuredUser } from '../../../../domain/usecases'
import { Services } from '../../../protocols/'

export type CreateInsuredUserRequest = {
  email: string
  full_name: string
  birth_date: Date | null
  gender: string | Gender
  phone: string
  cpf: string
  marital_status: string
  social_name?: string
  authorizes_whatsapp_contact: string
  is_politically_exposed_person?: string
  //
  cep: string
  state: string
  city: string
  street: string
  neighborhood: string
  number: string
  complement: string
}

type Request = CreateInsuredUserRequest
type Response = CreateInsuredUser.Response

export type ICreateInsuredUserService = Services<Request, Response>

export class CreateInsuredUserService implements ICreateInsuredUserService {
  constructor(
    private readonly insuredUserRepository: CreateInsuredUser,
    private readonly mapper: Mapper,
  ) {}

  async execute(params: Request): Promise<Response> {
    try {
      const payload = this.mapper.map(params)
      return await this.insuredUserRepository.createInsuredUser(payload)
    } catch (e: any) {
      const errorMessage =
        e instanceof UnprocessableEntityError
          ? 'Este CPF já possui uma conta com outro email. Por favor, faça login.'
          : 'Houve um problema ao atualizar o usuário'

      throw new AppError(errorMessage)
    }
  }
}
