import React from 'react'

import { InputAutoComplete } from '../InputAutoComplete'
import { InputProfessionProps } from './InputProfession.props'

const InputProfession = (props: InputProfessionProps) => {
  return (
    <InputAutoComplete
      {...props}
      placeholder="Digite"
      label="Profissão"
      disableClearable
      popupIcon={null}
      accuracy={0.8}
      minInputLength={2}
    />
  )
}

export default InputProfession
