export enum ClickRequestBrokerGuardianType {
  SUCCESS = 'sucesso',
  ERROR = 'error',
}

/**
 * Click Request Broker Guardian
 *
 * @alias ClickRequestBrokerGuardian
 */

export type ClickRequestBrokerGuardian = {
  /**
   * Check if user is insured
   */
  isInsured: boolean
  /**
   * Check if user is guardian
   */
  isGuardian: boolean
  /**
   * Status
   */
  tipo: ClickRequestBrokerGuardianType
}
