import HttpStatusCode from 'http-status-codes'

import { HttpClient } from '../../../data/protocols'
import { UnexpectedError } from '../../../domain/errors'
import { CreateContactNotify } from '../../../domain/usecases'

export type IHubRepository = CreateContactNotify

export class HubRepository implements IHubRepository {
  constructor(private readonly httpClient: HttpClient) {}

  async createContactNotify(
    params: CreateContactNotify.Params,
  ): Promise<CreateContactNotify.Response> {
    const httpResponse = await this.httpClient.request({
      url: 'https://api.hsforms.com/submissions/v3/integration/submit/9393121/abb109e7-8f52-4dc6-b6ee-2c5e296d2514',
      method: 'post',
      body: params,
    })

    const response = httpResponse.data

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
      case HttpStatusCode.CREATED:
        return response as CreateContactNotify.Response
      default:
        throw new UnexpectedError()
    }
  }
}
