import { css, styled } from '@azos/shared'

export const Root = styled('div')(
  ({ theme }) => css`
    .guardian-personal-data-form {
      &__input {
        background: ${theme.palette.neutral[50]};

        &:not(:first-of-type) {
          margin-top: ${theme.spacing[3]}px;
        }
      }
    }
  `,
)
