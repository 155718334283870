import { css, styled } from '@azos/shared'

export const Root = styled('div')(
  ({ theme }) => css`
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;

    .protected-request-help-list {
      &__header {
        padding: ${theme.spacing[4]}px;
        position: sticky;
        top: 0;
        z-index: ${theme.zIndex.header};
        background: ${theme.palette.neutral[50]};

        &--exit-button {
          cursor: pointer;
          background: ${theme.palette.neutral[100]};
          border: 0;
          border-radius: 50%;
          font-size: 1.5rem;
          padding: 0;
          position: relative;
          height: 48px;
          width: 48px;
          position: absolute;
          top: 24px;
          right: 24px;

          display: flex;
          align-items: center;
          justify-content: center;

          i {
            font-size: 1.5rem;
          }
        }

        .icon-content {
          font-size: 2.5rem;
          display: block;
          margin-top: 88px;
        }

        &--title {
          margin-top: 12px;

          label {
            color: ${theme.palette.primary.main};
          }
        }

        &--description {
          margin-top: ${theme.spacing[2]}px;
        }
      }

      &__content {
        padding: ${theme.spacing[2]}px ${theme.spacing[4]}px;

        &--list {
          margin-bottom: ${theme.spacing[5]}px;
        }
      }
    }
  `,
)
