import { ViewOnboardingGuardianContent } from '../../../../domain/models'
import { Dispatcher, Service } from '../../../protocols'

export type IViewOnboardingGuardianContentService =
  Service<ViewOnboardingGuardianContent>

/**
 * View onboarding guardian content
 *
 * @category Dashboard
 */
export class ViewOnboardingGuardianContentService
  implements IViewOnboardingGuardianContentService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @param {ViewOnboardingHomepage} data
   * @function
   */
  execute(data: ViewOnboardingGuardianContent): void {
    this.dispatcher.execute(
      'Area logada web - Visualização do conteúdo de guardiões do onboarding',
      data,
    )
  }
}
