import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;

    .loading {
      position: absolute;
      width: 100%;
      background: rgba(255, 255, 255, 0.75);
      backdrop-filter: blur(2px);
      padding: 30px;
      padding-top: 50vh;
      z-index: 1100;
      top: -100px;
      height: calc(100% + 140px);

      & > * {
        transform: translateY(-50%);
      }
    }

    .add-beneficiaries-button {
      margin-bottom: 12px;
    }

    p {
      margin: 0;
      padding: 0;
      font-size: 16px;
    }

    > .heading {
      margin-bottom: 32px;

      strong {
        color: ${theme.palette.primary[500]};
      }

      h2 {
        font-size: 24px;
        font-weight: 600;
        margin: 0 0 16px;
      }
    }

    ul {
      flex: 1;
      padding: 0;
      margin: 0;
      list-style: none;

      li {
        margin-bottom: 32px;

        & + li {
          padding-top: 32px;
          border-top: 1px solid ${theme.palette.neutral[200]};
        }

        > div {
          display: flex;
          align-items: center;
          justify-content: space-between;

          & + div {
            margin-top: 32px;
          }

          &.heading {
            p {
              font-weight: 600;
            }
          }

          p {
            margin-right: 16px;
          }
        }

        button {
          cursor: pointer;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 0;
          background: transparent;
          margin: 0;
          padding: 0;

          :disabled {
            cursor: not-allowed;
          }

          :not(:disabled) {
            &:before {
              content: '';
              position: absolute;
              width: 44px;
              height: 44px;
              border-radius: 44px;
              background: transparent;
              transition: background-color 0.3s;
            }

            &:hover {
              &:before {
                background: #00000009;
              }
            }

            &:active {
              &:before {
                background: transparent;
              }
            }

            &.locked {
              span {
                color: ${theme.palette.neutral[500]};
              }
            }
          }

          &.unlocked {
            span {
              color: ${theme.palette.neutral[300]};
            }
          }

          span {
            font-size: 25px;
            transition: color 0.2s;
          }
        }
      }
    }
  `,
)

export const ModalDeleteRoot = styled('div')(
  css`
    display: flex;
    button + button {
      margin-left: 16px;
    }
  `,
)
