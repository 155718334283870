import { IDelayService } from '../../protocols'

export class DelayService implements IDelayService {
  private timer = 0
  private destroyed = false

  constructor(readonly duration: number) {}

  delay(interval: number = this.duration): Promise<void> {
    return new Promise(resolve => {
      if (!this.destroyed) {
        this.timer = Number(setTimeout(resolve, interval))
      }
    })
  }

  destroy() {
    this.destroyed = true
    clearTimeout(this.timer)
  }
}
