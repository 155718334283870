import { css, styled } from '@azos/shared'

export const Root = styled('div')(
  ({ theme }) => css`
    .reduce-value {
      padding: 24px;
      height: 100%;
      max-height: 100vh;
      overflow: auto;
      padding-bottom: 140px;

      &__navigation {
        box-shadow: none;
        padding: 0;
        background: transparent;
        margin-bottom: 14px;
      }

      &__header {
        &--text {
          padding-top: 20px;
          display: flex;
          flex-direction: column;

          i {
            font-size: 40px;
          }

          h5 {
            margin: 16px 0 24px 0;
            color: ${theme.palette.neutral[500]};

            strong {
              color: ${theme.palette.primary[500]};
            }
          }

          p {
            color: ${theme.palette.neutral[400]};
          }
        }
      }

      &__content {
        width: 100%;
        text-align: center;
        margin: 32px 0 12px 0;

        &--options {
          & > * {
            text-align: center;
            margin: 12px 0;
          }
        }
      }

      &__card {
        height: auto;
        background: ${theme.palette.neutral[100]};
        padding: 16px;

        :not(:first-of-type) {
          margin-top: 12px;
        }

        &--title {
          display: flex;
          align-items: center;

          i {
            margin-right: 12px;
            font-size: 24px;
          }
        }

        &--description {
          margin-top: 4px;
        }
      }

      &__button {
        padding: 20px 24px;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: auto;
        background: ${theme.palette.neutral[50]};
        box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.08);
        border-top: 1px;
        button {
          :first-of-type {
            margin-bottom: 8px;
          }
        }
      }

      &__broker {
        margin: 32px 0;

        p {
          color: #505969;
        }

        &--content {
          &__card-copy {
            border: none;
            background: ${theme.palette.neutral[100]};
            justify-content: space-between;
            font-size: 0.875rem;
            border-radius: inherit;
            padding: 16px;

            &.no-copy {
              cursor: none;
              .icon-copy {
                display: none;
              }
            }

            .card_copy_text__value {
              flex: 1;
              text-align: start;
            }
          }
        }
      }
    }
  `,
)

export default Root
