import React from 'react'

import { useMediaQuery } from '@azos/shared'
import { PolicyListSelect } from '@presentation/components/molecules/Policies'

import { PolicyListSelectCardProps } from './PolicyListSelectCard.props'
import { Modal } from './PolicyListSelectCard.styles'

const PolicyListSelectCard: React.VFC<PolicyListSelectCardProps> = ({
  policiesList,
  isLoading,
  viewType,
  onClose,
  title,
  subtitle,
  ...rest
}) => {
  const { isMobile } = useMediaQuery()
  return (
    <Modal
      {...rest}
      onClose={onClose}
      variant={isMobile ? 'bottomDrawer' : 'drawer'}
      showBackButton={!isMobile}
      showHeader={!isMobile}
    >
      {isMobile && (
        <button type="button" onClick={onClose} className="exit-button-modal">
          <span className="icon-exit" />
        </button>
      )}

      <PolicyListSelect
        policiesList={policiesList}
        isLoading={isLoading}
        viewType={viewType}
        title={title}
        subtitle={subtitle}
        onClose={onClose}
      />
    </Modal>
  )
}

export default PolicyListSelectCard
