import currency from 'currency.js'

const handleCurrency = (
  value: number | string | currency,
  options?: currency.Options,
): currency => {
  return currency(value, {
    symbol: 'R$ ',
    separator: '.',
    decimal: ',',
    ...options,
  })
}

export const formatCurrency = (
  value: number | string | currency,
  options?: currency.Options,
): string => {
  return handleCurrency(value, options).format()
}
