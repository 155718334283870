import React from 'react'

import { BadgeProps } from './Badge.props'
import { Root } from './Badge.styles'

const Badge: React.FCC<BadgeProps> = ({
  className,
  children,
  color,
  textColor,
  icon,
  iconColor,
  type = 'info',
  borderRadius = 24,
  clickable,
  onClick,
}) => {
  return children ? (
    <Root
      $type={type}
      $color={color}
      $textColor={textColor}
      $borderRadius={borderRadius}
      $iconColor={iconColor}
      $clickable={clickable}
      className={className}
      onClick={onClick}
    >
      {icon && <i className={icon} />}
      {children}
    </Root>
  ) : null
}

export default Badge
