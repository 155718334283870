import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled('div')(
  ({ theme }) => css`
    .protected-tab {
      &__content {
        padding: 24px;
        flex: 1;
        background: ${theme.palette.neutral[50]};

        &-description {
          color: ${theme.palette.neutral[400]};
        }
      }

      &__about {
        background: ${theme.palette.primary[50]};
        padding: ${theme.spacing[3]}px;
        cursor: pointer;
        margin-bottom: ${theme.spacing[4]}px;

        i {
          font-size: 1.5rem;
        }

        &-description {
          margin-top: ${theme.spacing[2]}px;
        }

        i,
        &-title,
        &-description {
          color: ${theme.palette.primary[600]};
        }

        &-header {
          display: flex;
          justify-content: space-between;

          &-title {
            display: flex;
            align-items: center;

            i {
              margin-right: ${theme.spacing[3]}px;
            }
          }
        }
    }
  `,
)
