import { Utils } from '@azos/shared'
import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
  fullName: Yup.string()
    .test({
      name: 'full_name',
      test: value => {
        const splitValue = value?.trim().split(' ')
        return (splitValue && splitValue?.length >= 2) || false
      },
      message: 'O nome e sobrenome são obrigatórios',
    })
    .required('O nome é obrigatório'),
  maritalStatus: Yup.string().required('O estado civil é obrigatório'),
  phone: Yup.string()
    .test({
      name: 'Phone',
      message: 'O telefone não é válido',
      test: value => Utils.validator.validatePhone(value as string),
    })
    .required('O telefone é obrigatório'),
  email: Yup.string()
    .email('E-mail inválido')
    .required('Seu e-mail é obrigatório'),
  state: Yup.string().required('O estado é obrigatório'),
  city: Yup.string().required('A cidade é obrigatória'),
  street: Yup.string()
    .required('O logradouro é obrigatório')
    .max(50, 'Deve possuir no máximo 50 caracteres'),
  neighborhood: Yup.string()
    .required('O bairro é obrigatório')
    .max(50, 'Deve possuir no máximo 50 caracteres'),
  number: Yup.string().required('O número é obrigatório'),
  complement: Yup.string().optional(),
})
