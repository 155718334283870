import React from 'react'

import { Spinner, Utils } from '@azos/shared'
import {
  Input,
  InputNumber,
  InputZipcode,
  Select,
  SelectOption,
  Text,
} from '@azos/shared/src/components/v2'
import { useFormikContext } from 'formik'

import { useAuth } from '../../../../../hooks'
import { loadByZipcode, loadCities, loadStates } from '../../../../../services'
import {
  FormControl as WizardFormControl,
  useWizard,
} from '../../../../molecules'
import { RegistrationValue } from '../../Registration.props'
import { Root } from './Address.styles'

const delay = (delay = 100) =>
  new Promise(resolve => setTimeout(resolve, delay))

const Address: React.VFC = () => {
  const { isLoading: authLoading } = useAuth()
  const { prevStep, stepConfig } = useWizard()

  const {
    values,
    setFieldValue,
    handleBlur,
    handleChange,
    errors,
    touched,
    isSubmitting,
    setTouched,
  } = useFormikContext<RegistrationValue>()

  const [states, setStates] = React.useState<SelectOption[]>([])
  const [cities, setCities] = React.useState<SelectOption[]>([])

  const [lastCep, setLastCep] = React.useState<string | undefined>(values?.cep)

  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const inputZipcodeRef = React.useRef<HTMLInputElement>(null)
  const inputNumberRef = React.useRef<HTMLInputElement>(null)

  const [disabledFields, setDisabledFields] = React.useState<string[]>([
    'uf',
    'localidade',
  ])
  const isDisabled = React.useMemo<boolean>(
    () => isSubmitting || isLoading || authLoading,
    [isSubmitting, isLoading, authLoading],
  )

  const filterPropsWithMaxChars = ([_prop, value]: [string, string]) => {
    const hasLessThanMaxChars = value.length <= 50

    return hasLessThanMaxChars
  }

  const fetchAddressInfo = React.useCallback(
    async (cep: string) => {
      setIsLoading(true)

      const addressData = await loadByZipcode(cep).then(response => {
        setIsLoading(false)
        return response
      })

      if (!!addressData && addressData.uf) {
        setFieldValue('state', addressData.uf, true)
        setFieldValue('neighborhood', addressData.bairro, true)
        setFieldValue('city', addressData.localidade, true)
        setFieldValue('street', addressData.logradouro, true)
        setFieldValue('valid', cep, true)

        const { filtered: disabledAddresses, unfiltered: activeAddresses } =
          Utils.array.getFilteredUnfiltered(
            Object.entries(addressData),
            filterPropsWithMaxChars,
          )

        setDisabledFields(
          disabledAddresses.reduce((acc: string[], [prop, value]: string[]) => {
            if (value) acc.push(prop)
            return acc
          }, []),
        )

        const touchedFields: { [key: string]: boolean } = {}
        const activeCases: { [key: string]: string } = {
          bairro: 'neighborhood',
          logradouro: 'street',
        }

        activeAddresses.forEach(([prop, value]) => {
          const activeProp = activeCases[prop] ?? ''

          if (activeProp) {
            touchedFields[activeProp] = true
          }
        })

        setTouched(touchedFields)

        if (addressData.logradouro) {
          await delay(500)
          inputNumberRef.current?.focus()
        }
      } else {
        setFieldValue('state', '', true)
        setFieldValue('neighborhood', '', true)
        setFieldValue('street', '', true)
        setFieldValue('city', '', true)
        setFieldValue('valid', undefined, true)
        setDisabledFields([])
      }
    },
    [setFieldValue, setTouched],
  )

  React.useEffect(() => {
    const zipCodeValue = Utils.sanitizer.onlyNumber(values.cep)
    const zipCodeHasChanged =
      Utils.sanitizer.onlyNumber(lastCep) !==
      Utils.sanitizer.onlyNumber(zipCodeValue)

    if (zipCodeValue && zipCodeValue.length === 8 && zipCodeHasChanged) {
      fetchAddressInfo(zipCodeValue)
      setLastCep(zipCodeValue)
    }
  }, [fetchAddressInfo, lastCep, values.cep])

  React.useEffect(() => {
    setIsLoading(true)
    loadCities(values.state)
      .then(cities => {
        setCities(
          cities.map(city => ({
            value: city.name,
            label: city.name,
          })),
        )
      })
      .then(() => setIsLoading(false))
      .catch(() => {})
  }, [values.state])

  React.useEffect(() => {
    setIsLoading(true)
    loadStates()
      .then(states => {
        setStates(
          states.map(state => ({
            label: state.name,
            value: state.acronym,
          })),
        )
      })
      .then(() => setIsLoading(false))
      .catch(() => {})
  }, [])

  React.useEffect(() => {
    ;(async () => {
      await delay()
      inputZipcodeRef.current?.focus()
    })()
  }, [])

  return (
    <Root>
      <Text variant="h1">Endereço residencial</Text>

      <div className="address__content">
        <div className="zipcode__container">
          <InputZipcode
            autoFocus
            innerRef={inputZipcodeRef}
            name="cep"
            value={values.cep}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.cep && !!errors.cep}
            helperText={touched.cep ? errors.cep : undefined}
            disabled={isDisabled}
            fullWidth
          />
          {isLoading && (
            <Spinner className="zipcode__container--spinner" size={16} />
          )}
        </div>

        <Select
          name="state"
          label="Estado"
          value={values.state}
          onChange={handleChange}
          onBlur={handleBlur}
          className="zipcode__container--select"
          error={touched.state && !!errors.state}
          helperText={touched.state ? errors.state : undefined}
          disabled={isDisabled || disabledFields.includes('uf')}
          options={states}
          fullWidth
        />

        <Select
          name="city"
          label="Cidade"
          value={values.city}
          onChange={handleChange}
          onBlur={handleBlur}
          className="zipcode__container--select"
          error={touched.city && !!errors.city}
          helperText={touched.city ? errors.city : undefined}
          disabled={isDisabled || disabledFields.includes('localidade')}
          options={cities}
          fullWidth
        />

        <Input
          name="neighborhood"
          label="Bairro"
          value={values.neighborhood}
          onChange={handleChange}
          onBlur={handleBlur}
          className="zipcode__select"
          error={touched.neighborhood && !!errors.neighborhood}
          helperText={touched.neighborhood ? errors.neighborhood : undefined}
          disabled={isDisabled || disabledFields.includes('bairro')}
          fullWidth
        />

        <Input
          name="street"
          label="Rua"
          value={values.street}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.street && !!errors.street}
          helperText={touched.street ? errors.street : undefined}
          disabled={isDisabled || disabledFields.includes('logradouro')}
          fullWidth
        />

        <InputNumber
          innerRef={inputNumberRef}
          name="number"
          label="Número"
          value={values.number}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.number && !!errors.number}
          helperText={touched.number ? errors.number : undefined}
          disabled={isDisabled}
          fullWidth
        />

        <Input
          name="complement"
          label="Complemento"
          value={values.complement}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.complement && !!errors.complement}
          helperText={touched.complement ? errors.complement : undefined}
          disabled={isDisabled}
          fullWidth
        />
      </div>

      <WizardFormControl
        mt={4}
        onPrev={prevStep}
        isLoading={stepConfig.isSubmittingForm}
      />
    </Root>
  )
}

export default Address
