import { ClickButtonPolicyCancel } from '../../../../../domain/models'
import { Dispatcher, Service } from '../../../../protocols'

export type IClickButtonPolicyCancelService = Service<ClickButtonPolicyCancel>

/**
 * Select Policy Cancel Reason
 *
 * @category Dashboard
 */
export class ClickButtonPolicyCancelService
  implements IClickButtonPolicyCancelService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @param {ClickButtonPolicyCancel} data
   * @function
   */
  execute(data: ClickButtonPolicyCancel): void {
    this.dispatcher.execute(
      'Area logada web - Clique no botão de cancelar apólice',
      data,
    )
  }
}
