import { PaymentTimeoutError } from './payment-timeout-error'

export class PaymentInvoicesTimeoutError extends PaymentTimeoutError {
  constructor() {
    super(
      'Não conseguimos processar seu pagamento. Tente novamente ou tente utilizar outra forma de pagamento',
    )
    this.name = 'PaymentInvoicesTimeoutError'
  }
}

export class PaymentInvoiceDataError extends PaymentTimeoutError {
  constructor() {
    super(
      'Não conseguimos recuperar dados de pagamento. Tente novamente ou tente utilizar outra forma de pagamento',
    )
    this.name = 'PaymentInvoiceDataError'
  }
}
