import { Dispatcher, Service } from '../../../../protocols'

export type IUpsellViewSimulationPageProfessionSalary = Service<void>

/**
 * View simulation page profession salary
 *
 * @category Upsell
 */
export class UpsellViewSimulationPageProfessionSalary
  implements IUpsellViewSimulationPageProfessionSalary
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   */
  execute(): void {
    this.dispatcher.execute('Upsell - Visualização da tela "Dados iniciais 2"')
  }
}
