import { IQuotationsRepository } from '../../../../data/repositories'
import { Quotation } from '../../../../domain'

export class FakeQuotationsRepository implements IQuotationsRepository {
  input: any
  output: any

  async loadById(quotationId: string): Promise<Quotation> {
    this.input = quotationId
    return this.output
  }
}
