import {
  ReferralShareCodeService,
  ReferralCopyCodeService,
} from '../../../../../../data/services'
import { makeMixpanelDispatcherEvent } from '../../../../infra'

export const makeReferralShareCode = () =>
  new ReferralShareCodeService(makeMixpanelDispatcherEvent())

export const makeReferralCopyCode = () =>
  new ReferralCopyCodeService(makeMixpanelDispatcherEvent())
