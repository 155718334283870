import {
  CheckoutPaymentFrequency,
  CheckoutProvider,
  CheckoutServiceSource,
  Creditcard,
  ICheckoutProviderFactory,
  Mapper,
  PaymentContractError,
  PaymentMethod,
  Services,
} from '@azos/core'
import { Utils as SharedUtils } from '@azos/shared'
import { ICheckoutRepository } from '@data/repositories'
import { CreateContractUseCase } from '@domain/usecases'
import * as Sentry from '@sentry/nextjs'

type Params = {
  proposalId: string
  paymentMethod: PaymentMethod
  creditcard?: Creditcard
  checkoutServiceSource?: CheckoutServiceSource
  expiresAt?: string
  paymentFrequency: CheckoutPaymentFrequency
  discountConfigurationId?: string
  priceWithDiscount?: number
}
type Response = CreateContractUseCase.Response

export type ICreateContractService = Services<Params, Response>

export class CreateContractService implements ICreateContractService {
  private checkoutProvider?: CheckoutProvider

  constructor(
    private readonly checkoutProviderFactory: ICheckoutProviderFactory,
    private readonly checkoutRepository: ICheckoutRepository,
    private readonly createCreditcardMapper: Mapper,
  ) {}

  async execute({
    proposalId,
    paymentMethod,
    checkoutServiceSource,
    expiresAt,
    paymentFrequency,
    creditcard,
    discountConfigurationId,
    priceWithDiscount,
  }: Params): Promise<Response> {
    try {
      let paymentToken: string | undefined = undefined

      if (paymentMethod === PaymentMethod.CREDIT_CARD && creditcard) {
        this.checkoutProvider = this.checkoutProviderFactory(
          checkoutServiceSource,
        )

        this.checkoutProvider!.build()

        const { id } = await this.checkoutProvider!.createToken(
          this.createCreditcardMapper.map(creditcard),
        ).catch(error => {
          Sentry.captureException(error)
          throw new PaymentContractError('Falha ao gerar token de pagamento')
        })

        paymentToken = id
      }

      const payload = SharedUtils.object.returnNotEmptyObjectProps({
        proposalId,
        paymentMethod,
        paymentToken,
        expiresAt,
        paymentFrequency,
        discountConfigurationId,
        priceWithDiscount,
      }) as CreateContractUseCase.Params

      return await this.checkoutRepository.createContract(payload)
    } catch (err) {
      throw new PaymentContractError()
    }
  }

  public dispose() {
    this.checkoutProvider?.destroy()
  }
}
