import { Dispatcher, Service } from '../../../../protocols'

export type IUpsellSharePageService = Service<void>

/**
 * View Upsell Share Page
 *
 * @category Upsell
 */
export class UpsellSharePageService implements IUpsellSharePageService {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   */
  execute(): void {
    this.dispatcher.execute(
      'Upsell - Visualização da tela "Para quem? Indicação"',
    )
  }
}
