import React from 'react'

const SvgComponent = (props: any) => (
  <svg
    width="102"
    height="20"
    viewBox="0 0 102 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M31.4345 4.49955H44.6593L31.2094 16.6649V19.5627H52.4756V15.4893H39.1675L52.4756 3.46091V0.425984H31.4345V4.49955ZM12.8502 0.42642L0.980469 19.5627H6.8029L8.91234 15.9819H22.3573L24.4668 19.5627H30.2892L18.4195 0.42642H12.8502ZM15.6348 12.8234C14.9465 12.8234 14.2737 12.625 13.7015 12.2534C13.1292 11.8817 12.6832 11.3535 12.4198 10.7355C12.1564 10.1175 12.0875 9.43753 12.2218 8.78145C12.3561 8.1254 12.6875 7.52277 13.1742 7.04975C13.6608 6.57676 14.2809 6.25467 14.9559 6.12417C15.6309 5.99364 16.3305 6.06064 16.9664 6.31663C17.6023 6.57261 18.1457 7.00611 18.5281 7.56226C18.9105 8.11846 19.1145 8.77236 19.1145 9.44127C19.1145 10.3383 18.7479 11.1985 18.0953 11.8328C17.4428 12.467 16.5576 12.8234 15.6348 12.8234ZM65.4188 0C57.8708 0 53.1754 3.97379 53.1754 9.99998C53.1754 16.0262 57.8708 20 65.4188 20C72.9669 20 77.6622 16.0262 77.6622 9.99998C77.6622 3.97379 72.9669 0 65.4188 0ZM65.4188 15.8952C60.8811 15.8952 58.1403 13.6026 58.1403 9.99998C58.1403 6.39738 60.8811 4.1048 65.4188 4.1048C69.9569 4.1048 72.6973 6.39738 72.6973 9.99998C72.6973 13.6026 69.9569 15.8952 65.4188 15.8952ZM89.8239 7.83296C86.7962 7.68363 84.3396 7.49499 84.3396 5.92116C84.3396 4.66354 86.449 4.1048 89.9648 4.1048C93.5369 4.1048 95.5057 5.12839 95.5057 6.71397H100.512C100.512 2.75 96.406 0 89.9648 0C83.7487 0 79.3329 2.23057 79.3329 6.13997C79.3329 11.5718 85.0728 11.81 89.8239 11.9686C93.1784 12.0806 96.0401 12.1528 96.0401 13.9858C96.0401 15.3253 93.9024 15.8994 89.8239 15.8994C85.9987 15.8994 84.0578 14.8605 84.0578 13.2749H78.8274C78.7991 17.2116 83.0182 19.9998 89.8529 19.9998C96.8846 19.9998 101.02 17.9493 101.02 13.8207C101.02 8.21222 94.6864 8.07294 89.8239 7.83296Z"
      fill="#00B000"
    />
  </svg>
)

export default SvgComponent
