import React from 'react'

import { Box } from '@Azos-Seguros/apollo'
import { firebase } from '@azos/core'
import { Tooltip } from '@azos/shared'
import { Button, Input } from '@azos/shared/src/components/v2'
import axios from 'axios'
import { Form, Formik, FormikValues } from 'formik'

import { useAuth, useUserConfirmation } from '../../../hooks'
import { Link } from '../../atoms'
import { EmailLoginProps, initialValues } from './EmailLogin.props'
import { Root } from './EmailLogin.styles'
import { validationSchema } from './EmailLogin.validation'

const message = (errorMessage: string) => {
  switch (errorMessage) {
    case 'signInWithEmailAndPassword failed: First argument "email" must be a valid string.':
      return 'O e-mail informado é inválido'
    case 'There is no user record corresponding to this identifier. The user may have been deleted.':
      return 'O e-mail informado não foi encontrado'
    case 'The password is invalid or the user does not have a password.':
      return 'A senha informada está incorreta'
    default:
      return 'Houve um problema ao autenticar'
  }
}

const isInvalidEmailCode = (code: string) => code === 'auth/invalid-email'

const EmailLogin: React.VFC<EmailLoginProps> = ({ ...rest }) => {
  const { onError, onInfo, isLoading } = useAuth()
  const { confirmUser } = useUserConfirmation()

  const [showPassword, setShowPassword] = React.useState(false)

  const handleSubmit = React.useCallback(
    async (values: FormikValues) => {
      let error = ''
      let success = false

      try {
        confirmUser()
        await firebase
          .auth()
          .signInWithEmailAndPassword(values.email, values.password)
        success = true
      } catch (e: any) {
        error = message(e.message)
      }

      try {
        if (!success) {
          const { data } = await axios.post(
            `${process.env.BFF_BASE_URL}/temporary-password`,
            values,
          )
          await firebase.auth().signInWithCustomToken(data.customToken)
        }
      } catch (e: any) {
        onError?.(error)
      }
    },
    [confirmUser, onError],
  )

  const handlePasswordReset = React.useCallback(
    async (email: string) => {
      try {
        await firebase.auth().sendPasswordResetEmail(email)
        onInfo?.('Um link de recuperação de senha foi enviado ao seu e-mail')
      } catch (e: any) {
        const isInvalid = isInvalidEmailCode(e.code)
        const message = isInvalid
          ? 'O e-mail informado é inválido'
          : 'Houve uma falha ao tentar enviar o link para recuperação de senha'
        onError?.(message)
      }
    },
    [onError, onInfo],
  )

  return (
    <Root {...rest}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({
          values,
          handleChange,
          handleBlur,
          errors,
          touched,
          isSubmitting,
        }) => (
          <>
            <Form>
              <Input
                name="email"
                type="email"
                label="E-mail"
                placeholder="Seu e-mail pessoal"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.email && !!errors.email}
                helperText={touched.email ? errors.email : ''}
                disabled={isSubmitting || isLoading}
                fullWidth
              />

              <div className="password-wrapper">
                <Input
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  label="Senha"
                  placeholder="Sua senha"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.password && !!errors.password}
                  helperText={touched.password ? errors.password : ''}
                  disabled={isSubmitting || isLoading}
                  fullWidth
                />

                <Tooltip
                  title={showPassword ? 'Esconder senha' : 'Mostrar senha'}
                  tooltipContent={
                    <button
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                      className={
                        touched.password && !!errors.password ? 'error' : ''
                      }
                    >
                      <span
                        className={showPassword ? 'icon-hide' : 'icon-show'}
                      />
                    </button>
                  }
                />
              </div>

              <Button
                fullWidth
                type="submit"
                disabled={isSubmitting || isLoading}
              >
                Entrar
              </Button>
            </Form>

            <Box className="forgot_password">
              <Link
                href="#forgot_password"
                onClick={() => handlePasswordReset(values.email)}
              >
                Esqueceu sua senha?
              </Link>
            </Box>
          </>
        )}
      </Formik>
    </Root>
  )
}

export default EmailLogin
