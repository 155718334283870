import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled('div')(
  ({ theme }) => css`
    background: ${theme.palette.neutral[100]};

    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
  `,
)

export const Content = styled('div')(
  ({ theme }) => css`
    padding: 40px;
    text-align: center;

    display: flex;
    flex-direction: column;
    flex: 1;

    div.shared-buttons {
      margin: ${theme.spacing[4]}px auto 0 auto;
      max-width: 160px;

      display: flex;
      flex-direction: column;

      > a + a {
        margin-top: ${theme.spacing[2]}px;
      }
    }
  `,
)
