const isServer = typeof window === 'undefined'

export const formatProtocol = (url: string): string | undefined => {
  if (!isServer) {
    const { protocol } = window.location
    return url.replace(/https?:\/\//, `${protocol}//`)
  }
}

export const downloadFile = (url: string): void => {
  if (!isServer) window.open(formatProtocol(url), '_blank')
}
