import React from 'react'

import { useAuth } from '@azos/account'
import { withModal } from '@azos/shared'
import { useGuardian } from '@presentation/providers/GuardianProvider'

import ProtectedRequestHelpListModal from './ProtectedRequestHelpListModal'
import { ProtectedRequestHelpListModalAdapterProps } from './ProtectedRequestHelpListModal.props'

const ProtectedRequestHelpListModalAdapter: React.VFC<
  ProtectedRequestHelpListModalAdapterProps
> = props => {
  const { protectedList, isGuardian } = useGuardian()
  const { user } = useAuth()

  return (
    <ProtectedRequestHelpListModal
      {...props}
      protectedList={protectedList}
      isGuardian={isGuardian}
      user={user}
    />
  )
}

export default withModal<ProtectedRequestHelpListModalAdapterProps>(
  ProtectedRequestHelpListModalAdapter,
)
