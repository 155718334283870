import SliderBase, { sliderClasses, SliderProps } from '@mui/material/Slider'
import { styled } from '@mui/material/styles'

const iOSBoxShadow =
  '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)'

const iOSBoxShadowActived =
  '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)'

export const Root = styled(SliderBase)<SliderProps>(({ theme }) => ({
  color: theme.palette.common.black,
  height: 2,
  padding: '15px 0',

  [`& .${sliderClasses.thumb}`]: {
    height: 24,
    width: 24,
    backgroundColor: theme.palette.common.black,
    boxShadow: iOSBoxShadow,
    '&:focus, &:hover, &.Mui-active': {
      boxShadow: iOSBoxShadowActived,
      '@media (hover: none)': {
        boxShadow: iOSBoxShadow,
      },
    },
  },

  [`& .${sliderClasses.mark}`]: {
    display: 'none',
  },

  [`& .${sliderClasses.markLabel}`]: {
    fontSize: '0.715rem',
    color: theme.palette.common.black,
  },

  [`& .${sliderClasses.valueLabel}`]: {
    display: 'none',
  },

  [`& .${sliderClasses.track}`]: {
    border: 'none',
  },

  [`& .${sliderClasses.rail}`]: {
    height: 2,
    backgroundColor: theme.palette.grey[200],
  },
}))
