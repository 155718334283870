import React from 'react'

import { makeStorageProvider } from '@azos/core'
import { ParsedUrlQuery } from 'querystring'

type useDashboardTokenProps = Partial<{
  qs: ParsedUrlQuery
}>

const STORAGE_UPSELL_REDIRECT = '@azos/upsellRedirect'

const storageProvider = makeStorageProvider({}, { crossDomain: true })

const useUpsellRedirect = ({ qs }: useDashboardTokenProps) => {
  const [isRedirectedFromSimulation, setIsRedirectedFromSimulation] =
    React.useState(false)

  const getQuery = React.useCallback(async () => {
    const query = String(qs?.from ?? '')
    const storage = storageProvider.get(STORAGE_UPSELL_REDIRECT)

    if (query) {
      storageProvider.set(STORAGE_UPSELL_REDIRECT, query)
      setIsRedirectedFromSimulation(!!(query === 'simulacao'))
    } else if (storage) {
      setIsRedirectedFromSimulation(!!(storage === 'simulacao'))
    }
  }, [qs])

  const dismissRedirect = React.useCallback(async () => {
    storageProvider.remove(STORAGE_UPSELL_REDIRECT)
    setIsRedirectedFromSimulation(false)
  }, [])

  React.useEffect(() => {
    getQuery()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qs])

  return {
    isRedirectedFromSimulation,
    dismissRedirect,
  }
}

export { useUpsellRedirect }
