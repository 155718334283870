import React from 'react'

import { Skeleton, Typography } from '@Azos-Seguros/apollo'
import analytics from '@azos/analytics'
import { ViewBannerChangePaymentType } from '@azos/analytics/src/domain/models'
import { CheckoutPaymentFrequency, PaymentMethod } from '@azos/core'
import { Link, useTheme } from '@azos/shared'
import { Badge, Button } from '@azos/shared/src/components/v2'
import { BillingServiceSource, PolicyDetailsStatus } from '@domain/models'
import { azosPhone } from '@main/config/contact'
import { makeListPossibleExpiryDatesService } from '@main/factories/services'
import { useBanners } from '@presentation/providers/BannersProvider'
import { usePayment } from '@presentation/providers/PaymentProvider'
import { PoliciesAPI } from '@services/policies'
import dayjs from 'dayjs'
import Image from 'next/image'

import * as creditCardIcons from '../../../../assets/icons/creditCard'
import {
  PolicyCardEditPayment,
  PolicyCardPaymentEditExpiry,
} from '../PolicyChangePayment/components'
import { PolicyCardCreditCard } from './components'
import { PolicyPaymentDetailsProps } from './PolicyPaymentDetails.props'
import { Root } from './PolicyPaymentDetails.styles'
import { mapperBrandToIcon } from './utils'

const listPossibleExpiryDatesService = makeListPossibleExpiryDatesService()

const PolicyPaymentDetails: React.VFC<PolicyPaymentDetailsProps> = ({
  billingValue,
  payment,
  policyId,
  proposalId,
  status,
  //
  modalChangePayment,
  setModalChangePayment,
  //
  disabledPaymentActions = false,
  className,
  billingServiceSource,
  invoices,
  paymentFrequency,
  billingDueDate,
  billingDueDateFrequency,
}) => {
  const theme = useTheme()
  const { isPaymentChangeActive, isPaymentDateChangeActive } = usePayment()
  const { changePaymentBanner } = useBanners()

  const [modalChangeDatePayment, setModalChangeDatePayment] =
    React.useState(false)
  const [modalCreditCard, setModalCreditCard] = React.useState(false)

  const [days, setDays] = React.useState<string[]>([])
  const [expiresAt, setExpiresAt] = React.useState('')
  const [listExpiryDatesError, setListExpiryDatesError] = React.useState(false)

  const [paymentData, setPaymentData] = React.useState<{
    brand: string
    lastFourDigits: string
  } | null>(null)

  const [isLoadingCreditCard, setIsLoadingCreditCard] = React.useState(false)

  const isCreditCard = React.useMemo(
    () => payment?.paymentMethod === PaymentMethod.CREDIT_CARD,
    [payment],
  )

  const hasCreditCard = React.useMemo(
    () => !!paymentData?.lastFourDigits,
    [paymentData?.lastFourDigits],
  )

  const paymentInfo = React.useMemo(() => {
    if (isCreditCard && !hasCreditCard) {
      return {
        title: 'Cartão de crédito',
        icon: 'icon-credit-card',
      }
    }

    if (payment) {
      const info = {
        [PaymentMethod.BANK_SLIP]: { title: 'Boleto', icon: 'icon-boleto' },
        [PaymentMethod.PIX]: { title: 'Pix', icon: 'icon-pix' },
        [PaymentMethod.CREDIT_CARD]: {
          title: 'Cartão de crédito',
          icon: 'icon-credit-card',
        },
      }

      return info[payment.paymentMethod]
    }
  }, [payment, hasCreditCard, isCreditCard])

  const creditCardIcon = React.useMemo(() => {
    if (payment?.paymentMethod === PaymentMethod.CREDIT_CARD) {
      const icon = (creditCardIcons as any)[
        `${paymentData?.brand?.toLowerCase()}`
      ]

      if (icon) return icon
    }

    return null
  }, [payment, paymentData])

  const canShowPaymentChange =
    status.type === 'success' &&
    !disabledPaymentActions &&
    (!isCreditCard ||
      (isCreditCard && hasCreditCard) ||
      (isCreditCard && billingServiceSource === BillingServiceSource.VINDI)) &&
    isPaymentChangeActive

  const canShowPaymentDateChange =
    status.type === 'success' &&
    !disabledPaymentActions &&
    !listExpiryDatesError &&
    expiresAt !== 'invalid date' &&
    isPaymentDateChangeActive &&
    paymentFrequency !== CheckoutPaymentFrequency.ANNUAL

  const canAddCreditCard =
    status.type === 'success' &&
    !disabledPaymentActions &&
    isCreditCard &&
    !hasCreditCard

  const isPaymentUnavailable =
    !canShowPaymentChange &&
    !canShowPaymentDateChange &&
    !canAddCreditCard &&
    status?.type === 'success'

  const getExpiresAtFromLastInvoice = React.useCallback(() => {
    if (!invoices || invoices.length === 0) return ''

    const lastInvoice = invoices[invoices.length - 1]

    const dateParts = (lastInvoice.dueDate as string).split('/')
    const dateObject = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0])

    return dateObject
  }, [invoices])

  React.useEffect(() => {
    if (paymentData) return

    if (billingServiceSource === BillingServiceSource.VINDI) {
      setIsLoadingCreditCard(true)
      PoliciesAPI()
        .getVindiCreditCard({
          proposalId,
        })
        .then(creditCard => {
          setPaymentData({
            brand: mapperBrandToIcon(creditCard.brand),
            lastFourDigits: creditCard.lastFourDigits,
          })
        })
        .finally(() => {
          setIsLoadingCreditCard(false)
        })
      return
    }

    if (
      billingServiceSource === BillingServiceSource.IUGU &&
      payment?.paymentInfo
    ) {
      setPaymentData({
        brand: mapperBrandToIcon(payment?.paymentInfo?.brand),
        lastFourDigits: payment?.paymentInfo?.displayNumber.replace(/\D/gm, ''),
      })
      return
    }

    setPaymentData(null)
  }, [billingServiceSource, payment?.paymentInfo, paymentData, proposalId])

  React.useEffect(() => {
    if (days.length) return

    setListExpiryDatesError(false)
    listPossibleExpiryDatesService
      .execute({
        id: policyId,
        proposalId: proposalId,
        billingService: billingServiceSource,
      })
      .then(response => {
        let expiresAt: string | Date = ''

        if (!response.expiresAt) {
          expiresAt = getExpiresAtFromLastInvoice()
        } else {
          expiresAt = response.expiresAt
        }

        setDays(response.possibleDates)
        setExpiresAt(
          dayjs(expiresAt).locale('pt-br').format('DD [de] MMMM').toLowerCase(),
        )
      })
      .catch(() => {
        setListExpiryDatesError(true)
      })
  }, [
    billingServiceSource,
    days.length,
    getExpiresAtFromLastInvoice,
    policyId,
    proposalId,
  ])

  React.useEffect(() => {
    analytics.dashboard.payments.view.viewPaymentDetailsModal.execute()
  }, [])

  React.useEffect(() => {
    if (canShowPaymentChange && changePaymentBanner) {
      analytics.dashboard.payments.view.viewBannerChangePayment.execute({
        tela: ViewBannerChangePaymentType.POLICY,
      })
    }
  }, [canShowPaymentChange, changePaymentBanner])

  const onChangePaymentClick = () => {
    setModalChangePayment(true)
    analytics.dashboard.payments.change.changePaymentModalDetails.execute()
  }

  const paymentNumber = paymentData?.lastFourDigits || ''
  const paymentCharacters = '●●●● ●●●● ●●●●'

  const billingText =
    paymentFrequency === CheckoutPaymentFrequency.MONTHLY ? (
      <>
        Sua fatura vence no{' '}
        <span className="content-amount">dia {billingDueDateFrequency}</span> de
        todo mês
      </>
    ) : (
      <>
        A data de renovação será no{' '}
        <span className="content-amount">dia {billingDueDate}</span>
      </>
    )

  return (
    <Root
      $type={payment?.paymentMethod}
      $hasCreditCardIcon={!!creditCardIcon}
      className={className}
    >
      <div className="payment-data-content">
        <Badge
          className="payment-data-content--badge"
          borderRadius={4}
          color={theme.palette.neutral[200]}
          textColor={theme.palette.neutral[400]}
          icon="icon-dollar-sign"
          iconColor={theme.palette.neutral[400]}
        >
          Pagamento{' '}
          {paymentFrequency === CheckoutPaymentFrequency.MONTHLY
            ? 'mensal'
            : 'anual'}
        </Badge>
        <div className="content-header">
          {isLoadingCreditCard ? (
            <Skeleton width={250} height={36} />
          ) : (
            <>
              {payment?.paymentMethod === PaymentMethod.CREDIT_CARD &&
                creditCardIcon && (
                  <Image
                    className="credit-card-brand"
                    src={creditCardIcon}
                    width={44}
                    height={44}
                  />
                )}
              <div>
                <div className="content-title">
                  {(payment?.paymentMethod !== PaymentMethod.CREDIT_CARD ||
                    !creditCardIcon) && <i className={paymentInfo?.icon} />}
                  <Typography variant="body3">{paymentInfo?.title}</Typography>
                </div>

                {hasCreditCard && (
                  <div className="content-creditcard-number">
                    <Typography variant="caption2">
                      {paymentCharacters}
                    </Typography>
                    <Typography variant="bodyBold2">{paymentNumber}</Typography>
                  </div>
                )}
              </div>
            </>
          )}
        </div>

        <div className="content">
          <p>
            {status?.type !== PolicyDetailsStatus.error
              ? 'Sua próxima cobrança será no valor de'
              : 'Sua última cobrança foi no valor de'}{' '}
            <span className="content-amount">{billingValue}</span>
          </p>

          <p>{billingText}</p>

          <div className="content-actions">
            {canShowPaymentChange && (
              <Button
                onClick={onChangePaymentClick}
                variant="tertiary"
                fullWidth
                className="content-button"
              >
                Alterar meio de pagamento
              </Button>
            )}

            {canShowPaymentDateChange && (
              <Button
                onClick={() => setModalChangeDatePayment(true)}
                fullWidth
                variant="tertiary"
                className="content-button"
              >
                Alterar data de vencimento
              </Button>
            )}
          </div>

          {isPaymentUnavailable && (
            <>
              <div className="contact-content">
                <Link
                  href={`tel:+${azosPhone}`}
                  title="Ligar para central de atendimento"
                  textAlign="center"
                >
                  <i className="icon-dial-phone" /> Central de Atendimento
                </Link>
                <Link
                  href={`https://api.whatsapp.com/send?phone=${azosPhone}`}
                  title="Abrir Whatsapp"
                  textAlign="center"
                >
                  <i className="icon-whatsapp" /> Whatsapp
                </Link>
              </div>
            </>
          )}

          {canAddCreditCard && (
            <Button
              onClick={() => setModalCreditCard(true)}
              fullWidth
              variant="tertiary"
              className="content-button"
            >
              Adicionar cartão
            </Button>
          )}
        </div>
      </div>

      <PolicyCardEditPayment
        open={modalChangePayment}
        onClose={() => setModalChangePayment(false)}
        id={policyId}
        payment={payment || null}
        billingServiceSource={billingServiceSource}
      />

      <PolicyCardPaymentEditExpiry
        id={policyId}
        proposalId={proposalId}
        days={days}
        open={modalChangeDatePayment}
        onClose={() => setModalChangeDatePayment(false)}
        onSuccess={() => setModalChangeDatePayment(false)}
        billingService={billingServiceSource}
      />

      <PolicyCardCreditCard
        open={modalCreditCard}
        onClose={() => setModalCreditCard(false)}
        id={policyId}
        billingServiceSource={billingServiceSource}
      />
    </Root>
  )
}

export default PolicyPaymentDetails
