import { IBeneficiariesRepository } from '../../../../data/repositories'
import { CreateBeneficiaries, LoadKinships } from '../../../../domain/usecases'

export class FakeBeneficiariesRepository implements IBeneficiariesRepository {
  params: any = null
  output: any = null

  async createBeneficiaries(
    data: CreateBeneficiaries.Params,
  ): Promise<CreateBeneficiaries.Response> {
    this.params = data
    return Promise.resolve()
  }

  async loadKinships(): Promise<LoadKinships.Result> {
    return this.output
  }
}
