import { ViewReasonCancellation } from '../../../../../domain/models'
import { Dispatcher, Service } from '../../../../protocols'

export type IViewReasonCancellationService = Service<ViewReasonCancellation>

/**
 * View Reason Cancellation
 *
 * @category Dashboard
 */
export class ViewReasonCancellationService
  implements IViewReasonCancellationService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @param {ViewReasonCancellation} data
   * @function
   */
  execute(data: ViewReasonCancellation): void {
    this.dispatcher.execute(
      'Area logada web - Visualização da tela de seleção de motivo do cancelamento',
      data,
    )
  }
}
