import * as array from './array'
import * as currency from './currency'
import * as dataNames from './dataNames'
import * as date from './date'
import * as delay from './delay'
import * as download from './download'
import * as formatter from './formatter'
import * as number from './number'
import * as object from './object'
import * as progress from './progress'
import * as sanitizer from './sanitizer'
import * as similarity from './similarity'
import * as transient from './transient'
import * as validator from './validator'

export const Utils = {
  array,
  currency,
  dataNames,
  date,
  delay,
  download,
  formatter,
  number,
  object,
  progress,
  sanitizer,
  similarity,
  validator,
  transient,
}
