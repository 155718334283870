import {
  CheckoutProvider,
  CreatePaymentTokenResponse,
} from '../../../data/protocols'
import { PaymentCreditCardParams } from '../../../domain/models'

export class FakeCheckoutProvider implements CheckoutProvider {
  params: any
  responseCreateToken: CreatePaymentTokenResponse =
    {} as CreatePaymentTokenResponse

  build(): void {}

  destroy(): void {}

  async createToken(
    params: PaymentCreditCardParams,
  ): Promise<CreatePaymentTokenResponse> {
    this.params = params
    return this.responseCreateToken
  }
}
