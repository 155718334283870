import Box, { BoxProps } from '@mui/material/Box'

import { css, styled } from '../../../styles'

export const Root = styled(Box)<BoxProps>(
  ({ theme }) => css`
    background: ${theme.palette.background.paper};
    border-radius: 8px;
    padding: ${theme.spacing(4, 6)};

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    margin: 0 auto;

    .container__content-group + .container__content-group {
      margin-top: 1rem;
    }

    .container__content-group {
      display: flex;
      align-items: baseline;

      & > * + * {
        margin-left: 4px;
      }
    }
  `,
)
