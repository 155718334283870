import { css, Utils } from '@azos/shared'
import styled from '@emotion/styled'

type DataItemProps = {
  $column?: number
}

export const Root = styled('div')(
  ({ theme }) => css`
    .personal-data-changeable {
      &__grid {
        display: flex;
        flex-flow: row wrap;
        width: 100%;
      }

      &__content {
        &-text {
          font-size: 0.875rem;
        }

        span {
          color: ${theme.palette.neutral[400]};
        }

        &--divider {
          margin: 12px 0;
          border: 1px solid ${theme.palette.neutral[100]};
        }

        &--text {
          min-height: 42px;
          position: relative;
          font-size: 0.875rem;
        }

        &--append {
          cursor: pointer;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }

        &--info {
          width: 100%;
          margin: 12px 0;
          padding: ${theme.spacing[3]}px;
          background: ${theme.palette.neutral[50]};
        }
      }
    }
  `,
)

export const DataItem = styled(
  'div',
  Utils.transient.transientStyleProps,
)<DataItemProps>(
  ({ $column = 1 }) => css`
    box-sizing: border-box;
    margin: 0px;
    flex-direction: row;
    flex-basis: ${$column === 1 ? '100%' : '50%'};
    flex-grow: 0;
    max-width: ${$column === 1 ? '100%' : '50%'};
  `,
)
