import styled from '@emotion/styled'

import { css } from '../../../styles'
import { Text, TextProps } from '../Text'

export const Root = styled('button')(
  ({ theme }) => css`
    background: ${theme.palette.neutral[50]};
    border-color: ${theme.palette.neutral[50]};
    border-style: solid;
    border-width: 1px;
    border-radius: 8px;
    cursor: pointer;

    transition: 'border 0.3s';

    color: ${theme.palette.neutral[500]};
    font-weight: ${theme.typography.fontWeightMedium};
    padding: ${theme.spacing[3]}px;

    display: flex;
    align-items: center;

    &:disabled {
      color: ${theme.palette.neutral[200]};
      span {
        color: inherit;
      }
    }

    &:hover {
      border-color: ${theme.palette.neutral[500]};
    }

    svg {
      font-size: 20px;
    }

    div.button-select__content {
      display: flex;
      align-items: center;
      flex-direction: row;
      width: 100%;
    }

    svg {
      margin-top: 2px;
    }
    svg + div,
    i + div,
    & + svg,
    & + i {
      margin-left: ${theme.spacing[2]}px;
    }
  `,
)

export const Description = styled(Text)<TextProps>(
  ({ theme }) => css`
    color: ${theme.palette.neutral[400]};
    display: block;
    text-align: left;
  `,
)

Description.defaultProps = { variant: 'caption' }
