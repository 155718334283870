import React from 'react'

import { Utils } from '@azos/shared'
import { PaperProps } from '@mui/material'

import { Icon } from '../../icons'
import { CoverageProps } from './Coverage.props'
import { Drawer, IconButton } from './Coverage.styles'

const Coverage: React.FCC<CoverageProps> = ({
  children,
  parentName,
  //
  open,
  dark = false,
  //
  onClose,
  //
  PaperProps,
  showExit = true,
  ...rest
}) => {
  const drawProps = {
    'data-action': Utils.dataNames.renderDataName('draw', parentName),
  }
  const paperPropsAttr = {
    'data-action': Utils.dataNames.renderDataName('info-modal', parentName),
  }

  const paperPropsStyle = !dark
    ? PaperProps
    : { ...PaperProps, ...{ style: { backgroundColor: '#000' } } }

  const paperProps: PaperProps = Object.assign(
    {},
    PaperProps,
    paperPropsAttr,
    paperPropsStyle,
  )

  return (
    <Drawer
      {...drawProps}
      {...rest}
      PaperProps={paperProps}
      anchor="right"
      onClose={onClose}
      open={open}
    >
      {showExit && (
        <IconButton
          className="close"
          title="Fechar"
          onClick={onClose}
          edge="end"
        >
          <Icon.Close />
        </IconButton>
      )}
      <div className="coverage__content">{children}</div>
    </Drawer>
  )
}

export default Coverage
