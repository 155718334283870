import faker from 'faker'

import { SWRServices } from '../../../data/protocols'

export class FakeSWRServices<T = any, R = any> implements SWRServices<T, R> {
  private path = faker.random.word().toLocaleLowerCase()

  params: any
  response: any

  constructor(private readonly route: string = '/') {}

  async execute(params?: any): Promise<any> {
    this.params = params
    return this.response
  }

  getUrl(params?: any): string {
    return `${this.route}/${this.path}/${params}`
  }
}
