import React from 'react'

import { Utils } from '@azos/shared'
import MuiPopper from '@mui/material/Popper'

import { PopperProps } from './Popper.props'

const Popper: React.VFC<PopperProps> = ({ parentName, ...props }) => {
  return (
    <MuiPopper
      data-action={Utils.dataNames.renderDataName('popper', parentName)}
      {...props}
    />
  )
}

export default Popper
