import { IInsuredUserRepository } from '../../../../data/repositories'
import { CreateInsuredUser, InsuredUser } from '../../../../domain'

export class FakeInsuredUserRepository implements IInsuredUserRepository {
  params: any
  output: any

  async loadInsuredUser(): Promise<InsuredUser> {
    return this.output
  }

  async createInsuredUser(
    params: CreateInsuredUser.Params,
  ): Promise<CreateInsuredUser.Response> {
    this.params = params
    return this.output
  }
}
