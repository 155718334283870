import { Dispatcher, Service } from '../../../../protocols'

export type ICancelPolicyService = Service<void>

/**
 * Cancel Policy
 *
 * @category Dashboard
 */
export class CancelPolicyService implements ICancelPolicyService {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @param
   * @function
   */
  execute(): void {
    this.dispatcher.execute('Area logada web - Clique para cancelar apólice')
  }
}
