import {
  ViewImportantInfoBannerService,
  ClickImportantInfoBannerService,
} from '../../../../../data/services'
import { makeMixpanelDispatcherEvent } from '../../../infra'

export const makeViewImportantInfoBannerService = () =>
  new ViewImportantInfoBannerService(makeMixpanelDispatcherEvent())

export const makeClickImportantInfoBannerService = () =>
  new ClickImportantInfoBannerService(makeMixpanelDispatcherEvent())
