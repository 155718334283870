import {
  LoginService,
  RegistrationService,
} from '../../../data/services/account'
import { makeDispatcherEvent } from '../infra/dispatcher-event-factory'

export const makeLoginService = () => new LoginService(makeDispatcherEvent())

export const makeRegistrationService = () =>
  new RegistrationService(makeDispatcherEvent())
