import {
  css,
  Modal as SharedModal,
  ModalProps as SharedModalProps,
} from '@azos/shared'
import styled from '@emotion/styled'

export const Modal = styled(SharedModal)<SharedModalProps>(
  ({ theme }) => css`
    .modal-content {
      .modal-content-wrapper {
        margin: 0;
        position: relative;
      }
    }

    .exit-button-modal {
      position: absolute;
      right: ${theme.spacing[1]}px;
      top: ${theme.spacing[1]}px;

      cursor: pointer;
      background: transparent;
      border: 0;
      border-radius: 50%;
      font-size: 1.5rem;

      padding: 0;
      height: 48px;
      width: 48px;

      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-size: 1.25rem;
      }

      @media (min-width: ${theme.breakpoints.md}px) {
        top: ${theme.spacing[4]}px;
        right: ${theme.spacing[4]}px;

        span {
          font-size: 1.5rem;
        }
      }
    }
  `,
)
