import React from 'react'

import { useNotification } from '@Azos-Seguros/apollo'
import { CardCopyText, Modal, useMediaQuery } from '@azos/shared'
import { Button, ButtonSelect, Text } from '@azos/shared/src/components/v2'
import { azosEmail, azosPhone, formattedAzosPhone } from '@main/config/contact'

import { CallCenterModalProps } from './CallCenterModal.props'
import { Root } from './CallCenterModal.styles'

const CallCenterModal: React.FCC<CallCenterModalProps> = ({
  onClose,
  ...props
}) => {
  const { isMobile } = useMediaQuery()
  const { addNotification } = useNotification()

  const onSuccessCopy = (copyType: string) =>
    addNotification({
      variant: 'success',
      description: `${copyType} copiado com sucesso`,
    })

  return (
    <Modal
      {...props}
      onClose={onClose}
      variant={isMobile ? 'bottomDrawer' : 'modal'}
      maxWidth={isMobile ? undefined : '480px'}
      showCloseButton={false}
      showHeader={false}
    >
      <Root>
        <div className="call-center-modal__title">
          <Text className="call-center-modal__title-content" variant="h5">
            Central de atendimento
          </Text>
          <i className="icon-exit" onClick={onClose} />
        </div>
        <Text variant="body2" className="call-center-modal__description">
          Entre em contato com a gente que te ajudamos a resolver seus
          problemas:
        </Text>

        <div className="call-center-modal__content">
          <div className="call-center-modal__copy-content">
            <CardCopyText
              startIcon="icon-dial-phone"
              value={azosPhone}
              customLabel={formattedAzosPhone}
              className="call-center-modal__card-copy"
              shouldShowSuccessLabel={false}
              onClick={() => onSuccessCopy('Telefone')}
            />
            <CardCopyText
              startIcon="icon-email"
              value={azosEmail}
              className="call-center-modal__card-copy"
              shouldShowSuccessLabel={false}
              onClick={() => onSuccessCopy('E-mail')}
            />
            <ButtonSelect
              title="Conversar no whatsapp"
              startIcon={<i className="icon-whatsapp" />}
              endIcon={<i className="icon-arrow-topright endIcon" />}
              className="call-center-modal__button-select"
              onClick={() =>
                window.open(`https://api.whatsapp.com/send?phone=${azosPhone}`)
              }
            />
          </div>
        </div>

        <Button
          onClick={onClose}
          fullWidth
          className="call-center-modal__button"
        >
          Fechar
        </Button>
      </Root>
    </Modal>
  )
}

export default CallCenterModal
