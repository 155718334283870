import {
  SimulateInsuranceClickService,
  ViewAboutAzosService,
  ViewAzosContactService,
  ViewCoverageQuestionsService,
  ViewHomepageService,
  ViewProductCoverageDetailsService,
} from '../../../../../data/services'
import { makeMixpanelDispatcherEvent } from '../../../infra'

export const makeSimulateInsuranceClickService = () =>
  new SimulateInsuranceClickService(makeMixpanelDispatcherEvent())

export const makeViewAboutAzosService = () =>
  new ViewAboutAzosService(makeMixpanelDispatcherEvent())

export const makeViewAzosContactService = () =>
  new ViewAzosContactService(makeMixpanelDispatcherEvent())

export const makeViewCoverageQuestionsService = () =>
  new ViewCoverageQuestionsService(makeMixpanelDispatcherEvent())

export const makeViewHomepageService = () =>
  new ViewHomepageService(makeMixpanelDispatcherEvent())

export const makeViewProductCoverageDetailsService = () =>
  new ViewProductCoverageDetailsService(makeMixpanelDispatcherEvent())
