import { Services } from '@azos/core'
import { IProposalsRepository } from '@data/repositories'
import { LoadBillingDateUseCase } from '@domain/usecases'

type Params = LoadBillingDateUseCase.Params
type Response = LoadBillingDateUseCase.Response

export type ILoadBillingDateService = Services<Params, Response>

export class LoadBillingDateService implements ILoadBillingDateService {
  constructor(private readonly proposalsRepository: IProposalsRepository) {}

  async execute(params: Params): Promise<Response> {
    const response = await this.proposalsRepository.loadBillingDate(params)

    return response
  }
}
