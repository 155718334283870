import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
  cancel_text: Yup.string()
    .test({
      name: 'cancel_text',
      test: value => !!value && value.trim() === 'CANCELAR',
      message: 'Preencha com a palavra CANCELAR',
    })
    .required('Campo obrigatório'),
})
