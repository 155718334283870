import React from 'react'

import { Typography } from '@Azos-Seguros/apollo'

import { HighlightedStringProps } from './HighlightedString.props'

const HighlightedString: React.FC<HighlightedStringProps> = ({
  text,
  tag = 'span',
  highlight,
  className,
  color = 'primary',
  variant = 'bodyBold2',
}) => {
  const startIndex = highlight
    ? text.toLowerCase().indexOf(highlight.toLowerCase())
    : -1

  if (startIndex === -1) {
    return (
      <Typography variant={variant} tag={tag}>
        {text}
      </Typography>
    )
  }

  const endIndex = startIndex + (highlight ? highlight.length : 0)

  const beforeHighlight = text.slice(0, startIndex)
  const highlightedPart = text.slice(startIndex, endIndex)
  const afterHighlight = text.slice(endIndex)

  return (
    <Typography className={className} variant={variant} tag={tag}>
      {beforeHighlight}
      <Typography variant={variant} tag={tag} color={color}>
        {highlightedPart}
      </Typography>
      {afterHighlight}
    </Typography>
  )
}

export default HighlightedString
