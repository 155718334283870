import React from 'react'

import { ConfigContext } from './PaymentProvider'

export const usePayment = () => {
  const context = React.useContext(ConfigContext)

  if (!context) {
    throw new Error('The usePayment should within PaymentProvider')
  }

  return context
}
