import styled from '@emotion/styled'

import { css } from '../../../styles'

export const Root = styled('label')(
  ({ theme }) => css`
    position: relative;
    display: inline-block;
    width: 48px;
    height: 24px;

    .switch {
      &__input {
        opacity: 0;
        width: 0;
        height: 0;
      }

      &__slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: ${theme.palette.neutral[200]};
        transition: 0.4s;
        border-radius: 24px;
      }

      &__slider:before {
        position: absolute;
        content: '';
        height: 16px;
        width: 16px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        transition: 0.4s;
        border-radius: 50%;
      }
    }

    .switch__input:checked + .switch__slider {
      background-color: ${theme.palette.primary.main};
    }

    .switch__input:focus + .switch__slider {
      box-shadow: 0 0 1px ${theme.palette.primary.main};
    }

    .switch__input:checked + .switch__slider:before {
      transform: translateX(24px);
    }
  `,
)
