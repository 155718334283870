import { ClickPaymentType } from '../../../../../domain/models'
import { Dispatcher, Service } from '../../../../protocols'

export type IUpsellClickPaymentTypeService = Service<ClickPaymentType>

/**
 * Click Payment Type
 *
 * @category Upsell
 */
export class UpsellClickPaymentTypeService
  implements IUpsellClickPaymentTypeService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   * @param {ClickPaymentType} data
   */
  execute(data: ClickPaymentType): void {
    this.dispatcher.execute(
      'Upsell - Clique forma de pagamento em checkout',
      data,
    )
  }
}
