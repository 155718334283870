import { ContinueLifeStyleQuestion } from '../../../../../domain/models'
import { Dispatcher, Service } from '../../../../protocols'

export type IUpsellContinueLifeStyleQuestionService =
  Service<ContinueLifeStyleQuestion>

/**
 * Continue Life Style Question
 *
 * @category Upsell
 */
export class UpsellContinueLifeStyleQuestionService
  implements IUpsellContinueLifeStyleQuestionService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   * @param {ContinueLifeStyleQuestion} data
   */
  execute({ questionNumber, type }: ContinueLifeStyleQuestion): void {
    this.dispatcher.execute(
      `Upsell - Clique "continuar" em DPS estilo de vida ${questionNumber}`,
      { type: type },
    )
  }
}
