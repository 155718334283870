import * as checkout from './checkout'
import * as coverages from './coverages'
import * as currency from './currency'
import * as date from './date'
import * as numbers from './numbers'
import * as payment from './payment'
import * as queryString from './queryString'
import * as sanitizer from './sanitizer'
import * as validator from './validator'

export const Utils = {
  checkout,
  coverages,
  currency,
  date,
  numbers,
  payment,
  queryString,
  sanitizer,
  validator,
}
