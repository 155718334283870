import { ViewPersonalDataBottomSheet } from '../../../../domain/models'
import { Dispatcher, Service } from '../../../protocols'

export type IViewPersonalDataBottomSheetService =
  Service<ViewPersonalDataBottomSheet>

/**
 * View Personal Data Bottomsheet
 *
 * @category PersonalData
 */
export class ViewPersonalDataBottomSheetService
  implements IViewPersonalDataBottomSheetService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @param {ViewPersonalDataBottomSheet} data
   * @function
   */
  execute(data: ViewPersonalDataBottomSheet): void {
    this.dispatcher.execute('Web - Bottom sheets de Sua conta', data)
  }
}
