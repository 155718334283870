import React from 'react'

import { useWizard } from '../../hooks'

type WizardProps = {
  children: React.ReactNode[]
}

const WizardSwipper = ({ children }: WizardProps) => {
  const { step: currentStep } = useWizard()

  const elements = React.useMemo(
    () => (Array.isArray(children) ? children : [children]),
    [children],
  )

  const firstElement = elements[currentStep]

  return <div>{firstElement}</div>
}

export default WizardSwipper
