import { Dispatcher, Service } from '../../../../protocols'

export type IUpsellClickDetailsCoverageService = Service<void>

/**
 * Click Details Coverage
 *
 * @category Upsell
 */
export class UpsellClickDetailsCoverageService
  implements IUpsellClickDetailsCoverageService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   */
  execute(): void {
    this.dispatcher.execute(
      'Upsell - Clique "mais detalhes do pedido" em cobertura',
    )
  }
}
