import { css, styled } from '../../styles'
import { PlacementPosition } from './Tooltip.props'

type ContainerProps = {
  placement: PlacementPosition
}

const setPlacementPosition = (placamentValue: PlacementPosition) => {
  const placementSplitted = placamentValue.split('-')

  switch (placementSplitted[0]) {
    case 'top': {
      const topStyle = { bottom: 'calc(100% + 16px)' }
      if (placementSplitted[1] === 'start') {
        return css`
          ${topStyle};
          left: 0;
        `
      }

      if (placementSplitted[1] === 'end') {
        return css`
          ${topStyle};
          right: 0;
        `
      }

      return css`
        ${topStyle};
        left: 50%;
        transform: translateX(-50%);
      `
    }
    case 'right': {
      const rightStyle = { left: 'calc(100% + 16px)' }
      if (placementSplitted[1] === 'start') {
        return css`
          ${rightStyle};
          top: 0;
        `
      }

      if (placementSplitted[1] === 'end') {
        return css`
          ${rightStyle};
          bottom: 0;
        `
      }

      return css`
        ${rightStyle};
        top: 50%;
        transform: translateY(-50%);
      `
    }
    case 'bottom': {
      const bottomStyle = { top: 'calc(100% + 16px)' }
      if (placementSplitted[1] === 'start') {
        return css`
          ${bottomStyle};
          left: 0;
        `
      }

      if (placementSplitted[1] === 'end') {
        return css`
          ${bottomStyle};
          right: 0;
        `
      }

      return css`
        ${bottomStyle};
        left: 50%;
        transform: translateX(-50%);
      `
    }
    case 'left': {
      const leftStyle = { right: 'calc(100% + 16px)' }
      if (placementSplitted[1] === 'start') {
        return css`
          ${leftStyle};
          top: 0;
        `
      }

      if (placementSplitted[1] === 'end') {
        return css`
          ${leftStyle};
          bottom: 0;
        `
      }

      return css`
        ${leftStyle};
        top: 50%;
        transform: translateY(-50%);
      `
    }
  }
}

export const Container = styled('div')<ContainerProps>(
  ({ placement }) => css`
    .container__tooltip {
      visibility: hidden;
      opacity: 0;
      transition: visibility 0.25s linear, opacity 0.25s linear;
      position: absolute;
      max-width: 300px;
      word-wrap: break-word;
      background: #090a0b;
      color: #d0d4dc;
      box-shadow: 0 2px 4px #d0d4dc;
      padding: 8px;
      font-size: 14px;
      border-radius: 8px;

      & > * {
        min-width: 150px;
      }

      ${setPlacementPosition(placement)};
    }

    .container__content {
      position: relative;
    }

    .container__content:hover {
      .container__tooltip {
        visibility: visible;
        opacity: 1;
      }
    }
  `,
)
