import { Box, BoxProps } from '@Azos-Seguros/apollo'

import { css, styled } from '../../../styles'

export const ButtonRoot = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;

    & > div + div {
      margin-top: ${theme.spacing[3]}px;
    }

    .radio-button {
      &__error-message {
        margin-top: ${theme.spacing[3]}px;
        color: ${theme.palette.status.error.main};
      }
    }
  `,
)

export const Root = styled(Box)<BoxProps>(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr;
    gap: 8px;

    &.column-1 {
      grid-template-columns: 1fr;
    }

    &.column-2 {
      grid-template-columns: 1fr 1fr;
    }

    &.column-flex {
      grid-template-columns: 1fr;
    }

    button {
      height: 100%;
    }

    @media (min-width: ${theme.breakpoints.md}px) {
      grid-template-columns: 1fr 1fr;
    }
  `,
)

export const Label = styled('label')(
  () => css`
    background-color: '#FFF';
    border-radius: 8;
    border-style: solid;
    border-width: 1;
    padding: 16px;
    position: relative;
  `,
)

export const Input = styled('input')(
  () => css`
    display: none;
    visibility: hidden;
  `,
)

Input.defaultProps = {
  type: 'radio',
}
