import React from 'react'

import { Input } from '@Azos-Seguros/apollo'

import {
  CheckboxSelect,
  CheckboxSelectData,
  CheckboxSelectProps,
  ListSelect,
  ListSelectData,
  ListSelectProps,
  RadioButton,
  RadioButtonData,
  RadioButtonProps,
  Select,
} from '../..'
import { FieldType } from '../../../../models'
import { ListOptionsProps, ListOptionType } from './ListOptions.props'

const ListOptions = ({ type, value, onChange, ...rest }: ListOptionsProps) => {
  const handleRadioChange = React.useCallback(
    (value: RadioButtonData) => {
      onChange?.([value])
    },
    [onChange],
  )

  const handleListSelectChange = React.useCallback(
    (value: ListSelectData | null) => {
      onChange?.(!!value ? [value] : [])
    },
    [onChange],
  )

  const handleCheckboxSelectChange = React.useCallback(
    (value: CheckboxSelectData[] | null) => {
      onChange?.(!!value ? value : [])
    },
    [onChange],
  )

  const handleInputChange = React.useCallback(
    e => {
      onChange?.(!!e.target.value ? e.target.value : '')
    },
    [onChange],
  )

  const handleSelectChange = React.useCallback(
    e => {
      onChange?.(!!e.target.value ? [e.target.value] : [''])
    },
    [onChange],
  )

  const renderOptions = React.useCallback(
    (type?: string) => {
      switch (type) {
        case FieldType.SINGLE_CHOICE:
          return (
            <RadioButton
              {...(rest as RadioButtonProps)}
              value={value?.[0]}
              onChange={handleRadioChange}
            />
          )
        case ListOptionType.RADIO_MULTIPLE:
          return (
            <ListSelect
              {...(rest as ListSelectProps)}
              value={value?.[0]}
              onChange={handleListSelectChange}
            />
          )

        case FieldType.MULTIPLE_CHOICE:
          return (
            <CheckboxSelect
              {...(rest as CheckboxSelectProps)}
              value={value}
              onChange={handleCheckboxSelectChange}
            />
          )
        case FieldType.OPEN_TEXT:
          return (
            <Input
              autoFocus
              autoComplete="off"
              {...rest}
              placeholder="Escreva aqui"
              value={value.toString()}
              onChange={handleInputChange}
              fullWidth
            />
          )
        case FieldType.COUNTRY_CHOICE:
        case FieldType.SELECT_CHOICE:
          return (
            <Select
              {...rest}
              label="Selecione"
              value={value.toString()}
              onChange={handleSelectChange}
              options={rest.options.map(i => ({
                value: i.value as string | number,
                label: i.label,
              }))}
              fullWidth
            />
          )
        default:
          return <></>
      }
    },
    [
      handleCheckboxSelectChange,
      handleInputChange,
      handleListSelectChange,
      handleRadioChange,
      handleSelectChange,
      rest,
      value,
    ],
  )

  return renderOptions(type)
}

export default ListOptions
