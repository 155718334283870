import React from 'react'

import dayjs from 'dayjs'

import { InputDate } from '../InputDate'
import { InputBirthdateProps } from './InputBirthdate.props'

const InputBirthdate = ({ children, ...rest }: InputBirthdateProps) => {
  return (
    <InputDate
      disableFuture
      disableOpenPicker
      openTo="year"
      views={['year', 'day']}
      maxDate={dayjs().subtract(18, 'y')}
      minDate={dayjs().subtract(66, 'y').add(1, 'day')}
      {...rest}
    />
  )
}

export default InputBirthdate
