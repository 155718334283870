import {
  CheckoutProvider,
  PaymentContractError,
  PaymentMethod,
  Services,
  Utils,
} from '@azos/core'
import { IPaymentsRepository } from '@data/repositories'
import { ChangePaymentMethodV2UseCase } from '@domain/usecases'
import * as Sentry from '@sentry/nextjs'

type Params = {
  id: string
  email?: string
  paymentMethod: PaymentMethod
  data?: {
    number: string
    verificationValue: string
    name: string
    monthYear: string
  }
}
type Response = ChangePaymentMethodV2UseCase.Response

export type IChangePaymentMethodV2Service = Services<Params, Response>

export class ChangePaymentMethodV2Service
  implements IChangePaymentMethodV2Service
{
  constructor(
    private readonly paymentsRepository: IPaymentsRepository,
    private readonly checkoutProvider: CheckoutProvider,
  ) {
    checkoutProvider.build()
  }

  async execute(params: Params): Promise<Response> {
    const { data: creditcardData, email, ...payload } = params

    if (email) Sentry.setUser({ email })

    let paymentToken: string | undefined = undefined

    if (params.paymentMethod === PaymentMethod.CREDIT_CARD) {
      const creditcard =
        creditcardData &&
        Utils.checkout.formatCreditcard({
          ccname: creditcardData.name,
          ccnumber: creditcardData.number,
          cvv: creditcardData.verificationValue,
          exp_date: creditcardData.monthYear,
        })

      if (!creditcard) {
        throw new PaymentContractError('Cartão de crédito indefinido')
      }

      const { id } = await this.checkoutProvider
        .createToken(creditcard)
        .catch(error => {
          Sentry.captureException(error)
          throw new PaymentContractError('Falha ao gerar token de pagamento')
        })

      paymentToken = id
    }

    return await this.paymentsRepository.changePaymentMethodV2({
      ...payload,
      paymentToken,
    })
  }

  dispose(): void {
    this.checkoutProvider.destroy()
  }
}
