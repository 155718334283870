import { CustomAction } from '../../../domain/models'
import { Dispatcher, Service } from '../../protocols'

export type ICustomService = Service<CustomAction>

/**
 * Custom Event
 *
 * @category Custom
 */
export class CustomService implements ICustomService {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @param {CustomAction} data
   * @function
   */
  execute(data: CustomAction): void {
    this.dispatcher.execute('custom', data)
  }
}
