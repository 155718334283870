import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;

    .broker-referral-content {
      &__description {
        color: ${theme.palette.neutral[400]};
      }

      &__form {
        margin: ${theme.spacing[4]}px 0;

        & > * + * {
          margin-top: ${theme.spacing[2]}px;
        }
      }

      &__button {
        margin-top: ${theme.spacing[4]}px;
        height: 48px;
      }
    }
  `,
)
