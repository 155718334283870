import { Dispatcher, Service } from '../../protocols'

export type ISaveNewBeneficiaryModalService = Service<void>

/**
 * Save New Beneficiary modal
 *
 * @category Dashboard
 */
export class SaveNewBeneficiaryModalService
  implements ISaveNewBeneficiaryModalService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @param
   * @function
   */
  execute(): void {
    this.dispatcher.execute(
      'Area logada web - Clique para salvar novo beneficiário',
    )
  }
}
