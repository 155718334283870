import React from 'react'

import { Typography } from '@Azos-Seguros/apollo'

import { ProtectedListCardProps } from './ProtectedListCard.props'
import { Root } from './ProtectedListCard.styles'

const ProtectedListCard: React.VFC<ProtectedListCardProps> = ({
  items,
  onItemCLick,
  clickable = false,
}) => {
  return (
    <Root $cliclabke={clickable} data-testid="protected-list">
      {items.map((item, index) => (
        <div
          className="protected-list-card__item"
          key={index}
          onClick={() => onItemCLick(item)}
        >
          <div className="protected-list-card__item-content">
            <Typography variant="bodyBold3">{item.name}</Typography>
            <div className="protected-list-card__item-content--details">
              <Typography variant="caption">{item.kinship}</Typography>
              {item.city && (
                <>
                  <Typography variant="caption">・</Typography>
                  <Typography variant="caption">{item.city}</Typography>
                </>
              )}
            </div>
          </div>
          <i className="icon-arrow-topright" />
        </div>
      ))}
    </Root>
  )
}

export default ProtectedListCard
