import { HttpClient } from '@azos/core'
import {
  GetCoverageDetailsService,
  ListBenefitsService,
  ListProductCardsService,
} from '@data/services'
import { CoverageModalService } from '@data/services/cms/coverage-modal'
import { ListNewsBannersService } from '@data/services/cms/list-news-banners'
import {
  ListSliderCardsByIdService,
  ListSliderCardsService,
} from '@data/services/cms/list-slider-cards'
import { makeCmsRepository } from '@main/factories/repositories'

export const makeListBenefitsService = (httpClient?: HttpClient) =>
  new ListBenefitsService(makeCmsRepository(httpClient))

export const makeListNewsBannersService = (httpClient?: HttpClient) =>
  new ListNewsBannersService(makeCmsRepository(httpClient))

export const makeListSliderCardsService = (httpClient?: HttpClient) =>
  new ListSliderCardsService(makeCmsRepository(httpClient))

export const makeListSliderCardsByIdService = () =>
  new ListSliderCardsByIdService(makeCmsRepository())

export const makeCoverageModalService = () =>
  new CoverageModalService(makeCmsRepository())

export const makeGetCoverageDetailsService = () =>
  new GetCoverageDetailsService(makeCmsRepository())

export const makeListProductCardsService = () =>
  new ListProductCardsService(makeCmsRepository())
