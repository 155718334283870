import { ClearSaleResponseTime } from '../../../../../domain/models'
import { Dispatcher, Service } from '../../../../protocols'

export type IUpsellClearSaleResponseTimeService = Service<ClearSaleResponseTime>

/**
 * Clear Sale Response Time
 *
 * @category Checkout
 */
export class UpsellClearSaleResponseTimeService
  implements IUpsellClearSaleResponseTimeService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @param {ClearSaleResponseTime} data
   * @function
   */
  execute(data: ClearSaleResponseTime): void {
    this.dispatcher.execute('Upsell - Tempo de resposta ClearSale', data)
  }
}
