import React from 'react'
import { useTransition, animated } from 'react-spring'

import { useWindowSize, useOutsideClick } from '../../../hooks'
import { Icon } from '../../../icons'
import theme from '../../../styles/b2c'
import { HeaderProps, MenuProps } from './Header.props'
import Root from './Header.styles'

const Header: React.FCC<HeaderProps> = ({
  children,
  homeUrl = '/',
  broker,
  hiddenSignIn,
  menu,
  ...rest
}) => {
  return (
    <Root {...rest}>
      <div className="header-logo">
        <a
          data-action="header-logo"
          data-testid="header-logo"
          href={homeUrl}
          target="_self"
        >
          {broker ? <Icon.AzosCorretores /> : <Icon.Azos />}
        </a>
      </div>

      <div className="header-menu">
        {menu && menu.length > 0 && <Menu menu={menu} />}
        {!hiddenSignIn && <div className="header-user-menu">{children}</div>}
      </div>
    </Root>
  )
}

const Menu: React.VFC<{ menu: MenuProps[] }> = ({ menu }) => {
  const { width } = useWindowSize()
  const [isOpen, setIsOpen] = React.useState(false)
  const ref = React.useRef<HTMLDivElement>(null)

  const transition = useTransition(
    isOpen || width >= (theme?.breakpoints?.values?.md || 784),
    {
      from: { opacity: 0, transform: 'scaleY(0.6)' },
      enter: { opacity: 1, transform: 'scaleY(1)' },
      leave: { opacity: 0, transform: 'scaleY(0.6)' },
      config: { duration: 150 },
    },
  )

  useOutsideClick({
    ref,
    config: {
      useEscapeKey: true,
    },
    handler: React.useCallback(() => setIsOpen(false), [setIsOpen]),
  })

  return (
    <nav ref={ref}>
      <button onClick={() => setIsOpen(!isOpen)} />

      {transition(
        (styles, item) =>
          item && (
            <animated.ul style={styles}>
              {menu.map(item => (
                <li key={item.label}>
                  <a
                    href={item.href}
                    target={item.target || '_self'}
                    title={item.title || ''}
                  >
                    {item.label}
                  </a>
                </li>
              ))}
            </animated.ul>
          ),
      )}
    </nav>
  )
}

export default Header
