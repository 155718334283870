type getFilteredUnfilteredResponse<T> = {
  filtered: T[]
  unfiltered: T[]
}

export const getFilteredUnfiltered = <T = any>(
  array: T[],
  filter: (arg: T) => boolean,
): getFilteredUnfilteredResponse<T> => {
  return (array as T[]).reduce(
    (res: getFilteredUnfilteredResponse<T>, item) => {
      res[filter(item) ? 'filtered' : 'unfiltered'].push(item)
      return res
    },
    { filtered: [], unfiltered: [] },
  )
}
