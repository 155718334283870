import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;

    .insured-referral-content {
      &__description {
        color: ${theme.palette.neutral[400]};
      }
    }

    & > button {
      margin-top: ${theme.spacing[4]}px;
    }
  `,
)
