import React from 'react'

import { firebase } from '@azos/core'

import { useAuth, useUserConfirmation } from '../../../hooks'
import { Root } from './GoogleButton.styles'

const GoogleButton: React.VFC = () => {
  const { onError, isLoading } = useAuth()
  const { confirmUser } = useUserConfirmation()

  const handleGoogleLogin = React.useCallback(async () => {
    try {
      confirmUser()
      const provider = new firebase.auth.GoogleAuthProvider()
      await firebase.auth().signInWithPopup(provider)
    } catch (e) {
      onError?.('Houve um problema ao autenticar pelo Google')
    }
  }, [confirmUser, onError])

  return (
    <Root onClick={handleGoogleLogin} role="button" disabled={isLoading}>
      <span className="icon-google" />
      <span>Continuar com Google</span>
    </Root>
  )
}

export default GoogleButton
