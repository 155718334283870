import Box, { BoxProps } from '@mui/material/Box'

import { css, styled } from '../../styles'

export const Root = styled(Box)<BoxProps>(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    min-height: 72px;
    border-radius: 8px;
    padding: 0.75rem 1rem;

    &.variant-primary {
      background: #fff;
    }

    &.variant-secondary {
      background: transparent;
      border: 1px solid ${theme.palette.grey[200]};

      .product-resume__text-content {
        margin-top: ${theme.spacing(4)};
      }
    }

    ${theme.breakpoints.up('md')} {
      min-height: 72px;
    }

    .content__amount {
      display: flex;
      width: 100%;

      align-items: baseline;
      justify-content: space-between;

      .product-resume__text {
        width: 70%;
      }

      .product-resume__value {
        white-space: nowrap;
      }
    }

    .product-resume__text-content {
      width: 75%;
      margin-top: ${theme.spacing(2)};
    }
  `,
)
