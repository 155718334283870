import { FinishCashbackWithCreditcard } from '../../../domain/models'
import { Dispatcher, Service } from '../../protocols'

export type IFinishCashbackWithCreditcardService =
  Service<FinishCashbackWithCreditcard>

/**
 * Finish Cashback With Creditcard
 *
 * @category Checkout
 */
export class FinishCashbackWithCreditcardService
  implements IFinishCashbackWithCreditcardService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @param {FinishCashbackWithCreditcard} data
   * @function
   */
  execute(data: FinishCashbackWithCreditcard): void {
    this.dispatcher.execute('finish_cashback_with_creditcard', data)
  }
}
