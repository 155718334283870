import { PROPOSAL_COOKIE, Storage, SyncServices } from '@azos/core'

type Request = {
  proposalId: string
  quotationId: string
}
type Response = void

export type IProposalProducer = SyncServices<Request, Response>

export class ProposalProducer implements IProposalProducer {
  constructor(private readonly storage: Storage) {}

  execute(params?: Request): Response {
    if (params) this.storage.set(PROPOSAL_COOKIE, JSON.stringify(params))
    else this.storage.remove(PROPOSAL_COOKIE)
  }
}
