import React from 'react'

import dayjs from 'dayjs'
import parseFormat from 'dayjs/plugin/customParseFormat'

import { InputMask } from '../InputMask'
import { InputBirthdateProps } from './InputBirthdate.props'

dayjs.extend(parseFormat)

const InputBirthdate: React.FCC<InputBirthdateProps> = ({
  onChange,
  value,
  ...rest
}) => {
  const [inputValue, setInputValue] = React.useState<string>(
    dayjs(value).format('DD/MM/YYYY'),
  )

  const handleChange = React.useCallback(
    (value: any, inputText?: string) => {
      const data = dayjs(value.target.value, 'DD/MM/YYYY', true)
      const dateValue = data.isValid() ? data.toDate() : null
      setInputValue(value.target.value)
      onChange(dateValue, inputText)
    },
    [onChange],
  )

  return (
    <InputMask
      onChange={handleChange}
      mask="99/99/9999"
      placeholder="dd/mm/yyyy"
      value={inputValue}
      {...rest}
    />
  )
}

export default InputBirthdate
