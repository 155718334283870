import {
  CreateInsuredUserRequest,
  Gender,
  ICreateInsuredUserService,
  ILoadQuotationByIdService,
} from '@azos/core'

export type RegistrationValue = CreateInsuredUserRequest

export type RegistrationProps = {
  loadQuotationbyIdService: ILoadQuotationByIdService
  createInsuredUserService: ICreateInsuredUserService
  //
  onSuccessful?: () => Promise<void>
  onError?: () => void
}

export const INITIAL_VALUES: RegistrationValue = {
  email: '',
  full_name: '',
  birth_date: null,
  gender: Gender.MALE,
  phone: '',
  cpf: '',
  marital_status: '',
  social_name: '',
  authorizes_whatsapp_contact: 'false',
  is_politically_exposed_person: 'false',
  //
  cep: '',
  state: '',
  city: '',
  street: '',
  neighborhood: '',
  number: '',
  complement: '',
}

// export const INITIAL_VALUES: RegistrationValue = {
//   full_name: '',
//   birth_date: null,
//   gender: Gender.MALE,
//   phone: '(11) 21312-3123',
//   authorizes_whatsapp_contact: false,
//   cpf: '619.886.670-03',
//   marital_status: 'married',
//   is_politically_exposed_person: 'false',
//   //
//   cep: '09850-300',
//   state: '',
//   city: '',
//   street: '',
//   neighborhood: '',
//   number: '123',
//   complement: '',
// }
