import { Dispatcher, Service } from '../../protocols'

export type IViewAppDownloadModalService = Service<void>

/**
 * View app download modal
 *
 * @category Dashboard
 */
export class ViewAppDownloadModalService
  implements IViewAppDownloadModalService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @param
   * @function
   */
  execute(): void {
    this.dispatcher.execute(
      'Area logada web - Visualização do modal de download do app na home page',
    )
  }
}
