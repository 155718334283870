import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled('div')(
  ({ theme }) => css`
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: ${theme.palette.neutral[50]};

    .page-header__wrapper {
      padding: 0 !important;

      .page-header__tabs {
        & > div:first-child {
          background-color: ${theme.palette.neutral[400]};
        }
        .tabs-wrapper {
          margin-left: 4px;

          button {
            @media (min-width: 474px) {
              width: 220px;
            }

            color: #a3aab9;
            width: auto;
          }
        }
      }
    }

    .modal-guardian {
      &__about {
        background: ${theme.palette.primary[50]};
        padding: ${theme.spacing[3]}px;
        cursor: pointer;
        margin-bottom: ${theme.spacing[4]}px;

        i {
          font-size: 1.5rem;

          & > i {
            font-family: 'azos-icons';
            all: unset;
          }
        }

        &-description {
          margin-top: ${theme.spacing[2]}px;
        }

        i,
        &-title,
        &-description {
          color: ${theme.palette.primary[600]};
        }

        &-header {
          display: flex;
          justify-content: space-between;

          &-title {
            display: flex;
            align-items: center;

            i {
              margin-right: ${theme.spacing[3]}px;
            }
          }
        }
      }

      &__content {
        padding: 24px;

        flex: 1;

        background: ${theme.palette.neutral[50]};

        &-title {
          color: #505969;
          margin-bottom: 24px;
        }

        &--button {
          margin: 24px 0;

          button {
            padding: 14px;
          }
        }
      }
    }
  `,
)
