import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled('div')(
  ({ theme }) => css`
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;

    .modal-content {
      &__form,
      &__address .MuiCollapse-wrapperInner {
        display: flex;
        flex-direction: column;
        flex: 1;

        & > div + div {
          margin-top: 32px;
        }
      }

      &__form {
        margin: 24px -40px -40px;
        padding: 40px;
        background: ${theme.palette.neutral[100]};
      }

      &__description {
        color: ${theme.palette.neutral[400]};
        padding: 16px 0;
      }

      &__switch-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 350px;

        span {
          font-weight: ${theme.typography.fontWeightBold};
        }
      }

      &__button {
        margin-top: 40px;
        height: 48px;
      }
    }
  `,
)

export const ConfirmModalRoot = styled('div')(
  ({ theme }) => css`
    .confirm-modal-content__button-group {
      margin-top: 40px;
      display: flex;
      flex-direction: column;

      & > button + button {
        margin-top: 16px;
      }
    }
  `,
)
