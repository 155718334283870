import { SWRServices } from '@azos/core'
import { IPoliciesRepository } from '@data/repositories'
import { LoadPolicyByIdUseCase } from '@domain/usecases'

type Params = LoadPolicyByIdUseCase.Params
type Response = LoadPolicyByIdUseCase.Response

export type ILoadPolicyDetailsService = SWRServices<Params, Response>

export class LoadPolicyDetailsService implements ILoadPolicyDetailsService {
  constructor(private readonly policiesRepository: IPoliciesRepository) {}

  getUrl(params: Params): string {
    return `/insured/policy/${params?.policyId}`
  }

  async execute(params: Params): Promise<Response> {
    return await this.policiesRepository.loadById(params)
  }
}
