import { AlertProps } from '@Azos-Seguros/apollo'

export type BrokerContentFormValues = {
  name: string
  phone: string
}

export const initialValues: BrokerContentFormValues = {
  name: '',
  phone: '',
}

export type BrokerContentProps = {
  notify?: (message: Omit<AlertProps, 'open'>) => void
  maritalStatus: string
  isInsured: boolean
}
