import React from 'react'

import { Button, Modal, Typography } from '@Azos-Seguros/apollo'
import analytics from '@azos/analytics'
import { ClickContactHelpType } from '@azos/analytics/src/domain/models'
import { withModal } from '@azos/shared'
import { azosEmail, azosPhone } from '@main/config/contact'

import { ProtectedB2CHelpModalProps } from './ProtectedB2CHelpModal.props'
import { Root } from './ProtectedB2CHelpModal.styles'

const ProtectedB2CHelpModal: React.VFC<ProtectedB2CHelpModalProps> = ({
  user,
  isGuardian,
  ...props
}) => {
  React.useEffect(() => {
    analytics.dashboard.guardian.viewRequestProtectedHelp.execute({
      isInsured: !!user?.isInsured,
      isGuardian,
    })
  }, [isGuardian, user?.isInsured])

  const handlePhoneClick = () => {
    analytics.dashboard.guardian.clickContactHelp.execute({
      isInsured: !!user?.isInsured,
      isGuardian,
      tipo: ClickContactHelpType.CALL,
    })
    window.open(`tel:+${azosPhone}`)
  }

  const handleWhatsappClick = () => {
    analytics.dashboard.guardian.clickContactHelp.execute({
      isInsured: !!user?.isInsured,
      isGuardian,
      tipo: ClickContactHelpType.WHATSAPP,
    })
    window.open(`https://api.whatsapp.com/send?phone=${azosPhone}`, '_blank')
  }

  const handleEmailClick = () => {
    analytics.dashboard.guardian.clickContactHelp.execute({
      isInsured: !!user?.isInsured,
      isGuardian,
      tipo: ClickContactHelpType.EMAIL,
    })
    window.open(`mailto:${azosEmail}`)
  }

  return (
    <Modal {...props} maxWidth="392px" noGutter>
      <Root>
        <Typography variant="h4" className="protected-b2c-help-modal__title">
          Solicitar <strong>ajuda</strong>
        </Typography>
        <Typography
          variant="body3"
          className="protected-b2c-help-modal__description"
        >
          Para solicitar ajuda para este protegido, acione um dos contatos da
          Azos listados abaixo que te orientamos da melhor forma:
        </Typography>

        <div className="protected-b2c-help-modal__actions">
          <div
            className="protected-b2c-help-modal__actions--item"
            role="link"
            onClick={handlePhoneClick}
          >
            <div className="protected-b2c-help-modal__actions--item-content">
              <i className="icon-dial-phone" />
              <Typography variant="bodyBold3">Fazer uma ligação</Typography>
            </div>

            <i className="icon-arrow-topright redirect-icon" />
          </div>

          <div
            className="protected-b2c-help-modal__actions--item"
            role="link"
            onClick={handleWhatsappClick}
          >
            <div className="protected-b2c-help-modal__actions--item-content">
              <i className="icon-whatsapp" />
              <Typography variant="bodyBold3">Conversar no Whatsapp</Typography>
            </div>

            <i className="icon-arrow-topright redirect-icon" />
          </div>

          <div
            className="protected-b2c-help-modal__actions--item"
            role="link"
            onClick={handleEmailClick}
          >
            <div className="protected-b2c-help-modal__actions--item-content">
              <i className="icon-email" />
              <Typography variant="bodyBold3">Enviar um email</Typography>
            </div>

            <i className="icon-arrow-topright redirect-icon" />
          </div>

          <Button
            variant="outline"
            fullWidth
            type="button"
            onClick={props.onClose}
            className="protected-b2c-help-modal__close-button"
          >
            Fechar
          </Button>
        </div>
      </Root>
    </Modal>
  )
}

export default withModal<ProtectedB2CHelpModalProps>(ProtectedB2CHelpModal)
