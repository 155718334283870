import { ViewGuardianInstructions } from '../../../../domain/models'
import { Dispatcher, Service } from '../../../protocols'

export type IViewGuardianInstructionsService = Service<ViewGuardianInstructions>

/**
 * View guardian instructions
 *
 * @category Dashboard
 */
export class ViewGuardianInstructionsService
  implements IViewGuardianInstructionsService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @param {ViewGuardianHomepage} data
   * @function
   */
  execute(data: ViewGuardianInstructions): void {
    this.dispatcher.execute(
      'Area logada web - Visualizar tela de Por que ter um Guardião',
      data,
    )
  }
}
