import { Dispatcher, Service } from '../../../protocols'

export type IViewAddGuardianService = Service<void>

/**
 * View add guardian
 *
 * @category Dashboard
 */
export class ViewAddGuardianService implements IViewAddGuardianService {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @param
   * @function
   */
  execute(): void {
    this.dispatcher.execute(
      'Area logada web - Visualização da tela para adicionar guardião',
    )
  }
}
