import { makeCheckoutProvider, makeVindiCheckoutProvider } from '@azos/core'
import {
  ChangeExpiryDateService,
  ChangePaymentMethodService,
  ChangePaymentMethodV2Service,
  ListPossibleExpiryDatesService,
} from '@data/services'
import { makePaymentsRepository } from '@main/factories/repositories'

export const makeChangePaymentMethodService = () =>
  new ChangePaymentMethodService(
    makePaymentsRepository(),
    makeCheckoutProvider(),
  )

export const makeChangePaymentMethodV2Service = () =>
  new ChangePaymentMethodV2Service(
    makePaymentsRepository(),
    makeVindiCheckoutProvider(),
  )

export const makeChangeExpiryDateService = () =>
  new ChangeExpiryDateService(makePaymentsRepository())

export const makeListPossibleExpiryDatesService = () =>
  new ListPossibleExpiryDatesService(makePaymentsRepository())
