import React from 'react'

import { Typography } from '@Azos-Seguros/apollo'
import analytics from '@azos/analytics'

import { GuardianAboutModal } from '../../../GuardianAboutModal'
import { GuardiansTable } from '../GuardiansTable'
import { ProtectedTabAdapterProps } from './ProtectedTab.props'
import { Root } from './ProtectedTab.styles'

const ProtectedTab: React.VFC<ProtectedTabAdapterProps> = ({
  protectedList,
  user,
  isGuardian,
}) => {
  const [
    isGuardianRoleResponsabilityOpen,
    setIsGuardianRoleResponsabilityOpen,
  ] = React.useState(false)

  React.useEffect(() => {
    analytics.dashboard.guardian.viewProtected.execute({
      isInsured: !!user?.isInsured,
      isGuardian,
    })
  }, [isGuardian, user?.isInsured])

  return (
    <>
      <Root>
        <div className="protected-tab">
          <div className="protected-tab__content">
            <div
              className="protected-tab__about"
              role="button"
              onClick={() => setIsGuardianRoleResponsabilityOpen(true)}
            >
              <div className="protected-tab__about-header">
                <div className="protected-tab__about-header-title">
                  <i className="icon-user-heart" />

                  <Typography
                    variant="bodyBold3"
                    className="protected-tab__about-title"
                  >
                    O papel de um guardião
                  </Typography>
                </div>
                <i className="icon-help" />
              </div>
              <Typography
                variant="caption"
                className="protected-tab__about-description"
              >
                Entenda seu papel como guardião do seguro de alguém e como
                ajudar seus protegidos.
              </Typography>
            </div>
            <Typography
              variant="body3"
              className="protected-tab__content-description"
            >
              Para <strong>solicitar ajuda</strong> em nome das pessoas
              protegidas, <br />
              clique nas opções a seguir:
            </Typography>
            <GuardiansTable items={protectedList} />
          </div>
        </div>
      </Root>
      <GuardianAboutModal
        open={isGuardianRoleResponsabilityOpen}
        onClose={() => setIsGuardianRoleResponsabilityOpen(false)}
      />
    </>
  )
}

export default ProtectedTab
