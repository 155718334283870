import { CopyShareContent } from '../../../../../domain/models'
import { Dispatcher, Service } from '../../../../protocols'

export type IUpsellCopyShareContentService = Service<CopyShareContent>

/**
 * Copy Share Content
 *
 * @category Upsell
 */
export class UpsellCopyShareContentService
  implements IUpsellCopyShareContentService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   * @param {CopyShareContent} data
   */
  execute(data: CopyShareContent): void {
    this.dispatcher.execute(
      'Upsell - Clique copiar link em contratação para terceiro',
      data,
    )
  }
}
