export enum CoveragesCode {
  COD_0001_SEGURO_DE_VIDA = '0001',
  COD_0002_ASSISTENCIA_FUNERAL = '0002',
  COD_0003_INVALIDEZ = '0003',
  COD_0004_DOENCAS_GRAVES = '0004',
  COD_0005_DOENCAS_GRAVES_10 = '0005',
  COD_0006_DIH = '0006',
  // B2C
  COD_1001_SEGURO_DE_VIDA = '1001',
  COD_1002_ASSISTENCIA_FUNERAL = '1002',
  COD_1003_INVALIDEZ = '1003',
  COD_1005_DOENCAS_GRAVES_10 = '1005',
  COD_1006_DIH = '1006',
  COD_1009_SAFF = '1009',
  // B2B
  COD_2001_SEGURO_DE_VIDA = '2001',
  COD_2002_ASSISTENCIA_FUNERAL = '2002',
  COD_2003_INVALIDEZ = '2003',
  COD_2005_DOENCAS_GRAVES_10 = '2005',
  COD_2006_DIH = '2006',
  COD_2007_RIT = '2007',
  COD_2008_CIRURGIAS = '2008',
  COD_2009_SAFF = '2009',
  COD_2010_SEGURO_DE_VIDA_2 = '2010',
  COD_2011_DOENCAS_GRAVES_10 = '2011',
  COD_2012_MAC = '2012',
  COD_2013_INVALIDEZ_2 = '2013',
  COD_2014_IPTA_ESTENDIDA = '2014',
  COD_2015_DG30 = '2015',
  COD_2016_AFI = '2016',
  COD_2017_AFF = '2017',
}
