import { Dispatcher, Service } from '../../../../protocols'

export type IExitPolicyCancelService = Service<void>

/**
 * Exit Policy Cancel
 *
 * @category Dashboard
 */
export class ExitPolicyCancelService implements IExitPolicyCancelService {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @param
   * @function
   */
  execute(): void {
    this.dispatcher.execute(
      'Area logada web - Clique em fechar no modal com motivo de cancelamento da apólice',
    )
  }
}
