import { HomeFastQuotation } from '../../../domain/models'
import { Dispatcher, Service } from '../../protocols'

export type IHomeFastQuotationService = Service<HomeFastQuotation>

/**
 * Create a fast quotation on widget
 *
 * @category FastQuotation
 */
export class HomeFastQuotationService implements IHomeFastQuotationService {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}

  /**
   * Register event
   *
   * @param {HomeFastQuotation} data
   * @function
   */
  execute(data: HomeFastQuotation): void {
    this.dispatcher.execute('home_fast_quotation', data)
  }
}
