import dayjs from 'dayjs'

export const toISODate = (
  date: string | Date = new Date(Date.now()),
): string => {
  return dayjs(date).toISOString()
}

export const formatDate = (date: Date | string) => {
  return dayjs(date).add(5, 'h').format('DD/MM/YYYY')
}

export function resetTime(date: Date): Date {
  const data = new Date(date)
  data.setUTCHours(0, 0, 0, 0)
  return data
}
