import {
  ClickConfirmUpdateChangesService,
  ClickUpdateChangesService,
  ViewEditPersonalData,
  ViewPersonalDataBottomSheetService,
  ViewPersonalDataService,
  ViewResetPasswordService,
} from '../../../../../data/services'
import { makeMixpanelDispatcherEvent } from '../../../infra'

export const makeClickConfirmUpdateChangesService = () =>
  new ClickConfirmUpdateChangesService(makeMixpanelDispatcherEvent())

export const makeClickUpdateChangesService = () =>
  new ClickUpdateChangesService(makeMixpanelDispatcherEvent())

export const makeViewEditPersonalDataService = () =>
  new ViewEditPersonalData(makeMixpanelDispatcherEvent())

export const makeViewPersonalDataBottomSheetService = () =>
  new ViewPersonalDataBottomSheetService(makeMixpanelDispatcherEvent())

export const makeViewPersonalDataService = () =>
  new ViewPersonalDataService(makeMixpanelDispatcherEvent())

export const makeViewResetPasswordService = () =>
  new ViewResetPasswordService(makeMixpanelDispatcherEvent())
