import React from 'react'

import { Form, Formik, FormikHelpers } from 'formik'

import { useWizard } from '../../hooks'
import { FormProps as MyFormProps } from './Form.props'

type FormValues = any
type Props = MyFormProps<FormValues>

const MyForm: React.FCC<Props> = ({
  children,
  initialValues,
  initialSchema,
  onSubmit: finishSubmit,
}) => {
  const { nextStep, isLastStep, stepConfig } = useWizard()

  const validationSchema = React.useMemo(
    () => stepConfig.schema || initialSchema || null,
    [stepConfig.schema, initialSchema],
  )

  const onSubmit = React.useCallback(
    (data: any) => stepConfig.onSubmit?.(data) || Promise.resolve(),
    [stepConfig],
  )

  const handleSubmit = React.useCallback(
    async (values: FormValues, helpers: FormikHelpers<any>) => {
      await onSubmit(values)

      if (isLastStep) {
        await finishSubmit?.(values)
        return
      }

      helpers.setTouched({})
      nextStep()
    },
    [finishSubmit, isLastStep, nextStep, onSubmit],
  )

  return (
    <Formik
      initialValues={initialValues}
      validateOnMount={false}
      validateOnBlur={false}
      validateOnChange
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Form>{children}</Form>
    </Formik>
  )
}

export default MyForm
