import { Box, BoxProps } from '@Azos-Seguros/apollo'
import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled(Box)<BoxProps>(
  ({ theme }) => css`
    form {
      > div:not(:first-of-type) {
        margin-top: ${theme.spacing[2]}px;
      }

      .password-wrapper {
        position: relative;

        > button {
          cursor: pointer;
          position: absolute;
          right: 14px;
          top: 20px;
          background: transparent;
          border: 0;

          &.error {
            color: ${theme.palette.status.error.main};
          }
        }

        input {
          padding-right: 48px;
        }
      }

      > button {
        margin-top: ${theme.spacing[4]}px;
      }
    }

    .forgot_password {
      margin-top: ${theme.spacing[2]}px;
      text-align: right;
      width: 100%;

      div {
        margin-top: 0;
        text-align: right;
      }

      p {
        display: inline-block;
      }

      a {
        cursor: pointer;
        color: ${theme.palette.primary.main};
        font-weight: 600;
        text-decoration: none;
      }
    }
  `,
)
