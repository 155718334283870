import React from 'react'

import { WizardList } from '@data/models'
import { UpdateWizardUseCase } from '@domain/usecases'
import {
  makeListWizardService,
  makeUpdateWizardService,
} from '@main/factories/services'

export type OnboardingWizardContextData = {
  wizard?: WizardList
  setWizard: (wizard: WizardList) => void
  refreshSummary: () => Promise<void>
  updateSummary: (params: UpdateWizardUseCase.Params) => Promise<void>
  error: boolean
}

export const OnboardingWizardContext = React.createContext(
  {} as OnboardingWizardContextData,
)

const listWizardService = makeListWizardService()
const updateWizardService = makeUpdateWizardService()

const OnboardingWizardProvider: React.FCC = ({ children }) => {
  const [wizard, setWizard] = React.useState<WizardList>()
  const [error, setError] = React.useState(false)

  const refreshSummary = React.useCallback(async () => {
    setError(false)
    await listWizardService
      .execute()
      .then(wizard => setWizard(wizard))
      .catch(() => setError(true))
  }, [])

  const updateSummary = React.useCallback(
    async (params: UpdateWizardUseCase.Params) => {
      setError(false)
      await updateWizardService
        .execute(params)
        .then(wizard => setWizard(wizard))
        .catch(() => setError(true))
    },
    [],
  )

  return (
    <OnboardingWizardContext.Provider
      value={{
        error,
        wizard,
        setWizard,
        refreshSummary,
        updateSummary,
      }}
    >
      {children}
    </OnboardingWizardContext.Provider>
  )
}

export default OnboardingWizardProvider
