import { HttpClient } from '@azos/core'
import {
  CancelPolicyService,
  LoadPoliciesService,
  LoadPolicyDetailsService,
  UpdateBeneficiariesService,
} from '@data/services'
import { makePoliciesRepository } from '@main/factories/repositories'

export const makeLoadPoliciesService = (httpClient?: HttpClient) =>
  new LoadPoliciesService(makePoliciesRepository(httpClient))

export const makeLoadPolicyDetailsService = (httpClient?: HttpClient) =>
  new LoadPolicyDetailsService(makePoliciesRepository(httpClient))

export const makeUpdateBeneficiariesService = () =>
  new UpdateBeneficiariesService(makePoliciesRepository())

export const makeCancelPolicyService = () =>
  new CancelPolicyService(makePoliciesRepository())
