import React from 'react'

import { Button as ApolloButton, Spinner } from '@Azos-Seguros/apollo'
import analytics from '@azos/analytics'
import { Utils } from '@azos/shared'

import { ButtonProps } from './Button.props'

const Button = ({
  children,
  color = 'primary',
  variant = 'primary',
  loading = false,
  parentName,
  onClick,
  ...rest
}: ButtonProps) => {
  const handleClick = (
    params: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    if (parentName) {
      analytics.custom.action.execute({
        dataAction: Utils.dataNames.renderDataName('button', parentName),
      })
    }

    onClick?.(params)
  }

  return (
    <ApolloButton
      variant={variant}
      onClick={handleClick}
      {...rest}
      data-action={Utils.dataNames.renderDataName('button', parentName)}
    >
      {!loading ? children : <Spinner color="primary" size={28} />}
    </ApolloButton>
  )
}

export default Button
