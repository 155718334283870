import React from 'react'

import { useAuth } from '@azos/account'
import { useGuardian } from '@presentation/providers/GuardianProvider'

import NoPolicy from './NoPolicy'

const NoPolicyAdapter = () => {
  const { user } = useAuth()
  const { isGuardian } = useGuardian()

  return <NoPolicy isInsured={user?.isInsured} isGuardian={isGuardian} />
}

export default NoPolicyAdapter
