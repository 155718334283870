import { ViewHomepage } from '../../../../domain/models'
import { Dispatcher, Service } from '../../../protocols'

export type IViewHomepageService = Service<ViewHomepage>

/**
 * View homepage
 *
 * @category Home
 */
export class ViewHomepageService implements IViewHomepageService {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   * @param {ViewHomepage} data
   */
  execute(data: ViewHomepage): void {
    this.dispatcher.execute('Area logada web - Visualização de home page', data)
  }
}
