import { HubCreateContactNotifyRequest } from '../../../domain/models'
import { Utils } from '../../../utils'
import { Mapper } from '../../protocols'

type Request = {
  name: string
  email: string
  phone: string
  pageUri: string
  legalText: string
}
type Response = HubCreateContactNotifyRequest

export class CreateContactNotifyRequestMapper implements Mapper {
  map(params: Request): Response {
    const { name, email, phone, pageUri, legalText } = params

    return {
      fields: [
        {
          name: 'firstname',
          value: name,
        },
        {
          name: 'email',
          value: email,
        },
        {
          name: 'mobilephone',
          value: Utils.sanitizer.onlyNumber(phone),
        },
      ],
      context: {
        pageUri,
      },
      legalConsentOptions: {
        consent: {
          consentToProcess: true,
          text: legalText,
        },
      },
    }
  }

  mapCollection(response: Request[]): Response[] {
    return response.map(this.map.bind(this))
  }
}
