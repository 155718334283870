import { useEffect, useLayoutEffect, useState } from 'react'

import { NoSSRProps } from './noSSR.props'

// TODO: Change to apollo library when implemented
const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' ? useLayoutEffect : useEffect

const NoSSR: React.FCC<NoSSRProps> = ({
  children,
  defer = false,
  fallback = null,
}) => {
  const [isMounted, setMountedState] = useState(false)

  useIsomorphicLayoutEffect(() => {
    if (!defer) setMountedState(true)
  }, [defer])

  useEffect(() => {
    if (defer) setMountedState(true)
  }, [defer])

  return isMounted ? children : fallback
}

export default NoSSR
