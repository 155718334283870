import { styled } from '@mui/material/styles'
import { switchClasses } from '@mui/material/Switch'

export const StyledSwitch = styled('span')(({ theme }) => ({
  fontSize: 0,
  position: 'relative',
  display: 'inline-block',
  width: 48,
  height: 24,

  margin: 10,
  cursor: 'pointer',

  [`&.${switchClasses.disabled}`]: {
    opacity: 0.4,
    cursor: 'not-allowed',
  },

  [`& .${switchClasses.track}`]: {
    background: theme.palette.grey[200],
    borderRadius: 16,
    display: 'block',
    height: '100%',
    width: '100%',
    position: 'absolute',
  },

  [`& .${switchClasses.thumb}`]: {
    display: 'block',
    width: 20,
    height: 20,
    top: 2,
    left: 3,
    borderRadius: 16,
    backgroundColor: theme.palette.background.default,
    position: 'relative',
    transition: 'all 200ms ease',
  },

  [`&.${switchClasses.checked}`]: {
    [`.${switchClasses.thumb}`]: {
      left: 26,
      top: 2,
      backgroundColor: theme.palette.background.default,
    },

    [`.${switchClasses.track}`]: {
      background: theme.palette.primary.main,
    },
  },

  [`& .${switchClasses.input}`]: {
    cursor: 'inherit',
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    opacity: 0,
    zIndex: 1,
    margin: 0,
  },
}))
