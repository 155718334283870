import React from 'react'

import { makeUpdateBeneficiariesService } from '@main/factories/services'

import Beneficiaries from './BeneficiariesCard'
import { BeneficiariesCardProps } from './BeneficiariesCard.props'

type Props = Omit<BeneficiariesCardProps, 'updateBeneficiariesService'>

const BeneficiariesCardFactory: React.VFC<Props> = props => {
  return (
    <Beneficiaries
      {...props}
      updateBeneficiariesService={makeUpdateBeneficiariesService()}
    />
  )
}

export default BeneficiariesCardFactory
