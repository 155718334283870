export enum ContinueLifeStyleQuestionType {
  SIM = 'Sim',
  NAO = 'Não',
}

/**
 * Continue Life Style Question
 *
 * @alias ContinueLifeStyleQuestion
 */
export type ContinueLifeStyleQuestion = {
  /**
   * DPS Question Number
   */
  questionNumber: number
  /**
   * Question Answer Type
   */
  type: ContinueLifeStyleQuestionType
}
