import React from 'react'

import { useAuth } from '@azos/account'
import { Modal, useMediaQuery, useTheme } from '@azos/shared'
import { Button, Text } from '@azos/shared/src/components/v2'
import { env } from 'main/config/env'
import router from 'next/router'

import { ModalBuyPoliciesProps } from './ModalBuyPolicies.props'
import { Root } from './ModalBuyPolicies.style'

const ModalBuyPolicies: React.FCC<ModalBuyPoliciesProps> = ({
  onClose,
  open,
  title,
  description,
  mobileOnly = false,
}) => {
  const { isMobile } = useMediaQuery()
  const { user } = useAuth()

  const theme = useTheme()

  React.useEffect(() => {
    if (!isMobile && mobileOnly) onClose()
  }, [onClose, isMobile, mobileOnly])

  if (!isMobile && mobileOnly) return null

  const handleSimulation = () => {
    if (user?.isInsured) {
      router.push('/simular-novo-seguro')
    } else {
      router.push(env.SIMULATION_URL)
    }
  }

  return (
    <Modal
      open={open}
      variant={isMobile ? 'bottomDrawer' : 'modal'}
      maxWidth={isMobile ? undefined : '500px'}
      onClose={() => onClose()}
      showCloseButton={false}
      showHeader={false}
    >
      <Root>
        <div className="content-policies">
          <div className="content-policies__title">
            <Text variant="h5">{title}</Text>
            <Button onClick={() => onClose()}>
              <span className="icon-exit" />
            </Button>
          </div>
          <div className="content-policies__text">
            {typeof description === 'string' ? (
              <Text
                variant="body2"
                className="content-policies__text-description"
              >
                {description}
              </Text>
            ) : (
              description
            )}
          </div>
          <div className="content-policies__button">
            <div className="content-policies__button--primary">
              <Button
                onClick={handleSimulation}
                variant="primary"
                title="Simular agora"
                fullWidth
              >
                Simular agora
              </Button>
            </div>
            <div>
              <Button
                onClick={() => onClose()}
                variant="tertiary"
                title="Agora não"
                fullWidth
                color={theme.palette.neutral[500]}
              >
                Agora não
              </Button>
            </div>
          </div>
        </div>
      </Root>
    </Modal>
  )
}

export default ModalBuyPolicies
