export enum ViewPersonalDataBottomSheetStatus {
  SENSITIVE_DATA = 'Dados sensíveis',
  WAITING_APPROVAL = 'Aguardando aprovação',
  REFUSED = 'Edição recusada',
}

/**
 * View Personal Data Bottomsheet
 *
 * @alias ViewPersonalDataBottomSheet
 */

export type ViewPersonalDataBottomSheet = {
  /**
   * Status
   */
  status: ViewPersonalDataBottomSheetStatus
}
