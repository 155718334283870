import { Services } from '@azos/core'
import { IWizardRepository } from '@data/repositories'
import { UpdateWizardUseCase } from '@domain/usecases'

type Params = UpdateWizardUseCase.Params
type Response = UpdateWizardUseCase.Response

export type IUpdateWizardService = Services<Params, Response>

export class UpdateWizardService implements IUpdateWizardService {
  constructor(private readonly wizardRepository: IWizardRepository) {}

  async execute(params: Params): Promise<Response> {
    const upsertGuardians = await this.wizardRepository.update(params)

    return upsertGuardians
  }
}
