import { Button as ApolloButton } from '@Azos-Seguros/apollo'
import { css, styled } from '@azos/shared'

export const Button = styled(ApolloButton)(
  ({ theme }) => css`
    margin-left: -12px;
    margin-bottom: ${theme.spacing[3]}px;
    color: ${theme.palette.neutral[300]};
  `,
)
