import { GoBackCoverageDetails } from '../../../../../domain/models'
import { Dispatcher, Service } from '../../../../protocols'

export type IUpsellGoBackDetailsCoverageService = Service<GoBackCoverageDetails>

/**
 * Go back details coverage
 *
 * @category Upsell
 */
export class UpsellGoBackDetailsCoverageService
  implements IUpsellGoBackDetailsCoverageService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   * @param {GoBackCoverageDetails} data
   */
  execute(data: GoBackCoverageDetails): void {
    this.dispatcher.execute(
      'Upsell - Clique "voltar" em modal de detalhe do produto',
      data,
    )
  }
}
