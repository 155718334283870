import { Dispatcher, Service } from '../../../protocols'

export type ISaveEditGuardianService = Service<void>

/**
 * Save edit guardian service
 *
 * @category Dashboard
 */
export class SaveEditGuardianService implements ISaveEditGuardianService {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @param
   * @function
   */
  execute(): void {
    this.dispatcher.execute(
      'Area logada web - Clique para salvar alterações de edição de guardião na tela de adicionar guardião',
    )
  }
}
