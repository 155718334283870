import { camelCase, startCase } from 'lodash'

export const onlyNumber = (value?: string): string => {
  return value ? value.replace(/\D/gm, '') : ''
}

export const formatUTM = (value: string | string[] | null): string | null => {
  if (!value) return null

  if (Array.isArray(value)) {
    return value.join(',')
  }

  return value
}

export function capitalize(value: string): string {
  return startCase(camelCase(value))
}
