import { SideMenuHomepage } from '../../../domain/models'
import { Dispatcher, Service } from '../../protocols'

export type ISideMenuHomepageService = Service<SideMenuHomepage>

/**
 * Side Menu Homepage
 *
 * @category Callpage
 */
export class SideMenuHomepageService implements ISideMenuHomepageService {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @param {SideMenuHomepage} data
   * @function
   */
  execute(data: SideMenuHomepage): void {
    this.dispatcher.execute(
      'Area logada web - Clique no menu lateral na home page',
      data,
    )
  }
}
