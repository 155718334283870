import React, { Fragment } from 'react'

import { Button, Spinner, Typography } from '@Azos-Seguros/apollo'
import analytics from '@azos/analytics'
import { EmptyState, PageHeader, useTheme, withModal } from '@azos/shared'
import { PoliciesDetailsReadjustmentResponse } from '@domain/models'
import { makeLoadPolicyDetailsService } from '@main/factories/services'

import { CallCenterModal } from '../CallCenterModal'
import { HighlightedString } from '../HighlightString'
import ModalPolicyReadjustment from '../ModalPolicyReadjustment/ModalPolicyReadjustment'
import { ResponsibleBrokerModal } from '../ResponsibleBrokerModal'
import { ModalPolicyReadjustmentValueProps } from './ModalPolicyReadjustmentValue.props'
import { Root } from './ModalPolicyReadjustmentValue.styles'
import { getCoverageValue } from './ModalPolicyReadjustmentValue.utils'

const loadPolicyDetailsService = makeLoadPolicyDetailsService()

const ModalPolicyReadjustmentValue: React.VFC<
  ModalPolicyReadjustmentValueProps
> = ({ open, onClose, user, policyId, policyNumber, readjustment }) => {
  const theme = useTheme()

  const [readjustmentData, setReadjustmentData] =
    React.useState<PoliciesDetailsReadjustmentResponse | null>(null)

  const [isLoading, setIsLoading] = React.useState(false)
  const [isError, setIsError] = React.useState(false)

  const [openModalPolicyReadjustment, setOpenModalPolicyReadjustment] =
    React.useState(false)

  const [isOpenModalPolicyReadjustment, setIsOpenModalPolicyReadjustment] =
    React.useState(false)

  const [isOpenCallCenterModal, setIsOpenCallCenterModal] =
    React.useState(false)

  const [isOpenResponsibleBrokerModal, setIsOpenResponsibleBrokerModal] =
    React.useState(false)

  React.useEffect(() => {
    if (!policyId) setIsError(true)
  }, [policyId])

  React.useEffect(() => {
    if (readjustmentData)
      analytics.dashboard.policies.viewPolicyReadjustmentDetails.execute({
        with_ipca: !!readjustmentData.ipcaPercentage,
      })
  }, [readjustmentData])

  React.useEffect(() => {
    if (policyId) {
      if (!readjustment) {
        setIsLoading(true)

        loadPolicyDetailsService
          .execute({ policyId })
          .then(response => setReadjustmentData(response.readjustment))
          .catch(() => setIsError(true))
          .finally(() => setIsLoading(false))
      } else {
        setReadjustmentData(readjustment)
      }
    }
  }, [policyId, readjustment])

  const clickRedirectReadjustmentDetails = () => {
    analytics.dashboard.policies.clickRedirectReadjustmentDetails.execute()
    setIsOpenModalPolicyReadjustment(true)
  }

  const renderCoverageValue = readjustmentData?.coverages.map((item, index) => {
    const getCoverageData = getCoverageValue(item.newCoverage.code)

    return (
      <section key={index}>
        <div className="policy-readjustment__content--third-card__title">
          <i className={getCoverageData?.icon} />
          <Typography variant="bodyBold3">{getCoverageData?.name}</Typography>
        </div>

        <div className="policy-readjustment__content--third-card__table">
          <div className="policy-readjustment__content--third-card__table--line">
            <div className="policy-readjustment__content--third-card__table--line__content">
              <Typography variant="bodyBold3">Cobertura</Typography>

              <Typography
                className="policy-readjustment__content--third-card__table--line__content--chip"
                variant="captionBold"
              >
                IPCA
              </Typography>
            </div>

            <div className="policy-readjustment__content--third-card__table--line__content">
              <Typography color="lighter" variant="body3">
                R${' '}
                <Typography tag="s" color="lighter" variant="body3">
                  {item.oldCoverage.insuredCapital}
                </Typography>
              </Typography>

              <Typography variant="bodyBold3">
                R$ {item.newCoverage.insuredCapital}
              </Typography>
            </div>
          </div>

          <div className="policy-readjustment__content--third-card__table--line">
            <div className="policy-readjustment__content--third-card__table--line__content">
              <Typography variant="bodyBold3">Mensalidade</Typography>

              <Typography
                className="policy-readjustment__content--third-card__table--line__content--chip"
                variant="captionBold"
              >
                IPCA {!!item.newCoverage.age_percent ? '+ Reajuste etário' : ''}
              </Typography>
            </div>

            <div className="policy-readjustment__content--third-card__table--line__content">
              <Typography color="lighter" variant="body3">
                R${' '}
                <Typography tag="s" color="lighter" variant="body3">
                  {item.oldCoverage.premium}
                </Typography>
              </Typography>
              <Typography variant="bodyBold3">
                R$ {item.newCoverage.premium}
              </Typography>
            </div>
          </div>
        </div>
      </section>
    )
  })

  const handleOurContacts = () => {
    const userHasBroker = !!(user?.isInsuredByBroker && user?.isBroker)

    analytics.dashboard.policies.clickHelpReadjustmentDetails.execute({
      has_broker: userHasBroker,
    })

    if (userHasBroker) {
      setIsOpenResponsibleBrokerModal(true)
      return
    }
    setIsOpenCallCenterModal(true)
  }

  return (
    <Root
      variant="drawer"
      showHeader={false}
      backgroundColor={theme.palette.neutral[400]}
      exitBgColor={theme.palette.neutral[300]}
      $isLoadingOrError={isError || isLoading}
      noGutter
      maxWidth="440px"
      open={open}
      onClose={onClose}
    >
      {isError && (
        <div className="policy-readjustment__empty-state-wrapper">
          <EmptyState
            className="policy-readjustment__empty-state"
            align="center"
            icon="icon-policy-cancel"
            iconColor="error"
            title="Erro ao carregar o recurso"
          >
            Por favor, tente novamente ou entre em contato com nosso suporte.
          </EmptyState>
        </div>
      )}
      {isLoading && (
        <div className="policy-readjustment__empty-state-wrapper">
          <EmptyState
            className="policy-readjustment__empty-state"
            align="center"
            icon={<Spinner />}
            title="Carregando apólice"
          >
            Estamos carregando os detalhes da apólice.
          </EmptyState>
        </div>
      )}

      {readjustmentData && !isError && !isLoading && (
        <div className="policy-readjustment__wrapper">
          <div className="policy-readjustment__header-button">
            <button onClick={onClose} data-testid="exit-modal-button">
              <i className="icon-exit" />
            </button>
          </div>

          <PageHeader
            icon="icon-percent-16"
            title="Valores do reajuste"
            description="Confira detalhes dos valores da cobertura e mensalidade da sua apólice que serão atualizados."
          />

          <div className="policy-readjustment__content">
            <div className="policy-readjustment__content--policy-number">
              <Typography variant="bodyBold2" tag="span">
                Apólice: {policyNumber}
              </Typography>
            </div>

            <div className="policy-readjustment__content--first-card">
              <div className="policy-readjustment__content--first-card__title">
                <i className="icon-currency" />

                <HighlightedString
                  variant="bodyBold3"
                  text="Reajuste da mensalidade"
                  highlight="mensalidade"
                />
              </div>

              <div className="policy-readjustment__content--first-card__value">
                <div>
                  <Typography variant="caption">Atual</Typography>

                  <Typography variant="body3" className="current-value">
                    R$ <strong>{readjustmentData.oldPremiumTotal}/ mês</strong>
                  </Typography>
                </div>

                <Typography
                  className="icon-arrow-right"
                  tag="i"
                  color="lighter"
                />

                <div>
                  <Typography variant="caption" className="readjusted-label">
                    Reajustada
                  </Typography>

                  <Typography variant="body3" className="readjusted-value">
                    R${' '}
                    <strong>{readjustmentData.nextPremiumTotal} / mês</strong>
                  </Typography>
                </div>
              </div>
            </div>

            <Button
              variant="outline"
              fullWidth
              onClick={() => setOpenModalPolicyReadjustment(true)}
            >
              Quero entender o reajuste
            </Button>

            <div className="policy-readjustment__content--second-card">
              <div className="policy-readjustment__content--second-card__title">
                <i className="icon-policy-search" />

                <HighlightedString
                  variant="bodyBold2"
                  text="Valores em detalhes"
                  highlight="detalhes"
                />
              </div>

              <Typography variant="body3">
                Disponibilizamos os valores detalhados com transparência para
                você. Confira:
              </Typography>

              <div className="policy-readjustment__content--third-card">
                {renderCoverageValue}
              </div>
            </div>

            <Typography variant="body3">
              Se você ainda possui dúvidas ou deseja falar sobre especificidades
              de valores, estamos aqui para ajudar!
            </Typography>

            <Button
              icon="icon-headphone"
              variant="outline"
              fullWidth
              onClick={handleOurContacts}
            >
              Acessar nossos contatos.
            </Button>

            <ModalPolicyReadjustment
              open={isOpenModalPolicyReadjustment}
              onGoBack={() => setIsOpenModalPolicyReadjustment(false)}
              onClose={onClose}
            />

            <CallCenterModal
              open={isOpenCallCenterModal}
              onClose={() => setIsOpenCallCenterModal(false)}
            />

            <ResponsibleBrokerModal
              open={isOpenResponsibleBrokerModal}
              onClose={() => setIsOpenResponsibleBrokerModal(false)}
            />
          </div>
        </div>
      )}

      <ModalPolicyReadjustment
        open={openModalPolicyReadjustment}
        onClose={() => setOpenModalPolicyReadjustment(false)}
        onGoBack={() => setOpenModalPolicyReadjustment(false)}
      />
    </Root>
  )
}

export default withModal(ModalPolicyReadjustmentValue)
