import { Services } from '@azos/core'
import { IPoliciesRepository } from '@data/repositories'
import { UpdateBeneficiariesUseCase } from '@domain/usecases'

type Params = UpdateBeneficiariesUseCase.Params
type Response = UpdateBeneficiariesUseCase.Response

export type IUpdateBeneficiariesService = Services<Params, Response>

export class UpdateBeneficiariesService implements IUpdateBeneficiariesService {
  constructor(private readonly policiesRepository: IPoliciesRepository) {}

  async execute(params: Params): Promise<Response> {
    return await this.policiesRepository.updateBeneficiaries(params)
  }
}
