import { css, Utils } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled(
  'button',
  Utils.transient.transientStyleProps,
)(
  ({ theme }) => css`
    padding: 0;
    display: flex;
    width: 100%;
    height: 40px;
    justify-content: space-between;
    align-items: center;
    background: ${theme.palette.neutral[50]};
    border: none;
    cursor: pointer;

    .onboarding-action {
      &__content {
        display: flex;
        align-items: center;

        &--icon {
          font-size: 1.5rem;
          color: ${theme.palette.neutral[300]};

          &__active {
            color: ${theme.palette.primary.main};
            font-size: 1.5rem;
          }

          &__locked {
            color: ${theme.palette.neutral[400]};
          }
        }

        &--text {
          margin-left: 14px;
          color: ${theme.palette.neutral[400]};
          text-align: start;
        }
      }

      &__redirect--icon {
        font-size: 1.5rem;
      }
    }
  `,
)
