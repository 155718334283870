import { ClickRequestBrokerGuardian } from '../../../../domain/models'
import { Dispatcher, Service } from '../../../protocols'

export type IClickRequestBrokerGuardianService =
  Service<ClickRequestBrokerGuardian>

/**
 * Click Request Broker Guardian
 *
 * @category Home
 */
export class ClickRequestBrokerGuardianService
  implements IClickRequestBrokerGuardianService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   * @param {ClickRequestBrokerGuardian} data
   */
  execute(data: ClickRequestBrokerGuardian): void {
    this.dispatcher.execute(
      'Area logada web - Click em solicitar corretor na tela guardião',
      data,
    )
  }
}
