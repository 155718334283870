import React, { useState } from 'react'

import { Button } from '@azos/shared/src/components/v2'

import { ModalSimulation } from '../../molecules/ModalSimulation'
import { BottomNavigationProps } from './BottomNavigation.props'
import { Root } from './BottomNavigation.styles'

const BottomNavigation: React.VFC<BottomNavigationProps> = ({
  items,
  value,
  onChange,
  showBottomButton = true,
}) => {
  const [modalOpen, setModalOpen] = useState(false)

  const handleClose = () => {
    setModalOpen(false)
  }

  return (
    <Root>
      <ModalSimulation open={modalOpen} onClose={handleClose} />
      {showBottomButton && (
        <Button onClick={() => setModalOpen(true)}>
          <span className="icon-add" />
        </Button>
      )}
      <ul role="navigation" aria-label="Main">
        {items.map(item => (
          <li
            key={item.value}
            className={value === item.value ? 'selected' : ''}
            title={item.label}
            onClick={() => onChange(item.value)}
          >
            <span className={item.icon} />
            {!item.hideLabel && item.label}
          </li>
        ))}
      </ul>
    </Root>
  )
}

export default BottomNavigation
