import React from 'react'

import clsx from 'clsx'

import { useMediaQuery } from '../../hooks'
import { CarouselProps } from './Carousel.props'
import { Root } from './Carousel.styles'

const Carousel: React.FCC<CarouselProps> = ({ itens, onPositionChanged }) => {
  const [position, setPosition] = React.useState(0)
  const [clicked, setClicked] = React.useState(false)
  const [oldXPosition, setOldXPosition] = React.useState(0)
  const [XDireciton, setXDirection] = React.useState('')

  const { isMobile } = useMediaQuery()

  const sliderRef = React.useRef<HTMLDivElement>(null)

  const isFirstPosition = position === 0
  const isLastPosition = itens.length - position <= 1

  const handlePosition = (pos: ((pos: number) => number) | number) => {
    setPosition(pos)
    if (onPositionChanged) {
      const newPosition = typeof pos === 'number' ? pos : pos(position)
      onPositionChanged(newPosition)
    }
  }

  const onPrev = () => {
    if (sliderRef.current) {
      const width = sliderRef.current.offsetWidth

      if (!isFirstPosition) {
        sliderRef.current.scrollTo(width * (position - 1), 0)
        handlePosition(pos => (pos -= 1))
      }
    }
  }

  const onNext = () => {
    if (sliderRef.current) {
      const width = sliderRef.current.offsetWidth
      if (!isLastPosition) {
        sliderRef.current.scrollTo(width * (position + 1), 0)
        handlePosition(pos => (pos += 1))
      }
    }
  }

  const onSelectPosition = (index: number) => {
    if (sliderRef.current) {
      const width = sliderRef.current.offsetWidth
      sliderRef.current.scrollTo(width * index, 0)
      handlePosition(index)
    }
  }

  const onMouseDown = () => isMobile && setClicked(true)

  const onMouseMove = (pageX: number) => {
    if (clicked) {
      if (oldXPosition === pageX) return
      if (oldXPosition < pageX) {
        setXDirection('left')
      } else {
        setXDirection('right')
      }

      if (sliderRef.current) {
        sliderRef.current?.scrollTo(pageX, 0)
      }

      setOldXPosition(pageX)
    }
  }

  const onMouseUp = () => {
    setClicked(false)
    if (XDireciton === 'left') {
      if (isFirstPosition && !isLastPosition) {
        onSelectPosition(position + 1)
      }
      onPrev()
    }
    if (XDireciton === 'right') {
      if (isLastPosition) {
        onSelectPosition(0)
        return
      }
      onNext()
    }
  }

  const onItemClick = () => {
    setXDirection('')
  }

  return (
    <Root>
      <div
        ref={sliderRef}
        className="carousel__slider"
        onMouseDown={onMouseDown}
        onMouseMove={event => onMouseMove(event.pageX)}
        onMouseUp={onMouseUp}
        onTouchStart={onMouseDown}
        onTouchMove={event => onMouseMove(event.changedTouches[0].pageX)}
        onTouchEnd={onMouseUp}
      >
        {itens.map((item, index) => (
          <div key={index} className="carousel__item" onClick={onItemClick}>
            {item}
          </div>
        ))}
      </div>
      <div className="carousel__control">
        <button
          onClick={onPrev}
          className={clsx('carousel__control--button', {
            ['disabled']: isFirstPosition,
          })}
          disabled={isFirstPosition}
        >
          Anterior
        </button>

        <div className="carousel__control--button-select-wrapper">
          {itens.map((_item, index) => (
            <button
              key={index}
              onClick={() => onSelectPosition(index)}
              className={clsx('carousel__control--button-select', {
                ['active']: position === index,
              })}
            />
          ))}
        </div>

        <button
          onClick={onNext}
          className={clsx('carousel__control--button', {
            ['disabled']: isLastPosition,
          })}
          disabled={isLastPosition}
        >
          Próximo
        </button>
      </div>
    </Root>
  )
}

export default Carousel
