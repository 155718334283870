import { FinishCreditCardPayment } from '../../../../../domain/models'
import { Dispatcher, Service } from '../../../../protocols'

export type IUpsellFinishCreditcardPaymentService =
  Service<FinishCreditCardPayment>

/**
 * Finish Creditcard Payment
 *
 * @category Upsell
 */
export class UpsellFinishCreditcardPaymentService
  implements IUpsellFinishCreditcardPaymentService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   * @param {FinishCreditCardPayment} data
   */
  execute(data: FinishCreditCardPayment): void {
    this.dispatcher.execute(
      'Upsell - Clique "finalizar o pedido" em checkout',
      data,
    )
  }
}
