import { Storage, SyncServices } from '@azos/core'

type Request = void
type Response = void

export type IFinishUpsellService = SyncServices<Request, Response>

export class FinishUpsellService implements IFinishUpsellService {
  constructor(private readonly storage: Storage) {}

  execute(): Response {
    this.storage.remove('@azos/upsellDPS')
    this.storage.remove('@azos/upsellDuration')
    this.storage.remove('@azos/upsellSimulation')
  }
}
