import { DPSQuestionViewModel } from '../../../data/models'
import {
  CreateQuestionnaireService,
  LoadQuestionnaireService,
  LoadQuestionsByAnswersService,
} from '../../../data/services'
import { makeLoadQuestionnaireMapper } from '../mappers'
import { makeDPSRepository } from '../repositories'

export const makeLoadQuestionnaireService = () =>
  new LoadQuestionnaireService(
    makeDPSRepository(),
    makeLoadQuestionnaireMapper(),
  )

export const makeLoadQuestionsByAnswersService = (
  questions: DPSQuestionViewModel[],
) => new LoadQuestionsByAnswersService(questions)

export const makeCreateQuestionnaireService = () =>
  new CreateQuestionnaireService(makeDPSRepository())
