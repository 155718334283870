import {
  PaymentError,
  PaymentInvoicesTimeoutError,
  PaymentTimeoutError,
} from '../../../../domain/errors'
import {
  PaymentInvoicesStatus,
  PaymentInvoiceStatusResponse,
  PaymentMethod,
} from '../../../../domain/models'
import { LoadInvoices } from '../../../../domain/usecases'
import {
  IDelayService,
  IRetryService,
  Services,
  SyncServices,
} from '../../../protocols'

type Request = {
  proposalId: string
  paymentMethod: PaymentMethod
  status?: PaymentInvoicesStatus
}
type Response = PaymentInvoiceStatusResponse

export type ICheckInvoiceStatusService = Services<Request, Response>

export class CheckInvoiceStatusService implements ICheckInvoiceStatusService {
  constructor(
    private readonly retryService: IRetryService,
    private readonly delayService: IDelayService,
    private readonly loadInvoicesRepository: LoadInvoices,
    private readonly proposalProducer: SyncServices,
  ) {}

  async execute({
    proposalId,
    paymentMethod,
    status,
  }: Request): Promise<Response> {
    const now = () => new Date(Date.now())

    return new Promise((resolve, reject) => {
      const service = () =>
        this.loadInvoicesRepository.loadInvoices({
          proposalId,
          paymentMethod,
          status,
        })

      const retryRequest = (time: Date) => {
        // update interval time
        const interval = now().getTime() - time.getTime()
        // delay
        this.delayService
          .delay(this.delayService.duration - interval)
          .then(() => {
            // retry
            executePolling()
          })
      }

      const executePolling = async () => {
        const startTime = now()

        return this.retryService
          .retry(service)
          .then(response => {
            const lastInvoice = this.getLastInvoice(response)
            if (this.checkSomeInvoiceIsPaid(response)) {
              this.proposalProducer.execute(undefined)
              return resolve(lastInvoice)
            }
            if (this.checkIsPending(lastInvoice)) {
              throw new Error()
            }
            reject(new PaymentError())
          })
          .catch(error => {
            if (error instanceof PaymentTimeoutError) {
              return reject(new PaymentInvoicesTimeoutError())
            }
            if (error instanceof PaymentError) {
              return reject(error)
            }
            // retry
            retryRequest(startTime)
          })
      }

      // execute
      executePolling()
    })
  }

  private getLastInvoice(
    invoices: LoadInvoices.Response,
  ): PaymentInvoiceStatusResponse {
    return invoices.length > 0
      ? invoices[invoices.length - 1]
      : ({
          status: PaymentInvoicesStatus.pending,
        } as PaymentInvoiceStatusResponse)
  }

  private checkSomeInvoiceIsPaid(invoices: LoadInvoices.Response): boolean {
    return invoices.some(this.checkIsPaid)
  }

  private checkIsPaid(invoice: PaymentInvoiceStatusResponse): boolean {
    switch (invoice.status) {
      case PaymentInvoicesStatus.in_analysis:
      case PaymentInvoicesStatus.paid:
      case PaymentInvoicesStatus.partially_paid:
        return true
      default:
        return false
    }
  }

  private checkIsPending(invoice: PaymentInvoiceStatusResponse): boolean {
    return invoice.status === PaymentInvoicesStatus.pending
  }

  dispose() {
    this.delayService.destroy()
  }
}
