import React from 'react'

import analytics from '@azos/analytics'
import { Utils } from '@azos/shared'
import { CircularProgress } from '@mui/material'
import MuiButton from '@mui/material/Button'

import { ButtonProps } from './Button.props'

const Button = ({
  children,
  color = 'primary',
  variant = 'contained',
  loading = false,
  parentName,
  onClick,
  ...rest
}: ButtonProps) => {
  const handleClick = (
    params: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    if (parentName) {
      analytics.custom.action.execute({
        dataAction: Utils.dataNames.renderDataName('button', parentName),
      })
    }

    onClick?.(params)
  }

  return (
    <MuiButton
      variant={variant}
      color={color}
      onClick={handleClick}
      {...rest}
      data-action={Utils.dataNames.renderDataName('button', parentName)}
    >
      {!loading ? children : <CircularProgress color="inherit" size={28} />}
    </MuiButton>
  )
}

export default Button
