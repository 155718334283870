import { Box, BoxProps } from '@Azos-Seguros/apollo'
import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled(Box)<BoxProps>(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;

    .address__content {
      display: flex;
      flex-direction: column;
      margin-top: ${theme.spacing[2]}px;

      & > * {
        margin-top: ${theme.spacing[3]}px;
      }
    }

    .zipcode__container {
      position: relative;

      &--spinner {
        position: absolute;
        right: 1rem;
        top: 1.5rem;
      }

      &--select {
        display: flex;
        flex-direction: column;
      }
    }
  `,
)
