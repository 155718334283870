import React from 'react'

import Image from 'next/image'

import pdfIcon from '../../../assets/icons/pdf.svg'
import { CardDownloadDocumentProps } from './CardDownloadDocument.props'
import { Root } from './CardDownloadDocument.styles'

const CardDownloadDocument: React.FCC<CardDownloadDocumentProps> = ({
  title,
  button,
  children,
}) => {
  return (
    <Root>
      <div className="content">
        <div className="heading">
          <p className="title">{title}</p>
          {children}
        </div>

        <Image src={pdfIcon} width={44} height={44} />
      </div>

      <button onClick={button.onClick}>
        <span className="icon-downloads" />
        {button.label}
      </button>
    </Root>
  )
}

export default CardDownloadDocument
