import { ReferralShareCode } from '../../../../../domain/models'
import { Dispatcher, Service } from '../../../../protocols'

export type IReferralShareCodeService = Service<ReferralShareCode>

/**
 * Referral Share Code
 *
 * @category Dashboard
 */
export class ReferralShareCodeService implements IReferralShareCodeService {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @param {ReferralShareCode} data
   * @function
   */
  execute(data: ReferralShareCode): void {
    this.dispatcher.execute(
      'Area logada web - Clique em Compartilhar Código para indicação',
      data,
    )
  }
}
