import { AxiosResponse } from 'axios'
import HttpStatus from 'http-status-codes'

import {
  HttpClient,
  HttpRequest,
  HttpResponse,
} from '../../data/protocols/http'
import { api } from './api'

class AxiosHttpClient implements HttpClient {
  constructor(private readonly token: string) {}

  async request(data: HttpRequest): Promise<HttpResponse> {
    let axiosResponse: AxiosResponse
    try {
      const headers = data.headers || {}

      if (this.token) {
        Object.assign(headers, { Authorization: `Bearer ${this.token}` })
      }

      axiosResponse = await api.request({
        url: data.url,
        method: data.method,
        data: data.body,
        headers,
      })
    } catch (error: any) {
      const isServer = typeof window === 'undefined'

      if (
        !isServer &&
        [HttpStatus.UNAUTHORIZED, HttpStatus.FORBIDDEN].includes(
          error?.response?.status,
        )
      ) {
        window.location.replace(`/login?origin=${window.location.pathname}`)
      }

      axiosResponse =
        error?.response ||
        ({
          status: HttpStatus.INTERNAL_SERVER_ERROR,
          data: undefined,
        } as AxiosResponse)
    }

    return {
      statusCode: axiosResponse.status,
      data: axiosResponse.data,
    }
  }
}

export class AxiosHttpClientFactory {
  private static instance: AxiosHttpClient | null = null

  static factory(token?: string): AxiosHttpClient {
    if (!!token) {
      this.instance = new AxiosHttpClient(token)
    } else if (this.instance === null) {
      this.instance = new AxiosHttpClient('')
    }
    return this.instance
  }
}
