import React from 'react'

import analytics from '@azos/analytics'
import { AppError, firebase, useProposalCookie } from '@azos/core'
import { Spinner } from '@azos/shared'

import { useAuth, useUserConfirmation } from '../../../hooks'
import { Wizard, WizardItem } from '../../molecules'
import { Wrapper } from './containers'
import {
  INITIAL_VALUES,
  RegistrationProps,
  RegistrationValue,
} from './Registration.props'
import { Box } from './Registration.styles'
import {
  Address,
  addressSchemaValidation,
  PersonalData,
  personalDataSchemaValidation,
} from './steps'

type RegistrationState = {
  initilized: boolean
  disableFromQuotation: boolean
  isFullNameFieldDisabled: boolean
}

const Registration: React.VFC<RegistrationProps> = ({
  loadQuotationbyIdService,
  createInsuredUserService,
  //
  onSuccessful,
  onError,
}) => {
  const { proposalCookie } = useProposalCookie()
  const { confirmUser } = useUserConfirmation()
  const { user, onError: authOnError, setRegistered, isRegistered } = useAuth()

  const [registrationState, setRegistrationState] =
    React.useState<RegistrationState>({
      disableFromQuotation: false,
      initilized: false,
      isFullNameFieldDisabled: false,
    })

  const [initialValues, setInitialValues] =
    React.useState<RegistrationValue | null>(null)

  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const handleSubmit = React.useCallback(
    async (value: Required<RegistrationValue>) => {
      setIsSubmitting(true)
      try {
        if (!user) throw new Error()

        const { fullName } = await createInsuredUserService.execute({
          ...value,
          email: user.email,
        })

        const userName = value?.social_name || fullName

        if (userName) {
          firebase.auth().currentUser?.updateProfile({ displayName: userName })
        }

        analytics.account.registration.execute({
          firebase_id: user.uid,
          email: user.email,
        })

        confirmUser()
        setRegistered(true)
      } catch (e) {
        const errorMessage =
          e instanceof AppError
            ? e.message
            : 'Houve um problema ao criar o usuário'
        authOnError?.(errorMessage)
        onError?.()
        setIsSubmitting(false)
      }
    },
    [
      authOnError,
      confirmUser,
      createInsuredUserService,
      onError,
      setRegistered,
      user,
    ],
  )

  React.useEffect(() => {
    if (!!user && !registrationState.initilized) {
      const fullName =
        window.localStorage.getItem('@azos/userDisplayName') || ''

      const socialName =
        window.localStorage.getItem('@azos/userSocialName') || ''

      setRegistrationState(state => ({ ...state, initilized: true }))

      const quotationId = proposalCookie?.quotationId

      if (!quotationId) {
        const data = Object.assign({}, INITIAL_VALUES, {
          full_name: fullName,
          social_name: socialName,
        })
        setInitialValues(data)
        return
      }

      loadQuotationbyIdService
        .execute(quotationId)
        .then(quotation => {
          const { birthDate, gender } = quotation

          const data = Object.assign({}, INITIAL_VALUES, {
            full_name: fullName,
            social_name: socialName?.trim() || null,
            birth_date: birthDate,
            gender,
          } as Partial<RegistrationValue>)

          setRegistrationState(state => ({
            ...state,
            disableFromQuotation: true,
            isFullNameFieldDisabled: !!data.full_name,
          }))

          setInitialValues(data)
        })
        .catch(() => {
          const data = Object.assign({}, INITIAL_VALUES, {
            full_name: fullName,
            social_name: socialName,
          })
          setInitialValues(data)
        })
    }
  }, [
    loadQuotationbyIdService,
    proposalCookie?.quotationId,
    registrationState.initilized,
    user,
  ])

  React.useEffect(() => {
    if (isRegistered) {
      onSuccessful?.()
    }
  }, [isRegistered, onSuccessful])

  if (!initialValues) {
    return (
      <Box>
        <Spinner />
      </Box>
    )
  }

  return (
    <Wizard
      config={{
        initialSchema: personalDataSchemaValidation,
        initialValues,
        onSubmit: handleSubmit,
      }}
      wrapper={Wrapper}
    >
      <WizardItem
        component={() => (
          <PersonalData
            disabledUserField={registrationState.disableFromQuotation}
            disableNameField={registrationState.isFullNameFieldDisabled}
          />
        )}
        config={{
          schema: personalDataSchemaValidation,
          disableControls: true,
        }}
      />
      <WizardItem
        component={Address}
        config={{
          schema: addressSchemaValidation,
          disableControls: true,
          isSubmittingForm: isSubmitting,
        }}
      />
    </Wizard>
  )
}

export default Registration
