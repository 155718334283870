import MuiRadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup'

import { css, styled } from '../../styles'
import { Utils } from '../../utils'
import { DirectionRadioProps } from './Radio.props'

type RadioStyleProps = RadioGroupProps & {
  $direction?: DirectionRadioProps
}

export const RadioGroup = styled(
  MuiRadioGroup,
  Utils.transient.transientStyleProps,
)<RadioStyleProps>(
  ({ $direction }) => css`
    display: flex;
    flex-direction: ${$direction === 'row' ? 'row' : 'column'};

    .radio-group__radio {
      color: black;

      &.Mui-checked {
        color: black;
      }
    }
  `,
)
