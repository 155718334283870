import React from 'react'

import {
  Button,
  Input,
  Typography,
  useNotification,
} from '@Azos-Seguros/apollo'
import analytics from '@azos/analytics'
import {
  ReferralContextType,
  ReferralUserMaritalStatus,
} from '@azos/analytics/src/domain/models'
import { withModal } from '@azos/shared'
import { InputPhone } from '@azos/shared/src/components/v2'
import { Formik, Form } from 'formik'

import {
  initialValues,
  RequestBrokerProps,
  RequestBrokerValues,
} from './RequestBroker.props'
import { Root } from './RequestBroker.styles'
import { validationSchema } from './RequestBroker.validation'

const RequestBroker: React.VFC<RequestBrokerProps> = ({
  open,
  onClose,
  user,
  showNotification = false,
  onSuccess,
  onError,
}) => {
  const { addNotification } = useNotification()

  const handleSubmit = (values: RequestBrokerValues) => {
    try {
      analytics.dashboard.referral.clickBrokerShare.execute({
        referral: {
          nome: values.fullName,
          email: values.email,
          telefone: values.phone,
        },
        type: ReferralContextType.SHARE,
        status: ReferralUserMaritalStatus.OTHERS,
        isInsured: !!user?.isInsured,
      })
      if (showNotification) {
        addNotification({
          description: 'Corretor solicitado com sucesso!',
          variant: 'success',
        })
      }
      onSuccess?.()
    } catch {
      if (showNotification) {
        addNotification({
          description:
            'Não foi possível enviar sua solicitação no momento. Se desejar, fale com a gente no Whatsapp (11) 5445-1234.',
          variant: 'error',
        })
      }
      onError?.()
    }
    onClose?.()
  }

  return (
    <Root
      showHeader={false}
      noGutter
      open={open}
      variant="modal"
      maxWidth="516px"
      onClose={onClose}
    >
      <div className="request-broker__header">
        <button
          type="button"
          onClick={onClose}
          className="request-broker__header--exit-button"
        >
          <i className="icon-exit" />
        </button>
        <i className="icon-briefcase" />
        <Typography
          variant="h4"
          tag="strong"
          className="request-broker__header--title"
        >
          Corretores prontos para te ajudar na contratação!
        </Typography>
        <Typography
          variant="body3"
          className="request-broker__header--description"
        >
          São mais de 200 corretores e corretoras parceiros que podem te
          acompanhar de perto e tirar todas as suas dúvidas sobre o seu seguro e
          o processo de simulação, além de selecionar as melhores coberturas
          para o seu perfil.
        </Typography>
      </div>
      <div className="request-broker__content">
        <Typography
          variant="body3"
          className="request-broker__content--description"
        >
          Preencha as informações a seguir, que em pouco tempo um corretor ou
          corretora parceiro Azos entrará em contato!
        </Typography>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            isSubmitting,
          }) => (
            <Form className="request-broker__content--form">
              <div className="request-broker__content--form-input">
                <Input
                  fullWidth
                  id="fullName"
                  name="fullName"
                  label="Nome completo"
                  placeholder="Digite seu nome completo"
                  error={touched.fullName && !!errors.fullName}
                  helperText={touched.fullName ? errors.fullName : undefined}
                  value={values.fullName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                />
                <Input
                  name="email"
                  type="email"
                  label="E-mail"
                  placeholder="Seu e-mail pessoal"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.email && !!errors.email}
                  helperText={touched.email ? errors.email : ''}
                  disabled={isSubmitting}
                  fullWidth
                />
                <InputPhone
                  fullWidth
                  id="phone"
                  name="phone"
                  label="Telefone"
                  error={touched.phone && !!errors.phone}
                  helperText={touched.phone && errors.phone}
                  value={values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                />
              </div>

              <Button fullWidth type="submit" disabled={isSubmitting}>
                Enviar solicitação
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Root>
  )
}

export default withModal<RequestBrokerProps>(RequestBroker)
