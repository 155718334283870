import React, { useEffect } from 'react'

import { useAuth } from '@azos/account'
import analytics from '@azos/analytics'
import { SideMenu } from '@azos/analytics/src/domain/models'
import { MenuDashboardItem, useMediaQuery } from '@azos/shared'
import { Text } from '@azos/shared/src/components/v2'
import { env } from '@main/config/env'
import { MENU, mobileMenuB2C } from '@main/config/menu'
import utms from '@main/config/utms'
import { RequestBroker } from '@presentation/components/molecules/Broker'
import { CallCenterModal } from '@presentation/components/molecules/CallCenterModal'
import { GuardiansModal } from '@presentation/components/molecules/Guardians'
import { HelpCenterModal } from '@presentation/components/molecules/HelpCenterModal'
import { ModalAppStore } from '@presentation/components/molecules/ModalAppStore'
import { ModalBuyPolicies } from '@presentation/components/molecules/ModalBuyPolicies'
import { ModalMenu } from '@presentation/components/molecules/ModalMenu'
import { ModalReferral } from '@presentation/components/molecules/ModalReferral'
import { ResponsibleBrokerModal } from '@presentation/components/molecules/ResponsibleBrokerModal'
import { TransparencyPortalModal } from '@presentation/components/molecules/TransparencyPortalModal'
import { UserAccountModal } from '@presentation/components/molecules/UserAccountModal'
import * as Sentry from '@sentry/nextjs'
import { useRouter } from 'next/router'

export type MenuContextData = {
  open: boolean
  setMenuItems?: (menu: MenuDashboardItem) => void
  onChangeMenu: (cb?: (path: string) => void) => (menu: string) => void
}

export const MenuContext = React.createContext({} as MenuContextData)

const MenuProvider: React.FCC = ({ children }) => {
  const { user, logout } = useAuth()
  const Router = useRouter()
  const { isMobile } = useMediaQuery()
  const [modalBuyPolicies, setModalBuyPolicies] = React.useState(false)
  const [modalCallCenter, setModalCallCenter] = React.useState(false)
  const [modalUserAccount, setModalUserAccount] = React.useState(false)
  const [modalTransparencyPortal, setModalTransparencyPortal] =
    React.useState(false)
  const [modalHelpCenter, setModalHelpCenter] = React.useState(false)
  const [modalResponsibleBroker, setModalResponsibleBroker] =
    React.useState(false)
  const [menuIsOpen, setMenuIsOpen] = React.useState(false)
  const [referralIsOpen, setRefferalIsOpen] = React.useState(false)
  const [guardianIsOpen, setGuardianIsOpen] = React.useState(false)
  const [storeIsOpen, setStoreIsOpen] = React.useState(false)
  const [requestBrokerIsOpen, setRequestBrokerIsOpen] = React.useState(false)

  const isB2B = React.useMemo(
    () => !!user?.isBroker || !!user?.isInsuredByBroker,
    [user],
  )

  const hasPolicies = React.useMemo(
    () =>
      user?.insuredUser ? user.insuredUser.info.amountPolicies > 0 : false,
    [user],
  )

  useEffect(() => {
    if (!isMobile && menuIsOpen) {
      setMenuIsOpen(false)
    }
  }, [isMobile, menuIsOpen])

  const referralUTM = React.useMemo(
    () => ({
      utm_source: utms.general.utm_source,
      utm_medium: utms.general.utm_medium,
      utm_campaign: user?.uid,
    }),
    [user],
  )

  const sideMenuAnalytics = (menu: string) => {
    const analyticsEvents: { [key: string]: SideMenu } = {
      [MENU.MINHAS_APOLICES]: SideMenu.MINHAS_APOLICES,
      [MENU.BENEFICIOS]: SideMenu.BENEFICIOS,
      [MENU.MEUS_GUARDIOES]: SideMenu.MEUS_GUARDIOES,
      [MENU.MINHAS_INDICACOES]: SideMenu.MINHAS_INDICACOES,
      [MENU.TUDO_SOBRE_SEGURO]: SideMenu.TUDO_SOBRE_SEGURO,
      [MENU.FALE_COM_SEU_CORRETOR]: SideMenu.FALE_COM_SEU_CORRETOR,
      [MENU.CENTRAL_DE_ATENDIMENTO]: SideMenu.CENTRAL_DE_ATENDIMENTO,
      [MENU.CONDICOES_GERAIS]: SideMenu.CONDICOES_GERAIS,
      [MENU.PORTAL_DA_TRANSPARENCIA]: SideMenu.PORTAL_DA_TRANSPARENCIA,
      [MENU.CENTRAL_DE_DUVIDAS]: SideMenu.CENTRAL_DE_DUVIDAS,
      [MENU.BAIXE_APP_AZOS]: SideMenu.BAIXE_APP_AZOS,
      [MENU.AJUDA]: SideMenu.AJUDA,
      [MENU.SAIR]: SideMenu.SAIR,
    }
    if (analyticsEvents[menu]) {
      analytics.dashboard.sideMenuHomepage.execute({
        action: analyticsEvents[menu],
      })
    }
  }

  const handleChangeMenu = React.useCallback(
    (cb?: (path: string) => void) => {
      return async (menu: string) => {
        sideMenuAnalytics(menu as SideMenu)
        switch (menu) {
          case MENU.MINHAS_APOLICES:
            setMenuIsOpen(false)
            cb?.(menu)
            break
          case MENU.BENEFICIOS:
            setMenuIsOpen(false)
            await Router.push('/beneficios')
            break
          case MENU.MEUS_GUARDIOES:
            setMenuIsOpen(false)
            return setGuardianIsOpen(true)
          case MENU.MINHAS_INDICACOES:
            setRefferalIsOpen(true)
            break
          case MENU.CENTRAL_DE_ATENDIMENTO:
            setModalCallCenter(true)
            break
          case MENU.CENTRAL_DE_DUVIDAS:
            setModalHelpCenter(true)
            break
          case MENU.PORTAL_DA_TRANSPARENCIA:
            setModalTransparencyPortal(true)
            break
          case MENU.BAIXE_APP_AZOS:
            setStoreIsOpen(true)
            break
          case MENU.MENU_GERAL:
            setMenuIsOpen(value => !value)
            break
          case MENU.SUA_CONTA:
            setModalUserAccount(true)
            break
          case MENU.FALE_COM_SEU_CORRETOR:
            setModalResponsibleBroker(true)
            break

          case MENU.ASSISTENCIA_FUNERAL:
            if (!hasPolicies) {
              setModalBuyPolicies(true)
            }
            break
          case MENU.AJUDA:
            window.open('https://www.azos.com.br/contato', '_blank')
            break
          case MENU.SAIR:
            setMenuIsOpen(false)
            Sentry.configureScope(scope => {
              scope.clear()
            })
            await logout()
            await Router.push('/login')
            break
          case MENU.SOLICITAR_CORRETOR:
            setRequestBrokerIsOpen(true)
            break
          case MENU.SIMULAR_SEGURO:
            Router.push(env.SIMULATION_URL)
            break
          default:
            setMenuIsOpen(false)
            cb?.(menu)
        }
      }
    },
    [hasPolicies, logout, Router],
  )

  React.useEffect(() => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop: string) => searchParams.get(prop),
    })

    if ((params as any)?.guardioes === '') {
      setGuardianIsOpen(true)
    }
  }, [Router.pathname])

  return (
    <MenuContext.Provider
      value={{
        onChangeMenu: handleChangeMenu,
        open: menuIsOpen,
      }}
    >
      <ModalReferral
        open={referralIsOpen}
        onClose={() => setRefferalIsOpen(false)}
        utm={referralUTM}
        isBroker={user?.isBroker || user?.isInsuredByBroker}
      />

      <UserAccountModal
        open={modalUserAccount}
        onClose={() => setModalUserAccount(false)}
      />

      <GuardiansModal
        open={guardianIsOpen}
        onClose={() => setGuardianIsOpen(false)}
        hideShareLink={isB2B}
      />

      <ModalAppStore
        open={storeIsOpen}
        onClose={() => setStoreIsOpen(false)}
        utm={utms.store}
      />

      {children}

      <ModalMenu
        open={menuIsOpen}
        menuItems={mobileMenuB2C}
        onClick={handleChangeMenu}
        onClose={() => setMenuIsOpen(false)}
      />

      <CallCenterModal
        open={modalCallCenter}
        onClose={() => setModalCallCenter(false)}
      />

      <HelpCenterModal
        open={modalHelpCenter}
        onClose={() => setModalHelpCenter(false)}
      />

      <TransparencyPortalModal
        open={modalTransparencyPortal}
        onClose={() => setModalTransparencyPortal(false)}
      />

      <ResponsibleBrokerModal
        open={modalResponsibleBroker}
        onClose={() => setModalResponsibleBroker(false)}
      />
      <ModalBuyPolicies
        open={modalBuyPolicies}
        onClose={() => setModalBuyPolicies(false)}
        title="Faça sua apólice agora"
        description={
          <>
            <Text variant="body2">
              Para nomear guardiões para o seu seguro ou acionar a assistência
              funeral, primeiro você precisa ter uma apólice conosco!
            </Text>
            <Text variant="body2">Deseja simular um seguro?</Text>
          </>
        }
      />

      <RequestBroker
        open={requestBrokerIsOpen}
        onClose={() => setRequestBrokerIsOpen(false)}
        showNotification
      />
    </MenuContext.Provider>
  )
}

export default MenuProvider
