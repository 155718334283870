import { ClickGeneralConditionsLink } from '../../../../../domain/models'
import { Dispatcher, Service } from '../../../../protocols'

export type IUpsellClickGeneralConditionsLinkService =
  Service<ClickGeneralConditionsLink>

/**
 * Click General Conditions Link
 *
 * @category Upsell
 */
export class UpsellClickGeneralConditionsLinkService
  implements IUpsellClickGeneralConditionsLinkService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   * @param {ClickGeneralConditionsLink} data
   */
  execute(data: ClickGeneralConditionsLink): void {
    this.dispatcher.execute(
      'Upsell - Clique "condições gerais completa" em detalhes do produto',
      data,
    )
  }
}
