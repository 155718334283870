import { Services } from '@azos/core'
import { IProtectedRepository } from '@data/repositories'
import { ListProtectedUseCase } from '@domain/usecases'

type Params = ListProtectedUseCase.Params
type Response = ListProtectedUseCase.Response

export type IListProtectedService = Services<Params, Response>

export class ListProtectedService implements IListProtectedService {
  constructor(private readonly quotationsRepository: IProtectedRepository) {}

  async execute(params: Params): Promise<Response> {
    return await this.quotationsRepository.list(params)
  }
}
