import React from 'react'

import { Text } from '@azos/shared'

import { Divider, Link } from '../../atoms'
import { EmailLogin } from '../../molecules'
import { SocialLoginButtons } from '../../molecules/SocialLoginButtons'

interface SignInProps {
  hiddenRegistration?: boolean
}

const SignIn: React.VFC<SignInProps> = ({ hiddenRegistration }) => {
  return (
    <>
      <Text variant="h1">Faça seu login</Text>
      <EmailLogin mt={4} />
      <Divider label="OU" />
      <SocialLoginButtons />

      {!hiddenRegistration && (
        <>
          <Divider />
          <Link href="/cadastro" label="Você ainda não se cadastrou?">
            Cadastre-se aqui!
          </Link>
        </>
      )}
    </>
  )
}

export default SignIn
