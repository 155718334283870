import React from 'react'

import { Header } from '@Azos-Seguros/apollo'
import { Typography } from '@Azos-Seguros/apollo'
import { useMediaQuery } from '@azos/shared'

import { NavigationProps } from './Navigation.props'
import { Root } from './Navigation.styles'

const Navigation = ({
  className,
  onClick,
  text,
  noText = false,
}: NavigationProps) => {
  const { isMobile } = useMediaQuery()

  return (
    <Header className={className}>
      <Root $noText={noText}>
        <div className="navigation-icon" onClick={onClick}>
          <i className="icon-arrow-left" />
        </div>
        {!isMobile && (
          <div className="navigation-title">
            <Typography variant="bodyBold2">{text}</Typography>
          </div>
        )}
      </Root>
    </Header>
  )
}

export default Navigation
