import { ViewExperiment } from '../../../domain/models'
import { Dispatcher, Service } from '../../protocols'

export type IViewExperimentService = Service<ViewExperiment>

/**
 * View Experiment
 *
 * @category Simulation
 */
export class ViewExperimentService implements IViewExperimentService {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   * @param {ViewExperiment} data
   */
  execute(data: ViewExperiment): void {
    this.dispatcher.execute('Viewed Experiment', data)
  }
}
