import { css, Utils } from '@azos/shared'
import styled from '@emotion/styled'

type PolicyDetailsProps = {
  $isLoadingOrError: boolean
}

export const Root = styled(
  'div',
  Utils.transient.transientStyleProps,
)<PolicyDetailsProps>(
  ({ theme, $isLoadingOrError }) => css`
    align-items: center;
    flex: 1;
    margin: 0 -16px -40px;
    padding: 24px 40px;

    @media (min-width: ${theme.breakpoints.md}px) {
      margin: 0 -44px -40px;
    }

    ${$isLoadingOrError
      ? css`
          background: ${theme.palette.neutral[50]};
          height: 100%;
        `
      : css`
          background: ${theme.palette.neutral[100]};
        `}

    > .empty-state-wrapper {
      display: grid;
      place-items: center;
      height: 100%;
    }

    .downloads {
      margin: 16px 0 32px;

      > div {
        & + div {
          margin-top: 16px;
        }
      }
    }

    .cancel-policy {
      margin-bottom: 16px;

      h4 {
        font-size: 18px;
        font-weight: 600;
        margin: 0 0 20px;
      }

      button {
        width: 100%;
        border-width: 2px;
        height: 48px;
        font-weight: 600;
      }
    }
  `,
)
