import React from 'react'

import { useNotification } from '@Azos-Seguros/apollo'
import analytics from '@azos/analytics'
import { EmptyState, Spinner, Utils } from '@azos/shared'
import { Button } from '@azos/shared/src/components/v2'
import { WizardTutorialTag } from '@data/models'
import { Beneficiaries } from '@domain/models'
import { useOnboardingWizard } from '@presentation/providers/OnboardingWizardProvider'
import dayjs from 'dayjs'
import { isEqual } from 'lodash'

import { BeneficiariesCardProps } from './BeneficiariesCard.props'
import { Root } from './BeneficiariesCard.styles'
import { BeneficiaryAddCard, BeneficiariesEditCard } from './components'

const BeneficiariesCard: React.VFC<BeneficiariesCardProps> = ({
  id,
  lifeInsuranceCoverage,
  beneficiaries: _beneficiaries,
  updateBeneficiariesService,
}) => {
  const { addNotification } = useNotification()
  const { wizard, refreshSummary } = useOnboardingWizard()

  const [beneficiaries, setBeneficiaries] = React.useState<Beneficiaries[]>([])
  const [modalEdit, setModalEdit] = React.useState(false)
  const [modalAdd, setModalAdd] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [success, setSuccess] = React.useState(false)

  const beneficiariesActionWasExecuted = React.useMemo(() => {
    return wizard?.tutorial.find(
      item => item.tag === WizardTutorialTag.beneficiaries,
    )?.mainAction?.actions[0].executed
  }, [wizard])

  const getBeneficiaryInfo = () => {
    const numberOfBeneficiaries = beneficiaries.length + 1
    const quota = lifeInsuranceCoverage / numberOfBeneficiaries
    const percentage = 100 / numberOfBeneficiaries

    return {
      quota: Math.trunc(quota),
      percentage: Math.trunc(percentage),
    }
  }

  const addBeneficiarySuccess = ({
    birthDate,
    ...beneficiary
  }: Omit<Beneficiaries, 'percentage' | 'quota' | 'birthDate'> & {
    birthDate: Date
  }) => {
    setModalAdd(false)
    setSuccess(false)

    const newBeneficiaries = beneficiaries.map(item => ({
      ...item,
      ...getBeneficiaryInfo(),
    }))

    newBeneficiaries.push({
      ...beneficiary,
      birthDate: dayjs(birthDate).format('DD/MM/YYYY'),
      ...(beneficiary.kinshipDetails && {
        kinshipDetails: beneficiary.kinshipDetails,
      }),
      quota:
        lifeInsuranceCoverage -
        newBeneficiaries.reduce((prev, current) => prev + current.quota, 0),
      percentage:
        100 -
        newBeneficiaries.reduce(
          (prev, current) => prev + current.percentage,
          0,
        ),
    })

    setBeneficiaries(newBeneficiaries)
  }

  const editBeneficiariesSuccess = (newBeneficiaries: Beneficiaries[]) => {
    analytics.dashboard.saveEditedBeneficiaryModal.execute()
    setModalEdit(false)
    setSuccess(false)
    setBeneficiaries(newBeneficiaries)
  }

  const handleSubmitBeneficiaries = async () => {
    setLoading(true)

    try {
      await updateBeneficiariesService.execute({ policyId: id, beneficiaries })
      analytics.dashboard.saveBeneficiaryChanges.execute()
      setSuccess(true)

      addNotification({
        description: 'Beneficiários alterados com sucesso.',
        variant: 'success',
      })
      setLoading(false)

      if (!beneficiariesActionWasExecuted) {
        await refreshSummary()
      }
    } catch (_error) {
      addNotification({
        description:
          'Não foi possível alterar os beneficiários. Por favor, entre em contato que nosso atendimento.',
        variant: 'error',
      })
    }

    setLoading(false)
  }

  React.useEffect(() => {
    setBeneficiaries(_beneficiaries)
  }, [_beneficiaries])

  return (
    <Root>
      <div className="header">
        <h4>Beneficiários</h4>
        <p>Pessoas que recebem a indenização em caso de morte do titular.</p>
      </div>

      <div className="card">
        {loading && (
          <div className="loading">
            <EmptyState
              align="center"
              icon={<Spinner />}
              title="Salvando beneficiários"
            >
              Por favor, não feche essa janela enquanto salvamos os
              beneficiários.
            </EmptyState>
          </div>
        )}

        <button
          className="add-beneficiary"
          disabled={loading}
          onClick={() => setModalAdd(true)}
        >
          Adicionar beneficiário
          <span className="icon-add" />
        </button>

        {beneficiaries.length === 0 && (
          <div className="empty-state-wrapper">
            <EmptyState align="center" icon="icon-guardian">
              Caso você não defina nenhum beneficiário, a indenização será feita
              para os seus beneficiários legais.
            </EmptyState>
          </div>
        )}

        {beneficiaries.length > 0 && (
          <>
            <ul>
              {beneficiaries.map((beneficiary, index) => (
                <li
                  className="beneficiary"
                  key={`${index}-${beneficiary.fullName}`}
                >
                  <div className="heading">
                    <p>{beneficiary.fullName}</p>
                    <span>{beneficiary.percentage}%</span>
                  </div>

                  <div className="info">
                    <p>{Utils.currency.format(beneficiary.quota)}</p>
                    <p>{beneficiary.kinship}</p>
                  </div>
                </li>
              ))}
            </ul>

            <button
              className="edit-beneficiaries"
              disabled={loading}
              onClick={() => setModalEdit(true)}
            >
              Alterar beneficiários
            </button>
          </>
        )}
        {!isEqual(_beneficiaries, beneficiaries) && !success && (
          <Button
            fullWidth
            className="save-beneficiaries"
            disabled={loading}
            onClick={handleSubmitBeneficiaries}
          >
            Salvar alterações
          </Button>
        )}
      </div>

      <BeneficiariesEditCard
        open={modalEdit}
        initialBeneficiaries={beneficiaries}
        lifeInsuranceCoverage={lifeInsuranceCoverage}
        onClose={() => setModalEdit(false)}
        onSubmit={editBeneficiariesSuccess}
      />

      <BeneficiaryAddCard
        open={modalAdd}
        onClose={() => setModalAdd(false)}
        onSubmit={addBeneficiarySuccess}
      />
    </Root>
  )
}

export default BeneficiariesCard
