import { CreateQuotationService, UpdateQuotationService } from '@data/services'

import { makeProposalConsumer, makeProposalProducer } from '../adapters'
import { makeQuotationsRepository } from '../repositories'

export const makeCreateQuotationService = () =>
  new CreateQuotationService(makeQuotationsRepository(), makeProposalProducer())

export const makeUpdateQuotationService = () =>
  new UpdateQuotationService(makeQuotationsRepository(), makeProposalConsumer())
