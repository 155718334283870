import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled('div')(
  ({ theme }) => css`
    .payment-history-modal {
      &__wrapper {
        padding: ${theme.spacing[3]}px;
        background: ${theme.palette.neutral[50]};
      }
    }

    header {
      display: flex;
      align-items: center;
      margin-bottom: 48px;

      h3 {
        margin: 0;
        font-size: 24px;
        font-weight: 600;
      }

      button {
        cursor: pointer;
        background: transparent;
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        margin-left: 48px;

        span {
          display: flex;
          align-items: center;
          justify-content: center;
          background: #fff;
          height: 36px;
          width: 36px;
          border-radius: 36px;
          margin-left: 8px;
        }
      }
    }
  `,
)
