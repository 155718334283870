import { css, styled } from '../../styles'
import { Utils } from '../../utils'

type BreadcrumbStyleProps = {
  $active: boolean
  $viewed: boolean
}

export const Root = styled(
  'div',
  Utils.transient.transientStyleProps,
)(
  ({ theme }) => css`
    display: flex;
    justify-content: space-between;
    padding: 12px 20px;
    background: ${theme.palette.neutral[50]};

    @media (min-width: ${theme.breakpoints.md}px) {
      background: transparent;
    }
  `,
)

export const Stepper = styled(
  'div',
  Utils.transient.transientStyleProps,
)<BreadcrumbStyleProps>(
  ({ theme, $active, $viewed }) => css`
    display: grid;
    place-items: center;

    .breadcrumb {
      &__number,
      &__text {
        color: ${theme.palette.neutral[300]};
        font-size: ${theme.typography.caption.fontSize}px;
        line-height: 13px;
        font-weight: ${theme.typography.fontWeightSemiBold};
      }

      &__number {
        background: transparent;
        padding: 4px;
        width: 21px;
        height: 21px;
        text-align: center;
        border-radius: 50%;

        @media (min-width: ${theme.breakpoints.md}px) {
          background: ${theme.palette.neutral[50]};
          color: ${theme.palette.neutral[300]};
        }
      }

      &__text {
        margin-top: ${theme.spacing[1]}px;
      }
    }

    ${$viewed
      ? css`
          .breadcrumb {
            &__number {
              background: ${theme.palette.neutral[300]};

              color: ${theme.palette.neutral[50]};

              @media (min-width: ${theme.breakpoints.md}px) {
                background: ${theme.palette.neutral[200]};
                color: ${theme.palette.neutral[400]};
              }
            }

            &__text {
              color: ${theme.palette.neutral[400]};
            }
          }
        `
      : ''}

    ${$active
      ? css`
          .breadcrumb {
            &__number {
              background: ${theme.palette.primary.main};
              color: ${theme.palette.neutral[50]};
            }

            &__text {
              color: ${theme.palette.neutral[500]};
            }
          }
        `
      : ''}
  `,
  0,
)
