import React from 'react'

import { Button, Typography, useNotification } from '@Azos-Seguros/apollo'
import { useAuth } from '@azos/account'
import analytics from '@azos/analytics'
import {
  ActionsReduceValueType,
  PolicyCancelUserType,
} from '@azos/analytics/src/domain/models'
import { CardCopyText, Utils } from '@azos/shared'
import { Icon } from '@azos/shared/src/icons'
import { azosPhone } from '@main/config/contact'
import { Navigation } from '@presentation/components/molecules/Navigation'

import { ReduceValueProps } from './ReduceValue.props'
import Root from './ReduceValue.styles'

const ReduceValue: React.VFC<ReduceValueProps> = ({ onStepChange }) => {
  const { user } = useAuth()
  const { addNotification } = useNotification()

  const onSuccessCopy = (copyType: string) => {
    addNotification({
      variant: 'success',
      description: `${copyType} copiado com sucesso`,
    })
  }

  const brokerData = user?.brokerUser

  const isBroker = React.useMemo(
    () => user?.isBroker || user?.isInsuredByBroker,
    [user],
  )

  React.useEffect(() => {
    analytics.dashboard.policy.cancel.viewReduceValue.execute({
      type: isBroker ? PolicyCancelUserType.BROKER : PolicyCancelUserType.B2C,
    })
  }, [isBroker])

  const onStepChangeButton = () => {
    onStepChange(2)
    analytics.dashboard.policy.cancel.actionsReduceValue.execute({
      type: isBroker ? PolicyCancelUserType.BROKER : PolicyCancelUserType.B2C,
      action: ActionsReduceValueType.NAO_OBRIGADO,
    })
  }

  const reduceValueButton = () => {
    window.open(whatsappUrl, '_blank')
    analytics.dashboard.policy.cancel.actionsReduceValue.execute({
      type: isBroker ? PolicyCancelUserType.BROKER : PolicyCancelUserType.B2C,
      action: ActionsReduceValueType.SOLICITAR,
    })
  }

  const cardContent = [
    {
      icon: 'icon-delete',
      title: 'Excluir coberturas',
      description:
        'Você pode solicitar que uma ou mais coberturas contratadas sejam excluídas de sua apólice.',
    },
    {
      icon: 'icon-policy-reduce',
      title: 'Reduzir do capital segurado',
      description:
        'Você pode solicitar a redução do capital segurado de uma ou mais coberturas.',
    },
  ]

  const cardContentBroker = [
    {
      icon: 'icon-briefcase',
      content: `${brokerData?.fullName}`,
      noCopy: true,
    },
    {
      icon: 'icon-dial-phone',
      name: 'Telefone',
      content: `${Utils.formatter.formatPhone(brokerData?.phone ?? '')}`,
      noCopy: false,
    },
    {
      icon: 'icon-email',
      name: 'E-mail',
      content: `${brokerData?.email}`,
      noCopy: false,
    },
  ]

  const whatsAppMessage = `Olá! Sou um segurado da Azos e gostaria de solicitar alterações na minha apólice, pois estou tendo problemas em arcar com o valor mensal.
  Aguardo retorno.`

  const whatsappUrl = React.useMemo(
    () =>
      `https://api.whatsapp.com/send?phone=${azosPhone}&text=${whatsAppMessage} 
      `,
    [whatsAppMessage],
  )

  const renderCardBroker = cardContentBroker.map(cardBroker => {
    return (
      <div key={cardBroker.content} className="reduce-value__broker--content">
        <CardCopyText
          className={`reduce-value__broker--content__card-copy ${
            cardBroker.noCopy ? 'no-copy' : ''
          }`}
          startIcon={cardBroker.icon}
          value={cardBroker.content}
          onClick={() => onSuccessCopy(`${cardBroker.name}`)}
        />
      </div>
    )
  })

  const renderCards = cardContent.map(card => {
    return (
      <div key={card.title} className="reduce-value__card">
        <div className="reduce-value__card--title">
          <i className={card.icon} />
          <Typography variant="bodyBold3">{card.title}</Typography>
        </div>
        <div className="reduce-value__card--description">
          <Typography variant="caption">{card.description}</Typography>
        </div>
      </div>
    )
  })

  return (
    <Root>
      <div className="reduce-value">
        <Navigation
          noText
          className="reduce-value__navigation"
          onClick={() => onStepChange(0)}
        />
        <div className="reduce-value__header">
          <div className="reduce-value__header--text">
            <Icon.PolicyReduceDuotone />
            <Typography variant="h5">
              Gostaria de <strong>reduzir o valor</strong> do seu seguro?
            </Typography>
            <Typography variant="body3">
              Você pode solicitar alterações na sua apólice para reduzir o valor
              pago mensalmente e continuar com sua proteção.
            </Typography>
          </div>
        </div>
        <div className="reduce-value__content">
          <Typography variant="bodyBold">
            Por exemplo, você pode optar por
          </Typography>
        </div>
        {renderCards}
        <div className="reduce-value__content--options">
          <Typography variant="body3">Isso vai gerar</Typography>
        </div>
        <div className="reduce-value__card">
          <div className="reduce-value__card--title">
            <i className="icon-currency" />
            <Typography variant="bodyBold3">
              Redução do valor do prêmio
            </Typography>
          </div>
          <div className="reduce-value__card--description">
            <Typography variant="caption">
              Escolhendo uma ou ambas as opções, o valor do seu prêmio
              (mensalidade) será reduzido.
            </Typography>
          </div>
        </div>
        {user?.isInsuredByBroker && (
          <>
            <div className="reduce-value__broker">
              <Typography variant="body3">
                Para solicitar a redução do prêmio da sua apólice, entre em
                contato com o seu corretor:
              </Typography>
            </div>
            <div>{renderCardBroker}</div>
          </>
        )}
        <div className="reduce-value__button">
          {user?.isInsuredByBroker || (
            <Button fullWidth onClick={reduceValueButton}>
              Sim, gostaria
            </Button>
          )}
          <Button fullWidth variant="outline" onClick={onStepChangeButton}>
            Não, obrigado
          </Button>
        </div>
      </div>
    </Root>
  )
}

export default ReduceValue
