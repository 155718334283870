import { SWRServices } from '@azos/core'
import { IProposalsRepository } from '@data/repositories'
import { LoadProposalByIdUseCase } from '@domain/usecases'

type Params = LoadProposalByIdUseCase.Params
type Response = LoadProposalByIdUseCase.Response

export type ILoadProposalDetailsService = SWRServices<Params, Response>

export class LoadProposalDetailsService implements ILoadProposalDetailsService {
  constructor(private readonly proposalsRepository: IProposalsRepository) {}

  getUrl(params: Params): string {
    return `/proposals/${params?.proposalId}`
  }

  async execute(params: Params): Promise<Response> {
    return await this.proposalsRepository.loadById(params)
  }
}
