import React from 'react'

import { Radio, Typography } from '@Azos-Seguros/apollo'
import { Gender } from '@azos/core'
import {
  Checkbox,
  Input,
  InputBirthdate,
  InputCPF,
  InputPhone,
  Select,
  Text,
} from '@azos/shared/src/components/v2'
import { useFormikContext } from 'formik'
import Router from 'next/router'

import { FormControl as WizardFormControl } from '../../../../molecules'
import { RegistrationValue } from '../../Registration.props'
import { PersonalDataProps } from './PersonalData.props'
import { RadioGroupContent, Root } from './PersonalData.styles'

const MARITAL_STATUS_OPTIONS = [
  {
    label: 'Estado civil',
    value: '',
    disabled: true,
  },
  {
    label: 'Solteira/o',
    value: 'single',
  },
  {
    label: 'Casada/o',
    value: 'married',
  },
  {
    label: 'Viúva/o',
    value: 'widowed',
  },
  {
    label: 'Divorciada/o',
    value: 'divorced',
  },
  {
    label: 'União estável',
    value: 'stable_union',
  },
]

const PersonalData: React.VFC<PersonalDataProps> = ({
  disabledUserField = false,
  disableNameField = false,
}) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    isSubmitting,
  } = useFormikContext<RegistrationValue>()

  const isDisabled = React.useMemo<boolean>(() => isSubmitting, [isSubmitting])

  const cellPhoneInputRef = React.useRef<HTMLInputElement>(null)

  const isPersonalFieldDisabled = React.useMemo<boolean>(
    () => isDisabled || disabledUserField,
    [isDisabled, disabledUserField],
  )

  const isUserFieldDisabled = React.useMemo(
    () => disableNameField && isPersonalFieldDisabled,
    [disableNameField, isPersonalFieldDisabled],
  )

  React.useEffect(() => {
    if (cellPhoneInputRef.current && isUserFieldDisabled) {
      cellPhoneInputRef.current.scrollIntoView()
    }
  }, [cellPhoneInputRef, isUserFieldDisabled])

  return (
    <Root>
      <Text variant="h1">Dados pessoais</Text>

      <div className="personal-data__content">
        <Input
          name="full_name"
          label="Nome completo"
          placeholder="Digite seu nome completo"
          value={values.full_name}
          onChange={event => !isUserFieldDisabled && handleChange(event)}
          onBlur={handleBlur}
          error={touched.full_name && !!errors.full_name}
          helperText={touched.full_name ? errors.full_name : undefined}
          disabled={isUserFieldDisabled}
          fullWidth
        />

        {values.social_name && (
          <Input
            name="social_name"
            label="Nome social"
            value={values.social_name}
            disabled={true}
            fullWidth
          />
        )}

        <InputBirthdate
          name="birth_date"
          label="Data de nascimento"
          value={values.birth_date ?? undefined}
          onChange={value =>
            !disabledUserField && setFieldValue('birth_date', value)
          }
          onBlur={handleBlur}
          error={touched.birth_date && !!errors.birth_date}
          helperText={touched.birth_date ? errors.birth_date : undefined}
          disabled={isPersonalFieldDisabled}
          fullWidth
        />

        <RadioGroupContent>
          <Text>No seu registro de nascimento consta:</Text>
          <div
            className="radio-group-content__radio-group"
            role="group"
            aria-labelledby="gender"
          >
            <Radio
              aria-label="gender"
              id="radio"
              type="radio"
              name="gender"
              label="Sexo masculino"
              value={Gender.MALE}
              checked={values.gender === Gender.MALE}
              disabled={isPersonalFieldDisabled}
            />
            <Radio
              aria-label="gender"
              type="radio"
              id="radio"
              name="gender"
              label="Sexo feminino"
              value={Gender.FEMALE}
              checked={values.gender === Gender.FEMALE}
              disabled={isPersonalFieldDisabled}
            />
          </div>
        </RadioGroupContent>

        <InputPhone
          innerRef={cellPhoneInputRef}
          autoFocus={isUserFieldDisabled}
          name="phone"
          value={values.phone}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.phone && !!errors.phone}
          helperText={touched.phone ? errors.phone : undefined}
          disabled={isDisabled}
          fullWidth
        />

        <RadioGroupContent>
          <Checkbox
            label="Aceito receber minha apólice e demais informações sobre meu seguro
              via WhatsApp"
            id="authorizes_whatsapp_contact"
            name="authorizes_whatsapp_contact"
            value={values.authorizes_whatsapp_contact.toString()}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isDisabled}
          />
        </RadioGroupContent>

        <InputCPF
          name="cpf"
          value={values.cpf}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.cpf && !!errors.cpf}
          helperText={touched.cpf ? errors.cpf : undefined}
          disabled={isDisabled}
          fullWidth
        />

        <Select
          name="marital_status"
          label="Estado Civil"
          value={values.marital_status}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.marital_status && !!errors.marital_status}
          helperText={touched.marital_status && errors.marital_status}
          disabled={isDisabled}
          options={MARITAL_STATUS_OPTIONS}
          fullWidth
          className="personal-data__select"
        />

        <RadioGroupContent>
          <Text>Você é uma pessoa politicamente exposta?</Text>
          <Typography variant="body3" color="light">
            Informe se você desempenha ou desempenhou nos últimos 5 anos, no
            Brasil ou em outros países, territórios e dependências estrangeiras,
            cargos, empregos ou funções públicas relevantes, ou se possui
            parentes próximos dentro dessa descrição.
          </Typography>

          <div className="radio-group-content__radio-group">
            <Radio
              aria-label="is_politically_exposed_person"
              id="true"
              name="is_politically_exposed_person"
              label="Sim"
              value="true"
              checked={values.is_politically_exposed_person === 'true'}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Radio
              aria-label="is_politically_exposed_person"
              id="false"
              name="is_politically_exposed_person"
              label="Não"
              value="false"
              checked={values.is_politically_exposed_person === 'false'}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </RadioGroupContent>
      </div>

      <WizardFormControl
        mt={4}
        onPrev={() => Router.back()}
        requiredValid={false}
        disabledNextButton={false}
      />
    </Root>
  )
}

export default PersonalData
