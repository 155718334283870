import { Services } from '@azos/core'
import { IUserRepository } from '@data/repositories'
import { UpdateUserUseCase } from '@domain/usecases'

type Params = UpdateUserUseCase.Params
type Response = UpdateUserUseCase.Response

export type IUpdateUserService = Services<Params, Response>

export class UpdateUserService implements IUpdateUserService {
  constructor(private readonly userRepository: IUserRepository) {}

  async execute(params: Params): Promise<Response> {
    return await this.userRepository.updateUser(params)
  }
}
