import React from 'react'
import ReactConfetti from 'react-confetti'

import { theme } from '../../styles'
import { ConfettiProps } from './Confetti.props'
import { Container } from './Confetti.styles'

const Confetti = ({ isOnModal = false, ...props }: ConfettiProps) => {
  const [isShowing, setIsShowing] = React.useState(true)
  const ref = React.useRef<HTMLDivElement>(null)

  const { height: ConfettiHeight, width: ConfettiWidth, loop = false } = props

  const isServer = React.useMemo(() => typeof window === 'undefined', [])

  const maxHeight = React.useCallback(() => {
    if (isOnModal) {
      const modal = document.querySelector('[role="dialog"]')
      return !isServer && modal ? modal?.clientHeight : 400
    }

    return !isServer ? window.document.body.offsetHeight : 400
  }, [isServer, isOnModal])

  const [height, setHeight] = React.useState<number>(ConfettiHeight ?? 300)
  const [width, setWidth] = React.useState<number>(ConfettiWidth ?? 300)

  const handleResize = React.useCallback(() => {
    const parentRef = ref.current
    if (parentRef) {
      setHeight(Math.min(maxHeight(), parentRef.clientHeight))
      setWidth(parentRef.clientWidth - 24)
    }
  }, [maxHeight, ref])

  React.useEffect(() => {
    handleResize()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnModal])

  React.useEffect(() => {
    window.addEventListener('resize', handleResize)
    ;() => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])

  if (!isShowing) {
    return null
  }

  return (
    <Container ref={ref}>
      <ReactConfetti
        height={height}
        width={width}
        drawShape={ctx => {
          ctx.beginPath()
          ctx.fillRect(6 / 2, -6 / 2, 6, 6)
          ctx.stroke()
          ctx.closePath()
        }}
        numberOfPieces={100}
        initialVelocityY={7}
        recycle={loop}
        colors={[theme.palette.primary.main, theme.palette.primary.dark]}
        {...props}
        style={{ ...props.style, zIndex: theme.zIndex.tooltip }}
        onConfettiComplete={() => {
          setHeight(0)
          setWidth(0)
          setIsShowing(false)
        }}
      />
    </Container>
  )
}

export default Confetti
