import { ViewOnboardingPolicyContent } from '../../../../domain/models'
import { Dispatcher, Service } from '../../../protocols'

export type IViewOnboardingPolicyContentService =
  Service<ViewOnboardingPolicyContent>

/**
 * View onboarding policy content
 *
 * @category Dashboard
 */
export class ViewOnboardingPolicyContentService
  implements IViewOnboardingPolicyContentService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @param {ViewOnboardingHomepage} data
   * @function
   */
  execute(data: ViewOnboardingPolicyContent): void {
    this.dispatcher.execute(
      'Area logada web - Visualização do conteúdo de apólice do onboarding',
      data,
    )
  }
}
