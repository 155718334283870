import React from 'react'

import { Header, UserMenu } from '@azos/shared/src/components/v2'
import Router from 'next/router'

import { useAuth } from '../../hooks'
import { Root } from './Container.styles'

const Container: React.FCC = ({ children }) => {
  const { user, isSigned, logout } = useAuth()

  const handleSignOut = React.useCallback(async () => {
    await logout()
    await Router.push('/login')
  }, [logout])

  return (
    <Root>
      <Header>
        {isSigned && <UserMenu user={user} onSignOut={handleSignOut} />}
      </Header>
      <main>
        <div>{children}</div>
      </main>
    </Root>
  )
}

export default Container
