import { authCookies, makeStorageProvider } from '@azos/core'
import { NextPageContext } from 'next'

import { Utils } from '../utils'

type WithSSROptions = {
  loginUrl?: string
  origin: string
}

type WithSSRData = {
  context: NextPageContext
  token: string
  redirect: {
    destination: string
  }
}

export const withSSR = (
  resolve: (data: WithSSRData) => void,
  options?: WithSSROptions,
) => {
  return (context: NextPageContext) => {
    const config: Required<WithSSROptions> = Object.assign(
      {
        loginUrl: '/login',
        origin: '',
      },
      options,
    )

    if (config.origin && context.req?.url) {
      const url = context.req.url
      const qs = Utils.queryString.formatQueryStringUrl(url)
      config.origin = `${config.origin}${qs}`
    }

    const storageProvider = makeStorageProvider(context.req?.headers?.cookie)

    const token =
      storageProvider.get<string>(authCookies.COOKIE_TOKEN_KEY) || ''

    const destinationUrl = Utils.formatter.formatLoginUrl(
      config.loginUrl,
      config.origin || context.req?.url,
    )

    if (!token) {
      return {
        redirect: {
          destination: destinationUrl,
        },
      }
    }

    return resolve({
      context,
      token,
      redirect: {
        destination: destinationUrl,
      },
    })
  }
}
