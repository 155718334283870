import { Dispatcher, Service } from '../../../protocols'

export type IClickConfirmUpdateChangesService = Service<void>

/**
 * Click Confirm Update Changes
 *
 * @category PersonalData
 */
export class ClickConfirmUpdateChangesService
  implements IClickConfirmUpdateChangesService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @param
   * @function
   */
  execute(): void {
    this.dispatcher.execute(
      'Web - clique em confirmação de salvar alterações em Bottom sheets de Sua conta',
    )
  }
}
