import { SelectPolicyCancelReason } from '../../../../../domain/models'
import { Dispatcher, Service } from '../../../../protocols'

export type ISelectPolicyCancelReasonService = Service<SelectPolicyCancelReason>

/**
 * Select Policy Cancel Reason
 *
 * @category Dashboard
 */
export class SelectPolicyCancelReasonService
  implements ISelectPolicyCancelReasonService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @param {SelectPolicyCancelReason} data
   * @function
   */
  execute(data: SelectPolicyCancelReason): void {
    this.dispatcher.execute(
      'Area logada web - Clique em continuar no modal com motivo de cancelamento da apólice',
      data,
    )
  }
}
