import { css, styled } from '../../styles'

export const Root = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    background: ${theme.palette.neutral[400]};
    padding: ${theme.spacing[4]}px ${theme.spacing[4]}px 0;

    .page-header {
      &__icon {
        font-size: 2.5rem;
        color: ${theme.palette.neutral[300]};
      }

      &__title {
        margin-top: 14px;
        color: ${theme.palette.neutral[50]};
        line-height: 140%;
      }

      &__description {
        margin: ${theme.spacing[2]}px 0 ${theme.spacing[4]}px;
        color: ${theme.palette.neutral[200]};
        line-height: 144%;
      }

      &__link-content {
        display: flex;
        align-items: flex-start;

        * + * {
          margin-left: 12px;
        }

        i {
          color: ${theme.palette.neutral[50]};
        }
      }

      &__button {
        margin-bottom: ${theme.spacing[4]}px;
        padding: 0;
        border: none;
        outline: none;
        background: transparent;
        text-decoration: underline;
        text-align: left;

        font-size: 0.875rem;
        line-height: 144%;
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.neutral[50]};
      }
    }
  `,
)
