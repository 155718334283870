import styled from '@emotion/styled'

import { Modal } from '../../components/Modal'
import { css } from '../../styles'
import { Utils } from '../../utils'

const sizes = {
  sm: '40px',
  md: '80px',
}

export const StyledModal = styled(Modal)(
  ({ theme }) => css`
    .user-modal-content {
      background: ${theme.palette.neutral[50]};
      border-radius: ${theme.spacing[3]}px;
      padding: ${theme.spacing[3]}px ${theme.spacing[3]}px ${theme.spacing[4]}px;
      z-index: ${theme.zIndex.modal};

      @media (min-width: ${theme.breakpoints.md}px) {
        width: 360px;
        z-index: 100;
      }

      &__title {
        font-size: 16px;
        font-weight: ${theme.typography.fontWeightBold};
      }

      &__subtitle {
        padding: 16px 0 24px 0;
        font-size: 14px;
        font-weight: ${theme.typography.fontWeightRegular};
      }

      &__input {
        display: none;
      }
    }
  `,
)
type RootProps = {
  $size: 'sm' | 'md'
}
/* prettier-ignore */
export const Root = styled(
  'div', 
  Utils.transient.transientStyleProps
  )<RootProps>(({ theme, $size }) => css`
  position: relative;
  z-index: 1;
  button {
    cursor: pointer;
    user-select: none;
  }

  .user-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;

    border:${theme.spacing[0]}px;
    border-radius: ${theme.spacing[7]}px;
    background: ${theme.palette.neutral[100]};
    height: ${sizes[$size]};
    width: ${sizes[$size]};

    overflow: hidden;
    padding: ${theme.spacing[0]}px;

    i {
      font-size: 1.5rem;
    }

    span {
      text-transform: uppercase;
      font-size: 16px;
      font-weight: ${theme.typography.fontWeightBold};
      color: ${theme.palette.neutral[500]};
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  
`,
)
