import MaterialDrawer, { DrawerProps } from '@mui/material/Drawer'
import MaterialIconButton, { IconButtonProps } from '@mui/material/IconButton'

import { css, styled } from '../../styles'

export const Drawer = styled(MaterialDrawer)<DrawerProps>(
  ({ theme }) => css`
    & > .MuiPaper-root {
      background-color: ${theme.palette.background.default};
      width: 100%;

      ${theme.breakpoints.up('md')} {
        background-color: ${theme.palette.background.paper};
        width: 50%;
      }
      ${theme.breakpoints.up('lg')} {
        width: 35%;
      }

      div.coverage__content {
        padding: ${theme.spacing(5)};
        z-index: 0;
      }

      button.close {
        position: fixed;
        top: ${theme.spacing(4)};
        right: ${theme.spacing(4)};
        z-index: 10;
      }
    }
  `,
)

export const IconButton = styled(MaterialIconButton)<IconButtonProps>(
  () => css`
    background-color: rgba(255, 255, 255, 0.5);

    &:hover {
      background-color: rgba(255, 255, 255, 0.7);
    }
  `,
)
