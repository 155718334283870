import { ClickContactBrokerHelp } from '../../../../domain/models'
import { Dispatcher, Service } from '../../../protocols'

export type IClickContactBrokerHelpService = Service<ClickContactBrokerHelp>

/**
 * Click Contact Broker Help
 *
 * @category Home
 */
export class ClickContactBrokerHelpService
  implements IClickContactBrokerHelpService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   * @param {ClickContactBrokerHelp} data
   */
  execute(data: ClickContactBrokerHelp): void {
    this.dispatcher.execute(
      'Area logada web - Clicou em contato na tela de solicitar ajuda ao corretor',
      data,
    )
  }
}
