import React from 'react'

import { Icon } from '../../../icons'
import { Utils } from '../../../utils'
import { ListItemProps } from './ListItem.props'
import { Description, Root, Title } from './ListItem.styles'

const ListItem: React.VFC<ListItemProps> = ({
  name = 'list-item',
  parentName,
  //
  startIcon,
  endIcon = <Icon.ChevronRight />,
  //
  title,
  description,
  //
  ...rest
}) => {
  return (
    <Root
      title={title}
      aria-label={title}
      data-action={Utils.dataNames.renderDataName(name, parentName)}
      {...rest}
    >
      <div className="list-item__container">
        {startIcon}
        <div className="list-item__content">
          <Title>{title}</Title>
          {description && <Description>{description}</Description>}
        </div>
      </div>
      {endIcon}
    </Root>
  )
}

export default ListItem
