import React from 'react'

import analytics from '@azos/analytics'
import { Text, Utils } from '@azos/shared'
import clsx from 'clsx'

import { ListActionProps, ListActionOption } from './ListAction.props'
import { Item, Root } from './ListAction.styles'

export const ListAction = ({
  name,
  options,
  icon,
  //
  parentName,
  ...rest
}: ListActionProps) => {
  const handleClickItem = (option: ListActionOption) => {
    if (parentName) {
      analytics.custom.action.execute({
        dataAction: Utils.dataNames.renderDataName(option.name, parentName),
      })
    }

    option.onClick?.()
  }

  return (
    <Root
      {...rest}
      data-action={Utils.dataNames.renderDataName(name, parentName)}
    >
      {options.map(option => (
        <Item
          className={clsx({ ['clickable']: !!option.onClick })}
          key={option.id || option.name}
          title={option.title || ''}
          aria-label={option.title || ''}
          data-action={Utils.dataNames.renderDataName(option.name, parentName)}
          onClick={() => handleClickItem(option)}
        >
          <div className="item__text-wrapper">
            <Text variant="body1" fontWeight={600}>
              {option.label}
            </Text>
            {option.description && (
              <Text variant="body1" className="item__text-wrapper__description">
                {option.description}
              </Text>
            )}
          </div>

          {option?.icon || icon}
        </Item>
      ))}
    </Root>
  )
}

export default ListAction
