import React from 'react'

import { useNotification } from '@Azos-Seguros/apollo'
import { useAuth } from '@azos/account'
import analytics from '@azos/analytics'
import {
  ReferralContextType,
  ReferralUserMaritalStatus,
} from '@azos/analytics/src/domain/models'
import { CardCopyText, useNotify } from '@azos/shared'
import { Button, Text } from '@azos/shared/src/components/v2'

import { InsuredReferralContentProps } from './InsuredReferralContent.props'
import { Root } from './InsuredReferralContent.styles'

const WHATSAPP_MESSAGE = `Oi, tudo bem? Dizem que amigo que é amigo, quando tem uma indicação boa, compartilha. Ainda mais se for para trazer tranquilidade para quem a gente gosta. Contratei um seguro na Azos e a experiência foi muito boa: durou menos de 10 minutos e me senti super seguro.%0AClica nesse link que sua chance de aprovação é maior:`

const InsuredReferralContent: React.FCC<InsuredReferralContentProps> = ({
  utm,
  url = 'https://azos.com.br',
  whatsAppMessage = WHATSAPP_MESSAGE,
}) => {
  const copyRef = React.useRef<HTMLButtonElement>()
  const { user } = useAuth()
  const { addNotification } = useNotification()

  const utmQs = React.useMemo(() => {
    return Object.entries(utm || {})
      .filter(([key]) => key.startsWith('utm'))
      .map(([key, value]) => {
        return `${key}=${value}`
      })
      .join('&')
  }, [utm])

  const referralUrl = React.useMemo(() => {
    const qs = Object.entries(utmQs).length ? `?${utmQs}` : ''
    return `${url}${qs}`
  }, [utmQs, url])

  const whatsappUrl = React.useMemo(
    () =>
      `https://api.whatsapp.com/send?text=${whatsAppMessage} ${encodeURIComponent(
        referralUrl,
      )}`,
    [referralUrl, whatsAppMessage],
  )

  const isMarried = user?.insuredUser?.maritalStatus === 'married'

  const handleClickCardCopyText = () => {
    analytics.dashboard.referral.code.referralCopyCode.execute({
      type: ReferralContextType.SHARE,
      status: isMarried
        ? ReferralUserMaritalStatus.MARRIED
        : ReferralUserMaritalStatus.OTHERS,
    })

    addNotification({
      variant: 'success',
      description:
        'Código copiado com sucesso. Agora só compartilhar com seus amigos e família.',
    })
  }

  const handleShare = () => {
    window.open(whatsappUrl, '_blank')
    analytics.dashboard.referral.code.referralShareCode.execute({
      type: ReferralContextType.SHARE,
      status: isMarried
        ? ReferralUserMaritalStatus.MARRIED
        : ReferralUserMaritalStatus.OTHERS,
    })
  }

  return (
    <Root>
      <Text variant="body2" className="insured-referral-content__description">
        Basta copiar o código abaixo e enviar para um amigo ou familiar que
        possa se beneficiar dos nossos seguros:
      </Text>

      <CardCopyText
        onClick={handleClickCardCopyText}
        buttonRef={copyRef}
        value={referralUrl}
      />

      <Button variant="primary" color="primary" onClick={handleShare} fullWidth>
        Compartilhar no Whatsapp
      </Button>
    </Root>
  )
}

export default InsuredReferralContent
