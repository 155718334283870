import React from 'react'

import analytics from '@azos/analytics'
import { ViewResetPasswordStatus } from '@azos/analytics/src/domain/models'
import { Modal, useMediaQuery, withModal } from '@azos/shared'
import { Button, Text } from '@azos/shared/src/components/v2'

import { WaitingApprovalPasswordModalProps } from './WaitingApprovalPasswordModal.props'
import { Root } from './WaitingApprovalPasswordModal.styles'

const WaitingApprovalPasswordModal: React.FCC<
  WaitingApprovalPasswordModalProps
> = ({ onClose, ...props }) => {
  const { isMobile } = useMediaQuery()

  React.useEffect(() => {
    analytics.dashboard.personalData.viewResetPassword.execute({
      status: ViewResetPasswordStatus.WAITING_APPROVAL,
    })
  }, [])

  return (
    <Modal
      {...props}
      onClose={onClose}
      variant={isMobile ? 'bottomDrawer' : 'modal'}
      maxWidth={isMobile ? undefined : '480px'}
      showCloseButton={false}
      showHeader={false}
    >
      <Root>
        <div className="waiting-approval-password-modal__title">
          <Text
            className="waiting-approval-password-modal__title-content"
            variant="bodyBold"
          >
            Redefinir senha
          </Text>
          {isMobile && <i className="icon-exit" onClick={onClose} />}
        </div>
        <Text
          variant="body2"
          className="waiting-approval-password-modal__description"
        >
          Aguarde a aprovação dos seus dados antes de redefinir sua senha de
          acesso à Azos.
        </Text>

        <Button onClick={onClose} fullWidth>
          Entendi
        </Button>
      </Root>
    </Modal>
  )
}

export default withModal<WaitingApprovalPasswordModalProps>(
  WaitingApprovalPasswordModal,
)
