import { Utils } from '@azos/shared'
import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
  ccname: Yup.string()
    .test({
      name: 'ccname',
      test: value => {
        const splitValue = value?.trim().split(' ')
        return (splitValue && splitValue?.length >= 2) || false
      },
      message: 'O nome é obrigatório',
    })
    .required('O nome é obrigatório'),
  cardnumber: Yup.string()
    .test({
      name: 'cardnumber',
      message: 'O número do cartão é inválido',
      test: value => {
        if (value && window.Iugu) {
          const sanitizedValue = Utils.sanitizer.onlyNumber(value)
          return window.Iugu.utils.validateCreditCardNumber(sanitizedValue)
        }
        return false
      },
    })
    .min(15, 'O número do cartão é inválido')
    .required('O número do cartão é obrigatório'),
  'cc-exp': Yup.string()
    .length(5, 'A data de vencimento é inválida')
    .test({
      name: 'cc-exp',
      message: 'A data de vencimento é inválida',
      test: value => {
        if (value && window.Iugu) {
          return window.Iugu.utils.validateExpirationString(value)
        }
        return false
      },
    })
    .required('A data de vencimento é obrigatória'),
  cvc: Yup.string()
    .min(3, 'O código de segurança é inválido')
    .max(4, 'O código de segurança é inválido')
    .test({
      name: 'cvc',
      message: 'O número de segurança é inválido',
      test(value) {
        if (value && window.Iugu) {
          const brand = window.Iugu.utils.getBrandByCreditCardNumber(
            Utils.sanitizer.onlyNumber(this.parent.cardnumber),
          )
          return window.Iugu.utils.validateCVV(value, brand)
        }
        return false
      },
    })
    .required('O código de segurança é obrigatório'),
})
