import React from 'react'

import { useNotification } from '@Azos-Seguros/apollo'
import { Modal } from '@azos/shared'
import { ButtonSelect } from '@azos/shared/src/components/v2'
import copy from 'copy-to-clipboard'

import { ResponsibleBrokerEmailModalProps } from './ResponsibleBrokerEmailModal.props'
import { Root } from './ResponsibleBrokerEmailModal.styles'

const ResponsibleBrokerEmailModal: React.FCC<
  ResponsibleBrokerEmailModalProps
> = ({ onClose, email, ...props }) => {
  const { addNotification } = useNotification()

  const onCopy = (value?: string) => {
    if (value) {
      copy(value)
      addNotification({
        variant: 'success',
        description: `Email copiado com sucesso`,
      })
    }
  }

  return (
    <Modal
      {...props}
      onClose={onClose}
      variant="bottomDrawer"
      showCloseButton={true}
      showHeader={true}
      title={email ?? ''}
      noGutter
    >
      <Root>
        <div className="responsible-broker-email-modal__content">
          <ButtonSelect
            title="Copiar e-mail"
            startIcon={<i className="icon-copy" />}
            className="responsible-broker-email-modal__button-select"
            onClick={() => onCopy(email)}
          />
          <ButtonSelect
            title="Enviar e-mail"
            startIcon={<i className="icon-email" />}
            className="responsible-broker-email-modal__button-select"
            onClick={() => window.open(`mailto:${email}`)}
          />
        </div>
      </Root>
    </Modal>
  )
}

export default ResponsibleBrokerEmailModal
