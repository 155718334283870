import dayjs from 'dayjs'

import { Creditcard, CreditcardData } from '../domain/models'
import { onlyNumber } from './sanitizer'

export const getMonth = (date: string): string => {
  return dayjs(date, 'MM/YY').format('MM')
}

export const getYear = (date: string): string => {
  return dayjs(date, 'MM/YY').format('YYYY')
}

export const getNames = (name: string): string[] => {
  const firstName = name.split(' ')[0] || ''
  const lastName = name.split(' ').slice(1).join(' ') || ''
  return [firstName, lastName]
}

export const formatCreditcard = (data: Creditcard): CreditcardData => {
  return {
    number: onlyNumber(data.ccnumber),
    month: getMonth(data.exp_date),
    year: getYear(data.exp_date),
    first_name: getNames(data.ccname)[0],
    last_name: getNames(data.ccname)[1],
    cvv: data.cvv,
  }
}
