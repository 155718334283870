import { css } from '@azos/shared'
import { styled } from '@mui/material/styles'

export const InputCurrency = styled('div')(
  ({ theme }) => css`
    .text-input {
      padding-bottom: 30px;

      input {
        width: 100%;
        height: 44px;
        font-size: 1.5rem;
        font-weight: ${theme.typography.fontWeightBold};
        text-align: center;
        background: #f1f2f5;
        caret-color: ${theme.palette.primary.main};
        border-radius: 4px 8px 0px 0px;
      }
      div {
        &:after {
          border-bottom-color: #4b4c4e;
        }
        &:before {
          border-bottom-color: #4b4c4e;
        }
      }
    }
  `,
)
