import React from 'react'

import analytics from '@azos/analytics'
import {
  ReferralContextType,
  ReferralUserMaritalStatus,
} from '@azos/analytics/src/domain/models'
import { Button, Input, InputPhone } from '@azos/shared/src/components/v2'
import { Formik, Form } from 'formik'

import {
  BrokerContentFormValues,
  BrokerContentProps,
  initialValues,
} from './BrokerContent.props'
import { Root } from './BrokerContent.styles'
import { validationSchema } from './BrokerContent.validation'

const BrokerReferralContent: React.FCC<BrokerContentProps> = ({
  maritalStatus,
  isInsured,
  notify,
}) => {
  const isMarried = maritalStatus === 'married'

  const handleSubmit = (values: BrokerContentFormValues) => {
    analytics.dashboard.referral.clickBrokerShare.execute({
      referral: {
        nome: values.name,
        telefone: values.phone,
      },
      type: ReferralContextType.SHARE,
      status: isMarried
        ? ReferralUserMaritalStatus.MARRIED
        : ReferralUserMaritalStatus.OTHERS,
      isInsured,
    })

    if (notify) {
      notify({
        variant: 'success',
        description:
          'Corretor indicado com sucesso. Indique seu corretor para mais pessoas.',
      })
    }
  }

  return (
    <Root>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnMount
      >
        {({ values, errors, touched, handleChange, handleBlur, isValid }) => (
          <Form className="broker-content__form">
            <Input
              fullWidth
              id="name"
              name="name"
              label="Nome"
              error={touched.name && !!errors.name}
              helperText={touched.name && errors.name}
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              className="broker-content__form-input"
            />
            <InputPhone
              fullWidth
              id="phone"
              name="phone"
              label="Telefone"
              error={touched.phone && !!errors.phone}
              helperText={touched.phone && errors.phone}
              value={values.phone}
              onChange={handleChange}
              onBlur={handleBlur}
              className="broker-content__form-input"
            />

            <Button
              fullWidth
              type="submit"
              className="broker-content__button"
              disabled={!isValid}
            >
              Indicar seu corretor
            </Button>
          </Form>
        )}
      </Formik>
    </Root>
  )
}

export default BrokerReferralContent
