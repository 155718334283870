import React from 'react'

import { Modal, useMediaQuery, withModal } from '@azos/shared'
import { Button, Text } from '@azos/shared/src/components/v2'

import { UserEditConfirmUpdateModalProps } from './UserEditConfirmUpdateModal.props'
import { Root } from './UserEditConfirmUpdateModal.styles'

const UserEditConfirmUpdateModal: React.FCC<
  UserEditConfirmUpdateModalProps
> = ({ onConfirm, onClose, ...props }) => {
  const { isMobile } = useMediaQuery()

  return (
    <Modal
      {...props}
      onClose={onClose}
      showHeader={false}
      maxWidth="400px"
      variant={isMobile ? 'bottomDrawer' : 'modal'}
    >
      <Root>
        <div className="user-edit-confirm-update-modal__title-content">
          <Text variant="h5">Deseja salvar alterações?</Text>
          <i className="icon-exit" onClick={onClose} />
        </div>
        <Text className="user-edit-confirm-update-modal__description">
          Você só poderá alterar seus dados novamente após o período de
          aprovação dos mesmos.
        </Text>

        <Button
          fullWidth
          onClick={onConfirm}
          className="user-edit-confirm-update-modal__confirm-button"
        >
          Sim
        </Button>
        <Button fullWidth variant="tertiary" onClick={onClose}>
          Não
        </Button>
      </Root>
    </Modal>
  )
}

export default withModal<UserEditConfirmUpdateModalProps>(
  UserEditConfirmUpdateModal,
)
