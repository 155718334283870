import { Dispatcher, Service } from '../../protocols'

export type ICallpageActivateService = Service<void>

/**
 * Activate callpage
 *
 * @category Callpage
 */
export class CallpageActivateService implements ICallpageActivateService {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @param simulationData
   * @function
   */
  execute(): void {
    this.dispatcher.execute('callpage_activate')
  }
}
