import React from 'react'

import { Modal, useWindowSize, withModal } from '@azos/shared'
import { Button, Text } from '@azos/shared/src/components/v2'

import { GuardianDeleteModalProps } from './GuardianDeleteModal.props'
import { Root } from './GuardianDeleteModal.styles'

const GuardianDeleteModal: React.VFC<GuardianDeleteModalProps> = ({
  open,
  onClose,
  onSubmit,
  loading,
}) => {
  const { width } = useWindowSize()

  const isMobile = React.useMemo(() => width < 768, [width])

  return (
    <Modal
      variant={isMobile ? 'bottomDrawer' : 'modal'}
      open={open}
      onClose={onClose}
    >
      <Root>
        <Text variant="h5">Você deseja excluir este guardião?</Text>
        <div className="modal-content__button-group">
          <Button
            onClick={onSubmit}
            fullWidth
            loading={loading}
            disabled={loading}
          >
            Sim
          </Button>
        </div>
      </Root>
    </Modal>
  )
}

export default withModal<GuardianDeleteModalProps>(GuardianDeleteModal)
