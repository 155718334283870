import { SWRServices } from '@azos/core'
import { IAddressesRepository } from '@data/repositories'
import { LoadByZipCodeUseCase } from '@domain/usecases'

type Params = LoadByZipCodeUseCase.Params
type Response = LoadByZipCodeUseCase.Response

export type ILoadByZipCodesService = SWRServices<Params, Response>

export class LoadByZipCodeService implements ILoadByZipCodesService {
  constructor(private readonly addressesRepository: IAddressesRepository) {}

  getUrl(zipCode: Params): string {
    return `/addresses/zip-codes/${zipCode}`
  }

  async execute(zipCode: Params): Promise<LoadByZipCodeUseCase.Response> {
    const response = await this.addressesRepository.loadByZipCode(zipCode)

    return response
  }
}
