import { css, styled } from '@azos/shared'

export const Root = styled('aside')(
  ({ theme }) => css`
    background: ${theme.palette.neutral[50]};
    padding: ${theme.spacing[4]}px ${theme.spacing[3]}px;
    border-radius: ${theme.spacing[2]}px;
    overflow: auto;

    .sidebar-dashboard {
      &__button-group {
        margin-top: ${theme.spacing[6]}px;

        & > * + * {
          margin-top: 12px;
        }
      }

      &__button {
        span > [class^='icon-'] {
          display: none;
        }
      }
    }

    .spinner {
      &__text {
        font-size: 1rem !important;
        i {
          font-size: 1.25rem;
        }
      }
    }

    .icon-azos-big {
      display: none;
      width: 100%;
      height: 32px;
    }

    .icon-azos-mobile {
      transition: display 0.5s;
      display: block;
      display: grid;
      place-items: center;
    }

    .icon-azos-background {
      background: ${theme.palette.neutral[100]};
      min-height: 48px;
      min-width: 48px;
      border-radius: 50%;
      display: grid;
      place-items: center;
    }

    @media (min-width: ${theme.breakpoints.lg}px) {
      .icon-azos-big {
        display: grid;
        place-items: center;

        svg {
          width: 101px;
        }
      }
      .icon-azos-mobile {
        display: none;
      }
    }

    div {
      &:first-of-type {
        h4 {
          margin-top: ${theme.spacing[2]}px;
        }
      }

      &:last-of-type {
        ul {
          border-bottom: 0;
          padding-bottom: 0;
        }
      }

      h4 {
        margin: ${theme.spacing[4]}px 0 0;
        color: ${theme.palette.neutral[500]};
        font-size: ${theme.typography.h5.fontSize};
        font-weight: ${theme.typography.h5.fontWeight};
        text-transform: uppercase;
      }

      ul {
        list-style: none;
        margin: 0;
        padding: ${theme.spacing[5]}px 0;
        border-bottom: 1px solid ${theme.palette.neutral[100]};

        li:not(.customItem) + li.customItem,
        li.customItem + li:not(.customItem) {
          margin-top: 20px;
        }

        li {
          margin: 0;
          padding: 0;

          button {
            justify-content: center;
            cursor: pointer;
            background: transparent;
            border: 0;
            padding: 10px 14px;
            width: 100%;
            color: ${theme.palette.neutral[400]};
            font-size: 0.75;
            text-align: left;
            transition: background-color 0.3s;
            font-weight: 600;

            display: flex;
            align-items: center;

            @media (min-width: 1024px) {
              justify-content: left;
            }

            &:hover,
            &.active {
              background: ${theme.palette.neutral[100]};
            }

            &.active {
              background: ${theme.palette.neutral[100]};
              color: ${theme.palette.neutral[500]};
              font-weight: 600;

              span {
                color: ${theme.palette.primary.main};
              }
            }

            span {
              color: ${theme.palette.neutral[400]};
              width: 24px;
              height: 24px;
              font-size: 24px;
              transition: font-size 0.5s;

              @media (min-width: 1024px) {
                font-size: 24px;
              }
            }

            &.custom {
              border: solid 1px ${theme.palette.neutral[50]};
              min-height: 68px;
            }

            p {
              margin: 0;
              margin-left: 14px;
              display: none;
              transition: display 0.5s;
              font-size: 13px;
              font-weight: ${theme.typography.fontWeightSemiBold};

              @media (min-width: 1024px) {
                display: block;
              }
            }
          }
          &:last-child {
            button {
              margin-top: ${theme.spacing[4]}px;
              color: #ff455b;

              span {
                color: #ff455b;
              }
            }
          }
        }
      }
    }

    @media (max-width: ${theme.breakpoints.lg}px) {
      &:not(:hover) {
        .sidebar-dashboard {
          &__button {
            span {
              font-size: 0;
            }

            span > [class^='icon-'] {
              font-size: 1.5rem;
              display: block;
            }
          }
        }
      }

      &:hover {
        box-shadow: 0 0 0 1024px rgba(0, 0, 0, 0.3) !important;

        .sidebar-dashboard {
          > [class^='icon-'] {
            display: none;
          }
        }
      }
    }

    &:hover {
      @media (pointer: fine) {
        .icon-azos-big {
          display: grid;
          place-items: center;

          svg {
            width: 101px;
          }
        }

        .icon-azos-mobile {
          display: none;
        }

        div {
          ul {
            li {
              button {
                justify-content: left;

                span {
                  font-size: 24px;
                }
                p {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  `,
)
