import React from 'react'

import { SvgIconProps } from '../components/SvgIcon'

const Icon = ({ ...rest }: SvgIconProps) => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M8.5 23.9999L3.5 32.3333H24.3333L29.3333 23.9999H8.5Z"
      fill="#C2EBC2"
    />
    <path
      d="M10.1667 32.3333H1V5.66659H12.6667M24.3333 18.9999V2.33325M24.3333 2.33325L16 9.71341M24.3333 2.33325L32.6667 9.71342M3.5 32.3333L8.5 23.9999H29.3333L24.3333 32.3333H3.5Z"
      stroke="#005700"
      strokeWidth="2"
    />
  </svg>
)

export default Icon
