import Box, { BoxProps } from '@mui/material/Box'

import { css, styled } from '../../../styles'

type RootProps = BoxProps & {
  active?: boolean
}

export const Root = styled(Box)<RootProps>(
  ({ theme }) => css`
    cursor: pointer;
    background: ${theme.palette.common.white};
    border: 1px solid transparent;
    box-shadow: none;
    border-radius: ${theme.spacing(1)};
    padding: ${theme.spacing(3)};
    transition: border-color 0.3s;

    &:hover {
      border: 1px solid ${theme.palette.common.black};
    }

    .header {
      display: flex;
      flex-direction: column;

      &__heading {
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &__toggle-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        min-width: min-content;
        white-space: nowrap;
      }
    }

    .content {
      padding-top: ${theme.spacing(2)};
      font-size: 14px;
    }
  `,
)
