import { Dispatcher, Service } from '../../../../protocols'

export type IUpsellGobackSimulationPageProfessionSalary = Service<void>

/**
 * Go back simulation page profession salary
 *
 * @category Upsell
 */
export class UpsellGobackSimulationPageProfessionSalary
  implements IUpsellGobackSimulationPageProfessionSalary
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   */
  execute(): void {
    this.dispatcher.execute(
      'Upsell - Clique "voltar" em dados iniciais, profissão e renda',
    )
  }
}
