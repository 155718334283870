import { HttpClient, UnexpectedError } from '@azos/core'
import { LoadFinalQuotationsUseCase } from '@domain/usecases'
import HttpStatusCode from 'http-status-codes'

export type IFinalQuotationsRepository = LoadFinalQuotationsUseCase

export class FinalQuotationsRepository implements IFinalQuotationsRepository {
  constructor(private readonly httpClient: HttpClient) {}

  async loadFinalQuotations(
    proposalId: LoadFinalQuotationsUseCase.Params,
  ): Promise<LoadFinalQuotationsUseCase.Response> {
    const httpResponse = await this.httpClient.request({
      url: `/proposals/${proposalId}/final-quotations`,
      method: 'get',
      body: null,
    })

    const response = httpResponse.data

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
        return response as LoadFinalQuotationsUseCase.Response
      default:
        throw new UnexpectedError()
    }
  }
}
