import React from 'react'

import { useAuth } from '@azos/account'
import analytics from '@azos/analytics'
import { PaymentMethod, Utils } from '@azos/core'
import { EmptyState, Spinner } from '@azos/shared'
import {
  Button,
  Input,
  InputCardDate,
  InputCreditCard,
  InputCVV,
} from '@azos/shared/src/components/v2'
import { BillingServiceSource, ContractType } from '@domain/models'
import creditCardType from 'credit-card-type'
import { Form, Formik } from 'formik'

import { initialValues } from './CreditCardAdd.data'
import { CreditCard, CreditCardAddProps } from './CreditCardAdd.props'
import { Root } from './CreditCardAdd.styles'
import { validationSchema } from './CreditCardAdd.validations'

const ModalCreditCard: React.VFC<CreditCardAddProps> = ({
  id,
  type,
  onPaymentChangeError,
  onPaymentChangeSuccess,
  changePaymentMethodService,
  changePaymentMethodV2Service,
  loadPolicyDetailsService,
  loadProposalDetailsService,
  billingServiceSource,
}) => {
  const [loading, setLoading] = React.useState(false)
  const [isLoadingDetails, setIsLoadingDetails] = React.useState(false)
  const [error, setError] = React.useState(false)

  const [currentBillingServiceSource, setCurrentBillingServiceSource] =
    React.useState<BillingServiceSource | null>(null)

  const { user } = useAuth()

  const [creditCardBrand, setCreditCardBrand] = React.useState<string | null>(
    null,
  )

  React.useEffect(() => {
    if (billingServiceSource)
      setCurrentBillingServiceSource(billingServiceSource)
    else {
      if (type !== ContractType.policy && type !== ContractType.proposal) {
        setIsLoadingDetails(false)
        setError(true)
      }

      if (type === ContractType.policy) {
        setIsLoadingDetails(true)

        loadPolicyDetailsService
          .execute({
            policyId: id,
          })
          .then(response =>
            setCurrentBillingServiceSource(
              response.policy.billingServiceSource,
            ),
          )
          .catch(() => {
            setError(true)
          })
          .finally(() => setIsLoadingDetails(false))
      }

      if (type === ContractType.proposal) {
        setIsLoadingDetails(true)

        loadProposalDetailsService
          .execute({
            proposalId: id,
          })
          .then(response =>
            setCurrentBillingServiceSource(
              response.proposal.billingServiceSource,
            ),
          )
          .catch(() => {
            setError(true)
          })
          .finally(() => setIsLoadingDetails(false))
      }
    }
  }, [
    billingServiceSource,
    id,
    loadPolicyDetailsService,
    loadProposalDetailsService,
    type,
  ])

  React.useEffect(() => {
    analytics.dashboard.payments.view.viewAddCreditCardModal.execute()
    return () => {
      changePaymentMethodService.dispose?.()
      changePaymentMethodV2Service.dispose?.()
    }
  }, [changePaymentMethodService, changePaymentMethodV2Service])

  const handleSubmit = async (values: CreditCard) => {
    setLoading(true)

    try {
      if (currentBillingServiceSource === BillingServiceSource.VINDI) {
        await changePaymentMethodV2Service.execute({
          paymentMethod: PaymentMethod.CREDIT_CARD,
          id,
          email: user?.email,
          data: {
            number: values.cardnumber.replaceAll(/\s/g, ''),
            name: values.ccname,
            monthYear: values['cc-exp'],
            verificationValue: values.cvc,
          },
        })
      } else {
        await changePaymentMethodService.execute({
          type: type,
          paymentMethod: PaymentMethod.CREDIT_CARD,
          id,
          email: user?.email,
          data: {
            number: values.cardnumber.replaceAll(/\s/g, ''),
            name: values.ccname,
            monthYear: values['cc-exp'],
            verificationValue: values.cvc,
          },
        })
      }

      onPaymentChangeSuccess()
      analytics.dashboard.payments.save.saveNewCreditCard.execute()
    } catch (error) {
      onPaymentChangeError()
    }

    setLoading(false)
  }

  return (
    <Root>
      {error && (
        <div className="empty-state-wrapper">
          <EmptyState
            align="center"
            icon="icon-policy-cancel"
            iconColor="error"
            title="Erro ao carregar o recurso"
          >
            Por favor, tente novamente ou entre em contato com nosso suporte.
          </EmptyState>
        </div>
      )}

      {isLoadingDetails && !error && !loading && (
        <div className="empty-state-wrapper">
          <EmptyState align="center" icon={<Spinner />} title="Carregando">
            Estamos carregado os dados da sua{' '}
            {type === 'proposal' ? 'proposta' : 'apólice'}.
          </EmptyState>
        </div>
      )}

      {loading && !error && !isLoadingDetails && (
        <div className="empty-state-wrapper">
          <EmptyState align="center" icon={<Spinner />} title="Salvando">
            Estamos salvando o novo cartão de crédito da sua{' '}
            {type === 'proposal' ? 'proposta' : 'apólice'}.
          </EmptyState>
        </div>
      )}

      {!error && (
        <>
          <h2>Adicionar novo cartão</h2>

          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              setFieldValue,
            }) => (
              <Form>
                <div>
                  <div>
                    <InputCreditCard
                      fullWidth
                      autoFocus
                      id="cc-num"
                      autoComplete="cc-number"
                      x-autocompletetype="cc-number"
                      name="cardnumber"
                      placeholder="Ex: 1234 1234 1234 1234"
                      mask={Utils.payment.getMaskByBrand(creditCardBrand)}
                      error={!!errors.cardnumber && touched.cardnumber}
                      helperText={touched.cardnumber && errors.cardnumber}
                      value={values.cardnumber}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleChange(e)
                        const value = e.target.value
                        const brand = creditCardType(value)?.[0]?.type
                        setCreditCardBrand(value ? brand : null)
                      }}
                      onBlur={handleBlur}
                      label={undefined}
                      disabled={loading}
                    />
                  </div>

                  <div>
                    <Input
                      fullWidth
                      id="ccname"
                      name="ccname"
                      placeholder="Nome como consta no cartão"
                      autoComplete="cc-name"
                      error={!!errors.ccname && touched.ccname}
                      helperText={touched.ccname && errors.ccname}
                      value={values.ccname}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={loading}
                    />
                  </div>

                  <div>
                    <InputCardDate
                      fullWidth
                      id="cc-exp"
                      name="cc-exp"
                      autoComplete="cc-exp"
                      placeholder="mm/aa"
                      error={!!errors['cc-exp'] && touched['cc-exp']}
                      helperText={touched['cc-exp'] && errors['cc-exp']}
                      value={values['cc-exp']}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label={undefined}
                      disabled={loading}
                    />
                  </div>

                  <div>
                    <InputCVV
                      fullWidth
                      id="cvc"
                      name="cvc"
                      autoComplete="cc-csc"
                      error={!!errors.cvc && touched.cvc}
                      helperText={touched.cvc && errors.cvc}
                      value={values.cvc}
                      onChange={event =>
                        setFieldValue(
                          'cvc',
                          event.target.value.replace(/\D/g, ''),
                        )
                      }
                      onBlur={handleBlur}
                      label={undefined}
                      disabled={loading}
                    />
                  </div>
                </div>

                <Button
                  fullWidth
                  type="submit"
                  disabled={Object.values(errors).length > 0 || loading}
                >
                  Salvar cartão
                </Button>
              </Form>
            )}
          </Formik>
        </>
      )}
    </Root>
  )
}

export default ModalCreditCard
