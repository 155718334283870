export enum ViewPersonalDataStatus {
  DEFAULT = 'Default',
  WAITING_APPROVAL = 'Aguardando aprovação',
  REFUSED = 'Edição recusada',
}

/**
 * View Personal Data
 *
 * @alias ViewPersonalData
 */

export type ViewPersonalData = {
  /**
   * Status
   */
  status: ViewPersonalDataStatus
}
