import { AddCoverageProduct } from '../../../../../domain/models'
import { Dispatcher, Service } from '../../../../protocols'

export type IUpsellAddCoverageProductService = Service<AddCoverageProduct>

/**
 * Add Coverage Product
 *
 * @category Upsell
 */
export class UpsellAddCoverageProductService
  implements IUpsellAddCoverageProductService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   * @param {AddCoverageProduct} data
   */
  execute(data: AddCoverageProduct): void {
    this.dispatcher.execute('Upsell - Adiciona o produto em cobertura', data)
  }
}
