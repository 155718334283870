import React from 'react'

import { Form } from '../../../../molecules'
import { WrapperProps } from './Wrapper.props'
import { Root } from './Wrapper.styles'

const Wrapper: React.FCC<WrapperProps> = ({ children, config }) => {
  return (
    <Root>
      <Form
        initialValues={config.initialValues}
        initialSchema={config.initialSchema}
        onSubmit={config.onSubmit}
      >
        {children}
      </Form>
    </Root>
  )
}

export default Wrapper
