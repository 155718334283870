import React from 'react'

import { ButtonProps } from '@Azos-Seguros/apollo'
import { Radio } from '@Azos-Seguros/apollo'
import { BaseComponent, Utils } from '@azos/shared'

import { Button } from './RadioButtonOption.styles'

type Data = string | number | boolean

type RadioButtonOptionProps = {
  name: string
  label: React.ReactNode
  showInput?: boolean
  //
  value?: Data
  onChange: (value?: Data) => void
  //
  checked?: boolean
  disabled?: boolean
  buttonProps?: ButtonProps
} & BaseComponent

export const RadioButtonOption = ({
  name,
  label,
  showInput,
  //
  value,
  onChange,
  //
  checked = false,
  disabled,
  parentName,
  buttonProps,
}: RadioButtonOptionProps) => {
  const handleChange = React.useCallback(() => {
    onChange(value)
  }, [onChange, value])

  return (
    <label htmlFor={name}>
      <Button
        {...buttonProps}
        type="button"
        className={checked ? 'checked' : ''}
        onClick={handleChange}
        disabled={disabled}
        data-action={Utils.dataNames.renderDataName(
          value?.toString(),
          parentName,
        )}
        $showInput={showInput}
      >
        <div className="text-content">{label}</div>
        <Radio
          id={name}
          name={name}
          value={value?.toString()}
          onChange={() => {}}
          checked={checked}
          disabled={disabled}
        />
      </Button>
    </label>
  )
}

export default RadioButtonOption
