import { Utils } from '@azos/shared'
import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
  fullName: Yup.string()
    .test({
      name: 'Name',
      test: value => {
        const splitValue = value?.trim().split(' ')
        return (splitValue && splitValue?.length >= 2) || false
      },
      message: 'O nome e sobrenome são obrigatórios',
    })
    .required('O nome é obrigatório'),
  email: Yup.string()
    .email('E-mail inválido')
    .required('Seu e-mail é obrigatório'),
  phone: Yup.string()
    .test({
      name: 'Phone',
      message: 'O telefone não é válido',
      test: value => Utils.validator.validatePhone(value as string),
    })
    .required('O telefone é obrigatório'),
})
