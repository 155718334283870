import { css, Utils } from '@azos/shared'
import styled from '@emotion/styled'

interface RootProps {
  $childrenType: string
}

export const Root = styled(
  'div',
  Utils.transient.transientStyleProps,
)<RootProps>(
  ({ theme, $childrenType }) => css`
    display: flex;
    align-items: ${$childrenType === 'string' ? 'center' : 'flex-start'};
    padding: ${$childrenType === 'string' ? '18px' : '24px'};
    background: ${theme.palette.neutral[500]};
    color: ${theme.palette.neutral[50]};

    [class^='icon-'] {
      margin-right: ${$childrenType === 'string' ? '14px' : '20px'};
      font-size: 25px;
    }

    @media (min-width: 1024px) {
      border-radius: ${theme.spacing[2]}px;
    }
  `,
)

export default Root
