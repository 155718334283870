import { Box, BoxProps } from '@Azos-Seguros/apollo'
import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled(Box)<BoxProps>(
  ({ theme }) => css`
  position: relative;

  & .wrapper--header {
    background-color: ${theme.palette.neutral[100]};
    padding: ${theme.spacing[4]}px ${theme.spacing[4]}px ${theme.spacing[2]}px ${theme.spacing[4]}px;

    @media (min-width: ${theme.breakpoints.md}px) {
      display: none;
    }

    position: sticky;
    top: 0;
    width: 100%;
    z-index: 10;
  },
`,
)
