import React from 'react'

import { Text, Utils } from '@azos/shared'

import { CardPriceProps } from './CardPrice.props'
import { Root } from './CardPrice.styles'

const CardPrice = ({ newPrice, oldPrice }: CardPriceProps) => {
  return (
    <Root>
      <div className="container__content-group">
        <Text variant="body2">Passou de</Text>
        <Text variant="body2" component="del">
          {Utils.currency.format(oldPrice)}
        </Text>
        <Text variant="body2">para:</Text>
      </div>

      <div className="container__content-group">
        <Text variant="body2">R$</Text>
        <Text variant="h1">{Utils.currency.format(newPrice, '')}</Text>
        <Text variant="body2">/ Mês</Text>
      </div>
    </Root>
  )
}

export default CardPrice
