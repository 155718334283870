import { css, styled } from '../../styles'

export const Root = styled('aside')(
  ({ theme }) => css`
    background: ${theme.palette.common.white};
    padding: ${theme.spacing(3)};
    border-radius: ${theme.spacing(2)};
    max-height: max-content;

    div {
      &:first-of-type {
        h4 {
          margin-top: ${theme.spacing(2)};
        }
      }

      &:last-of-type {
        ul {
          border-bottom: 0;
          padding-bottom: ${theme.spacing(0)};
        }
      }

      h4 {
        margin: ${theme.spacing(4)} ${theme.spacing(0)} ${theme.spacing(0)};
        color: ${theme.palette.grey[600]};
        font-size: ${theme.typography.h6.fontSize};
        font-weight: ${theme.typography.fontWeightSemiBold};
        text-transform: uppercase;
      }

      ul {
        list-style: none;
        margin: ${theme.spacing(0)};
        padding: ${theme.spacing(3)} ${theme.spacing(0)};
        border-bottom: 1px solid ${theme.palette.grey[100]};

        li {
          margin: ${theme.spacing(0)};
          padding: ${theme.spacing(0)};

          button {
            cursor: pointer;
            background: transparent;
            border: 0;
            border-radius: ${theme.spacing(2)};
            padding: 10px 20px;
            margin: 3px 0;
            width: 100%;
            color: ${theme.palette.grey[600]};
            font-size: ${theme.typography.h6.fontSize};
            text-align: left;
            transition: background-color 0.3s;

            display: flex;
            align-items: center;
            justify-content: left;

            &:hover,
            &.active {
              background: ${theme.palette.background.default};
            }

            &.active {
              font-weight: 600;
            }

            span {
              display: inline-block;
              width: 24px;
              height: 24px;
              font-size: 24px;
              margin-right: 18px;
            }
          }
        }
      }
    }
  `,
)
