import { MenuDashboardItem } from '@azos/shared'
import {
  BottomNavigationItemsProps,
  SidebarDashboardMenuProps,
  SidebarDashboardMenuType,
} from '@presentation/components/organisms'

//DESKTOP

export enum MENU {
  INICIO = '/inicio',
  MINHAS_APOLICES = '/minhas-apolices',
  BENEFICIOS = '/beneficios',
  MEUS_GUARDIOES = 'meus-guardioes',
  MINHAS_INDICACOES = 'minhas-indicacoes',
  SIMULAR_SEGURO = 'simular-seguro',
  SOLICITAR_CORRETOR = 'solicitar-corretor',
  MENU_GERAL = 'menu',
  BAIXE_APP_AZOS = 'baixe-app-azos',
  AJUDA = 'ajuda',
  SAIR = 'sair',
  //
  ASSISTENCIA_FUNERAL = 'assistencia-funeral',
  CENTRAL_DE_ATENDIMENTO = 'central-de-atendimento',
  CENTRAL_DE_DUVIDAS = 'central-de-duvidas',
  CONDICOES_GERAIS = 'condicoes-gerais',
  PORTAL_DA_TRANSPARENCIA = 'portal-da-transparencia',
  WHATSAPP = 'whatsapp',
  EMAIL = 'email',
  SOBRE_AZOS = 'sobre-a-azos',
  TUDO_SOBRE_SEGURO = '/onboarding',
  FALE_COM_SEU_CORRETOR = 'fale-com-seu-corretor(a)',
  SUA_CONTA = 'sua-conta',
  MEMORE = 'https://www.memoreazos.com.br/',
}

export enum DESKTOP_MENU_LABEL {
  INICIO = 'Início',
  MINHAS_APOLICES = 'Apólices',
  BENEFICIOS = 'Benefícios',
  MEUS_GUARDIOES = 'Guardiões',
  INDICAR_AMIGOS = 'Indicar',
  TUDO_SOBRE_SEGURO = 'Tudo sobre o seu seguro',
  CENTRAL_DE_ATENDIMENTO = 'Central de atendimento',
  CENTRAL_DE_DUVIDAS = 'Central de dúvidas',
  PORTAL_DA_TRANSPARENCIA = 'Portal da transparência',
  FALE_COM_SEU_CORRETOR = 'Fale com o seu corretor(a)',
  SUA_CONTA = 'Sua conta',
  MEMORE = 'Memore',
}

export const menuB2B: SidebarDashboardMenuProps[] = [
  {
    label: DESKTOP_MENU_LABEL.INICIO,
    icon: 'icon-home',
    value: MENU.INICIO,
  },
  {
    label: DESKTOP_MENU_LABEL.MINHAS_APOLICES,
    icon: 'icon-heart',
    value: MENU.MINHAS_APOLICES,
  },
  {
    label: DESKTOP_MENU_LABEL.BENEFICIOS,
    icon: 'icon-star',
    value: MENU.BENEFICIOS,
  },
  {
    label: DESKTOP_MENU_LABEL.MEMORE,
    icon: 'icon-policy-love',
    value: MENU.MEMORE,
  },
  {
    label: DESKTOP_MENU_LABEL.MEUS_GUARDIOES,
    icon: 'icon-guardian',
    value: MENU.MEUS_GUARDIOES,
  },
  {
    label: DESKTOP_MENU_LABEL.TUDO_SOBRE_SEGURO,
    icon: 'icon-policy-shield',
    value: MENU.TUDO_SOBRE_SEGURO,
    progress: 33,
  },
  {
    label: DESKTOP_MENU_LABEL.CENTRAL_DE_ATENDIMENTO,
    icon: 'icon-headphone',
    value: MENU.CENTRAL_DE_ATENDIMENTO,
  },
  {
    label: DESKTOP_MENU_LABEL.CENTRAL_DE_DUVIDAS,
    icon: 'icon-help',
    value: MENU.CENTRAL_DE_DUVIDAS,
  },
  {
    label: 'Sair',
    icon: 'icon-sign-in',
    value: MENU.SAIR,
  },
]

export const upperMenuB2B: SidebarDashboardMenuProps[] = [
  {
    label: DESKTOP_MENU_LABEL.INICIO,
    icon: 'icon-home',
    value: MENU.INICIO,
  },
  {
    label: DESKTOP_MENU_LABEL.MINHAS_APOLICES,
    icon: 'icon-heart',
    value: MENU.MINHAS_APOLICES,
  },
  {
    label: DESKTOP_MENU_LABEL.BENEFICIOS,
    icon: 'icon-star',
    value: MENU.BENEFICIOS,
  },
  {
    label: DESKTOP_MENU_LABEL.MEMORE,
    icon: 'icon-policy-love',
    value: MENU.MEMORE,
  },
  {
    label: DESKTOP_MENU_LABEL.MEUS_GUARDIOES,
    icon: 'icon-guardian',
    value: MENU.MEUS_GUARDIOES,
  },
  {
    label: DESKTOP_MENU_LABEL.INDICAR_AMIGOS,
    icon: 'icon-share',
    value: MENU.MINHAS_INDICACOES,
  },
]

export const lowerMenuB2B: SidebarDashboardMenuProps[] = [
  {
    label: DESKTOP_MENU_LABEL.CENTRAL_DE_ATENDIMENTO,
    icon: 'icon-headphone',
    value: MENU.CENTRAL_DE_ATENDIMENTO,
  },
  {
    label: DESKTOP_MENU_LABEL.CENTRAL_DE_DUVIDAS,
    icon: 'icon-help',
    value: MENU.CENTRAL_DE_DUVIDAS,
  },
  {
    icon: 'icon-shield',
    label: DESKTOP_MENU_LABEL.PORTAL_DA_TRANSPARENCIA,
    value: MENU.PORTAL_DA_TRANSPARENCIA,
  },
  {
    label: 'Sair',
    icon: 'icon-sign-in',
    value: MENU.SAIR,
  },
]

export const wizardMenuItem: SidebarDashboardMenuProps = {
  label: DESKTOP_MENU_LABEL.TUDO_SOBRE_SEGURO,
  icon: 'icon-policy-shield',
  value: MENU.TUDO_SOBRE_SEGURO,
  type: SidebarDashboardMenuType.ONBOARDING,
  progress: 0,
}

export const responsibleBrokerMenuItem: SidebarDashboardMenuProps = {
  label: DESKTOP_MENU_LABEL.FALE_COM_SEU_CORRETOR,
  icon: 'icon-briefcase',
  value: MENU.FALE_COM_SEU_CORRETOR,
  type: SidebarDashboardMenuType.RESPONSIBLE_BROKER,
}

export const userAccountMenuItem: SidebarDashboardMenuProps = {
  label: DESKTOP_MENU_LABEL.SUA_CONTA,
  icon: 'icon-user',
  value: MENU.SUA_CONTA,
  type: SidebarDashboardMenuType.USER_ACCOUNT,
}

export const upperMenuB2C = [
  {
    label: DESKTOP_MENU_LABEL.INICIO,
    icon: 'icon-home',
    value: MENU.INICIO,
  },
  {
    label: DESKTOP_MENU_LABEL.MINHAS_APOLICES,
    icon: 'icon-policy',
    value: MENU.MINHAS_APOLICES,
  },
  {
    label: DESKTOP_MENU_LABEL.BENEFICIOS,
    icon: 'icon-star',
    value: MENU.BENEFICIOS,
  },
  {
    label: DESKTOP_MENU_LABEL.MEMORE,
    icon: 'icon-policy-love',
    value: MENU.MEMORE,
  },
  {
    label: DESKTOP_MENU_LABEL.MEUS_GUARDIOES,
    icon: 'icon-guardian',
    value: MENU.MEUS_GUARDIOES,
  },
  {
    label: DESKTOP_MENU_LABEL.INDICAR_AMIGOS,
    icon: 'icon-share',
    value: MENU.MINHAS_INDICACOES,
  },
]

export const lowerMenuB2C = [
  {
    label: DESKTOP_MENU_LABEL.CENTRAL_DE_ATENDIMENTO,
    icon: 'icon-headphone',
    value: MENU.CENTRAL_DE_ATENDIMENTO,
  },
  {
    label: DESKTOP_MENU_LABEL.CENTRAL_DE_DUVIDAS,
    icon: 'icon-help',
    value: MENU.CENTRAL_DE_DUVIDAS,
  },
  {
    icon: 'icon-shield',
    label: DESKTOP_MENU_LABEL.PORTAL_DA_TRANSPARENCIA,
    value: MENU.PORTAL_DA_TRANSPARENCIA,
  },
  {
    label: 'Sair',
    icon: 'icon-sign-in',
    value: MENU.SAIR,
  },
]

//MOBILE
export enum MOBILE_MENU_LABEL {
  INICIO = 'Início',
  MINHAS_APOLICES = 'Apólices',
  BENEFICIOS = 'Benefícios',
  MEUS_GUARDIOES = 'Guardiões',
  MENU_GERAL = 'Menu',
}

export enum MOBILE_GENERAL_MENU_LABEL_B2C {
  MEUS_GUARDIOES = 'Nomear guardião',
  BENEFICIOS = 'Benefícios',
  MINHAS_INDICACOES = 'Indicar amigos',
  ASSISTENCIA_FUNERAL = 'Assistência Funeral',
  CENTRAL_DE_ATENDIMENTO = 'Central de Atendimento',
  WHATSAPP = 'Whatsapp',
  EMAIL = 'E-mail',
  SOBRE_AZOS = 'Sobre a Azos',
  TUDO_SOBRE_SEGURO = 'Tudo sobre seguro',
  CENTRAL_DE_DUVIDAS = 'Central de dúvidas',
  CONDICOES_GERAIS = 'Condições gerais',
  PORTAL_DA_TRANSPARENCIA = 'Portal da transparência',
  SAIR = 'Sair',
}

export enum MOBILE_GENERAL_MENU_LABEL_B2B {
  MEUS_GUARDIOES = 'Nomear guardião',
  BENEFICIOS = 'Benefícios',
  MEMORE = 'Memore',
  ASSISTENCIA_FUNERAL = 'Assistência Funeral',
  CENTRAL_DE_ATENDIMENTO = 'Central de Atendimento',
  WHATSAPP = 'Whatsapp',
  EMAIL = 'E-mail',
  SOBRE_AZOS = 'Sobre a Azos',
  TUDO_SOBRE_SEGURO = 'Tudo sobre seguro',
  CONDICOES_GERAIS = 'Condições gerais',
  PORTAL_DA_TRANSPARENCIA = 'Portal da transparência',
  SAIR = 'Sair',
}

export const mobileBottomNavigation: BottomNavigationItemsProps[] = [
  {
    label: MOBILE_MENU_LABEL.INICIO,
    icon: 'icon-home',
    value: MENU.INICIO,
  },
  {
    label: MOBILE_MENU_LABEL.MINHAS_APOLICES,
    icon: 'icon-heart',
    value: MENU.MINHAS_APOLICES,
  },
  {
    label: MOBILE_MENU_LABEL.BENEFICIOS,
    icon: 'icon-star',
    value: MENU.BENEFICIOS,
  },
  {
    label: MOBILE_MENU_LABEL.MEUS_GUARDIOES,
    icon: 'icon-guardian',
    value: MENU.MEUS_GUARDIOES,
  },
  {
    label: DESKTOP_MENU_LABEL.INDICAR_AMIGOS,
    icon: 'icon-share',
    value: MENU.MINHAS_INDICACOES,
  },
  {
    label: MOBILE_MENU_LABEL.MENU_GERAL,
    icon: 'icon-menu',
    value: MENU.MENU_GERAL,
  },
]

export const mobileMenuB2C: MenuDashboardItem[] = [
  {
    icon: 'icon-headphone',
    label: MOBILE_GENERAL_MENU_LABEL_B2C.CENTRAL_DE_ATENDIMENTO,
    value: MENU.CENTRAL_DE_ATENDIMENTO,
    onClick: () => {},
  },
  {
    icon: 'icon-help',
    label: MOBILE_GENERAL_MENU_LABEL_B2C.CENTRAL_DE_DUVIDAS,
    value: MENU.CENTRAL_DE_DUVIDAS,
    onClick: () => {},
  },
  {
    icon: 'icon-contract',
    label: MOBILE_GENERAL_MENU_LABEL_B2C.CONDICOES_GERAIS,
    value: MENU.CONDICOES_GERAIS,
    onClick: () => {
      window.open('https://www.azos.com.br/condicoes-gerais', '_blank')
    },
  },
  {
    icon: 'icon-shield',
    label: MOBILE_GENERAL_MENU_LABEL_B2C.PORTAL_DA_TRANSPARENCIA,
    value: MENU.PORTAL_DA_TRANSPARENCIA,
    onClick: () => {},
  },
  {
    label: MOBILE_GENERAL_MENU_LABEL_B2C.SAIR,
    icon: 'icon-sign-in',
    value: MENU.SAIR,
  },
]

export const mobileMenuB2B: MenuDashboardItem[] = [
  {
    icon: 'icon-headphone',
    label: MOBILE_GENERAL_MENU_LABEL_B2C.CENTRAL_DE_ATENDIMENTO,
    value: MENU.CENTRAL_DE_ATENDIMENTO,
    onClick: () => {},
  },
  {
    icon: 'icon-help',
    label: MOBILE_GENERAL_MENU_LABEL_B2C.CENTRAL_DE_DUVIDAS,
    value: MENU.CENTRAL_DE_DUVIDAS,
    onClick: () => {},
  },
  {
    icon: 'icon-contract',
    label: MOBILE_GENERAL_MENU_LABEL_B2B.CONDICOES_GERAIS,
    value: MENU.CONDICOES_GERAIS,
    onClick: () => {
      window.open('https://www.azos.com.br/condicoes-gerais', '_blank')
    },
  },
  {
    icon: 'icon-shield',
    label: MOBILE_GENERAL_MENU_LABEL_B2B.PORTAL_DA_TRANSPARENCIA,
    value: MENU.PORTAL_DA_TRANSPARENCIA,
    onClick: () => {},
  },
  {
    label: MOBILE_GENERAL_MENU_LABEL_B2C.SAIR,
    icon: 'icon-sign-in',
    value: MENU.SAIR,
  },
]
