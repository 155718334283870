import React from 'react'

import InputAdornment from '@mui/material/InputAdornment'

import { InputNumber } from '../InputNumber'
import { InputWeightProps } from './InputWeight.props'

const InputWeight = ({ children, ...rest }: InputWeightProps) => {
  return (
    <InputNumber
      label="Peso"
      placeholder="em kg"
      {...rest}
      InputProps={{
        endAdornment: <InputAdornment position="end">kg</InputAdornment>,
      }}
    />
  )
}

export default InputWeight
