import { ViewGuardianHomepage } from '../../../../domain/models'
import { Dispatcher, Service } from '../../../protocols'

export type IViewGuardianHomepageService = Service<ViewGuardianHomepage>

/**
 * View guardian home page
 *
 * @category Dashboard
 */
export class ViewGuardianHomepageService
  implements IViewGuardianHomepageService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @param {ViewGuardianHomepage} data
   * @function
   */
  execute(data: ViewGuardianHomepage): void {
    this.dispatcher.execute(
      'Area logada web - Visualização da tela de guardião',
      data,
    )
  }
}
