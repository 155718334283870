import { ContinueLifeStyleQuestion } from '../../../../../domain/models'
import { Dispatcher, Service } from '../../../../protocols'

export type IUpsellContinueDiseasesQuestionService =
  Service<ContinueLifeStyleQuestion>

/**
 * Continue Diseases Question
 *
 * @category Upsell
 */
export class UpsellContinueDiseasesQuestionService
  implements IUpsellContinueDiseasesQuestionService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   */
  execute(): void {
    this.dispatcher.execute(
      'Upsell - Clique "continuar" em DPS, opções de doenças',
    )
  }
}
