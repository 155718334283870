export enum ViewCoverageQuestionsType {
  INSURANCE_CONTRACT = 'o seguro de vida é para sempre?',
  COVERAGE_VALUE = 'o valor de uma cobertura aumenta?',
  INSURANCE_PAYMENT = 'como vou ter certeza que vou receber o valor?',
}

/**
 * View Coverage Questions
 *
 * @alias ViewCoverageQuestions
 */

export type ViewCoverageQuestions = {
  /**
   * Check if user is insured
   */
  isInsured: boolean
  /**
   * Coverage Question Type
   */
  tipo: ViewCoverageQuestionsType
}
