import { Dispatcher, Service } from '../../../../protocols'

export type IChangePaymentModalDetailsService = Service<void>

/**
 * Change Payment Modal Details
 *
 * @category Payment
 */
export class ChangePaymentModalDetailsService
  implements IChangePaymentModalDetailsService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}

  execute(): void {
    this.dispatcher.execute(
      'Area logada web - Clique para alterar método de pagamento no modal com detalhe de pagamento',
    )
  }
}
