import { SWRServices } from '@azos/core'
import { ICmsRepository } from '@data/repositories'
import { GetCoverageDetailsUseCase } from '@domain/usecases'

type Params = GetCoverageDetailsUseCase.Params
type Response = GetCoverageDetailsUseCase.Response

export type IGetCoverageDetailsService = SWRServices<Params, Response>

export class GetCoverageDetailsService implements IGetCoverageDetailsService {
  constructor(private readonly cmsRepository: ICmsRepository) {}

  getUrl({ coverageCode }: Params): string {
    return `/coverage-details?productCode=${coverageCode}`
  }

  async execute(
    params: GetCoverageDetailsUseCase.Params,
  ): Promise<GetCoverageDetailsUseCase.Response> {
    const response = await this.cmsRepository.getCoverageDetails(params)

    return response
  }
}
