import React from 'react'

import { Typography } from '@Azos-Seguros/apollo'
import { Badge } from '@azos/shared/src/components/v2'
import { PaymentHistoryInvoice } from '@data/models'
import { InvoiceStatus, PolicyDetailsStatus } from '@domain/models'

import { PaymentHistoryItemsProps } from './PaymentHistoryItems.props'
import { Root } from './PaymentHistoryItems.styles'

const PaymentHistoryItems: React.FCC<PaymentHistoryItemsProps> = ({
  children,
  invoices,
  showAll,
}) => {
  const paymentTitle = (payment: PaymentHistoryInvoice) => {
    const info = {
      bank_slip: 'Boleto',
      pix: 'Pix',
      credit_card: 'Cartão',
      credit_card_authorization: 'Cartão',
    }

    return info[payment.paymentMethod]
  }

  const hasAction = (payment: PaymentHistoryInvoice) =>
    payment.status.label !== InvoiceStatus.canceled &&
    payment.status.label !== InvoiceStatus.in_analysis

  return (
    <>
      <Root>
        {(showAll ? invoices : invoices.slice(0, 3)).map((payment, index) => (
          <li
            key={`${index}-${payment.invoiceId}`}
            className={hasAction(payment) ? 'has-action' : ''}
            onClick={
              hasAction(payment)
                ? () => window.open(payment.invoiceUrl, '_blank')
                : () => undefined
            }
          >
            <div>
              <div>
                <Badge
                  type={
                    (payment.status.type as PolicyDetailsStatus) || 'warning'
                  }
                  borderRadius={4}
                >
                  {payment.status.label}
                </Badge>

                <Typography className="value" variant="bodyBold">
                  {payment.value}
                </Typography>
              </div>

              <div className="policy-date__content">
                <Typography variant="bodyBold2">
                  {paymentTitle(payment)}{' '}
                </Typography>
                ・
                <Typography variant="body2" className="date">
                  {payment.dueDate}
                </Typography>
              </div>
            </div>

            {hasAction(payment) && (
              <button>
                <i className="icon-chevron-right" />
              </button>
            )}
          </li>
        ))}

        {children}
      </Root>
    </>
  )
}

export default PaymentHistoryItems
