import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled('div')(
  () => css`
    aspect-ratio: 16/9;

    iframe {
      width: 100%;
      border: none;
      height: 100%;
    }
  `,
)
