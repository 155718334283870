import React from 'react'

import { SwitchProps } from './Switch.props'
import { Root } from './Switch.styles'

const Switch: React.VFC<SwitchProps> = props => {
  return (
    <Root>
      <input {...props} type="checkbox" className="switch__input" />
      <span className="switch__slider" />
    </Root>
  )
}

export default Switch
