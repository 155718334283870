import React, { createContext, useCallback, useState } from 'react'

import { NotifyContextData, NotifyState } from './NotifyProvider.props'

const NotifyContext = createContext<NotifyContextData>({
  state: null,
  addNotify: () => {},
  removeNotify: () => {},
})

const NotifyProvider: React.FCC = ({ children }) => {
  const [data, setData] = useState<NotifyState | null>(null)

  const addNotify = useCallback((data: NotifyState) => {
    setData(data)
  }, [])

  const removeNotify = useCallback(() => {
    setData(null)
  }, [])

  const contextData: NotifyContextData = {
    state: data,
    addNotify,
    removeNotify,
  }

  return (
    <NotifyContext.Provider value={contextData}>
      {children}
    </NotifyContext.Provider>
  )
}

export { NotifyContext, NotifyProvider }
