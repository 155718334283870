export const editTextDistance = (firstValue: string, secondValue: string) => {
  const loweredFirstValue = firstValue.toLowerCase()
  const loweredSecondValue = secondValue.toLowerCase()

  const costs: number[] = []
  for (let i = 0; i <= loweredFirstValue.length; i++) {
    let lastValue = i
    for (let j = 0; j <= loweredSecondValue.length; j++) {
      if (i == 0) costs[j] = j
      else {
        if (j > 0) {
          let newValue: number = costs[j - 1]
          if (
            loweredFirstValue.charAt(i - 1) != loweredSecondValue.charAt(j - 1)
          )
            newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1
          costs[j - 1] = lastValue
          lastValue = newValue
        }
      }
    }
    if (i > 0) costs[loweredSecondValue.length] = lastValue
  }
  return costs[loweredSecondValue.length]
}

export const compareSimilarText = (firstValue: string, secondValue: string) => {
  let longerString = firstValue
  let shorterString = secondValue

  if (firstValue.length < secondValue.length) {
    longerString = secondValue
    shorterString = firstValue
  }

  if (longerString.length == 0) {
    return 1.0
  }

  const result =
    (longerString.length - editTextDistance(longerString, shorterString)) /
    longerString.length

  return Math.round((result + Number.EPSILON) * 100) / 100
}
