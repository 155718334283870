import React from 'react'

import { ButtonSelect, Utils } from '@azos/shared'
import clsx from 'clsx'

import { ItemProps } from './Item.props'
import { Label } from './Item.styles'

export const Item = ({
  name,
  label,
  //
  value,
  onChange,
  //
  checked = false,
  disabled,
  parentName,
}: ItemProps) => {
  const inputRef = React.useRef<any>()

  const handleChange = React.useCallback(() => {
    inputRef.current?.click()
    if (typeof value !== 'undefined') onChange(value)
  }, [onChange, value])

  return (
    <Label htmlFor={name}>
      <ButtonSelect
        className={clsx('form-select-list__button', { ['active']: checked })}
        onClick={handleChange}
        disabled={disabled}
        title={label}
        data-action={Utils.dataNames.renderDataName(
          value?.toString(),
          parentName,
        )}
      />
      <input
        ref={inputRef}
        className="form-select-list__input"
        type="radio"
        id={name}
        name={name}
        value={value?.toString()}
        onChange={() => {}}
        checked={checked}
        disabled={disabled}
      />
    </Label>
  )
}

export default Item
