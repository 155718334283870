import { Dispatcher, Service } from '../../../../protocols'

export type IUpsellContinueRevisionService = Service<void>

/**
 * Continue revision
 *
 * @category Upsell
 */
export class UpsellContinueRevisionService
  implements IUpsellContinueRevisionService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   */
  execute(): void {
    this.dispatcher.execute('Upsell - Clique "continuar" em DPS, resumo')
  }
}
