export const B2CHeaderContent = {
  title: 'Indicar amigos',
  description:
    'Convide quem você mais se importa para fazer parte Azos e ajude essas pessoas a se protegerem e garantir planos futuros!',
}

export const B2BHeaderContent = {
  title: 'Indicar seu corretor',
  description:
    'Convide quem você mais se importa para fazer parte da Azos e ajude essas pessoas a se protegerem e garantir planos futuros!',
}
