import React from 'react'

import analytics from '@azos/analytics'
import { Utils } from '@azos/shared'
import {
  Button,
  Input,
  InputNumber,
  InputPhone,
  InputZipcode,
  Select,
  SelectOption,
  Text,
} from '@azos/shared/src/components/v2'
import { MARITAL_STATUS_OPTIONS } from '@main/config/maritalStatus'
import clsx from 'clsx'
import { useFormikContext } from 'formik'
import {
  makeListCitiesService,
  makeLoadByZipCodeService,
} from 'main/factories/services'

import { UserEditPersonalDataValues } from '../../UserEditPersonalDataCard.props'
import { UserEditConfirmUpdateModal } from '../UserEditConfirmUpdateModal'
import { UserEditPersonalDataFormProps } from './UserEditPersonalDataForm.props'
import { Form } from './UserEditPersonalDataForm.styles'

const delay = (delay = 100) =>
  new Promise(resolve => setTimeout(resolve, delay))

const UserEditPersonalDataForm: React.FCC<UserEditPersonalDataFormProps> = ({
  isLoadingState,
  statesList,
}) => {
  const CEPRef = React.useRef<HTMLInputElement>(null)
  const inputNumberRef = React.useRef<HTMLInputElement>(null)

  const {
    errors,
    handleBlur,
    handleChange,
    submitForm,
    isSubmitting,
    setFieldValue,
    touched,
    initialValues,
    values,
    isValid,
    setTouched,
  } = useFormikContext<UserEditPersonalDataValues>()

  const [isShowingConfirmUpdateModal, setIsShowingConfirmUpdateModal] =
    React.useState(false)

  const [states, setStates] = React.useState<SelectOption[]>([])
  const [cities, setCities] = React.useState<SelectOption[]>([])
  const [lastCep, setLastCep] = React.useState<string | undefined>(
    initialValues?.cep,
  )
  const [isLoadingCalls, setIsLoadingCalls] = React.useState(false)
  const [disabledFields, setDisabledFields] = React.useState<string[]>([])

  const filterPropsWithMaxChars = ([_prop, value]: [string, string]) => {
    const hasLessThanMaxChars = value.length <= 50

    return hasLessThanMaxChars
  }

  const fetchAddressInfo = React.useCallback(
    async (cep: string) => {
      setIsLoadingCalls(true)

      const addressData = await makeLoadByZipCodeService()
        .execute(cep)
        .then(response => {
          setIsLoadingCalls(false)
          return response
        })
        .catch(() => null)

      if (!!addressData && addressData.state && addressData.uf) {
        setFieldValue('state', addressData.state, true)
        setFieldValue('uf', addressData.uf, true)
        setFieldValue(
          'neighborhood',
          addressData.neighborhood || values.neighborhood,
          true,
        )
        setFieldValue('city', addressData.city, true)
        setFieldValue('street', addressData.street || values.street, true)
        setCities([
          {
            label: addressData.city,
            value: addressData.city,
          },
        ])
        setStates([
          {
            label: addressData.state,
            value: addressData.uf,
          },
        ])

        const { filtered: disabledAddresses, unfiltered: activeAddresses } =
          Utils.array.getFilteredUnfiltered(
            Object.entries(addressData),
            filterPropsWithMaxChars,
          )

        setDisabledFields(
          disabledAddresses.reduce((acc: string[], [prop, value]: string[]) => {
            if (value) acc.push(prop)
            return acc
          }, []),
        )

        const touchedFields: { [key: string]: boolean } = {}

        activeAddresses.forEach(([prop, value]) => {
          touchedFields[prop] = true
        })

        setTouched(touchedFields)

        inputNumberRef.current?.focus()
      } else {
        setFieldValue('state', '', true)
        setFieldValue('uf', '', true)
        setFieldValue('neighborhood', '', true)
        setFieldValue('city', '', true)
        setFieldValue('street', '', true)
        setFieldValue('number', '', true)
        setFieldValue('complement', '', true)

        setDisabledFields([
          'city',
          'street',
          'number',
          'complement',
          'neighborhood',
        ])
        setStates(statesList)
      }
    },
    [setFieldValue, setTouched, statesList, values],
  )

  React.useEffect(() => {
    const zipCodeValue = Utils.sanitizer.onlyNumber(values.cep)
    const zipCodeHasChanged =
      Utils.sanitizer.onlyNumber(lastCep) !==
      Utils.sanitizer.onlyNumber(zipCodeValue)

    if (
      values.cep &&
      zipCodeValue.length === 8 &&
      zipCodeHasChanged &&
      !isLoadingState
    ) {
      fetchAddressInfo(values.cep)
      setLastCep(values.cep)
    }
  }, [values.cep, fetchAddressInfo, lastCep, isLoadingState])

  const handleStateChange = React.useCallback((state: string) => {
    setIsLoadingCalls(true)
    makeListCitiesService()
      .execute(state)
      .then(response =>
        setCities(
          response.map(cities => ({
            value: cities.name,
            label: cities.name,
          })),
        ),
      )
      .then(() => {
        setIsLoadingCalls(false)
        setDisabledFields([])
      })
      .catch(() => {})
  }, [])

  React.useEffect(() => {
    ;(async () => {
      await delay()
      CEPRef.current?.focus()
    })()
  }, [])

  React.useEffect(() => {
    if (!!initialValues.state && !!initialValues.uf && !!initialValues.city) {
      setDisabledFields(['state', 'city'])

      setStates([
        {
          label: initialValues.state,
          value: initialValues.uf,
        },
      ])
      setCities([
        {
          label: initialValues.city,
          value: initialValues.city,
        },
      ])
    }
  }, [initialValues])

  React.useEffect(() => {
    if (values.uf) {
      setFieldValue(
        'state',
        states.find(state => state.value === values.uf)?.label ?? values.uf,
        true,
      )
    }
  }, [values.uf, states, setFieldValue])

  const isLoading = React.useMemo<boolean>(
    () => isLoadingCalls || isLoadingState,
    [isLoadingCalls, isLoadingState],
  )

  const isDisabled = React.useMemo<boolean>(
    () => isSubmitting || isLoading,
    [isSubmitting, isLoading],
  )

  const onSubmitButtonClick = () => {
    setIsShowingConfirmUpdateModal(true)
    analytics.dashboard.personalData.clickUpdateChanges.execute()
  }

  const onConfirm = () => {
    submitForm()
    setIsShowingConfirmUpdateModal(false)
    analytics.dashboard.personalData.clickConfirmUpdateChanges.execute()
  }

  return (
    <>
      <Form>
        <div className="user-edit-personal-data-form__content">
          <Text variant="h5" className="user-edit-personal-data-form__title">
            Dados pessoais
          </Text>
          <Input
            name="fullName"
            label="Nome completo"
            placeholder="Seu nome completo"
            className="user-edit-personal-data-form__input"
            value={values.fullName}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.fullName && !!errors.fullName}
            helperText={touched.fullName && errors.fullName}
            disabled={isSubmitting}
            fullWidth
          />
          {values.socialName && (
            <Input
              name="socialName"
              label="Nome social"
              placeholder="Seu nome social"
              className="user-edit-personal-data-form__input"
              value={values.socialName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.socialName && !!errors.socialName}
              helperText={touched.socialName && errors.socialName}
              fullWidth
              disabled
            />
          )}
          <Select
            name="maritalStatus"
            label="Estado Civil"
            className="user-edit-personal-data-form__select"
            value={values.maritalStatus}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.maritalStatus && !!errors.maritalStatus}
            helperText={touched.maritalStatus && errors.maritalStatus}
            disabled={isSubmitting}
            options={MARITAL_STATUS_OPTIONS}
            fullWidth
          />

          <Text
            className="user-edit-personal-data-form__subtitle"
            variant="h6"
            tag="strong"
          >
            Telefone
          </Text>
          <Text>
            Este é o número de telefone pelo qual você receberá as comunicações
            da Azos e informações sobre as suas apólices.
          </Text>
          <InputPhone
            fullWidth
            label="Telefone"
            id="phone"
            name="phone"
            className="user-edit-personal-data-form__input"
            placeholder="(00) 00000-0000"
            error={touched.phone && !!errors.phone}
            helperText={touched.phone && errors.phone}
            value={values.phone}
            onChange={handleChange}
            disabled={isDisabled}
            onBlur={handleBlur}
          />

          <Text
            className="user-edit-personal-data-form__subtitle"
            variant="h6"
            tag="strong"
          >
            E-mail
          </Text>
          <Text>
            Este é o e-mail que você vai utilizar para acessar sua conta na
            Azos, receber as nossas comunicações e informações da sua apólice.
          </Text>
          <Input
            fullWidth
            label="E-mail"
            id="email"
            name="email"
            className="user-edit-personal-data-form__input"
            placeholder="Digite aqui"
            error={touched.email && !!errors.email}
            helperText={touched.email && errors.email}
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isDisabled}
            type="email"
          />

          <Text
            className="user-edit-personal-data-form__subtitle"
            variant="h6"
            tag="strong"
          >
            Endereço residencial
          </Text>
          <InputZipcode
            innerRef={CEPRef}
            id="cep"
            name="cep"
            className="user-edit-personal-data-form__input"
            value={values.cep}
            onChange={event => {
              handleChange(event)
            }}
            onBlur={handleBlur}
            error={touched.cep && !!errors.cep}
            helperText={touched.cep && errors.cep}
            disabled={isDisabled}
            fullWidth
          />
          <Select
            name="state"
            label="Estado"
            className="user-edit-personal-data-form__select"
            options={states}
            value={values.uf}
            onBlur={handleBlur}
            onChange={event => {
              setFieldValue('uf', event.target.value, true)
              handleStateChange(event.target.value.toString())
            }}
            error={touched.state && !!errors.state}
            helperText={touched.state && errors.state}
            disabled={isDisabled || disabledFields.includes('state')}
            fullWidth
          />

          <Select
            name="city"
            label="Cidade"
            className="user-edit-personal-data-form__select"
            options={cities}
            value={values.city}
            onBlur={handleBlur}
            onChange={event => {
              setFieldValue('city', event.target.value, true)
            }}
            error={touched.city && !!errors.city}
            helperText={touched.city && errors.city}
            disabled={isDisabled || disabledFields.includes('city')}
            fullWidth
          />

          <Input
            name="street"
            label="Rua"
            className="user-edit-personal-data-form__input"
            value={values.street}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.street && !!errors.street}
            helperText={touched.street && errors.street}
            disabled={isDisabled || disabledFields.includes('street')}
            fullWidth
          />

          <div className="user-edit-personal-data-form__input-wrapper">
            <InputNumber
              innerRef={inputNumberRef}
              name="number"
              label="Número"
              className="user-edit-personal-data-form__input"
              value={values.number}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.number && !!errors.number}
              helperText={touched.number && errors.number}
              disabled={isDisabled || disabledFields.includes('number')}
              fullWidth
            />

            <Input
              name="complement"
              label="Complemento"
              className="user-edit-personal-data-form__input"
              value={values.complement}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.complement && !!errors.complement}
              helperText={touched.complement && errors.complement}
              disabled={isDisabled || disabledFields.includes('complement')}
              fullWidth
            />
          </div>

          <Input
            name="neighborhood"
            label="Bairro"
            className="user-edit-personal-data-form__input"
            value={values.neighborhood}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.neighborhood && !!errors.neighborhood}
            helperText={touched.neighborhood && errors.neighborhood}
            disabled={isDisabled || disabledFields.includes('neighborhood')}
            fullWidth
          />
        </div>

        <div className="user-edit-personal-data-form__submit-wrapper">
          <Button
            onClick={onSubmitButtonClick}
            className={clsx('user-edit-personal-data-form__submit-button', {
              ['disabled']: !isValid || isDisabled,
            })}
            fullWidth
            disabled={!isValid || isDisabled}
            loading={isSubmitting}
            type="button"
          >
            Salvar alterações
          </Button>
        </div>
      </Form>
      <UserEditConfirmUpdateModal
        open={isShowingConfirmUpdateModal}
        onClose={() => setIsShowingConfirmUpdateModal(false)}
        onConfirm={onConfirm}
      />
    </>
  )
}

export default UserEditPersonalDataForm
