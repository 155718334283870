/**
 * View Banner Change Payment
 *
 * @alias ViewBannerChangePayment
 */

export enum ViewBannerChangePaymentType {
  HOME_PAGE = 'home page',
  POLICY = 'apólice',
}
export type ViewBannerChangePayment = {
  /**
   * Tela
   */
  tela: ViewBannerChangePaymentType
}
