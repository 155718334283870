import { PaymentTimeoutError } from '../../../domain/errors'
import { IRetryService } from '../../protocols'

export class RetryService implements IRetryService {
  public count = 0

  constructor(
    private readonly maxRetry: number,
    private readonly error: Error = new PaymentTimeoutError(),
  ) {}

  public retry(callback: () => Promise<any>): Promise<any> {
    if (this.count >= this.maxRetry) return Promise.reject(this.error)
    this.count += 1
    return callback()
  }
}
