import React from 'react'

import { Utils } from '@azos/shared'
import { EditableInput } from '@azos/shared'

import { CurrencySliderProps } from './CurrencySlider.props'
import { Amount, Currency, Root, Slider, Label } from './CurrencySlider.styles'

const CurrencySlider: React.VFC<CurrencySliderProps> = ({
  parentName,
  //
  range,
  value,
  disabled = false,
  editable = false,
  //
  onChange,
  onChangeCommitted,
  ...rest
}) => {
  const dataProps = {
    parentName,
    'data-min': range.min,
    'data-max': range.max,
    'data-selected': value,
  }

  const marks = React.useMemo(
    () => [
      {
        value: range.min,
        label: Utils.currency.getLongValue(range.min),
      },
      {
        value: range.max,
        label: Utils.currency.getLongValue(range.max),
      },
    ],
    [range.max, range.min],
  )

  const handleChange = React.useCallback(
    (_: object, value: number | number[]) => {
      const valueData = Array.isArray(value) ? value[0] : value
      onChange(valueData)
    },
    [onChange],
  )

  return (
    <Root {...rest}>
      <Amount>
        {editable ? (
          <EditableInput value={value} onChange={onChange} />
        ) : (
          <>
            <Label>R$</Label>
            <Currency>{Utils.currency.formatWithoutCents(value, '')}</Currency>
          </>
        )}
      </Amount>

      <Slider
        min={range.min}
        max={range.max}
        marks={marks}
        value={value}
        step={10000}
        disabled={disabled}
        onChange={handleChange}
        onChangeCommitted={() => onChangeCommitted?.(value)}
        {...dataProps}
      />
    </Root>
  )
}

export default CurrencySlider
