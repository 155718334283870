import {
  BenefitsPageViewService,
  BenefitsPageDetailsService,
  BenefitsBannerService,
} from '../../../../../../data/services'
import { makeMixpanelDispatcherEvent } from '../../../../infra'

export const makeBenefitsPageViewService = () =>
  new BenefitsPageViewService(makeMixpanelDispatcherEvent())

export const makeBenefitsPageDetailsService = () =>
  new BenefitsPageDetailsService(makeMixpanelDispatcherEvent())

export const makeBenefitsBannerService = () =>
  new BenefitsBannerService(makeMixpanelDispatcherEvent())
