import { HttpClient } from '@azos/core'
import {
  LoadBillingDateService,
  LoadFinalQuotationsService,
  LoadProposalDetailsService,
} from '@data/services'
import {
  makeFinalQuotationsRepository,
  makeProposalsRepository,
} from '@main/factories/repositories'

export const makeLoadProposalDetailsService = () =>
  new LoadProposalDetailsService(makeProposalsRepository())

export const makeLoadBillingDateService = (httpClient?: HttpClient) =>
  new LoadBillingDateService(makeProposalsRepository())

export const makeLoadFinalQuotationsService = (httpClient?: HttpClient) =>
  new LoadFinalQuotationsService(makeFinalQuotationsRepository(httpClient))
