import React from 'react'

import { Progress, Router, Swipper } from './components'
import { WizardProvider } from './providers'
import { WizardProps } from './Wizard.props'

const Wizard: React.FCC<WizardProps> = ({
  children,
  config,
  wrapper,
  ...rest
}) => {
  const Wrapper = wrapper || (({ children }) => <>{children}</>)

  const elements: React.ReactNode[] = Array.isArray(children)
    ? children
    : [children]

  const render = elements.reduce((acc: React.ReactNode[], item) => {
    return [...acc, ...(Array.isArray(item) ? item : [item])]
  }, [])

  return (
    <WizardProvider max={render.length} {...rest}>
      <Progress />
      <Router>
        <Wrapper config={config}>
          <Swipper>{render}</Swipper>
        </Wrapper>
      </Router>
    </WizardProvider>
  )
}

export default Wizard
