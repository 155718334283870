const COOKIE_REDIRECT_FROM_KEY = '@azos/redirectUrl'
const COOKIE_USER_KEY = '@azos/user'
const COOKIE_TOKEN_KEY = '@azos/token'
const COOKIE_REFRESH_TOKEN_KEY = '@azos/refreshToken'

export const authCookies = {
  COOKIE_REDIRECT_FROM_KEY,
  COOKIE_USER_KEY,
  COOKIE_TOKEN_KEY,
  COOKIE_REFRESH_TOKEN_KEY,
}
