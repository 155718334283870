import {
  UpsellAddCoverageProductService,
  UpsellChangeCoverageRangeService,
  UpsellClickCoverageDetailsService,
  UpsellClickCoverageValueInputService,
  UpsellClickDetailsAddCoverageService,
  UpsellClickDetailsCoverageService,
  UpsellClickGeneralConditionsLinkService,
  UpsellClickHelpCoverageService,
  UpsellClickSubmitCalculatorService,
  UpsellCloseDetailsModalService,
  UpsellExpandCoverageDetailsService,
  UpsellGoBackCalculatorPage,
  UpsellGoBackDetailsCoverageService,
  UpsellRemoveCoverageProductService,
  UpsellViewCalculatorPage,
} from '../../../../../../data/services'
import { makeMixpanelDispatcherEvent } from '../../../../infra'

export const makeUpsellAddCoverageProductService = () =>
  new UpsellAddCoverageProductService(makeMixpanelDispatcherEvent())

export const makeUpsellClickCoverageDetailsService = () =>
  new UpsellClickCoverageDetailsService(makeMixpanelDispatcherEvent())

export const makeUpsellClickCoverageValueInputService = () =>
  new UpsellClickCoverageValueInputService(makeMixpanelDispatcherEvent())

export const makeUpsellClickHelpCoverageService = () =>
  new UpsellClickHelpCoverageService(makeMixpanelDispatcherEvent())

export const makeUpsellClickDetailsCoverageService = () =>
  new UpsellClickDetailsCoverageService(makeMixpanelDispatcherEvent())

export const makeUpsellClickSubmitCalculatorService = () =>
  new UpsellClickSubmitCalculatorService(makeMixpanelDispatcherEvent())

export const makeUpsellGoBackCalculatorPage = () =>
  new UpsellGoBackCalculatorPage(makeMixpanelDispatcherEvent())

export const makeUpsellRemoveCoverageProductService = () =>
  new UpsellRemoveCoverageProductService(makeMixpanelDispatcherEvent())

export const makeUpsellChangeCoverageRangeService = () =>
  new UpsellChangeCoverageRangeService(makeMixpanelDispatcherEvent())

export const makeUpsellExpandCoverageDetailsService = () =>
  new UpsellExpandCoverageDetailsService(makeMixpanelDispatcherEvent())

export const makeUpsellViewCalculatorPage = () =>
  new UpsellViewCalculatorPage(makeMixpanelDispatcherEvent())

export const makeUpsellClickDetailsAddCoverageService = () =>
  new UpsellClickDetailsAddCoverageService(makeMixpanelDispatcherEvent())

export const makeUpsellClickGeneralConditionsLinkService = () =>
  new UpsellClickGeneralConditionsLinkService(makeMixpanelDispatcherEvent())

export const makeUpsellCloseDetailsModalService = () =>
  new UpsellCloseDetailsModalService(makeMixpanelDispatcherEvent())

export const makeUpsellGoBackDetailsCoverageService = () =>
  new UpsellGoBackDetailsCoverageService(makeMixpanelDispatcherEvent())
