import Box, { BoxProps } from '@mui/material/Box'
import { sliderClasses } from '@mui/material/Slider'
import { styled } from '@mui/material/styles'
import Typography, { TypographyProps } from '@mui/material/Typography'

import { Slider as BaseSlider, SliderProps } from '../Slider'

export const Root = styled(Box)<BoxProps>(({ theme }) => ({
  width: '100%',
}))

export const Amount = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  alignItems: 'baseline',
  justifyContent: 'center',
}))

export const Label = styled(Typography)<TypographyProps<any>>(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: 200,
  marginRight: theme.spacing(1),
}))

Label.defaultProps = {
  component: 'span',
}

export const Currency = styled(Typography)<TypographyProps<any>>(
  ({ theme }) => ({
    fontSize: '2.5rem',
    fontWeight: 500,

    [theme.breakpoints.up('md')]: {
      fontSize: '4rem',
    },
  }),
)

Currency.defaultProps = {
  component: 'strong',
}

export const Slider = styled(BaseSlider)<SliderProps>(({ theme }) => ({
  [`& .${sliderClasses.markLabel}`]: {
    transform: 'translateX(0)',
  },
  [`&.${sliderClasses.marked}`]: {
    [`& .${sliderClasses.markLabel}[data-index="0"]`]: {
      left: 0,
    },
    [`& .${sliderClasses.markLabel}[data-index="1"]`]: {
      left: 'auto !important',
      right: 0,
    },
  },
}))
