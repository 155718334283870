import React from 'react'

import { StepperProps } from './Stepper.props'
import { Root } from './Stepper.styles'

const Stepper: React.VFC<StepperProps> = ({ content }) => {
  return (
    <Root>
      {content.map((item, index) => (
        <div key={index} className="stepper__wrapper">
          <div className="stepper__aside">
            <div className="stepper__step-number">{index + 1}</div>
            {index !== content.length - 1 && (
              <div className="stepper__connector" />
            )}
          </div>
          <div className="stepper__content">{item}</div>
        </div>
      ))}
    </Root>
  )
}

export default Stepper
