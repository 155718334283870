import React from 'react'

import TextField from '@mui/material/TextField'

import { Utils } from '../../../utils'
import { InputProps } from './Input.props'

const Input: React.FCC<InputProps> = ({
  children,
  variant = 'standard',
  name = 'input',
  parentName,
  ...rest
}) => {
  return (
    <TextField
      name={name}
      variant={variant}
      {...rest}
      inputProps={{
        ...rest.inputProps,
        'data-action': Utils.dataNames.renderDataName(name, parentName),
      }}
    >
      {children}
    </TextField>
  )
}

export default Input
