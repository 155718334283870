import { Analytics } from '../../data/protocols'
import { FakeInitializer, FakeLogout, FakeService } from './service'

const makeFakeService = () => new FakeService()
const makeFakeIntiializer = () => new FakeInitializer().initialize
const makeFakeLogout = () => new FakeLogout().logout

export const makeFakeAnalytics = (): Analytics => ({
  initializeLoggedUser: makeFakeIntiializer(),
  logout: makeFakeLogout(),
  account: {
    login: makeFakeService(),
    registration: makeFakeService(),
  },
  calculator: {
    share: makeFakeService(),
  },
  callpage: {
    activate: makeFakeService(),
  },
  cart: {
    add: makeFakeService(),
    remove: makeFakeService(),
  },
  checkout: {
    clickInsertDueDate: makeFakeService(),
    finishCashbackWithCreditcard: makeFakeService(),
    purchase: makeFakeService(),
    saveDueDateChange: makeFakeService(),
    saveProposalPdf: makeFakeService(),
    selectDueDate: makeFakeService(),
    startCashbackWithCreditcard: makeFakeService(),
    viewDueDateSelectDrawer: makeFakeService(),
    clearSaleResponseTime: makeFakeService(),
    selectPaymentFrequency: makeFakeService(),
    viewCashbackModal: makeFakeService(),
    selectCreditCardCashbackService: makeFakeService(),
    selectPixCashbackService: makeFakeService(),
  },
  dashboard: {
    addBeneficiaryModal: makeFakeService(),
    home: {
      simulateInsuranceClick: makeFakeService(),
      viewAboutAzos: makeFakeService(),
      viewAzosContact: makeFakeService(),
      viewCoverageQuestions: makeFakeService(),
      viewHomepage: makeFakeService(),
      viewProductCoverageDetails: makeFakeService(),
    },
    banner: {
      viewImportantInfoBanner: makeFakeService(),
      clickImportantInfoBanner: makeFakeService(),
    },
    broker: {
      interactBrokerContactCard: makeFakeService(),
      viewBrokerContactCard: makeFakeService(),
    },
    benefits: {
      code: {
        benefitsCode: makeFakeService(),
        benefitsCodeAccess: makeFakeService(),
      },
      view: {
        benefitsPageView: makeFakeService(),
        benefitsPageDetails: makeFakeService(),
        benefitsBanner: makeFakeService(),
      },
    },
    editBeneficiaryModal: makeFakeService(),
    policyCardHomepage: makeFakeService(),
    policyPaymentHomepage: makeFakeService(),
    referral: {
      code: {
        referralShareCode: makeFakeService(),
        referralCopyCode: makeFakeService(),
      },
      view: {
        referralPageView: makeFakeService(),
      },
      clickBrokerShare: makeFakeService(),
    },
    saveBeneficiaryChanges: makeFakeService(),
    saveEditedBeneficiaryModal: makeFakeService(),
    sideMenuHomepage: makeFakeService(),
    onboarding: {
      viewOnboardingHomepage: makeFakeService(),
      viewOnboardingPage: makeFakeService(),
      viewOnboardingPolicyContent: makeFakeService(),
      viewOnboardingBeneficiaryContent: makeFakeService(),
      viewOnboardingGuardianContent: makeFakeService(),
    },
    guardian: {
      clickContactBrokerHelp: makeFakeService(),
      clickContactHelp: makeFakeService(),
      clickRequestBrokerGuardian: makeFakeService(),
      clickRequestHelpGuardianRoleResponsability: makeFakeService(),
      guardianHomepage: makeFakeService(),
      saveEditGuardian: makeFakeService(),
      saveGuardian: makeFakeService(),
      viewAddGuardian: makeFakeService(),
      viewEditGuardian: makeFakeService(),
      viewGuardianHomepage: makeFakeService(),
      viewGuardianInstructions: makeFakeService(),
      viewGuardianRoleResponsability: makeFakeService(),
      viewProtectedListHelp: makeFakeService(),
      viewProtected: makeFakeService(),
      viewRequestBrokerProtectedHelp: makeFakeService(),
      viewRequestProtectedHelp: makeFakeService(),
    },
    saveNewBeneficiaryModal: makeFakeService(),
    policies: {
      clickCheckPoliciesReadjustment: makeFakeService(),
      clickHelpReadjustmentDetails: makeFakeService(),
      clickPoliciesCards: makeFakeService(),
      clickPoliciesTabs: makeFakeService(),
      clickReadjustmentBadge: makeFakeService(),
      clickReadjustmentDetails: makeFakeService(),
      clickRedirectReadjustmentDetails: makeFakeService(),
      downloadProposal: makeFakeService(),
      viewPoliciesDetails: makeFakeService(),
      viewPolicyReadjustmentDetails: makeFakeService(),
      viewPolicyReadjustmentModal: makeFakeService(),
    },
    policy: {
      cancel: {
        cancelPolicy: makeFakeService(),
        viewPolicyCancel: makeFakeService(),
        viewPolicyCancelReason: makeFakeService(),
        selectPolicyCancelReason: makeFakeService(),
        exitPolicyCancel: makeFakeService(),
        clickPolicyCancel: makeFakeService(),
        viewReasonCancellation: makeFakeService(),
        actionsReasonCancellation: makeFakeService(),
        viewReduceValue: makeFakeService(),
        actionsReduceValue: makeFakeService(),
        viewCancellationValidation: makeFakeService(),
        actionsCancellationValidation: makeFakeService(),
        viewFeedbackModal: makeFakeService(),
        actionsFeedbackModal: makeFakeService(),
        talkAzosCancellation: makeFakeService(),
      },
      view: {
        clickPolicyCard: makeFakeService(),
        policyScreenView: makeFakeService(),
      },
      clickCoverageDetailsButtons: makeFakeService(),
      clickCoverageDetails: makeFakeService(),
      downloadPolicy: makeFakeService(),
      downloadGeneralConditions: makeFakeService(),
      policyExpirationDate: makeFakeService(),
      viewCoverageDetails: makeFakeService(),
    },
    payments: {
      change: {
        changePaymentModalDetails: makeFakeService(),
      },
      save: {
        saveNewCreditCard: makeFakeService(),
      },
      view: {
        viewBannerChangePayment: makeFakeService(),
        viewPaymentDetailsModal: makeFakeService(),
        viewAddCreditCardModal: makeFakeService(),
      },
    },
    personalData: {
      clickConfirmUpdateChanges: makeFakeService(),
      clickUpdateChanges: makeFakeService(),
      viewEditPersonalData: makeFakeService(),
      viewPersonalDataBottomSheet: makeFakeService(),
      viewPersonalData: makeFakeService(),
      viewResetPassword: makeFakeService(),
    },
    viewAppDownloadModal: makeFakeService(),
    upsell: {
      calculator: {
        addCoverageProduct: makeFakeService(),
        removeCoverageProduct: makeFakeService(),
        viewCalculatorPage: makeFakeService(),
        clickCoverageValueInput: makeFakeService(),
        clickCoverageDetails: makeFakeService(),
        clickHelpCoverage: makeFakeService(),
        clickDetailsCoverage: makeFakeService(),
        clickSubmitCalculator: makeFakeService(),
        expandCoverageDetails: makeFakeService(),
        goBackCalculator: makeFakeService(),
        changeCoverageRange: makeFakeService(),
        clickDetailsAddCoverage: makeFakeService(),
        clickGeneralConditionsLink: makeFakeService(),
        closeDetailsModal: makeFakeService(),
        goBackDetailsCoverage: makeFakeService(),
      },
      checkout: {
        clickInsertDueDate: makeFakeService(),
        viewCheckoutPage: makeFakeService(),
        clearSaleResponseTime: makeFakeService(),
        clickPaymentType: makeFakeService(),
        expandCheckoutDetails: makeFakeService(),
        finishCreditcardPayment: makeFakeService(),
        finishPixPayment: makeFakeService(),
        saveDueDateChange: makeFakeService(),
        selectDueDate: makeFakeService(),
        loadingPixPayment: makeFakeService(),
        viewDueDateSelectDrawer: makeFakeService(),
        selectPaymentFrequency: makeFakeService(),
      },
      contract: {
        gobackSharePage: makeFakeService(),
        sendReferralForm: makeFakeService(),
        copyShareContent: makeFakeService(),
        selectContractType: makeFakeService(),
        viewSelectPage: makeFakeService(),
        upsellDurationTime: makeFakeService(),
        viewUpsellSharePage: makeFakeService(),
      },
      DPS: {
        continueLifeStyleQuestion: makeFakeService(),
        continueDiseasesQuestion: makeFakeService(),
        continueGeneralConditionsQuestion: makeFakeService(),
        continueOtherDiseasesQuestion: makeFakeService(),
        continueRevision: makeFakeService(),
        viewLifeStyleContent: makeFakeService(),
        viewDiseasesContent: makeFakeService(),
        viewGeneralConditions: makeFakeService(),
        viewOtherDiseasesContent: makeFakeService(),
        viewDPSResumeService: makeFakeService(),
        goBack: makeFakeService(),
      },
      simulation: {
        clickOtherDpsOtherDiseasesQuestion: makeFakeService(),
        continueSimulationPageSmokerIMC: makeFakeService(),
        continueSimulationPageProfessionSalary: makeFakeService(),
        goBackSimulationPageProfessionSalary: makeFakeService(),
        goBackSimulationPageSmokerIMC: makeFakeService(),
        viewSimulationPageProfessionSalary: makeFakeService(),
        viewSimulationPageSmokerIMC: makeFakeService(),
      },
    },
  },
  dps: {
    dpsIllness: makeFakeService(),
    selection: makeFakeService(),
    submit: makeFakeService(),
  },
  proposal: {
    accepted: makeFakeService(),
    refused: makeFakeService(),
    changed: makeFakeService(),
    denied: makeFakeService(),
  },
  quotation: {
    allowReceiveByEmail: makeFakeService(),
    declineReceiveByEmail: makeFakeService(),
  },
  simulation: {
    answer: makeFakeService(),
    income: makeFakeService(),
    editCoverage: makeFakeService(),
    lead: makeFakeService(),
    start: makeFakeService(),
    submit: makeFakeService(),
    summary: makeFakeService(),
    viewExperiment: makeFakeService(),
  },
  custom: {
    action: makeFakeService(),
  },
  widget: {
    homeFastQuotation: makeFakeService(),
  },
})
