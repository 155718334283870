import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled('div')(
  ({ theme }) => css`
    .user-edit-personal-data {
      &__header {
        background: ${theme.palette.neutral[50]};
        padding: ${theme.spacing[4]}px;
        display: flex;
        align-items: center;
      }

      &__button {
        height: 56px;
        width: 56px;

        display: grid;
        place-items: center;
        cursor: pointer;

        border-radius: 50%;
        background: ${theme.palette.neutral[100]};
        border: none;

        i {
          font-size: 1.5rem;
        }
      }

      &__title {
        margin-left: ${theme.spacing[4]}px;
      }
    }
  `,
)
