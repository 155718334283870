import React from 'react'

import { useRemoteConfig } from '@azos/core'

export interface PaymentContextValue {
  isLoading: boolean
  isPaymentChangeActive: boolean
  isPaymentDateChangeActive: boolean
}

export const ConfigContext = React.createContext<PaymentContextValue>(
  {} as PaymentContextValue,
)

export const PaymentProvider: React.FCC = ({ children }) => {
  const remoteConfig = useRemoteConfig()
  const isPaymentChangeActive = remoteConfig.get<boolean>(
    'WEB_TAB_CHANGE_PAYMENT',
  )
  const isPaymentDateChangeActive = remoteConfig.get<boolean>(
    'WEB_TAB_CHANGE_PAYMENT_EXPIRES_AT',
  )

  return (
    <ConfigContext.Provider
      value={{
        isLoading: !remoteConfig,
        isPaymentChangeActive,
        isPaymentDateChangeActive,
      }}
    >
      {children}
    </ConfigContext.Provider>
  )
}
