import { Services } from '@azos/core'
import { IDPSRepository } from '@data/repositories'
import { CreateDPSUseCase } from '@domain/usecases'

type Params = CreateDPSUseCase.Params
type Response = CreateDPSUseCase.Response

export type ICreateDPSService = Services<Params, Response>

export class CreateDPSService implements ICreateDPSService {
  constructor(private readonly DPSRepository: IDPSRepository) {}

  async execute(params: Params): Promise<Response> {
    return await this.DPSRepository.createDPS(params)
  }
}
