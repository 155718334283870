import { ClickDetailsAddCoverage } from '../../../../../domain/models'
import { Dispatcher, Service } from '../../../../protocols'

export type IUpsellClickDetailsAddCoverageService =
  Service<ClickDetailsAddCoverage>

/**
 * Click Details Add Coverage
 *
 * @category Upsell
 */
export class UpsellClickDetailsAddCoverageService
  implements IUpsellClickDetailsAddCoverageService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   * @param {ClickDetailsAddCoverage} data
   */
  execute(data: ClickDetailsAddCoverage): void {
    this.dispatcher.execute(
      'Upsell - Clique "quero essa cobertura" em detalhes do produto',
      data,
    )
  }
}
