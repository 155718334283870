import {
  ProposalAcceptedService,
  ProposalChangedService,
  ProposalDeniedService,
  ProposalRefusedService,
} from '../../../data/services/proposals'
import { makeDispatcherEvent } from '../infra/dispatcher-event-factory'

export const makeProposalAcceptedService = () =>
  new ProposalAcceptedService(makeDispatcherEvent())

export const makeProposalChangedService = () =>
  new ProposalChangedService(makeDispatcherEvent())

export const makeProposalDeniedService = () =>
  new ProposalDeniedService(makeDispatcherEvent())

export const makeProposalRefusedService = () =>
  new ProposalRefusedService(makeDispatcherEvent())
