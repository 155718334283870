import firebase from 'firebase/app'
import 'firebase/storage'

import 'firebase/auth'
import 'firebase/remote-config'
import { firebaseConfig } from '../../main/config/firebase'

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
} else {
  firebase.app()
}

export { firebase }
