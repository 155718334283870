import { Utils } from '@azos/shared'
import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
  valid: Yup.string().required(),
  cep: Yup.string()
    .test({
      name: 'CEP',
      message: 'O CEP não é válido.',
      test: (value, ctx) =>
        Utils.validator.validateCep(
          Utils.sanitizer.onlyNumber(value).toString(),
        ) && !!ctx.parent.valid,
    })
    .required('O CEP é obrigatório'),
  state: Yup.string().required('O estado é obrigatório'),
  city: Yup.string().required('A cidade é obrigatória'),
  street: Yup.string()
    .required('O logradouro é obrigatório')
    .max(50, 'Deve possuir no máximo 50 caracteres'),
  neighborhood: Yup.string()
    .required('O bairro é obrigatório')
    .max(50, 'Deve possuir no máximo 50 caracteres'),
  number: Yup.number().required('O número é obrigatório'),
  complement: Yup.string().optional(),
})
