import { Dispatcher, Service } from '../../../../protocols'

export type IViewPolicyCancelService = Service<void>

/**
 * View Policy Cancel
 *
 * @category Dashboard
 */
export class ViewPolicyCancelService implements IViewPolicyCancelService {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @param
   * @function
   */
  execute(): void {
    this.dispatcher.execute(
      'Area logada web - Visualização de modal para confirmar cancelamento da apólice',
    )
  }
}
