import React from 'react'

import { Modal, ModalProps, useTheme, withModal } from '@azos/shared'
import { ContractType } from '@domain/models'
import { makeChangeExpiryDateService } from '@main/factories/services'

import { PaymentEditExpiry, PaymentEditExpiryProps } from '../../../Payments'

type Props = ModalProps &
  Omit<PaymentEditExpiryProps, 'changeExpiryDateService'>

const changeExpiryDateService = makeChangeExpiryDateService()

const PolicyCardPaymentEditExpiry: React.VFC<Props> = ({
  onClose,
  open,
  id,
  days,
  onSuccess,
  billingService,
  proposalId,
}) => {
  const theme = useTheme()
  return (
    <Modal
      variant="bottomDrawer"
      backgroundColor={theme.palette.neutral[100]}
      open={open}
      onClose={onClose}
    >
      <PaymentEditExpiry
        id={id}
        days={days}
        type={ContractType.policy}
        onSuccess={onSuccess}
        changeExpiryDateService={changeExpiryDateService}
        billingService={billingService}
        proposalId={proposalId}
      />
    </Modal>
  )
}

export default withModal(PolicyCardPaymentEditExpiry)
