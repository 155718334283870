import { css, styled } from '../../../styles'

export const Root = styled('div')(
  ({ theme }) => css`
    display: flex;

    .card-qr-code {
      &__wrapper {
        padding: ${theme.spacing[3]}px;
        background: ${theme.palette.neutral[100]};
      }
    }
  `,
)
