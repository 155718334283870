import React from 'react'

import analytics from '@azos/analytics'
import { Utils } from '@azos/shared'
import Skeleton from '@mui/material/Skeleton'
import clsx from 'clsx'
import copy from 'copy-to-clipboard'

import { CardCopyTextHandle, CardCopyTextProps } from './CardCopyText.props'
import { Root } from './CardCopyText.styles'

const CardCopyText: React.ForwardRefRenderFunction<
  CardCopyTextHandle,
  CardCopyTextProps
> = (
  {
    value,
    buttonRef,
    onClick,
    disabled = false,
    loading = false,
    successLabel = 'Copiado com sucesso',
    parentName,
    shouldShowSuccessLabel = true,
    customLabel,
    className,
    startIcon,
  },
  ref,
) => {
  const timer = React.useRef<NodeJS.Timeout | null>(null)

  const [showMessage, setShowMessage] = React.useState(false)

  React.useEffect(() => {
    return () => {
      if (timer.current) clearTimeout(timer.current)
    }
  }, [])

  const handleCopy = () => {
    copy(value)

    if (shouldShowSuccessLabel) {
      setShowMessage(true)

      timer.current = setTimeout(() => {
        setShowMessage(false)
      }, 3000)
    }

    onClick?.()

    if (parentName) {
      analytics.custom.action.execute({
        dataAction: Utils.dataNames.renderDataName(
          'card-copy-text',
          parentName,
        ),
      })
    }
  }

  React.useImperativeHandle(ref, () => ({
    handleCopy,
  }))

  return (
    <Root
      ref={buttonRef}
      className={clsx('card_copy_text', className)}
      disabled={disabled}
      onClick={handleCopy}
      $showMessage={showMessage}
      data-action={Utils.dataNames.renderDataName('card-copy-text', parentName)}
    >
      {showMessage && (
        <span className="card_copy_text__success">{successLabel}</span>
      )}
      <div className="card_copy_text__text-content">
        {startIcon && <i className={startIcon} />}
        <span className="card_copy_text__value">
          {loading ? (
            <Skeleton animation="wave" height={25} width="100%" />
          ) : (
            customLabel || value
          )}
        </span>
      </div>

      <span className="icon-copy" />
    </Root>
  )
}

CardCopyText.displayName = 'CardCopyText'

export default React.forwardRef(CardCopyText)
