import React, { useContext } from 'react'

import { Button, Input, Radio, Typography } from '@Azos-Seguros/apollo'
import { useAuth } from '@azos/account'
import analytics from '@azos/analytics'
import {
  ActionsReasonCancellationType,
  PolicyCancelUserType,
} from '@azos/analytics/src/domain/models'
import { Icon } from '@azos/shared/src/icons'
import { Navigation } from '@presentation/components/molecules/Navigation'

import { PolicyCancelContext } from '../../PolicyCancelContext'
import { cancelOptions } from '../../PolicyCancelReasons'
import { ReasonCancellationProps } from './ReasonCancellation.props'
import Root from './ReasonCancellation.styles'

const ReasonCancellation: React.VFC<ReasonCancellationProps> = ({
  onStepChange,
  onClose,
}) => {
  const { user } = useAuth()
  const { reason, setReason, setOtherReason, otherReason } =
    useContext(PolicyCancelContext)

  const handleReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReason(event.target.value)

    const newReason = event.target.value

    analytics.dashboard.policy.cancel.selectPolicyCancelReason.execute({
      reason: newReason,
      other:
        newReason === cancelOptions.OTHER_REASON && otherReason
          ? otherReason
          : undefined,
    })
  }

  const handleOtherReasonChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    analytics.dashboard.policy.cancel.selectPolicyCancelReason.execute({
      reason,
      other: event.target.value,
    })
  }

  React.useEffect(() => {
    setOtherReason('')
  }, [reason, setOtherReason])

  const stepReduceValue = () => {
    onStepChange(1)
  }

  const nextStep = () => {
    if (reason === cancelOptions.PRICE) {
      stepReduceValue()
      return
    }
    onStepChange(2)
  }

  const isBroker = React.useMemo(
    () => user?.isBroker || user?.isInsuredByBroker,
    [user],
  )

  React.useEffect(() => {
    analytics.dashboard.policy.cancel.viewReasonCancellation.execute({
      type: isBroker ? PolicyCancelUserType.BROKER : PolicyCancelUserType.B2C,
    })
  }, [isBroker])

  const onNextStep = () => {
    nextStep()
    analytics.dashboard.policy.cancel.actionsReasonCancellation.execute({
      action: ActionsReasonCancellationType.CONTINUAR,
    })
  }

  const quitFlow = () => {
    onClose?.()
    analytics.dashboard.policy.cancel.actionsReasonCancellation.execute({
      action: ActionsReasonCancellationType.DESISTIR,
    })
  }

  return (
    <Root>
      <div className="reason-cancellation">
        <Navigation
          noText
          className="reason-cancellation__navigation"
          onClick={onClose}
        />
        <div className="reason-cancellation__header">
          <div className="reason-cancellation__header--text">
            <div className="reason-cancellation__header--icon">
              <Icon.PolicyCancelError />
            </div>
            <Typography variant="h5"> Cancelamento de apólice</Typography>
            <Typography variant="body3">
              O cancelamento de uma apólice é um processo irreversível. A partir
              da data do cancelamento, você não terá mais acesso aos serviços de
              proteção e benefícios.
            </Typography>
          </div>
        </div>

        <div className="reason-cancellation__content">
          <Typography
            variant="body3"
            className="reason-cancellation__content--title"
          >
            Se realmente deseja prosseguir com o cancelamento, selecione abaixo
            um dos motivos abaixo:
          </Typography>
          <div className="reason-cancellation__content--radio">
            {Object.values(cancelOptions).map((item, index) => (
              <div
                className="reason-cancellation__content--radio__style"
                key={`${index}-${item}`}
              >
                <Radio
                  id={item}
                  name="cancel-motivation"
                  label={item}
                  value={item}
                  checked={item === reason}
                  onChange={event => handleReasonChange(event)}
                />
              </div>
            ))}
          </div>

          {reason === cancelOptions.OTHER_REASON && (
            <div className="reason-cancellation__content--other-reason">
              <label htmlFor="other-reason">
                Poderia nos contar um pouco mais sobre o motivo do cancelamento?
              </label>

              <Input
                fullWidth
                id="other-reason"
                name="other-reason"
                value={otherReason}
                onChange={e => setOtherReason(e.target.value)}
                onBlur={handleOtherReasonChange}
              />
            </div>
          )}
        </div>
        <div className="reason-cancellation__button">
          <Button fullWidth onClick={onNextStep} disabled={!reason}>
            Continuar
          </Button>
          <Button fullWidth variant="outline" onClick={quitFlow}>
            Desistir do cancelamento
          </Button>
        </div>
      </div>
    </Root>
  )
}

export default ReasonCancellation
