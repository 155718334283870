import { HttpClient, UnexpectedError } from '@azos/core'
import {
  CreateQuotationUseCase,
  UpdateQuotationUseCase,
} from '@domain/usecases'
import HttpStatusCode from 'http-status-codes'

export type IQuotationsRepository = CreateQuotationUseCase &
  UpdateQuotationUseCase

export class QuotationsRepository implements IQuotationsRepository {
  constructor(private readonly httpClient: HttpClient) {}

  async create(
    params: CreateQuotationUseCase.Params,
  ): Promise<CreateQuotationUseCase.Response> {
    const httpResponse = await this.httpClient.request({
      url: `/quotations`,
      method: 'post',
      body: params,
    })

    const response = httpResponse.data

    switch (httpResponse.statusCode) {
      case HttpStatusCode.CREATED:
        return response as CreateQuotationUseCase.Response
      default:
        throw new UnexpectedError()
    }
  }

  async update(
    params: UpdateQuotationUseCase.Params,
  ): Promise<UpdateQuotationUseCase.Response> {
    const { quotationId, ...payload } = params

    const httpResponse = await this.httpClient.request({
      url: `/quotations/${quotationId}`,
      method: 'patch',
      body: payload,
    })

    const response = httpResponse.data

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
        return response as UpdateQuotationUseCase.Response
      default:
        throw new UnexpectedError()
    }
  }
}
