import { Dispatcher, Service } from '../../../../protocols'

export type IViewPolicyReadjustmentModalService = Service<void>

/**
 * View Policy Readjustment Modal
 *
 * @category Policies
 */
export class ViewPolicyReadjustmentModalService
  implements IViewPolicyReadjustmentModalService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @param
   * @function
   */
  execute(): void {
    this.dispatcher.execute(
      'Area logada web - Visualização de conteúdo reajuste de apólice',
    )
  }
}
