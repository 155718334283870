import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled('div')(
  ({ theme }) => css`
    .error-modal {
      &__title {
        display: block;
        margin: ${theme.spacing[4]}px 0 ${theme.spacing[3]}px;
      }

      &__description {
        display: block;
        margin-bottom: ${theme.spacing[4]}px;
        color: ${theme.palette.neutral[400]};

        @media (min-width: ${theme.breakpoints.md}px) {
          color: ${theme.palette.neutral[500]};
        }
      }

      &__content {
        display: grid;
        gap: 9px;
        margin-bottom: ${theme.spacing[2]}px;
      }

      &__copy-content {
        display: flex;
        max-width: 100%;
        overflow: auto;

        & > button {
          width: 152px;
          min-width: 152px;
          margin-right: ${theme.spacing[2]}px;
        }

        @media (min-width: ${theme.breakpoints.sm}px) {
          display: grid;
          gap: ${theme.spacing[2]}px;

          & > button {
            width: 100%;
            padding: 16px 16px;
            margin-right: 0;
          }
        }
      }

      &__card-copy {
        background: ${theme.palette.neutral[100]};
        border: none;
        justify-content: space-between;
        font-size: 0.875rem;
        padding: 16px 0;

        @media (min-width: ${theme.breakpoints.sm}px) {
          padding: 16px 0;
        }

        :last-child {
          padding-bottom: 0;
        }

        .card_copy_text__text-content {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .card_copy_text__value {
          flex: 1;
          text-align: start;
        }
      }

      &__button-select {
        background: ${theme.palette.neutral[100]};
        border: none;

        height: 100%;
        display: flex;
        align-items: flex-start;

        @media (min-width: ${theme.breakpoints.sm}px) {
          height: 100%;
          display: flex;
          align-items: flex-start;
        }

        i {
          font-size: 24px;
          margin-bottom: 4px;

          @media (min-width: ${theme.breakpoints.sm}px) {
            margin-bottom: 0;
          }

          :last-child {
            color: #a3aab9;
          }
        }

        .button-select__content {
          flex-direction: column;

          @media (min-width: ${theme.breakpoints.sm}px) {
            flex-direction: row;
          }

          & > div {
            margin-left: 0;

            @media (min-width: ${theme.breakpoints.sm}px) {
              margin-left: 8px;
            }
          }
        }
      }

      &__content--broker {
        width: 100%;
        height: auto;
        padding: 20px;
        background: #f3f4f6;

        &__title {
          display: flex;
          align-items: center;
          margin-bottom: 16px;

          i {
            font-size: 24px;
            margin-right: 14px;
          }
        }

        &__contact {
          display: flex;
          justify-content: space-around;
          margin-top: 16px;

          i {
            font-size: 24px;
            margin-right: 14px;
          }

          &--phone {
            display: flex;
            align-items: center;
            cursor: pointer;
          }

          &--email {
            display: flex;
            align-items: center;
            cursor: pointer;
          }
        }

        hr {
          border: 1px solid #d0d4dc;
          margin: 0 -20px;
        }
      }

      &__button {
        margin-top: 24px;
      }
    }
  `,
)
