import { IDPSRepository } from '../../../../data/repositories'
import {
  DPSIllnessesRequest,
  DPSQuestionnaireRequest,
  LoadQuestionnaire,
} from '../../../../domain'

export class FakeDPSRepository implements IDPSRepository {
  createQuestionnaireParams: any
  createQuestionnaireResponse: any

  createIllnessesParams: any
  createIllnessesResponse: any

  loadQuestionnaireResponse: LoadQuestionnaire.Response = {
    questionnaire_order: [],
    questions: {},
  }

  async loadQuestionnaire(): Promise<LoadQuestionnaire.Response> {
    return this.loadQuestionnaireResponse
  }

  async createQuestionnaire(params: DPSQuestionnaireRequest): Promise<void> {
    this.createQuestionnaireParams = params
    return this.createQuestionnaireResponse
  }

  async createIllnesses(params: DPSIllnessesRequest): Promise<void> {
    this.createIllnessesParams = params
    return this.createIllnessesResponse
  }
}
