import { Dispatcher, Service } from '../../../../protocols'

export type IClickCheckPoliciesReadjustmentService = Service<void>

/**
 * Click Check Policies Readjustment
 *
 * @category Policies
 */
export class ClickCheckPoliciesReadjustmentService
  implements IClickCheckPoliciesReadjustmentService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @param
   * @function
   */
  execute(): void {
    this.dispatcher.execute(
      'Area logada web - Clique em "Conferir apólice" na tela de conteúdo de reajuste de apólice',
    )
  }
}
