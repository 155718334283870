import {
  ViewOnboardingHomepageService,
  ViewOnboardingPageService,
  ViewOnboardingGuardianContentService,
  ViewOnboardingBeneficiaryContentService,
  ViewOnboardingPolicyContentService,
} from '../../../../../data/services'
import { makeMixpanelDispatcherEvent } from '../../../infra'

export const makeViewOnboardingHomepageService = () =>
  new ViewOnboardingHomepageService(makeMixpanelDispatcherEvent())

export const makeViewOnboardingPageService = () =>
  new ViewOnboardingPageService(makeMixpanelDispatcherEvent())

export const makeViewOnboaringPolicyContentService = () =>
  new ViewOnboardingPolicyContentService(makeMixpanelDispatcherEvent())

export const makeViewOnboaringBeneficiaryContentService = () =>
  new ViewOnboardingBeneficiaryContentService(makeMixpanelDispatcherEvent())

export const makeViewOnboaringGuardianContentService = () =>
  new ViewOnboardingGuardianContentService(makeMixpanelDispatcherEvent())
