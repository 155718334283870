import React from 'react'

import { useQuery } from '@azos/core'

import { makeTokenService } from '../main/factories/services'

const PARAM_TOKEN = 'token'

const authService = makeTokenService()

const useToken = () => {
  const { getQueryParam } = useQuery()

  React.useEffect(() => {
    const token = getQueryParam(PARAM_TOKEN)
    if (token) {
      authService.removeToken().setToken(token, '')
    }
  }, [getQueryParam])
}

export { useToken }
