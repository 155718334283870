import React from 'react'

import { InputMask } from '../InputMask'
import { InputNumberProps } from './InputNumber.props'

const InputNumber = ({ children, ...rest }: InputNumberProps) => {
  return (
    <InputMask {...rest} type="tel">
      {children}
    </InputMask>
  )
}

export default InputNumber
