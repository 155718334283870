import { Services } from '@azos/core'
import { IDPSRepository } from '@data/repositories'
import { GetDPSQuestionnaireUseCase } from '@domain/usecases'

type Params = GetDPSQuestionnaireUseCase.Params
type Response = GetDPSQuestionnaireUseCase.Response

export type IGetDPSQuestionnaireService = Services<Params, Response>

export class GetDPSQuestionnaireService implements IGetDPSQuestionnaireService {
  constructor(private readonly DPSRepository: IDPSRepository) {}

  getUrl(): string {
    return '/dps'
  }

  async execute(): Promise<Response> {
    return await this.DPSRepository.getDPSQuestionnaire()
  }
}
