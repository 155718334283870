import { ViewOnboardingHomepage } from '../../../../domain/models'
import { Dispatcher, Service } from '../../../protocols'

export type IViewOnboardingHomepageService = Service<ViewOnboardingHomepage>

/**
 * View onboarding homepage
 *
 * @category Dashboard
 */
export class ViewOnboardingHomepageService
  implements IViewOnboardingHomepageService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @param {ViewOnboardingHomepage} data
   * @function
   */
  execute(data: ViewOnboardingHomepage): void {
    this.dispatcher.execute(
      'Area logada web - Visualização do banner de onboarding na home page',
      data,
    )
  }
}
