import { css, Modal as StyledModal, ModalProps } from '@azos/shared'
import styled from '@emotion/styled'

export const Modal = styled(StyledModal)<ModalProps>(
  ({ theme }) => css`
    .modal-content {
      width: 100%;
    }
  `,
)

export const Root = styled('div')(
  ({ theme }) => css`
    .call-center-modal {
      &__title {
        display: block;
        margin: ${theme.spacing[4]}px 0 ${theme.spacing[3]}px;
      }

      &__description {
        display: block;
        margin-bottom: ${theme.spacing[4]}px;
      }

      &__content {
        display: grid;
        gap: ${theme.spacing[2]}px;
        margin-bottom: ${theme.spacing[4]}px;
      }

      &__copy-content {
      }

      &__card-copy {
        background: ${theme.palette.neutral[100]};
        border: none;
        justify-content: space-between;
        padding: 14px;

        .card_copy_text__text-content {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .card_copy_text__value {
          flex: 1;
          text-align: start;
        }
      }

      &__button-select {
        background: ${theme.palette.neutral[100]};
        border: none;
      }

      &__button {
        height: 48px;
      }
    }
  `,
)
