import { AppError, HttpClient, UnexpectedError } from '@azos/core'
import { LoadGuardiansUseCase, UpsertGuardiansUseCase } from '@domain/usecases'
import HttpStatusCode from 'http-status-codes'

export type IGuardiansRepository = LoadGuardiansUseCase & UpsertGuardiansUseCase

export class GuardiansRepository implements IGuardiansRepository {
  constructor(private readonly httpClient: HttpClient) {}

  async loadGuardians(): Promise<LoadGuardiansUseCase.Response> {
    const httpResponse = await this.httpClient.request({
      url: `/guardians`,
      method: 'get',
      body: null,
    })

    const response = httpResponse.data

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
        return response as LoadGuardiansUseCase.Response
      default:
        throw new UnexpectedError()
    }
  }

  async upsertGuardians(
    params: UpsertGuardiansUseCase.Params,
  ): Promise<UpsertGuardiansUseCase.Response> {
    const httpResponse = await this.httpClient.request({
      url: `/guardians`,
      method: 'post',
      body: params,
    })

    const response = httpResponse.data

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
        return response as UpsertGuardiansUseCase.Response
      default:
        throw new AppError(response?.message)
    }
  }
}
