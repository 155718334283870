import React from 'react'

import { useAuth } from '@azos/account'
import analytics from '@azos/analytics'
import { MenuDashboard, useMediaQuery, useTheme } from '@azos/shared'
import { Text } from '@azos/shared/src/components/v2'
import { useBanners } from '@presentation/providers/BannersProvider'

import { InfoCard } from '../ImportantInfos/components'
import { ResponsibleBrokerCard } from '../ResponsibleBrokerCard'
import { UserAccountCard } from '../User'
import { ModalMenuProps } from './ModalMenu.props'
import { Modal, Root } from './ModalMenu.styles'

const ModalMenu: React.VFC<ModalMenuProps> = ({
  open,
  onClose,
  menuItems,
  onClick,
}: ModalMenuProps) => {
  const theme = useTheme()
  const { isMobile } = useMediaQuery()
  const { user } = useAuth()
  const { onboardingBanner } = useBanners()

  const handleEvents = React.useCallback(() => {
    if (onboardingBanner && onboardingBanner.icon.valueProgressIndicator) {
      analytics.dashboard.onboarding.viewOnboardingHomepage.execute({
        percentual: onboardingBanner.icon.valueProgressIndicator,
      })
    }
  }, [onboardingBanner])

  React.useEffect(() => {
    if (isMobile && open) {
      handleEvents()
    }
  }, [handleEvents, isMobile, open])

  if (!isMobile) return null

  return (
    <Modal
      variant="drawer"
      open={open}
      onClose={onClose}
      showHeader={false}
      noGutter
      backgroundColor={theme.palette.neutral[100]}
    >
      <Root>
        <UserAccountCard />

        {onboardingBanner && <InfoCard item={onboardingBanner} />}

        {user?.brokerUser && (
          <div className="modal-menu__responsible-broker-content">
            <Text variant="body2" className="modal-menu__broker-header">
              Fale com o seu <strong>corretor(a)</strong>
            </Text>
            <ResponsibleBrokerCard />
          </div>
        )}

        <MenuDashboard menuItems={menuItems} onClick={onClick} />
      </Root>
    </Modal>
  )
}

export default ModalMenu
