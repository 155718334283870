import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;

    .personal-data {
      &__select {
        display: flex;
        flex-direction: column;
      }

      &__content {
        display: flex;
        flex-direction: column;
        margin-top: ${theme.spacing[2]}px;

        & > * {
          margin-top: ${theme.spacing[3]}px;
        }
      }
    }
  `,
)

export const RadioGroupContent = styled('fieldset')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;

    .radio-group-content__radio-group {
      display: flex;

      & > * {
        flex: 1;
      }
    }

    > legend {
      margin-top: ${theme.spacing[4]}px;
    }
  `,
)
