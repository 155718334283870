import { User } from '../../../domain/models'
import { MixpanelDispatcher } from '../../../infra'

export const makeMixpanelDispatcherEvent = () =>
  MixpanelDispatcher.getInstance()

export const makeMixpanelDispatcher = (id: string, userData: User) =>
  MixpanelDispatcher.initialize(id, userData)

export const makeMixpanelLogout = () => MixpanelDispatcher.logout()
