import { CreateInsuredUserRequest } from '../../../data/services'
import { CreateInsuredUser } from '../../../domain/usecases'
import { Utils } from '../../../utils'
import { Mapper } from '../../protocols'

type Request = CreateInsuredUserRequest
type Response = CreateInsuredUser.Params

export class CreateInsuredUserMapper implements Mapper {
  map(params: Request): Response {
    return {
      email: params.email.toLowerCase().normalize('NFD').trim(),
      socialName: params.social_name,
      gender: params.gender,
      city: params.city,
      neighborhood: params.neighborhood,
      state: params.state,
      street: params.street,
      number: parseInt(params.number.toString(), 10),
      complement: params.complement,
      maritalStatus: params.marital_status,
      authorizesWhatsappContact: params.authorizes_whatsapp_contact == 'true',
      isPoliticallyExposedPerson:
        params.is_politically_exposed_person === 'true',
      //
      fullName: Utils.sanitizer.capitalize(params.full_name),
      birthDate: Utils.date.formatResetDate(params.birth_date!),
      phone: Utils.sanitizer.onlyNumber(params.phone),
      cpf: Utils.sanitizer.onlyNumber(params.cpf),
      cep: Utils.sanitizer.onlyNumber(params.cep),
    }
  }

  mapCollection(response: Request[]): Response[] {
    return response.map(this.map.bind(this))
  }
}
