import { SWRServices } from '@azos/core'
import { IAddressesRepository } from '@data/repositories'
import { ListStatesUseCase } from '@domain/usecases'

type Params = ListStatesUseCase.Params
type Response = ListStatesUseCase.Response

export type IListStatesService = SWRServices<Params, Response>

export class ListStatesService implements IListStatesService {
  constructor(private readonly addressesRepository: IAddressesRepository) {}

  getUrl(): string {
    return `/addresses/states`
  }

  async execute(): Promise<ListStatesUseCase.Response> {
    const response = await this.addressesRepository.listStates()

    return response
  }
}
