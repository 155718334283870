import {
  ListCitiesService,
  ListStatesService,
  LoadByZipCodeService,
} from '@data/services'

import { makeAddressesRepository } from '../repositories'

export const makeListCitiesService = () =>
  new ListCitiesService(makeAddressesRepository())

export const makeListStatesService = () =>
  new ListStatesService(makeAddressesRepository())

export const makeLoadByZipCodeService = () =>
  new LoadByZipCodeService(makeAddressesRepository())
