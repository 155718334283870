import { ActionsReasonCancellation } from '../../../../../domain/models'
import { Dispatcher, Service } from '../../../../protocols'

export type IActionsReasonCancellationService =
  Service<ActionsReasonCancellation>

/**
 * Actions Reason Cancellation
 *
 * @category Dashboard
 */
export class ActionsReasonCancellationService
  implements IActionsReasonCancellationService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @param {ActionsReasonCancellation} data
   * @function
   */
  execute(data: ActionsReasonCancellation): void {
    this.dispatcher.execute(
      'Area logada web - Clicou nas ações da tela de seleção de motivo de cancelamento',
      data,
    )
  }
}
