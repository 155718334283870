import { Box as ApolloBox } from '@Azos-Seguros/apollo'
import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Box = styled(ApolloBox)(
  () => css`
    display: flex;

    justify-content: center;
  `,
)
