import React from 'react'

import { Guardian } from '@domain/models'

import { GuardianFormValue } from '../../Guardians/GuardiansModal/GuardiansModal.props'

export const GuardiansContext = React.createContext<{
  guardians: Guardian[]
  loading: boolean
  error: boolean
  setModalDeleteGuardian: React.Dispatch<React.SetStateAction<boolean>>
  setModalEditGuardian: React.Dispatch<React.SetStateAction<boolean>>
  setIsDeletingGuardian: React.Dispatch<React.SetStateAction<boolean>>
  setSelectedGuardian: React.Dispatch<
    React.SetStateAction<GuardianFormValue | null>
  >
  handleLoadGuardians: () => void
  optionsGuardian: (guardian: Guardian, guardianIndex: number) => void
  isEditingGuardian: boolean
  isDeletingGuardian: boolean
}>({
  guardians: [],
  loading: true,
  error: false,
  setModalDeleteGuardian: () => {},
  setModalEditGuardian: () => {},
  setSelectedGuardian: () => {},
  handleLoadGuardians: () => {},
  setIsDeletingGuardian: () => {},
  optionsGuardian: () => {},
  isEditingGuardian: false,
  isDeletingGuardian: false,
})
