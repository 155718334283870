import {
  CheckBillingStatusService,
  CheckInvoiceStatusService,
  LoadInvoiceDataService,
  LoadPaymentStatusService,
} from '../../../data/services'
import { checkoutConfig } from '../../config/checkout'
import { makeProposalConsumer, makeProposalProducer } from '../adapters'
import { makeCoreCheckoutRepository } from '../repositories'
import { makeDelayService, makeRetryService } from './polling-service-factory'

export const makeCheckBillingStatusService = () =>
  new CheckBillingStatusService(
    makeRetryService(checkoutConfig.polling.billing.attempts),
    makeDelayService(checkoutConfig.polling.billing.interval),
    makeCoreCheckoutRepository(),
  )

export const makeCheckInvoiceStatusService = () =>
  new CheckInvoiceStatusService(
    makeRetryService(checkoutConfig.polling.invoiceStatus.attempts),
    makeDelayService(checkoutConfig.polling.invoiceStatus.interval),
    makeCoreCheckoutRepository(),
    makeProposalProducer(),
  )

export const makeLoadPaymentStatusService = () =>
  new LoadPaymentStatusService(
    makeProposalConsumer(),
    makeCheckInvoiceStatusService(),
  )

export const makeLoadInvoiceDataService = () =>
  new LoadInvoiceDataService(
    makeRetryService(checkoutConfig.polling.invoiceData.attempts),
    makeDelayService(checkoutConfig.polling.invoiceData.interval),
    makeCoreCheckoutRepository(),
  )
