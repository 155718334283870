export const getNearestValue = (source: number[]) => (value: number) => {
  return source.reduce((prev, current) =>
    Math.abs(current - value) < Math.abs(prev - value) ? current : prev,
  )
}

export const getMinMax = (
  value: number,
  boundaries: { min: number; max: number } | undefined = {
    min: 0,
    max: 100,
  },
) => {
  return Math.min(Math.max(value, boundaries.min), boundaries.max)
}
