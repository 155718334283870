import React from 'react'

import { useAuth } from '@azos/account'
import { useGuardian } from '@presentation/providers/GuardianProvider'

import ProtectedTab from './ProtectedTab'

const ProtectedTabAdapter = () => {
  const { protectedList, isGuardian } = useGuardian()
  const { user } = useAuth()

  return (
    <ProtectedTab
      protectedList={protectedList}
      user={user}
      isGuardian={isGuardian}
    />
  )
}

export default ProtectedTabAdapter
