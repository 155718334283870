import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled('div')(
  ({ theme }) => css`
    display: flex;
    justify-content: center;
    position: relative;
    margin: ${theme.spacing[3]}px 0;
    min-height: 1rem;

    &:after {
      content: '';
      background-color: rgba(0, 0, 0, 0.1);
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      height: 1px;
      width: 100%;
    }

    > span {
      background: ${theme.palette.neutral[50]};
      color: ${theme.palette.neutral[400]};
      display: inline-block;
      font-weight: 600;
      padding: 0 12px;
      z-index: 1;
    }
  `,
)
