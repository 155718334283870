export enum ClickPoliciesTab {
  POLICIES = 'Apólices',
  PROPOSALS = 'Propostas',
}

/**
 * Click Policies Tabs
 *
 * @alias ClickPoliciesTabs
 */

export type ClickPoliciesTabs = {
  /**
   * Tab type
   */
  tab: ClickPoliciesTab
}
