import { SimulationLead } from '../../../domain/models'
import { Dispatcher, Service } from '../../protocols'

export type ISimulationLeadService = Service<SimulationLead>

/**
 * Lead simulation
 *
 * @category Simulation
 */
export class SimulationLeadService implements ISimulationLeadService {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   * @param {SimulationLead} data
   */
  execute(data: SimulationLead): void {
    this.dispatcher.execute('lead_simulation', data)
  }
}
