import React from 'react'

import { useMediaQuery } from '@azos/shared'
import { MODAL_DELAY } from '@azos/shared/src/components/Modal/withModal'
import { Badge, Text } from '@azos/shared/src/components/v2'
import { DashboardSliderCardsRedirectPoliciesType } from '@data/models'
import { TextPolicyStatus } from '@domain/models'
import { PolicySelectViewType } from '@presentation/components/organisms/PolicyViewManagement'
import { usePolicyPaymentStatus } from '@presentation/hooks/usePolicyPaymentStatus'
import { useMyPolicies } from '@presentation/providers/MyPoliciesProvider'
import { withPolicyPaymentStatus } from '@presentation/providers/PolicyProvider'
import { formatPresentationPolicy } from '@utils/policies'
import { useRouter } from 'next/router'

import {
  PolicyCardCancel,
  CardPolicyDetails,
  PolicyCardPayment,
} from '../PolicyCard/components'
import { CardPolicyCreditCard } from '../PolicyEditPayment/components'
import { PolicyListSelectProps } from './PolicyListSelect.props'
import { Root } from './PolicyListSelect.styles'

const PolicyListSelect: React.VFC<PolicyListSelectProps> = ({
  title,
  subtitle,
  policiesList,
  viewType,
  onClose,
}) => {
  const { isMobile } = useMediaQuery()
  const router = useRouter()
  const { isPaymentChangeSucceeded, setIsPaymentChangeSucceeded } =
    usePolicyPaymentStatus()

  const [policies, setPolicies] = React.useState<
    DashboardSliderCardsRedirectPoliciesType[]
  >([])
  const { policies: policiesData, refreshPolicies } = useMyPolicies()

  const [selectedPolicyId, setSelectedPolicyId] = React.useState('')
  const [isPolicyDetailsOpen, setIsPolicyDetailsOpen] = React.useState(false)
  const [isPolicyCancelOpen, setIsPolicyCancelOpen] = React.useState(false)
  const [isPolicyPaymentOpen, setIsPolicyPaymentOpen] = React.useState(false)
  const [modalCreditCard, setModalCreditCard] = React.useState(false)

  const handlePolicyList = React.useCallback(() => {
    if (policiesList) {
      setPolicies(policiesList)
    }

    if (!policiesList && policiesData.length > 0) {
      const inactivePoliciesStatus = [
        TextPolicyStatus.canceled,
        TextPolicyStatus.expired,
        TextPolicyStatus.pre_canceled,
      ]
      const policiesFilteredByActive = policiesData
        .filter(
          policy =>
            !inactivePoliciesStatus.includes(
              policy.status[0].label as TextPolicyStatus,
            ),
        )
        .map(policy => formatPresentationPolicy(policy))
      setPolicies(policiesFilteredByActive)
    }
  }, [policiesList, policiesData])

  React.useEffect(() => {
    if (isPaymentChangeSucceeded) {
      onClose()
      setIsPaymentChangeSucceeded(false)
      refreshPolicies()
    }
  }, [
    isPaymentChangeSucceeded,
    onClose,
    setIsPaymentChangeSucceeded,
    refreshPolicies,
  ])

  React.useEffect(() => {
    handlePolicyList()
  }, [handlePolicyList])

  const handleSelect = ({
    policyId,
  }: DashboardSliderCardsRedirectPoliciesType) => {
    router.push(`minhas-apolices/detalhes/${policyId}`)
  }

  React.useEffect(() => {
    if (policies.length === 1 && !selectedPolicyId) {
      handleSelect(policies[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policies])

  const onCloseModal = () => {
    setTimeout(() => setSelectedPolicyId(''), MODAL_DELAY)
    if (policies.length === 1) {
      onClose()
    }
  }

  const getTitleByViewType = React.useCallback(() => {
    const titleCases = {
      [PolicySelectViewType.beneficiaries]: 'Definir beneficiários',
      [PolicySelectViewType.details]: 'Visualizar detalhes',
      [PolicySelectViewType.payment]: 'Ver pagamentos',
      [PolicySelectViewType.change_payment]: 'Selecione uma apólice',
    }

    return titleCases[viewType]
  }, [viewType])

  const containerTitle = React.useMemo(
    () => (title ? title : getTitleByViewType()),
    [title, getTitleByViewType],
  )

  return (
    <>
      {selectedPolicyId ? (
        <>
          <CardPolicyDetails
            policyId={selectedPolicyId}
            open={isPolicyDetailsOpen}
            onClose={() => {
              setIsPolicyDetailsOpen(false)
              onCloseModal()
            }}
            onCancel={() => {
              setIsPolicyDetailsOpen(false)
              setIsPolicyCancelOpen(true)
            }}
          />
          <PolicyCardPayment
            policyId={selectedPolicyId}
            open={isPolicyPaymentOpen}
            onClose={() => {
              setIsPolicyPaymentOpen(false)
              onCloseModal()
            }}
          />
          <PolicyCardCancel
            isMobile={isMobile}
            policyId={selectedPolicyId}
            open={isPolicyCancelOpen}
            onClose={() => {
              setIsPolicyCancelOpen(false)
              onCloseModal()
            }}
          />
          <CardPolicyCreditCard
            open={modalCreditCard}
            onClose={() => {
              setModalCreditCard(false)
              onCloseModal()
            }}
            id={selectedPolicyId}
          />
        </>
      ) : (
        <Root>
          <Text variant="h5" className="policy-list-select-container__title">
            {containerTitle}
          </Text>
          <Text
            className="policy-list-select-container__subtitle"
            variant="body3"
          >
            {subtitle || 'Escolha qual apólice deseja visualizar.'}
          </Text>
          <div className="policy-list-select-container__policy-list">
            {policies.map(policy => (
              <div
                onClick={() => handleSelect(policy)}
                key={policy.policyId}
                className="policy-list-select-container__policy-list--item"
              >
                <div className="policy-list-select-container__text-content">
                  <Text variant="body2">{policy.valueLabel}</Text>
                  <div className="policy-list-select-container__label-content">
                    <Badge
                      color={policy.badgeColor}
                      className="policy-list-select-container__label-content--badge"
                    >
                      {policy.badgeLabel}
                    </Badge>
                    <button type="button">
                      <span className="icon-chevron-right" />
                    </button>
                  </div>
                </div>

                <Text
                  className="policy-list-select-container__coverages-text"
                  variant="caption"
                >
                  {policy.coveragesLabel}
                </Text>
              </div>
            ))}
          </div>
        </Root>
      )}
    </>
  )
}

export default withPolicyPaymentStatus(PolicyListSelect)
