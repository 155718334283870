import { IUsersRepository } from '../../../../data/repositories'
import { UserProfile } from '../../../../domain'

export class FakeUsersRepository implements IUsersRepository {
  params: any
  output: any

  async loadUserProfile(): Promise<UserProfile> {
    return this.output
  }
}
