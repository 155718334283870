import React from 'react'

import analytics from '@azos/analytics'
import { Utils as ClientsUtils } from '@azos/core'
import { EmptyState, Spinner } from '@azos/shared'
import { Button } from '@azos/shared/src/components/v2'
import { WizardTutorialTag } from '@data/models'
import { PolicyCancellationStatus } from '@domain/models'
import { useFetchPolicyDetails } from '@presentation/hooks/useFetchPolictDetails'
import { useOnboardingWizard } from '@presentation/providers/OnboardingWizardProvider'

import { BeneficiariesCard } from '../../Beneficiaries'
import CardDownloadDocument from '../../CardDownloadDocument'
import { PolicyDetailsProps } from './PolicyDetails.props'
import { Root } from './PolicyDetails.styles'

const CardPolicyDetails: React.VFC<PolicyDetailsProps> = ({
  policyId,
  //
  onCancel,
}) => {
  const {
    data: policyDetails,
    isLoading,
    isError,
  } = useFetchPolicyDetails(policyId)
  const { wizard, updateSummary } = useOnboardingWizard()

  const handleCancel = () => {
    onCancel()
    analytics.dashboard.policy.cancel.cancelPolicy.execute()
  }

  const isPreCancel = React.useMemo<boolean>(
    () =>
      policyDetails?.policy.cancellationStatus ===
      PolicyCancellationStatus.pre_canceled,
    [policyDetails?.policy.cancellationStatus],
  )
  const hasDeathCoverage = React.useMemo(
    () =>
      policyDetails?.policy?.coverages.some(item =>
        ClientsUtils.coverages.isDeathCoverage(item.code),
      ),
    [policyDetails?.policy?.coverages],
  )

  const lifeInsuranceCoverage = React.useMemo(() => {
    return (
      policyDetails?.policy.coverages.find(item =>
        ClientsUtils.coverages.isDeathCoverage(item.code),
      )?.insuredCapitalValue || 0
    )
  }, [policyDetails?.policy.coverages])

  React.useEffect(() => {
    if (policyDetails) {
      analytics.dashboard.policyCardHomepage.execute({
        type: {
          active: String(!!policyDetails?.policy?.status[0].label),
          proposal: String(policyDetails?.policy?.proposalId),
          status: String(policyDetails?.policy?.status[0].label),
          isPolice: 'true',
        },
      })
    }
  }, [policyDetails])

  const policyActionWasExecuted = React.useMemo(() => {
    return wizard?.tutorial.find(item => item.tag === WizardTutorialTag.policy)
      ?.mainAction?.actions[0].executed
  }, [wizard])

  const handleDownloadPolicyPdf = () => {
    if (!policyActionWasExecuted) {
      updateSummary({
        entity: WizardTutorialTag.policy,
        action: 'action',
      })
    }
    window.open(`${policyDetails?.policy.policyUrl}`, '_blank')
    analytics.dashboard.policy.downloadPolicy.execute()
  }

  return (
    <Root $isLoadingOrError={isLoading || isError}>
      {isError && (
        <div className="empty-state-wrapper">
          <EmptyState
            align="center"
            icon="icon-policy-cancel"
            iconColor="error"
            title="Erro ao carregar o recurso"
          >
            Por favor, tente novamente ou entre em contato com nosso suporte.
          </EmptyState>
        </div>
      )}

      {isLoading && (
        <div className="empty-state-wrapper">
          <EmptyState
            align="center"
            icon={<Spinner />}
            title="Carregando apólice"
          >
            Estamos carregando as informações da sua apólice.
          </EmptyState>
        </div>
      )}

      {!isError && !isLoading && (
        <>
          {hasDeathCoverage && (
            <BeneficiariesCard
              id={policyId}
              beneficiaries={policyDetails?.beneficiaries || []}
              lifeInsuranceCoverage={lifeInsuranceCoverage}
            />
          )}

          <div className="downloads">
            <CardDownloadDocument
              title="Proposta"
              button={{
                label: 'Baixar proposta',
                onClick: () => {
                  window.open(`${policyDetails?.policy.proposalUrl}`, '_blank')
                  analytics.dashboard.policies.downloadProposal.execute()
                },
              }}
            >
              Faça download da sua proposta
            </CardDownloadDocument>

            <CardDownloadDocument
              title="Apólice"
              button={{
                label: 'Baixar apólice',
                onClick: handleDownloadPolicyPdf,
              }}
            >
              Faça download da sua apólice
            </CardDownloadDocument>

            {policyDetails?.policy.generalConditionUrl && (
              <CardDownloadDocument
                title="Condições Gerais"
                button={{
                  label: 'Baixar condições gerais',
                  onClick: () =>
                    window.open(
                      `${policyDetails?.policy.generalConditionUrl}`,
                      '_blank',
                    ),
                }}
              >
                Faça download das condições gerais
              </CardDownloadDocument>
            )}
          </div>

          <div className="cancel-policy">
            <h4>Cancelar apólice</h4>

            <Button
              disabled={isPreCancel}
              variant="outline"
              color="inherit"
              title="Cancelar"
              onClick={handleCancel}
            >
              {isPreCancel ? 'Em cancelamento' : 'Cancelar'}
            </Button>
          </div>
        </>
      )}
    </Root>
  )
}

export default CardPolicyDetails
