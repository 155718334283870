import React from 'react'

import { PolicyPaymentStatusContext } from '@presentation/providers/PolicyProvider'

const usePolicyPaymentStatus = () => {
  const context = React.useContext(PolicyPaymentStatusContext)

  if (!context) {
    throw new Error(
      'usePolicyPaymentStatus must be used within a PolicyPaymentStatusProvider',
    )
  }

  return context
}

export { usePolicyPaymentStatus }
