import {
  DPSAnswerViewModel,
  DPSAvailableQuestionsViewModel,
  DPSQuestionViewModel,
} from '../../../../data/models'
import { SyncServices } from '../../../protocols/service'

type Request = DPSAnswerViewModel[]
type Response = DPSAvailableQuestionsViewModel

export type ILoadQuestionsByAnswersService = SyncServices<Request, Response>

export class LoadQuestionsByAnswersService
  implements ILoadQuestionsByAnswersService
{
  private readonly allRequiredIds: string[]

  constructor(private readonly questions: DPSQuestionViewModel[]) {
    this.allRequiredIds = this.questions
      .filter(
        item =>
          !item.trigger || this.filterArrayByNull(item.trigger).length === 0,
      )
      .map(item => item.questionId)
  }

  execute(answers: Request): Response {
    const findNextQuestions = (questionId: string): string[] => {
      return answers
        .filter(answer => answer.questionId === questionId)
        .reduce((acc: DPSQuestionViewModel[], answer: DPSAnswerViewModel) => {
          const questions = this.questions
            .filter(item =>
              (item?.trigger || []).some(
                dep => dep?.questionId === answer.questionId,
              ),
            )
            .filter(item =>
              (item?.trigger || [])?.some(dep => {
                if (dep?.choices) {
                  if (dep.strict) {
                    return (
                      dep.choices.sort().join('') ===
                      answer.answersIds.value.sort().join('')
                    )
                  } else {
                    return dep?.choices.filter(choiceId =>
                      answer.answersIds?.value?.includes(choiceId),
                    )?.length
                  }
                }

                if (dep?.groups && dep?.groups.length > 0) {
                  if (dep.strict) {
                    return (
                      dep?.groups.sort().join('') ===
                      (answer.answersIds?.groups || []).sort().join('')
                    )
                  } else {
                    return dep?.groups.filter(groupId =>
                      answer.answersIds?.groups?.includes(groupId),
                    )?.length
                  }
                }

                return false
              }),
            )

          return [...acc, ...questions]
        }, [])
        .reduce((ids: string[], question: DPSQuestionViewModel) => {
          const nextIds = [
            question.questionId,
            ...findNextQuestions(question.questionId),
          ]
          return [...ids, ...nextIds]
        }, [])
    }

    const finalQuestionIds = this.allRequiredIds.reduce(
      (acc: string[], questionId: string) => {
        return [...acc, questionId, ...findNextQuestions(questionId)]
      },
      [],
    )

    return this.formatResult(finalQuestionIds, finalQuestionIds.length)
  }

  private filterArrayByNull<T>(array: T[]): T[] {
    return array.filter(item => item !== null)
  }

  private formatResult(
    questionIds: string[],
    total = 0,
  ): DPSAvailableQuestionsViewModel {
    const questions = questionIds
      .sort(this.order.bind(this))
      .map(this.getQuestionById.bind(this))
    return {
      questions,
      total,
    }
  }

  private getQuestionById(questionId: string): DPSQuestionViewModel {
    return this.questions.find(item => item.questionId === questionId)!
  }

  private order(questionId: string): number {
    return this.questions.findIndex(item => item.questionId === questionId)
  }
}
