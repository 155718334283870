import React from 'react'

import { Input } from '../Input'
import { InputMaskProps } from './InputMask.props'

const InputMask = ({
  children,
  value = '',
  error = false,
  helperText = '',
  parentName,
  innerRef,
  ...rest
}: InputMaskProps) => {
  return (
    <Input
      innerRef={innerRef}
      parentName={parentName}
      error={error}
      helperText={helperText ?? undefined}
      value={value}
      {...rest}
    />
  )
}

export default InputMask
