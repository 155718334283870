import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled('div')(
  ({ theme }) => css`
    margin: 0 -16px -16px -24px;
    padding: ${theme.spacing[3]}px ${theme.spacing[3]}px 0 ${theme.spacing[3]}px;

    .button-modal {
      cursor: pointer;

      &__items {
        display: flex;
        align-items: center;
        margin-bottom: ${theme.spacing[3]}px;
        padding: ${theme.spacing[3]}px 0 ${theme.spacing[3]}px
          ${theme.spacing[3]}px;
        width: 100%;
        height: ${theme.spacing[7]}px;
        background-color: ${theme.palette.neutral[100]};
        border-radius: ${theme.spacing[2]}px;
        justify-content: space-between;

        &__label {
          display: flex;
          align-items: center;
        }

        span {
          padding-right: 14px;
          font-size: 1.5rem;
          color: ${theme.palette.neutral[500]};
        }

        &__label-text {
          color: ${theme.palette.neutral[500]};
          font-weight: ${theme.typography.fontWeightBold};
        }
      }
    }
  `,
)

export default Root
