import { BoxProps } from '@Azos-Seguros/apollo'
import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled('nav')<BoxProps>(
  ({ theme }) => css`
    position: fixed;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: ${theme.zIndex.header};

    @media (min-width: 600px) {
      display: none;
    }

    button {
      transform: translate(-50%, -50%);
      position: relative;
      width: 56px;
      height: 56px;
      left: 50%;
      top: 16px;
      background-color: ${theme.palette.neutral[500]};
      border: none;
      padding: ${theme.spacing[3]}px;
      border-radius: ${theme.spacing[3]}px;
      box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
      filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.3));

      span {
        color: ${theme.palette.neutral[50]};
      }
    }

    ul {
      height: 82px;
      display: flex;
      list-style: none;
      align-items: center;
      justify-content: space-between;
      gap: ${theme.spacing[3]}px;
      margin: 0;
      padding: 12px 0;
      background: ${theme.palette.neutral[50]};
    }

    li {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 120px;
      height: 42px;
      border-radius: 42px;
      font-size: 10px;
      font-weight: ${theme.typography.fontWeightRegular};
      color: ${theme.palette.neutral[400]};
      transition: padding 0.3s, background-color 0.3s, color 0.3s;

      span {
        margin-bottom: 10px;
      }

      &.selected {
        display: flex;
        align-items: center;
        font-size: 10px;
        font-weight: 700;
        color: ${theme.palette.neutral[500]};

        span {
          color: ${theme.palette.primary.main};
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          background: ${theme.palette.primary[50]};
          width: 56px;
          padding: 8px 18px;
          margin-bottom: ${theme.spacing[1]}px;
          height: 32px;
          border-radius: 20px;
          font-size: 24px;
        }
      }

      span {
        font-size: 24px;
        color: ${theme.palette.neutral[400]};
        transition: margin 0.2s, color 0.3s;
      }
    }
  `,
)
