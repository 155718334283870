import React from 'react'

import { SvgIconProps } from './SvgIcon.props'
import { Root } from './SvgIcon.styles'

const SvgIcon: React.FCC<SvgIconProps> = ({ children, ...rest }) => {
  return <Root {...rest}>{children}</Root>
}

export default SvgIcon
