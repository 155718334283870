import React from 'react'

import { PRIVACY_POLICY_LINK, SERVICE_TERMS_LINK } from '@azos/core'
import { Modal, useMediaQuery } from '@azos/shared'
import { Button, ButtonSelect, Text } from '@azos/shared/src/components/v2'

import { TransparencyPortalModalProps } from './TransparencyPortalModal.props'
import { Root } from './TransparencyPortalModal.styles'

const TransparencyPortalModal: React.FCC<TransparencyPortalModalProps> = ({
  onClose,
  ...props
}) => {
  const { isMobile } = useMediaQuery()

  return (
    <Modal
      {...props}
      onClose={onClose}
      variant={isMobile ? 'bottomDrawer' : 'modal'}
      maxWidth={isMobile ? undefined : '480px'}
      showCloseButton={false}
      showHeader={false}
    >
      <Root>
        <div className="transparency-portal-modal__title">
          <Text
            className="transparency-portal-modal__title-content"
            variant="bodyBold"
          >
            Portal da transparência
          </Text>
          <i className="icon-exit" onClick={onClose} />
        </div>
        <Text
          variant="body2"
          className="transparency-portal-modal__description"
        >
          Entenda a nossa política de privacidade de dados e nossos termos e
          condições de uso:
        </Text>

        <div className="transparency-portal-modal__content">
          <ButtonSelect
            title="Termos de Uso"
            startIcon={<i className=" icon-paste" />}
            className="transparency-portal-modal__button-select"
            onClick={() => window.open(SERVICE_TERMS_LINK, '_blank')}
          />
          <ButtonSelect
            title="Política de Privacidade"
            startIcon={<i className="icon-padlock" />}
            className="transparency-portal-modal__button-select"
            onClick={() => window.open(PRIVACY_POLICY_LINK, '_blank')}
          />
          <ButtonSelect
            title="Condições gerais"
            startIcon={<i className="icon-contract" />}
            className="transparency-portal-modal__button-select"
            onClick={() =>
              window.open('https://www.azos.com.br/condicoes-gerais', '_blank')
            }
          />
        </div>

        <Button
          onClick={onClose}
          fullWidth
          className="transparency-portal-modal__button"
        >
          Fechar
        </Button>
      </Root>
    </Modal>
  )
}

export default TransparencyPortalModal
