import { Registration } from '../../../domain/models'
import { Dispatcher, Service } from '../../protocols'

export type IRegistrationService = Service<Registration>

/**
 * Registration
 *
 * @category Auth
 */
export class RegistrationService implements IRegistrationService {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @param {Registration} data
   * @function
   */
  execute(data: Registration): void {
    this.dispatcher.execute('registration', data)
  }
}
