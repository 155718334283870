import { CheckoutServiceSource } from '../../../data/models'
import { CheckoutProvider } from '../../../data/protocols'
import { IuguProvider } from '../../../infra/checkout/iugu-provider'
import { VindiProvider } from '../../../infra/checkout/vindi-provider'
import { env } from '../../../main/config'
import { FakeCheckoutProvider } from '../../../tests/fakes'
import { IuguConfig, iuguConfig } from '../../config/iugu'
import { vindiConfig } from '../../config/vindi'

export type ICheckoutProviderFactory = (
  source?: CheckoutServiceSource,
) => CheckoutProvider

export const makeCheckoutProvider: ICheckoutProviderFactory = (
  source?: CheckoutServiceSource,
) => {
  if (env.isTest) return new FakeCheckoutProvider()

  if (source === CheckoutServiceSource.VINDI) {
    return makeVindiCheckoutProvider()
  }

  return makeIuguCheckoutProvider()
}

export const makeIuguCheckoutProvider = (config: IuguConfig = iuguConfig) =>
  new IuguProvider(config.url, config.id, config.testMode)

export const makeVindiCheckoutProvider = () =>
  new VindiProvider(vindiConfig.url, vindiConfig.token)
