import { PaymentMethod } from '@azos/core'

export enum BillingServiceSource {
  IUGU = 'IUGU',
  VINDI = 'VINDI',
}

export type PaymentInfoResponse = {
  displayName: string
  displayNumber: string
  year: number
  month: number
  brand: string
}

export type PolicyDetailsPaymentResponse = {
  paymentMethod: PaymentMethod
  paymentDescription: string
  paymentInfo?: PaymentInfoResponse
}

export type ProposalDetailsPaymentsResponse = {
  paymentMethod: PaymentMethod
  paymentDescription: string
  paymentInfo?: PaymentInfoResponse
}

export type GetPaymentTokenResponse = {
  id: string
  method: string
  extra_info: {
    bin: string
    year: number
    month: number
    brand: string
    holder_name: string
    display_number: string
  }
  test: boolean
}

export type GetDueDateListResponse = {
  current: string[]
  next: string[]
  periods: string[]
  periodsFormatted: string[]
}
