import { resolve } from 'dns'

type GAProps = {
  clientId: string
}

export const setupGA = (timer = 2000, isDev: boolean): Promise<GAProps> => {
  return new Promise((resolve, reject) => {
    let checkGaInterval: NodeJS.Timeout | number | null

    const startGA = () => {
      const gaId = document.cookie.match(/_ga=(.+?);/) || []
      let clientId = gaId?.[1]?.split('.')?.slice(-2)?.join('.')

      if (isDev && !clientId) {
        clientId = String(Math.floor(Math.random() * 1000) + 1)
        console.log('ClientID Generated: ' + clientId)
      }

      if (clientId) {
        if (checkGaInterval) {
          clearInterval(checkGaInterval)
          checkGaInterval = null
        }
        resolve({ clientId })
      }
    }

    checkGaInterval = setInterval(startGA, 100)

    setTimeout(() => {
      if (checkGaInterval) {
        clearInterval(checkGaInterval)
        reject()
      }
    }, timer)
  })
}
