import React from 'react'

import { Button } from '@azos/shared/src/components/v2'
import { Typography } from '@mui/material'

import { SidebarProps } from './Sidebar.props'
import { Root } from './Sidebar.styles'

const Sidebar: React.VFC<SidebarProps> = ({ value, items, onChange }) => {
  const handleOnClick = (onClick?: () => void) => (menu: string) => {
    if (!!onClick) {
      onClick()
    } else {
      onChange(menu)
    }
  }

  return (
    <Root>
      <nav>
        {items.map(({ label, subItems }, index) => (
          <div key={index}>
            {label && <Typography variant="h4">{label}</Typography>}
            <ul>
              {subItems.map(item => (
                <li key={item.value}>
                  <Button
                    className={`${item.value === value ? 'active' : ''}`}
                    onClick={() => handleOnClick(item.onClick)(item.value)}
                  >
                    {item.icon && <span className={item.icon} />}
                    {item.label}
                  </Button>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </nav>
    </Root>
  )
}

export default Sidebar
