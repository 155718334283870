import { Tooltip as ApolloTooltip } from '@Azos-Seguros/apollo'

import { css, styled } from '../../../styles'
import { Utils } from '../../../utils'

export const Tooltip = styled(ApolloTooltip)(
  () => css`
    width: 80vw;
    max-width: 400px;
    padding: 12px 16px;
  `,
)

export const Root = styled(
  'button',
  Utils.transient.transientStyleProps,
)<any>(
  ({ theme, $showMessage }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    text-align: center;

    background: #fff;
    cursor: pointer;
    border: 1px solid #d0d4dc;
    border-radius: 8px;
    padding: 18px 28px;
    position: relative;

    min-width: 170px;
    width: 100%;

    .card_copy_text {
      &__text-content {
        display: flex;
        align-items: center;
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;

        i {
          font-size: 25px;
          width: 25px;
          margin-right: 12px;
          margin-left: 0;
        }
      }

      &__success {
        position: absolute;
        bottom: -28px;
        left: calc(50% - 83px);
        background: #323232;
        color: #fff;
        padding: 6px 14px;
        border-radius: 0 0 8px 8px;
        z-index: 20;
        font-size: 12px;
        transform: ${$showMessage ? 'translateY(0px)' : 'translateY(-28px)'};
        transition: transform 0.3s;
      }

      &__value {
        flex: 1;
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;

        @media (max-width: 600px) {
          /* TODO: Eliminar scroll horizontal */
          max-width: 50vw;
        }
      }
    }

    > [class^='icon-'] {
      font-size: 25px;
      color: ${theme.palette.primary.main};
      margin-left: 16px;
      width: 25px;
    }
  `,
)
