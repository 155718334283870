import { Dispatcher, Service } from '../../protocols'

export type IClickInsertDueDateService = Service<void>

/**
 * Click Insert Due Date
 *
 * @category Checkout
 */
export class ClickInsertDueDateService implements IClickInsertDueDateService {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   */
  execute(): void {
    this.dispatcher.execute(
      'Clicou para inserir data de vencimento no fluxo de contratação',
    )
  }
}
