import React from 'react'

import { Collapse, useMediaQuery, useTheme } from '@mui/material'

import { Utils } from '../../../utils'
import { CurrencySlider } from '../../CurrencySlider'
import { Switch } from '../../Switch'
import { Text } from '../../Text'
import { CardProductProps } from './CardProduct.props'
import {
  ActiveButton,
  Content,
  Description,
  Header,
  Main,
  Root,
} from './CardProduct.styles'

const CardProduct: React.FCC<CardProductProps> = ({
  children,
  parentName,
  //
  name,
  description,
  active = true,
  disabled = false,
  editable = false,
  source,
  value,
  shouldRefuse,
  //
  onChange,
  onChangeCommitted,
  onEnable,
  //
  cardProps,
}) => {
  const theme = useTheme()
  const hiddenMdDown = useMediaQuery(theme.breakpoints.down('md'))

  const range = React.useMemo<{ min: number; max: number }>(() => {
    const min = source[0].premium
    const max = source[source.length - 1].premium
    return { min, max }
  }, [source])

  const selectedValue = React.useMemo<number>(() => value.premium, [value])
  const getPremium = Utils.number.getNearestValue(source.map(i => i.premium))

  const handleChange = React.useCallback(
    (value: number) => {
      const selectedPremium = getPremium(value)
      const selected = source.find(item => item.premium === selectedPremium)
      if (selected) {
        onChange(selected)
      }
    },
    [getPremium, onChange, source],
  )

  const handleChangeCommitted = React.useCallback(
    (value: number) => {
      const selected = source.find(item => item.premium === value)
      if (selected) {
        onChangeCommitted?.(selected)
      }
    },
    [onChangeCommitted, source],
  )

  return (
    <Root elevation={0} {...cardProps}>
      <Main>
        <Header
          onClick={() => (disabled || shouldRefuse ? {} : onEnable(!active))}
        >
          <Text className="card-product__name" variant="h5" component="strong">
            {name}
          </Text>
          <ActiveButton>
            <Text variant="caption" component="span">
              Incluir no plano
            </Text>
            <Switch
              parentName={parentName}
              checked={active}
              disabled={disabled || shouldRefuse}
              onChange={() => {}}
            />
          </ActiveButton>
        </Header>
        {shouldRefuse && (
          <Description>
            Essa cobertura não está habilitada para você.
          </Description>
        )}
        {!hiddenMdDown && !shouldRefuse && (
          <Description>{description}</Description>
        )}
      </Main>
      <Collapse in={active} timeout="auto" unmountOnExit>
        <Content data-impression="true">
          <CurrencySlider
            parentName={parentName}
            range={range}
            value={selectedValue}
            disabled={disabled || shouldRefuse}
            editable={editable}
            onChange={handleChange}
            onChangeCommitted={handleChangeCommitted}
          />
          <div className="card-product__content--container">{children}</div>
        </Content>
      </Collapse>
    </Root>
  )
}

export default React.memo(CardProduct)
