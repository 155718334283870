import { ActionsReduceValue } from '../../../../../domain/models'
import { Dispatcher, Service } from '../../../../protocols'

export type IActionsReduceValueService = Service<ActionsReduceValue>

/**
 * Actions Reduce Value
 *
 * @category Dashboard
 */
export class ActionsReduceValueService implements IActionsReduceValueService {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @param {ActionsReduceValue} data
   * @function
   */
  execute(data: ActionsReduceValue): void {
    this.dispatcher.execute(
      'Area logada web - Clique nas ações da drawer de redução de cobertura',
      data,
    )
  }
}
