import { css, Utils } from '@azos/shared'
import styled from '@emotion/styled'

type InfoCardIconStyleProps = {
  $iconColor: string
  $iconBackgroundColor: string
}

export const Root = styled(
  'div',
  Utils.transient.transientStyleProps,
)<InfoCardIconStyleProps>(
  ({ theme, $iconColor, $iconBackgroundColor }) => css`
    .info-card-icon {
      &__info {
        &--icon {
          i {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            display: grid;
            place-items: center;
            background: ${$iconBackgroundColor};
            font-size: 24px;
            color: ${$iconColor};
          }
        }
      }

      &__invoice {
        &--background {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          display: grid;
          place-items: center;
          background: ${$iconBackgroundColor};
        }

        &--wrapper {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          display: grid;
          place-items: center;
          background: ${$iconColor};
          color: ${theme.palette.neutral[50]};
          font-size: 1rem;
          font-weight: ${theme.typography.fontWeightBold};
        }
      }

      &__onboarding {
        i {
          color: ${$iconColor};
        }
      }
    }
  `,
)

export default Root
