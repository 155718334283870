import React from 'react'
import Lottie from 'react-lottie-player'

import creditCardError from '../assets/lottie/creditcard_error.json'
import creditCardTimeout from '../assets/lottie/creditcard_genericError.json'
import creditCardProcessing from '../assets/lottie/creditcard_processing.json'
import creditCardPaid from '../assets/lottie/creditcard_success.json'
import pixError from '../assets/lottie/pix_error.json'
import pixProcessing from '../assets/lottie/pix_processing.json'
import pixPaid from '../assets/lottie/pix_success.json'

export const CreditCardPaid = (props: any) => (
  <Lottie play loop={false} animationData={creditCardPaid} {...props} />
)

export const CreditCardError = (props: any) => (
  <Lottie play loop={false} animationData={creditCardError} {...props} />
)

export const CreditCardProcessing = (props: any) => (
  <Lottie play loop={true} animationData={creditCardProcessing} {...props} />
)

export const CreditCardTimeout = (props: any) => (
  <Lottie play loop={false} animationData={creditCardTimeout} {...props} />
)

export const CreditCardLoading = (props: any) => (
  <Lottie play loop={false} animationData={creditCardProcessing} {...props} />
)

export const PixPaid = (props: any) => (
  <Lottie play loop={false} animationData={pixPaid} {...props} />
)

export const PixError = (props: any) => (
  <Lottie play loop={false} animationData={pixError} {...props} />
)

export const PixProcessing = (props: any) => (
  <Lottie play loop={false} animationData={pixProcessing} {...props} />
)
