import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled('div')(
  ({ theme }) => css`
    background: ${theme.palette.neutral[50]};
    padding: 24px;

    .content {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .heading {
        .title {
          margin: 0 0 8px;
          padding: 0;
          font-size: 20px;
          font-weight: 600;
        }
      }

      > span {
        margin-left: 16px !important;
      }
    }

    > button {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0;
      background: transparent;
      margin-top: 12px;
      padding: 12px 0 0;
      font-size: 16px;
      font-weight: 600;

      > span {
        margin-right: 12px;
      }
    }
  `,
)
