import { styled, css } from '../../styles'

export const Root = styled('div')(
  ({ theme }) => css`
    .info-container {
      &__topics {
        list-style: none;
        margin-top: 34px;
        padding: 0;

        & > li + li {
          margin-top: ${theme.spacing(3)};
        }
      }

      &__topic-item {
        display: flex;
        flex-direction: row;

        &:not(:last-of-type) {
          & > .info-container__topic-text {
            border-bottom: solid 1px ${theme.palette.grey[200]};
          }
        }
      }

      &__topic-text {
        padding-bottom: 1rem;
        width: 100%;
        margin-left: ${theme.spacing(3)};
      }
    }
  `,
)
