import { Services } from '@azos/core'
import { IGuardiansRepository } from '@data/repositories'
import { LoadGuardiansUseCase } from '@domain/usecases'

type Params = void
type Response = LoadGuardiansUseCase.Response

export type ILoadGuardiansService = Services<Params, Response>

export class LoadGuardiansService implements ILoadGuardiansService {
  constructor(private readonly guardiansRepository: IGuardiansRepository) {}

  async execute(): Promise<Response> {
    return await this.guardiansRepository.loadGuardians()
  }
}
