import React from 'react'

import { Utils } from '@azos/shared'

import { SliderProps } from './Slider.props'
import { Root } from './Slider.styles'

const Slider = ({
  parentName,
  //
  min,
  max,
  marks,
  step,
  //
  ...rest
}: SliderProps) => {
  return (
    <Root
      data-action={Utils.dataNames.renderDataName('slider', parentName)}
      min={min}
      max={max}
      marks={marks}
      step={step}
      valueLabelDisplay="auto"
      {...rest}
    />
  )
}

export default Slider
