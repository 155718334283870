import {
  RadioProps,
  Typography as TypographyBase,
  TypographyProps,
} from '@Azos-Seguros/apollo'

import { css, styled } from '../../../../../styles'
import { Utils } from '../../../../../utils'

type InputProps = RadioProps & {
  $showInput?: boolean
}

type ButtonProps = {
  $showInput?: boolean
}

export const Button = styled(
  'button',
  Utils.transient.transientStyleProps,
)<ButtonProps>(
  ({ theme, $showInput }) => css`
  display: ${$showInput ? 'flex' : 'inline-flex'};
  justify-content: ${$showInput ? 'space-between' : 'center'};
  align-items: center;

  background-color: ${theme.palette.neutral[100]};
  border-color: ${theme.palette.neutral[50]};
  border-style: solid;
  border-width: 1px;

  color: ${theme.palette.neutral[500]};
  padding: 12px ${theme.spacing[4]}px;
  width: 100%;,
  height: 100%;
  max-height: 48px;
  
  cursor: pointer;

  transition: border-color background-color 0.2s, color 0.2s;

  &:disabled {
    color: ${theme.palette.neutral[300]};
  }

  &:focus {
    outline: 2px solid ${theme.palette.status.focused.main};
  }

  &:hover {
    border-color: ${theme.palette.neutral[500]};
  }

  & > div:last-of-type > label {
    display: ${$showInput ? 'inline-block' : 'none'};
    visibility: ${$showInput ? 'visible' : 'hidden'};
    padding: 0;
    margin-left: 16px;
  }

  &.checked, &:disabled.checked {
    border-width: 2px;
    border-color: ${theme.palette.primary[600]};
    background-color: ${theme.palette.primary[50]};
    color: ${theme.palette.neutral[500]};
  }


  & > .text-content {
    font-family: 'Dazzed', sans-serif;
    font-size: 0.875rem;
    font-weight: ${theme.typography.fontWeightBold};
    margin: 0;
  }
`,
)

export const Typography = styled(TypographyBase)<
  TypographyProps & { component: string }
>(
  ({ theme }) => css`
    font-weight: ${theme.typography.fontWeightBold};
  `,
)
