import { SimulationSubmit } from '../../../domain/models'
import { Dispatcher, Service } from '../../protocols'

export type ISimulationSubmitService = Service<SimulationSubmit>

/**
 * Submit simulation
 *
 * @category Simulation
 */
export class SimulationSubmitService implements ISimulationSubmitService {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   * @param {SimulationSubmit} data
   */
  execute(data: SimulationSubmit): void {
    this.dispatcher.execute('submit_simulation', data)
  }
}
