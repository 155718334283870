import { ViewLifeStyleContent } from '../../../../../domain/models'
import { Dispatcher, Service } from '../../../../protocols'

export type IUpsellViewLifeStyleContentService = Service<ViewLifeStyleContent>

/**
 * View Life Style Content
 *
 * @category Upsell
 */
export class UpsellViewLifeStyleContentService
  implements IUpsellViewLifeStyleContentService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   * @param {ViewLifeStyleContent} data
   */
  execute({ questionNumber }: ViewLifeStyleContent): void {
    this.dispatcher.execute(
      `Upsell - Visualização da tela "DPS - Estilo de vida ${questionNumber}"`,
    )
  }
}
