import React from 'react'
import { useSpring, animated } from 'react-spring'

import { ProgressBarProps } from './ProgressBar.props'
import { Root, FILL_CONFIG, FILL_TRANSITION } from './ProgressBar.styles'

const ProgressBar: React.VFC<ProgressBarProps> = ({
  percentage,
  bar,
  className,
}) => {
  const fillTransition = useSpring({
    ...FILL_TRANSITION(percentage),
    config: FILL_CONFIG,
  })

  return (
    <Root $bar={bar} className={className}>
      <animated.div className="progress-bar" style={fillTransition} />
      <progress value={percentage} max="100" />
    </Root>
  )
}

export default ProgressBar
