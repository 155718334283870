import React from 'react'

import { SvgIcon, SvgIconProps } from '../components/SvgIcon'

const Icon = ({ ...rest }: SvgIconProps) => {
  return (
    <SvgIcon {...rest} width="43" height="39" viewBox="0 0 43 39" fill="none">
      <path
        d="M37.8996 15.7C37.8996 30.4 18.9996 38.8 18.9996 38.8C18.9996 38.8 0.0996094 30.4 0.0996094 15.7C0.0996094 11.5 3.24961 5.19998 10.5996 5.19998C16.8996 5.19998 18.9996 11.5 18.9996 11.5C18.9996 11.5 21.0996 5.19998 27.3996 5.19998C34.7496 5.19998 37.8996 11.5 37.8996 15.7Z"
        fill="#96FAA7"
      />
      <path
        d="M41.3996 12.2C41.3996 26.9 22.4996 35.3 22.4996 35.3C22.4996 35.3 3.59961 26.9 3.59961 12.2C3.59961 7.99998 6.74961 1.69998 14.0996 1.69998C20.3996 1.69998 22.4996 7.99998 22.4996 7.99998C22.4996 7.99998 24.5996 1.69998 30.8996 1.69998C38.2496 1.69998 41.3996 7.99998 41.3996 12.2Z"
        stroke="#090A0B"
        strokeWidth="1.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.1004 14.3C35.1004 12.2 35.1004 9.05 30.9004 8"
        stroke="#090A0B"
        strokeWidth="1.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}

export default Icon
