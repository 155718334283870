import { SelectContractType } from '../../../../../domain/models'
import { Dispatcher, Service } from '../../../../protocols'

export type IUpsellSelectContractTypeService = Service<SelectContractType>

/**
 * Select Contract Type
 *
 * @category Upsell
 */
export class UpsellSelectContractTypeService
  implements IUpsellSelectContractTypeService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   * @param {SelectContractType} data
   */
  execute(data: SelectContractType): void {
    this.dispatcher.execute(
      'Upsell - Clique em tela para quem está contratando',
      data,
    )
  }
}
