import {
  ClickInsertDueDateService,
  SaveDueDateChangeService,
  SelectDueDateService,
  UpsellClearSaleResponseTimeService,
  UpsellClickPaymentTypeService,
  UpsellExpandCheckoutDetailsService,
  UpsellFinishCreditcardPaymentService,
  UpsellFinishPixPaymentService,
  UpsellLoadingPixPaymentService,
  UpsellViewCheckoutPage,
  ViewDueDateSelectDrawerService,
} from '../../../../../../data/services'
import { makeMixpanelDispatcherEvent } from '../../../../infra'

export const makeUpsellClickInsertDueDateService = () =>
  new ClickInsertDueDateService(makeMixpanelDispatcherEvent())

export const makeUpsellClickPaymentTypeService = () =>
  new UpsellClickPaymentTypeService(makeMixpanelDispatcherEvent())

export const makeUpsellExpandCheckoutDetailsService = () =>
  new UpsellExpandCheckoutDetailsService(makeMixpanelDispatcherEvent())

export const makeUpsellFinishCreditcardPaymentService = () =>
  new UpsellFinishCreditcardPaymentService(makeMixpanelDispatcherEvent())

export const makeUpsellFinishPixPaymentService = () =>
  new UpsellFinishPixPaymentService(makeMixpanelDispatcherEvent())

export const makeUpsellSaveDueDateChangeService = () =>
  new SaveDueDateChangeService(makeMixpanelDispatcherEvent())

export const makeUpsellSelectDueDateService = () =>
  new SelectDueDateService(makeMixpanelDispatcherEvent())

export const makeUpsellLoadingPixPaymentService = () =>
  new UpsellLoadingPixPaymentService(makeMixpanelDispatcherEvent())

export const makeUpsellViewCheckoutPage = () =>
  new UpsellViewCheckoutPage(makeMixpanelDispatcherEvent())

export const makeUpsellViewDueDateSelectDrawerService = () =>
  new ViewDueDateSelectDrawerService(makeMixpanelDispatcherEvent())

export const makeUpsellClearSaleResponseTimeService = () =>
  new UpsellClearSaleResponseTimeService(makeMixpanelDispatcherEvent())
