import React from 'react'

import { Typography } from '@Azos-Seguros/apollo'
import { Modal, useMediaQuery } from '@azos/shared'
import { Button } from '@azos/shared/src/components/v2'

import { SuccessModalProps } from './SuccessModal.props'
import { Root } from './SuccessModal.styles'

const SuccessModal: React.VFC<SuccessModalProps> = ({ open, onClose }) => {
  const { isMobile } = useMediaQuery()

  return (
    <Modal
      open={open}
      onClose={onClose}
      variant={isMobile ? 'bottomDrawer' : 'modal'}
      maxWidth={isMobile ? undefined : '480px'}
      showCloseButton={false}
      showHeader={false}
    >
      <Root>
        <div className="success-modal">
          <Typography className="success-modal__title" variant="bodyBold">
            Solicitação enviada com sucesso
          </Typography>
        </div>
        <Typography variant="body2" className="success-modal__description">
          Sentiremos sua falta!
          <br />
          <br />
          Enviamos um e-mail de confirmação da sua solicitação. Em até 2 dias,
          realizaremos o cancelamento.
        </Typography>

        <Button onClick={onClose} fullWidth className="success-modal__button">
          Fechar
        </Button>
      </Root>
    </Modal>
  )
}

export default SuccessModal
