import React from 'react'

import { Typography } from '@Azos-Seguros/apollo'
import { NoSSR } from '@azos/shared'

import { SpecialistAvatarProps } from './SpecialistAvatar.props'
import { Root } from './SpecialistAvatar.styles'

const SpecialistAvatar: React.VFC<SpecialistAvatarProps> = ({
  border = false,
  specialist,
  className,
}) => {
  return (
    <NoSSR>
      <Root $border={border} className={className}>
        <div className="specialist-avatar__image">
          <img
            src={specialist?.imageUrl}
            alt={specialist?.name}
            loading="lazy"
          />
        </div>
        <div className="specialist-avatar__text-content">
          <Typography
            variant="body2"
            bold
            className="specialist-avatar__text-name"
          >
            {specialist?.name}
          </Typography>
          <Typography
            variant="body3"
            bold
            className="specialist-avatar__text-seniority"
          >
            Especialista
          </Typography>
        </div>
      </Root>
    </NoSSR>
  )
}

export default SpecialistAvatar
