import React from 'react'

import { Radio } from '@Azos-Seguros/apollo'

import { ItemProps } from './Item.props'

export const Item = ({ name, value, onChange, ...props }: ItemProps) => {
  const inputRef = React.useRef<any>()

  const handleChange = React.useCallback(() => {
    inputRef.current?.click()
    if (typeof value !== 'undefined') onChange(value)
  }, [onChange, value])

  return (
    <Radio
      id={`${name}-${value}`}
      name={name}
      value={typeof value === 'boolean' ? value.toString() : value}
      onChange={handleChange}
      onClick={handleChange}
      {...props}
    />
  )
}

export default Item
