import React from 'react'

import { Modal, useMediaQuery, withModal } from '@azos/shared'
import { Button, Text } from '@azos/shared/src/components/v2'

import { UserEditConfirmDiscardModalProps } from './UserEditConfirmDiscardModal.props'
import { Root } from './UserEditConfirmDiscardModal.styles'

const UserEditConfirmDiscardModal: React.FCC<
  UserEditConfirmDiscardModalProps
> = ({ onConfirm, onClose, ...props }) => {
  const { isMobile } = useMediaQuery()

  return (
    <Modal
      {...props}
      showHeader={false}
      maxWidth="430px"
      variant={isMobile ? 'bottomDrawer' : 'modal'}
    >
      <Root>
        <div className="user-edit-confirm-discard-modal__title-content">
          <Text variant="h5">Deseja sair?</Text>
          <i className="icon-exit" onClick={onClose} />
        </div>
        <Text className="user-edit-confirm-discard-modal__description">
          Se você sair agora, as informações inseridas por você serão
          descartadas.
        </Text>

        <Button
          fullWidth
          onClick={onClose}
          className="user-edit-confirm-discard-modal__confirm-button"
        >
          Não
        </Button>
        <Button fullWidth variant="tertiary" onClick={onConfirm}>
          Sim
        </Button>
      </Root>
    </Modal>
  )
}

export default withModal<UserEditConfirmDiscardModalProps>(
  UserEditConfirmDiscardModal,
)
