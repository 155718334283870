import React from 'react'

import { useAuth } from '@azos/account'
import { ListProtected } from '@domain/models'
import { makeListProtectedService } from '@main/factories/services'

export type GuardianContextData = {
  isGuardian: boolean
  isLoading: boolean
  protectedList: ListProtected[]
}

const listProtectedService = makeListProtectedService()

export const GuardianContext = React.createContext({} as GuardianContextData)

const GuardianProvider: React.FCC = ({ children }) => {
  const { user } = useAuth()
  const [isGuardian, setIsGuardian] = React.useState(false)
  const [protectedList, setProtectedList] = React.useState<ListProtected[]>([])
  const [isLoading, setIsLoading] = React.useState(true)

  React.useEffect(() => {
    if (user) {
      listProtectedService
        .execute()
        .then(list => {
          setIsGuardian(list.length > 0)
          setProtectedList(list)
        })
        .catch(() => {
          setIsGuardian(false)
          setProtectedList([])
        })
      setIsLoading(false)
    }
  }, [user])

  return (
    <GuardianContext.Provider
      value={{
        isGuardian,
        isLoading,
        protectedList,
      }}
    >
      {children}
    </GuardianContext.Provider>
  )
}

export default GuardianProvider
