import { ClickPolicyCoverageDetails } from '../../../../../domain/models'
import { Dispatcher, Service } from '../../../../protocols'

export type IClickCoverageDetailsService = Service<ClickPolicyCoverageDetails>

/**
 * Click Policy Coverage Details
 *
 * @category Policy
 */
export class ClickCoverageDetailsService
  implements IClickCoverageDetailsService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   * @param {ClickPolicyCoverageDetails} data
   */
  execute(data: ClickPolicyCoverageDetails): void {
    this.dispatcher.execute(
      'Area logada web - Clique para ver detalhes de coberturas contratadas',
      data,
    )
  }
}
