import axios from 'axios'
import HttpStatus from 'http-status-codes'

import { authCookies, env, sentryConfig } from '../../main/config'
import { makeStorageProvider } from '../../main/factories'
import { Utils } from '../../utils'

export const api = axios.create({
  baseURL: env.bffURL,
})

// add auth token if authenticated
api.interceptors.request.use(async (config: any) => {
  const isServer = typeof window === 'undefined'

  // Authorization
  if (!env.isTest && !isServer) {
    const storageProvider = makeStorageProvider()
    const accessToken = storageProvider.get(authCookies.COOKIE_TOKEN_KEY)

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`
    }
  }

  // Sentry
  if (Utils.validator.isAzosDomain(config?.url || '')) {
    config.headers[sentryConfig.headerName] = sentryConfig.transactionId
  }

  return config
})

api.interceptors.response.use(
  response => {
    return response
  },
  async error => {
    if (
      [HttpStatus.UNAUTHORIZED, HttpStatus.FORBIDDEN].includes(
        error?.response?.status,
      )
    ) {
      const storageProvider = makeStorageProvider()
      const refreshToken = storageProvider.get(
        authCookies.COOKIE_REFRESH_TOKEN_KEY,
      )

      if (!refreshToken) {
        return Promise.reject(error)
      }

      await api
        .request<{ accessToken: string; refreshToken: string }>({
          url: '/auth/refresh-token',
          method: 'POST',
          data: {
            refreshToken,
          },
        })
        .then(res => {
          const { accessToken, refreshToken } = res.data

          storageProvider.set(authCookies.COOKIE_TOKEN_KEY, accessToken)
          storageProvider.set(
            authCookies.COOKIE_REFRESH_TOKEN_KEY,
            refreshToken,
          )

          Object.assign(error.config.headers || {}, {
            Authorization: `Bearer ${accessToken}`,
          })

          return axios.request(error.config)
        })
        .catch(() => {
          storageProvider.remove(authCookies.COOKIE_USER_KEY)
          storageProvider.remove(authCookies.COOKIE_TOKEN_KEY)
          storageProvider.remove(authCookies.COOKIE_REFRESH_TOKEN_KEY)

          return Promise.reject(error)
        })
    }

    return Promise.reject(error)
  },
)
