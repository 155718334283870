import { InputLabelProps } from '@mui/material/InputLabel'
import { styled } from '@mui/material/styles'

export const Label = styled('label')<InputLabelProps>(({ theme }) => ({
  flex: '0 1 100%',

  '& .form-select-list__input': {
    display: 'none',
    visibility: 'hidden',
  },

  '& .form-select-list__button': {
    width: '100%',

    padding: theme.spacing(3, 4),

    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4),
    },

    '& p': {
      fontWeight: theme.typography.fontWeightBold,
      display: 'block',
      textAlign: 'start',
      width: '100%',
      wordBreak: 'break-word',
    },

    '&:hover': {
      borderColor: theme.palette.common.black,
    },

    '&.active': {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
  },
}))
