import React from 'react'

import { PolicyDetails } from '../../PolicyDetails'
import {
  PolicyProductModalProps,
  PolicyProductModal,
} from '../../PolicyProductModal'

type Props = PolicyProductModalProps & {
  policyId: string
  onCancel: () => void
}

const CardPolicyPayment: React.VFC<Props> = ({
  policyId,
  onCancel,
  open,
  ...rest
}) => {
  return (
    <PolicyProductModal {...rest} policyId={policyId} open={open}>
      <PolicyDetails policyId={policyId} onCancel={onCancel} />
    </PolicyProductModal>
  )
}

export default CardPolicyPayment
