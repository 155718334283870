import { PaymentMethod } from '@azos/core'
import { css, Utils } from '@azos/shared'
import styled from '@emotion/styled'

interface RootProps {
  $type?: string
  $hasCreditCardIcon: boolean
}

export const Root = styled(
  'div',
  Utils.transient.transientStyleProps,
)<RootProps>(
  ({ theme, $type, $hasCreditCardIcon }) => css`
    background: ${theme.palette.neutral[50]};
    padding: 18px 16px;

    display: flex;
    flex-direction: column;

    .payment-data-content {
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      &--badge {
        font-size: 14px;

        i {
          font-size: 1.25em;
        }

        margin-bottom: 8px;
      }

      &--text {
        margin-top: ${$hasCreditCardIcon ? 0 : '2px'} !important;
        margin-right: 16px !important;
        font-size: 26px;
      }

      button {
        margin-top: 20px;
      }

      button:not(:first-of-type) {
        margin-top: 20px;
      }
    }

    .content {
      width: 100%;

      &-actions {
        margin-top: ${theme.spacing[3]}px;
        border-top: 1px solid ${theme.palette.neutral[100]};
      }

      &-header {
        display: flex;

        & > span {
          height: 32px !important;
          margin-bottom: 4px;
        }

        & > * + * {
          margin-left: ${theme.spacing[3]}px;
        }
      }
      &-button {
        color: ${theme.palette.primary.main};
      }

      h2 {
        margin: 0;
        padding: 0;
        font-size: ${$type === PaymentMethod.CREDIT_CARD ? '14px' : '18px'};
        text-transform: ${$type === PaymentMethod.CREDIT_CARD
          ? 'uppercase'
          : 'none'};
        font-weight: ${theme.typography.fontWeightBold};
      }

      &-creditcard-number {
        margin-top: ${theme.spacing[2]}px;
        display: flex;
        align-items: center;

        & > * + * {
          margin-left: ${theme.spacing[2]}px;
        }
      }

      &-amount {
        display: inline-block;
        font-weight: ${theme.typography.fontWeightBold};
      }

      p {
        margin: 14px 0 0 0;
        padding: 0;
        font-size: 16px;
      }

      a {
        cursor: pointer;
        display: block;
        font-size: 16px;
        font-weight: ${theme.typography.fontWeightBold};
        margin-top: 14px;
      }

      &-title {
        display: flex;
        align-items: center;

        i {
          font-size: 2rem;
          display: block;
          margin-right: ${theme.spacing[3]}px;
        }

        span {
          font-size: 14px;
          font-weight: normal;
          margin-right: ${theme.spacing[3]}px;
        }
      }
    }

    > hr {
      background-color: #f3f4f6;
      border: none;
      height: 1px;
      width: 100%;
      margin: ${theme.spacing[4]}px 0;
    }

    .contact-content {
      display: flex;
      flex-direction: column;
      text-align: center;

      > a {
        cursor: pointer;
        display: inline;
        font-weight: 600;
        padding: 12px;
        text-align: center;
      }
    }
  `,
)
