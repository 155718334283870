import React from 'react'

import { useMyPolicies } from '@presentation/providers/MyPoliciesProvider'

import ModalPolicyReadjustment from './ModalPolicyReadjustment'
import { ModalPolicyReadjustmentAdapterProps } from './ModalPolicyReadjustment.props'

const ModalPolicyReadjustmentAdapter: React.VFC<
  ModalPolicyReadjustmentAdapterProps
> = props => {
  const { policies } = useMyPolicies()

  return <ModalPolicyReadjustment {...props} policiesList={policies} />
}

export default ModalPolicyReadjustmentAdapter
