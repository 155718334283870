import React from 'react'

import analytics from '@azos/analytics'
import { Utils } from '@azos/shared'
import SwitchUnstyled from '@mui/core/SwitchUnstyled'

import { SwitchProps } from './Switch.props'
import { StyledSwitch } from './Switch.styles'

const Switch = ({ onChange, parentName, ...rest }: SwitchProps) => {
  const label = {
    componentsProps: {
      input: {
        'aria-label': 'Switch',
        'data-action': Utils.dataNames.renderDataName('switch', parentName),
      },
    },
  }

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (parentName) {
        analytics.custom.action.execute({
          dataAction: Utils.dataNames.renderDataName('switch', parentName),
        })
      }
      onChange(event.target.checked)
    },
    [onChange, parentName],
  )

  return (
    <SwitchUnstyled
      component={StyledSwitch}
      onChange={handleChange}
      {...label}
      {...rest}
    />
  )
}

export default Switch
