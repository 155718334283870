import { Box, BoxProps } from '@Azos-Seguros/apollo'
import { css, Modal as SharedModal } from '@azos/shared'
import styled from '@emotion/styled'

export const Modal = styled(SharedModal)(
  ({ theme }) => css`
    z-index: ${theme.zIndex.header};
    top: 64px;
    height: calc(100% - 146px);

    & > span {
      backdrop-filter: none;
      background: transparent;
      pointer-events: none;
    }
  `,
)

export const Root = styled(Box)<BoxProps>(
  ({ theme }) => css`
    padding: ${theme.spacing[4]}px;
    width: 100vw;

    .info-card {
      width: auto;
    }

    .modal-menu {
      &__responsible-broker-content {
        margin: ${theme.spacing[5]}px 0;
      }

      &__broker-header {
        display: block;
        margin-bottom: 18px;
      }
    }
  `,
)
