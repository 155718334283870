import { css, keyframes, styled } from '../../styles'
import { Utils } from '../../utils'
import { ProgressSpinnerProps } from './Progress.props'

type ProgressProps = {
  $radius: number
  $size: number
  $progress?: ProgressSpinnerProps
  $progressColor?: string
}

export const FIRST_HALF_CONFIG = {
  duration: 750,
  tension: 210,
  friction: 20,
}

export const SECOND_HALF_CONFIG = {
  duration: 750,
  tension: 210,
  friction: 20,
}

export const FIRST_HALF_TRANSITION = (radius: number) => ({
  from: { transform: 'rotate(0deg)' },
  to: { transform: `rotate(${radius > 180 ? '180deg' : `${radius}deg`})` },
})

export const SECOND_HALF_TRANSITION = (radius: number) =>
  radius > 180
    ? {
        to: {
          transform: `rotate(${radius - 180}deg)`,
          display: 'block',
        },
        from: { transform: 'rotate(0deg)', display: 'none' },
      }
    : {}

const getBorderSize = (size: number) => {
  if (size > 48) {
    return css`
      width: 90%;
      height: 90%;
      margin: 5%;
    `
  }

  return css`
    width: 85%;
    height: 85%;
    margin: 7.5%;
  `
}

export const Root = styled(
  'div',
  Utils.transient.transientStyleProps,
)<ProgressProps>(
  ({ $radius, $size, $progress, $progressColor, theme }) => css`
  position: relative;
    width: ${$size}px;
    height: ${$size}px;

    div:before,
    div:after {
      content: "";
      position: absolute;
      height: 100%;
      width: 50%;
      background-color: ${$progressColor || theme.palette.primary.main};
    }
    .spinner {
      position: relative;
        width: ${$size}px;
        padding-bottom: ${$size}px;
        overflow: hidden;
        background-color: ${
          $progress?.backgroundBorderColor || theme.palette.neutral[100]
        };
        border-radius: 50%;
        z-index: 1;

        &:after {
          ${getBorderSize($size)}
          border-radius: 50%;
          background-color: ${
            $progress?.backgroundColor || theme.palette.neutral[50]
          };
          z-index: 6;
        }

        &:before {
          background-color: inherit;
          z-index: 5;
        }

      &__text {
        position: absolute;
        z-index: 10;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: ${`${Math.round($size * 0.34)}px`};
        color: "white";
        font-weight: 400;
        display: grid;
        place-items: center;
      }

      &__first-half {
        content: "";
        position: absolute;
        height: 100%;
        width: 50%;
        background-color: ${$progressColor || theme.palette.primary.main};
        z-index: 4;
        transform-origin: 100% 50%;
      }

      &__second-half {
        content: "";
        position: absolute;
        height: 100%;
        width: 50%;
        background-color: ${$progressColor || theme.palette.primary.main};
        display: none;
        right: 0;
        z-index: 6;
        transform-origin: 0 50%;
      }
  `,
)
