import { css, Modal } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled(Modal)(
  ({ theme }) => css`
    svg {
      font-size: 40px;
    }

    h6 {
      margin: 14px 0 32px 0;
    }

    a {
      text-decoration: none;
    }

    .policy-readjustment {
      padding: 24px;
      background: ${theme.palette.neutral[50]};
      flex: 1;

      &__video {
        margin-top: ${theme.spacing[3]}px;
      }

      &__link {
        display: flex;
        align-items: center;
        gap: 8px;

        &--content {
          display: flex;
          flex-direction: column;
          gap: 2px;

          span {
            min-width: 98px;
            min-height: 91px;
          }

          img {
            width: 98px;
            height: 91px;
          }
        }
      }

      &__topic {
        margin-bottom: ${theme.spacing[3]}px;

        &--title {
          margin: 12px 0 8px 0;

          & > strong {
            color: ${theme.palette.primary.main};
          }
        }
      }

      &__wrapper {
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
      }

      i {
        font-size: 24px;
      }

      &__header {
        &-button {
          padding: ${theme.spacing[4]}px;
          display: flex;
          justify-content: space-between;

          button {
            display: grid;
            place-items: center;
            width: 48px;
            height: 48px;

            cursor: pointer;

            border-radius: 50%;
            background: ${theme.palette.neutral[300]};
            color: ${theme.palette.neutral[400]};

            i {
              font-size: 1.5rem;
            }
          }
        }

        &__description {
          color: ${theme.palette.neutral[400]};
        }

        &__second-topic {
          margin-top: 36px;
        }

        &__img {
          width: 328px;
          height: 104px;
        }

        &__image {
          margin: 16px 0 32px 0;
        }
      }

      &__footer {
        margin-top: ${theme.spacing[4]}px;
        padding: ${theme.spacing[4]}px;
        background: ${theme.palette.neutral[100]};

        button {
          margin-top: ${theme.spacing[4]}px;
        }
      }
    }
  `,
)
