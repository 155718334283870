import React from 'react'

import { Button } from '@Azos-Seguros/apollo'
import analytics from '@azos/analytics'
import { Modal, useTheme, withModal } from '@azos/shared'
import { Text } from '@azos/shared/src/components/v2'
import { ListProtected, ListProtectedBroker } from '@domain/models'

import { ProtectedB2CHelpModal } from '../ProtectedB2CHelpModal'
import { ProtectedBrokerHelpModal } from '../ProtectedBrokerHelpModal'
import { ProtectedListCard } from '../ProtectedListCard'
import { ProtectedRequestHelpListModalProps } from './ProtectedRequestHelpListModal.props'
import { Root } from './ProtectedRequestHelpListModal.styles'

const ProtectedRequestHelpListModal: React.VFC<
  ProtectedRequestHelpListModalProps
> = ({ open, onClose, protectedList, user, isGuardian }) => {
  const theme = useTheme()

  const [isShowingB2CHelpModal, setIsShowingB2CHelpModal] =
    React.useState(false)
  const [isShowingBrokerHelpModal, setIsShowingBrokerHelpModal] =
    React.useState(false)

  const [selectedBroker, setSelectedBroker] =
    React.useState<ListProtectedBroker | null>(null)

  React.useEffect(() => {
    analytics.dashboard.guardian.viewProtectedListHelp.execute({
      isInsured: !!user?.isInsured,
      isGuardian,
    })
  }, [isGuardian, user?.isInsured])

  const handleRequestHelp = (protectedUser: ListProtected) => {
    if (protectedUser.broker) {
      setSelectedBroker(protectedUser.broker)
      setIsShowingBrokerHelpModal(true)
    } else {
      setIsShowingB2CHelpModal(true)
    }
  }

  return (
    <>
      <Modal
        variant="drawer"
        exitBgColor={theme.palette.neutral[100]}
        open={open}
        onClose={onClose}
        showHeader={false}
        noGutter
      >
        <Root>
          <div className="protected-request-help-list__header">
            <button
              type="button"
              onClick={onClose}
              className="protected-request-help-list__header--exit-button"
            >
              <i className="icon-exit" />
            </button>
            <i className="icon-user-heart icon-content" />

            <Text
              variant="h5"
              className="protected-request-help-list__header--title"
            >
              Selecione uma pessoa <label>protegida</label>
            </Text>
            <Text
              variant="body3"
              className="protected-request-help-list__header--description"
            >
              Escolha para quem deseja solicitar ajuda:
            </Text>
          </div>

          <div className="protected-request-help-list__content">
            <div className="protected-request-help-list__content--list">
              <ProtectedListCard
                clickable
                onItemCLick={handleRequestHelp}
                items={protectedList}
              />
            </div>

            <Button variant="outline" fullWidth onClick={onClose}>
              Voltar
            </Button>
          </div>
        </Root>
      </Modal>
      <ProtectedB2CHelpModal
        open={isShowingB2CHelpModal}
        onClose={() => setIsShowingB2CHelpModal(false)}
      />
      <ProtectedBrokerHelpModal
        open={isShowingBrokerHelpModal}
        broker={selectedBroker}
        onClose={() => setIsShowingBrokerHelpModal(false)}
      />
    </>
  )
}

export default withModal<ProtectedRequestHelpListModalProps>(
  ProtectedRequestHelpListModal,
)
