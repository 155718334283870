import { SimulationAnswer } from '../../../domain/models'
import { Dispatcher, Service } from '../../protocols'

export type ISimulationAnswerService = Service<SimulationAnswer[]>

/**
 * Submit simulation
 *
 * @category Simulation
 */
export class SimulationAnswerService implements ISimulationAnswerService {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   * @param {SimulationAnswer[]} simulationData
   */
  execute(simulationData: SimulationAnswer[]): void {
    this.dispatcher.execute('simulation_question_answer', {
      simulationData,
    })
  }
}
