import { FinishPixPayment } from '../../../../../domain/models'
import { Dispatcher, Service } from '../../../../protocols'

export type IUpsellFinishPixPaymentService = Service<FinishPixPayment>

/**
 * Finish Pix Payment
 *
 * @category Upsell
 */
export class UpsellFinishPixPaymentService
  implements IUpsellFinishPixPaymentService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   * @param {FinishPixPayment} data
   */
  execute(data: FinishPixPayment): void {
    this.dispatcher.execute('Upsell - PIX aprovado em checkout', data)
  }
}
