import React from 'react'

import { Typography } from '@Azos-Seguros/apollo'
import { EmptyState, useTheme } from '@azos/shared'
import { Button, Text } from '@azos/shared/src/components/v2'
import { env } from '@main/config/env'
import { withPolicyPaymentStatus } from '@presentation/providers/PolicyProvider'
import { useRouter } from '@presentation/providers/RouterProvider'

import { PolicyCard } from '../PolicyCard'
import { PoliciesListProps } from './PoliciesList.props'
import { Root } from './PoliciesList.styles'

const PoliciesList: React.VFC<PoliciesListProps> = ({
  policies,
  max,
  showHeader = false,
}) => {
  const theme = useTheme()
  const Router = useRouter()

  const policiesTitle = React.useMemo(() => {
    const amount = policies.filter(
      policy => policy.status[0].type !== 'error',
    ).length

    const s = amount !== 1

    if (amount === 0) {
      return (
        <>
          <strong>Apólices</strong>
          <span className="policies-container__policy-count">
            ・ Nenhuma apólice ativa
          </span>
        </>
      )
    }

    return (
      <>
        Sua{s && 's'} <strong>apólice{s && 's'}</strong>
        <span className="policies-container__policy-count">
          ・ {amount} ativa{s && 's'}
        </span>
      </>
    )
  }, [policies])

  const policiesList = React.useMemo(() => {
    if (max && max > 0) {
      return policies.slice(0, max)
    }

    return policies
  }, [policies, max])

  const handleRedirect = async () => {
    await Router.push('/minhas-apolices')
  }

  const handleSimulation = () => Router.push(env.SIMULATION_URL)

  const PolicyHeader = React.memo(function policyHeader() {
    return (
      <div className="policies-container__info">
        <Text variant="body2">{policiesTitle}</Text>
        {max && policies.length > 0 && policies.length > max && (
          <Button
            variant="tertiary"
            onClick={handleRedirect}
            className="policies-container__redirect"
          >
            <Typography
              variant="bodyBold3"
              className="policies-container__redirect-text"
            >
              Ver tudo
            </Typography>
            <i className="icon-arrow-topright" />
          </Button>
        )}
      </div>
    )
  })

  const PolicyContent = React.memo(function policyContent() {
    if (policies.length === 0) {
      return (
        <div className="policies-container__empty-state">
          <EmptyState
            align="center"
            icon="icon-policy"
            iconColor={theme.palette.neutral[400]}
            title="Nenhuma apólice"
            titleTag="h5"
          >
            <div className="policies-container__empty-state--content">
              Você não possui nenhum apólice ativa. Faça uma nova simulação.
              <Button onClick={handleSimulation}>Simule agora</Button>
            </div>
          </EmptyState>
        </div>
      )
    }

    return (
      <div className="policies-wrapper">
        {policiesList.map(policy => (
          <PolicyCard
            key={policy.policyId}
            coverages={policy.coverages}
            created={policy.created}
            number={policy.number}
            premium={policy.premium}
            policyId={policy.policyId}
            status={policy.status}
            readjustment={policy.readjustment}
            frequency={policy.frequency}
          />
        ))}
      </div>
    )
  })

  return (
    <Root>
      {showHeader && <PolicyHeader />}
      <PolicyContent />
    </Root>
  )
}

export default withPolicyPaymentStatus(PoliciesList)
