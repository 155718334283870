import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled('div')(
  ({ theme }) => css`
    .success-modal {
      &__title {
        display: block;
        margin: ${theme.spacing[4]}px 0 8px;
      }

      &__description {
        display: block;
        margin-bottom: ${theme.spacing[4]}px;
        color: #505969;
      }

      &__button {
        height: 48px;
        display: flex;
      }
    }
  `,
)
