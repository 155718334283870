import React from 'react'

import analytics from '@azos/analytics'
import { Progress } from '@azos/shared'

import {
  SidebarDashboardMenuProps,
  SidebarDashboardMenuType,
} from '../../../SidebarDashboard/SidebarDashboard.props'
import { SidebarDashboardItemProps } from './SidebarDashboardItem.props'

const SidebarDashboardItem: React.FCC<SidebarDashboardItemProps> = ({
  itemValue,
  onChange,
  item,
}) => {
  const handleEvent = React.useCallback(() => {
    const eventCases: { [key: string]: () => void } = {
      [SidebarDashboardMenuType.ONBOARDING]: () => {
        if (item.progress) {
          analytics.dashboard.onboarding.viewOnboardingHomepage.execute({
            percentual: item.progress,
          })
        }
      },
    }

    if (item.type && eventCases[item.type]) return eventCases[item.type]()
  }, [item])

  React.useEffect(() => {
    handleEvent()
  }, [handleEvent])

  const handleOnClick = (onClick?: () => void) => (menu: string) => {
    if (!!onClick) {
      onClick()
    } else {
      onChange(menu)
    }
  }

  const handlePresentation = (item: SidebarDashboardMenuProps) => {
    const typeCases: {
      [key: string]: (item: SidebarDashboardMenuProps) => JSX.Element
    } = {
      [SidebarDashboardMenuType.USER_ACCOUNT]: (
        item: SidebarDashboardMenuProps,
      ) => (
        <div className="icon-azos-background">
          <span className={item.icon} />
        </div>
      ),
      [SidebarDashboardMenuType.ONBOARDING]: (
        item: SidebarDashboardMenuProps,
      ) => (
        <Progress
          percentage={Number(item.progress)}
          size={48}
          content={<i className={`progressContent ${item.icon}`} />}
          spinnerProps={{
            backgroundColor: item.progressBackgroundColor,
            progressColor: item.progressColor as string,
          }}
        />
      ),
      [SidebarDashboardMenuType.RESPONSIBLE_BROKER]: (
        item: SidebarDashboardMenuProps,
      ) => (
        <div className="icon-azos-background">
          <span className={item.icon} />
        </div>
      ),
      default: (item: SidebarDashboardMenuProps) => (
        <span className={item.icon} />
      ),
    }
    if (item.type) return typeCases[item.type](item)

    return typeCases.default(item)
  }

  return (
    <li className={item.type ? 'customItem' : undefined}>
      <button
        className={`
          ${item.value === itemValue ? 'active' : ''}
          ${item.type ? 'custom' : ''}
          `}
        onClick={() => handleOnClick(item.onClick)(item.value)}
      >
        {handlePresentation(item)}
        <p>{item.label}</p>
      </button>
    </li>
  )
}

export default SidebarDashboardItem
