import { SWRServices } from '@azos/core'
import { IAddressesRepository } from '@data/repositories'
import { ListCitiesUseCase } from '@domain/usecases'

type Params = ListCitiesUseCase.Params
type Response = ListCitiesUseCase.Response

export type IListCitiesService = SWRServices<Params, Response>

export class ListCitiesService implements IListCitiesService {
  constructor(private readonly addressesRepository: IAddressesRepository) {}

  getUrl(state: Params): string {
    return `/addresses/states/${state}/cities`
  }

  async execute(state: Params): Promise<ListCitiesUseCase.Response> {
    const response = await this.addressesRepository.listCities(state)

    return response
  }
}
