import { styled, css } from '../../styles'

export const Root = styled('div')(
  ({ theme }) => css`
    .topic {
      display: flex;
      margin-bottom: ${theme.spacing(5)};
      align-items: center;

      :last-child {
        margin-bottom: ${theme.spacing(3)};
      }

      i {
        margin-right: ${theme.spacing(3)};
      }

      &__label {
        p {
          padding: ${theme.spacing(4, 0)};
          color: ${theme.palette.grey[300]};
          font-weight: ${theme.typography.fontWeightBold};
        }

        &:last-child {
          .topic {
            color: #ff455b;
            margin: 40px 0 44px 0;
          }
        }
      }

      &__divider {
        width: 100vw;
        margin-left: -16px;
      }
    }
  `,
)

export default Root
