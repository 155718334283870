import { ProductPurchase } from '../../../domain/models'
import { Dispatcher, Service } from '../../protocols'

export type IPurchaseService = Service<ProductPurchase>

/**
 * Purchase product
 *
 * @category Checkout
 */
export class PurchaseService implements IPurchaseService {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @param {ProductPurchase} data
   * @function
   */
  execute(data: ProductPurchase): void {
    this.dispatcher.execute('purchase', {
      ...data,
      price: Number(data.price.toFixed(2)),
    })
  }
}
