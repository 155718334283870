import React from 'react'

import { IconAttributes } from '../components/SvgIcon'

const Icon = ({ as, style, ...props }: IconAttributes) => {
  return (
    <i
      className="icon-exit"
      style={{ fontSize: '1.75rem', color: '#000', ...style }}
      {...props}
    />
  )
}

export default Icon
