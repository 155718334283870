import React from 'react'

import { setupAnalytics } from '@azos/analytics'
import { makeIuguCheckoutProvider } from '@azos/core'
import { css } from '@azos/shared'
import { CacheProvider, Global } from '@emotion/react'
import { iuguConfig } from '@main/config/iugu'
import { MainContainer } from '@presentation/containers/MainContainer'
import { ClearsaleProvider } from '@presentation/providers/ClearSaleProvider'
import MainProvider from '@presentation/providers/MainProvider'
import googleTagManager from 'main/config/googleTagManager'
import { AppPropsWithLayout } from 'next'
import Head from 'next/head'
import Script from 'next/script'
import clearSale from 'static/clearsaleSDK'
import createEmotionCache from 'utility/createEmotionCache'
import { v4 as uuid } from 'uuid'

const clientSideEmotionCache = createEmotionCache()

const globalStyle = css`
  * {
    font-family: 'Dazzed', sans-serif;
  }

  body {
    background: #f3f4f6 !important;
  }

  html,
  body,
  #__next {
    height: 100%;
    margin: 0;
  }

  button {
    color: #000;
  }
`

function MyApp({
  emotionCache = clientSideEmotionCache,
  Component,
  pageProps,
}: AppPropsWithLayout) {
  const getLayout = Component.getLayout || ((page: any) => page)
  const clearSaleSessionId = React.useMemo(() => uuid(), [])

  React.useEffect(() => {
    setupAnalytics(googleTagManager)
  }, [])

  return (
    <>
      <Head>
        <title>Painel de Controle do Segurado Azos</title>
        <link rel="manifest" href="/manifest.json" />
        <link rel="apple-touch-icon" href="/assets/png/96x96.png" />
        <meta name="theme-color" content="#00D563" />
        <meta name="apple-mobile-web-app-status-bar" content="#F3F4F6" />
        <meta
          name="description"
          content="Tudo que você precisa para controlar a sua apólice de seguro e os dados relacionados a ela em 1 clique"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
      </Head>

      <ClearsaleProvider clearsaleSessionId={clearSaleSessionId}>
        <CacheProvider value={emotionCache}>
          <MainProvider>
            <MainContainer>
              {getLayout(<Component {...pageProps} />)}
            </MainContainer>
          </MainProvider>

          <Script
            id="clearSale"
            dangerouslySetInnerHTML={{
              __html: clearSale(clearSaleSessionId),
            }}
          />
          <Script
            src="/api/iugu"
            id="iugu"
            onLoad={() => {
              const checkoutProvider = makeIuguCheckoutProvider(iuguConfig)
              checkoutProvider.build()
            }}
          />

          <Global styles={globalStyle} />
        </CacheProvider>
      </ClearsaleProvider>
    </>
  )
}

export default MyApp
