import { UpsellSendReferralForm } from '../../../../../domain/models'
import { Dispatcher, Service } from '../../../../protocols'

export type IUpsellSendReferralFormService = Service<UpsellSendReferralForm>

/**
 * Send Referral Form
 *
 * @category Upsell
 */
export class UpsellSendReferralFormService
  implements IUpsellSendReferralFormService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   * @param {UpsellSendReferralForm} data
   */
  execute(data: UpsellSendReferralForm): void {
    this.dispatcher.execute(
      'Upsell - Envio de indicação em contratação para terceiro',
      data,
    )
  }
}
