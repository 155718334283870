import React from 'react'

export interface ClearSaleContextValue {
  sessionId: string | null
}

export const ClearsaleContext = React.createContext<ClearSaleContextValue>(
  {} as ClearSaleContextValue,
)

type ClearsaleProviderProps = {
  clearsaleSessionId: string
}

export const ClearsaleProvider: React.FCC<ClearsaleProviderProps> = ({
  children,
  clearsaleSessionId,
}) => {
  return (
    <ClearsaleContext.Provider
      value={{
        sessionId: clearsaleSessionId,
      }}
    >
      {children}
    </ClearsaleContext.Provider>
  )
}
