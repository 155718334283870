import {
  UpsellContinueDiseasesQuestionService,
  UpsellContinueGeneralConditionsQuestionService,
  UpsellContinueLifeStyleQuestionService,
  UpsellContinueOtherDiseasesQuestionService,
  UpsellGoBackService,
  UpsellViewDiseasesContentService,
  UpsellViewGeneralConditionsContentService,
  UpsellViewLifeStyleContentService,
  UpsellViewOtherDiseasesContentService,
  UpsellViewDPSResumeService,
} from '../../../../../../data/services'
import { makeMixpanelDispatcherEvent } from '../../../../infra'

export const makeUpsellContinueLifeStyleQuestionService = () =>
  new UpsellContinueLifeStyleQuestionService(makeMixpanelDispatcherEvent())

export const makeUpsellContinueDiseasesQuestionService = () =>
  new UpsellContinueDiseasesQuestionService(makeMixpanelDispatcherEvent())

export const makeUpsellContinueGeneralConditionsQuestionService = () =>
  new UpsellContinueGeneralConditionsQuestionService(
    makeMixpanelDispatcherEvent(),
  )

export const makeUpsellContinueOtherDiseasesQuestionService = () =>
  new UpsellContinueOtherDiseasesQuestionService(makeMixpanelDispatcherEvent())

export const makeUpsellViewDiseasesContentService = () =>
  new UpsellViewDiseasesContentService(makeMixpanelDispatcherEvent())

export const makeUpsellViewGeneralConditionsContentService = () =>
  new UpsellViewGeneralConditionsContentService(makeMixpanelDispatcherEvent())

export const makeUpsellViewLifeStyleContentService = () =>
  new UpsellViewLifeStyleContentService(makeMixpanelDispatcherEvent())

export const makeUpsellViewOtherDiseasesContentService = () =>
  new UpsellViewOtherDiseasesContentService(makeMixpanelDispatcherEvent())

export const makeUpsellGoBackService = () =>
  new UpsellGoBackService(makeMixpanelDispatcherEvent())

export const makeUpsellViewDPSResumeService = () =>
  new UpsellViewDPSResumeService(makeMixpanelDispatcherEvent())
