import React from 'react'

import { SignIn } from '../../components'
import { Container } from '../../containers'

const SignInView = () => {
  return (
    <Container>
      <SignIn />
    </Container>
  )
}

export default SignInView
