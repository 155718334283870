import HttpStatusCode from 'http-status-codes'

import { HttpClient } from '../../../data/protocols'
import { UnexpectedError } from '../../../domain/errors'
import { LoadBeneficiariesV2 } from '../../../domain/usecases'

export type IBeneficiariesV2Repository = LoadBeneficiariesV2

export class BeneficiariesV2Repository implements IBeneficiariesV2Repository {
  constructor(private readonly httpClient: HttpClient) {}

  async loadBeneficiaries(
    proposal_id: string,
  ): Promise<LoadBeneficiariesV2.Response> {
    const httpResponse = await this.httpClient.request({
      url: `/proposals/${proposal_id}/beneficiaries`,
      method: 'get',
    })
    const response = httpResponse.data

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
        return response as LoadBeneficiariesV2.Response
      default:
        throw new UnexpectedError()
    }
  }
}
