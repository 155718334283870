import { css, Utils } from '@azos/shared'
import styled from '@emotion/styled'

type ProgressBannerProps = {
  $textColor: string
}

export const Root = styled(
  'div',
  Utils.transient.transientStyleProps,
)<ProgressBannerProps>(
  ({ $textColor, theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    background: ${theme.palette.neutral[400]};
    padding: ${theme.spacing[6]}px ${theme.spacing[4]}px;
    position: relative;

    @media (min-width: ${theme.breakpoints.sm}px) {
      padding: ${theme.spacing[5]}px ${theme.spacing[6]}px;
      position: relative;
      flex-direction: row;
      border-radius: 16px;
    }

    .progress-banner-container {
      &__progress--percentage {
        color: ${theme.palette.neutral[50]};
        font-size: 1.5rem;
        font-weight: ${theme.typography.fontWeightBold};
      }

      &__text--wrapper {
        text-align: center;

        @media (min-width: ${theme.breakpoints.sm}px) {
          margin-left: ${theme.spacing[5]}px;
          text-align: start;
        }
      }

      &__title {
        display: block;
        margin-top: ${theme.spacing[3]}px;
        color: ${$textColor};
        font-weight: ${theme.typography.fontWeightBold};

        @media (min-width: ${theme.breakpoints.sm}px) {
          margin-top: 0;
          max-width: 60%;
        }
      }

      &__description {
        display: block;
        margin-top: ${theme.spacing[2]}px;

        color: ${theme.palette.neutral[50]};
        font-weight: ${theme.typography.fontWeightRegular};

        @media (min-width: ${theme.breakpoints.sm}px) {
          margin-top: ${theme.spacing[1]}px;
          font-size: 1rem;
        }
      }
    }
  `,
)
