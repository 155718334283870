import React from 'react'

import FormHelperText from '@mui/material/FormHelperText'

import { CheckboxSelectData, CheckboxSelectProps } from './CheckboxSelect.props'
import { Container } from './CheckboxSelect.styles'
import { Item } from './components'

export const CheckboxSelect: React.VFC<CheckboxSelectProps> = ({
  name,
  options,
  disabled,
  //
  value = null,
  onChange,
  //
  error,
  helperText,
  parentName,
}) => {
  const addOnList = React.useCallback(
    (optionValue: CheckboxSelectData) =>
      !!value ? [...value, optionValue] : [optionValue],
    [value],
  )

  const removeFromList = React.useCallback(
    (optionValue: CheckboxSelectData) =>
      value?.filter(item => item !== optionValue) ?? [],
    [value],
  )

  const valueIsChecked = React.useCallback(
    (optionValue: CheckboxSelectData) => value?.includes(optionValue),
    [value],
  )

  const handleChange = React.useCallback(
    (optionValue: CheckboxSelectData) => {
      const valueList = valueIsChecked(optionValue)
        ? removeFromList(optionValue)
        : addOnList(optionValue)

      onChange(valueList)
    },
    [addOnList, onChange, removeFromList, valueIsChecked],
  )

  return (
    <Container>
      <div
        role="radiogroup"
        aria-label={name}
        className="container__option-list"
      >
        {options.map((option, index) => (
          <Item
            key={`${index}-${option.value.toString()}`}
            name={name}
            label={option.label}
            value={option.value}
            checked={valueIsChecked(option.value)}
            onChange={handleChange}
            disabled={disabled}
            parentName={parentName}
          />
        ))}
      </div>
      {error && (
        <FormHelperText error className="container__item__helper-text">
          {helperText}
        </FormHelperText>
      )}
    </Container>
  )
}

export default CheckboxSelect
