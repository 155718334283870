import HttpStatusCode from 'http-status-codes'

import { UnauthorizedError, UnexpectedError } from '../../../domain/errors'
import { LoadUserProfile } from '../../../domain/usecases'
import { HttpClient } from '../../protocols/http'

export type IUsersRepository = LoadUserProfile

export class UsersRepository implements IUsersRepository {
  constructor(private readonly httpClient: HttpClient) {}

  async loadUserProfile(): Promise<LoadUserProfile.Response> {
    const httpResponse = await this.httpClient.request({
      url: `/users`,
      method: 'get',
    })

    const response = httpResponse.data

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
        return response as LoadUserProfile.Response
      case HttpStatusCode.UNAUTHORIZED:
        throw new UnauthorizedError()
      default:
        throw new UnexpectedError()
    }
  }
}
