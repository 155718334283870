import { HttpClient } from '@azos/core'
import { LoadCoverages2PremiumsService } from '@data/services/coverages'

import { makeProposalConsumer } from '../adapters'
import { makeCoveragesRepository } from '../repositories/coverages-repository-factory'

export const makeLoadCoverages2PremiumsService = (httpClient?: HttpClient) =>
  new LoadCoverages2PremiumsService(
    makeCoveragesRepository(httpClient),
    makeProposalConsumer(),
  )
