import { Dispatcher, Service } from '../../../../protocols'

export type IUpsellViewGeneralConditionsContentService = Service<void>

/**
 * View General Conditions Content
 *
 * @category Upsell
 */
export class UpsellViewGeneralConditionsContentService
  implements IUpsellViewGeneralConditionsContentService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   */
  execute(): void {
    this.dispatcher.execute(
      'Upsell - Visualização da tela "DPS - Opções de casos"',
    )
  }
}
