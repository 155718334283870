import React, { useContext, useState } from 'react'

import { Button, Input, Spinner, Typography } from '@Azos-Seguros/apollo'
import { useAuth } from '@azos/account'
import analytics from '@azos/analytics'
import {
  ActionsCancellationValidationType,
  PolicyCancelUserType,
  ViewFeedbackModalType,
} from '@azos/analytics/src/domain/models'
import { Utils } from '@azos/shared'
import { makeCancelPolicyService } from '@main/factories/services'
import { Navigation } from '@presentation/components/molecules/Navigation'
import { useFetchPolicyDetails } from '@presentation/hooks/useFetchPolictDetails'
import { useMyPolicies } from '@presentation/providers/MyPoliciesProvider'
import { Form, Formik } from 'formik'

import { PolicyCancelContext } from '../../PolicyCancelContext'
import { cancelOptions } from '../../PolicyCancelReasons'
import { CancellationValidationProps } from './CancellationValidation.props'
import Root from './CancellationValidation.styles'
import { validationSchema } from './CancellationValidation.validations'
import { ErrorModal } from './ErrorModal'
import { SuccessModal } from './SuccessModal'

const CancellationValidation: React.VFC<CancellationValidationProps> = ({
  onStepChange,
  onClose,
  policyId,
}) => {
  const { data: policyDetails } = useFetchPolicyDetails(policyId)
  const cancelPolicyService = makeCancelPolicyService()
  const { refreshPolicies } = useMyPolicies()
  const interval = React.useRef<NodeJS.Timeout>()
  const { user } = useAuth()
  const [loading, setLoading] = React.useState(false)
  const { reason, otherReason, step, setStep } = useContext(PolicyCancelContext)

  const [openModalError, setOpenModalError] = useState(false)
  const [openModalSuccess, setOpenModalSuccess] = useState(false)

  const initialValues = {
    cancel_text: '',
  }

  const isBroker = React.useMemo(
    () => user?.isBroker || user?.isInsuredByBroker,
    [user],
  )

  const analyticsType = isBroker
    ? PolicyCancelUserType.BROKER
    : PolicyCancelUserType.B2C

  React.useEffect(() => {
    analytics.dashboard.policy.cancel.viewCancellationValidation.execute({
      type: analyticsType,
    })
  }, [analyticsType])

  const canShowValidationMessageError = (value: string) =>
    !(!value || value.length < 8)

  const handleFirstStep = () => {
    setStep(1)
    analytics.dashboard.policy.cancel.viewPolicyCancelReason.execute()
  }

  const handleSecondStep = () => {
    setStep(2)
    analytics.dashboard.policy.cancel.selectPolicyCancelReason.execute({
      reason,
      other: reason === cancelOptions.OTHER_REASON ? otherReason : undefined,
    })
  }

  const handleSteps = () => {
    const stepCases: { [key: string]: () => void } = {
      0: handleFirstStep,
      1: handleSecondStep,
      2: handleOnPolicyCancel,
    }

    stepCases[step]()
  }

  const handleOnPolicyCancel = async () => {
    if (!policyDetails?.policy) return

    setLoading(true)
    try {
      await cancelPolicyService.execute({
        policyId: policyDetails.policy.policyId,
        reason: reason,
        description:
          reason === cancelOptions.OTHER_REASON ? otherReason : undefined,
      })
      setOpenModalSuccess(true)
      refreshPolicies()
      analytics.dashboard.policy.cancel.viewFeedbackModal.execute({
        type: analyticsType,
        feedback: ViewFeedbackModalType.ENVIADA,
      })
    } catch (_error) {
      setOpenModalError(true)
      analytics.dashboard.policy.cancel.viewFeedbackModal.execute({
        type: analyticsType,
        feedback: ViewFeedbackModalType.NAO_ENVIADA,
      })
    }

    setLoading(false)
  }

  const handleClose = React.useCallback(() => {
    onClose?.()
    interval.current = setTimeout(() => setStep(0), 500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClose])

  const quitFlow = () => {
    onClose?.()
    analytics.dashboard.policy.cancel.actionsCancellationValidation.execute({
      action: ActionsCancellationValidationType.DESISTIR,
      type: isBroker ? PolicyCancelUserType.BROKER : PolicyCancelUserType.B2C,
    })
  }

  const requestCancellation = () => {
    analytics.dashboard.policy.cancel.actionsCancellationValidation.execute({
      action: ActionsCancellationValidationType.SOLICITAR,
      type: isBroker ? PolicyCancelUserType.BROKER : PolicyCancelUserType.B2C,
    })
  }

  const serviceUrl =
    'https://wa.me/+551154451234?text=Cancelamento%20area%20logada'

  const talkToAzos = () => {
    analytics.dashboard.policy.cancel.talkAzosCancellation.execute()
    window.open(serviceUrl, '_blank')
  }

  const userData = [
    {
      title: 'Nome completo:',
      data: `${user?.displayName}`,
    },
    {
      title: 'E-mail:',
      data: `${user?.email}`,
    },
    {
      title: 'CPF:',
      data: `${Utils.formatter.formatCPF(user?.insuredUser?.cpf ?? '')}`,
    },
    {
      title: 'Celular:',
      data: `${Utils.formatter.formatPhone(user?.insuredUser?.phone ?? '')}`,
    },
    {
      title: 'Motivo do cancelamento:',
      data: `${reason === cancelOptions.OTHER_REASON ? otherReason : reason}`,
    },
    {
      title: 'Coberturas contratadas:',
      data: `${policyDetails?.policy.coverages.map(
        coverage => coverage.label,
      )}`,
    },
  ]

  const renderUserData = userData.map((user, index) => {
    return (
      <div key={index} className="cancellation-validation__user--data">
        <Typography
          variant="caption"
          className="cancellation-validation__user--data__title"
        >
          {user.title}
        </Typography>
        <Typography
          variant="body3"
          className="cancellation-validation__user--data__data"
        >
          {user.data}
        </Typography>
      </div>
    )
  })

  return (
    <Root>
      <div className="cancellation-validation">
        <Navigation
          text="Cancelar apólice"
          noText={false}
          className="cancellation-validation__navigation"
          onClick={() => {
            onStepChange(0)
          }}
        />
        <div className="cancellation-validation__header">
          <div className="cancellation-validation__header--text">
            <Typography variant="h5">
              Validação do <strong>cancelamento</strong>
            </Typography>
            <Typography variant="body3">
              Valide os dados abaixo para seguir com o cancelamento da apólice:
            </Typography>
          </div>
        </div>
        <div className="cancellation-validation__user">{renderUserData}</div>
        <div className="cancellation-validation__cancel">
          <hr />

          <p>
            Digite a palavra <strong>CANCELAR</strong> no campo abaixo para
            confirmar o cancelamento:
          </p>

          <Formik
            initialValues={initialValues}
            onSubmit={handleSteps}
            validationSchema={validationSchema}
            validateOnMount
          >
            {({ values, errors, handleChange, isValid }) => (
              <Form>
                <div className="input-wrapper">
                  <Input
                    fullWidth
                    autoComplete="off"
                    id="cancel_text"
                    name="cancel_text"
                    label="Digite CANCELAR"
                    error={
                      !!errors.cancel_text &&
                      canShowValidationMessageError(values.cancel_text)
                    }
                    helperText={
                      canShowValidationMessageError(values.cancel_text)
                        ? errors.cancel_text
                        : ''
                    }
                    value={values.cancel_text}
                    onChange={handleChange}
                  />
                </div>
                <div className="cancellation-validation__button">
                  <Button fullWidth type="button" onClick={talkToAzos}>
                    Falar com a Azos
                  </Button>
                  <Button
                    fullWidth
                    type="submit"
                    onClick={requestCancellation}
                    disabled={loading || !isValid}
                  >
                    {loading ? (
                      <Spinner size={18} borderSize={3} />
                    ) : (
                      'Solicitar cancelamento'
                    )}
                  </Button>
                  <Button
                    fullWidth
                    variant="outline"
                    onClick={quitFlow}
                    disabled={loading}
                  >
                    Desistir do cancelamento
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <ErrorModal
        open={openModalError}
        onClose={() => {
          handleClose()
          setOpenModalError(false)
        }}
      />
      <SuccessModal
        open={openModalSuccess}
        onClose={() => {
          handleClose()
          setOpenModalSuccess(false)
        }}
      />
    </Root>
  )
}

export default CancellationValidation
