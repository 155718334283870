import React from 'react'
import QRCode from 'react-qr-code'

import Skeleton from '@mui/material/Skeleton'

import { CardQRCodeProps } from './CardQRCode.props'
import { Root } from './CardQRCode.styles'

const CardQRCode: React.VFC<CardQRCodeProps> = ({
  value,
  loading = false,
  ...rest
}) => {
  return (
    <Root {...rest}>
      {loading ? (
        <Skeleton
          variant="rectangular"
          animation="wave"
          width={256}
          height={256}
        />
      ) : (
        <QRCode value={value} />
      )}
    </Root>
  )
}

export default CardQRCode
