type BreakPoints = 'xs' | 'sm' | 'md' | 'lg' | 'xl'
const up = (key: BreakPoints) =>
  `@media (min-width: ${breakpointValues[key]}px)`

const breakpointValues = {
  xs: 0,
  sm: 600,
  md: 784,
  lg: 1280,
  xl: 1920,
}

const breakpoints = {
  breakpoints: {
    values: breakpointValues,
    up,
  },
}

export default breakpoints
