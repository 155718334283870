import React from 'react'

import { InputMask } from '../InputMask'
import { InputCPFProps } from './InputCPF.props'

const InputCPF = ({ children, ...rest }: InputCPFProps) => {
  return (
    <InputMask
      label="CPF"
      placeholder="999.999.999-99"
      {...rest}
      mask={'999.999.999-99'}
      type="tel"
    />
  )
}

export default InputCPF
