import { css, styled } from '../../styles'

const Styles = styled('div')(
  ({ theme }) => css`
    .tabs-wrapper {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      margin: 0;
      background: ${theme.palette.neutral[400]};

      button {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        background: ${theme.palette.neutral[400]};
        margin-left: 0;
        padding: ${theme.spacing[2]}px ${theme.spacing[3]}px;
        color: ${theme.palette.neutral[50]};
        font-size: 1rem;
        border-radius: 0;
        min-height: 48px;
        transition: border-bottom 0.2s;
        border: none;
        flex: 1;
        font-weight: 600;
        font-size: 14px;
        line-height: 144%;

        &:disabled {
          cursor: not-allowed;
          background: ${theme.palette.neutral[200]};
        }

        &.selected {
          background: ${theme.palette.neutral[400]};
          position: relative;

          &:before {
            content: '';
            height: 4px;
            width: 90%;
            bottom: 0;
            position: absolute;
            margin: 0 16px;
            border-bottom: 4px solid ${theme.palette.primary.main};
          }

          &:disabled {
            background: ${theme.palette.neutral[300]};
          }
        }

        > span[class^='icon-'] {
          font-size: 24px;
        }

        span + span {
          margin-left: ${theme.spacing[3]}px;
        }
      }
    }
  `,
)

export default Styles
