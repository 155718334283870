import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled('div')(
  ({ theme }) => css`
    .guardian__no-policy {
      width: 100%;
      height: auto;
      background: ${theme.palette.neutral[100]};
      padding: ${theme.spacing[4]}px;
      display: flex;
      flex-direction: column;
      align-items: center;

      & > * + * {
        margin-top: ${theme.spacing[2]}px;
      }

      i {
        font-size: 1.5rem;
      }

      i,
      &-title,
      &-description {
        color: ${theme.palette.neutral[400]};
        text-align: center;
      }

      &--error {
        display: flex;
        align-items: center;

        background: ${theme.palette.secondary[500]};
        padding: ${theme.spacing[3]}px;
        cursor: pointer;
        margin-top: ${theme.spacing[4]}px;
        margin-bottom: ${theme.spacing[4]}px;
        border-radius: 0;

        i {
          font-size: 1.5rem;
          margin-right: ${theme.spacing[3]}px;
        }

        &-description {
          margin-top: ${theme.spacing[2]}px;
        }

        &-title {
          a {
            text-decoration: underline;
          }
        }

        i,
        &-title,
        &-description {
          color: ${theme.palette.primary[100]};
        }
      }

      &--success {
        display: flex;
        align-items: center;

        background: ${theme.palette.primary[50]};
        padding: ${theme.spacing[3]}px;
        cursor: pointer;
        margin-top: ${theme.spacing[4]}px;
        margin-bottom: ${theme.spacing[4]}px;
        border-radius: 0;

        i {
          font-size: 1.5rem;
          margin-right: ${theme.spacing[3]}px;
        }

        &-description {
          margin-top: ${theme.spacing[2]}px;
        }

        &-title {
          a {
            text-decoration: underline;
          }
        }

        i,
        &-title,
        &-description {
          color: ${theme.palette.primary[600]};
        }
      }

      &-description {
        width: auto;
        color: ${theme.palette.neutral[400]};
      }

      &--button {
        margin: 24px 0;

        button {
          padding: 14px;

          :first-of-type {
            margin-bottom: 8px;
          }
        }
      }
    }
  `,
)
