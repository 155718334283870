import React from 'react'

import { Input, InputPhone, Select } from '@azos/shared/src/components/v2'
import {
  GuardianFormValue,
  guardianKinshipOptions,
} from '@presentation/components/molecules/Guardians'
import { useFormikContext } from 'formik'

import { Root } from './GuardianPersonalDataForm.styles'

const GuardianPersonalDataForm: React.FCC = () => {
  const { errors, handleBlur, handleChange, setFieldValue, touched, values } =
    useFormikContext<GuardianFormValue>()

  return (
    <Root>
      <Input
        fullWidth
        autoFocus
        label="Nome Completo"
        id="name"
        name="name"
        placeholder="Digite aqui"
        error={touched.name && !!errors.name}
        helperText={touched.name && errors.name}
        value={values.name}
        onChange={handleChange}
        onBlur={handleBlur}
        className="guardian-personal-data-form__input"
      />
      <Input
        fullWidth
        label="E-mail para contato"
        id="email"
        name="email"
        placeholder="Digite aqui"
        error={touched.email && !!errors.email}
        helperText={touched.email && errors.email}
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
        type="email"
        className="guardian-personal-data-form__input"
      />
      <InputPhone
        fullWidth
        label="Telefone"
        id="phone"
        name="phone"
        placeholder="(00) 00000-0000"
        error={touched.email && !!errors.phone}
        helperText={touched.email && errors.phone}
        value={values.phone}
        onChange={handleChange}
        onBlur={handleBlur}
        className="guardian-personal-data-form__input"
      />
      <Select
        name="kinship"
        label="Grau de relacionamento"
        value={values.kinship}
        options={guardianKinshipOptions}
        onBlur={handleBlur}
        onChange={handleChange}
        error={touched.kinship && !!errors.kinship}
        helperText={touched.kinship && errors.kinship}
        fullWidth
        className="guardian-personal-data-form__input"
      />
    </Root>
  )
}

export default GuardianPersonalDataForm
