import { SWRServices } from '@azos/core'
import { ICmsRepository } from '@data/repositories'
import { ListSliderCardsByIdUseCase } from '@domain/usecases'

type Params = ListSliderCardsByIdUseCase.Params
type Response = ListSliderCardsByIdUseCase.Response

export type IListSliderCardsByIdService = SWRServices<Params, Response>

export class ListSliderCardsByIdService implements IListSliderCardsByIdService {
  constructor(private readonly cmsRepository: ICmsRepository) {}

  getUrl(params: Params): string {
    return `/dashboard-slider-cards/${params.cardId}`
  }

  async execute(params: Params): Promise<ListSliderCardsByIdUseCase.Response> {
    return await this.cmsRepository.listSliderCardsById(params)
  }
}
