import React from 'react'

import { Box, Button } from '@Azos-Seguros/apollo'
import { Text } from '@azos/shared'
import { useRouter } from 'next/router'

import { Divider } from '../../atoms'
import { EmailLogin } from '../../molecules'
import { SocialLoginButtons } from '../../molecules/SocialLoginButtons'

interface SignInProps {
  hiddenRegistration?: boolean
}

const SignInV2: React.VFC<SignInProps> = ({ hiddenRegistration }) => {
  const router = useRouter()

  const handleRedirectClick = () => {
    router.push('/cadastro')
  }

  return (
    <>
      <Text variant="h1">Faça seu login</Text>
      <EmailLogin mt={4} />

      {!hiddenRegistration && (
        <Box my={4}>
          <Button variant="outline" onClick={handleRedirectClick} fullWidth>
            Cadastre-se aqui!
          </Button>
        </Box>
      )}

      <Divider label="OU" />
      <SocialLoginButtons />
    </>
  )
}

export default SignInV2
