import {
  CancelPolicyService,
  ClickCoverageDetailsButtonsService,
  ClickCoverageDetailsService,
  DownloadGeneralConditionsService,
  DownloadPolicyService,
  PolicyExpirationDateService,
  ViewCoverageDetailsService,
} from '../../../../../data/services'
import { makeMixpanelDispatcherEvent } from '../../../infra'

export const makePolicyExpirationDateService = () =>
  new PolicyExpirationDateService(makeMixpanelDispatcherEvent())

export const makeDownloadGeneralConditionsService = () =>
  new DownloadGeneralConditionsService(makeMixpanelDispatcherEvent())

export const makeDownloadPolicyService = () =>
  new DownloadPolicyService(makeMixpanelDispatcherEvent())

export const makeCancelPolicyService = () =>
  new CancelPolicyService(makeMixpanelDispatcherEvent())

export const makeClickCoverageDetailsService = () =>
  new ClickCoverageDetailsService(makeMixpanelDispatcherEvent())

export const makeClickCoverageDetailsButtonsService = () =>
  new ClickCoverageDetailsButtonsService(makeMixpanelDispatcherEvent())

export const makeViewCoverageDetailsService = () =>
  new ViewCoverageDetailsService(makeMixpanelDispatcherEvent())
