import React from 'react'

import { makeStorageProvider } from '@azos/core'

const storageProvider = makeStorageProvider()

type UserConfirmationType = {
  isConfirmattedUser: boolean
  confirmUser: (confirm?: boolean) => void
}

const USER_CONFIRMATION_KEY = '@azos/user_confirmation'

export const useUserConfirmation = (): UserConfirmationType => {
  const isConfirmattedUser: boolean =
    storageProvider.get<boolean>(USER_CONFIRMATION_KEY) || false

  const confirmUser = React.useCallback((confirm = true) => {
    if (confirm) storageProvider.set(USER_CONFIRMATION_KEY, true)
    else storageProvider.remove(USER_CONFIRMATION_KEY)
  }, [])

  return {
    isConfirmattedUser,
    confirmUser,
  }
}
