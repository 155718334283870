import { css, styled } from '@azos/shared'

export const Root = styled('div')(
  ({ theme }) => css`
    .modal-content {
      &__button-group {
        margin-top: 40px;
        display: flex;
        flex-direction: column;

        & > button + button {
          margin-top: 16px;
        }
      }
    }
  `,
)
