import React from 'react'

import { useRemoteConfig } from '@azos/core'

export interface PolicyReadjustmentContextValue {
  isLoading: boolean
  isPolicyReadjustmentActive: boolean
}

export const ConfigContext =
  React.createContext<PolicyReadjustmentContextValue>(
    {} as PolicyReadjustmentContextValue,
  )

export const PolicyReadjustmentProvider: React.FCC = ({ children }) => {
  const remoteConfig = useRemoteConfig()
  const isPolicyReadjustmentActive = remoteConfig.get<boolean>('WEB_READJUST')

  return (
    <ConfigContext.Provider
      value={{
        isLoading: !remoteConfig,
        isPolicyReadjustmentActive,
      }}
    >
      {children}
    </ConfigContext.Provider>
  )
}
