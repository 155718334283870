import { CreateBeneficiaries } from '../../../../domain/usecases'
import { Services } from '../../../protocols/service'

export type ICreateBeneficiariesService = Services<
  CreateBeneficiaries.Params,
  CreateBeneficiaries.Response
>

export class CreateBeneficiariesService implements ICreateBeneficiariesService {
  constructor(private readonly beneficiariesRepository: CreateBeneficiaries) {}

  async execute(
    params: CreateBeneficiaries.Params,
  ): Promise<CreateBeneficiaries.Response> {
    const response = await this.beneficiariesRepository.createBeneficiaries(
      params,
    )

    return response
  }
}
