import { Dispatcher, Service } from '../../protocols'

export type ISelectPixCashbackService = Service<void>

/**
 * Select pix cashback service
 *
 * @category Checkout
 */
export class SelectPixCashbackService implements ISelectPixCashbackService {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   */
  execute(): void {
    this.dispatcher.execute(
      'B2C - Clicou no botão: Continuar com o Pix no modal de cashback',
    )
  }
}
