import { css, styled } from '@azos/shared'

export const Root = styled('div')(
  ({ theme }) => css`
    .guardians-table {
      background: ${theme.palette.neutral[100]};
      height: auto;
      padding: 16px;
      position: relative;
      overflow: hidden;
      margin: 24px 0;

      &__beneficiary-list {
        padding: 0;
        margin: 0;
      }

      &__beneficiary-item {
        list-style-type: none;

        &__content {
          display: flex;
          justify-content: space-between;
          align-items: center;

          &__heading {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &__title {
              font-weight: ${theme.typography.fontWeightBold};
              font-size: 1rem;
              display: block;
              word-break: break-word;
              margin: 0;
              margin-bottom: 8px;
            }
          }
        }

        hr {
          margin: 16px 0;
          border-top: 1px solid #d0d4dc;
        }

        &__info {
          display: flex;

          p {
            margin: 0;
          }

          &__dot::after {
            content: '\\00B7';
            font-size: 32px;
            color: ${theme.palette.neutral[300]};
            line-height: 20px;
            margin: 0 6px;
          }

          &__city {
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  `,
)
