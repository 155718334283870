import React from 'react'

import { Button, Typography } from '@Azos-Seguros/apollo'
import analytics from '@azos/analytics'
import { withModal, PageHeader, useTheme } from '@azos/shared'
import { azosWhatsappUrl } from '@main/config/contact'
import { getYoutubeEmbedUrl } from '@utils/getYoutubeEmbedUrl'
import Image from 'next/image'

import VideoPlayer from '../VideoPlayer'
import { ModalPolicyReadjustmentProps } from './ModalPolicyReadjustment.props'
import { Root } from './ModalPolicyReadjustment.styles'

const ModalPolicyReadjustment: React.VFC<ModalPolicyReadjustmentProps> = ({
  open,
  onGoBack,
  onClose,
}) => {
  const theme = useTheme()

  React.useEffect(() => {
    analytics.dashboard.policies.viewPolicyReadjustmentModal.execute()
  }, [])

  return (
    <>
      <Root
        variant="drawer"
        open={open}
        onClose={onClose}
        showHeader={false}
        backgroundColor={theme.palette.neutral[400]}
        exitBgColor={theme.palette.neutral[300]}
        noGutter
        maxWidth="440px"
      >
        <div className="policy-readjustment__wrapper">
          <div className="policy-readjustment__header-button">
            <button onClick={onGoBack} data-testid="back-modal-button">
              <i className="icon-arrow-left" />
            </button>

            <button onClick={onClose} data-testid="exit-modal-button">
              <i className="icon-exit" />
            </button>
          </div>

          <PageHeader
            icon="icon-policy-increase"
            title="Entenda o reajuste de apólices"
            description="Entenda os tipos de reajuste:"
          />

          <div className="policy-readjustment">
            <div className="policy-readjustment__topic">
              <i className="icon-policy" />
              <Typography
                variant="bodyBold2"
                className="policy-readjustment__topic--title"
              >
                <strong>Por que</strong> o reajuste anual do seguro de vida
                acontece?
              </Typography>

              <Typography variant="body3" color="light">
                Assista ao vídeo e entenda!
              </Typography>

              <VideoPlayer
                className="policy-readjustment__video"
                videoUrl={getYoutubeEmbedUrl(
                  'https://www.youtube.com/watch?v=iA2-6TY9D9k',
                )}
                width="100%"
                height="350px"
              />
            </div>

            <div className="policy-readjustment__topic">
              <i className="icon-policy" />
              <Typography
                variant="bodyBold2"
                className="policy-readjustment__topic--title"
              >
                Reajuste pelo <strong>IPCA</strong>
              </Typography>

              <Typography variant="body3" color="light">
                O Índice de Preços ao Consumidor Amplo (IPCA) é um índice de
                inflação tradicional, efetuado com base nas variações dos preços
                do mercado por conta da inflação.
              </Typography>
              <br />

              <Typography variant="body3" color="light">
                Mesmo que a mensalidade tenha uma alteração, a sua cobertura
                (capital segurado), também acompanha essa mudança com base nas
                taxas inflacionárias do País, garantindo que a apólice continue
                valorizada!
              </Typography>
              <br />

              <Typography variant="body3" color="light">
                A sua mensalidade pode aumentar, mas a sua proteção (o capital
                segurado), também!
              </Typography>
            </div>

            <div className="policy-readjustment__topic">
              <i className="icon-policy" />
              <Typography
                variant="bodyBold2"
                className="policy-readjustment__topic--title"
              >
                Reajuste <strong>etário</strong>
              </Typography>

              <Typography variant="body3" color="light">
                Nessa modalidade, a atualização de valores é anual e segue a
                tabela etária disponível nas Condições Gerais da Azos, de acordo
                com o percentual de cada idade.
              </Typography>
              <br />

              <Typography variant="body3" color="light">
                Cada cobertura tem a sua tabela etária específica, ou seja, o
                reajuste etário varia também de cobertura para cobertura.
              </Typography>
              <br />

              <Typography variant="body3" color="light">
                Vale reforçar que temos as menores taxas do mercado!
              </Typography>
            </div>

            <a
              className="policy-readjustment__link"
              href="https://www.azos.com.br/vida-segura/reajuste-anual-de-seguro-de-vida"
              target="_blank"
              rel="noreferrer"
            >
              <Image
                src="https://storage.googleapis.com/azos-cms-strapi-uploads/reajuste_seguro_de_vida_link_eb7c24fe48/reajuste_seguro_de_vida_link_eb7c24fe48.png?updated_at=2024-02-15T16:10:48.635Z"
                alt="Reajuste de apólice"
                width={98}
                height={91}
                className="policy-readjustment__image"
              />
              <div className="policy-readjustment__link--content">
                <Typography variant="bodyBold2">
                  Você sabe como funciona o reajuste do Seguro de Vida?
                </Typography>
                <Typography variant="body3">
                  Saiba mais em nosso Blog.
                </Typography>
              </div>
            </a>

            <div className="policy-readjustment__footer">
              <i className="icon-policy" />
              <Typography
                variant="bodyBold2"
                className="policy-readjustment__topic--title"
              >
                Conversando, a gente se entende
              </Typography>

              <Typography variant="body3">
                Se você ainda possui dúvidas ou deseja falar sobre
                especificidades de valores, estamos aqui para ajudar!
              </Typography>

              <a href={azosWhatsappUrl} target="_blank" rel="noreferrer">
                <Button icon="icon-headphone" variant="outline" fullWidth>
                  Falar com atendimento
                </Button>
              </a>
            </div>
          </div>
        </div>
      </Root>
    </>
  )
}

export default withModal(ModalPolicyReadjustment)
