import React from 'react'

import ImageFirebaseStorage, {
  BaseImageFirebaseStorage,
} from './ImageFirebaseStorage'

const ImageExternalBannerStore: React.VFC<BaseImageFirebaseStorage> = ({
  title = 'Baixe o app Azos',
}) => {
  return (
    <ImageFirebaseStorage
      src="/B2C/dashboard/banners/banner-externo.png"
      alt={title}
      title={title}
      width={656}
      height={320}
    />
  )
}

export default ImageExternalBannerStore
