import React from 'react'

import { OnboardingWizardContext } from './OnboardingWizardProvider'

export const useOnboardingWizard = () => {
  const context = React.useContext(OnboardingWizardContext)

  if (!context) {
    throw new Error(
      'useOnboardingWizard must be used within a OnboardingWizardProvider',
    )
  }

  return context
}
