import React from 'react'

import { InputMask } from '../InputMask'
import { InputPhoneProps } from './InputPhone.props'

const InputPhone = ({ children, ...rest }: InputPhoneProps) => {
  return (
    <InputMask
      label="Celular"
      placeholder="(99) 99999-9999"
      {...rest}
      mask={'(99) 99999-9999'}
      type="tel"
    />
  )
}

export default InputPhone
