export type BeneficiaryAddValues = {
  fullName: string
  socialName?: string | null
  kinshipDetails?: string
  birthDate?: Date | null
  kinship: string
}

export const initialValues: BeneficiaryAddValues = {
  fullName: '',
  socialName: '',
  birthDate: null,
  kinship: '',
}
