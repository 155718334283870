import { SWRService, useFetch } from '@azos/core'
import { LoadPolicyByIdUseCase } from '@domain/usecases'
import { makeLoadPolicyDetailsService } from '@main/factories/services'

const loadPolicyDetailsService = makeLoadPolicyDetailsService()

export const useFetchPolicyDetails = (policyId: string) => {
  const {
    data = null,
    error,
    mutate,
  } = useFetch<LoadPolicyByIdUseCase.Response>(
    new SWRService(loadPolicyDetailsService.getUrl({ policyId }), () => {
      return loadPolicyDetailsService.execute({
        policyId,
      })
    }),
    { revalidateOnFocus: false },
  )

  const isLoading = !data && !error

  return {
    data,
    isLoading,
    isError: error,
    refetch: mutate,
  }
}
