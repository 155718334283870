export const getVindiBrand = (brand: string) => {
  if (!brand) return ''

  const brandCases: { [key: string]: string } = {
    ['american-express']: 'american_express',
    ['diners-club']: 'diners_club',
  }

  return brandCases?.[brand] || brand
}

export const getMaskByBrand = (brand: string | null) => {
  if (!brand) return '9999 9999 9999 9999'

  const brandCases: { [key: string]: string } = {
    visa: '9999 9999 9999 9999',
    mastercard: '9999 9999 9999 9999',
    ['american-express']: '9999 999999 99999',
    ['diners-club']: '9999 999999 9999',
    default: '9999 9999 9999 9999',
  }

  return brandCases?.[brand] ?? brandCases.default
}
