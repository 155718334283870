import { css, Modal, ModalProps, Utils } from '@azos/shared'
import styled from '@emotion/styled'

type RootProps = ModalProps & {
  $isLoadingOrError: boolean
}

export const Root = styled(
  Modal,
  Utils.transient.transientStyleProps,
)<RootProps>(
  ({ theme, $isLoadingOrError }) => css`
    p {
      font-size: 0.875rem;
    }

    .current-value {
      text-decoration: line-through;
      color: ${theme.palette.neutral[400]};
    }

    .readjusted-label {
      display: flex;
      justify-content: end;
    }

    .policy-readjustment {
      &__empty-state-wrapper {
        ${$isLoadingOrError
          ? css`
              height: 100%;
              background: ${theme.palette.neutral[50]};
            `
          : ''}
      }

      &__empty-state {
        height: 100%;
      }

      &__wrapper {
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
      }

      &__header {
        display: flex;
        flex-direction: column;
        background: ${theme.palette.neutral[50]};

        position: sticky;
        top: 0;

        padding: 0 ${theme.spacing[4]}px ${theme.spacing[5]}px;

        &-button {
          padding: ${theme.spacing[3]}px ${theme.spacing[2]}px;
          display: flex;
          justify-content: end;

          button {
            display: grid;
            place-items: center;
            width: 40px;
            height: 40px;

            cursor: pointer;

            border-radius: 50%;
            background: ${theme.palette.neutral[300]};

            i {
              font-size: 1.5rem;
            }
          }
        }
      }

      &__content {
        padding: 24px;
        background: ${theme.palette.neutral[50]};
        display: flex;
        flex-direction: column;
        gap: ${theme.spacing[4]}px;

        button > span {
          font-size: 14px;
        }

        &--policy-number {
          white-space: nowrap;
          width: 80%;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &--first-card {
          display: flex;
          flex-direction: column;
          gap: ${theme.spacing[3]}px;
          background: ${theme.palette.neutral[100]};
          padding: 16px;

          &__title {
            display: flex;
            align-items: center;

            i {
              font-size: 24px;
              margin-right: 8px;
            }
          }

          &__description {
            margin: 12px 0;

            .date {
              color: ${theme.palette.status.error.main};
            }
          }

          &__value {
            display: flex;
            align-items: center;
            justify-content: space-between;

            i {
              font-size: 24px;
            }
          }
        }

        &--second-card {
          display: flex;
          flex-direction: column;
          gap: ${theme.spacing[3]}px;

          i {
            font-size: 24px;
          }

          &__title {
            display: flex;
            align-items: center;

            i {
              font-size: 24px;
              margin-right: 8px;
            }
          }

          &__content {
            background: ${theme.palette.neutral[100]};
            padding: 16px;
            display: flex;
            justify-content: space-between;

            p {
              width: 174px;
            }
          }
        }

        &--third-card {
          background: ${theme.palette.neutral[100]};

          section {
            border-bottom: 1px solid ${theme.palette.neutral[600]};
            padding: 24px 16px;

            :last-of-type {
              border-bottom: none;
            }
          }

          &__title {
            display: flex;
            align-items: center;
            margin-bottom: ${theme.spacing[4]}px;

            i {
              margin-right: 12px;
            }
          }

          &__table {
            display: flex;
            flex-direction: column;
            gap: ${theme.spacing[3]}px;

            &--line {
              display: flex;
              justify-content: space-between;

              &__content {
                display: flex;
                flex-direction: column;
                gap: ${theme.spacing[1]}px;

                &--chip {
                  background-color: ${theme.palette.neutral[200]};
                  border-radius: ${theme.spacing[1]}px;
                  padding: 2px ${theme.spacing[2]}px;
                  width: fit-content;
                  color: ${theme.palette.neutral[400]};
                }
              }
            }
          }
        }
      }
    }
  `,
)
