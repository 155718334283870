import React from 'react'

import { Alert as MuiAlert, Snackbar as MuiSnackbar } from '@mui/material'

import { NotifyProps } from './Notify.props'

const Notify: React.VFC<NotifyProps> = ({ message, status, onClose }) => {
  return (
    <MuiSnackbar
      open
      autoHideDuration={6000}
      onClose={onClose}
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        onClose={onClose}
        severity={status}
      >
        {message}
      </MuiAlert>
    </MuiSnackbar>
  )
}

export default Notify
