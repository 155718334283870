import React from 'react'

import { useAuth } from '@azos/account'

import RequestBroker from './RequestBroker'
import { RequestBrokerAdapterProps } from './RequestBroker.props'

const AzosMediaCardAdapter: React.VFC<RequestBrokerAdapterProps> = props => {
  const { user } = useAuth()

  return <RequestBroker {...props} user={user} />
}

export default AzosMediaCardAdapter
