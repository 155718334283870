import React from 'react'

import analytics from '@azos/analytics'
import { Utils } from '@azos/shared'
import MuiLink from '@mui/material/Link'

import { LinkProps } from './Link.props'

const Link = ({ parentName, onClick, ...rest }: LinkProps) => {
  const handleClick = (
    params: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    if (parentName) {
      analytics.custom.action.execute({
        dataAction: Utils.dataNames.renderDataName('link', parentName),
      })
    }
    onClick?.(params)
  }

  return (
    <MuiLink
      onClick={handleClick}
      data-action={Utils.dataNames.renderDataName('link', parentName)}
      {...rest}
    />
  )
}

export default Link
