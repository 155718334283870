import React from 'react'

import { Loading } from '@azos/shared'
import Router from 'next/router'

export type RouterContextData = {
  push: (route: string) => Promise<void>
  replace: (route: string) => Promise<void>
  back: () => Promise<void>
}

export const RouterContext = React.createContext({} as RouterContextData)

const RouterProvider: React.FCC = ({ children }) => {
  const [loading, setIsLoading] = React.useState(false)

  const push = async (route: string) => {
    setIsLoading(true)
    await Router.push(route)
    setIsLoading(false)
  }

  const replace = async (route: string) => {
    setIsLoading(true)
    await Router.replace(route)
    setIsLoading(false)
  }

  const back = async () => {
    setIsLoading(true)
    Router.back()
    setIsLoading(false)
  }

  return (
    <RouterContext.Provider value={{ push, replace, back }}>
      {loading && <Loading />}
      {children}
    </RouterContext.Provider>
  )
}

export default RouterProvider
