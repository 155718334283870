import { camelCase, startCase } from 'lodash'

export function onlyNumber(value?: string): string {
  return value ? value.replace(/\D/gm, '') : ''
}

export function capitalizeString(value: string): string {
  return startCase(camelCase(value))
}

export function numFormatter(num: number): string {
  if (num >= 1000 && num <= 1000000) {
    return `${(num / 1000).toFixed(0)}M`
  } else if (num > 1000000) {
    return `${(num / 1000000).toFixed(0)}MM`
  }
  return num.toString()
}

export function getLastCharsFromString(string: string, chars: number): string {
  return string.substr(string.length - chars)
}

export function currencyFormatter(num: number): string {
  return Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(num)
}

export const sanitizeString = (
  value: string,
  options?: { withoutTrim: boolean },
) => {
  const newValue = value
    .toLocaleLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')

  return options?.withoutTrim ? newValue : newValue.trim()
}
