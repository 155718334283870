import React from 'react'

export const PolicyCancelContext = React.createContext<{
  reason: string
  otherReason: string
  step: number
  setStep: React.Dispatch<React.SetStateAction<number>>
  setReason: React.Dispatch<React.SetStateAction<string>>
  setOtherReason: React.Dispatch<React.SetStateAction<string>>
}>({
  reason: '',
  otherReason: '',
  step: 0,
  setStep: () => {},
  setReason: () => {},
  setOtherReason: () => {},
})
