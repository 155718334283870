export enum SidebarDashboardMenuType {
  ONBOARDING = 'onboarding',
  RESPONSIBLE_BROKER = 'responsible_broker',
  USER_ACCOUNT = 'user_account',
}

export type SidebarDashboardMenuProps = {
  type?: SidebarDashboardMenuType
  icon?: string
  //
  label: string
  value: string
  progress?: number
  progressColor?: string
  progressBackgroundColor?: string
  //
  onClick?: () => void
}

export type SidebarDashboardProps = {
  value?: string
  items: SidebarDashboardMenuProps[]
  //
  onChange: (newValue: string) => void
  isUserInsured: boolean
}
