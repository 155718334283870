import React from 'react'

import { useWizard } from '../../hooks'
import { WizardItemProps } from '../../Wizard.props'

const WizardItem = ({ component: ItemComponent, config }: WizardItemProps) => {
  const context = useWizard()

  React.useEffect(() => {
    context.setConfig(config)
  }, [config, context])

  return <ItemComponent context={context} />
}

export default WizardItem
