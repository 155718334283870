import {
  InteractBrokerContactCardService,
  ViewBrokerContactCardService,
} from '../../../../../data/services'
import { makeMixpanelDispatcherEvent } from '../../../infra'

export const makeViewBrokerContactCardService = () =>
  new ViewBrokerContactCardService(makeMixpanelDispatcherEvent())

export const makeInteractBrokerContactCardService = () =>
  new InteractBrokerContactCardService(makeMixpanelDispatcherEvent())
