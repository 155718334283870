import React from 'react'

import analytics from '@azos/analytics'
import { firebase } from '@azos/core'
import { Loading } from '@azos/shared'

import { SetSession, User } from '../../domain/models'
import { AuthContextData, AuthProviderProps } from './AuthProvider.props'

export const AuthContext = React.createContext<AuthContextData>(
  {} as AuthContextData,
)

type AuthState = {
  isLoading: boolean
  isRegistered: boolean
  user: User | null
}

const AuthProvider: React.FCC<AuthProviderProps> = ({
  children,
  authService,
  onSuccessful,
  onError,
  onInfo,
  onSignOut,
}) => {
  const isServer = typeof window === 'undefined'

  const origin = React.useRef<string>('')

  const [authState, setAuthState] = React.useState<AuthState>({
    isLoading: false,
    isRegistered: false,
    user: null,
  })

  // console.log('[AUTH]', { authState })

  const isSigned = React.useMemo<boolean>(
    () => !!authState.user,
    [authState.user],
  )
  const isAllow = React.useMemo<boolean>(
    () => isSigned && authState.isRegistered && !authState.isLoading,
    [isSigned, authState.isRegistered, authState.isLoading],
  )

  // #region Callbacks

  const logout = React.useCallback(async () => {
    if (!isServer) {
      analytics.logout()
      await authService.signOut()
      window.localStorage.removeItem('@azos/showModalHomeWarning')
      setAuthState(state => ({ ...state, user: null }))
    }
  }, [authService, isServer])

  const setSession = React.useCallback(
    async (session: SetSession | null): Promise<void> => {
      if (!session) {
        logout()
        onSignOut?.()
        return
      }

      const user: User = {
        ...session.auth,
        uid: session.auth.accountId,
        displayName: session.auth.name,
        email: session.auth.email,
        photoURL: session.auth.profilePicture ?? '',
      }

      setAuthState(state => ({
        ...state,
        isRegistered: !!session.auth?.isInsured,
        user,
      }))

      await onSuccessful?.({
        from: origin.current,
        token: session.token,
        user,
      })
    },
    [logout, onSignOut, onSuccessful],
  )

  const signInWithProvider = React.useCallback(
    async (fbUser: firebase.User | null): Promise<void> => {
      if (authState.isLoading) return
      if (!fbUser || fbUser?.email === authState.user?.email) return
      setAuthState(state => ({ ...state, isLoading: true }))
      await authService
        .refreshToken({ refreshToken: fbUser.refreshToken })
        .then(setSession)
        .catch(onError)
        .finally(() => {
          setAuthState(state => ({ ...state, isLoading: false }))
        })
    },
    [
      authService,
      authState.isLoading,
      authState.user?.email,
      onError,
      setSession,
    ],
  )

  const recovery = React.useCallback(
    async (token: string) => {
      if (authState.isLoading) return
      setAuthState(state => ({ ...state, isLoading: true }))
      await authService
        .recovery(token)
        .then(setSession)
        .catch(onError)
        .finally(() => {
          setAuthState(state => ({ ...state, isLoading: false }))
        })
    },
    [authService, authState.isLoading, onError, setSession],
  )

  const setOriginToRedirect = React.useCallback((value: string) => {
    if (value.toLowerCase().indexOf('login') >= 0) return
    origin.current = value
  }, [])

  // #endregion

  // #region Effects

  React.useEffect(() => {
    if (!!authState.user) {
      authService.setUser(authState.user)
      return
    }
    authService.removeUser()
  }, [authService, authState.user])

  React.useEffect(() => {
    authService.getSession().then(data => {
      setAuthState(state => ({
        ...state,
        user: data.user,
      }))
    })
  }, [authService])

  React.useEffect(() => {
    const subscribe = firebase.auth().onIdTokenChanged(signInWithProvider)
    return subscribe
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // #endregion

  return (
    <AuthContext.Provider
      value={{
        from: origin.current,
        setFrom: setOriginToRedirect,
        //
        isLoading: authState.isLoading,
        isSigned,
        isAllow,
        //
        user: authState.user,
        setUser: (user: User | null) => {
          setAuthState(state => ({ ...state, user }))
        },
        recovery,
        logout,
        //
        isRegistered: authState.isRegistered,
        registered: authState.isRegistered,
        setRegistered: (value: boolean) => {
          setAuthState(state => ({ ...state, isRegistered: value }))
        },
        //
        onError,
        onInfo,
      }}
    >
      {authState.isLoading && <Loading />}
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
