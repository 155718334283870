import { DpsSubmit } from '../../../domain/models'
import { Dispatcher, Service } from '../../protocols'

export type IDPSSubmitService = Service<DpsSubmit>

/**
 * DPS selection question/answer
 *
 * @category DPS
 */
export class DPSSubmitService implements IDPSSubmitService {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   * @param {DpsSubmit} data
   */
  execute(data: DpsSubmit): void {
    this.dispatcher.execute('dps_submit', data)
  }
}
