import { ViewCoverageQuestions } from '../../../../domain/models'
import { Dispatcher, Service } from '../../../protocols'

export type IViewCoverageQuestionsService = Service<ViewCoverageQuestions>

/**
 * View coverage questions
 *
 * @category Home
 */
export class ViewCoverageQuestionsService
  implements IViewCoverageQuestionsService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   * @param {ViewCoverageQuestions} data
   */
  execute(data: ViewCoverageQuestions): void {
    this.dispatcher.execute(
      'Area logada web - Visualização de detalhamento de como funcionam as coberturas',
      data,
    )
  }
}
