import { SimulationIncomeCoverage } from '../../../domain/models'
import { Dispatcher, Service } from '../../protocols'

export type ISimulationIncomeService = Service<SimulationIncomeCoverage>

/**
 * Income simulation
 *
 * @category Simulation
 */
export class SimulationIncomeService implements ISimulationIncomeService {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @param {SimulationIncomeCoverage} data
   * @function
   */
  execute(data: SimulationIncomeCoverage): void {
    this.dispatcher.execute('income', data)
  }
}
