import React from 'react'

import { Utils } from '../../../utils'
import { Input } from '../Input'
import { InputCPFCNPJProps } from './InputCPFCNPJ.props'

const InputCPFCNPJ: React.VFC<InputCPFCNPJProps> = ({
  children,
  value,
  onChange,
  ...rest
}) => {
  const [inputValue, setInputValue] = React.useState(
    Utils.formatter.formatCPFCNPJ(value as string),
  )

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const targetValue = Utils.sanitizer.onlyNumber(event.target.value)

    if (targetValue.length <= 14) {
      const getFirst14Characters = targetValue.slice(0, 14)
      setInputValue(Utils.formatter.formatCPFCNPJ(getFirst14Characters))
      onChange?.(event)
    }
  }

  return (
    <Input
      label="CPF ou CNPJ"
      value={inputValue}
      placeholder="CPF ou CNPJ"
      {...rest}
      onChange={handleChange}
      type="tel"
    />
  )
}

export default InputCPFCNPJ
