import React from 'react'

import { InputNumber } from '../InputNumber'
import { InputWeightProps } from './InputWeight.props'

const InputWeight = ({ children, ...rest }: InputWeightProps) => {
  return <InputNumber label="Peso" placeholder="em kg" {...rest} />
}

export default InputWeight
