import { Services } from '@azos/core'
import { IPaymentsRepository } from '@data/repositories'
import { BillingServiceSource } from '@domain/models'
import dayjs from 'dayjs'

type Params = {
  id: string
  proposalId: string
  billingService: BillingServiceSource
}
type Response = {
  expiresAt?: string
  possibleDates: string[]
}

export type IListPossibleExpiryDatesService = Services<Params, Response>

export class ListPossibleExpiryDatesService
  implements IListPossibleExpiryDatesService
{
  constructor(private readonly paymentsRepository: IPaymentsRepository) {}

  async execute({
    billingService,
    proposalId,
    ...params
  }: Params): Promise<Response> {
    if (billingService === BillingServiceSource.VINDI) {
      const vindiExpiryDates = await this.paymentsRepository.getDueDateList({
        proposalId,
      })

      return {
        possibleDates: vindiExpiryDates.periods.filter(period => {
          const periodDay = dayjs(period).format('D')

          return +periodDay % 5 === 0
        }),
      }
    }

    return await this.paymentsRepository.listPossibleExpiryDates(params)
  }
}
