import React from 'react'

import { Typography } from '@Azos-Seguros/apollo'
import { useRouter } from 'next/router'

import { EmailRegister } from '../../molecules'
import { SignUpProps } from './SignUpV2.props'
import { Button } from './SignUpV2.styles'

const SignUp: React.VFC<SignUpProps> = ({ hiddenSignIn = false }) => {
  const router = useRouter()

  const handleRedirectClick = () => {
    router.push('/login')
  }

  return (
    <>
      {!hiddenSignIn && (
        <Button
          onClick={handleRedirectClick}
          icon="icon-arrow-left"
          variant="tertiary"
        >
          Voltar
        </Button>
      )}
      <Typography variant="h1">Cadastre-se aqui</Typography>
      <EmailRegister mt={4} />
    </>
  )
}

export default SignUp
