import React from 'react'

import { BaseComponent } from '../../../models'

type BaseProps = React.HTMLAttributes<HTMLButtonElement> & BaseComponent

export type ButtonSelectProps = BaseProps & {
  name?: string
  title: string
  description?: string
  startIcon?: React.ReactNode
  endIcon?: React.ReactNode
}
