import { ClickContactHelp } from '../../../../domain/models'
import { Dispatcher, Service } from '../../../protocols'

export type IClickContactHelpService = Service<ClickContactHelp>

/**
 * Click Contact Help
 *
 * @category Home
 */
export class ClickContactHelpService implements IClickContactHelpService {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   * @param {ClickContactHelp} data
   */
  execute(data: ClickContactHelp): void {
    this.dispatcher.execute(
      'Area logada web - Clicou em contato na tela de solicitar ajuda',
      data,
    )
  }
}
