import { IHubRepository } from '../../../../data/repositories'
import {
  CreateContactNotify,
  HubCreateContactNotifyRequest,
} from '../../../../domain'

export class FakeHubRepository implements IHubRepository {
  params: any
  output: any

  async createContactNotify(
    params: HubCreateContactNotifyRequest,
  ): Promise<CreateContactNotify.Response> {
    this.params = params
    return this.output
  }
}
