import React from 'react'

import { Modal, useWindowSize, withModal } from '@azos/shared'
import { ButtonSelect } from '@azos/shared/src/components/v2'

import { GuardiansContext } from '../GuardiansContext/GuardiansContext'
import { GuardianOptionsModalProps } from './GuardianOptionsModal.props'
import { Root } from './GuardianOptionsModal.styles'

const GuardianOptionsModal: React.VFC<GuardianOptionsModalProps> = ({
  open,
  onClose,
}) => {
  const { width } = useWindowSize()

  const isMobile = React.useMemo(() => width < 768, [width])

  const {
    setModalEditGuardian,
    isEditingGuardian,
    isDeletingGuardian,
    setModalDeleteGuardian,
    setIsDeletingGuardian,
  } = React.useContext(GuardiansContext)

  const onSubmitDelete = () => {
    setModalDeleteGuardian(true)
    setIsDeletingGuardian(false)
  }

  return (
    <Modal
      variant={isMobile ? 'bottomDrawer' : 'modal'}
      open={open}
      onClose={onClose}
      showHeader={false}
    >
      <Root>
        <div className="modal-content__button-group">
          <ButtonSelect
            className="modal-content__button-group__actions"
            title="Editar guardião"
            startIcon={<i className="icon-edit" />}
            endIcon={<i className="icon-arrow-topright" />}
            onClick={() => setModalEditGuardian(true)}
          />
          <ButtonSelect
            className="modal-content__button-group__actions"
            title="Excluir guardião"
            startIcon={<i className="icon-delete" />}
            endIcon={<i className="icon-arrow-topright" />}
            onClick={onSubmitDelete}
          />
        </div>
      </Root>
    </Modal>
  )
}

export default withModal<GuardianOptionsModalProps>(GuardianOptionsModal)
