import React from 'react'

import { Typography, useNotification } from '@Azos-Seguros/apollo'
import { useAuth } from '@azos/account'
import analytics from '@azos/analytics'
import { PolicyCancelUserType } from '@azos/analytics/src/domain/models'
import { CardCopyText, Modal, useMediaQuery, Utils } from '@azos/shared'
import { Button, ButtonSelect } from '@azos/shared/src/components/v2'
import { azosEmail, azosPhone, formattedAzosPhone } from '@main/config/contact'

import { ErrorModalProps } from './ErrorModal.props'
import { Root } from './ErrorModal.styles'

const ErrorModalProps: React.FCC<ErrorModalProps> = ({ open, onClose }) => {
  const { isMobile } = useMediaQuery()
  const { user } = useAuth()
  const { addNotification } = useNotification()

  const isBroker = React.useMemo(
    () => user?.isBroker || user?.isInsuredByBroker,
    [user],
  )

  const analyticsType = isBroker
    ? PolicyCancelUserType.BROKER
    : PolicyCancelUserType.B2C

  const onSuccessCopy = (copyType: string) => {
    addNotification({
      variant: 'success',
      description: `${copyType} copiado com sucesso`,
    })

    analytics.dashboard.policy.cancel.actionsFeedbackModal.execute({
      type: analyticsType,
    })
  }

  const azosPhoneContact = () => {
    window.open(`tel:${azosPhone}`)

    analytics.dashboard.policy.cancel.actionsFeedbackModal.execute({
      type: analyticsType,
    })
  }

  const azosWhatsAppContact = () => {
    window.open(`https://api.whatsapp.com/send?phone=${azosPhone}`)

    analytics.dashboard.policy.cancel.actionsFeedbackModal.execute({
      type: analyticsType,
    })
  }

  const azosEmailContact = () => {
    window.open(`mailto:${azosEmail}`)

    analytics.dashboard.policy.cancel.actionsFeedbackModal.execute({
      type: analyticsType,
    })
  }

  const formattedbrokerUser = Utils.formatter.formatPhone(
    user?.brokerUser?.phone ?? '',
  )

  return (
    <Modal
      open={open}
      onClose={onClose}
      variant={isMobile ? 'bottomDrawer' : 'modal'}
      maxWidth={isMobile ? undefined : '480px'}
      showCloseButton={false}
      showHeader={false}
    >
      <Root>
        <div className="error-modal">
          <Typography className="error-modal__title" variant="bodyBold">
            Solicitação não enviada
          </Typography>
        </div>
        <Typography variant="body3" className="error-modal__description">
          Parece que ocorreu um erro ao enviar a sua solicitação de cancelamento
          de apólice.
          <br />
          <br />
          {user?.isInsuredByBroker
            ? 'Tente novamente mais tarde ou entre em contato com seu corretor(a):'
            : 'Pedimos que tente novamente mais tarde ou entre em contato com a nossa Central de Atendimento:'}
        </Typography>

        {user?.isInsuredByBroker ? (
          <div className="error-modal__content--broker">
            <div className="error-modal__content--broker__title">
              <i className="icon-briefcase" />
              <Typography variant="bodyBold3">
                {user?.brokerUser?.fullName}
              </Typography>
            </div>
            <hr />
            {isMobile && (
              <div className="error-modal__content--broker__contact">
                <div className="error-modal__content--broker__contact--phone">
                  <i className="icon-dial-phone" />
                  <Typography
                    variant="captionBold"
                    onClick={() =>
                      window.open(`tel:${user?.brokerUser?.phone}`)
                    }
                  >
                    Ligar
                  </Typography>
                </div>
                <div className="error-modal__content--broker__contact--email">
                  <i className="icon-email" />
                  <Typography
                    variant="captionBold"
                    onClick={() =>
                      window.open(`mailto:${user?.brokerUser?.email}`)
                    }
                  >
                    E-mail
                  </Typography>
                </div>
              </div>
            )}

            {!isMobile && (
              <>
                <CardCopyText
                  startIcon="icon-dial-phone"
                  value={user.brokerUser?.phone as string}
                  customLabel={formattedbrokerUser}
                  className="error-modal__card-copy"
                  shouldShowSuccessLabel={false}
                  onClick={() => onSuccessCopy('Telefone')}
                />
                <CardCopyText
                  startIcon="icon-email"
                  value={user.brokerUser?.email as string}
                  className="error-modal__card-copy"
                  shouldShowSuccessLabel={false}
                  onClick={() => onSuccessCopy('E-mail')}
                />
              </>
            )}
          </div>
        ) : (
          <div className="error-modal__content">
            <div className="error-modal__copy-content">
              {!isMobile && (
                <>
                  <CardCopyText
                    startIcon="icon-dial-phone"
                    value={azosPhone}
                    customLabel={formattedAzosPhone}
                    className="error-modal__card-copy"
                    shouldShowSuccessLabel={false}
                    onClick={() => onSuccessCopy('Telefone')}
                  />
                  <CardCopyText
                    startIcon="icon-email"
                    value={azosEmail}
                    className="error-modal__card-copy"
                    shouldShowSuccessLabel={false}
                    onClick={() => onSuccessCopy('E-mail')}
                  />
                </>
              )}
              {isMobile && (
                <>
                  <ButtonSelect
                    title="Fazer uma ligação"
                    startIcon={<i className="icon-dial-phone" />}
                    className="error-modal__button-select"
                    endIcon={<i className="icon-arrow-topright" />}
                    onClick={azosPhoneContact}
                  />
                </>
              )}
              <ButtonSelect
                title="Conversar no whatsapp"
                startIcon={<i className="icon-whatsapp" />}
                className="error-modal__button-select"
                endIcon={<i className="icon-arrow-topright" />}
                onClick={azosWhatsAppContact}
              />
              {isMobile && (
                <>
                  <ButtonSelect
                    title="Enviar um e-mail"
                    startIcon={<i className="icon-email" />}
                    className="error-modal__button-select"
                    endIcon={<i className="icon-arrow-topright" />}
                    onClick={azosEmailContact}
                  />
                </>
              )}
            </div>
          </div>
        )}

        <Button onClick={onClose} fullWidth className="error-modal__button">
          Fechar
        </Button>
      </Root>
    </Modal>
  )
}

export default ErrorModalProps
