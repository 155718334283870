import { css, styled } from '@azos/shared'

export const Root = styled('div')(
  ({ theme }) => css`
    .modal-content {
      &__button-group {
        margin-top: 40px;
        display: flex;
        flex-direction: column;

        &__actions {
          background: #f3f4f6;
          padding: 18px;
          margin-bottom: 8px;
          border-radius: 0px;

          .icon-arrow-topright {
            font-size: 24px;
            color: #a3aab9;
          }
        }
      }
    }
  `,
)
