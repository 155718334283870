import { User } from '@azos/account'
import { ModalProps } from '@azos/shared'

export type RequestBrokerAdapterProps = ModalProps & {
  onSuccess?: () => void
  onError?: () => void
  showNotification?: boolean
}

export type RequestBrokerProps = RequestBrokerAdapterProps & {
  user: User | null
}

export type RequestBrokerValues = {
  fullName: string
  email: string
  phone: string
}

export const initialValues = {
  fullName: '',
  email: '',
  phone: '',
}
