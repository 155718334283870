export enum SideMenu {
  MINHAS_APOLICES = 'minhas-apolices',
  TUDO_SOBRE_SEGURO = 'onboarding',
  FALE_COM_SEU_CORRETOR = 'card-de-corretor',
  SIMULAR_SEGURO = 'simular-seguro',
  SOLICITAR_CORRETOR = 'solicitar-corretor',
  BENEFICIOS = 'beneficios',
  MEUS_GUARDIOES = 'meus-guardioes',
  MINHAS_INDICACOES = 'minhas-indicacoes',
  CENTRAL_DE_ATENDIMENTO = 'central-de-atendimento',
  CENTRAL_DE_DUVIDAS = 'central-de-duvidas',
  CONDICOES_GERAIS = 'condicoes-gerais',
  PORTAL_DA_TRANSPARENCIA = 'portal-da-transparencia',
  BAIXE_APP_AZOS = 'baixe-app-azos',
  AJUDA = 'ajuda',
  SAIR = 'sair',
}

/**
 * Side Menu Homepage
 *
 * @alias SideMenuHomepage
 */

export type SideMenuHomepage = {
  /**
   * Action
   */
  action: SideMenu
}
