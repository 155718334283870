import { css, styled } from '../../../../styles'

export const Root = styled('div')(
  ({ theme }) => css`
    .insured-content {
      &__button {
        margin-top: 24px;
      }
    }
  `,
)
