import React from 'react'

import { useNotification } from '@Azos-Seguros/apollo'
import { useAuth } from '@azos/account'
import analytics from '@azos/analytics'
import { makeLoadUserProfileService } from '@azos/core'
import { Modal, withModal } from '@azos/shared'
import { SelectOption, Text } from '@azos/shared/src/components/v2'
import { Formik } from 'formik'
import { isEqualWith } from 'lodash'
import {
  makeListStatesService,
  makeUpdateUserService,
} from 'main/factories/services'

import { UserEditConfirmDiscardModal } from './components/UserEditConfirmDiscardModal'
import { UserEditPersonalDataForm } from './components/UserEditPersonalDataForm'
import {
  initialValues,
  UserEditPersonalDataCardProps,
  UserEditPersonalDataValues,
} from './UserEditPersonalDataCard.props'
import { Root } from './UserEditPersonalDataCard.styles'
import { validationSchema } from './UserEditPersonalDataCard.validation'

const updateUserService = makeUpdateUserService()
const loadUserProfileService = makeLoadUserProfileService()

const UserEditPersonalDataCard: React.FCC<UserEditPersonalDataCardProps> = ({
  onClose,
  onUpdate,
  ...props
}) => {
  const { addNotification } = useNotification()
  const { user, setUser } = useAuth()
  const [initialFormValues, setInitialFormValues] =
    React.useState<UserEditPersonalDataValues>(initialValues)

  const [isShowingConfirmDiscardModal, setIsShowingConfirmDiscardModal] =
    React.useState(false)

  const [isLoadingState, setIsLoadingState] = React.useState(false)
  const [states, setStates] = React.useState<SelectOption[]>([])

  const getStateByAcronym = React.useCallback(
    (uf: string) => {
      return (states.find(state => state.value === uf)?.label ?? uf).toString()
    },
    [states],
  )

  const fetchStates = React.useCallback(() => {
    setIsLoadingState(true)
    makeListStatesService()
      .execute()
      .then(response =>
        setStates(
          response.map(state => ({
            value: state.acronym,
            label: state.name,
          })),
        ),
      )
      .then(() => setIsLoadingState(false))
      .catch(() => {})
  }, [])

  React.useEffect(() => {
    analytics.dashboard.personalData.viewEditPersonalData.execute()
  }, [])

  React.useEffect(() => {
    fetchStates()
  }, [fetchStates])

  React.useEffect(() => {
    if (user && user?.insuredUser && states.length > 0) {
      const insured = user.insuredUser
      const state = getStateByAcronym(insured.address.state)

      const initialData = {
        fullName: insured.fullName,
        socialName: null as null | string,
        maritalStatus: insured.maritalStatus,
        phone: insured.phone,
        email: insured.email,
        cep: insured.address.zipCode,
        uf: insured.address.state,
        state: state,
        city: insured.address.city,
        street: insured.address.street,
        neighborhood: insured.address.neighborhood,
        number: insured.address.number.toString(),
        complement: insured.address.complement,
      }

      if (insured?.socialName) {
        initialData.socialName = insured.socialName
      }

      setInitialFormValues(initialData)
    }
  }, [getStateByAcronym, states.length, user])

  const handleSubmit = async ({
    uf,
    state,
    ...values
  }: UserEditPersonalDataValues) => {
    const payload = {
      ...values,
      state: uf,
    }

    try {
      await updateUserService.execute(payload)

      const { insuredUser } = await loadUserProfileService.execute()

      if (user) {
        setUser({
          ...user,
          displayName: insuredUser.fullName,
          insuredUser,
        })
      }

      addNotification({
        description: 'Alterações enviadas com sucesso.',
        variant: 'success',
      }),
        onClose?.()
    } catch (err) {
      addNotification({
        description:
          'Falha ao salvar as alterações. Por favor, tente novamente.',
        variant: 'error',
      })
    }

    onUpdate()
  }

  const onCloseEdit = (
    initialValues: UserEditPersonalDataValues,
    values: UserEditPersonalDataValues,
  ) => {
    const valuesHasChanged = !isEqualWith(initialValues, values)

    if (valuesHasChanged) {
      setIsShowingConfirmDiscardModal(true)
      return
    }

    onClose?.()
  }

  return (
    <>
      <Formik
        initialValues={initialFormValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ initialValues, values }) => (
          <Modal
            {...props}
            onClose={() => onCloseEdit(initialValues, values)}
            noGutter
            variant="drawer"
            showHeader={false}
          >
            <Root>
              <div className="user-edit-personal-data__header">
                <button
                  className="user-edit-personal-data__button"
                  onClick={() => onCloseEdit(initialValues, values)}
                >
                  <i className="icon-chevron-left" />
                </button>
                <Text variant="h5" className="user-edit-personal-data__title">
                  Editar endereço
                </Text>
              </div>
              <UserEditPersonalDataForm
                isLoadingState={isLoadingState}
                statesList={states}
              />
            </Root>
          </Modal>
        )}
      </Formik>
      <UserEditConfirmDiscardModal
        open={isShowingConfirmDiscardModal}
        onClose={() => setIsShowingConfirmDiscardModal(false)}
        onConfirm={() => {
          setIsShowingConfirmDiscardModal(false)
          onClose?.()
        }}
      />
    </>
  )
}

export default withModal<UserEditPersonalDataCardProps>(
  UserEditPersonalDataCard,
)
