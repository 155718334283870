import { RemoveCoverageProduct } from '../../../../../domain/models'
import { Dispatcher, Service } from '../../../../protocols'

export type IUpsellRemoveCoverageProductService = Service<RemoveCoverageProduct>

/**
 * Remove Coverage Product
 *
 * @category Upsell
 */
export class UpsellRemoveCoverageProductService
  implements IUpsellRemoveCoverageProductService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   * @param {RemoveCoverageProduct} data
   */
  execute(data: RemoveCoverageProduct): void {
    this.dispatcher.execute('Upsell - Remova o produto em cobertura', data)
  }
}
