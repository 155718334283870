import { css, styled } from '../../styles'
import { Utils } from '../../utils'

export type SpecialistAvatarStyleProps = {
  $border: boolean
}

export const Root = styled(
  'div',
  Utils.transient.transientStyleProps,
)<SpecialistAvatarStyleProps>(
  ({ theme, $border }) => css`
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    @media (min-width: ${theme.breakpoints.md}px) {
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    .specialist-avatar {
      &__image {
        width: 42px;
        height: 42px;
        border-radius: 42px;
        overflow: hidden;

        @media (min-width: ${theme.breakpoints.md}px) {
          width: 64px;
          height: 64px;
          border-radius: 64px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        ${$border
          ? css`
              border: 3px solid ${theme.palette.neutral[300]};
            `
          : ''}
      }

      &__text {
        &-content {
          display: flex;
          flex-direction: column;
          align-items: start;
          justify-content: center;
          margin-top: 0;
          margin-left: ${theme.spacing[3]}px;

          @media (min-width: ${theme.breakpoints.md}px) {
            align-items: center;
            margin-top: ${theme.spacing[3]}px;
            margin-left: 0;
          }
        }

        &-name {
          color: ${theme.palette.neutral[500]};
        }

        &-seniority {
          color: ${theme.palette.neutral[300]};
        }
      }
    }
  `,
)
