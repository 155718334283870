/**
 * Select Payment Frequency
 *
 * @alias SelectPaymentFrequency
 */

export enum PaymentFrequencyEventType {
  MONTHLY = 'Mensal',
  ANNUAL = 'Anual',
}

export enum PaymentChannel {
  B2C = 'B2C',
  UPSELL = 'Upsell',
}

export type SelectPaymentFrequency = {
  /**
   * Select payment frequency
   */
  paymentFrequency: PaymentFrequencyEventType
  /**
   * Payment Channel
   */
  paymentChannel: PaymentChannel
}
