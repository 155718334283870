import { ModalProps } from '@azos/shared'
import { UserInsuredData } from '@data/models'

export type UserEditPersonalDataValues = UserInsuredData & { uf: string }

export const initialValues: UserEditPersonalDataValues = {
  fullName: '',
  socialName: '',
  maritalStatus: '',
  phone: '',
  email: '',
  cep: '',
  uf: '',
  state: '',
  city: '',
  street: '',
  neighborhood: '',
  number: '',
  complement: '',
}

export type UserEditPersonalDataCardProps = ModalProps & {
  onUpdate: () => void
}
