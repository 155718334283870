import React from 'react'

import { Modal, withModal } from '@azos/shared'
import { Badge, Text } from '@azos/shared/src/components/v2'
import ProductItem from '@presentation/components/atoms/ProductItem'
import { useFetchPolicyDetails } from '@presentation/hooks/useFetchPolictDetails'

import { PolicyProductModalProps } from './PolicyProductModal.props'
import { Root } from './PolicyProductModal.styles'

const PolicyProductModal: React.FCC<PolicyProductModalProps> = ({
  children,
  policyId,
  open,
  onClose,
}) => {
  const {
    data: policyDetails,
    isLoading,
    isError,
  } = useFetchPolicyDetails(policyId)

  return (
    <Modal open={open} onClose={onClose} showBackButton variant="drawer">
      {!isLoading && !isError ? (
        <Root>
          {policyDetails?.policy.status.map((status, index) => (
            <Badge key={index} color={status.color}>
              {status.label}
            </Badge>
          ))}

          <h3>Apólice: {policyDetails?.policy.policyNumber}</h3>
          <ul className="products">
            {policyDetails?.policy.coverages.map(coverage => (
              <li key={coverage.label}>
                <ProductItem>{coverage.label}</ProductItem>
              </li>
            ))}
          </ul>
          {children}
        </Root>
      ) : (
        children
      )}
    </Modal>
  )
}

export default withModal<PolicyProductModalProps>(PolicyProductModal)
