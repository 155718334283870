import React from 'react'

import analytics from '@azos/analytics'
import { ClickRequestBrokerGuardianType } from '@azos/analytics/src/domain/models'
import { Button, Text } from '@azos/shared/src/components/v2'
import { azosPhone } from '@main/config/contact'
import { RequestBroker } from '@presentation/components/molecules/Broker'
import { env } from 'main/config/env'
import router from 'next/router'

import { NoPolicyProps } from './NoPolicy.props'
import { Root } from './NoPolicy.styles'

const NoPolicy: React.VFC<NoPolicyProps> = ({ isInsured, isGuardian }) => {
  const handleSimulation = () => router.push(env.SIMULATION_URL)

  const [isRequestBroker, setIsRequestBroker] = React.useState(false)
  const [isRequestBrokerError, setIsRequestBrokerError] = React.useState(false)
  const [isRequestBrokerSuccess, setIsRequestBrokerSuccess] =
    React.useState(false)

  const handleRequestBroker = (success: boolean) => {
    setIsRequestBrokerSuccess(false)
    setIsRequestBrokerError(false)

    analytics.dashboard.guardian.clickRequestBrokerGuardian.execute({
      isInsured: !!isInsured,
      isGuardian: !!isGuardian,
      tipo: success
        ? ClickRequestBrokerGuardianType.SUCCESS
        : ClickRequestBrokerGuardianType.ERROR,
    })

    if (success) {
      setIsRequestBrokerSuccess(true)
    } else {
      setIsRequestBrokerError(true)
    }
  }

  return (
    <Root>
      <div className="guardian__no-policy">
        <i className="icon-guardian" />
        <Text variant="bodyBold3" className="guardian__no-policy-title">
          Aqui ficam os seus guardiões
        </Text>
        <Text className="guardian__no-policy-description" variant="caption">
          Para adicionar um guardião ou guardiã, você precisa ter uma apólice
          com a Azos. Simule seu seguro ou solicite um corretor.
        </Text>
      </div>
      {isRequestBrokerError && (
        <div
          className="guardian__no-policy--error"
          role="link"
          onClick={() =>
            window.open(
              `https://api.whatsapp.com/send?phone=${azosPhone}`,
              '_blank',
            )
          }
        >
          <i className="icon-exit" />
          <Text
            variant="bodyBold3"
            className="guardian__no-policy--error-title"
          >
            Não foi possível enviar sua solicitação no momento. Se desejar, fale
            com a gente no <a>Whatsapp (11) 5445-1234</a>.
          </Text>
        </div>
      )}
      {isRequestBrokerSuccess && (
        <div className="guardian__no-policy--success">
          <i className="icon-check" />
          <Text
            variant="bodyBold3"
            className="guardian__no-policy--success-title"
          >
            Corretor solicitado com sucesso!
          </Text>
        </div>
      )}
      <div className="guardian__no-policy--button">
        <Button fullWidth onClick={handleSimulation}>
          Simule agora
        </Button>
        {!isInsured && (
          <Button
            fullWidth
            variant="outline"
            onClick={() => setIsRequestBroker(true)}
          >
            Solicitar corretor
          </Button>
        )}
      </div>

      <RequestBroker
        open={isRequestBroker}
        onClose={() => setIsRequestBroker(false)}
        onSuccess={() => handleRequestBroker(true)}
        onError={() => handleRequestBroker(false)}
      />
    </Root>
  )
}

export default NoPolicy
