import { AddToCart } from '../../../domain/models'
import { Dispatcher, Service } from '../../protocols'

export type IAddToCartService = Service<AddToCart>

/**
 * Add to cart
 *
 * @category Cart
 */
export class AddToCartService implements IAddToCartService {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @param {AddToCart} data
   * @function
   */
  execute(data: AddToCart): void {
    this.dispatcher.execute('add_to_cart', data)
  }
}
