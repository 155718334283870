import { css, Utils } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled(
  'ul',
  Utils.transient.transientStyleProps,
)(
  ({ theme }) => css`
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;

    li {
      display: flex;
      align-items: center;

      &.has-action {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        width: 100%;
        & > div:first-of-type {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
      }

      & + li {
        border-top: 1px solid ${theme.palette.neutral[100]};
        margin-top: ${theme.spacing[4]}px;
        padding-top: ${theme.spacing[4]}px;
      }

      &.see-more {
        text-align: center;
        justify-content: center;
        padding-bottom: 6px;
        transition: color 0.2s;

        &:hover {
          color: ${theme.palette.neutral[500]};
        }
      }

      > div {
        flex: 1;
        display: flex;
        flex-direction: column;

        @media (min-width: 500px) {
          flex-direction: row;
          align-items: center;
        }

        > div {
          & + div {
            margin-top: 12px;
          }

          @media (min-width: 500px) {
            margin-right: ${theme.spacing[5]}px;
            & + div {
              margin-top: 0;
            }
          }
        }

        p {
          margin: 0;
          font-size: 16px;
        }

        .policy-date__content {
          display: flex;
          margin-top: 16px;
        }

        .policy {
          width: auto;
        }

        .date {
          margin-top: 0;
        }

        .value {
          display: block;
          white-space: nowrap;
          margin-top: 16px;
        }
      }

      button {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        background: ${theme.palette.neutral[100]};
        height: 40px;
        width: 40px;
        border-radius: 36px;

        i {
          font-size: 1.5rem;
        }
      }
    }
  `,
)
