import React from 'react'

import { useNotification } from '@Azos-Seguros/apollo'
import { useAuth } from '@azos/account'
import { UserPhoto as SharedUserPhoto, UserPhotoProps } from '@azos/shared'
import firebase from 'firebase'

const UserPhoto: React.VFC<Omit<UserPhotoProps, 'onUpdatePhoto'>> = ({
  user,
  ...props
}) => {
  const { setUser } = useAuth()
  const { addNotification } = useNotification()

  const userUpdatePhoto = async (file: File) => {
    const storage = firebase.storage()
    try {
      const upload = await storage
        .ref()
        .child('profileImages')
        .child(`${user?.uid}.jpg`)
        .put(file)

      const photoURL = await upload.ref.getDownloadURL()
      await firebase.auth().currentUser?.updateProfile({ photoURL })
      setUser({ ...(user as any), photoURL })

      addNotification({
        description: 'Foto alterada com sucesso.',
        variant: 'success',
      })
    } catch (e) {
      addNotification({
        description: 'Houve um erro ao alterar a foto',
        variant: 'error',
      })
    }
  }

  return (
    <SharedUserPhoto user={user} onUpdatePhoto={userUpdatePhoto} {...props} />
  )
}

export default UserPhoto
