import { ViewFeedbackModal } from '../../../../../domain/models'
import { Dispatcher, Service } from '../../../../protocols'

export type IViewFeedbackModalService = Service<ViewFeedbackModal>

/**
 * View Feedback Modal
 *
 * @category Dashboard
 */
export class ViewFeedbackModalService implements IViewFeedbackModalService {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @param {ViewFeedbackModal} data
   * @function
   */
  execute(data: ViewFeedbackModal): void {
    this.dispatcher.execute(
      'Area logada web - Visualização dos modais de feedbacks na solicitação de cancelamento',
      data,
    )
  }
}
