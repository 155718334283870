import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled('div')(
  ({ theme }) => css`
    .reset-password-modal {
      &__title {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &__title-content {
        display: block;
        margin: ${theme.spacing[4]}px 0 ${theme.spacing[3]}px;
      }

      &__description {
        display: block;
        margin-bottom: ${theme.spacing[4]}px;
      }

      &__content {
        background: ${theme.palette.neutral[100]};
        padding: 14px;
        display: flex;
        gap: 14px;
        margin-bottom: ${theme.spacing[2]}px;

        &-text {
          font-weight: ${theme.typography.fontWeightBold};
          font-size: 0.75rem;
        }
      }

      &__button-select {
        background: ${theme.palette.neutral[100]};
        border: none;
      }
    }
  `,
)
