import { css, styled, Utils } from '@azos/shared'

type RootProps = {
  $cliclabke: boolean
}

export const Root = styled(
  'div',
  Utils.transient.transientStyleProps,
)<RootProps>(
  ({ theme, $cliclabke }) => css`
    .protected-list-card {
      &__item {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: ${theme.spacing[3]}px;
        background: ${theme.palette.neutral[100]};

        ${$cliclabke &&
        css`
          cursor: pointer;
        `}

        i {
          font-size: 1.5rem;
          color: ${theme.palette.neutral[300]};
        }

        &:not(:first-of-type) {
          border-top: 1px solid ${theme.palette.neutral[200]};
        }

        &-content {
          display: flex;
          flex-direction: column;

          & > * + * {
            margin-top: ${theme.spacing[1]}px;
          }

          &--details {
            display: flex;

            & > * + * {
              margin-left: ${theme.spacing[1]}px;
            }
          }
        }
      }
    }
  `,
)
