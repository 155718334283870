import React from 'react'

import { useMediaQuery } from '@azos/shared'
import { DashboardSliderCardsRedirectPoliciesType } from '@data/models'
import {
  CardPolicyDetails,
  PolicyCardCancel,
  PolicyCardPayment,
} from '@presentation/components/molecules/Policies/PolicyCard/components'
import { CardPolicyCreditCard } from '@presentation/components/molecules/Policies/PolicyEditPayment/components'
import { usePolicyPaymentStatus } from '@presentation/hooks/usePolicyPaymentStatus'
import { useMyPolicies } from '@presentation/providers/MyPoliciesProvider'
import { withPolicyPaymentStatus } from '@presentation/providers/PolicyProvider'
import { formatPresentationPolicy } from '@utils/policies'

import { PolicyListSelectCard } from '../OnboardingManagement/components'
import {
  PolicySelectViewType,
  PolicyViewManagementProps,
} from './PolicyViewManagement.props'

const PolicyViewManagement: React.VFC<PolicyViewManagementProps> = ({
  open,
  onClose,
  viewType,
  policiesList,
  title,
  subtitle,
}) => {
  const { policies: policiesData } = useMyPolicies()
  const { isMobile } = useMediaQuery()
  const { isPaymentChangeSucceeded, setIsPaymentChangeSucceeded } =
    usePolicyPaymentStatus()
  const { refreshPolicies } = useMyPolicies()

  const [policies, setPolicies] = React.useState<
    DashboardSliderCardsRedirectPoliciesType[]
  >([])
  const [hasMultiplePolicies, setHasMultiplesPolicies] = React.useState(false)

  const [isPolicyDetailsOpen, setIsPolicyDetailsOpen] = React.useState(false)
  const [isPolicyCancelOpen, setIsPolicyCancelOpen] = React.useState(false)
  const [isPolicyPaymentOpen, setIsPolicyPaymentOpen] = React.useState(false)

  const [modalCreditCard, setModalCreditCard] = React.useState(false)

  const hasPolicies = React.useMemo(
    () => policies && policies.length > 0,
    [policies],
  )

  React.useEffect(() => {
    if (isPaymentChangeSucceeded) {
      setIsPolicyPaymentOpen(false)
      setIsPaymentChangeSucceeded(false)
      refreshPolicies()
    }
  }, [isPaymentChangeSucceeded, setIsPaymentChangeSucceeded, refreshPolicies])

  React.useEffect(() => {
    if (policiesList) {
      setPolicies(policiesList)
      setHasMultiplesPolicies(policiesList && policiesList.length > 1)
    } else {
      const policiesFormatted = policiesData.map(policy =>
        formatPresentationPolicy(policy),
      )
      setPolicies(policiesFormatted)
      setHasMultiplesPolicies(policiesData && policiesData.length > 1)
    }
  }, [policiesData, policiesList])

  React.useEffect(() => {
    if (open) {
      if (viewType === PolicySelectViewType.change_payment) {
        return setModalCreditCard(true)
      }

      if (viewType === PolicySelectViewType.payment) {
        return setIsPolicyPaymentOpen(true)
      }

      return setIsPolicyDetailsOpen(true)
    }
  }, [viewType, hasMultiplePolicies, open])

  return hasPolicies ? (
    hasMultiplePolicies ? (
      <PolicyListSelectCard
        open={open}
        onClose={onClose}
        viewType={viewType}
        policiesList={policiesList}
        title={title}
        subtitle={subtitle}
      />
    ) : (
      <>
        <CardPolicyDetails
          policyId={policies[0]?.policyId ?? ''}
          open={isPolicyDetailsOpen}
          onClose={() => {
            setIsPolicyDetailsOpen(false)
            onClose()
          }}
          onCancel={() => {
            setIsPolicyDetailsOpen(false)
            setIsPolicyCancelOpen(true)
          }}
        />
        <PolicyCardPayment
          policyId={policies[0]?.policyId ?? ''}
          open={isPolicyPaymentOpen}
          onClose={() => {
            setIsPolicyPaymentOpen(false)
            onClose()
          }}
        />
        <PolicyCardCancel
          isMobile={isMobile}
          policyId={policies[0]?.policyId ?? ''}
          open={isPolicyCancelOpen}
          onClose={() => {
            setIsPolicyCancelOpen(false)
            onClose()
          }}
        />
        <CardPolicyCreditCard
          open={modalCreditCard}
          onClose={() => {
            setModalCreditCard(false)
            onClose()
          }}
          id={policies[0]?.policyId ?? ''}
        />
      </>
    )
  ) : null
}

export default withPolicyPaymentStatus(PolicyViewManagement)
