import {
  UpsellClickOtherDiseasesService,
  UpsellContinueSimulationPageProfessionSalaryService,
  UpsellContinueSimulationPageSmokerIMCService,
  UpsellGobackSimulationPageProfessionSalary,
  UpsellGobackSimulationPageSmokerIMC,
  UpsellViewSimulationPageProfessionSalary,
  UpsellViewSimulationPageSmokerIMC,
} from '../../../../../../data/services'
import { makeMixpanelDispatcherEvent } from '../../../../infra'

export const makeUpsellClickOtherDiseasesService = () =>
  new UpsellClickOtherDiseasesService(makeMixpanelDispatcherEvent())

export const makeUpsellContinueSimulationPageSmokerIMCService = () =>
  new UpsellContinueSimulationPageSmokerIMCService(
    makeMixpanelDispatcherEvent(),
  )

export const makeUpsellContinueSimulationPageProfessionSalaryService = () =>
  new UpsellContinueSimulationPageProfessionSalaryService(
    makeMixpanelDispatcherEvent(),
  )

export const makeUpsellGobackSimulationPageProfessionSalary = () =>
  new UpsellGobackSimulationPageProfessionSalary(makeMixpanelDispatcherEvent())

export const makeUpsellGobackSimulationPageSmokerIMC = () =>
  new UpsellGobackSimulationPageSmokerIMC(makeMixpanelDispatcherEvent())

export const makeUpsellViewSimulationPageProfessionSalary = () =>
  new UpsellViewSimulationPageProfessionSalary(makeMixpanelDispatcherEvent())

export const makeUpsellViewSimulationPageSmokerIMC = () =>
  new UpsellViewSimulationPageSmokerIMC(makeMixpanelDispatcherEvent())
