import { css, styled } from '@azos/shared'

export const Root = styled('div')(
  ({ theme }) => css`
    .guardian__empty-state {
      width: 100%;
      height: auto;
      background: #f3f4f6;
      padding: 24px 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 24px 0;

      i {
        font-size: 24px;
      }

      &-title {
        font-size: 0.875rem;
        font-weight: 600;
        margin: 12px 0 8px 0;
      }

      &-description {
        font-size: 0.75rem;
        font-weight: 500;
        margin-bottom: 8px;
      }
    }
  `,
)
