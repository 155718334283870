import React, { useState } from 'react'

import { Typography } from '@Azos-Seguros/apollo'
import { useAuth } from '@azos/account'
import { useMediaQuery, Utils as SharedUtils } from '@azos/shared'
import { Text } from '@azos/shared/src/components/v2'
import { Utils } from 'utils'

import { SensitiveDataModal } from '../SensitiveDataModal'
import {
  PersonalDataChangeableProps,
  PersonalDataChangeableCardProps,
} from './PersonalDataChangeable.props'
import { DataItem, Root } from './PersonalDataChangeable.styles'

const PersonalDataChangeable = ({
  waitingApproval,
  isElegibleToEdit,
}: PersonalDataChangeableCardProps) => {
  const { user } = useAuth()
  const { isMobile } = useMediaQuery()

  const [openSensitiveData, setOpenSensitiveData] = useState(false)

  let customerChangeableData = [
    {
      type: 'Nome completo',
      data: user?.insuredUser?.fullName ?? user?.displayName,
    },
    {
      type: 'Estado civil',
      data: Utils.user.formatMaritalStatus(
        user?.insuredUser?.maritalStatus ?? '',
      ),
      insured: true,
    },
    {
      type: 'Telefone',
      data: SharedUtils.formatter.formatPhone(user?.insuredUser?.phone ?? ''),
      insured: true,
    },
    {
      type: 'E-mail',
      data: user?.insuredUser?.email ?? user?.email,
      withNoDivider: true,
    },
  ]

  if (user?.insuredUser?.socialName) {
    customerChangeableData.splice(1, 0, {
      type: 'Nome social',
      data: user?.insuredUser?.socialName,
    })
  }

  if (!isElegibleToEdit) {
    customerChangeableData = customerChangeableData.filter(
      ({ insured }) => !insured,
    )
  }

  const customerData: PersonalDataChangeableProps = [
    {
      type: 'Data de nascimento',
      data: user?.insuredUser?.birthDate as string,
      append: (
        <i className="icon-help" onClick={() => setOpenSensitiveData(true)} />
      ),
    },
    {
      type: 'Sexo biológico',
      data: user?.insuredUser?.gender,
    },
    {
      type: 'CPF',
      data: SharedUtils.formatter.formatCPF(user?.insuredUser?.cpf ?? ''),
      withNoDivider: true,
    },
  ]

  const address: PersonalDataChangeableProps = [
    {
      type: 'CEP',
      data: SharedUtils.formatter.formatCEP(user?.insuredUser?.address.zipCode),
    },
    {
      type: 'Rua',
      data: user?.insuredUser?.address.street,
      withNoDivider: true,
    },
    {
      type: 'Número',
      data: user?.insuredUser?.address.number.toString(),
      cols: 2,
    },
    {
      type: 'Complemento',
      data: user?.insuredUser?.address.complement,
      cols: 2,
    },
    {
      type: 'Estado',
      data: user?.insuredUser?.address.stateName,
      cols: 2,
      withNoDivider: isMobile ? true : false,
    },
    {
      type: 'Cidade',
      data: user?.insuredUser?.address.city,
      cols: isMobile ? 1 : 2,
    },
    {
      type: 'Bairro',
      data: user?.insuredUser?.address.neighborhood,
      withNoDivider: true,
    },
  ]

  const renderData = (customerData: PersonalDataChangeableProps) => {
    const content = customerData.map((data, index) => {
      const cols: any = data.cols || 1

      const append = (() => {
        if (!data.append) {
          return null
        }
        return (
          <div className="personal-data-changeable__content--append">
            {data.append}
          </div>
        )
      })()
      return (
        <DataItem key={index} $column={cols}>
          <div className="personal-data-changeable__content--text">
            <Typography variant="caption">{data.type}</Typography> <br />
            <Typography variant="bodyBold3">{data.data}</Typography>
            {append}
          </div>
          {!data.withNoDivider && (
            <hr className="personal-data-changeable__content--divider" />
          )}
        </DataItem>
      )
    })
    return <div className="personal-data-changeable__grid">{content}</div>
  }

  return (
    <Root>
      <div className="personal-data-changeable">
        <div className="personal-data-changeable__content">
          <Text
            variant="body2"
            className="personal-data-changeable__content-text"
          >
            Dados <strong>pessoais</strong>
          </Text>
          <div className="personal-data-changeable__content--info">
            {renderData(customerChangeableData)}
          </div>

          {isElegibleToEdit && (
            <>
              <div className="personal-data-changeable__content--info">
                {renderData(customerData)}
              </div>
              <Text
                variant="body2"
                className="personal-data-changeable__content-text"
              >
                Endereço <strong>residencial</strong>
              </Text>
              <div className="personal-data-changeable__content--info">
                {renderData(address)}
              </div>
            </>
          )}
        </div>
      </div>
      <SensitiveDataModal
        open={openSensitiveData}
        onClose={() => setOpenSensitiveData(false)}
      />
    </Root>
  )
}

export default PersonalDataChangeable
