const clearSale = uuid => `
;(function (a, b, c, d, e, f, g) {
  a['CsdpObject'] = e
  ;(a[e] =
    a[e] ||
    function () {
      ;(a[e].q = a[e].q || []).push(arguments)
    }),
    (a[e].l = 1 * new Date())
  ;(f = b.createElement(c)), (g = b.getElementsByTagName(c)[0])
  f.async = 1
  f.src = d
  g.parentNode.insertBefore(f, g)
})(window, document, 'script', '//device.clearsale.com.br/p/fp.js', 'csdp')
var session_id = '${uuid}'
csdp('app', 'g6jyr13zkoj9nnaj5wv8')
csdp('sessionid', session_id)
`

export default clearSale
