import React from 'react'

import { useQuery } from '@azos/core'

import { useAuth } from './useAuth'

const PARAM_TOKEN = 'token'

const useDashboardToken = () => {
  const { getQueryParam, removeQueryParam } = useQuery()
  const { recovery, isLoading } = useAuth()

  React.useEffect(() => {
    const token = getQueryParam(PARAM_TOKEN)
    if (token && !isLoading) {
      removeQueryParam(PARAM_TOKEN)
      recovery(token)
    }
  }, [getQueryParam, isLoading, recovery, removeQueryParam])
}

export { useDashboardToken }
