import { ClickPolicyCard } from '../../../../../domain/models'
import { Dispatcher, Service } from '../../../../protocols'

export type IClickPolicyCardService = Service<ClickPolicyCard>

/**
 * Click Policy Card
 *
 * @category Callpage
 */
export class ClickPolicyCardService implements IClickPolicyCardService {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @param {ClickPolicyCard} data
   * @function
   */
  execute(data: ClickPolicyCard): void {
    this.dispatcher.execute(
      'Area logada web - Clique no card de apólice na home page',
      data,
    )
  }
}
