import { HttpClient, UnexpectedError } from '@azos/core'
import { ListProtectedUseCase } from '@domain/usecases'
import HttpStatusCode from 'http-status-codes'

export type IProtectedRepository = ListProtectedUseCase

export class ProtectedRepository implements IProtectedRepository {
  constructor(private readonly httpClient: HttpClient) {}

  async list(
    params: ListProtectedUseCase.Params,
  ): Promise<ListProtectedUseCase.Response> {
    const httpResponse = await this.httpClient.request({
      url: `/protected`,
      method: 'get',
      body: params,
    })

    const response = httpResponse.data

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
        return response as ListProtectedUseCase.Response
      default:
        throw new UnexpectedError()
    }
  }
}
