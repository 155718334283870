import React from 'react'

import { SvgIconProps } from '../components/SvgIcon'

const Icon = ({ ...rest }: SvgIconProps) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path d="M12.5 25L7.5 33.3333H28.3333L33.3333 25H12.5Z" fill="#C2EBC2" />
    <path
      d="M14.1667 33.3333H5V6.66668H18.3333M28.3333 3.33334V18.927M28.3333 18.927L36.6667 11.5468M28.3333 18.927L20 11.5468M7.5 33.3333L12.5 25H33.3333L28.3333 33.3333H7.5Z"
      stroke="#005700"
      strokeWidth="2"
    />
  </svg>
)

export default Icon
