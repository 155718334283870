import React from 'react'

import { useNotification } from '@Azos-Seguros/apollo'
import { useAuth } from '@azos/account'
import analytics from '@azos/analytics'
import { ViewResetPasswordStatus } from '@azos/analytics/src/domain/models'
import { Modal, useMediaQuery, useNotify, withModal } from '@azos/shared'
import { Button, Text } from '@azos/shared/src/components/v2'
import firebase from 'firebase'

import { ResetPasswordModalProps } from './ResetPasswordModal.props'
import { Root } from './ResetPasswordModal.styles'

const ResetPasswordModal: React.FCC<ResetPasswordModalProps> = ({
  onClose,
  ...props
}) => {
  const { isMobile } = useMediaQuery()
  const { addNotification } = useNotification()
  const { user } = useAuth()

  React.useEffect(() => {
    analytics.dashboard.personalData.viewResetPassword.execute({
      status: ViewResetPasswordStatus.RESET,
    })
  }, [])

  const handlePasswordReset = React.useCallback(
    async (email: string) => {
      try {
        await firebase.auth().sendPasswordResetEmail(email)
        addNotification({
          variant: 'success',
          description: 'Solicitação enviada com sucesso. Confira o seu e-mail.',
        })
        onClose?.()
      } catch (e: any) {
        addNotification({
          variant: 'error',
          description: 'Falha ao redefinir senha. Por favor, tente novamente.',
        })
      }
    },
    [addNotification, onClose],
  )

  return (
    <Modal
      {...props}
      onClose={onClose}
      variant={isMobile ? 'bottomDrawer' : 'modal'}
      maxWidth={isMobile ? undefined : '480px'}
      showCloseButton={false}
      showHeader={false}
    >
      <Root>
        <div className="reset-password-modal__title">
          <Text
            className="reset-password-modal__title-content"
            variant="bodyBold"
          >
            Redefinir senha
          </Text>
          {isMobile && <i className="icon-exit" onClick={onClose} />}
        </div>
        <Text variant="body2" className="reset-password-modal__description">
          Você receberá um link no seu e-mail abaixo para redefinir a sua senha:
        </Text>

        <div className="reset-password-modal__content">
          <i className="icon-email" />
          <Text className="reset-password-modal__content-text">
            {user?.email}
          </Text>
        </div>

        <Button
          onClick={() => handlePasswordReset(user?.email as string)}
          fullWidth
        >
          Solicitar redefinição
        </Button>
      </Root>
    </Modal>
  )
}

export default withModal<ResetPasswordModalProps>(ResetPasswordModal)
