import { Box, BoxProps } from '@Azos-Seguros/apollo'
import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled(Box)<BoxProps>(
  ({ theme }) => css`
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;

    p {
      font-size: 0.875rem;
    }

    .modal-referral {
      &__content {
        padding: ${theme.spacing[4]}px ${theme.spacing[3]}px;
        background: ${theme.palette.neutral[50]};
        flex: 1;
      }
    }

    .referral__tips {
      margin: ${theme.spacing[5]}px 0 ${theme.spacing[5]}px;
      background: ${theme.palette.neutral[100]};
      border: 1px solid ${theme.palette.neutral[200]};
      display: flex;

      i {
        margin-top: ${theme.spacing[1]}px;
      }

      p {
        margin-left: 10px;
      }
    }

    div.referral__buttons {
      width: 100%;

      display: flex;
      flex-direction: column;

      > button + button {
        margin-top: 10px;
      }
    }
  `,
)
