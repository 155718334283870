import styled from '@emotion/styled'

import { Utils } from '../../utils'

interface StylesProps {
  $align: 'left' | 'center' | 'right'
  $titleSize: string
  $iconColor:
    | 'primary'
    | 'secondary'
    | 'grey'
    | 'error'
    | 'warning'
    | 'success'
    | string
}

interface GetIconColorProps extends Omit<StylesProps, '$align' | '$titleSize'> {
  theme: any
}

const getIconColor = ({ theme, $iconColor }: GetIconColorProps) => {
  const obj = {
    primary: theme.palette.primary.main,
    secondary: theme.palette.secondary.main,
    grey: theme.palette.neutral[300],
    error: theme.palette.status.error.main,
    warning: theme.palette.status.warning.main,
    success: theme.palette.primary.main,
    default: $iconColor,
  } as any

  return obj[$iconColor] ? obj[$iconColor] : obj.default
}

const getAlignItems = ({
  $align,
}: Omit<StylesProps, '$iconColor' | '$titleSize'>) => {
  if ($align === 'center') return 'center'
  if ($align === 'right') return 'flex-end'
  return 'flex-start'
}

/* prettier-ignore */
export const Root = styled('div', Utils.transient.transientStyleProps)<StylesProps>(({ theme, $align, $iconColor, $titleSize }) => `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${getAlignItems({ $align })};
  text-align: ${$align};

  .empty-icon {
    font-size: 48px;
    margin-bottom: 20px;
    color: ${getIconColor({ theme, $iconColor })};
  }

  .title {
    color: ${theme.palette.neutral[500]};
    font-size: ${$titleSize};
    line-height: 1;
    font-weight: 600;
    margin: 0;
    padding: 0;

    & + .content {
      margin-top: 16px;
    }
  }

  .content {
    p {
      margin: 0 0 32px;
      padding: 0;

      &:last-child {
        margin: 0;
      }
    }
  }
`,
)
