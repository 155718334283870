export enum PaymentMethod {
  CREDIT_CARD = 'credit_card',
  PIX = 'pix',
  BANK_SLIP = 'bank_slip',
}

export enum PaymentBillingStatus {
  pending = 'pending',
  processing = 'processing',
  error = 'error',
  finished = 'finished',
}

export enum PaymentInvoicesStatus {
  pending = 'pending',
  paid = 'paid',
  expired = 'expired',
  canceled = 'canceled',
  partially_paid = 'partially_paid',
  refunded = 'refunded',
  in_protest = 'in_protest',
  chargeback = 'chargeback',
  manual_refunded = 'manual_refunded',
  in_analysis = 'in_analysis',
}

export enum PaymentInvoiceLastTransactionStatus {
  success = 'success',
  error = 'error',
  pending = 'pending',
}

export type PaymentParams = {
  paymentMethod: PaymentMethod
  price: number
  paymentToken?: string
}

export type PaymentCreditCardParams = {
  first_name: string
  last_name: string
  number: string
  month: string
  year: string
  cvv: string
}

export type PaymentInvoiceStatusCharge = {
  lastTransaction: {
    status: string
  }
}

export type PaymentInvoiceStatusResponse = {
  paymentMethod: PaymentMethod
  dueDate: string
  url: string
  value: number
  status: PaymentInvoicesStatus
  paymentData: Partial<{
    pix: {
      qrcode: string
      qrcodeText: string
    }
    bankSlip: {
      barcode: string
      digitableLine: string
    }
  }>
  charges: PaymentInvoiceStatusCharge[]
}

export type Creditcard = {
  ccname: string
  ccnumber: string
  cvv: string
  exp_date: string
}

export type CreditcardData = {
  number: string
  month: string
  year: string
  first_name: string
  last_name: string
  cvv: string
}
