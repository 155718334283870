import React from 'react'

import { useNotification } from '@Azos-Seguros/apollo'
import { useAuth } from '@azos/account'
import analytics from '@azos/analytics'
import {
  ReferralContextType,
  ReferralUserMaritalStatus,
} from '@azos/analytics/src/domain/models'
import { useNotify } from '@azos/shared'
import { Button, Input, InputPhone, Text } from '@azos/shared/src/components/v2'
import { Formik, Form } from 'formik'

import {
  BrokerReferralFormValues,
  initialValues,
} from './BrokerReferralContent.props'
import { Root } from './BrokerReferralContent.styles'
import { validationSchema } from './BrokerReferralContent.validation'

const BrokerReferralContent: React.FCC = () => {
  const { user } = useAuth()
  const { addNotification } = useNotification()

  const isMarried = user?.insuredUser?.maritalStatus === 'married'

  const handleSubmit = (values: BrokerReferralFormValues) => {
    analytics.dashboard.referral.clickBrokerShare.execute({
      referral: {
        nome: values.name,
        telefone: values.phone,
      },
      type: ReferralContextType.SHARE,
      status: isMarried
        ? ReferralUserMaritalStatus.MARRIED
        : ReferralUserMaritalStatus.OTHERS,
      isInsured: !!user?.insuredUser,
    })

    addNotification({
      variant: 'success',
      description:
        'Corretor indicado com sucesso. Indique seu corretor para mais pessoas.',
    })
  }

  return (
    <Root>
      <Text variant="body2" className="broker-referral-content__description">
        Deixe abaixo o contato de quem deseja trazer para a Azos que o seu
        corretor entrará em contato com ele:
      </Text>

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnMount
      >
        {({ values, errors, touched, handleChange, handleBlur, isValid }) => (
          <Form className="broker-referral-content__form">
            <Input
              fullWidth
              id="name"
              name="name"
              label="Nome"
              error={touched.name && !!errors.name}
              helperText={touched.name && errors.name}
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <InputPhone
              fullWidth
              id="phone"
              name="phone"
              label="Telefone"
              error={touched.phone && !!errors.phone}
              helperText={touched.phone && errors.phone}
              value={values.phone}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <Button
              fullWidth
              type="submit"
              className="broker-referral-content__button"
              disabled={!isValid}
            >
              Indicar seu corretor
            </Button>
          </Form>
        )}
      </Formik>
    </Root>
  )
}

export default BrokerReferralContent
