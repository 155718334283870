import React from 'react'

import { Input as ApolloInput } from '@Azos-Seguros/apollo'

import { NoSSR } from '../../noSSR'
import { InputProps } from './Input.props'

const Input: React.FCC<InputProps> = ({
  children,
  name = 'input',
  parentName,
  innerRef,
  helperText,
  ...rest
}) => {
  return (
    <NoSSR>
      <ApolloInput
        name={name}
        ref={innerRef}
        helperText={helperText ? helperText : undefined}
        {...rest}
      >
        {children}
      </ApolloInput>
    </NoSSR>
  )
}

export default Input
