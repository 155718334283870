import React from 'react'

import analytics from '@azos/analytics'
import {
  ReferralContextType,
  ReferralUserMaritalStatus,
} from '@azos/analytics/src/domain/models'
import { Text, theme } from '@azos/shared'
import copy from 'copy-to-clipboard'
import Image from 'next/image'

import { BrokerContent, InsuredContent } from './components'
import { referralConfig } from './ReferralCard.config'
import {
  ReferralCardHandle,
  ReferralCardProps,
  UserContentType,
} from './ReferralCard.props'
import { Root } from './ReferralCard.styles'

const ReferralCard: React.ForwardRefRenderFunction<
  ReferralCardHandle,
  ReferralCardProps
> = (
  {
    copyRef,
    notify,
    utm,
    backgroundColor = theme.palette.secondary.main,
    context = ReferralContextType.SHARE,
    isBroker = false,
    isInsured = false,
    showBackgroundImage = false,
    showInteractiveButton = true,
    showTooltip = false,
    isTooltipOpen = false,
    url = 'https://azos.com.br',
    userMaritalStatus = 'single',
    className,
    customCopyEvent,
    customShareEvent,
  },
  ref,
) => {
  const timer = React.useRef<NodeJS.Timeout | null>(null)

  React.useEffect(() => {
    return () => {
      if (timer.current) clearTimeout(timer.current)
    }
  }, [])

  const [isShowingTooltip, setIsShowingTooltip] = React.useState(isTooltipOpen)

  const referralUrl = React.useMemo(() => {
    const utmQs = Object.entries(utm || {})
      .filter(([key]) => key.startsWith('utm'))
      .map(([key, value]) => {
        return `${key}=${value}`
      })
      .join('&')
    const qs = Object.entries(utmQs).length ? `?${utmQs}` : ''
    return `${url}${qs}`
  }, [url, utm])

  const isMarried = React.useMemo(
    () => userMaritalStatus === 'married',
    [userMaritalStatus],
  )

  const [userContentType, setUserContentType] = React.useState<UserContentType>(
    referralConfig.B2C.others,
  )

  const onShare = () => {
    copy(referralUrl)

    if (customShareEvent) {
      customShareEvent()
    } else {
      analytics.dashboard.referral.code.referralShareCode.execute({
        type: context,
        status: isMarried
          ? ReferralUserMaritalStatus.MARRIED
          : ReferralUserMaritalStatus.OTHERS,
      })
    }

    if (showTooltip) {
      setIsShowingTooltip(true)

      timer.current = setTimeout(() => {
        setIsShowingTooltip(false)
      }, 3000)
    }

    if (notify) {
      notify({
        variant: 'success',
        description:
          'Código copiado com sucesso. Agora só compartilhar com seus amigos e família.',
      })
    }
  }

  React.useImperativeHandle(ref, () => ({
    handleCopy: copyRef?.current?.click,
    handleShare: onShare,
  }))

  React.useEffect(() => {
    if (isBroker) {
      setUserContentType(
        isMarried
          ? referralConfig.Broker.married
          : referralConfig.Broker.others,
      )
    } else {
      setUserContentType(
        isMarried ? referralConfig.B2C.married : referralConfig.B2C.others,
      )
    }
  }, [isMarried, isBroker])

  return (
    <Root $backgroundColor={backgroundColor} className={className}>
      {showBackgroundImage && (
        <div className="referral-card__image-header">
          <Image
            src={userContentType.background}
            layout="fill"
            objectFit="cover"
          />
        </div>
      )}

      <div className="referral-card">
        <div className="referral-card__content">
          <i className="icon-share" />
          <Text component="div" className="referral-card__content--title">
            {userContentType.title}
          </Text>
          <Text className="referral-card__content--description">
            {userContentType.description}
          </Text>

          {isBroker ? (
            <BrokerContent
              maritalStatus={userMaritalStatus}
              isInsured={isInsured}
              notify={notify}
            />
          ) : (
            <>
              <InsuredContent
                referralUrl={referralUrl}
                notify={notify}
                onShare={onShare}
                context={context}
                userMaritalStatus={userMaritalStatus}
                showInteractiveButton={showInteractiveButton}
                showTooltip={showTooltip}
                isTooltipOpen={isShowingTooltip}
                customCopyEvent={customCopyEvent}
              />
            </>
          )}
        </div>
      </div>
    </Root>
  )
}

ReferralCard.displayName = 'ReferralCard'

export default React.forwardRef(ReferralCard)
