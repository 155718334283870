import { Box, BoxProps } from '@Azos-Seguros/apollo'
import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled(Box)<BoxProps>(
  ({ theme }) => css`
    form {
      button {
        margin-top: ${theme.spacing[4]}px;
      }
    }

    .email-register {
      &__input:not(:first-of-type) {
        margin-top: ${theme.spacing[4]}px;
      }
    }

    .tooltip {
      margin-left: 8px;
      font-size: 1.5rem;
      color: #a3aab9;
    }

    .tooltipContent {
      width: 300px;
    }

    .tooltipWrapper {
      display: flex;
      align-items: center;
    }

    .email-register {
      &__text-content {
        margin: ${theme.spacing[6]}px 0;

        & > * {
          display: inline;
          text-decoration: none;
        }

        &--link {
          color: ${theme.palette.primary.main};
          ${theme.typography.bodyBold3};
        }
      }
    }
  `,
)
