import { authCookies, firebase, Storage } from '@azos/core'

import {
  AuthResponse,
  AuthUser,
  GetSession,
  RefreshTokenParams,
  SetSession,
  SignInParams,
  User,
} from '../domain/models'
import { api } from '../http/api'
import { ITokenService } from './token-service'

export interface IAuthService {
  signOut(): Promise<void>
  signIn(params: SignInParams): Promise<SetSession | null>
  refreshToken(params: RefreshTokenParams): Promise<SetSession | null>
  recovery(token: string): Promise<SetSession | null>
  //
  getSession(): Promise<GetSession>
  getFirebaseToken(): string | null
  //
  setSession(session: SetSession): Promise<GetSession>
  setUser(user: User): void
  setRedirectUrl(redirectUrl: string): void
  //
  removeUser(): void
}

export class AuthService implements IAuthService {
  constructor(
    private readonly tokenService: ITokenService,
    private readonly storage: Storage,
  ) {}

  public async signIn(params: SignInParams): Promise<SetSession | null> {
    const auth = await api
      .post<AuthResponse>('/auth/sign-in', {
        email: params.email,
        password: params.password,
      })
      .then(res => res.data)
      .then(auth => this.saveSession(auth))
      .catch(() => null)

    if (!!auth) return auth

    const sessionToken = this.tokenService.getToken()

    if (!!sessionToken) {
      return await this.recovery(sessionToken)
    }

    return null
  }

  public async refreshToken({
    refreshToken,
  }: RefreshTokenParams): Promise<SetSession | null> {
    return await api
      .post<AuthResponse>('/auth/refresh-token', { refreshToken })
      .then(res => res.data)
      .then(auth => this.saveSession(auth))
      .catch(() => null)
  }

  public async recovery(token: string): Promise<SetSession | null> {
    return await api
      .post<AuthResponse>('/auth/recovery', null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => res.data)
      .then(auth => this.saveSession(auth))
      .catch(() => null)
  }

  public async signOut(): Promise<void> {
    this.removeUser()
    this.tokenService.removeToken()
    await firebase
      .auth()
      .signOut()
      .catch(() => console.error('Error firebase signOut!'))
  }

  public removeUser() {
    this.storage.remove(authCookies.COOKIE_USER_KEY)
  }

  public setRedirectUrl(redirectUrl: string) {
    this.storage.set(authCookies.COOKIE_REDIRECT_FROM_KEY, redirectUrl)
  }

  public setSession({ auth, token }: SetSession): Promise<GetSession> {
    this.storage.set(authCookies.COOKIE_USER_KEY, this.parseUser(auth))
    this.storage.set(authCookies.COOKIE_TOKEN_KEY, token)
    return this.getSession()
  }

  public getSession(): Promise<GetSession> {
    return new Promise((resolve, reject) => {
      const user = this.storage.getJSON(authCookies.COOKIE_USER_KEY)
      const token = this.storage.get(authCookies.COOKIE_TOKEN_KEY)
      const redirectUrl = this.storage.get(authCookies.COOKIE_REDIRECT_FROM_KEY)

      if (!user || !token) {
        return reject()
      }

      return resolve({
        user,
        token,
        redirectUrl,
      })
    })
  }

  public setUser(user: AuthUser) {
    this.storage.set(authCookies.COOKIE_USER_KEY, user)
  }

  public getFirebaseToken(): string | null {
    return this.storage.get(authCookies.COOKIE_TOKEN_KEY) || null
  }

  private async saveSession(auth: AuthResponse): Promise<SetSession> {
    const { accessToken, refreshToken } = auth
    const session = { auth, token: accessToken }
    this.tokenService.setToken(accessToken, refreshToken)
    this.setSession(session)
    return session
  }

  private parseUser(user: AuthResponse): AuthUser {
    const { accountId, name, profilePicture, email } = user
    return {
      uid: accountId,
      displayName: name || '',
      email: email || '',
      photoURL: profilePicture || undefined,
    }
  }
}
