import { Dispatcher, Service } from '../../../../protocols'

export type IUpsellGobackSharePage = Service<void>

/**
 * Go back share page
 *
 * @category Upsell
 */
export class UpsellGobackSharePage implements IUpsellGobackSharePage {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   */
  execute(): void {
    this.dispatcher.execute(
      'Upsell - Clique "voltar" em contratação para terceiro',
    )
  }
}
