export enum ViewPoliciesDetailsType {
  POLICIES = 'Apólices',
  PROPOSALS = 'Propostas',
}

/**
 * View Policies Details
 *
 * @alias ViewPoliciesDetails
 */

export type ViewPoliciesDetails = {
  /**
   *  view type
   */
  type: ViewPoliciesDetailsType
}
