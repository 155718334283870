import {
  makeCheckBillingStatusService,
  makeCoreCheckoutRepository,
  makeLoadInvoiceDataService,
} from '@azos/core'
import { CreateContractService, CreatePaymentService } from '@data/services'
import { makeCheckoutProvider } from '@main/factories/infra'

import { makeProposalConsumer, makeProposalProducer } from '../adapters'
import { makeCreditcardMapper } from '../mappers/checkout'
import { makeCheckoutRepository } from '../repositories'

export const makeCreateContractService = () =>
  new CreateContractService(
    makeCheckoutProvider,
    makeCheckoutRepository(),
    makeCreditcardMapper(),
  )

export const makeCreatePaymentService = () =>
  new CreatePaymentService(
    makeCreateContractService(),
    makeCheckBillingStatusService(),
    makeCoreCheckoutRepository(),
    makeLoadInvoiceDataService(),
    makeProposalConsumer(),
    makeProposalProducer(),
  )
