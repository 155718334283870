import { ViewPoliciesDetails } from '../../../../../domain/models'
import { Dispatcher, Service } from '../../../../protocols'

export type IViewPoliciesDetailsService = Service<ViewPoliciesDetails>

/**
 * View Policies Details
 *
 * @category Policies
 */
export class ViewPoliciesDetailsService implements IViewPoliciesDetailsService {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   * @param {ViewPoliciesDetails} data
   */
  execute(data: ViewPoliciesDetails): void {
    this.dispatcher.execute(
      'Area logada web - Visualização da tela de detalhamento de apólice ou proposta',
      data,
    )
  }
}
