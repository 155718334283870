export enum ClickPolicyCoverageDetailsButtonsType {
  HOME = 'Início',
  POLICY_DETAILS = 'Detalhamento de apólices',
}

/**
 * Click Policy Coverages Details Buttons
 *
 * @alias ClickPolicyCoverageDetailsButtons
 */

export type ClickPolicyCoverageDetailsButtons = {
  /**
   * Coverage name
   */
  coverage: string
  /**
   * Coverage name
   */
  type: string
}
