import React from 'react'

import { Link, LinkProps, Text } from '@azos/shared'

import { Root } from './Link.styles'

type InfoLinkProps = LinkProps & {
  label?: string
}

const InfoLink: React.FCC<InfoLinkProps> = ({
  label,
  href,
  children,
  ...rest
}) => {
  return (
    <Root>
      {label && (
        <>
          <Text>{label}</Text>{' '}
        </>
      )}
      <Link href={href} {...rest}>
        {children}
      </Link>
    </Root>
  )
}

export default InfoLink
