import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;

    @media (min-width: ${theme.breakpoints.md}px) {
      flex-direction: row;
    }

    > button {
      flex: 1;

      &:not(:first-of-type) {
        margin-top: ${theme.spacing[3]}px;
      }

      @media (min-width: ${theme.breakpoints.md}px) {
        &:not(:first-of-type) {
          margin-top: 0;
          margin-left: ${theme.spacing[3]}px;
        }
      }
    }
  `,
)
