import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled('div')(
  () => css`
    .loading-spinner {
      &__backdrop {
        background: rgba(0, 0, 0, 0.8);
        position: fixed;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 9999;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  `,
)
