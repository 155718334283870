import { ICheckoutRepository } from '../../../../data/repositories'
import { LoadBillingStatus, LoadInvoices } from '../../../../domain'

export class FakeCheckoutRepository implements ICheckoutRepository {
  billingStatusResponse = {} as LoadBillingStatus.Response
  invoiceResponse = {} as LoadInvoices.Response

  async loadBillingStatus(
    proposalId: string,
  ): Promise<LoadBillingStatus.Response> {
    return this.billingStatusResponse
  }

  async loadInvoices(
    params: LoadInvoices.Params,
  ): Promise<LoadInvoices.Response> {
    return this.invoiceResponse
  }
}
