import React from 'react'

import analytics from '@azos/analytics'
import { Utils } from '@azos/shared'
import BaseStepLabel from '@mui/material/StepLabel'

import { StepLabelProps } from './StepLabel.props'

const StepLabel = ({ parentName, onClick, ...props }: StepLabelProps) => {
  const handleClick = (
    params: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if (parentName) {
      analytics.custom.action.execute({
        dataAction: Utils.dataNames.renderDataName('stepper', parentName),
      })
    }
    onClick?.(params)
  }

  return (
    <BaseStepLabel
      {...props}
      onClick={handleClick}
      data-action={Utils.dataNames.renderDataName('stepper', parentName)}
    />
  )
}

export default StepLabel
