import React from 'react'

import {
  PolicyProductModal,
  PolicyProductModalProps,
} from '../../PolicyProductModal'
import { PolicyPayment } from './../../PolicyPayment'

type Props = PolicyProductModalProps & {
  policyId: string
}

const PolicyCardPayment: React.VFC<Props> = ({ policyId, open, ...rest }) => {
  return (
    <PolicyProductModal {...rest} policyId={policyId} open={open}>
      <PolicyPayment policyId={policyId} />
    </PolicyProductModal>
  )
}

export default PolicyCardPayment
