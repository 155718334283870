import { LoadKinships } from '../../../../domain/usecases'
import { SWRServices } from '../../../protocols/service'

export type ILoadKinshipsService = SWRServices<void, LoadKinships.Result>

export class LoadKinshipsService implements ILoadKinshipsService {
  constructor(private readonly beneficiariesRepository: LoadKinships) {}

  public getUrl() {
    return `/beneficiaries/kinship`
  }

  async execute(): Promise<LoadKinships.Result> {
    return await this.beneficiariesRepository.loadKinships()
  }
}
