import { ViewResetPassword } from '../../../../domain/models'
import { Dispatcher, Service } from '../../../protocols'

export type IViewResetPasswordService = Service<ViewResetPassword>

/**
 * View Personal Data
 *
 * @category PersonalData
 */
export class ViewResetPasswordService implements IViewResetPasswordService {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @param {ViewResetPassword} data
   * @function
   */
  execute(data: ViewResetPassword): void {
    this.dispatcher.execute('Web - Redefinir senha', data)
  }
}
