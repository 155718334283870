import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled('div')(
  ({ theme }) => css`
    padding-top: ${theme.spacing[3]}px;

    @media (min-width: ${theme.breakpoints.md}px) {
      padding-top: ${theme.spacing[4]}px;
    }
    .content-policies {
      &__title {
        display: flex;
        justify-content: space-between;

        @media (min-width: ${theme.breakpoints.md}px) {
          button {
            display: none;
          }
        }
      }

      &__text {
        margin: ${theme.spacing[4]}px 0 ${theme.spacing[3]}px 0;

        &-description {
          margin-bottom: ${theme.spacing[3]}px;
        }
      }

      &__button {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-bottom: ${theme.spacing[4]}px;

        &--primary {
          & > button {
            background-color: ${theme.palette.neutral[500]};
          }
          width: 100%;
          margin-bottom: ${theme.spacing[3]}px;
        }
      }
    }
  `,
)

export default Root
