import React from 'react'

import analytics from '@azos/analytics'
import { Modal, withModal, useTheme } from '@azos/shared'
import { Button, Switch, Text } from '@azos/shared/src/components/v2'
import { GuardianAddressDataForm } from '@presentation/components/atoms/GuardianAddressDataForm'
import { GuardianPersonalDataForm } from '@presentation/components/atoms/GuardianPersonalDataForm'
import { Form, Formik } from 'formik'

import { initialValues } from './GuardianAddModal.data'
import { GuardianAddModalProps } from './GuardianAddModal.props'
import { Root } from './GuardianAddModal.styles'
import { validationSchema } from './GuardianAddModal.validations'

const GuardiansAddModal: React.VFC<GuardianAddModalProps> = ({
  open,
  onClose,
  onSubmit,
  loading,
}) => {
  const theme = useTheme()
  React.useEffect(() => {
    if (open) analytics.dashboard.guardian.viewAddGuardian.execute()
  }, [open])

  return (
    <Modal
      variant="drawer"
      exitBgColor={theme.palette.neutral[100]}
      open={open}
      onClose={onClose}
    >
      <Root>
        <Text variant="h3">Adicionar guardião</Text>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnMount
        >
          {({ values, setFieldValue }) => (
            <Form className="modal-content__form">
              <Text variant="h4">Dados pessoais</Text>
              <Text className="modal-content__description">
                Os dados pessoais são <strong>obrigatórios</strong> para nomear
                seu guardião.
              </Text>

              <GuardianPersonalDataForm />

              <Text variant="h4" className="modal-content__address-title">
                Endereço
              </Text>

              <Text className="modal-content__description">
                O preenchimento do endereço do guardião é{' '}
                <strong>opcional</strong>.
              </Text>

              <div className="modal-content__switch-wrapper">
                <Text variant="body2">Desejo informar o endereço</Text>
                <Switch
                  checked={values.hasAddress}
                  onChange={event =>
                    setFieldValue('hasAddress', event.target.checked, true)
                  }
                />
              </div>

              <GuardianAddressDataForm className="modal-content__address" />

              <Button
                type="submit"
                className="modal-content__button"
                loading={loading}
                disabled={loading}
              >
                Adicionar guardião
              </Button>
            </Form>
          )}
        </Formik>
      </Root>
    </Modal>
  )
}

export default withModal<GuardianAddModalProps>(GuardiansAddModal)
