import React from 'react'

import { Progress } from '@azos/shared'
import { DashboardSliderCardsIconType } from '@data/models'

import { InfoCardIconProps } from './InfoCardIcon.props'
import { Root } from './InfoCardIcon.styles'

const InfoCardIcon: React.FCC<InfoCardIconProps> = ({ icon }) => {
  const Icon = React.memo(function iconRender() {
    const bannerCases: { [key: string]: () => JSX.Element } = {
      [DashboardSliderCardsIconType.CIRCLE]: () => {
        return (
          <div className="info-card-icon__info--icon">
            <i className={icon.iconName} />
          </div>
        )
      },
      [DashboardSliderCardsIconType.ONBOARDING]: () => {
        return (
          <div className="info-card-icon__onboarding">
            <Progress
              percentage={icon.valueProgressIndicator as number}
              size={48}
              content={<i className={icon.iconName} />}
              spinnerProps={{
                backgroundColor: icon.iconBackgroundColor,
                progressColor: icon.colorProgressIndicator as string,
              }}
            />
          </div>
        )
      },
      [DashboardSliderCardsIconType.INVOICE]: () => {
        return (
          <div className="info-card-icon__invoice--background">
            <div className="info-card-icon__invoice--wrapper">
              {icon.badgeNumber}
            </div>
          </div>
        )
      },
    }

    return (
      bannerCases[icon.type]() ??
      bannerCases[DashboardSliderCardsIconType.CIRCLE]()
    )
  })

  const isInvoiceIcon = icon.type === DashboardSliderCardsIconType.INVOICE

  return (
    <Root
      $iconColor={isInvoiceIcon ? (icon.badgeColor as string) : icon.iconColor}
      $iconBackgroundColor={icon.iconBackgroundColor}
    >
      <Icon />
    </Root>
  )
}

export default InfoCardIcon
