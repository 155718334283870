import React from 'react'

import { useAuth } from '@azos/account'
import { Modal } from '@azos/shared'
import { Text } from '@azos/shared/src/components/v2'
import { azosPhone } from '@main/config/contact'
import { env } from 'main/config/env'
import { useRouter } from 'next/router'

import { RequestBroker } from '../Broker'
import { ModalItemsProps, ModalSimulationProps } from './ModalSimulation.props'
import { Root } from './ModalSimulation.style'

const ModalSimulation: React.FCC<ModalSimulationProps> = ({
  onClose,
  open,
}) => {
  const { user } = useAuth()
  const router = useRouter()

  const [requestBrokerIsOpen, setRequestBrokerIsOpen] = React.useState(false)

  const menuItems: ModalItemsProps[] = [
    {
      icon: 'icon-warning',
      label: 'Comunicar sinistro',
      onClick: () =>
        window.open(`https://api.whatsapp.com/send?phone=${azosPhone}`),
    },
  ]

  if (!user?.isInsured) {
    menuItems.unshift({
      icon: 'icon-briefcase',
      label: 'Solicitar corretor',
      onClick: () => setRequestBrokerIsOpen(true),
    })
  }

  if (!user?.isInsuredByBroker) {
    menuItems.unshift({
      icon: 'icon-insurance',
      label: 'Simular agora',
      onClick: () => {
        if (user?.isInsured) {
          router.push('/simular-novo-seguro')
        } else {
          router.push(env.SIMULATION_URL)
        }
      },
    })
  }

  const renderItem = (item: ModalItemsProps) => (
    <div
      className="button-modal__items"
      key={item.label}
      onClick={item.onClick}
    >
      <div className="button-modal__items__label">
        <span className={item.icon} />
        <Text variant="body2" className="button-modal__items__label-text">
          {item.label}
        </Text>
      </div>
      <div>
        <span className="icon-chevron-right" />
      </div>
    </div>
  )

  return (
    <>
      <Modal
        open={open}
        variant="bottomDrawer"
        onClose={onClose}
        showCloseButton={false}
        showHeader={false}
      >
        <Root>
          <div className="button-modal" role="button">
            {menuItems.map(renderItem)}
          </div>
        </Root>
      </Modal>
      <RequestBroker
        open={requestBrokerIsOpen}
        onClose={() => setRequestBrokerIsOpen(false)}
        showNotification
      />
    </>
  )
}

export default ModalSimulation
