import { css, ProgressBar as LinearProgress } from '@azos/shared'
import styled from '@emotion/styled'

export const ProgressBar = styled(LinearProgress)(
  ({ theme }) => css`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: ${theme.zIndex.header};

    @media (min-width: ${theme.breakpoints.md}px) {
      position: absolute;
    }
  `,
)
