import {
  UpdateUserService,
  UserManagementStatusService,
} from '@data/services/user'
import { makeUserRepository } from '@main/factories/repositories'

export const makeUpdateUserService = () =>
  new UpdateUserService(makeUserRepository())

export const makeUserManagementStatusService = () =>
  new UserManagementStatusService(makeUserRepository())
