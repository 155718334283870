import { Dispatcher, Service } from '../../../../protocols'

export type IUpsellViewCheckoutPage = Service<void>

/**
 * View Checkout Page
 *
 * @category Upsell
 */
export class UpsellViewCheckoutPage implements IUpsellViewCheckoutPage {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   */
  execute(): void {
    this.dispatcher.execute('Upsell - Visualização da tela "Checkout"')
  }
}
