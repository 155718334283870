import React from 'react'

import { Text } from '@azos/shared'

import { Link, Divider } from '../../../components/atoms'
import { EmailRegister } from '../../../components/molecules'

const SignUp: React.VFC = () => {
  return (
    <>
      <Text variant="h1">Faça seu cadastro</Text>
      <EmailRegister mt={4} />
      <Divider />
      <Link href="/login" label="Você já tem uma conta?">
        Entre aqui!
      </Link>
    </>
  )
}

export default SignUp
