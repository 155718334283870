import React from 'react'

import { Modal, useMediaQuery, withModal, useTheme } from '@azos/shared'
import { Text } from '@azos/shared/src/components/v2'
import {
  ImageAppleStore,
  ImageGooglePlay,
  ImageInternalBannerStore,
} from '@presentation/components/atoms/Images'

import { ModalAppStoreProps } from './ModalAppStore.props'
import { Content, Root } from './ModalAppStore.styles'

const LINKS = {
  googlePlay:
    'https://play.google.com/store/apps/details?id=br.com.azos.cliente',
  appleStore: 'https://apps.apple.com/br/app/azos/id1613523805?utm_source=azos',
}

const ModalAppStore: React.VFC<ModalAppStoreProps> = ({
  googlePlayUrl = LINKS.googlePlay,
  appleStoreUrl = LINKS.appleStore,
  utm,
  ...rest
}) => {
  const theme = useTheme()

  const { isMobile } = useMediaQuery()

  const utmQs = React.useMemo<string>(() => {
    return Object.entries(utm || {})
      .filter(([key]) => key.startsWith('utm'))
      .map(([key, value]) => {
        return key === 'utm_medium'
          ? `${key}=${isMobile ? 'mobile' : 'desktop'}`
          : `${key}=${value}`
      })
      .join('&')
  }, [isMobile, utm])

  const googlePlayLink = React.useMemo<string>(() => {
    const qs = Object.entries(utmQs).length ? `&${utmQs}` : ''
    return encodeURI(`${googlePlayUrl}${qs}`)
  }, [googlePlayUrl, utmQs])

  const appleStoreLink = React.useMemo<string>(() => {
    const qs = Object.entries(utmQs).length ? `&${utmQs}` : ''
    return encodeURI(`${appleStoreUrl}${qs}`)
  }, [appleStoreUrl, utmQs])

  return (
    <Modal
      {...rest}
      noGutter
      variant="drawer"
      backgroundColor={theme.palette.neutral[50]}
      exitBgColor={theme.palette.neutral[100]}
    >
      <Root>
        <ImageInternalBannerStore />

        <Content>
          <Text>
            Para baixar o aplicativo da Azos basta clicar em um dos{' '}
            <strong>botões abaixo</strong>.
          </Text>

          <div className="shared-buttons">
            <a href={googlePlayLink} title="Ir para a Google Play">
              <ImageGooglePlay />
            </a>
            <a href={appleStoreLink} title="Ir para a Apple Store">
              <ImageAppleStore />
            </a>
          </div>
        </Content>
      </Root>
    </Modal>
  )
}

export default withModal<ModalAppStoreProps>(ModalAppStore)
