export enum StatusColor {
  success = '#50C309',
  warning = '#F9BF09',
  error = '#FF455B',
}

export enum ChipStatusColor {
  success = '#50C309',
  warning = '#F9BF09',
  error = '#FF455B',
  info = '#8890AA',
}

export enum BadgeStatusColor {
  yellow = '#FCDF84',
  green = '#C2EBC2',
  red = '#FFB18E',
  gray = '#D0D4DC',
  pink = '#FFD9FF',
  purple = '#CEC7FF',
}

export enum PolicyDetailsStatus {
  success = 'success',
  error = 'error',
  warning = 'warning',
}

export enum PolicyCancellationStatus {
  in_effect = 'in-effect',
  pre_canceled = 'pre-canceled',
  canceled = 'canceled',
}

export enum TextPolicyStatus {
  canceled = 'Cancelada',
  expired = 'Expirado',
  in_effect = 'Ativa',
  'in-effect' = 'Ativa',
  pending = 'Pendente',
  paid = 'Pago',
  pre_canceled = 'Em cancelamento',
}

export type PolicyInvoiceStatusResponse = {
  color: ChipStatusColor
  label: InvoiceStatus
  type: string
}

export enum BadgeType {
  POLICY_STATUS = 'policy_status',
  READJUSTMENT_POLICY = 'readjustment_policy',
}

export type PolicyStatusResponse = {
  badgeType?: BadgeType
  icon?: string
  iconColor?: string
  color: ChipStatusColor | BadgeStatusColor
  label: TextPolicyStatus | string
  labelAbbr: string
  type: string
}

export type PolicyDetailsStatusResponse = {
  badgeType?: BadgeType
  icon?: string
  iconColor?: string
  color: ChipStatusColor | BadgeStatusColor
  label: string
  labelAbbr: string
  type: PolicyDetailsStatus
}

export enum InvoiceStatus {
  pending = 'Pendente',
  paid = 'Paga',
  expired = 'Expirada',
  partially_pending = 'Parcialmente paga',
  canceled = 'Cancelada',
  refunded = 'Reembolsada',
  in_protest = 'Em protesto',
  chargeback = 'Extornada',
  manual_refunded = 'Reembolsada',
  in_analysis = 'Em análise',
}

export enum ProposalStatus {
  draft = 'Rascunho',
  sign_process = 'Aguardando assinatura', // status broker
  info_completed = 'Aguardando pagamento',
  in_analysis = 'Em análise',
  signed = 'Assinado', // status broker
  pending_payment = 'Aguardando pagamento',
  accepted = 'Aceita',
  refused = 'Recusada',
  expired = 'Expirada',
  filed = 'Arquivada',
}
