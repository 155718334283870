import { SWRServices } from '@azos/core'
import { ICmsRepository } from '@data/repositories'
import { ListSliderCardsUseCase } from '@domain/usecases'

type Params = ListSliderCardsUseCase.Params
type Response = ListSliderCardsUseCase.Response

export type IListSliderCardsService = SWRServices<Params, Response>

export class ListSliderCardsService implements IListSliderCardsService {
  constructor(private readonly cmsRepository: ICmsRepository) {}

  getUrl(): string {
    return `/dashboard-slider-cards`
  }

  async execute(): Promise<ListSliderCardsUseCase.Response> {
    const response = await this.cmsRepository.listSliderCards()

    return response
  }
}
