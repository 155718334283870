import { Dispatcher, Service } from '../../../../protocols'

export type IViewBrokerContactCardService = Service<void>

/**
 * View Broker Contact Card
 *
 * @category Broker
 */
export class ViewBrokerContactCardService
  implements IViewBrokerContactCardService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}

  execute(): void {
    this.dispatcher.execute(
      'Area logada web - visualização de card com contato do corretor',
    )
  }
}
