import React from 'react'

import { BreadcrumbProps } from './Breadcrumb.props'
import { Root, Stepper } from './Breadcrumb.styles'

const Breadcrumb: React.VFC<BreadcrumbProps> = ({
  steps,
  currentStep,
  className,
}) => {
  return (
    <Root className={className}>
      {steps.map((step, index) => (
        <Stepper
          key={index}
          $active={currentStep === index + 1}
          $viewed={index < currentStep}
        >
          <div className="breadcrumb__number">{index + 1}</div>
          <div className="breadcrumb__text">{step}</div>
        </Stepper>
      ))}
    </Root>
  )
}

export default Breadcrumb
