import { Dispatcher, Service } from '../../protocols'

export type IQuotationAllowReceiveByEmailService = Service

/**
 * Allow to receive by email
 *
 * @category Quotation
 */
export class QuotationAllowReceiveByEmailService
  implements IQuotationAllowReceiveByEmailService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   */
  execute(): void {
    this.dispatcher.execute('quotation_allow_receive_by_email')
  }
}
