import { DashboardSliderCardsRedirectPoliciesType } from '@data/models'

export enum PolicySelectViewType {
  details = 'details',
  payment = 'payment',
  beneficiaries = 'beneficiaries',
  change_payment = 'change_payment',
}

export type PolicyViewManagementProps = {
  open: boolean
  onClose: () => void
  viewType: PolicySelectViewType
  policiesList?: DashboardSliderCardsRedirectPoliciesType[]
  title?: string
  subtitle?: string
}
