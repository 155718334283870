import { Utils } from '@azos/shared'
import { DashboardSliderCardsRedirectPoliciesType } from '@data/models'
import { PoliciesResponse, PolicyCoverageResponse } from '@domain/models'

export const joinCoverages = (coverages: PolicyCoverageResponse[]) => {
  return coverages.map(item => item.label).join(', ')
}

export const formatPresentationPolicy = (
  policy: PoliciesResponse,
  abbreviate = true,
): DashboardSliderCardsRedirectPoliciesType => {
  return {
    policyId: policy.policyId,
    valueLabel: `${Utils.currency.format(policy.premium)}/mês`,
    coveragesLabel: joinCoverages(policy.coverages),
    badgeColor: policy.status[0].color,
    badgeLabel: abbreviate
      ? policy.status[0].labelAbbr
      : policy.status[0].label,
  }
}
