import styled from '@emotion/styled'

import { css } from '../../styles'

export const Root = styled('header')(
  ({ theme }) => css`
    background: ${theme.palette.neutral[400]};
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.12);
    padding: ${theme.spacing[3]}px 0;
    position: relative;
    width: 100%;
    z-index: ${theme.zIndex.header};
    margin-top: 0;
    margin-bottom: 0;

    display: flex;
    align-items: center;

    @media (min-width: ${theme.breakpoints.md}px) {
      padding: ${theme.spacing[4]}px 0;
    }

    & > .header-container {
      width: 100%;

      & > header {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        width: 100%;
      }
    }

    .header {
      &-logo {
        flex: 0;
        display: flex;
        align-items: center;
        margin-right: ${theme.spacing[4]}px;
        height: 32px;

        > a {
          height: inherit;
        }
      }

      &-menu {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        & > .header-user-menu {
          width: 100%;
          max-width: 1280px;
          padding: 0 ${theme.spacing[6]}px;
          margin: 0 auto;

          @media (min-width: ${theme.breakpoints.sm}px) {
            padding: 0 ${theme.spacing[4]}px;
          }
        }

        nav {
          margin-right: ${theme.spacing[4]}px;

          @media (min-width: ${theme.breakpoints.md}px) {
            margin-right: ${theme.spacing[7]}px;
          }

          button {
            cursor: pointer;
            position: relative;
            width: 18px;
            height: 16px;
            background: none;
            top: -4px;
            border: 0;

            @media (min-width: ${theme.breakpoints.md}px) {
              display: none;
            }

            &:before,
            &:after {
              content: '';
              position: absolute;
              width: 18px;
              height: 2px;
              left: 0;
              background: ${theme.palette.neutral[400]};
            }

            &:before {
              top: 0;
              box-shadow: 0 7px 0 ${theme.palette.neutral[400]};
            }

            &:after {
              bottom: 0;
            }
          }

          ul {
            position: absolute;
            left: 0;
            top: 100%;
            background: ${theme.palette.neutral[50]};
            width: 100%;
            transform-origin: top;

            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            list-style: none;
            padding: ${theme.spacing[0]}px;
            margin: ${theme.spacing[0]}px;

            @media (min-width: ${theme.breakpoints.md}px) {
              position: relative;
              left: unset;
              top: unset;
              width: unset;
              background: transparent;
              flex-direction: row;
              gap: ${theme.spacing[7]}px;
            }

            li {
              border-top: 1px solid ${theme.palette.neutral[100]};

              @media (min-width: ${theme.breakpoints.md}px) {
                border: 0;
              }

              a {
                display: block;
                color: ${theme.palette.neutral[500]};
                font-size: ${theme.typography.body2.fontSize};
                line-height: ${theme.spacing[7]}px;
                text-decoration: none;
                text-transform: uppercase;
                padding: ${theme.spacing[3]}px ${theme.spacing[4]}px;
                transition: background 0.3s, opacity 0.3s;

                &:hover {
                  opacity: 0.75;
                  background: ${theme.palette.neutral[100]};
                }

                @media (min-width: ${theme.breakpoints.md}px) {
                  padding: ${theme.spacing[0]}px;

                  &:hover {
                    background: transparent;
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
)

export default Root
