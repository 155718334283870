import { CheckoutServiceSource } from '../../data/models'

export class InvalidCreditCardError extends Error {
  constructor(
    checkoutProvider: CheckoutServiceSource,
    causes: string,
    message?: string,
  ) {
    super(
      message ??
        `O cartão de crédito usando o provedor ${checkoutProvider} foi invalidado`,
    )
    this.cause = causes
    this.name = 'InvalidCreditCardError'
  }
}
