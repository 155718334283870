import { AppError, Services } from '@azos/core'
import { IPaymentsRepository } from '@data/repositories'
import { BillingServiceSource } from '@domain/models'
import { ChangeExpiryDateUseCase } from '@domain/usecases'

type Params = {
  id: string
  type: string
  expiresAt?: string | undefined
  billingService: BillingServiceSource
}
type Response = ChangeExpiryDateUseCase.Response

export type IChangeExpiryDateService = Services<Params, Response>

export class ChangeExpiryDateService implements IChangeExpiryDateService {
  constructor(private readonly paymentsRepository: IPaymentsRepository) {}

  async execute({
    id,
    expiresAt,
    type,
    billingService,
  }: Params): Promise<Response> {
    if (!expiresAt) throw new AppError()

    if (billingService === BillingServiceSource.VINDI)
      return await this.paymentsRepository.updateDueDate({
        proposalId: id,
        dueDate: expiresAt,
      })

    return await this.paymentsRepository.changeExpiryDate({
      id,
      expiresAt,
      type,
    })
  }
}
