export enum ShareContentType {
  ICON = 'icon',
  BUTTON = 'button',
}

/**
 * Copy share content
 *
 * @alias CopyShareContent
 */
export type CopyShareContent = {
  /**
   * Upsell copy share content type
   */
  type: ShareContentType
}
