import React from 'react'

import { useMediaQuery } from '@azos/shared'
import { usePolicyPaymentStatus } from '@presentation/hooks/usePolicyPaymentStatus'
import { useMyPolicies } from '@presentation/providers/MyPoliciesProvider'
import { usePolicyReadjustmentProvider } from '@presentation/providers/PolicyReadjustmentProvider'
import { useRouter } from '@presentation/providers/RouterProvider'

import PolicyCardProduct from '../PolicyCardProduct'
import {
  PolicyCardCancel,
  CardPolicyDetails,
  PolicyCardPayment,
} from './components'
import { PolicyCardProps } from './PolicyCard.props'

const PolicyCard: React.VFC<PolicyCardProps> = ({
  coverages,
  created,
  number,
  policyId,
  readjustment,
  premium,
  status,
  frequency,
}) => {
  const { isMobile } = useMediaQuery()
  const { isPaymentChangeSucceeded, setIsPaymentChangeSucceeded } =
    usePolicyPaymentStatus()
  const { refreshPolicies } = useMyPolicies()
  const router = useRouter()
  const { isPolicyReadjustmentActive } = usePolicyReadjustmentProvider()

  const [productOpen, setProductOpen] = React.useState(false)
  const [paymentOpen, setPaymentOpen] = React.useState(false)
  const [cancelOpen, setCancelOpen] = React.useState(false)

  React.useEffect(() => {
    if (isPaymentChangeSucceeded) {
      setPaymentOpen(false)
      setIsPaymentChangeSucceeded(false)
      refreshPolicies()
    }
  }, [isPaymentChangeSucceeded, setIsPaymentChangeSucceeded, refreshPolicies])

  const handleRedirect = async () => {
    await router.push(`/minhas-apolices/detalhes/${policyId}`)
  }

  return (
    <>
      <PolicyCardProduct
        policyNumber={number}
        status={status}
        coverages={coverages}
        created={created}
        premium={premium}
        policyId={policyId}
        readjustment={readjustment}
        isPolicyReadjustmentActive={isPolicyReadjustmentActive}
        onClick={handleRedirect}
        onPayment={handleRedirect}
        frequency={frequency}
      />

      <PolicyCardPayment
        policyId={policyId}
        open={paymentOpen}
        onClose={() => setPaymentOpen(false)}
      />

      <CardPolicyDetails
        policyId={policyId}
        open={productOpen}
        onClose={() => setProductOpen(false)}
        onCancel={() => {
          setProductOpen(false)
          setCancelOpen(true)
        }}
      />

      <PolicyCardCancel
        isMobile={isMobile}
        policyId={policyId}
        open={cancelOpen}
        onClose={() => setCancelOpen(false)}
      />
    </>
  )
}

export default PolicyCard
