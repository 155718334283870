import React from 'react'

import { PolicyPaymentStatusProvider } from './'

export const withPolicyPaymentStatus = <T extends object>(
  WrappedComponent: React.VFC<T>,
) =>
  function Component(props: T) {
    return (
      <PolicyPaymentStatusProvider>
        <WrappedComponent {...props} />
      </PolicyPaymentStatusProvider>
    )
  }

export default withPolicyPaymentStatus
