import { ViewProductCoverageDetails } from '../../../../domain/models'
import { Dispatcher, Service } from '../../../protocols'

export type IViewProductCoverageDetailsService =
  Service<ViewProductCoverageDetails>

/**
 * View product coverage details
 *
 * @category Home
 */
export class ViewProductCoverageDetailsService
  implements IViewProductCoverageDetailsService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   * @param {ViewProductCoverageDetails} data
   */
  execute(data: ViewProductCoverageDetails): void {
    this.dispatcher.execute(
      'Area logada web - Visualização de detalhamento de cards de produtos',
      data,
    )
  }
}
