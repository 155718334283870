export type SRWServiceResponse<T = any> = {
  data?: T
  isLoading: boolean
  isError: boolean
  mutate?: () => void
  isValidating?: boolean | undefined
}

export class SWRService {
  constructor(
    readonly url: string | null | (() => string | null),
    private readonly service: () => Promise<any>,
  ) {}

  execute(): Promise<any> {
    return this.service()
  }
}
