import {
  CheckoutProvider,
  CreatePaymentTokenResponse,
  PaymentCreditCardParams,
} from '@azos/core'
import axios from 'axios'
import creditCardType from 'credit-card-type'

import { env } from '../../main/config'
import { Utils } from '../../utils'

export type VindiTokenResponse = {
  payment_profile: {
    gateway_token: string
  }
}

export class VindiProvider implements CheckoutProvider {
  constructor(private readonly url: string, private readonly token: string) {}

  build(): void {}

  destroy(): void {}

  createToken(
    params: PaymentCreditCardParams,
  ): Promise<CreatePaymentTokenResponse> {
    return new Promise((resolve, reject) => {
      const { first_name, last_name, number, month, year, cvv } = params

      const brand = Utils.payment.getVindiBrand(
        creditCardType(number)?.[0]?.type,
      )

      const creditCardParams = {
        holder_name: `${first_name} ${last_name}`,
        card_expiration: `${month}/${year}`,
        card_number: number,
        card_cvv: cvv,
        payment_method_code: 'credit_card',
        payment_company_code: brand,
      }

      const vindiApi = `${env.vindiUrl}/payment_profiles`
      const token = this.token

      axios
        .request<VindiTokenResponse>({
          url: vindiApi,
          method: 'post',
          data: creditCardParams,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Basic ${token}`,
          },
        })
        .then(response => {
          resolve({
            id: response.data.payment_profile.gateway_token,
          })
        })
        .catch(({ error }) => {
          console.error(error)
          reject(error)
        })
    })
  }
}
