import styled from '@emotion/styled'

import { css } from '../../../styles'

/* prettier-ignore */
export const Root = styled('div')(({ theme }) => css`
  position: relative;
  max-width: max-content;

  button {
    cursor: pointer;
    user-select: none;
  }

  .user-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;

    border: ${theme.spacing[0]}px;
    border-radius: ${theme.spacing[7]}px;
    background: ${theme.palette.neutral[100]};
    height: ${theme.spacing[7]}px;
    overflow: hidden;
    padding: ${theme.spacing[0]}px;
    width: ${theme.spacing[7]}px;

    i {
      font-size: 1.5rem;
    }

    span {
      text-transform: uppercase;
      font-size: 15.4px;
      font-weight: 600;
      color: ${theme.palette.neutral[500]};
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .user-popover {
    background: ${theme.palette.neutral[50]};
    border: 1px solid ${theme.palette.neutral[200]};
    border-radius: ${theme.spacing[2]}px;
    box-shadow: 0 30px 60px rgba(32, 56, 85, 0.15);
    width: 285px;

    position: absolute;
    top: calc(100% + ${theme.spacing[4]}px);
    right: -${theme.spacing[1]}px;
    z-index: ${theme.zIndex.modal};

    @media (min-width: ${theme.breakpoints.md}px) {
      width: 310px;
      right: -${theme.spacing[4]}px;
      z-index: 100;
    }

    &:before, &:after {
      content: '';
      position: absolute;
      border: 10px solid transparent;
      border-bottom-color: ${theme.palette.neutral[50]};
      top: -18px;
      right: 16px;

      @media (min-width: ${theme.breakpoints.md}px) {
        right: 36px;
      }
    }

    &:before {
      border-bottom-color: ${theme.palette.neutral[200]};
      top: -20px;
    }

    &-info {
      display: flex;
      padding: ${theme.spacing[4]}px;
    }

    &-avatar {
      flex: 0 0 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 60px;
      border-radius: 60px;
      overflow: hidden;
      margin-right: 20px;
      background: ${theme.palette.neutral[100]};

      @media (min-width: 400px) {
        flex: 0 0 72px;
        width: 72px;
        height: 72px;
        border-radius: 72px;
      }

      span {
        text-transform: uppercase;
        font-size: ${theme.spacing[4]}px;
        font-weight: 600;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &-text {
      flex: 1;

      h5 {
        margin: 0;
        padding: 0;
        font-size: ${theme.typography.body2.fontSize}px;
        word-break: break-all;
      }

      p {
        margin: 0;
        padding: 0;
        font-size: ${theme.typography.caption.fontSize}px;
        word-break: break-all;
      }

      button {
        background: transparent;
        border: 0;
        padding: 0;
        margin: 12px 0 0;
        font-size: ${theme.typography.body2.fontSize};
        font-weight: 600;
        color: ${theme.palette.primary.main};
      }
    }

    &-footer {
      border-top: 1px solid ${theme.palette.neutral[200]};
      padding: ${theme.spacing[2]}px;

      button {
        display: flex;
        align-items: center;
        background: transparent;
        border: 0;
        padding: 16px 20px;
        color: ${theme.palette.status.error.main};
        font-size: 12px;
        font-weight: 600;
        text-align: left;
        width: 100%;
        border-radius: ${theme.spacing[2]}px;
        transition: background-color 0.3s;

        &:hover {
          background: ${theme.palette.neutral[100]};
        }

        i {
          font-size: 1.05rem;
          margin-right: 6px;
          color: #FF455B;
        }
      }
    }
  }
`,
)
