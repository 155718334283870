import { Box, BoxProps } from '@Azos-Seguros/apollo'
import { css, Utils } from '@azos/shared'
import styled from '@emotion/styled'

type RootProps = BoxProps & {
  $disabled: boolean
  $iconColor: string
}

export const Root = styled(
  Box,
  Utils.transient.transientStyleProps,
)<RootProps>(
  ({ $disabled, $iconColor, theme }) => css`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;

    transition: opacity 0.4s;
    opacity: ${$disabled ? 0.4 : 1};

    button + button {
      margin-left: ${theme.spacing[2]}px;
    }
    button {
      height: 48px;

      i {
        color: ${$iconColor};
        font-size: 1.85rem;
      }
    }
  `,
)
