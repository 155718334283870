export const MARITAL_STATUS_OPTIONS = [
  {
    label: 'Estado civil',
    value: '',
    disabled: true,
  },
  {
    label: 'Solteira/o',
    value: 'single',
  },
  {
    label: 'Casada/o',
    value: 'married',
  },
  {
    label: 'Viúva/o',
    value: 'widowed',
  },
  {
    label: 'Divorciada/o',
    value: 'divorced',
  },
  {
    label: 'União estável',
    value: 'stable_union',
  },
]
