import React from 'react'

import { Notify, useNotify } from '@azos/shared'

const Notification: React.FCC = () => {
  const { state, removeNotify } = useNotify()

  if (!state) return null

  return (
    <Notify
      message={state.message}
      status={state.status}
      onClose={removeNotify}
    />
  )
}

export default Notification
