import { Dispatcher, Service } from '../../../../protocols'

export type IViewAddCreditCardModalService = Service<void>

/**
 * View Add Credit Card Modal
 *
 * @category Payment
 */
export class ViewAddCreditCardModalService
  implements IViewAddCreditCardModalService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  execute(): void {
    this.dispatcher.execute(
      'Area logada web - Visualização de modal para adicionar cartão',
    )
  }
}
