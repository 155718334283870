import React from 'react'

import { Icon, Text } from '@azos/shared'

import { InfoProps } from './Info.props'
import { Root } from './Info.styles'

const Info = ({ className, title, topics }: InfoProps) => {
  return (
    <Root className={className}>
      {title && (
        <Text variant="h3" component="h1">
          {title}
        </Text>
      )}

      <ul className="info-container__topics">
        {topics.map((topic, index: number) => (
          <li className="info-container__topic-item" key={index}>
            <Icon.Check />
            <Text className="info-container__topic-text">{topic}</Text>
          </li>
        ))}
      </ul>
    </Root>
  )
}

export default Info
