import { ViewAboutAzos } from '../../../../domain/models'
import { Dispatcher, Service } from '../../../protocols'

export type IViewAboutAzosService = Service<ViewAboutAzos>

/**
 * View about azos
 *
 * @category Home
 */
export class ViewAboutAzosService implements IViewAboutAzosService {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   * @param {ViewAboutAzos} data
   */
  execute(data: ViewAboutAzos): void {
    this.dispatcher.execute(
      'Area logada web - Visualização de detalhamento de o que faz a azos diferente',
      data,
    )
  }
}
