import { IBeneficiariesV2Repository } from '../../../../data/repositories'
import { LoadBeneficiariesV2 } from '../../../../domain/usecases'

export class FakeBeneficiariesV2Repository
  implements IBeneficiariesV2Repository
{
  params: any = null
  output: any = null

  async loadBeneficiaries(
    proposal_id: string,
  ): Promise<LoadBeneficiariesV2.Response> {
    this.params = proposal_id
    return this.output
  }
}
