export enum ViewResetPasswordStatus {
  RESET = 'Redefinir',
  WAITING_APPROVAL = 'Aguarde aprovação',
}

/**
 * View Reset Password
 *
 * @alias ViewResetPassword
 */

export type ViewResetPassword = {
  /**
   * Status
   */
  status: ViewResetPasswordStatus
}
