export enum ViewAzosContactType {
  AZOS_MEDIA = 'azos na midia',
  INSTAGRAM = 'instagram',
  YOUTUBE = 'youtube',
  FACEBOOK = 'facebook',
  LINKEDIN = 'linkedin',
}

/**
 * View Azos Contact
 *
 * @alias ViewAzosContact
 */

export type ViewAzosContact = {
  /**
   * Check if user is insured
   */
  isInsured: boolean
  /**
   * Azos details type
   */
  rede: ViewAzosContactType
}
