import { ReferralPageView } from '../../../../../domain/models'
import { Dispatcher, Service } from '../../../../protocols'

export type IReferralPageViewService = Service<ReferralPageView>

/**
 * Referral Page View
 *
 * @category Dashboard
 */
export class ReferralPageViewService implements IReferralPageViewService {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @param {ReferralPageView} data
   * @function
   */
  execute(data: ReferralPageView): void {
    this.dispatcher.execute(
      'Area logada web - Visualização da tela de referral',
      data,
    )
  }
}
