import { Dispatcher, Service } from '../../../../protocols'

export type IUpsellGoBackCalculatorPage = Service<void>

/**
 * Go back calculator
 *
 * @category Upsell
 */
export class UpsellGoBackCalculatorPage implements IUpsellGoBackCalculatorPage {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   */
  execute(): void {
    this.dispatcher.execute('Upsell - Clique "voltar" em cobertura')
  }
}
