import { Services } from '../../../protocols/service'
import { IDPSRepository } from '../../../repositories'

type Request = {
  proposalId: string
  answers: {
    [key: string]: string[]
  }
  illnessInjury?: string | null
}
type Response = void

export type ICreateQuestionnaireService = Services<Request, Response>

export class CreateQuestionnaireService implements ICreateQuestionnaireService {
  constructor(private readonly dpsRepository: IDPSRepository) {}

  async execute(params: Request): Promise<Response> {
    const { proposalId: proposal_id, illnessInjury, answers } = params

    const createIllnessesPayload = {
      proposal_id,
      has_illness_injury: !!illnessInjury,
      illness_injury_description: illnessInjury || '',
    }

    const createQuestionnairePayload = {
      proposal_id,
      answer_sheet: answers,
    }

    await Promise.all([
      this.dpsRepository.createIllnesses(createIllnessesPayload),
      this.dpsRepository.createQuestionnaire(createQuestionnairePayload),
    ])
  }
}
