import { CloseDetailsModal } from '../../../../../domain/models'
import { Dispatcher, Service } from '../../../../protocols'

export type IUpsellCloseDetailsModalService = Service<CloseDetailsModal>

/**
 * Close Coverage Details Modal
 *
 * @category Upsell
 */
export class UpsellCloseDetailsModalService
  implements IUpsellCloseDetailsModalService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   * @param {CloseDetailsModal} data
   */
  execute(data: CloseDetailsModal): void {
    this.dispatcher.execute('Upsell - Fecha modal em detalhes do produto', data)
  }
}
