import React from 'react'

import MyPoliciesProvider from './MyPoliciesProvider'

export const withMyPoliciesProvider = <T extends object>(
  WrappedComponent: React.VFC<T>,
) =>
  function Component(props: T) {
    return (
      <MyPoliciesProvider>
        <WrappedComponent {...props} />
      </MyPoliciesProvider>
    )
  }

export default withMyPoliciesProvider
