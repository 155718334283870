import React from 'react'

import { InputMask } from '../InputMask'
import { InputCVVProps } from './InputCVV.props'

const InputCVV = ({ children, ...rest }: InputCVVProps) => {
  return (
    <InputMask
      label="Código (CVV)"
      placeholder="Código de segurança"
      {...rest}
      mask="9999"
      type="tel"
    />
  )
}

export default InputCVV
