import React from 'react'

import { BannersContext, BannersContextData } from './BannersProvider'

const useBanners = (): BannersContextData => {
  const context = React.useContext(BannersContext)

  if (!context) {
    throw new Error('useBanners must be used within a BannersProvider')
  }

  return context
}

export { useBanners }
