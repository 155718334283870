import React from 'react'

import { EmptyState, Spinner, useWindowSize } from '@azos/shared'
import { Button, Text } from '@azos/shared/src/components/v2'
import { Guardian } from '@domain/models'

import { GuardiansContext } from '../../../GuardiansContext'
import { Root } from './ManagementCard.styles'

const ManagementCard = () => {
  const {
    guardians,
    loading,
    error,
    setModalDeleteGuardian,
    setModalEditGuardian,
    setSelectedGuardian,
    handleLoadGuardians,
    optionsGuardian,
  } = React.useContext(GuardiansContext)

  const { width } = useWindowSize()

  const isMobile = React.useMemo(() => width < 1024, [width])

  const onEditGuardian = (guardian: Guardian, guardianIndex: number) => {
    const hasAnyOptionalGuardianParams =
      !!guardian.zipCode ||
      !!guardian.state ||
      !!guardian.city ||
      !!guardian.neighborhood ||
      !!guardian.street ||
      !!guardian.number ||
      !!guardian.complement

    const guardianFormatted = {
      ...guardian,
      hasAddress: hasAnyOptionalGuardianParams,
    }

    setModalEditGuardian(true)
    setSelectedGuardian({ ...guardianFormatted, guardianIndex })
  }

  const onDeleteGuardian = (guardian: Guardian, guardianIndex: number) => {
    setModalDeleteGuardian(true)
    setSelectedGuardian({ ...guardian, guardianIndex })
  }

  if (guardians.length !== 0) {
    return (
      <Root>
        <div className="guardian-management-card">
          {loading && (
            <div className="guardian-management-card__loading-wrapper">
              <EmptyState
                align="center"
                icon={<Spinner />}
                title="Carregando guardiões"
              ></EmptyState>
            </div>
          )}

          {error && (
            <div className="guardian-management-card__error-state-wrapper">
              <Text variant="body">Falhar ao carregar os guardiões.</Text>

              <Button
                variant="primary"
                color="primary"
                title="Tentar novamente carregar os guardiões"
                onClick={handleLoadGuardians}
                fullWidth
              >
                Tentar novamente
              </Button>
            </div>
          )}

          {guardians.length > 0 && !error && !loading && (
            <ul className="guardian-management-card__beneficiary-list">
              {guardians.map((guardian, index) => (
                <li
                  className="guardian-management-card__beneficiary-item"
                  key={`${index}-${guardian.name}`}
                >
                  <div className="guardian-management-card__beneficiary-item__content">
                    <div>
                      <div className="guardian-management-card__beneficiary-item__content__heading">
                        <p className="guardian-management-card__beneficiary-item__content__heading__title">
                          {guardian.name}
                        </p>
                      </div>

                      <div className="guardian-management-card__beneficiary-item__info">
                        <p>{guardian.kinship}</p>
                        {guardian.city && (
                          <>
                            <span className="guardian-management-card__beneficiary-item__info__dot" />
                            <p className="guardian-management-card__beneficiary-item__info__city">
                              {guardian.state}
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="guardian-management-card__beneficiary-item__button-group">
                      {isMobile ? (
                        <button
                          className="guardian-management-card__beneficiary-item__button"
                          onClick={() => optionsGuardian(guardian, index)}
                          disabled={guardians.length === 1}
                        >
                          <span className="icon-more" />
                        </button>
                      ) : (
                        <>
                          <button
                            className="guardian-management-card__beneficiary-item__button"
                            onClick={() => onDeleteGuardian(guardian, index)}
                            disabled={guardians.length === 1}
                          >
                            <span className="icon-delete" />
                          </button>
                          <button
                            className="guardian-management-card__beneficiary-item__button"
                            onClick={() => onEditGuardian(guardian, index)}
                          >
                            <span className="icon-edit" />
                          </button>
                        </>
                      )}
                    </div>
                  </div>

                  {index < guardians.length - 1 && <hr />}
                </li>
              ))}
            </ul>
          )}
        </div>
      </Root>
    )
  }
  return null
}

export default ManagementCard
