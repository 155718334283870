/**
 * Actions Reason Cancellation
 *
 * @alias ActionsReasonCancellation
 */

export enum ActionsReasonCancellationType {
  CONTINUAR = 'CONTINUAR',
  DESISTIR = 'DESISTIR',
}

export type ActionsReasonCancellation = {
  action: ActionsReasonCancellationType
}
