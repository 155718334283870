import {
  CreateDPSService,
  GetDPSQuestionnaireService,
  LoadQuestionsByAnswersService,
} from '@data/services'
import { DPSQuestion } from '@domain/models'

import { makeDPSRepository } from '../repositories'

export const makeGetDPSQuestionnaireService = () =>
  new GetDPSQuestionnaireService(makeDPSRepository())

export const makeLoadQuestionsByAnswersService = (questions: DPSQuestion[]) =>
  new LoadQuestionsByAnswersService(questions)

export const makeCreateDPSService = () =>
  new CreateDPSService(makeDPSRepository())
