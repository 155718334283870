export enum ViewAboutAzosType {
  OUR_MISSION = 'nossa missão',
  OUR_DIFFERENTIAL = 'nosso diferencial',
  INSURANCE_IS_FOR_ME = 'seguro é pra mim?',
  ABOUT_INSURANCE = 'não entende segurês?',
  ABOUT_GUARDIAN = 'Qual seu papel como guardião ou guardiã?',
}

/**
 * View About Azos
 *
 * @alias ViewAboutAzos
 */

export type ViewAboutAzos = {
  /**
   * Check if user is insured
   */
  isInsured: boolean
  /**
   * Azos details type
   */
  tipo: ViewAboutAzosType
}
