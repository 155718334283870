import React from 'react'

import { Item } from './components/Item'
import { ListSelectProps } from './ListSelect.props'
import { Container } from './ListSelect.styles'

export const ListSelect: React.VFC<ListSelectProps> = ({
  name,
  options,
  disabled,
  //
  value = null,
  onChange,
  //
  error,
  helperText,
  className,
}) => {
  return (
    <Container className={className}>
      <div
        role="radiogroup"
        aria-label={name}
        className="container__option-list"
      >
        {options.map((option, index) => (
          <Item
            key={`${index}-${option.value.toString()}`}
            name={name}
            label={option.label}
            value={option.value}
            checked={value === option.value}
            onChange={onChange}
            disabled={disabled}
          />
        ))}
      </div>
      {error && <div className="container__error-message">{helperText}</div>}
    </Container>
  )
}

export default ListSelect
