import { Theme } from '@emotion/react'

import { css, styled } from '../../styles'
import { Utils } from '../../utils'
import { BadgeTypeProps } from './Badge.props'

type BadgeStyleProps = {
  $type: BadgeTypeProps
  $color?: string
  $textColor?: string
}

type ThemePalleteProps = {
  main: string
  contrastText: string
}

const getBackgroundColor =
  (theme: Theme) =>
  (type: BadgeTypeProps): ThemePalleteProps =>
    theme.palette?.[type] || theme.palette.info

export const Root = styled(
  'span',
  Utils.transient.transientStyleProps,
)<BadgeStyleProps>(({ theme, $type, $color, $textColor }) => {
  const getColor = getBackgroundColor(theme)

  return css`
    background: ${$color || getColor($type).main};
    border-radius: 24px;
    color: ${$textColor || getColor($type).contrastText};
    font-size: 0.75rem;
    font-weight: ${theme.typography.fontWeightBold};
    padding: ${theme.spacing(1, 2)};
    white-space: nowrap;

    transition: background 0.5s;
  `
})
