import React from 'react'

import analytics from '@azos/analytics'
import { ViewPersonalDataBottomSheetStatus } from '@azos/analytics/src/domain/models'
import { Modal, useMediaQuery, withModal } from '@azos/shared'
import { Button, Text } from '@azos/shared/src/components/v2'

import { WaitingApprovalModalProps } from './WaitingApprovalModal.props'
import { Root } from './WaitingApprovalModal.styles'

const WaitingApprovalModal: React.FCC<WaitingApprovalModalProps> = ({
  onClose,
  ...props
}) => {
  const { isMobile } = useMediaQuery()

  React.useEffect(() => {
    analytics.dashboard.personalData.viewPersonalDataBottomSheet.execute({
      status: ViewPersonalDataBottomSheetStatus.WAITING_APPROVAL,
    })
  }, [])

  return (
    <Modal
      {...props}
      onClose={onClose}
      variant={isMobile ? 'bottomDrawer' : 'modal'}
      maxWidth={isMobile ? undefined : '480px'}
      showCloseButton={false}
      showHeader={false}
    >
      <Root>
        <div className="waiting-approval-modal__title">
          <Text
            className="waiting-approval-modal__title-content"
            variant="bodyBold"
          >
            Aguardando aprovação
          </Text>
          {isMobile && <i className="icon-exit" onClick={onClose} />}
        </div>
        <Text variant="body2" className="waiting-approval-modal__description">
          Seus dados alterados recentemente estão em aprovação.
          <br />
          <br />
          Aguarde a aprovação antes de editar seus dados novamente.
        </Text>

        <Button
          onClick={onClose}
          fullWidth
          className="waiting-approval-modal__button"
        >
          Entendi
        </Button>
      </Root>
    </Modal>
  )
}

export default withModal<WaitingApprovalModalProps>(WaitingApprovalModal)
