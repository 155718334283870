import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    height: 100%;

    > .empty-state-wrapper {
      height: 100%;
      display: grid;
      place-items: center;
    }

    .onboarding-action-details {
      &__image {
        flex: 0.6;
        max-height: 600px;
        position: relative;
        background: ${theme.palette.primary.main};

        img {
          position: absolute;
          bottom: 0px;
          object-fit: scale-down;
          width: 100%;
          height: 80%;

          @media (min-width: ${theme.breakpoints.md}px) {
            object-fit: cover;
            width: 100%;
            height: 100%;
            max-height: 500px;
            background: ${theme.palette.primary.main};
          }
        }
      }

      &__text-content {
        flex: 0.4;
        padding: ${theme.spacing[5]}px;

        &--title {
          color: ${theme.palette.neutral[500]};
          font-weight: ${theme.typography.fontWeightBold};
        }

        &--description {
          display: inline-block;
          color: ${theme.palette.neutral[500]};
          margin: ${theme.spacing[3]}px 0 ${theme.spacing[4]}px;
        }
      }

      &__button-group {
        display: flex;
        flex-direction: column;

        button {
          height: 48px;
        }

        button + button {
          margin-top: ${theme.spacing[4]}px;
        }
      }
    }
  `,
)
