import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled('div')(
  ({ theme }) => css`
    margin-top: ${theme.spacing[4]}px;
    text-align: center;
    width: 100%;

    p {
      display: inline-block;
    }

    a {
      cursor: pointer;
      color: ${theme.palette.primary.main};
      text-decoration: none;
      ${theme.typography.bodyBold3};
    }
  `,
)
