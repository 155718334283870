import React from 'react'

import OnboardingWizardProvider from './OnboardingWizardProvider'

export const withOnboardingWizardProvider = <T extends object>(
  WrappedComponent: React.VFC<T>,
) =>
  function Component(props: T) {
    return (
      <OnboardingWizardProvider>
        <WrappedComponent {...props} />
      </OnboardingWizardProvider>
    )
  }

export default withOnboardingWizardProvider
