import List, { ListProps } from '@mui/material/List'
import ListItem, {
  ListItemProps as BaseListItemProps,
} from '@mui/material/ListItem'

import { css, styled } from '../../../styles'

export const Root = styled(List)<ListProps>(
  ({ theme }) => css`
    width: 100%;

    & > li {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    svg,
    .icon {
      font-size: 1.25rem;
    }
  `,
)

export const Item = styled(ListItem)<BaseListItemProps>(
  ({ theme }) => css`
    border-bottom: 1px solid ${theme.palette.grey[200]};
    padding: ${theme.spacing(3)};

    transition: color 0.2s;

    &.clickable {
      cursor: pointer;
      &:hover {
        color: ${theme.palette.primary.main};
      }
    }

    .item__text-wrapper {
      &__description {
        margin-bottom: 0.5rem;
      }
    }

    & > i {
      color: inherit;
      margin-left: ${theme.spacing(3)};
    }
  `,
)
