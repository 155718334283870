import { ContinueLifeStyleQuestion } from '../../../../../domain/models'
import { Dispatcher, Service } from '../../../../protocols'

export type IUpsellContinueOtherDiseasesQuestionService =
  Service<ContinueLifeStyleQuestion>

/**
 * Continue Other Diseases Question
 *
 * @category Upsell
 */
export class UpsellContinueOtherDiseasesQuestionService
  implements IUpsellContinueOtherDiseasesQuestionService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   */
  execute(): void {
    this.dispatcher.execute(
      'Upsell - Clique "continuar" em DPS, doença não mencionada',
    )
  }
}
