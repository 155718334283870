import { css, styled } from '../../../styles'

export const CardContent = styled('div')(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    padding: ${theme.spacing(3)};
    margin-top: ${theme.spacing(4)};
    border-radius: ${theme.spacing(1)};
    background: ${theme.palette.background.paper};
    width: 100%;

    p {
      margin-right: ${theme.spacing(3)};
    }

    .social-media-content {
      display: flex;
      flex-wrap: wrap;
      gap: ${theme.spacing(2)};

      &__item {
        height: 36px;
        width: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${theme.palette.common.white};
        background: ${theme.palette.common.black};
        border-radius: ${theme.spacing(2)};
      }
    }
  `,
)
