import {
  QuotationAllowReceiveByEmailService,
  QuotationDeclineReceiveByEmailService,
} from '../../../data/services/quotations'
import { makeDispatcherEvent } from '../infra/dispatcher-event-factory'

export const makeQuotationAllowReceiveByEmailService = () =>
  new QuotationAllowReceiveByEmailService(makeDispatcherEvent())

export const makeQuotationDeclineReceiveByEmailService = () =>
  new QuotationDeclineReceiveByEmailService(makeDispatcherEvent())
