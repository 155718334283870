import { Login } from '../../../domain/models'
import { Dispatcher, Service } from '../../protocols'

export type ILoginService = Service<Login>

/**
 * Login
 *
 * @category Auth
 */
export class LoginService implements ILoginService {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @param {Login} data
   * @function
   */
  execute(data: Login): void {
    this.dispatcher.execute('login', data)
  }
}
