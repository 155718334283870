import { CardAccordion } from '@Azos-Seguros/apollo'
import { css, styled } from '@azos/shared'

export const Root = styled(CardAccordion)(
  ({ theme }) => css`
    .guardian-address-data {
      &__input-wrapper {
        display: flex;

        div {
          margin-top: ${theme.spacing[3]}px;
        }
        & > div + div {
          margin-left: 16px;
        }
      }

      &__input {
        background: ${theme.palette.neutral[50]};

        &:not(:first-of-type) {
          margin-top: ${theme.spacing[3]}px;
        }
      }
    }
  `,
)
