import { env } from './env'

type ConfigProps = {
  billing: {
    interval: number
    attempts: number
  }
  invoiceData: {
    interval: number
    attempts: number
  }
  invoiceStatus: {
    interval: number
    attempts: number
  }
}

const CONFIG: ConfigProps = {
  billing: {
    interval: 10000, // 10sec
    attempts: 30,
  },
  invoiceData: {
    interval: 5000, // 5sec
    attempts: 100,
  },
  invoiceStatus: {
    interval: 15000, // 15sec
    attempts: 20,
  },
}

const CONFIG_DEV: ConfigProps = {
  billing: {
    interval: 5000, // 5sec
    attempts: 30,
  },
  invoiceData: {
    interval: 5000, // 5sec
    attempts: 20,
  },
  invoiceStatus: {
    interval: 15000, // 15sec
    attempts: 8,
  },
}

const makePolling = (config: ConfigProps) => ({
  billing: {
    ...config.billing,
    total: config.billing.interval * config.billing.attempts, // 5min
  },
  invoiceData: {
    ...config.invoiceData,
    total: config.invoiceData.interval * config.invoiceData.attempts, // 5min
  },
  invoiceStatus: {
    ...config.invoiceStatus,
    total: config.invoiceStatus.interval * config.invoiceStatus.attempts, // 5min
  },
})

export const checkoutConfig = {
  polling: makePolling(env.isProduction ? CONFIG : CONFIG_DEV),
}
