export const azosPhone = '551154451234'

export const formattedAzosPhone = '(11) 5445-1234'

export const funeralAssistancePhone = '08007770151'

export const azosEmail = 'contato@azos.com.br'

export const azosLinks = {
  instagram: 'https://www.instagram.com/azos.seguros/',
  youtube: 'https://www.youtube.com/channel/UC6HMSag8UFT-nHnsAr2NHug',
  facebook: 'https://www.facebook.com/azos.seguros/',
  linkedin: 'https://www.linkedin.com/company/azos-seguros',
}

export const azosWhatsappUrl = `https://api.whatsapp.com/send?phone=${azosPhone}`
