import React from 'react'

import { EmptyStateProps } from './EmptyState.props'
import { Root } from './EmptyState.styles'

const EmptyState: React.FCC<EmptyStateProps> = ({
  align = 'left',
  children,
  icon,
  iconColor = 'grey',
  title,
  titleTag = 'h5',
  titleSize = '20px',
  className,
}) => {
  const TitleTag = titleTag

  return (
    <Root
      $align={align}
      $iconColor={iconColor}
      $titleSize={titleSize}
      className={className}
    >
      {icon && typeof icon !== 'string' && (
        <div className="empty-icon">{icon}</div>
      )}

      {icon && typeof icon === 'string' && (
        <span className={`empty-icon ${icon}`} />
      )}

      {title && <TitleTag className="title">{title}</TitleTag>}

      {children && (
        <div className="content">
          {typeof children === 'string' ? <p>{children}</p> : children}
        </div>
      )}
    </Root>
  )
}

export default EmptyState
