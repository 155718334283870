import { ReferralCopyCode } from '../../../../../domain/models'
import { Dispatcher, Service } from '../../../../protocols'

export type IReferralCopyCodeService = Service<ReferralCopyCode>

/**
 * Referral Copy Code
 *
 * @category Dashboard
 */
export class ReferralCopyCodeService implements IReferralCopyCodeService {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @param {ReferralCopyCode} data
   * @function
   */
  execute(data: ReferralCopyCode): void {
    this.dispatcher.execute(
      'Area logada web - Clique em Copiar Código para indicação',
      data,
    )
  }
}
