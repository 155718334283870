import { styled, css } from '../../../../styles'

export const Container = styled('div')(
  ({ theme }) => css`
    width: 100%;

    .container {
      &__error-message {
        margin-top: ${theme.spacing[3]}px;
        color: ${theme.palette.status.error.main};
      }
    }

    & .container__option-list {
      display: grid;
      grid-template-columns: 1fr;
      gap: ${theme.spacing[2]}px;
    }

    & .container__item__helper-text {
      margin-top: 0.5rem;
      width: 100%;
    }
  `,
)
