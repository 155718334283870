import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Utils } from '../../../utils'

type RootProps = {
  $label: boolean
}

export const Root = styled(
  'div',
  Utils.transient.transientStyleProps,
)<RootProps>(
  ({ theme, $label }) => css`
    position: relative;

    input {
      height: 0;
      width: 0;
      position: absolute;
      visibility: hidden;
    }

    label {
      cursor: pointer;
      position: relative;
      line-height: 2.625;
      max-width: max-content;
      min-width: 28px;
      min-height: 38px;
      display: flex;
      align-items: center;
      padding-left: ${$label
        ? `${theme.spacing[5]}px`
        : `${theme.spacing[0]}px`};

      &:before {
        content: ' ';
        position: absolute;
        width: 24px;
        height: 24px;
        border-radius: 24px;
        left: 0;
        border: 3px solid transparent;
        border-color: ${theme.palette.neutral[300]};
        transition: border-color 0.3s;
      }

      &:after {
        content: ' ';
        position: absolute;
        left: 4px;
        width: 16px;
        height: 16px;
        border-radius: 16px;
        opacity: 0;
        border: 1px solid #00b000;
        background: #c2ebc2;
        transition: opacity 0.3s;
      }

      &:hover {
        &:before {
          border-color: #00b000;
        }
      }
    }

    input:checked + label {
      &:before {
        border-color: #00b000;
        background: #00b000;
      }

      &:after {
        opacity: 1;
      }
    }

    input:disabled + label {
      cursor: not-allowed;
      color: ${theme.palette.neutral[300]};

      &:before {
        border-color: ${theme.palette.neutral[200]};
        background: #f3f4f6;
      }
    }

    input:disabled:checked + label {
      &:before {
        border-color: ${theme.palette.neutral[300]};
        background: ${theme.palette.neutral[300]};
      }

      &:after {
        background: ${theme.palette.neutral[200]};
        border: 1px solid ${theme.palette.neutral[200]};
      }
    }

    input:focus + label {
      &:before {
        outline: 2px solid #b5cfff;
      }
    }
  `,
)
