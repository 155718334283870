import { env } from './env'

export const firebaseConfig = {
  apiKey: env.firebaseKey,
  authDomain: 'pipa-293216.firebaseapp.com',
  projectId: 'pipa-293216',
  storageBucket: 'pipa-293216.appspot.com',
  messagingSenderId: '233998926519',
  appId: '1:233998926519:web:3debffe40befe03457c332',
  measurementId: '',
}
