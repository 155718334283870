import { PolicyExpirationDate } from '../../../../domain/models'
import { Dispatcher, Service } from '../../../protocols'

export type IPolicyExpirationDateService = Service<PolicyExpirationDate>

/**
 * Policy Expiration Date
 *
 * @category Callpage
 */
export class PolicyExpirationDateService
  implements IPolicyExpirationDateService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @param {PolicyExpirationDate} data
   * @function
   */
  execute(data: PolicyExpirationDate): void {
    this.dispatcher.execute(
      'Area logada web - Clique para salvar nova data de vencimento',
      data,
    )
  }
}
