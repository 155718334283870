export enum Kinship {
  CONJUGE = 'Cônjuge',
  FILHOS_NETOS = 'Filhos / Netos',
  ENTEADOS = 'Enteados',
  PAIS_AVOS = 'Pais / Avós',
  IRMAO_IRMA = 'Irmão / Irmã',
  COMPANHEIRO_NAMORADO = 'Companheiro(a)/ Namorado(a)',
  TIO_TIA = 'Tio / Tia',
  AMIGO = 'Amigo(a)',
  SOBRINHOS = 'Sobrinhos',
  AFILHADOS = 'Afilhados',
  OUTROS = 'Outros',
  DEFAULT = '',
}

export type Beneficiaries = {
  _id: string
  //
  birth_date: string | Date
  full_name: string
  social_name?: string | null
  kinship: Kinship
  is_politically_exposed_person: boolean
  percentage: number
  quota: number
  //
  personal_data_id: string
  proposal_id: string
}

type Meta = {
  page: number
  max_results: number
  total: number
}

export type LoadBeneficiariesResponse = {
  _items: Beneficiaries[]
  _meta: Meta
}

export type LoadBeneficiariesV2Response = Beneficiaries[]

export type LoadKinshipsResponse = {
  kinships: Kinship[]
}
