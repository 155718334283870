import { Dispatcher, Service } from '../../protocols'

export type ISimulationStartService = Service

/**
 * Start simulation
 *
 * @category Simulation
 */
export class SimulationStartService implements ISimulationStartService {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   */
  execute(): void {
    this.dispatcher.execute('start_simulation')
  }
}
