import {
  ClearSaleResponseTimeService,
  ClickInsertDueDateService,
  FinishCashbackWithCreditcardService,
  PurchaseService,
  SaveDueDateChangeService,
  SaveProposalPdfService,
  SelectDueDateService,
  SelectPaymentFrequencyService,
  StartCashbackWithCreditcardService,
  ViewDueDateSelectDrawerService,
  ViewCashbackModalService,
  SelectCreditCardCashbackService,
  SelectPixCashbackService,
} from '../../../data/services/checkout'
import { makeMixpanelDispatcherEvent, makeDispatcherEvent } from '../infra'

export const makeClickInsertDueDateService = () =>
  new ClickInsertDueDateService(makeDispatcherEvent())

export const makeFinishCashbackWithCreditCardService = () =>
  new FinishCashbackWithCreditcardService(makeDispatcherEvent())

export const makePurchaseService = () =>
  new PurchaseService(makeDispatcherEvent())

export const makeSaveDueDateChangeService = () =>
  new SaveDueDateChangeService(makeDispatcherEvent())

export const makeSaveProposalPdfService = () =>
  new SaveProposalPdfService(makeDispatcherEvent())

export const makeSelectDueDateService = () =>
  new SelectDueDateService(makeDispatcherEvent())

export const makeStartCashbackWithCreditcardService = () =>
  new StartCashbackWithCreditcardService(makeDispatcherEvent())

export const makeViewDueDateSelectDrawerService = () =>
  new ViewDueDateSelectDrawerService(makeDispatcherEvent())

export const makeClearSaleResponseTimeService = () =>
  new ClearSaleResponseTimeService(makeMixpanelDispatcherEvent())

export const makeSelectPaymentFrequencyService = () =>
  new SelectPaymentFrequencyService(makeMixpanelDispatcherEvent())

export const makeViewCashbackModal = () =>
  new ViewCashbackModalService(makeMixpanelDispatcherEvent())

export const makeSelectCreditCardCashbackService = () =>
  new SelectCreditCardCashbackService(makeMixpanelDispatcherEvent())

export const makeSelectPixCashbackService = () =>
  new SelectPixCashbackService(makeMixpanelDispatcherEvent())
