import React from 'react'
import BaseInputMask from 'react-input-mask'

import NoSsr from '@mui/material/NoSsr'

import { Input } from '../Input'
import { InputMaskProps } from './InputMask.props'

const InputMask = ({
  children,
  disabled,
  name,
  mask = '',
  maskPlaceholder = '',
  value = '',
  error = false,
  helperText = '',
  parentName,
  onChange,
  onBlur,
  onFocus,
  ...rest
}: InputMaskProps) => {
  return (
    <NoSsr>
      <BaseInputMask
        name={name}
        mask={mask}
        value={value.toString()}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        maskPlaceholder={maskPlaceholder}
        disabled={disabled}
      >
        <Input
          parentName={parentName}
          error={error}
          helperText={helperText}
          inputProps={{ onBlur, onFocus }}
          {...rest}
        />
      </BaseInputMask>
    </NoSsr>
  )
}

export default InputMask
