import React from 'react'

import analytics from '@azos/analytics'
import { useTheme } from '@azos/shared'
import { Button, Text } from '@azos/shared/src/components/v2'
import { WizardTutorialTag } from '@data/models'
import { marked } from 'marked'

import { OnboardingActionDetailsProps } from './OnboardingActionDetails.props'
import { Root } from './OnboardingActionDetails.styles'

const OnboardingActionDetails: React.VFC<OnboardingActionDetailsProps> = ({
  tag,
  action,
  onManualComplete,
  onRedirect,
}) => {
  const theme = useTheme()
  const getAnalyticsByType = React.useCallback(() => {
    const analyticCases = {
      [WizardTutorialTag.policy]: (title: string) =>
        analytics.dashboard.onboarding.viewOnboardingPolicyContent.execute({
          tela: title,
        }),
      [WizardTutorialTag.beneficiaries]: (title: string) =>
        analytics.dashboard.onboarding.viewOnboardingBeneficiaryContent.execute(
          {
            tela: title,
          },
        ),
      [WizardTutorialTag.guardians]: (title: string) =>
        analytics.dashboard.onboarding.viewOnboardingGuardianContent.execute({
          tela: title,
        }),
    }

    if (analyticCases[tag]) analyticCases[tag](action.content.title)
  }, [action.content.title, tag])

  React.useEffect(() => {
    getAnalyticsByType()
  }, [getAnalyticsByType])

  const handleClick = (
    externalLink: string | null,
    isManuallyCompleted?: boolean,
  ) => {
    if (isManuallyCompleted) {
      return onManualComplete()
    }

    if (externalLink) {
      return window.open(externalLink, '_blank')
    }

    if (action.tag) {
      return onRedirect(action.tag, action.executed)
    }
  }

  return (
    <Root>
      <picture className="onboarding-action-details__image">
        <source
          srcSet={action.content?.desktopImage}
          media={`(min-width: ${theme.breakpoints.md}px)`}
        />
        <source srcSet={action.content?.mobileImage} />
        <img src={action.content?.mobileImage} alt={action.content?.title} />
      </picture>
      <div className="onboarding-action-details__text-content">
        <Text
          variant="h5"
          className="onboarding-action-details__text-content--title"
        >
          {action.content?.title}
        </Text>
        <Text
          variant="body2"
          className="onboarding-action-details__text-content--description"
          dangerouslySetInnerHTML={{
            __html: marked.parse(action.content?.description ?? ''),
          }}
        />

        {action.content?.actions && action.content.actions.length > 0 && (
          <div className="onboarding-action-details__button-group">
            {action.content?.actions.map(action => (
              <Button
                key={action.label}
                color={action.variant}
                fullWidth
                onClick={() =>
                  handleClick(action.externalLink, action.isManuallyCompleted)
                }
              >
                {action.label}
              </Button>
            ))}
          </div>
        )}
      </div>
    </Root>
  )
}

export default OnboardingActionDetails
