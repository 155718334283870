import React from 'react'

import { useTheme } from '@azos/shared'

import { useWizard } from '../../hooks'
import { ProgressBar } from './Progress.styles'

const Progress = () => {
  const { step, max } = useWizard()
  const theme = useTheme()

  const progress = React.useMemo<number>(
    () => Math.floor((step / max) * 100),
    [max, step],
  )

  return (
    <ProgressBar
      bar={{ barColor: theme.palette.neutral[500] }}
      percentage={progress}
    />
  )
}

export default Progress
