import { HttpClient, UnexpectedError } from '@azos/core'
import { CreateContractUseCase } from '@domain/usecases'
import HttpStatusCode from 'http-status-codes'

export type ICheckoutRepository = CreateContractUseCase

export class CheckoutRepository implements ICheckoutRepository {
  constructor(private readonly httpClient: HttpClient) {}

  async createContract(
    params: CreateContractUseCase.Params,
  ): Promise<CreateContractUseCase.Response> {
    const httpResponse = await this.httpClient.request({
      url: `/checkout`,
      method: 'post',
      body: params,
    })

    const response = httpResponse.data

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
      case HttpStatusCode.CREATED:
        return response as CreateContractUseCase.Response
      default:
        throw new UnexpectedError()
    }
  }
}
