import { ThemeOptions } from '@mui/material/styles'

const theme: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 784,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: '#00B000',
      light: '#C2EBC2',
      dark: '#005700',
      '100': '#96FAA7',
      '200': '#60F287',
      '400': '#39E578',
      '500': '#00B000',
      '600': '#005700',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#FFFFFF',
      contrastText: '#090A0B',
    },
    background: {
      default: '#F3F4F6',
      paper: '#FFFFFF',
    },
    grey: {
      '100': '#F3F4F6',
      '200': '#D0D4DC',
      '300': '#A3AAB9',
      '400': '#505969',
      '500': '#323232',
      '600': '#090A0B',
    },
    common: {
      black: '#090A0B',
      white: '#FFFFFF',
    },
    success: {
      main: '#00B000',
      contrastText: '#FFF',
    },
    error: {
      main: '#EB615B',
      contrastText: '#FFF',
    },
    warning: {
      main: '#F2AA29',
      contrastText: '#FFF',
    },
    info: {
      main: '#505969',
      contrastText: '#FFF',
    },
  },
  spacing: [0, 4, 8, 16, 24, 32, 40, 48, 56],
  typography: {
    fontFamily: ['Dazzed', 'sans-serif'].join(','),
    fontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    h1: {
      fontSize: '3rem',
      fontWeight: 600,
    },
    h2: {
      fontSize: '2.5rem',
      fontWeight: 600,
    },
    h3: {
      fontSize: '2rem',
      fontWeight: 600,
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 600,
    },
    h5: {
      fontSize: '1.25rem',
      fontWeight: 600,
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 600,
    },
    body1: {
      fontSize: '1rem',
    },
    body2: {
      fontSize: '0.875rem',
    },
    caption: {
      fontSize: '0.75rem',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      variants: [
        // {
        //   props: { variant: 'contained', color: 'secondary' },
        //   style: {
        //     textTransform: 'none',
        //     border: `2px dashed red`,
        //   },
        // },
      ],
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        containedSecondary: {
          '&:hover': {
            backgroundColor: `rgba(255, 255, 255, 0.72)`,
            color: '#090A0B',
          },
          '&:disabled': {
            backgroundColor: `rgba(255, 255, 255, 0.16)`,
            color: 'rgba(255, 255, 255, 0.32)',
          },
        },
        outlinedSecondary: {
          '&:hover': {
            backgroundColor: `rgba(255, 255, 255, 0.08)`,
            color: '#FFF',
          },
          '&:disabled': {
            borderColor: `rgba(255, 255, 255, 0.16)`,
            color: 'rgba(255, 255, 255, 0.32)',
          },
        },
        textSecondary: {
          '&:hover': {
            color: 'rgba(255, 255, 255, 0.72)',
          },
          '&:disabled': {
            color: 'rgba(255, 255, 255, 0.32)',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        colorPrimary: {
          color: '#090A0B !important',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'none',
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
        },
        bar: {
          backgroundColor: '#090A0B',
          borderTopRightRadius: 2,
          borderBottomRightRadius: 2,
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&:before': {
            borderBottomColor: '#d1d4dc',
          },
        },
      },
    },
  },
  zIndex: {
    mobileStepper: 1000,
    speedDial: 1050,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
}

export default theme
