import React from 'react'

import AdapterDayjs from '@mui/lab/AdapterDayjs'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import 'dayjs/locale/pt-br'

const MaterialProvider: React.FCC = ({ children }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} locale="pt-br">
      {children}
    </LocalizationProvider>
  )
}

export default MaterialProvider
