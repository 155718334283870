import Box, { BoxProps } from '@mui/material/Box'
import Card, { CardProps } from '@mui/material/Card'
import CardActions, { CardActionsProps } from '@mui/material/CardActions'
import Typography, { TypographyProps } from '@mui/material/Typography'

import { styled } from '../../../styles'

export const Root = styled(Card)<CardProps>(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(2),
}))

export const Main = styled(CardActions)<CardActionsProps>(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  width: '100%',
}))

export const Header = styled(Box)<BoxProps>(({ theme }) => ({
  cursor: 'pointer',
  width: '100%',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row',

  '&:hover .card-product__name': {
    color: theme.palette.grey[400],
  },

  '& > .card-product__name': {
    transition: 'color 0.2s',
    color: 'inherit',
  },
}))

export const ActiveButton = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',

  '& > .MuiTypography-root': {
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}))

export const Description = styled(Typography)<TypographyProps>(({ theme }) => ({
  display: 'block',
  marginTop: theme.spacing(2),
  width: '100%',
}))

Description.defaultProps = { variant: 'body2' }

export const Content = styled(Box)<BoxProps>(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(3),

  '& > div.card-product__content--container': {
    marginTop: theme.spacing(2),
    width: '100%',
  },
}))
