import { CoveragesCode } from '../domain/models/coverages'

const filterByCoverageCode =
  (codes: CoveragesCode[]) => (code: CoveragesCode) =>
    codes.includes(code)

export const isDeathCoverage = filterByCoverageCode([
  CoveragesCode.COD_0001_SEGURO_DE_VIDA,
  CoveragesCode.COD_1001_SEGURO_DE_VIDA,
  CoveragesCode.COD_2001_SEGURO_DE_VIDA,
  CoveragesCode.COD_2010_SEGURO_DE_VIDA_2,
  CoveragesCode.COD_2012_MAC,
])

export const isFuneralAssistanceCoverage = filterByCoverageCode([
  CoveragesCode.COD_0002_ASSISTENCIA_FUNERAL,
  CoveragesCode.COD_1002_ASSISTENCIA_FUNERAL,
  CoveragesCode.COD_1009_SAFF,
  CoveragesCode.COD_2002_ASSISTENCIA_FUNERAL,
  CoveragesCode.COD_2009_SAFF,
  CoveragesCode.COD_2016_AFI,
  CoveragesCode.COD_2017_AFF,
])

export const isDisabiltyCoverage = filterByCoverageCode([
  CoveragesCode.COD_0003_INVALIDEZ,
  CoveragesCode.COD_1003_INVALIDEZ,
  CoveragesCode.COD_2003_INVALIDEZ,
  CoveragesCode.COD_2013_INVALIDEZ_2,
  CoveragesCode.COD_2014_IPTA_ESTENDIDA,
])

export const isDGCoverage = filterByCoverageCode([
  CoveragesCode.COD_0004_DOENCAS_GRAVES,
  CoveragesCode.COD_0005_DOENCAS_GRAVES_10,
  CoveragesCode.COD_1005_DOENCAS_GRAVES_10,
  CoveragesCode.COD_2005_DOENCAS_GRAVES_10,
  CoveragesCode.COD_2011_DOENCAS_GRAVES_10,
  CoveragesCode.COD_2015_DG30,
])

export const isDIHCoverage = filterByCoverageCode([
  CoveragesCode.COD_0006_DIH,
  CoveragesCode.COD_1006_DIH,
  CoveragesCode.COD_2006_DIH,
])

export const isRITCoverage = filterByCoverageCode([CoveragesCode.COD_2007_RIT])

export const isSurgeryCoverage = filterByCoverageCode([
  CoveragesCode.COD_2008_CIRURGIAS,
])
