import { css, styled } from '@azos/shared'

export const Root = styled('div')(
  ({ theme }) => css`
    padding: ${theme.spacing[5]}px;

    .protected-b2c-help-modal {
      &__title {
        strong {
          color: ${theme.palette.primary.main};
        }
      }

      &__description {
        margin-top: ${theme.spacing[4]}px;
      }

      &__actions,
      &__close-button {
        margin-top: ${theme.spacing[5]}px;
      }

      &__actions {
        &--item {
          padding: 14px 0;
          display: flex;
          justify-content: space-between;
          cursor: pointer;

          i {
            font-size: 1.5em;
          }

          .redirect-icon {
            color: ${theme.palette.neutral[300]};
          }

          &-content {
            display: flex;
            align-items: center;

            & > * + * {
              margin-left: ${theme.spacing[2]}px;
            }
          }
        }
      }
    }
  `,
)
