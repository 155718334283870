import React from 'react'

import analytics from '@azos/analytics'
import { Utils } from '@azos/shared'
import MuiTypography from '@mui/material/Typography'

import { TextProps } from './Text.props'

const Text: React.FCC<TextProps> = ({
  children,
  variant = 'body1',
  parentName,
  onClick,
  ...rest
}) => {
  const handleClick = () => {
    if (parentName) {
      analytics.custom.action.execute({
        dataAction: Utils.dataNames.renderDataName('text', parentName),
      })
    }
    onClick?.()
  }

  return (
    <MuiTypography
      variant={variant}
      onClick={handleClick}
      {...rest}
      data-action={Utils.dataNames.renderDataName('text', parentName)}
    >
      {children}
    </MuiTypography>
  )
}

export default Text
