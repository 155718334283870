import { PolicyCancelUserType } from './policy-cancel'

/**
 * Actions Cancellation Validation
 *
 * @alias ActionsCancellationValidation
 */

export enum ActionsCancellationValidationType {
  SOLICITAR = 'SOLICITAR',
  DESISTIR = 'DESISTIR',
}

export type ActionsCancellationValidation = {
  type: PolicyCancelUserType
  action: ActionsCancellationValidationType
}
