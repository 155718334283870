import React from 'react'

import { Utils } from '@azos/shared'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'

import { InputAutoCompleteProps } from './InputAutoComplete.props'

const AutoComplete = ({
  options,
  label,
  placeholder,
  loading,
  inputProps,
  //
  accuracy = 0.8,
  minInputLength = 3,
  noOptionText = 'Não encontrado',
  error = false,
  helperText,
  parentName,
  //
  onBlur,
  //
  ...rest
}: InputAutoCompleteProps) => {
  const [autoCompleteValue, setAutoCompleteValue] = React.useState<string>('')

  const compareSimilarityRange = React.useCallback(
    (listValue: string, inputValue: string) => {
      const inputLength = inputValue.length

      return (
        Utils.similarity.compareSimilarText(listValue, inputValue) >=
        Math.min(accuracy, inputLength * 0.085)
      )
    },
    [accuracy],
  )

  const handleFilterBasedOnSimilarity = React.useCallback(
    (optionList: string[], inputValue: string) =>
      optionList.filter(
        listValue =>
          Utils.sanitizer
            .sanitizeString(listValue)
            .includes(Utils.sanitizer.sanitizeString(inputValue)) ||
          compareSimilarityRange(listValue, inputValue),
      ),
    [compareSimilarityRange],
  )

  const handleFilterOptions = React.useCallback(
    (optionsList: string[]) => {
      if (autoCompleteValue.length < minInputLength) {
        return []
      }

      const similarityBasedList = handleFilterBasedOnSimilarity(
        optionsList,
        autoCompleteValue,
      )

      return similarityBasedList.length > 0
        ? similarityBasedList
        : [noOptionText]
    },
    [
      autoCompleteValue,
      handleFilterBasedOnSimilarity,
      minInputLength,
      noOptionText,
    ],
  )

  return (
    <Autocomplete
      autoComplete={false}
      disableClearable
      freeSolo={false}
      multiple={false}
      popupIcon={null}
      placeholder="Digite para filtrar"
      noOptionsText={
        autoCompleteValue.length < minInputLength
          ? 'Digite para filtrar'
          : noOptionText
      }
      openOnFocus
      options={options}
      filterOptions={handleFilterOptions}
      onInputChange={(_event, value) => setAutoCompleteValue(value)}
      renderInput={(params: any) => (
        <TextField
          {...params}
          margin="normal"
          variant="standard"
          label={label}
          placeholder={placeholder}
          onBlur={onBlur}
          inputProps={{
            ...inputProps?.inputProps,
            ...params.inputProps,
            'data-action': Utils.dataNames.renderDataName(
              'input-autocomplete',
              parentName,
            ),
          }}
          error={error}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          {...inputProps}
        />
      )}
      {...rest}
    />
  )
}

export default AutoComplete
