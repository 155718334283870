export enum ClickContactBrokerHelpType {
  CALL = 'ligar',
  EMAIL = 'email',
}

/**
 * Click Contact Broker Help
 *
 * @alias ClickContactBrokerHelp
 */

export type ClickContactBrokerHelp = {
  /**
   * Check if user is insured
   */
  isInsured: boolean
  /**
   * Check if user is guardian
   */
  isGuardian: boolean
  /**
   * Status
   */
  tipo: ClickContactBrokerHelpType
}
