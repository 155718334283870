import React from 'react'

import { useAuth } from '@azos/account'
import {
  DashboardSliderCardsResponse,
  DashboardSliderCardsType,
} from '@data/models'
import { makeListSliderCardsByIdService } from '@main/factories/services'

export type BannersContextData = {
  banners: DashboardSliderCardsResponse[]
  setBanners: (policies: DashboardSliderCardsResponse[]) => void
  onboardingBanner: DashboardSliderCardsResponse | null
  changePaymentBanner: DashboardSliderCardsResponse | null
}

export const BannersContext = React.createContext({} as BannersContextData)

type BannersProviderProps = {
  banners?: DashboardSliderCardsResponse[]
}

type SliderCardsState = {
  onboardingBanner: DashboardSliderCardsResponse | null
  changePaymentBanner: DashboardSliderCardsResponse | null
  isFetched: boolean
  isLoading: boolean
}

const getDashboardCardItem = makeListSliderCardsByIdService()

const BannersProvider: React.FCC<BannersProviderProps> = ({
  banners = [],
  children,
}) => {
  const { user } = useAuth()

  const [bannersData, setBannersData] =
    React.useState<DashboardSliderCardsResponse[]>(banners)

  const [sliderCards, setSliderCards] = React.useState<SliderCardsState>({
    changePaymentBanner: null,
    onboardingBanner: null,
    isLoading: false,
    isFetched: false,
  })

  React.useEffect(() => {
    if (!user || sliderCards.isFetched) return

    setSliderCards(state => ({
      ...state,
      isLoading: true,
      isFetched: true,
    }))

    const fetchOnboarding: Promise<DashboardSliderCardsResponse | null> =
      getDashboardCardItem
        .execute({
          cardId: DashboardSliderCardsType.ONBOARDING,
        })
        .catch(() => null)

    const fetchChangePayment: Promise<DashboardSliderCardsResponse | null> =
      getDashboardCardItem
        .execute({
          cardId: DashboardSliderCardsType.CHANGE_PAYMENT_TYPE,
        })
        .catch(() => null)

    Promise.all([fetchOnboarding, fetchChangePayment]).then(
      ([onboardingBanner, changePaymentBanner]) => {
        setSliderCards(state => ({
          ...state,
          onboardingBanner,
          changePaymentBanner,
          isLoading: false,
        }))
      },
    )
  }, [sliderCards.isFetched, user])

  return (
    <BannersContext.Provider
      value={{
        banners: bannersData,
        setBanners: setBannersData,
        onboardingBanner: sliderCards.onboardingBanner,
        changePaymentBanner: sliderCards.changePaymentBanner,
      }}
    >
      {children}
    </BannersContext.Provider>
  )
}

export default BannersProvider
