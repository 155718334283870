import { BenefitsBanner } from '../../../../../domain/models'
import { Dispatcher, Service } from '../../../../protocols'

export type IBenefitsBannerService = Service<BenefitsBanner>

/**
 * Benefits Banner
 *
 * @category Dashboard
 */
export class BenefitsBannerService implements IBenefitsBannerService {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @param {BenefitsBanner} data
   * @param data.partner
   * @function
   */
  execute(data: BenefitsBanner): void {
    this.dispatcher.execute(
      'Area logada web - Clique em banner de novidades para você',
      data,
    )
  }
}
