import { css, keyframes, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { createTheme, responsiveFontSizes } from '@mui/material/styles'

import b2cTheme from './b2c'

const createResponsiveTheme = () =>
  responsiveFontSizes(createTheme(b2cTheme), { factor: 2 })

const theme = createResponsiveTheme()

export { theme, styled, css, useTheme, keyframes }
