import { InputLabelProps } from '@mui/material/InputLabel'
import { styled } from '@mui/material/styles'

export const Label = styled('label')<InputLabelProps>(({ theme }) => ({
  '& .list--choice': {
    display: 'flex',
    justifyContent: 'space-between',

    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette.common.white,
    borderRadius: 8,
    borderStyle: 'solid',
    borderWidth: 1,

    color: theme.palette.common.black,
    width: '100%',
    height: '100%',

    padding: theme.spacing(2, 3),

    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3),
    },

    transition: 'border-color 0.2s, color 0.2s',

    '&:hover': {
      backgroundColor: theme.palette.common.white,
      borderColor: theme.palette.common.black,
    },
    '&.active': {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },

    '& .list--choice__typography': {
      fontWeight: theme.typography.fontWeightBold,
      display: 'block',
      textAlign: 'start',
      width: '100%',
      wordBreak: 'break-word',
    },
  },
}))
