import { HttpClient, UnauthorizedError, UnexpectedError } from '@azos/core'
import {
  CancelPolicyUseCase,
  LoadPoliciesUseCase,
  LoadPolicyByIdUseCase,
  UpdateBeneficiariesUseCase,
} from '@domain/usecases'
import HttpStatusCode from 'http-status-codes'

export type IPoliciesRepository = LoadPoliciesUseCase &
  LoadPolicyByIdUseCase &
  UpdateBeneficiariesUseCase &
  CancelPolicyUseCase

export class PoliciesRepository implements IPoliciesRepository {
  constructor(private readonly httpClient: HttpClient) {}

  async loadPoliciesAndProposals(): Promise<LoadPoliciesUseCase.Response> {
    const httpResponse = await this.httpClient.request({
      url: `/policies`,
      method: 'get',
      body: null,
    })

    const response = httpResponse.data

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
        return response as LoadPoliciesUseCase.Response
      case HttpStatusCode.UNAUTHORIZED:
        throw new UnauthorizedError(response?.message)
      default:
        throw new UnexpectedError()
    }
  }

  async loadById({
    policyId,
  }: LoadPolicyByIdUseCase.Params): Promise<LoadPolicyByIdUseCase.Response> {
    const httpResponse = await this.httpClient.request({
      url: `/policies/${policyId}`,
      method: 'get',
      body: null,
    })

    const response = httpResponse.data

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
        return response as LoadPolicyByIdUseCase.Response
      case HttpStatusCode.UNAUTHORIZED:
        throw new UnauthorizedError(response?.message)
      default:
        throw new UnexpectedError()
    }
  }

  async updateBeneficiaries(
    params: UpdateBeneficiariesUseCase.Params,
  ): Promise<UpdateBeneficiariesUseCase.Response> {
    const { policyId, beneficiaries } = params

    const newBeneficiaries = beneficiaries.map(beneficiary => ({
      ...beneficiary,
      socialName: beneficiary.socialName?.trim() || null,
    }))

    const httpResponse = await this.httpClient.request({
      url: `/policies/${policyId}/beneficiaries`,
      method: 'post',
      body: {
        beneficiaries: newBeneficiaries,
      },
    })

    const response = httpResponse.data

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
      case HttpStatusCode.NO_CONTENT:
        return response as UpdateBeneficiariesUseCase.Response
      case HttpStatusCode.UNAUTHORIZED:
        throw new UnauthorizedError(response?.message)
      default:
        throw new UnexpectedError()
    }
  }

  async cancelPolicy(
    params: CancelPolicyUseCase.Params,
  ): Promise<CancelPolicyUseCase.Response> {
    const { policyId, reason, description } = params

    const httpResponse = await this.httpClient.request({
      url: `/policies/${policyId}/cancel`,
      method: 'post',
      body: {
        reason,
        description,
      },
    })

    const response = httpResponse.data

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
        return response as UpdateBeneficiariesUseCase.Response
      case HttpStatusCode.UNAUTHORIZED:
        throw new UnauthorizedError(response?.message)
      default:
        throw new UnexpectedError()
    }
  }
}
