import { ActionsCancellationValidation } from '../../../../../domain/models'
import { Dispatcher, Service } from '../../../../protocols'

export type IActionsCancellationValidationService =
  Service<ActionsCancellationValidation>

/**
 * Actions Cancellation Validation
 *
 * @category Dashboard
 */
export class ActionsCancellationValidationService
  implements IActionsCancellationValidationService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @param {ActionsCancellationValidation} data
   * @function
   */
  execute(data: ActionsCancellationValidation): void {
    this.dispatcher.execute(
      'Area logada web - Clique nas ações da tela de validação de cancelamento',
      data,
    )
  }
}
