import { ViewAzosContact } from '../../../../domain/models'
import { Dispatcher, Service } from '../../../protocols'

export type IViewAzosContactService = Service<ViewAzosContact>

/**
 * View azos contact
 *
 * @category Home
 */
export class ViewAzosContactService implements IViewAzosContactService {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   * @param {ViewAzosContact} data
   */
  execute(data: ViewAzosContact): void {
    this.dispatcher.execute(
      'Area logada web - Visualização de detalhamento de siga a azos',
      data,
    )
  }
}
