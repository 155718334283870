import React from 'react'

import Image, { ImageProps } from 'next/image'
import { formatFirebaseImage } from 'utils/formatImages'

export type BaseImageFirebaseStorage = Omit<ImageProps, 'src'>

const ImageFirebaseStorage: React.VFC<ImageProps> = ({ src, ...rest }) => {
  return (
    <Image loader={({ src }) => formatFirebaseImage(src)} src={src} {...rest} />
  )
}

export default ImageFirebaseStorage
