import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled('div')(
  ({ theme }) => css`
    .payment-history {
      &__wrapper {
        padding: ${theme.spacing[3]}px;
        background: ${theme.palette.neutral[50]};
      }

      &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: ${theme.spacing[3]}px;

        &-icon {
          color: ${theme.palette.primary.main};
          margin-left: 12px;
          font-size: 1.5rem;
        }

        &-see-more {
          display: flex;
          align-items: center;
          text-decoration: none;

          &--title {
            text-decoration: underline;
            color: ${theme.palette.primary.main};
          }
        }
      }
    }
  `,
)
