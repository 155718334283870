import React from 'react'

import { InputMask } from '../InputMask'
import { InputCreditCardProps } from './InputCreditCard.props'

const InputCreditCard = ({
  children,
  mask = '9999 9999 9999 9999',
  ...rest
}: InputCreditCardProps) => {
  return (
    <InputMask
      label="Número do cartão"
      placeholder="Ex: 234-1234-1234-1234"
      {...rest}
      mask={mask}
      type="tel"
    />
  )
}

export default InputCreditCard
