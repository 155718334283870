import {
  InsuredUserRepository,
  UsersRepository,
} from '../../../data/repositories'
import { makeAxiosHttpClient } from '../http'

export const makeUsersRepository = (httpClient = makeAxiosHttpClient()) =>
  new UsersRepository(httpClient)

export const makeInsuredUserRepository = () =>
  new InsuredUserRepository(makeAxiosHttpClient())
