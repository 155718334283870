import { RemoveFromCart } from '../../../domain/models'
import { Dispatcher, Service } from '../../protocols'

export type IRemoveFromCartService = Service<RemoveFromCart>

/**
 * Remove from cart
 *
 * @category Cart
 */
export class RemoveFromCartService implements IRemoveFromCartService {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @param {RemoveFromCart} data
   * @function
   */
  execute(data: RemoveFromCart): void {
    this.dispatcher.execute('remove_from_cart', data)
  }
}
