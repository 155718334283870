import { BenefitsPage } from '../../../../../domain/models'
import { Dispatcher, Service } from '../../../../protocols'

export type IBenefitsCodeAccessService = Service<BenefitsPage>

/**
 * Benefits Code Access
 *
 * @category Dashboard
 */
export class BenefitsCodeAccessService implements IBenefitsCodeAccessService {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @param {BenefitsCode} data
   * @param data.partner
   * @function
   */
  execute(data: BenefitsPage): void {
    this.dispatcher.execute(
      'Area logada web - Clique no código para acessar parceiro na tela de detalhamento dos benefícios',
      data,
    )
  }
}
