import { ViewPolicyReadjustmentDetails } from '../../../../../domain/models'
import { Dispatcher, Service } from '../../../../protocols'

export type IViewPolicyReadjustmentDetailsService =
  Service<ViewPolicyReadjustmentDetails>

/**
 * View Policy Readjustment Details
 *
 * @category Policies
 */
export class ViewPolicyReadjustmentDetailsService
  implements IViewPolicyReadjustmentDetailsService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   * @param {ViewPolicyReadjustmentDetails} data
   */
  execute(data: ViewPolicyReadjustmentDetails): void {
    this.dispatcher.execute(
      'Area logada web - Visualização de Reajuste de apólice',
      data,
    )
  }
}
