import { StartCashbackWithCreditcard } from '../../../domain/models'
import { Dispatcher, Service } from '../../protocols'

export type IStartCashbackWithCreditcardService =
  Service<StartCashbackWithCreditcard>

/**
 * Start Cashback With Creditcard
 *
 * @category Checkout
 */
export class StartCashbackWithCreditcardService
  implements IStartCashbackWithCreditcardService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @param {StartCashbackWithCreditcard} data
   * @function
   */
  execute(data: StartCashbackWithCreditcard): void {
    this.dispatcher.execute('start_cashback_with_creditcard', data)
  }
}
