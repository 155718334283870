import React from 'react'

import { ApolloProvider, NotificationProvider } from '@Azos-Seguros/apollo'
import { RemoteConfigProvider } from '@azos/core'
import { NotifyProvider } from '@azos/shared'
import { Notification } from '@presentation/components/atoms/Notification'

import AccountProvider from './AccountProvider'
import { BannersProvider } from './BannersProvider'
import { GuardianProvider } from './GuardianProvider'
import { MenuProvider } from './MenuProvider'
import { MyPoliciesProvider } from './MyPoliciesProvider'
import { OnboardingWizardProvider } from './OnboardingWizardProvider'
import { PaymentProvider } from './PaymentProvider'
import { PolicyReadjustmentProvider } from './PolicyReadjustmentProvider'
import { RouterProvider } from './RouterProvider'

const MainProvider: React.FCC = ({ children }) => {
  return (
    <ApolloProvider>
      <NotificationProvider position="bottom-start">
        <NotifyProvider>
          <AccountProvider>
            <GuardianProvider>
              <MyPoliciesProvider>
                <RouterProvider>
                  <OnboardingWizardProvider>
                    <BannersProvider>
                      <Notification />
                      <RemoteConfigProvider>
                        <PaymentProvider>
                          <PolicyReadjustmentProvider>
                            <MenuProvider>{children}</MenuProvider>
                          </PolicyReadjustmentProvider>
                        </PaymentProvider>
                      </RemoteConfigProvider>
                    </BannersProvider>
                  </OnboardingWizardProvider>
                </RouterProvider>
              </MyPoliciesProvider>
            </GuardianProvider>
          </AccountProvider>
        </NotifyProvider>
      </NotificationProvider>
    </ApolloProvider>
  )
}

export default MainProvider
