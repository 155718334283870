import Box, { BoxProps } from '@mui/material/Box'
import { InputLabelProps } from '@mui/material/InputLabel'
import { RadioProps } from '@mui/material/Radio'
import { styled } from '@mui/material/styles'

export const Root = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: 8,

  '&.column-1': {
    gridTemplateColumns: '1fr',
  },
  '&.column-2': {
    gridTemplateColumns: '1fr 1fr',
  },

  '&.column-flex': {
    gridTemplateColumns: '1fr',

    '& .MuiButtonBase-root': {
      height: '100%',
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
}))

export const Label = styled('label')<InputLabelProps>(() => ({
  backgroundColor: '#FFF',
  borderRadius: 8,
  borderStyle: 'solid',
  borderWidth: 1,
  padding: 16,
  position: 'relative',
}))

export const Input = styled('input')<RadioProps>(() => ({
  display: 'none',
  visibility: 'hidden',
}))

Input.defaultProps = {
  type: 'radio',
}
