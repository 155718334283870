import { ICheckInvoiceStatusService } from '../'
import { IProposalConsumer } from '../../../../data/adapters'
import { PaymentInvoicesStatus, PaymentMethod } from '../../../../domain/models'
import { Services } from '../../../protocols'

type Request = {
  paymentMethod: PaymentMethod
  status?: PaymentInvoicesStatus
}
type Response = void

export type ILoadPaymentStatusService = Services<Request, Response>

export class LoadPaymentStatusService implements ILoadPaymentStatusService {
  constructor(
    private readonly proposalConsumer: IProposalConsumer,
    private readonly checkInvoiceStatusService: ICheckInvoiceStatusService,
  ) {}

  async execute(params: Request): Promise<Response> {
    const { paymentMethod, status } = params

    const { proposalId } = this.proposalConsumer.execute()

    await this.checkInvoiceStatusService.execute({
      proposalId,
      paymentMethod,
      status,
    })
  }

  dispose() {
    this.checkInvoiceStatusService.dispose?.()
  }
}
