import { PaymentTimeoutError } from './payment-timeout-error'

export class PaymentBillingTimeoutError extends PaymentTimeoutError {
  constructor() {
    super(
      'Houve um problema em processar a forma de pagamento, Por favor, tente novamente',
    )
    this.name = 'PaymentBillingTimeoutError'
  }
}
