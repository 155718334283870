import { HttpClient, UnexpectedError } from '@azos/core'
import {
  ChangeExpiryDateUseCase,
  ChangePaymentMethodUseCase,
  ChangePaymentMethodV2UseCase,
  GetDueDateListUseCase,
  ListPossibleExpiryDatesUseCase,
  UpdateDueDateUseCase,
} from '@domain/usecases'
import HttpStatusCode from 'http-status-codes'

export type IPaymentsRepository = ChangePaymentMethodUseCase &
  ChangeExpiryDateUseCase &
  ListPossibleExpiryDatesUseCase &
  ChangePaymentMethodV2UseCase &
  GetDueDateListUseCase &
  UpdateDueDateUseCase

export class PaymentsRepository implements IPaymentsRepository {
  constructor(private readonly httpClient: HttpClient) {}

  async changePaymentMethod(
    params: ChangePaymentMethodUseCase.Params,
  ): Promise<ChangePaymentMethodUseCase.Response> {
    const httpResponse = await this.httpClient.request({
      url: `/payments/payment-method`,
      method: 'patch',
      body: params,
    })

    const response = httpResponse.data

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
      case HttpStatusCode.NO_CONTENT:
        return response as ChangePaymentMethodUseCase.Response
      default:
        throw new UnexpectedError()
    }
  }

  async changeExpiryDate(
    params: ChangeExpiryDateUseCase.Params,
  ): Promise<ChangeExpiryDateUseCase.Response> {
    const httpResponse = await this.httpClient.request({
      url: `/payments/expiry-date`,
      method: 'patch',
      body: params,
    })

    const response = httpResponse.data

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
      case HttpStatusCode.NO_CONTENT:
        return response as ChangeExpiryDateUseCase.Response
      default:
        throw new UnexpectedError()
    }
  }

  async listPossibleExpiryDates({
    id,
  }: ListPossibleExpiryDatesUseCase.Params): Promise<ListPossibleExpiryDatesUseCase.Response> {
    const httpResponse = await this.httpClient.request({
      url: `/payments/${id}/possible-expiry-dates`,
      method: 'get',
      body: null,
    })

    const response = httpResponse.data

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
        return response as ListPossibleExpiryDatesUseCase.Response
      default:
        throw new UnexpectedError()
    }
  }

  async changePaymentMethodV2(
    params: ChangePaymentMethodV2UseCase.Params,
  ): Promise<ChangePaymentMethodV2UseCase.Response> {
    const httpResponse = await this.httpClient.request({
      url: `/payments/change/v2`,
      method: 'post',
      body: params,
    })

    const response = httpResponse.data

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
      case HttpStatusCode.NO_CONTENT:
        return response as ChangePaymentMethodV2UseCase.Response
      default:
        throw new UnexpectedError()
    }
  }

  async getDueDateList(
    params: GetDueDateListUseCase.Params,
  ): Promise<GetDueDateListUseCase.Response> {
    const httpResponse = await this.httpClient.request({
      url: `/payments/list-due-date/${params.proposalId}`,
      method: 'get',
      body: null,
    })

    const response = httpResponse.data

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
        return response as GetDueDateListUseCase.Response
      default:
        throw new UnexpectedError()
    }
  }

  async updateDueDate(
    params: UpdateDueDateUseCase.Params,
  ): Promise<UpdateDueDateUseCase.Response> {
    const httpResponse = await this.httpClient.request({
      url: `/payments/update-due-date`,
      method: 'post',
      body: params,
    })

    const response = httpResponse.data

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
        return response as UpdateDueDateUseCase.Response
      default:
        throw new UnexpectedError()
    }
  }
}
