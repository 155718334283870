import React from 'react'

import { Confetti, Progress, useTheme, useMediaQuery } from '@azos/shared'
import { Text } from '@azos/shared/src/components/v2'

import { ProgressBannerProps } from './ProgressBanner.props'
import { Root } from './ProgressBanner.styles'

const ProgressBanner: React.VFC<ProgressBannerProps> = ({
  percentage,
  color,
  title,
  description,
}) => {
  const theme = useTheme()
  const { isMobile } = useMediaQuery()

  return (
    <Root $textColor={color}>
      {percentage === 100 && (
        <Confetti width={isMobile ? undefined : 400} isOnModal={isMobile} />
      )}
      <Progress
        percentage={percentage}
        size={96}
        spinnerProps={{
          backgroundColor: theme.palette.neutral[400],
          backgroundBorderColor: '#737A87',
          progressColor: color,
        }}
        content={
          <Text
            variant="h4"
            className="progress-banner-container__progress--percentage"
          >
            {percentage}%
          </Text>
        }
      />
      <div className="progress-banner-container__text--wrapper">
        <Text variant="h4" className="progress-banner-container__title">
          {title}
        </Text>
        <Text
          variant="body2"
          className="progress-banner-container__description"
        >
          {description}
        </Text>
      </div>
    </Root>
  )
}

export default ProgressBanner
