import { Dispatcher, Service } from '../../../../protocols'

export type ISaveNewCreditCardService = Service<void>

/**
 * Save New Credit Card
 *
 * @category Payment
 */
export class SaveNewCreditCardService implements ISaveNewCreditCardService {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}

  execute(): void {
    this.dispatcher.execute('Area logada web - Clique para salvar novo cartão')
  }
}
