import { styled, css } from '../../../../styles'

export const Root = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;

    .broker-content {
      &__description {
        color: #505969;
      }

      &__form {
        & > * + * {
          margin-top: 8px;
        }

        &-input {
          background: ${theme.palette.neutral[50]};

          i {
            font-size: 0.875rem;
          }
        }
      }

      &__button {
        margin-top: 24px;
        height: 48px;
      }
    }
  `,
)
