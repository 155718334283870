import { makeAxiosHttpClient } from '@azos/core'
import {
  FinalQuotationsRepository,
  ProposalsRepository,
} from '@data/repositories'

export const makeProposalsRepository = () =>
  new ProposalsRepository(makeAxiosHttpClient())

export const makeFinalQuotationsRepository = (
  httpClient = makeAxiosHttpClient(),
) => new FinalQuotationsRepository(httpClient)
