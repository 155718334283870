import React from 'react'

import { Checkbox } from '@Azos-Seguros/apollo'

import { ItemProps } from './Item.props'

export const Item = ({ value, onChange, ...props }: ItemProps) => {
  const handleChange = React.useCallback(() => {
    if (value) {
      onChange(value)
    }
  }, [onChange, value])

  return (
    <Checkbox value={value?.toString()} onChange={handleChange} {...props} />
  )
}

export default Item
