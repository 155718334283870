import { SWRServices } from '@azos/core'
import { IWizardRepository } from '@data/repositories'
import { ListWizardUseCase } from '@domain/usecases'

type Params = ListWizardUseCase.Params
type Response = ListWizardUseCase.Response

export type IListSummaryService = SWRServices<Params, Response>

export class ListWizardService implements IListSummaryService {
  constructor(private readonly wizardRepository: IWizardRepository) {}

  getUrl(): string {
    return '/dashboard/wizard'
  }

  async execute(): Promise<Response> {
    const response = await this.wizardRepository.list()

    return response
  }
}
