import React from 'react'

import ImageFirebaseStorage, {
  BaseImageFirebaseStorage,
} from './ImageFirebaseStorage'

const ImageAppleStore: React.VFC<BaseImageFirebaseStorage> = ({
  title = 'Apple Store',
}) => {
  return (
    <ImageFirebaseStorage
      src="/B2C/dashboard/general/applestore.png"
      alt={title}
      title={title}
      width={322}
      height={96}
    />
  )
}

export default ImageAppleStore
