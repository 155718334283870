'use client'

import React from 'react'

import clsx from 'clsx'

import { Text } from '../Text'
import { Root } from './AutoCompleteEmail.styles'

const domains = [
  'gmail.com',
  'hotmail.com',
  'hotmail.com.br',
  'yahoo.com.br',
  'yahoo.com',
  'outlook.com',
  'outlook.com.br',
  'icloud.com',
  'portfel.com.br',
  'uol.com.br',
  'terra.com.br',
  'live.com',
  'bol.com.br',
  'msn.com',
  'me.com',
  'ymail.com',
]

interface AutoCompleteEmailProps extends React.PropsWithChildren<any> {
  onSubmit?: () => void
  onCompletion?: (completedStr: string) => void
}

interface Match {
  domain: string
  completion: string
}

interface InputHandle extends HTMLInputElement {
  check(e: React.KeyboardEvent<HTMLInputElement>): void
  change(e: React.ChangeEvent<HTMLInputElement>): void
}

const AutoCompleteEmail = React.forwardRef<
  HTMLInputElement,
  AutoCompleteEmailProps
>((props, ref) => {
  const [inputVal, setInputVal] = React.useState('')
  const [overlayVisible, setOverlayVisible] = React.useState(false)
  const [matches, setMatches] = React.useState<Match[]>([] as Match[])
  const [selectedMatch, setSelectedMatch] = React.useState<number | null>(null)

  const autoCompleteWrapperRef = React.useRef<HTMLInputElement>(null)

  React.useImperativeHandle(
    ref,
    () =>
      ({
        check(e) {
          if (isActionKey(Number(e.key))) {
            invokeAction(e, Number(e.key))
          }
        },
        change(e) {
          const value = e.target.value
          setInputVal(value)
          resetMatches()
          filterDomains(value)
        },
      } as InputHandle),
  )

  const isActionKey = (keyCode: number) => [38, 40, 13, 27].includes(keyCode)

  const invokeAction = (e: React.KeyboardEvent, keyCode: number) => {
    e.preventDefault()
    e.stopPropagation()
    switch (keyCode) {
      case 38:
        navigateSuggestions('up')
        break
      case 40:
        navigateSuggestions('down')
        break
      case 13:
        selectSuggestion()
        break
      case 27:
        closeOverlay()
        break
    }
  }
  const navigateSuggestions = (direction: 'up' | 'down') => {
    if (matches.length < 1) return
    if (direction === 'down') {
      setSelectedMatch(prev =>
        prev === null ? 0 : Math.min(prev + 1, matches.length - 1),
      )
    } else {
      setSelectedMatch(prev => (prev !== null && prev > 0 ? prev - 1 : prev))
    }
  }

  const selectSuggestion = () => {
    if (selectedMatch !== null) {
      completeInput(inputVal + matches[selectedMatch].completion)
    } else if (props.onSubmit) {
      props.onSubmit()
    }
  }

  const completeInput = (completedStr: string) => {
    if (props.onCompletion) props.onCompletion(completedStr)
    closeOverlay()
  }

  const closeOverlay = () => {
    setOverlayVisible(false)
    resetMatches()
  }

  const resetMatches = () => {
    setMatches([])
    setSelectedMatch(null)
  }

  const computeMatches = (vendorSearch: string) => {
    const filteredMatches = domains
      .filter(
        vendor => vendor.startsWith(vendorSearch) && vendor !== vendorSearch,
      )
      .map(domain => ({
        domain,
        completion: domain.slice(vendorSearch.length),
      }))
    if (filteredMatches.length > 0) {
      setMatches(
        filteredMatches.sort((a, b) => a.domain.localeCompare(b.domain)),
      )
      setOverlayVisible(true)
    }
  }

  const filterDomains = (input: string) => {
    const [_, vendorSearch] = input.split('@')
    if (vendorSearch) computeMatches(vendorSearch)
  }

  React.useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        autoCompleteWrapperRef.current &&
        !autoCompleteWrapperRef.current.contains(e.target as Node)
      ) {
        setOverlayVisible(false)
      }
    }
    document.addEventListener('click', handleClickOutside)
    return () => document.removeEventListener('click', handleClickOutside)
  }, [inputVal])

  return (
    <Root ref={autoCompleteWrapperRef}>
      {props.children}
      <div>
        {overlayVisible && matches.length > 0 ? (
          <div className="overlay">
            <ul className="list">
              {matches.map((match, index) => (
                <li
                  key={index}
                  onClick={() => completeInput(inputVal + match.completion)}
                  className={clsx(
                    'list-item',
                    index === selectedMatch ? 'gray' : 'white',
                  )}
                >
                  <Text color="primary">{inputVal}</Text>
                  <span>{match.completion}</span>
                </li>
              ))}
            </ul>
          </div>
        ) : null}
      </div>
    </Root>
  )
})

AutoCompleteEmail.displayName = 'AutoCompleteEmail'

export default AutoCompleteEmail
