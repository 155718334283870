import { SWRServices } from '@azos/core'
import { ICmsRepository } from '@data/repositories'
import { ListBenefitsUseCase } from '@domain/usecases'

type Params = ListBenefitsUseCase.Params
type Response = ListBenefitsUseCase.Response

export type IListBenefitsService = SWRServices<Params, Response>

export class ListBenefitsService implements IListBenefitsService {
  constructor(private readonly cmsRepository: ICmsRepository) {}

  getUrl(): string {
    return `/benefits`
  }

  async execute(): Promise<ListBenefitsUseCase.Response> {
    const response = await this.cmsRepository.listBenefits()

    return response
  }
}
