import { CreateContactNotify } from '../../../../domain/usecases'
import { Mapper, Services } from '../../../protocols'
import { IHubRepository } from '../../../repositories'

type Request = {
  name: string
  email: string
  phone: string
  pageUri: string
  legalText: string
}
type Response = CreateContactNotify.Response

export type ICreateContactNotifyService = Services<Request, Response>

export class CreateContactNotifyService implements ICreateContactNotifyService {
  constructor(
    private readonly requestMapper: Mapper,
    private readonly hubRepository: IHubRepository,
  ) {}

  async execute(params: Request): Promise<Response> {
    const payload = this.requestMapper.map(params)

    return await this.hubRepository.createContactNotify(payload)
  }
}
