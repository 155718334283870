import { ContinueLifeStyleQuestion } from '../../../../../domain/models'
import { Dispatcher, Service } from '../../../../protocols'

export type IUpsellContinueGeneralConditionsQuestionService =
  Service<ContinueLifeStyleQuestion>

/**
 * Continue GeneralConditions Question
 *
 * @category Upsell
 */
export class UpsellContinueGeneralConditionsQuestionService
  implements IUpsellContinueGeneralConditionsQuestionService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   */
  execute(): void {
    this.dispatcher.execute(
      'Upsell - Clique "continuar" em DPS, opções de casos',
    )
  }
}
