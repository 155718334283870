import { DownloadProposal } from '../../../../../domain/models'
import { Dispatcher, Service } from '../../../../protocols'

export type IDownloadProposalService = Service<DownloadProposal>

/**
 * Download Proposal
 *
 * @category Policies
 */
export class DownloadProposalService implements IDownloadProposalService {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   * @param {DownloadProposal} data
   */
  execute(data: DownloadProposal): void {
    this.dispatcher.execute(
      'Area logada web - Clique para baixar proposta',
      data,
    )
  }
}
