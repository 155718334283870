import React from 'react'

import {
  makeProposalConsumer,
  makeProposalProducer,
} from '../main/factories/adapters'

const proposalConsumer = makeProposalConsumer()
const proposalProducer = makeProposalProducer()

type ProposalData = {
  proposalId: string
  quotationId: string
}

type CookiesResult = {
  proposalCookie?: ProposalData
  setProposalCookie(value?: Partial<ProposalData>): void
  removeProposalCookie(): void
}

type ProposalCookieProps = {
  revalidate?: boolean
}

const defaultData: ProposalCookieProps = {
  revalidate: false,
}

const useProposalCookie = ({
  revalidate,
}: ProposalCookieProps = defaultData): CookiesResult => {
  const proposalCookie = React.useRef<ProposalData>(proposalConsumer.execute())

  const setProposalCookie = React.useCallback(
    (value: Partial<ProposalData>) => {
      const data = Object.assign({}, proposalCookie.current, value)
      proposalProducer.execute(data)
      proposalCookie.current = data
    },
    [],
  )

  const removeProposalCookie = React.useCallback(() => {
    proposalProducer.execute(undefined)
  }, [])

  React.useEffect(() => {
    if (revalidate) {
      setProposalCookie(proposalCookie.current)
    }
  }, [proposalCookie, revalidate, setProposalCookie])

  return {
    proposalCookie: proposalCookie.current,
    setProposalCookie,
    removeProposalCookie,
  }
}

export { useProposalCookie }
