export enum SelectContract {
  ME = 'Para mim',
  OTHER = 'Para outra pessoa',
}

/**
 * Select contract type
 *
 * @alias SelectContractType
 */
export type SelectContractType = {
  /**
   * Upsell select contract type
   */
  type: SelectContract
}
