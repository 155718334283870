import { css, Modal, styled } from '@azos/shared'

export const Root = styled(Modal)(
  ({ theme }) => css`
    .modal-content-wrapper {
      display: flex;
      flex-direction: column;
      flex: 1;
      height: 100%;
    }

    .modal-content {
      border-radius: 0;
    }

    .request-broker {
      &__header {
        background: url('/assets/png/request_broker.png');
        background-repeat: no-repeat;
        background-size: cover;

        display: flex;
        flex-direction: column;
        gap: ${theme.spacing[2]}px;

        padding: ${theme.spacing[4]}px;
        position: relative;

        i {
          color: ${theme.palette.neutral[50]};
          font-size: 2.5rem;
        }

        &--exit-button {
          position: fixed;
          top: 24px;
          right: 16px;

          background: ${theme.palette.neutral[100]};
          border-radius: 50%;
          padding: 0;
          height: 48px;
          width: 48px;

          display: flex;
          align-items: center;
          justify-content: center;

          i {
            font-size: 1.5rem;
            color: ${theme.palette.neutral[400]};
          }

          @media (min-width: 600px) {
            display: none;
          }
        }

        &--title {
          color: ${theme.palette.neutral[50]};
        }

        &--description {
          color: ${theme.palette.neutral[50]};
        }
      }

      &__content {
        padding: ${theme.spacing[4]}px;

        display: flex;
        flex-direction: column;
        gap: ${theme.spacing[4]}px;
        flex: 1;

        &--description {
          color: ${theme.palette.neutral[400]};
        }

        &--form {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          flex: 1;

          &-input {
            display: flex;
            flex-direction: column;
            gap: ${theme.spacing[4]}px;
            margin-bottom: 42px;
          }
        }
      }
    }
  `,
)
