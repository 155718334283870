import React from 'react'

import { InputMask } from '../InputMask'
import { InputHeightProps } from './InputHeight.props'

const InputHeight = ({
  children,
  value,
  onChange,
  ...rest
}: InputHeightProps) => {
  const [dirty, setDirty] = React.useState(false)

  const formattedValue =
    !dirty && !!value ? Number(value).toFixed(2).toString() : value

  const handleChange = React.useCallback(
    event => {
      setDirty(true)
      onChange?.(event)
    },
    [onChange],
  )

  return (
    <InputMask
      label="Altura"
      placeholder="em metros"
      {...rest}
      value={formattedValue}
      onChange={handleChange}
      mask="9.99"
      type="tel"
    />
  )
}

export default InputHeight
