import { isEqual } from 'lodash'

export const deepCopy = <T = any>(props: any) =>
  JSON.parse(JSON.stringify(props)) as T

export const isEmpty = (object: any) =>
  object === null || Object.keys(object).length === 0

export const getObjectDiff = (obj1: Object, obj2: Object): string[] => {
  const diff = Object.keys(obj1).reduce((result, key) => {
    if (!Object.prototype.hasOwnProperty.call(obj2, key)) {
      result.push(key)
    } else if (isEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key)
      result.splice(resultKeyIndex, 1)
    }
    return result
  }, Object.keys(obj2))

  return diff
}

export const isValidObject = (obj: Object) => {
  return obj !== null && !isEmpty(obj) && !Array.isArray(obj)
}

export const returnNotEmptyObjectProps = <T extends Record<string, any>>(
  obj: T,
): Partial<T> => {
  const newObj: Partial<T> = {}

  for (const key in obj) {
    if (
      Array.isArray(obj[key]) &&
      obj[key].length > 0 &&
      (obj[key][0].length > 0 || isValidObject(obj[key][0]))
    ) {
      newObj[key] = obj[key]
    }

    if (
      typeof obj[key] !== 'object' &&
      obj[key] !== undefined &&
      obj[key] !== null
    ) {
      newObj[key] = obj[key]
    }
  }

  return newObj
}
