import React from 'react'

import { RadioButtonProps } from '.'
import { RadioButtonOption } from './components'
import { ButtonRoot, Root } from './RadioButton.styles'

export const RadioButton = ({
  name,
  options,
  columns = 2,
  disabled,
  showInput,
  //
  value = null,
  onChange,
  //
  error,
  helperText,
  className,
}: RadioButtonProps) => {
  return (
    <ButtonRoot className={className}>
      <div className="radio-button__wrapper">
        <Root
          className={`column-${columns}`}
          role="radiogroup"
          aria-label={name}
        >
          {options.map((option, index) => (
            <RadioButtonOption
              key={`${index}-${option.value.toString()}`}
              name={name}
              label={option.label}
              value={option.value}
              checked={option.value === value}
              onChange={() => onChange(option.value)}
              disabled={disabled}
              showInput={showInput}
            />
          ))}
        </Root>
      </div>
      {error && (
        <div className="radio-button__error-message">
          <div>{helperText}</div>
        </div>
      )}
    </ButtonRoot>
  )
}

export default RadioButton
