import React from 'react'

import { Text, useMediaQuery } from '@azos/shared'
import { Button } from '@azos/shared/src/components/v2'

import { UserPhotoProps } from '.'
import { Icon } from '../../icons'
import { Root, StyledModal } from './UserPhoto.styles'

const UserPhoto: React.VFC<UserPhotoProps> = ({
  user,
  size = 'sm',
  disabled,
  onUpdatePhoto,
  isOpen,
  setIsOpen,
  ...rest
}) => {
  const ref = React.useRef<HTMLDivElement>(null)

  const { isMobile } = useMediaQuery()

  const handleClose = () => {
    setIsOpen(false)
  }

  const inputFileRef = React.useRef<HTMLInputElement>(null)

  const getPhoto = async (
    e: React.ChangeEvent<HTMLInputElement> | undefined,
  ) => {
    const file = e?.target?.files?.[0]

    if (file) {
      const pattern = /image-*/

      if (!file.type.match(pattern)) {
        return
      }

      onUpdatePhoto(file)
      setIsOpen(false)
    }
  }

  return (
    <Root $size={size} ref={ref} {...rest}>
      {user?.displayName ? (
        <button onClick={() => setIsOpen(true)} className="user-button">
          {user?.photoURL && (
            <img
              src={user?.photoURL}
              alt={user?.displayName}
              referrerPolicy="no-referrer"
            />
          )}
          {!user?.photoURL && (
            <span>
              <Icon.User />
            </span>
          )}
        </button>
      ) : (
        <button
          onClick={() => setIsOpen(true)}
          disabled={disabled}
          className="user-button"
        >
          <Icon.User />
        </button>
      )}

      <StyledModal
        variant={isMobile ? 'bottomDrawer' : 'modal'}
        open={isOpen}
        onClose={handleClose}
        showCloseButton={false}
        showHeader={false}
        noGutter
        maxWidth={isMobile ? undefined : '360px'}
      >
        <div className="user-modal-content">
          <Text variant="body1" className="user-modal-content__title">
            Alterar imagem
          </Text>
          <Text variant="body1" className="user-modal-content__subtitle">
            Deseja selecionar uma nova imagem de perfil?
          </Text>
          <input
            className="user-modal-content__input"
            ref={inputFileRef}
            accept="image/*"
            onChange={getPhoto}
            name="upload-photo"
            type="file"
            id="upload-photo"
          />
          <Button fullWidth onClick={() => inputFileRef.current?.click()}>
            Sim
          </Button>
          <Button
            fullWidth
            variant="tertiary"
            className="user-modal-content__secondary-button"
            onClick={() => handleClose()}
          >
            Não
          </Button>
        </div>
      </StyledModal>
    </Root>
  )
}

export default UserPhoto
