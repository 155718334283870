import { ImportantInfoBanner } from '../../../../../domain/models'
import { Dispatcher, Service } from '../../../../protocols'

export type IViewImportantInfoBannerService = Service<ImportantInfoBanner>

/**
 * Important Info Banner
 *
 * @category Callpage
 */
export class ViewImportantInfoBannerService
  implements IViewImportantInfoBannerService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @param {ImportantInfoBanner} data
   * @function
   */
  execute(data: ImportantInfoBanner): void {
    this.dispatcher.execute(
      'Area logada web - Visualização do banner de informações importantes',
      data,
    )
  }
}
