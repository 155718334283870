import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled('div')(
  () => css`
    .empty-state-wrapper {
      margin: 32px;
    }

    li {
      p {
        font-weight: 400;
      }
    }
  `,
)
