import { css, styled } from '../../styles'

export const Root = styled('div')(
  () => css`
    display: flex;
    flex-direction: column;

    .stepper {
      &__wrapper {
        display: flex;
        margin-bottom: 24px;
        gap: 24px;
      }

      &__aside {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      &__content {
        margin-top: 8px;
      }

      &__connector {
        border-left: 1px solid #d0d4dc;
        width: 1px;
        margin: 0 auto;
        min-height: 12px;
        flex: 1;
      }

      &__step-number {
        border-radius: 50%;
        border: 1px solid #d0d4dc;
        display: grid;
        height: 32px;
        width: 32px;
        place-items: center;
        color: #323232;
        font-size: 16px;
        flex-shrink: 0;
      }
    }
  `,
)
