import React from 'react'

import { useAuth } from '@azos/account'
import { withModal } from '@azos/shared'
import { useGuardian } from '@presentation/providers/GuardianProvider'

import GuardianAboutModal from './GuardianAboutModal'
import { GuardianAboutModalAdapterProps } from './GuardianAboutModal.props'

const GuardianAboutModalAdapter: React.VFC<
  GuardianAboutModalAdapterProps
> = props => {
  const { user } = useAuth()
  const { protectedList, isLoading, isGuardian } = useGuardian()

  return (
    <GuardianAboutModal
      {...props}
      user={user}
      protectedList={protectedList}
      isLoading={isLoading}
      isGuardian={isGuardian}
    />
  )
}

export default withModal<GuardianAboutModalAdapterProps>(
  GuardianAboutModalAdapter,
)
