import { Dispatcher, Service } from '../../../../protocols'

export type IClickReadjustmentDetailsService = Service<void>

/**
 * Click Readjustment Details
 *
 * @category Policies
 */
export class ClickReadjustmentDetailsService
  implements IClickReadjustmentDetailsService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @param
   * @function
   */
  execute(): void {
    this.dispatcher.execute(
      'Area logada web - Clique em "Reajuste da mensalidade" na tela de apólice',
    )
  }
}
