import { css, styled } from '../../../styles'
import { Utils } from '../../../utils'
import { BadgeTypeProps } from './Badge.props'

type BadgeStyleProps = {
  $type: BadgeTypeProps
  $color?: string
  $textColor?: string
  $borderRadius?: number
  $iconColor?: string
  $clickable?: boolean
}

type ThemePalleteProps = {
  main: string
  contrastText: string
}

const getBackgroundColor =
  (theme: any) =>
  (type: BadgeTypeProps): ThemePalleteProps => {
    const colorCases: { [key: string]: ThemePalleteProps } = {
      primary: {
        main: theme.palette.primary[200],
        contrastText: theme.palette.secondary[50],
      },
      success: {
        main: theme.palette.primary[50],
        contrastText: theme.palette.primary[600],
      },
      warning: {
        main: '#FCDF84',
        contrastText: '#5F4800',
      },
      error: {
        main: theme.palette.secondary[500],
        contrastText: theme.palette.primary[100],
      },
      info: {
        main: theme.palette.neutral[200],
        contrastText: theme.palette.neutral[400],
      },
    }

    return colorCases[type] ?? colorCases.info
  }

export const Root = styled(
  'span',
  Utils.transient.transientStyleProps,
)<BadgeStyleProps>(
  ({
    theme,
    $type,
    $color,
    $textColor,
    $borderRadius,
    $iconColor,
    $clickable,
  }) => {
    const getColor = getBackgroundColor(theme)

    return css`
      background: ${$color || getColor($type).main};
      border-radius: ${$borderRadius}px;
      color: ${$textColor || getColor($type).contrastText};
      font-size: 0.75rem;
      font-weight: ${theme.typography.fontWeightBold};
      padding: 6px ${theme.spacing[2]}px;
      white-space: nowrap;

      transition: background 0.5s;

      display: flex;
      align-items: center;
      justify-content: center;

      cursor: ${$clickable ? 'pointer' : 'default'};

      i {
        ${$iconColor
          ? css`
              color: ${$iconColor};
            `
          : ''}
        margin-right: ${theme.spacing[1]}px;
        font-size: 1rem;
      }
    `
  },
)
