import React from 'react'

import { Modal, useTheme, withModal } from '@azos/shared'

import { GuardianMainContentModalProps } from './GuardianMainContentModal.props'
import { Root } from './GuardianMainContentModal.styles'

const GuardianMainContentModal: React.FCC<GuardianMainContentModalProps> = ({
  open,
  onClose,
  children,
}) => {
  const theme = useTheme()

  return (
    <Modal
      variant="drawer"
      backgroundColor={theme.palette.neutral[400]}
      exitBgColor={theme.palette.neutral[300]}
      noGutter
      open={open}
      onClose={onClose}
    >
      <Root>{children}</Root>
    </Modal>
  )
}

export default withModal<GuardianMainContentModalProps>(
  GuardianMainContentModal,
)
