import React from 'react'

import { VideoPlayerProps } from './VideoPlayer.props'
import { Root } from './VideoPlayer.styles'

const VideoPlayer: React.FC<VideoPlayerProps> = ({
  videoUrl,
  width,
  height,
  className,
}) => {
  return (
    <Root className={className}>
      <iframe
        width={width}
        height={height}
        src={videoUrl}
        allowFullScreen
        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      />
    </Root>
  )
}

export default VideoPlayer
