import React from 'react'

import ImageFirebaseStorage, {
  BaseImageFirebaseStorage,
} from './ImageFirebaseStorage'

const ImageGooglePlay: React.VFC<BaseImageFirebaseStorage> = ({
  title = 'Google Play',
}) => {
  return (
    <ImageFirebaseStorage
      src="/B2C/dashboard/general/googleplay.png"
      alt={title}
      title={title}
      width={322}
      height={96}
    />
  )
}

export default ImageGooglePlay
