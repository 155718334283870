import {
  CheckboxSelectOption,
  CheckboxSelectProps,
  ListSelectProps,
  RadioButtonProps,
} from '../../'

export enum ListOptionType {
  RADIO = 'simple',
  RADIO_MULTIPLE = 'radio-multiple',
  CHECKBOX = 'checkbox',
}

export type ListOptionsValue = string | number | boolean

export type ListOptionsSource = CheckboxSelectOption[]
export type ListOptionsData = { id: string; value: ListOptionsValue[] }

export type BaseProps = ListSelectProps | CheckboxSelectProps | RadioButtonProps

export type ListOptionsProps = Omit<BaseProps, 'value'> & {
  type?: string
  options: ListOptionsSource
  value: ListOptionsValue[]
  onChange?: (data: ListOptionsValue[]) => void
  className?: string
}
