import React from 'react'

import { PolicyPaymentStatusContextData } from './PolicyPaymentStatusProvider.props'

export const PolicyPaymentStatusContext = React.createContext(
  {} as PolicyPaymentStatusContextData,
)

const PolicyPaymentStatusProvider: React.FCC = ({ children }) => {
  const [isPaymentChangeSucceeded, setIsPaymentChangeSucceeded] =
    React.useState(false)

  const onPaymentChangeSuccess = () => {
    setIsPaymentChangeSucceeded(true)
  }

  return (
    <PolicyPaymentStatusContext.Provider
      value={{
        isPaymentChangeSucceeded,
        setIsPaymentChangeSucceeded,
        onPaymentChangeSuccess,
      }}
    >
      {children}
    </PolicyPaymentStatusContext.Provider>
  )
}

export default PolicyPaymentStatusProvider
