import { SaveProposalPdf } from '../../../domain/models'
import { Dispatcher, Service } from '../../protocols'

export type ISaveProposalPdfService = Service<SaveProposalPdf>

/**
 * Save Proposal Pdf
 *
 * @category Checkout
 */
export class SaveProposalPdfService implements ISaveProposalPdfService {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @param {ProductPurchase} data
   * @function
   */
  execute(data: SaveProposalPdf): void {
    this.dispatcher.execute('save_proposal_pdf', data)
  }
}
