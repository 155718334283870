import React from 'react'

import { Text } from '@azos/shared'
import { Collapse } from '@mui/material'

import { CardToggleProps } from '.'
import { Switch } from '../../Switch'
import { Root } from './CardToggle.styles'

const CardToggle: React.FCC<CardToggleProps> = ({
  children,
  //
  name,
  nameTag = 'strong',
  description,
  inputLabel,
  active = false,
  alwaysShowChildren = false,
  //
  boxProps,
  //
  onChange,
  parentName,
}) => {
  return (
    <Root {...boxProps} onClick={() => onChange(!active)}>
      <div className="header">
        <div className="header__heading">
          <Text variant="h6" component={nameTag}>
            {name}
          </Text>

          <div className="header__toggle-wrapper">
            {inputLabel && (
              <Text variant="h6" component="span">
                {inputLabel}
              </Text>
            )}

            <Switch
              checked={active}
              onChange={() => {}}
              parentName={parentName}
            />
          </div>
        </div>
        {description && (
          <Text variant="body2" component="p">
            {description}
          </Text>
        )}
      </div>

      <Collapse in={active || alwaysShowChildren}>
        {children && <div className="content">{children}</div>}
      </Collapse>
    </Root>
  )
}

export default CardToggle
