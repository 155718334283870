import React from 'react'

import { AlertProps } from './Alert.props'
import { Root } from './Alert.styles'

const Alert: React.FCC<AlertProps> = ({
  icon = 'icon-circle-warning',
  children,
  onClick,
}) => (
  <Root onClick={onClick} $childrenType={typeof children}>
    <span className={icon} />
    {children}
  </Root>
)

export default Alert
