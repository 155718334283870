import React from 'react'

import { Icon, useTheme } from '@azos/shared'
import { Button } from '@azos/shared/src/components/v2'
import { useFormikContext } from 'formik'
// import { Utils } from '../utils'

import { ControlProps } from './Control.props'
import { Root } from './Control.styles'

// const ACTIONS = {
//   BUTTON_BACK: Utils.actions.general.BUTTON_BACK_CONTROL,
//   BUTTON_NEXT: Utils.actions.general.BUTTON_NEXT_CONTROL,
// }

const Control: React.FCC<ControlProps> = ({
  onPrev,
  //
  disabled = false,
  isLoading = false,
  disabledPrevButton = false,
  disabledNextButton = false,
  requiredValid = true,
  hideNextButton = false,
  //
  textNextButton = 'Continuar',
  //
  ...rest
}) => {
  const { isValid: formIsValid, isSubmitting } = useFormikContext<any>()
  const theme = useTheme()

  const isAllDisabled = React.useMemo<boolean>(
    () => disabled || isSubmitting,
    [disabled, isSubmitting],
  )

  const isDisabledPrevButton = React.useMemo(() => {
    return disabledPrevButton || isAllDisabled
  }, [disabledPrevButton, isAllDisabled])

  const isDisabledNextButton = React.useMemo(() => {
    const isRequiredValid = requiredValid && !formIsValid
    return disabledNextButton || isRequiredValid || isAllDisabled
  }, [disabledNextButton, formIsValid, isAllDisabled, requiredValid])

  const iconColor = disabledPrevButton
    ? theme.palette.neutral[200]
    : theme.palette.primary.main

  return (
    <Root $disabled={disabled} $iconColor={iconColor} {...rest}>
      <Button
        variant="outline"
        type="button"
        onClick={onPrev}
        disabled={isDisabledPrevButton}
        // parentName={ACTIONS.BUTTON_BACK}
      >
        <Icon.ArrowLeft />
      </Button>

      {!hideNextButton && (
        <Button
          type="submit"
          variant="primary"
          disabled={isDisabledNextButton || isLoading}
          loading={isSubmitting || isLoading}
          // parentName={ACTIONS.BUTTON_NEXT}
          fullWidth
        >
          {textNextButton}
        </Button>
      )}
    </Root>
  )
}

export default Control
