import { ProposalDenied } from '../../../domain/models'
import { Dispatcher, Service } from '../../protocols'

export type IProposalDeniedService = Service<ProposalDenied>

/**
 * ProposalDeniedService
 *
 * @category Proposals
 */
export class ProposalDeniedService implements IProposalDeniedService {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   * @param {ProposalDenied} data
   */
  execute(data: ProposalDenied): void {
    this.dispatcher.execute('coverage_denied', data)
  }
}
