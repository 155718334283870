import React from 'react'

import {
  makeLoadGuardiansService,
  makeUpsertGuardiansService,
} from '@main/factories/services'

import GuardiansModal from './GuardiansModal'
import { GuardianModalProps } from './GuardiansModal.props'

type Props = Omit<
  GuardianModalProps,
  'loadGuardiansService' | 'upsertGuardiansService'
>

const GuardiansModalFactory: React.VFC<Props> = props => {
  return (
    <GuardiansModal
      {...props}
      loadGuardiansService={makeLoadGuardiansService()}
      upsertGuardiansService={makeUpsertGuardiansService()}
    />
  )
}

export default GuardiansModalFactory
