import { SelectPaymentFrequency } from '../../../domain/models/checkout'
import { Dispatcher, Service } from '../../protocols'

export type ISelectPaymentFrequencyService = Service<SelectPaymentFrequency>

/**
 * Select Payment Frequency
 *
 * @category Checkout
 */
export class SelectPaymentFrequencyService
  implements ISelectPaymentFrequencyService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @param {SelectPaymentFrequency} data
   * @function
   */
  execute(data: SelectPaymentFrequency): void {
    this.dispatcher.execute(
      `Selecionou pagamento ${data.paymentFrequency} no ${data.paymentChannel}`,
    )
  }
}
