import { ProposalRefused } from '../../../domain/models'
import { Dispatcher, Service } from '../../protocols'

export type IProposalRefusedService = Service<ProposalRefused>

/**
 * ProposalRefusedService
 *
 * @category Proposals
 */
export class ProposalRefusedService implements IProposalRefusedService {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   * @param {ProposalRefused} data
   */
  execute(data: ProposalRefused): void {
    this.dispatcher.execute('coverage_decrease', data)
  }
}
