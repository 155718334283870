import React from 'react'

import { useAuth } from '@azos/account'
import analytics from '@azos/analytics'
import { Button, Text } from '@azos/shared/src/components/v2'

import { ResponsibleBrokerEmailModal } from '../ResponsibleBrokerEmailModal'
import { ResponsibleBrokerPhoneModal } from '../ResponsibleBrokerPhoneModal'
import { Root } from './ResponsibleBrokerCard.styles'

const ResponsibleBrokerCard = () => {
  const { user } = useAuth()

  const [isPhoneModalOpen, setIsPhoneModalOpen] = React.useState(false)
  const [isEmailModalOpen, setIsEmailModalOpen] = React.useState(false)

  React.useEffect(() => {
    analytics.dashboard.broker.viewBrokerContactCard.execute()
  }, [])

  const handleClick = () => {
    analytics.dashboard.broker.interactBrokerContactCard.execute()
  }

  return (
    <>
      <Root>
        <div className="responsible-broker-card__header">
          <div className="responsible-broker-card__background-icon">
            <i className="icon-briefcase" />
          </div>
          <Text variant="body2">{user?.brokerUser?.fullName}</Text>
        </div>
        <div className="responsible-broker-card__button-group">
          <Button
            variant="tertiary"
            color="info"
            fullWidth
            onClick={() => {
              setIsPhoneModalOpen(true)
              handleClick()
            }}
          >
            <i className="icon-dial-phone" />
            Ligar
          </Button>
          <Button
            variant="tertiary"
            color="info"
            fullWidth
            onClick={() => {
              setIsEmailModalOpen(true)
              handleClick()
            }}
          >
            <i className="icon-email" />
            E-mail
          </Button>
        </div>
      </Root>
      <ResponsibleBrokerPhoneModal
        open={isPhoneModalOpen}
        phone={user?.brokerUser?.phone}
        onClose={() => setIsPhoneModalOpen(false)}
      />
      <ResponsibleBrokerEmailModal
        open={isEmailModalOpen}
        email={user?.brokerUser?.email}
        onClose={() => setIsEmailModalOpen(false)}
      />
    </>
  )
}

export default ResponsibleBrokerCard
