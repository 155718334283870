import { css } from '@azos/shared'
import styled from '@emotion/styled'

/* prettier-ignore */
export const Root = styled('div')(({ theme }) => css`
  padding: 4px 16px;
  
  border-radius: 24px;
  background: ${theme.palette.neutral[100]};
  color: ${theme.palette.neutral[400]};
  font-size: 12px;

  @media (min-width: 768px) {
    font-size: 12px;
  }
`)
