import { css, styled } from '../../styles'

export const Root = styled('div')(
  ({ theme }) => css`
    background: ${theme.palette.background.default};
    border-radius: 60px;
    height: 60px;
    overflow: hidden;
    width: 60px;

    flex: 0 0 60px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: 400px) {
      flex: 0 0 72px;
      width: 72px;
      height: 72px;
      border-radius: 72px;
    }

    span {
      text-transform: uppercase;
      font-size: ${theme.spacing(4)};
      font-weight: 600;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  `,
)
