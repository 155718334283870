import React from 'react'

import { PoliciesResponse } from '@domain/models'
import { makeLoadPoliciesService } from '@main/factories/services'

export type MyPoliciesContextData = {
  policies: PoliciesResponse[]
  setPolicies: (policies: PoliciesResponse[]) => void
  refreshPolicies: () => void
}

export const MyPoliciesContext = React.createContext(
  {} as MyPoliciesContextData,
)

type MyPoliciesProviderProps = {
  policies?: PoliciesResponse[]
}

const loadInsuredPoliciesService = makeLoadPoliciesService()

const MyPoliciesProvider: React.FCC<MyPoliciesProviderProps> = ({
  policies = [],
  children,
}) => {
  const [policiesData, setPoliciesData] =
    React.useState<PoliciesResponse[]>(policies)

  const refreshPolicies = React.useCallback(() => {
    loadInsuredPoliciesService
      .execute()
      .then(({ policies }) => setPoliciesData(policies))
      .catch(console.error)
  }, [])

  return (
    <MyPoliciesContext.Provider
      value={{
        policies: policiesData,
        setPolicies: setPoliciesData,
        refreshPolicies,
      }}
    >
      {children}
    </MyPoliciesContext.Provider>
  )
}

export default MyPoliciesProvider
