import { ClickPolicyCoverageDetailsButtons } from '../../../../../domain/models'
import { Dispatcher, Service } from '../../../../protocols'

export type IClickCoverageDetailsButtonsService =
  Service<ClickPolicyCoverageDetailsButtons>

/**
 * Click Coverage Details Button
 *
 * @category Policy
 */
export class ClickCoverageDetailsButtonsService
  implements IClickCoverageDetailsButtonsService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   * @param {ClickPolicyCoverageDetailsButtons} data
   */
  execute(data: ClickPolicyCoverageDetailsButtons): void {
    this.dispatcher.execute(
      'Area logada web - Clique nos botões de upsell nas telas explicativas de produtos',
      data,
    )
  }
}
