import React from 'react'

import { useSpring, useChain, useSpringRef, animated } from '@react-spring/web'

import { Utils } from '../../utils'
import { ProgressProps } from './Progress.props'
import {
  Root,
  FIRST_HALF_TRANSITION,
  SECOND_HALF_TRANSITION,
  FIRST_HALF_CONFIG,
  SECOND_HALF_CONFIG,
} from './Progress.styles'

const Progress: React.VFC<ProgressProps> = ({
  content,
  percentage,
  size,
  spinnerProps,
}) => {
  const percentageWithBoundaries = Utils.number.getMinMax(percentage)
  const firstHalfRef = useSpringRef()
  const secondHalfRef = useSpringRef()

  const percentageAsRadius = React.useMemo(
    () => percentageWithBoundaries * 3.6,
    [percentageWithBoundaries],
  )

  const firstHalfStyle = useSpring({
    ...FIRST_HALF_TRANSITION(percentageAsRadius),
    config: FIRST_HALF_CONFIG,
    ref: firstHalfRef,
  })

  const secondHalfStyle = useSpring({
    ...SECOND_HALF_TRANSITION(percentageAsRadius),
    config: SECOND_HALF_CONFIG,
    ref: secondHalfRef,
  })

  useChain([firstHalfRef, secondHalfRef], [0, 0.75])

  return (
    <Root
      $radius={percentageAsRadius}
      $size={size}
      $progress={spinnerProps}
      $progressColor={spinnerProps?.progressColor}
    >
      <div className="spinner">
        <animated.div className="spinner__first-half" style={firstHalfStyle} />
        <animated.div
          className="spinner__second-half"
          style={secondHalfStyle}
        />
      </div>
      <span className="spinner__text">
        {content ? content : `${percentage}%`}
      </span>
    </Root>
  )
}

export default Progress
