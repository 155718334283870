import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled('div')(
  ({ theme }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;

    h2 {
      font-size: 24px;
      font-weight: 600;
      margin: 0 0 32px;
    }

    .empty-state-wrapper {
      position: absolute;
      inset: 0;
      display: flex;
      padding: 32px;
      background: rgba(255, 255, 255, 0.7);
      z-index: 1;
      backdrop-filter: blur(2px);
    }

    form {
      height: 100%;
      display: flex;
      flex-direction: column;

      > div {
        flex: 1;

        > div {
          margin-bottom: ${theme.spacing[4]}px;
        }
      }
    }
  `,
)
