import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled('div')(
  ({ theme }) => css`
    .header {
      margin-bottom: 16px;

      h4 {
        font-size: 18px;
        font-weight: 600;
        margin: 0 0 8px;
      }

      p {
        font-size: 16px;
        margin: 0;
      }
    }

    .card {
      position: relative;
      background: #fff;
      padding: 16px;
      overflow: hidden;

      .loading {
        position: absolute;
        inset: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(255, 255, 255, 0.75);
        z-index: 1;
        backdrop-filter: blur(2px);
        padding: 30px;
      }

      .empty-state-wrapper {
        opacity: 0.4;
        margin: 24px 0 12px;
        color: ${theme.palette.neutral[500]};

        .empty-icon {
          margin-bottom: 8px;
        }
      }

      ul {
        margin: 32px 0 0;
        padding: 0;
        list-style: none;
      }

      .add-beneficiary {
        cursor: pointer;
        border: 0;
        background: ${theme.palette.neutral[100]};
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
        font-size: 16px;
        font-weight: 600;

        span {
          font-size: 24px;
        }
      }

      .edit-beneficiaries {
        cursor: pointer;
        border: 0;
        background: transparent;
        font-size: 14px;
        width: 100%;
        padding: 16px 16px 0;
        font-weight: 600;
        color: ${theme.palette.primary.main};
        text-align: center;

        &:disabled {
          color: ${theme.palette.neutral[200]};
        }
      }

      .save-beneficiaries {
        margin-top: 16px;

        span {
          margin-right: 14px;
        }
      }
    }

    .beneficiary {
      & + .beneficiary {
        padding-top: 16px;
        border-top: 1px solid ${theme.palette.neutral[200]};
      }

      .heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;

        p {
          padding: 0;
          margin: 0;
          font-size: 16px;
          font-weight: 600;
        }

        span {
          background: ${theme.palette.neutral[500]};
          padding: 9px 16px;
          border-radius: 4px;
          color: ${theme.palette.neutral[50]};
          font-size: 12px;
          font-weight: 600;
          margin-left: 16px;
        }
      }

      .info {
        p {
          margin: 0 0 16px;
          padding: 0;
          font-size: 16px;
        }
      }
    }
  `,
)
