import { ClickCoverageDetails } from '../../../../../domain/models'
import { Dispatcher, Service } from '../../../../protocols'

export type IUpsellClickCoverageDetailsService = Service<ClickCoverageDetails>

/**
 * Add Coverage Product
 *
 * @category Upsell
 */
export class UpsellClickCoverageDetailsService
  implements IUpsellClickCoverageDetailsService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   * @param {ClickCoverageDetails} data
   */
  execute(data: ClickCoverageDetails): void {
    this.dispatcher.execute('Upsell - Clique "entenda mais" em cobertura', data)
  }
}
