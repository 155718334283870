import React from 'react'

import analytics from '@azos/analytics'
import { withModal } from '@azos/shared'
import { OnboardingActionDetails } from '@presentation/components/molecules/Onboarding'

import { OnboardingActionDetailsCard } from './OnboardingActionDetailsCard.props'
import { Modal } from './OnboardingActionDetailsCard.styles'

const OnboardingActionDetailsCard: React.VFC<OnboardingActionDetailsCard> = ({
  action,
  onManualComplete,
  onRedirect,
  onClose,
  open,
  tag,
  ...rest
}) => {
  if (!action) return null

  return (
    <Modal
      {...rest}
      open={open}
      onClose={onClose}
      variant="drawer"
      showHeader={false}
    >
      <button type="button" onClick={onClose} className="exit-button-modal">
        <span className="icon-exit" />
      </button>

      <OnboardingActionDetails
        tag={tag}
        action={action}
        onManualComplete={onManualComplete}
        onRedirect={onRedirect}
      />
    </Modal>
  )
}

export default withModal(OnboardingActionDetailsCard)
