import React from 'react'

import FormHelperText from '@mui/material/FormHelperText'
import Grid from '@mui/material/Grid'

import { RadioButtonProps } from '.'
import { RadioButtonOption } from './components'
import { Root } from './RadioButton.styles'

export const RadioButton = ({
  name,
  options,
  columns = 2,
  disabled,
  showInput,
  //
  value = null,
  onChange,
  //
  error,
  helperText,
  parentName,
}: RadioButtonProps) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Root
          className={`column-${columns}`}
          role="radiogroup"
          aria-label={name}
        >
          {options.map((option, index) => (
            <RadioButtonOption
              key={`${index}-${option.value.toString()}`}
              name={name}
              label={option.label}
              value={option.value}
              checked={option.value === value}
              onChange={() => onChange(option.value)}
              disabled={disabled}
              parentName={parentName}
              showInput={showInput}
            />
          ))}
        </Root>
      </Grid>
      <Grid item xs={12}>
        {error && (
          <FormHelperText error sx={{ width: 1 }}>
            {helperText}
          </FormHelperText>
        )}
      </Grid>
    </Grid>
  )
}

export default RadioButton
