import React from 'react'

import { Modal, useMediaQuery } from '@azos/shared'
import { Button, ButtonSelect, Text } from '@azos/shared/src/components/v2'

import { HelpCenterModalProps } from './HelpCenterModal.props'
import { Root } from './HelpCenterModal.styles'

const HelpCenterModal: React.FCC<HelpCenterModalProps> = ({
  onClose,
  ...props
}) => {
  const { isMobile } = useMediaQuery()

  return (
    <Modal
      {...props}
      onClose={onClose}
      variant={isMobile ? 'bottomDrawer' : 'modal'}
      maxWidth={isMobile ? undefined : '480px'}
      showCloseButton={false}
      showHeader={false}
    >
      <Root>
        <div className="help-center-modal__title">
          <Text
            className="help-center-modal__title-content"
            variant="bodyBold2"
          >
            Central de dúvidas
          </Text>
          <i className="icon-exit" onClick={onClose} />
        </div>
        <Text variant="body2" className="help-center-modal__description">
          Saiba mais sobre a nossa missão, tire suas dúvidas e se informe sobre
          o mundo dos seguros no nosso blog:
        </Text>

        <div className="help-center-modal__content">
          <ButtonSelect
            title="Sobre a Azos"
            startIcon={<i className="icon-azos-stroke" />}
            className="help-center-modal__button-select"
            onClick={() =>
              window.open('https://www.azos.com.br/sobre', '_blank')
            }
          />
          <ButtonSelect
            title="Dúvidas frequentes"
            startIcon={<i className="icon-help" />}
            className="help-center-modal__button-select"
            onClick={() =>
              window.open(
                'https://www.azos.com.br/perguntas-frequentes',
                '_blank',
              )
            }
          />
          <ButtonSelect
            title="Blog da Azos"
            startIcon={<i className="icon-write" />}
            className="help-center-modal__button-select"
            onClick={() =>
              window.open('https://www.azos.com.br/vida-segura', '_blank')
            }
          />
        </div>

        <Button
          onClick={onClose}
          fullWidth
          className="help-center-modal__button"
        >
          Fechar
        </Button>
      </Root>
    </Modal>
  )
}

export default HelpCenterModal
