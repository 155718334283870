import { SWRServices } from '@azos/core'
import { ICmsRepository } from '@data/repositories'
import { ListProductCardsUseCase } from '@domain/usecases'

type Params = ListProductCardsUseCase.Params
type Response = ListProductCardsUseCase.Response

export type IListProductCardsService = SWRServices<Params, Response>

export class ListProductCardsService implements IListProductCardsService {
  constructor(private readonly cmsRepository: ICmsRepository) {}

  getUrl(): string {
    return `/product-cards`
  }

  async execute(): Promise<ListProductCardsUseCase.Response> {
    const response = await this.cmsRepository.listProductCards()

    return response
  }
}
