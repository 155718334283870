import styled from '@emotion/styled'

import { css } from '../../../styles'

export const Root = styled('label')(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    margin: ${theme.spacing[4]}px 0;

    font-weight: 400;
    font-size: 14px;
    line-height: 21px;

    .checkbox__input {
      appearance: none;
      margin-right: 16px;
      font: inherit;
      color: currentColor;
      width: 24px;
      height: 24px;
      border: 3px solid currentColor;
      border-radius: 4px;
      display: grid;
      place-content: center;

      &:checked {
        background: ${theme.palette.neutral[500]};
      }

      &::before {
        content: '';
        width: 1rem;
        height: 1rem;
        clip-path: polygon(
          44% 66%,
          19% 42%,
          11% 52%,
          45% 81%,
          96% 29%,
          88% 21%
        );
        transform: scale(0);
        background-color: ${theme.palette.neutral[50]};
        transition: all 0.1s;
      }

      &:checked::before {
        transform: scale(1);
      }

      &:disabled {
        color: ${theme.palette.neutral[200]};
        cursor: not-allowed;

        &:checked {
          background: ${theme.palette.neutral[200]};

          &::before {
            background-color: ${theme.palette.neutral[50]};
          }
        }
      }
    }
  `,
)
