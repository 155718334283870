import { Dispatcher, Service } from '../../protocols'

export type IQuotationDeclineReceiveByEmailService = Service

/**
 * Decline to receive by email
 *
 * @category Quotation
 */
export class QuotationDeclineReceiveByEmailService
  implements IQuotationDeclineReceiveByEmailService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   */
  execute(): void {
    this.dispatcher.execute('quotation_decline_receive_by_email')
  }
}
