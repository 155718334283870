import {
  BenefitsCodeService,
  BenefitsCodeAccessService,
} from '../../../../../../data/services'
import { makeMixpanelDispatcherEvent } from '../../../../infra'

export const makeBenefitsCodeService = () =>
  new BenefitsCodeService(makeMixpanelDispatcherEvent())

export const makeBenefitsCodeAccessService = () =>
  new BenefitsCodeAccessService(makeMixpanelDispatcherEvent())
