import { styled } from '@mui/material'
import ButtonBase, { ButtonBaseProps } from '@mui/material/ButtonBase'

import { css } from '../../styles'
import { Text, TextProps } from '../Text'

export const Root = styled(ButtonBase)<ButtonBaseProps>(
  ({ theme }) => css`
    background: ${theme.palette.common.white};
    border-color: ${theme.palette.common.white};
    border-style: solid;
    border-width: 1px;
    border-radius: 8px;

    transition: 'border 0.3s';

    color: ${theme.palette.common.black};
    font-weight: ${theme.typography.fontWeightMedium};
    padding: ${theme.spacing(3)};

    display: flex;
    align-items: center;

    &:disabled {
      color: ${theme.palette.grey[200]};
      span {
        color: inherit;
      }
    }

    &:hover {
      border-color: ${theme.palette.common.black};
    }

    svg {
      font-size: 20px;
    }

    div.button-select__content {
      display: flex;
      align-items: flex-start;
      flex-direction: row;
      width: 100%;
    }

    svg {
      margin-top: 2px;
    }
    svg + div,
    i + div,
    & + svg,
    & + i {
      margin-left: ${theme.spacing(2)};
    }
  `,
)

export const Title = styled(Text)<TextProps>(
  ({ theme }) => css`
    color: inherit;
    display: block;
    font-weight: ${theme.typography.fontWeightMedium};
    text-align: left;
  `,
)

Title.defaultProps = { variant: 'body1' }

export const Description = styled(Text)<TextProps>(
  ({ theme }) => css`
    color: ${theme.palette.grey[400]};
    display: block;
    text-align: left;
  `,
)

Description.defaultProps = { variant: 'caption' }
