import styled from '@emotion/styled'

import { css } from '../../styles'

export const Root = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex: 1;
    flex-direction: column;

    .carousel {
      &__slider {
        width: 100%;
        display: flex;
        overflow: hidden;
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
        flex: 1;

        & > div + div {
          margin-left: ${theme.spacing[4]}px;
        }
      }

      &__item {
        width: 100%;
        flex: 1 0 100%;
        scroll-snap-align: start end;
        display: table-cell;
        vertical-align: bottom;

        & > div {
          height: 100%;
        }
      }

      &__control {
        margin-top: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &--button-select-wrapper {
          display: flex;

          & > button + button {
            margin-left: ${theme.spacing[2]}px;
          }
        }

        &--button-select {
          height: 4px;
          width: 8px;
          border: none;
          background: ${theme.palette.neutral[300]};
          transition: width 0.25s;
          cursor: pointer;

          &.active {
            width: 24px;
            background: ${theme.palette.neutral[50]};
          }
        }

        &--button {
          background: transparent;
          border: none;
          font-weight: ${theme.typography.fontWeightBold};
          font-size: 0.875rem;
          line-height: 144%;
          color: ${theme.palette.neutral[50]};
          cursor: pointer;

          &.disabled {
            color: ${theme.palette.neutral[300]};
          }
        }
      }
    }
  `,
)
