import React from 'react'

import { useNotification } from '@Azos-Seguros/apollo'
import { Radio } from '@Azos-Seguros/apollo'
import analytics from '@azos/analytics'
import { EmptyState, Spinner } from '@azos/shared'
import { ListAction } from '@azos/shared/src/components/v2'
import { BillingServiceSource } from '@domain/models'
import dayjs from 'dayjs'

import { PaymentEditExpiryProps } from './PaymentEditExpiry.props'
import { Root } from './PaymentEditExpiry.styles'

const ModalEditPaymentExpiry: React.VFC<PaymentEditExpiryProps> = ({
  days,
  onSuccess,
  type,
  id,
  changeExpiryDateService,
  billingService,
  proposalId,
}) => {
  const { addNotification } = useNotification()
  const [loading, setLoading] = React.useState(false)

  const handleEditChange = React.useCallback(
    async (expiresAt: string) => {
      setLoading(true)

      try {
        await changeExpiryDateService.execute({
          id: billingService === BillingServiceSource.VINDI ? proposalId : id,
          type,
          expiresAt,
          billingService: billingService,
        })

        addNotification({
          variant: 'success',
          description: 'Data de vencimento alterada com sucesso.',
        })

        onSuccess(expiresAt)
        analytics.dashboard.policy.policyExpirationDate.execute({
          date: expiresAt,
        })
      } catch (error) {
        addNotification({
          variant: 'error',
          description:
            'Falha ao alterar data de vencimento. Por favor, tente novamente ou entre em contato com nosso suporte.',
        })
      }

      setLoading(false)
    },
    [
      changeExpiryDateService,
      billingService,
      proposalId,
      id,
      type,
      addNotification,
      onSuccess,
    ],
  )

  const dateFormatType =
    billingService === BillingServiceSource.VINDI
      ? 'DD [de] MMMM'
      : '[Todo dia] DD'

  return (
    <Root>
      <h3>Data de vencimento</h3>
      <p>
        A nova data de vencimento será válida apenas para o próximo pagamento.
      </p>

      {loading && (
        <div className="empty-state-wrapper">
          <EmptyState align="center" icon={<Spinner />} title="Salvando">
            Estamos salvando o novo vencimento da fatura.
          </EmptyState>
        </div>
      )}

      {!loading && (
        <ListAction
          name="payment-options"
          options={days.map(day => ({
            name: `${day}`,
            label: dayjs(day).locale('pt-br').format(dateFormatType),
            icon: <Radio color="primary" />,
            disabled: loading,
            onClick: () => handleEditChange(day),
          }))}
        />
      )}
    </Root>
  )
}

export default ModalEditPaymentExpiry
