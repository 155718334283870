import React from 'react'

import { Text } from '../../Text'
import {
  CardItemsProps,
  ShareSocialMediaCardProps,
} from './ShareSocialMediaCard.props'
import { CardContent } from './ShareSocialMediaCard.styles'

const MESSAGE =
  'Você precisa ver o tanto que esse seguro de vida vale a pena. Você consegue simular em 1 minuto'

const ShareSocialMediaCard: React.VFC<ShareSocialMediaCardProps> = ({
  text = 'Viu como é acessível? Ajude mais familias a se protegerem',
  url = 'www.azos.com.br',
  whatsAppRedirectUrl = 'https://bit.ly/3Ltro4B',
  facebookRedirectUrl = 'https://bit.ly/3qPerdn',
  onShareClick,
}) => {
  const whatsappUrl = `https://api.whatsapp.com/send?text=${MESSAGE} ${encodeURIComponent(
    whatsAppRedirectUrl,
  )}`

  const facebookUrl = `
        https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          url,
        )}&quote=${MESSAGE} ${encodeURIComponent(facebookRedirectUrl)}`

  const socialMedia: CardItemsProps[] = [
    {
      icon: 'icon-whatsapp',
      onClick: () => window.open(whatsappUrl, '_blank'),
      label: 'whatsapp',
    },
    {
      icon: 'icon-facebook',
      onClick: () => {
        window.open(facebookUrl, '_blank')
      },
      label: 'facebook',
    },
  ]

  const renderSocialMedia = socialMedia.map(item => (
    <div
      key={item.icon}
      className="social-media-content__item"
      onClick={() => onShareClick(item.label)}
    >
      <i className={item.icon} onClick={item.onClick} />
    </div>
  ))

  return (
    <CardContent>
      <Text variant="body2" component="p">
        {text}
      </Text>
      <div className="social-media-content">{renderSocialMedia}</div>
    </CardContent>
  )
}

export default ShareSocialMediaCard
