import React from 'react'

import { Text } from '../Text'
import { PageHeaderProps } from './PageHeader.props'
import { Root } from './PageHeader.styles'

const PageHeader: React.FCC<PageHeaderProps> = ({
  icon,
  title,
  description,
  linkText,
  onLinkClick,
  className,
}) => {
  return (
    <Root className={className}>
      <i className={`${icon} page-header__icon`} />
      <Text variant="h4" className="page-header__title">
        {title}
      </Text>
      {description && (
        <Text variant="body1" className="page-header__description">
          {description}
        </Text>
      )}
      {linkText && (
        <div className="page-header__link-content">
          <button onClick={onLinkClick} className="page-header__button">
            {linkText}
          </button>
          <i className="icon-arrow-topright" />
        </div>
      )}
    </Root>
  )
}

export default PageHeader
