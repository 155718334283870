import { Select } from '@Azos-Seguros/apollo'
import styled from '@emotion/styled'

import { css } from '../../../styles'
import { Utils } from '../../../utils'

type SelectStyleProps = {
  $fullWidth?: boolean
}

export const ApolloSelect = styled(
  Select,
  Utils.transient.transientStyleProps,
)<SelectStyleProps>(
  ({ $fullWidth }) =>
    css`
      ${$fullWidth
        ? css`
            width: 100%;
          `
        : ''}
    `,
)
