import { keyframes } from '@emotion/react'

import { css, styled } from '../../styles'
import { Utils } from '../../utils'

type BaseProps = React.AllHTMLAttributes<HTMLSpanElement>

type SpinnerProps = BaseProps & {
  $size: number
  $borderSize: number
  $backgroundColor?: string
}

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

export const Root = styled(
  'span',
  Utils.transient.transientStyleProps,
)<SpinnerProps>(
  ({ $size, $backgroundColor = 'rgba(0, 0, 0, 0.03)', $borderSize }) => css`
    display: block;
    width: ${$size}px;
    height: ${$size}px;
    border: ${$borderSize}px solid ${$backgroundColor};
    border-top: ${$borderSize}px solid #00b000;
    border-radius: 50%;
    animation: ${spin} 1.5s linear infinite;
  `,
)
