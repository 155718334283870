import Box, { BoxProps } from '@mui/material/Box'

import { css, styled } from '../../../styles'
import { Text, TextProps } from '../../Text'

export const Root = styled(Box)<BoxProps>(
  ({ theme }) => css`
    background: #ffffff;
    border-color: #ffffff;
    border-style: solid;
    border-width: 1px;
    border-radius: 8px;

    transition: border 0.3s;

    color: #090a0b;
    font-weight: 600;
    padding: 16px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
      font-size: 1.25rem;
    }

    svg + div,
    div + svg {
      margin-left: 8px;
    }

    .list-item__container {
      display: flex;
      align-items: flex-start;
      flex-direction: row;
      width: 100%;

      svg {
        margin-top: 2px;
      }
      svg + div,
      i + div,
      & + svg,
      & + i {
        margin-left: 8px;
      }
    }
  `,
)

export const Title = styled(Text)<TextProps>(
  () => css`
    color: inherit;
    display: block;
    font-weight: 600;
    text-align: left;
  `,
)

Title.defaultProps = { variant: 'body1' }

export const Description = styled(Text)<TextProps>(
  ({ theme }) => css`
    color: #505969;
    display: block;
    text-align: left;
  `,
)

Description.defaultProps = { variant: 'caption' }
