import React from 'react'

import { MyPoliciesContext } from './MyPoliciesProvider'

export const useMyPolicies = () => {
  const context = React.useContext(MyPoliciesContext)

  if (!context) {
    throw new Error('useMyPolicies must be used within a MyPoliciesProvider')
  }

  return context
}
