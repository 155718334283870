import { AppError, IProposalConsumer, Services } from '@azos/core'
import { IQuotationsRepository } from '@data/repositories'
import { UpdateQuotationUseCase } from '@domain/usecases'

type Params = Omit<UpdateQuotationUseCase.Params, 'quotationId'> & {
  quotationId?: string
}
type Response = UpdateQuotationUseCase.Response

export type IUpdateQuotationService = Services<Params, Response>

export class UpdateQuotationService implements IUpdateQuotationService {
  constructor(
    private readonly quotationsRepository: IQuotationsRepository,
    private readonly proposalConsumer: IProposalConsumer,
  ) {}

  async execute(params: Params): Promise<Response> {
    let quotationId = params?.quotationId

    if (!quotationId) {
      quotationId = this.proposalConsumer.execute()?.quotationId
    }

    if (!quotationId) throw new AppError()

    const payload = Object.assign(params, { quotationId })

    const quotation = await this.quotationsRepository.update(payload)

    return quotation
  }
}
