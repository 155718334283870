import { Kinship } from '@azos/core'
import dayjs from 'dayjs'
import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
  fullName: Yup.string()
    .test({
      name: 'full_name',
      test: value => {
        const splitValue = value?.trim().split(' ')
        return (splitValue && splitValue?.length >= 2) || false
      },
      message: 'O nome e sobrenome são obrigatórios',
    })
    .required('O nome completo é obrigatório'),
  kinship: Yup.string().required('Você deve selecionar um grau de parentesco'),
  birthDate: Yup.date()
    .test({
      name: 'birth_date',
      test: value =>
        dayjs(value).isAfter(dayjs().subtract(150, 'year').toDate()),
      message: 'Você deve selecionar uma data de no máximo 150 anos',
    })
    .test({
      name: 'birth_date',
      test: value => dayjs(value).isBefore(dayjs().toDate()),
      message: 'Você deve selecionar uma data passada',
    })
    .required('A data de nascimento é obrigatória')
    .nullable(),
  kinshipDetails: Yup.string().when('kinship', {
    is: (value: string) => value === Kinship.AMIGO || value === Kinship.OUTROS,
    then: Yup.string().required('Este campo é obrigatório.'),
  }),
})
