import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled('button')(
  ({ theme }) => css`
    background: ${theme.palette.neutral[50]};
    border: 1px solid ${theme.palette.neutral[200]};
    border-radius: 8px;
    color: ${theme.palette.neutral[400]};
    cursor: pointer;

    min-height: 50px;
    padding: 0 16px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;

    .icon-google {
      margin-right: 14px;
      font-size: 20px;
    }
  `,
)
