export const mapperBrandToIcon = (brand: string): string => {
  const types = {
    Master: 'mastercard',
    american_express: 'amex',
    americanexpress: 'amex',
    diners_club: 'diners',
    dinersclub: 'diners',
  }

  return types[brand] || brand?.toLowerCase()
}
