export enum ReferralContextType {
  GUARDIANS = 'Guardiões',
  SHARE = 'Indicar',
  BENEFICIARIES = 'Beneficiários',
  ACQUISITION = 'Aquisição',
  UPSELL = 'Upsell',
}

export enum ReferralUserMaritalStatus {
  MARRIED = 'Casado',
  OTHERS = 'Outros',
}
