import { Services } from '@azos/core'
import { IFinalQuotationsRepository } from '@data/repositories'
import { LoadFinalQuotationsUseCase } from '@domain/usecases'

type Params = LoadFinalQuotationsUseCase.Params
type Response = LoadFinalQuotationsUseCase.Response

export type ILoadFinalQuotationsService = Services<Params, Response>

export class LoadFinalQuotationsService implements ILoadFinalQuotationsService {
  constructor(
    private readonly proposalsRepository: IFinalQuotationsRepository,
  ) {}

  async execute(proposalId: string): Promise<Response> {
    const response = await this.proposalsRepository.loadFinalQuotations(
      proposalId,
    )

    return response
  }
}
