import {
  ViewAddCreditCardModalService,
  ViewBannerChangePaymentService,
  ViewPaymentDetailsModalService,
} from '../../../../../../data/services'
import { makeMixpanelDispatcherEvent } from '../../../../infra'

export const makeViewAddCreditCardModalService = () =>
  new ViewAddCreditCardModalService(makeMixpanelDispatcherEvent())

export const makeViewBannerChangePaymentService = () =>
  new ViewBannerChangePaymentService(makeMixpanelDispatcherEvent())

export const makeViewPaymentDetailsModalService = () =>
  new ViewPaymentDetailsModalService(makeMixpanelDispatcherEvent())
