import React from 'react'

import { Modal, ModalProps, withModal } from '@azos/shared'

import { BeneficiaryAdd, BeneficiaryAddProps } from '../../BeneficiaryAdd'

type Props = ModalProps & BeneficiaryAddProps

const CardAddBeneficiary: React.VFC<Props> = ({ onClose, open, ...props }) => {
  return (
    <Modal
      showBackButton
      variant="drawer"
      open={open}
      closeOnOverlayClick={false}
      closeOnEsc={false}
      onClose={onClose}
    >
      <BeneficiaryAdd {...props} />
    </Modal>
  )
}

export default withModal(CardAddBeneficiary)
