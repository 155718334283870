import { ChangeCoverageRange } from '../../../../../domain/models'
import { Dispatcher, Service } from '../../../../protocols'

export type IUpsellChangeCoverageRangeService = Service<ChangeCoverageRange>

/**
 * Add Coverage Range
 *
 * @category Upsell
 */
export class UpsellChangeCoverageRangeService
  implements IUpsellChangeCoverageRangeService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   * @param {ChangeCoverageRange} data
   */
  execute(data: ChangeCoverageRange): void {
    this.dispatcher.execute('Upsell - Arrasta ranger em cobertura', data)
  }
}
