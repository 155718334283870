import React from 'react'

import analytics from '@azos/analytics'
import { Modal, useTheme, useWindowSize, withModal } from '@azos/shared'
import { Button, Switch, Text } from '@azos/shared/src/components/v2'
import { GuardianAddressDataForm } from '@presentation/components/atoms/GuardianAddressDataForm'
import { GuardianPersonalDataForm } from '@presentation/components/atoms/GuardianPersonalDataForm'
import { Form, Formik } from 'formik'

import { GuardianFormValue } from '../GuardiansModal'
import { GuardianEditModalProps } from './GuardianEditModal.props'
import { ConfirmModalRoot, Root } from './GuardianEditModal.styles'
import { validationSchema } from './GuardianEditModal.validations'

const ModalEditGuardian: React.VFC<GuardianEditModalProps> = ({
  open,
  guardian,
  onClose,
  onSubmit,
  loading,
}) => {
  if (!guardian) return null

  const theme = useTheme()

  React.useEffect(() => {
    if (open) analytics.dashboard.guardian.viewEditGuardian.execute()
  }, [open])

  const [initialValues, setInitialValues] =
    React.useState<GuardianFormValue>(guardian)
  const { width } = useWindowSize()
  const [isConfirmingGuardianEdit, setIsConfirmingGuardianEdit] =
    React.useState(false)

  const isMobile = React.useMemo(() => width < 768, [width])

  const userHasAddressFilled = React.useMemo(() => {
    return (
      !!guardian.zipCode &&
      !!guardian.state &&
      !!guardian.uf &&
      !!guardian.city &&
      !!guardian.street &&
      !!guardian.number &&
      !!guardian.neighborhood
    )
  }, [guardian])

  React.useEffect(() => {
    setInitialValues(values => ({
      ...values,
      zipCode: values?.zipCode ?? '',
      state: values?.state ?? '',
      city: values?.city ?? '',
      street: values.street ?? '',
      uf: values.uf ?? '',
      number: values.number ?? '',
      complement: values?.complement ?? '',
      neighborhood: values?.neighborhood ?? '',
    }))
  }, [guardian])

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnMount
      enableReinitialize
    >
      {({ values, setFieldValue, submitForm }) => (
        <>
          <Modal
            variant="drawer"
            exitBgColor={theme.palette.neutral[100]}
            open={open}
            onClose={onClose}
          >
            <Root>
              <Text variant="h3">Editar guardião</Text>

              <Form className="modal-content__form">
                <Text variant="h4">Dados pessoais</Text>
                <Text className="modal-content__description">
                  Os dados pessoais são <strong>obrigatórios</strong> para
                  nomear seu guardião.
                </Text>

                <GuardianPersonalDataForm />

                <Text variant="h4">Endereço</Text>

                <Text className="modal-content__description">
                  O preenchimento do endereço do guardião é{' '}
                  <strong>opcional</strong>.
                </Text>

                <div className="modal-content__switch-wrapper">
                  <Text variant="body2">Desejo informar o endereço</Text>
                  <Switch
                    checked={values.hasAddress}
                    onChange={event =>
                      setFieldValue('hasAddress', event.target.checked, true)
                    }
                  />
                </div>

                <GuardianAddressDataForm
                  className="modal-content__address"
                  hasAddressFilled={userHasAddressFilled}
                />

                <Button
                  onClick={() => setIsConfirmingGuardianEdit(true)}
                  className="modal-content__button"
                  type="button"
                  loading={loading}
                  disabled={loading}
                >
                  Salvar alterações
                </Button>
              </Form>
            </Root>
          </Modal>

          <Modal
            variant={isMobile ? 'bottomDrawer' : 'modal'}
            open={isConfirmingGuardianEdit}
            onClose={() => setIsConfirmingGuardianEdit(false)}
            maxWidth="460px"
          >
            <ConfirmModalRoot>
              <Text variant="h5">
                Você deseja salvar as alterações feitas para este guardião?
              </Text>
              <div className="confirm-modal-content__button-group">
                <Button
                  onClick={() => {
                    submitForm()
                    setIsConfirmingGuardianEdit(false)
                  }}
                  fullWidth
                  disabled={loading}
                >
                  Sim
                </Button>
              </div>
            </ConfirmModalRoot>
          </Modal>
        </>
      )}
    </Formik>
  )
}

export default withModal<GuardianEditModalProps>(ModalEditGuardian)
