import React from 'react'
import { animated, AnimationProps, useTransition } from 'react-spring'

import { UserMenuProps, UserPopoverProps } from '.'
import { useOutsideClick } from '../../hooks'
import { Icon } from '../../icons'
import { Root } from './UserMenu.styles'

const getUserInitials = (user: string) =>
  (user.match(/\b(\w)/g) || []).join('').substring(0, 2)

const UserMenu: React.VFC<UserMenuProps> = ({
  user,
  disabled,
  onSignIn,
  onSignOut,
  onDashboard,
  ...rest
}) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const ref = React.useRef<HTMLDivElement>(null)

  const transitions = useTransition(isOpen, {
    from: { opacity: 0, position: 'relative' as AnimationProps },
    enter: { opacity: 1, zIndex: 100, position: 'relative' as AnimationProps },
    leave: { opacity: 0 },
    config: { duration: 150 },
  })

  return (
    <Root ref={ref} {...rest}>
      {user?.displayName && (
        <button onClick={() => setIsOpen(!isOpen)} className="user-button">
          {user?.photoURL && (
            <img
              src={user?.photoURL}
              alt={user?.displayName}
              referrerPolicy="no-referrer"
            />
          )}
          {!user?.photoURL && <span>{getUserInitials(user?.displayName)}</span>}
        </button>
      )}

      {!user?.displayName && (
        <button disabled={disabled} onClick={onSignIn} className="user-button">
          <Icon.User />
        </button>
      )}

      {user?.displayName &&
        transitions(
          (styles, item) =>
            item && (
              <animated.div style={styles}>
                <UserPopover
                  user={user}
                  onSignOut={onSignOut}
                  onDashboard={onDashboard}
                  setIsOpen={setIsOpen}
                  parentRef={ref}
                />
              </animated.div>
            ),
        )}
    </Root>
  )
}

export default UserMenu

const UserPopover: React.VFC<UserPopoverProps> = ({
  user,
  parentRef,
  setIsOpen,
  onSignOut,
  onDashboard,
}) => {
  React.useEffect(() => {
    const handleClose = (event: KeyboardEvent) => {
      event.stopPropagation()

      if (event.key === 'Escape') {
        setIsOpen(false)
      }
    }

    window.addEventListener('keydown', handleClose)

    return () => {
      window.removeEventListener('keydown', handleClose)
    }
  }, [setIsOpen])

  useOutsideClick({
    ref: parentRef,
    config: {
      useEscapeKey: true,
    },
    handler: React.useCallback(() => setIsOpen(false), [setIsOpen]),
  })

  const handleOnDashboard = () => {
    onDashboard?.()
    setIsOpen(false)
  }

  const handleOnSignOut = () => {
    onSignOut?.()
    setIsOpen(false)
  }

  return (
    <div className="user-popover">
      <div className="user-popover-info">
        <div className="user-popover-avatar">
          {user.photoURL && (
            <img
              src={user.photoURL}
              alt={user.displayName || ''}
              referrerPolicy="no-referrer"
            />
          )}

          {!user.photoURL && (
            <span>{getUserInitials(user.displayName || '')}</span>
          )}
        </div>

        <div className="user-popover-text">
          <h5>{user.displayName}</h5>
          <p>{user.email}</p>
          {!!onDashboard && (
            <button onClick={handleOnDashboard}>Minha conta</button>
          )}
        </div>
      </div>

      <div className="user-popover-footer">
        <button onClick={handleOnSignOut}>
          <Icon.SignOut />
          Sair
        </button>
      </div>
    </div>
  )
}
