import React from 'react'

import { useNotification } from '@Azos-Seguros/apollo'
import { useAuth } from '@azos/account'
import { useNotify, CardCopyText, Utils, useMediaQuery } from '@azos/shared'
import { Button, Text } from '@azos/shared/src/components/v2'

import { ResponsibleBrokerModalProps } from './ResponsibleBrokerModal.props'
import { Modal, Root } from './ResponsibleBrokerModal.styles'

const ResponsibleBrokerModal: React.FCC<ResponsibleBrokerModalProps> = ({
  onClose,
  ...props
}) => {
  const { user } = useAuth()
  const { addNotification } = useNotification()
  const { isMobile } = useMediaQuery()

  const onSuccessCopy = (copyType: string) =>
    addNotification({
      variant: 'success',
      description: `${copyType} copiado com sucesso`,
    })

  return (
    <Modal
      {...props}
      onClose={onClose}
      variant={isMobile ? 'bottomDrawer' : 'modal'}
      maxWidth="480px"
      showCloseButton={false}
      showHeader={false}
    >
      <Root>
        <Text className="call-center-modal__title" variant="bodyBold">
          Fale com seu corretor(a)
        </Text>
        <Text variant="bodyBold2" className="call-center-modal__description">
          {user?.brokerUser?.fullName}
        </Text>

        <div className="call-center-modal__content">
          <CardCopyText
            startIcon="icon-dial-phone"
            value={user?.brokerUser?.phone as string}
            customLabel={Utils.formatter.formatPhone(
              user?.brokerUser?.phone ?? '',
            )}
            className="call-center-modal__card-copy"
            shouldShowSuccessLabel
            onClick={() => onSuccessCopy('Telefone')}
          />
          <CardCopyText
            startIcon="icon-email"
            value={user?.brokerUser?.email as string}
            className="call-center-modal__card-copy"
            shouldShowSuccessLabel
            onClick={() => onSuccessCopy('E-mail')}
          />
        </div>

        <Button
          onClick={onClose}
          fullWidth
          className="call-center-modal__button"
        >
          Fechar
        </Button>
      </Root>
    </Modal>
  )
}

export default ResponsibleBrokerModal
