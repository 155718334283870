import React from 'react'

import analytics from '@azos/analytics'
import { ReferralUserMaritalStatus } from '@azos/analytics/src/domain/models'
import { useMediaQuery } from '@azos/shared'
import { Button, CardCopyText } from '@azos/shared/src/components/v2'

import { InsuredContentProps } from './InsuredContent.props'
import { Root } from './InsuredContent.styles'

const InsuredContent: React.FCC<InsuredContentProps> = ({
  copyRef,
  showInteractiveButton,
  showTooltip,
  isTooltipOpen,
  referralUrl,
  notify,
  context,
  userMaritalStatus,
  onShare,
  customCopyEvent,
}) => {
  const { isMobile } = useMediaQuery()

  const isMarried = React.useMemo(
    () => userMaritalStatus === 'married',
    [userMaritalStatus],
  )

  const onCopy = () => {
    if (customCopyEvent) {
      customCopyEvent()
    } else {
      analytics.dashboard.referral.code.referralCopyCode.execute({
        type: context,
        status: isMarried
          ? ReferralUserMaritalStatus.MARRIED
          : ReferralUserMaritalStatus.OTHERS,
      })
    }

    if (notify) {
      notify({
        variant: 'success',
        description:
          'Código copiado com sucesso. Agora só compartilhar com seus amigos e família.',
      })
    }
  }

  return (
    <Root>
      <CardCopyText
        buttonRef={copyRef}
        value={referralUrl}
        onClick={onCopy}
        shouldShowTooltip={showTooltip}
        isTooltipOpen={isTooltipOpen}
      />
      {showInteractiveButton && (
        <Button
          title="Copiar código"
          fullWidth
          onClick={onShare}
          className="insured-content__button"
        >
          {isMobile ? 'Compartilhar código' : 'Copiar código'}
        </Button>
      )}
    </Root>
  )
}

export default InsuredContent
