import {
  AddBeneficiaryModalService,
  EditBeneficiaryModalService,
  PolicyCardHomepageService,
  PolicyPaymentHomepageService,
  SaveBeneficiaryChangesService,
  SaveEditedBeneficiaryModalService,
  SaveNewBeneficiaryModalService,
  SideMenuHomepageService,
  ViewAppDownloadModalService,
} from '../../../data/services'
import { makeMixpanelDispatcherEvent } from '../infra'

export const makeViewAppDownloadModal = () =>
  new ViewAppDownloadModalService(makeMixpanelDispatcherEvent())

export const makeSideMenuHomepageService = () =>
  new SideMenuHomepageService(makeMixpanelDispatcherEvent())

export const makePolicyCardHomepageService = () =>
  new PolicyCardHomepageService(makeMixpanelDispatcherEvent())

export const makePolicyPaymentHomepageService = () =>
  new PolicyPaymentHomepageService(makeMixpanelDispatcherEvent())

export const makeAddBeneficiaryModalService = () =>
  new AddBeneficiaryModalService(makeMixpanelDispatcherEvent())

export const makeSaveNewBeneficiaryModalService = () =>
  new SaveNewBeneficiaryModalService(makeMixpanelDispatcherEvent())

export const makeEditBeneficiaryModalService = () =>
  new EditBeneficiaryModalService(makeMixpanelDispatcherEvent())

export const makeSaveEditedBeneficiaryModalService = () =>
  new SaveEditedBeneficiaryModalService(makeMixpanelDispatcherEvent())

export const makeSaveBeneficiaryChangesService = () =>
  new SaveBeneficiaryChangesService(makeMixpanelDispatcherEvent())
