import React from 'react'

import { useNotification } from '@Azos-Seguros/apollo'
import { Utils, Modal } from '@azos/shared'
import { ButtonSelect } from '@azos/shared/src/components/v2'
import copy from 'copy-to-clipboard'

import { ResponsibleBrokerPhoneModalProps } from './ResponsibleBrokerPhoneModal.props'
import { Root } from './ResponsibleBrokerPhoneModal.styles'

const ResponsibleBrokerPhoneModal: React.FCC<
  ResponsibleBrokerPhoneModalProps
> = ({ onClose, phone, ...props }) => {
  const { addNotification } = useNotification()

  const onCopy = (value?: string) => {
    if (value) {
      copy(value)
      addNotification({
        variant: 'success',
        description: `Telefone copiado com sucesso`,
      })
    }
  }

  return (
    <Modal
      {...props}
      onClose={onClose}
      variant="bottomDrawer"
      showCloseButton={true}
      showHeader={true}
      title={Utils.formatter.formatPhone(phone ?? '')}
      noGutter
    >
      <Root>
        <div className="responsible-broker-phone-modal__content">
          <ButtonSelect
            title="Copiar o número"
            startIcon={<i className="icon-copy" />}
            className="responsible-broker-phone-modal__button-select"
            onClick={() => onCopy(phone)}
          />
          <ButtonSelect
            title="Fazer ligação"
            startIcon={<i className="icon-dial-phone" />}
            className="responsible-broker-phone-modal__button-select"
            onClick={() => window.open(`tel:${phone}`)}
          />
        </div>
      </Root>
    </Modal>
  )
}

export default ResponsibleBrokerPhoneModal
