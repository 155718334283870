import { PolicyCardHomepage } from '../../../domain/models'
import { Dispatcher, Service } from '../../protocols'

export type IPolicyCardHomepageService = Service<PolicyCardHomepage>

/**
 * Policy Card Homepage
 *
 * @category Callpage
 */
export class PolicyCardHomepageService implements IPolicyCardHomepageService {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @param {PolicyCardHomepage} data
   * @function
   */
  execute(data: PolicyCardHomepage): void {
    this.dispatcher.execute(
      'Area logada web - Visualização do modal com detalhamento de apólice',
      data,
    )
  }
}
