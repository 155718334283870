import { SWRServices } from '@azos/core'
import { ICmsRepository } from '@data/repositories'
import { CoverageModalUseCase } from '@domain/usecases'

type Params = CoverageModalUseCase.Params
type Response = CoverageModalUseCase.Response

export type ICoverageModalService = SWRServices<Params, Response>

export class CoverageModalService implements ICoverageModalService {
  constructor(private readonly cmsRepository: ICmsRepository) {}

  getUrl(params: Params): string {
    return `/coverages-modal/${params.coverageCode}`
  }

  async execute(params: Params): Promise<CoverageModalUseCase.Response> {
    return await this.cmsRepository.CoverageModal(params)
  }
}
