export enum ClickPoliciesCardType {
  POLICIES = 'Apólices',
  PROPOSALS = 'Propostas',
}

/**
 * Click Policies Cards
 *
 * @alias ClickPoliciesCards
 */

export type ClickPoliciesCards = {
  /**
   * Card type
   */
  type: ClickPoliciesCardType
}
