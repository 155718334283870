export enum ClickDpsOtherDiseasesType {
  SIM = 'Sim',
  NAO = 'Não',
}

/**
 * Continue Life Style Question
 *
 * @alias ClickDpsOtherDiseasesQuestion
 */
export type ClickDpsOtherDiseasesQuestion = {
  /**
   * Question Answer Type
   */
  type: ClickDpsOtherDiseasesType
}
