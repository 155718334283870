import { GrowthBook } from '@growthbook/growthbook-react'

export const setupGrowthbook = (
  gaId: string,
  growthbook: GrowthBook<Record<string, any>>,
) => {
  growthbook.setAttributes({
    id: gaId,
  })
  growthbook
    .loadFeatures({ autoRefresh: false, skipCache: true })
    .then(() => {
      console.debug('Growthbook configured!')
    })
    .catch(err => {
      console.warn('Growthbook not configured!')
      console.error(err)
    })
}
