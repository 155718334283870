import React from 'react'

import { Button } from '@Azos-Seguros/apollo'
import analytics from '@azos/analytics'
import { Modal, useTheme, withModal } from '@azos/shared'
import { Text } from '@azos/shared/src/components/v2'
import { ListProtectedBroker } from '@domain/models'

import { ProtectedB2CHelpModal } from '../ProtectedB2CHelpModal'
import { ProtectedBrokerHelpModal } from '../ProtectedBrokerHelpModal'
import { ProtectedRequestHelpListModal } from '../ProtectedRequestHelpListModal'
import { TOPICS } from './GuardianAboutModal.config'
import { GuardianAboutModalProps } from './GuardianAboutModal.props'
import { Root } from './GuardianAboutModal.styles'

const GuardianAboutModal: React.VFC<GuardianAboutModalProps> = ({
  open,
  onClose,
  protectedList,
  isLoading,
  user,
  isGuardian,
}) => {
  const theme = useTheme()

  const [isProtectedListHelpOpen, setIsProtectedListHelpOpen] =
    React.useState(false)
  const [isShowingBrokerHelpModal, setIsShowingBrokerHelpModal] =
    React.useState(false)
  const [isShowingB2CHelpModal, setIsShowingB2CHelpModal] =
    React.useState(false)

  const [selectedBroker, setSelectedBroker] =
    React.useState<ListProtectedBroker | null>(null)

  React.useEffect(() => {
    if (!isLoading) {
      analytics.dashboard.guardian.viewGuardianRoleResponsability.execute({
        isInsured: !!user?.isInsured,
        isGuardian: isGuardian,
      })
    }
  }, [isGuardian, isLoading, user?.isInsured])

  const handleRequestHelp = () => {
    analytics.dashboard.guardian.clickRequestHelpGuardianRoleResponsability.execute(
      {
        isInsured: !!user?.isInsured,
        isGuardian,
      },
    )

    if (protectedList.length === 1) {
      if (protectedList[0].broker) {
        setIsShowingBrokerHelpModal(true)
        setSelectedBroker(protectedList[0].broker)
      } else {
        setIsShowingB2CHelpModal(true)
      }
    }

    if (protectedList.length > 1) {
      setIsProtectedListHelpOpen(true)
    }
  }

  return (
    <>
      <Modal
        variant="drawer"
        exitBgColor={theme.palette.neutral[100]}
        open={open}
        onClose={onClose}
        showHeader={false}
        noGutter
      >
        <Root>
          <div className="guardian-instructions-modal__header">
            <button
              type="button"
              onClick={onClose}
              className="guardian-instructions-modal__header--exit-button"
            >
              <i className="icon-exit" />
            </button>
            <i className="icon-user-heart icon-content" />

            <Text
              variant="h5"
              className="guardian-instructions-modal__header--title"
            >
              Qual seu papel como <label>guardião</label> ou{' '}
              <label>guardiã</label>?
            </Text>
            <Text
              variant="body3"
              className="guardian-instructions-modal__header--description"
            >
              Saiba como ajudar seus protegidos caso precisem da sua ajuda.
            </Text>
          </div>

          <div className="guardian-instructions-modal__instructions">
            {TOPICS.map((item, index) => (
              <div
                key={index}
                className="guardian-instructions-modal__instructions--item"
              >
                <i className={item.icon} />
                <Text variant="bodyBold2">{item.title}</Text>
                <Text
                  variant="body3"
                  className="guardian-instructions-modal__instructions--item-description"
                >
                  {item.text}
                </Text>
              </div>
            ))}
          </div>

          <div className="guardian-instructions-modal__footer">
            <Button fullWidth onClick={handleRequestHelp} loading={isLoading}>
              Solicitar ajuda
            </Button>
          </div>
        </Root>
      </Modal>
      <ProtectedRequestHelpListModal
        open={isProtectedListHelpOpen}
        onClose={() => setIsProtectedListHelpOpen(false)}
      />
      <ProtectedB2CHelpModal
        open={isShowingB2CHelpModal}
        onClose={() => setIsShowingB2CHelpModal(false)}
      />
      <ProtectedBrokerHelpModal
        open={isShowingBrokerHelpModal}
        onClose={() => setIsShowingBrokerHelpModal(false)}
        broker={selectedBroker}
      />
    </>
  )
}

export default withModal<GuardianAboutModalProps>(GuardianAboutModal)
