import React from 'react'

import analytics from '@azos/analytics'
import { Utils } from '@azos/shared'
import { FormControlLabel, Radio as MuiRadio } from '@mui/material'

import { RadioGroupProps } from './Radio.props'
import { RadioGroup } from './Radio.styles'

const Radio = ({
  parentName,
  items,
  direction = 'row',
  ...rest
}: RadioGroupProps) => {
  return (
    <RadioGroup
      {...rest}
      $direction={direction}
      data-action={Utils.dataNames.renderDataName('radio-group', parentName)}
    >
      {items.map((item, index) => (
        <FormControlLabel
          key={index}
          value={item.value}
          label={item.label}
          control={
            <MuiRadio
              className="radio-group__radio"
              data-action={Utils.dataNames.renderDataName(
                item.value.toString(),
                parentName,
              )}
            />
          }
        />
      ))}
    </RadioGroup>
  )
}

export default Radio
