import React from 'react'

import { useNotification, PageHeader } from '@Azos-Seguros/apollo'
import { useAuth } from '@azos/account'
import analytics from '@azos/analytics'
import { GuardianUserType } from '@azos/analytics/src/domain/models'
import {
  PageHeader as PageHeaderComponent,
  ReferralCard,
  useTheme,
} from '@azos/shared'
import { Button, Text } from '@azos/shared/src/components/v2'
import { WizardTutorialTag } from '@data/models'
import utms from '@main/config/utms'
import { useGuardian } from '@presentation/providers/GuardianProvider'
import { useMyPolicies } from '@presentation/providers/MyPoliciesProvider'
import { useOnboardingWizard } from '@presentation/providers/OnboardingWizardProvider'
import { Guardian, PoliciesResponse, TextPolicyStatus } from 'domain/models'

import { ModalReferral } from '../../ModalReferral'
import { GuardianAddModal } from '../GuardianAddModal'
import { GuardianDeleteModal } from '../GuardianDeleteModal'
import { GuardianEditModal } from '../GuardianEditModal'
import { GuardianInstructionsModal } from '../GuardianInstructionsModal'
import { GuardianMainContentModal } from '../GuardianMainContentModal'
import GuardianOptionsModal from '../GuardianOptionsModal/GuardianOptionsModal'
import { GuardiansContext } from '../GuardiansContext/GuardiansContext'
import {
  NoPolicy,
  EmptyStateCard,
  ManagementCard,
  ProtectedTab,
} from './Components'
import { GuardianFormValue, GuardianModalProps } from './GuardiansModal.props'
import { Root } from './GuardiansModal.styles'

const GuardiansModal: React.VFC<GuardianModalProps> = ({
  open,
  onClose,
  loadGuardiansService,
  upsertGuardiansService,
  hideShareLink = false,
}) => {
  const { addNotification } = useNotification()
  const { policies } = useMyPolicies()
  const { wizard, updateSummary } = useOnboardingWizard()
  const { user } = useAuth()
  const { isGuardian } = useGuardian()
  const theme = useTheme()

  const [guardians, setGuardians] = React.useState<Guardian[]>([])
  const [selectedGuardian, setSelectedGuardian] =
    React.useState<GuardianFormValue | null>(null)

  const guardiansActionWasExecuted = React.useMemo(() => {
    return wizard?.tutorial.find(
      item => item.tag === WizardTutorialTag.guardians,
    )?.mainAction?.actions[0].executed
  }, [wizard])

  const referralUTM = React.useMemo(
    () => ({
      utm_source: utms.guardians.utm_source,
      utm_medium: utms.guardians.utm_medium,
      utm_campaign: user?.uid,
    }),
    [user],
  )

  const isBroker = React.useMemo(
    () => user?.isBroker || user?.isInsuredByBroker,
    [user],
  )

  const [activePolicies, setActivePolicies] = React.useState<
    PoliciesResponse[]
  >([])

  const [modalAddGuardian, setModalAddGuardian] = React.useState(false)
  const [isAddingGuardian, setIsAddingGuardian] = React.useState(false)

  const [modalEditGuardian, setModalEditGuardian] = React.useState(false)
  const [isEditingGuardian, setIsEditingGuardian] = React.useState(false)

  const [modalDeleteGuardian, setModalDeleteGuardian] = React.useState(false)
  const [modalOptionsGuardian, setModalOptionsGuardian] = React.useState(false)
  const [isGuardianInstructionsOpen, setIsGuardianInstructionsOpen] =
    React.useState(false)
  const [isDeletingGuardian, setIsDeletingGuardian] = React.useState(false)

  const [loading, setLoading] = React.useState<boolean>(true)
  const [error, setError] = React.useState<boolean>(false)

  const [isReferralOpen, setIsReferralOpen] = React.useState(false)

  const hasPolicies = React.useMemo(
    () => activePolicies && activePolicies.length > 0,
    [activePolicies],
  )

  const filterPoliciesByActive = React.useCallback(() => {
    const inactivePoliciesStatus = [
      TextPolicyStatus.canceled,
      TextPolicyStatus.expired,
      TextPolicyStatus.pre_canceled,
    ]
    const policiesFilteredByActive = policies.filter(
      policy =>
        !inactivePoliciesStatus.includes(
          policy.status[0].label as TextPolicyStatus,
        ),
    )
    setActivePolicies(policiesFilteredByActive)
  }, [policies])

  React.useEffect(() => {
    filterPoliciesByActive()
  }, [filterPoliciesByActive])

  const showEmptyStateCard = !loading && hasPolicies && guardians.length === 0

  const validateOnboardingEvent = async () => {
    if (!guardiansActionWasExecuted) {
      await updateSummary({
        entity: WizardTutorialTag.guardians,
        action: 'action',
      })
    }
  }

  const handleLoadGuardians = React.useCallback(() => {
    setLoading(true)
    setError(false)
    loadGuardiansService
      .execute()
      .then(response => {
        setLoading(false)
        setError(false)
        setGuardians(response.guardians)
      })
      .catch(() => {
        setLoading(false)
        setError(true)
        addNotification({
          variant: 'error',
          description: 'Falha ao carregar os dados de guardiões',
        })
      })
  }, [addNotification, loadGuardiansService])

  React.useEffect(() => {
    if (open) {
      handleLoadGuardians()
      analytics.dashboard.guardian.viewGuardianHomepage.execute({
        type: isBroker ? GuardianUserType.BROKER : GuardianUserType.B2C,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const optionsGuardian = (guardian: Guardian, guardianIndex: number) => {
    setModalOptionsGuardian(true)
    setSelectedGuardian({ ...guardian, guardianIndex })
  }
  const onSubmitAddGuardian = ({
    hasAddress,
    ...guardian
  }: GuardianFormValue) => {
    setIsAddingGuardian(true)

    const formattedCurrentGuardians = guardians.map(
      ({ state, ...currentGuardian }) => ({
        ...currentGuardian,
      }),
    )

    const currentGuardian = {
      name: guardian.name,
      email: guardian.email,
      kinship: guardian.kinship,
      phone: guardian.phone,
      ...(hasAddress && {
        zipCode: guardian.zipCode,
        uf: guardian.uf,
        city: guardian.city,
        neighborhood: guardian.neighborhood,
        street: guardian.street,
        number: guardian.number,
        complement: guardian.complement,
      }),
    }

    upsertGuardiansService
      .execute({
        guardians: [...formattedCurrentGuardians, currentGuardian],
      })
      .then(async () => {
        setGuardians([...guardians, guardian])
        addNotification({
          description: 'Guardião adicionado com sucesso.',
          variant: 'success',
        })
        analytics.dashboard.guardian.saveGuardian.execute()
        setIsAddingGuardian(false)
        setModalAddGuardian(false)

        await validateOnboardingEvent()
      })
      .catch(() => {
        addNotification({
          description:
            'Falha ao adicionar guardião. Por favor, tente novamente mais tarde.',
          variant: 'error',
        })
        setIsAddingGuardian(false)
        setModalAddGuardian(false)
      })
  }

  const onSubmitEditGuardian = ({
    hasAddress,
    guardianIndex,
    state: GuardianState,
    ...guardian
  }: GuardianFormValue) => {
    setIsEditingGuardian(true)

    const filteredGuardians = guardians.filter(
      (_guard, index) => index !== selectedGuardian?.guardianIndex,
    )

    const formattedGuardians = filteredGuardians.map(
      ({ state, ...currentGuardian }) => ({
        ...currentGuardian,
      }),
    )

    const currentGuardian = {
      name: guardian.name,
      email: guardian.email,
      kinship: guardian.kinship,
      phone: guardian.phone,
      ...(hasAddress && {
        zipCode: guardian.zipCode,
        uf: guardian.uf,
        city: guardian.city,
        neighborhood: guardian.neighborhood,
        street: guardian.street,
        number: guardian.number,
        complement: guardian.complement,
      }),
    }

    upsertGuardiansService
      .execute({
        guardians: [...formattedGuardians, currentGuardian],
      })
      .then(async () => {
        setGuardians([
          ...filteredGuardians,
          { ...currentGuardian, ...(hasAddress && { state: GuardianState }) },
        ])
        addNotification({
          description: 'Guardião alterado com sucesso.',
          variant: 'success',
        })
        analytics.dashboard.guardian.saveEditGuardian.execute()
        setIsEditingGuardian(false)
        setModalEditGuardian(false)

        await validateOnboardingEvent()
      })
      .catch(() => {
        addNotification({
          description:
            'Falha salvar as alterações. Por favor, tente novamente mais tarde.',
          variant: 'error',
        })
        setIsEditingGuardian(false)
        setModalEditGuardian(false)
      })
  }

  const onSubmitDeleteGuardian = () => {
    setIsDeletingGuardian(true)

    const filteredGuardians = guardians.filter(
      (_guard, index) => index !== selectedGuardian?.guardianIndex,
    )

    const formattedGuardians = filteredGuardians.map(
      ({ state, ...currentGuardian }) => ({
        ...currentGuardian,
      }),
    )

    upsertGuardiansService
      .execute({
        guardians: formattedGuardians,
      })
      .then(async () => {
        setGuardians(filteredGuardians)
        addNotification({
          description: 'Guardião excluído com sucesso.',
          variant: 'success',
        })
        setModalEditGuardian(false)
        setModalDeleteGuardian(false)
        setModalOptionsGuardian(false)

        await validateOnboardingEvent()
      })
      .catch(() => {
        addNotification({
          description:
            'Falha ao excluir guardião. Por favor, tente novamente mais tarde.',
          variant: 'error',
        })
        setIsEditingGuardian(false)
        setModalEditGuardian(false)
      })
      .finally(() => {
        setIsDeletingGuardian(false)
      })
  }

  const guardianContent = () => {
    return (
      <div className="modal-guardian__content">
        <div
          className="modal-guardian__about"
          role="button"
          onClick={() => setIsGuardianInstructionsOpen(true)}
        >
          <div className="modal-guardian__about-header">
            <div className="modal-guardian__about-header-title">
              <i className="icon-guardian-duotone-24">
                <i className="path1" />
                <i className="path2" />
                <i className="path3" />
              </i>
              <Text variant="bodyBold3" className="modal-guardian__about-title">
                Por que ter um guardião?
              </Text>
            </div>
            <i className="icon-help" />
          </div>
          <Text variant="caption" className="modal-guardian__about-description">
            Saiba mais sobre os benefícios de ter um guardião.
          </Text>
        </div>
        {(showEmptyStateCard || hasPolicies) && (
          <div>
            <Text variant="body2" className="modal-guardian__content-title">
              Você pode nomear até <b>dois guardiões</b> para a sua conta.
            </Text>
          </div>
        )}
        {hasPolicies && <ManagementCard />}
        {!loading && !hasPolicies && <NoPolicy />}
        {showEmptyStateCard && <EmptyStateCard />}
        {!loading && hasPolicies && guardians.length !== 2 && (
          <div className="modal-guardian__content--button">
            <Button
              fullWidth
              onClick={() => setModalAddGuardian(true)}
              loading={isAddingGuardian}
            >
              Adicionar guardião
            </Button>
          </div>
        )}

        {user?.isInsured && (
          <ReferralCard
            showBackgroundImage
            backgroundColor={theme.palette.neutral[100]}
            utm={referralUTM}
            userMaritalStatus={user?.insuredUser?.maritalStatus}
            isBroker={user?.isBroker || user?.isInsuredByBroker}
            isInsured={user?.isInsured}
            notify={addNotification}
          />
        )}
      </div>
    )
  }

  return (
    <GuardiansContext.Provider
      value={{
        guardians,
        loading,
        error,
        setModalDeleteGuardian,
        setModalEditGuardian,
        setSelectedGuardian,
        handleLoadGuardians,
        optionsGuardian,
        isEditingGuardian,
        isDeletingGuardian,
        setIsDeletingGuardian,
      }}
    >
      <GuardianMainContentModal open={open} onClose={onClose}>
        <PageHeaderComponent
          icon="icon-guardian-default-40"
          title="Guardiões"
          description="O Guardião é mais uma garantia de que, caso seus beneficiários precisem, seu seguro será acionado."
        />
        <Root>
          <PageHeader.Root>
            {isGuardian ? (
              <PageHeader.Tabs
                tabs={['Seus guardiões', 'Seus protegidos']}
                tabsContent={[guardianContent(), <ProtectedTab key={1} />]}
              />
            ) : (
              guardianContent()
            )}
          </PageHeader.Root>
        </Root>
      </GuardianMainContentModal>

      <ModalReferral
        open={isReferralOpen}
        onClose={() => setIsReferralOpen(false)}
        utm={referralUTM}
      />
      <GuardianAddModal
        open={modalAddGuardian}
        onClose={() => setModalAddGuardian(false)}
        onSubmit={onSubmitAddGuardian}
        loading={isAddingGuardian}
      />
      <GuardianEditModal
        guardian={selectedGuardian}
        open={modalEditGuardian}
        onClose={() => setModalEditGuardian(false)}
        onSubmit={onSubmitEditGuardian}
        loading={isEditingGuardian}
      />
      <GuardianDeleteModal
        open={modalDeleteGuardian}
        onClose={() => setModalDeleteGuardian(false)}
        onSubmit={onSubmitDeleteGuardian}
        loading={isDeletingGuardian}
      />
      <GuardianInstructionsModal
        open={isGuardianInstructionsOpen}
        onClose={() => setIsGuardianInstructionsOpen(false)}
      />
      <GuardianOptionsModal
        open={modalOptionsGuardian}
        onClose={() => setModalOptionsGuardian(false)}
      />
    </GuardiansContext.Provider>
  )
}

export default GuardiansModal
