import Card, { CardProps } from '@mui/material/Card'

import { css, styled } from '../../../styles'

type RootProps = CardProps<any>

export const Root = styled(Card)<RootProps>(
  ({ theme }) => css`
    background: ${theme.palette.background.paper};
    border-radius: 8px;
    padding: ${theme.spacing(3)};
  `,
)

Root.defaultProps = {
  elevation: 0,
}
