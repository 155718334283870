import {
  CheckoutProvider,
  CreatePaymentTokenResponse,
} from '../../data/protocols/checkout'
import { PaymentError } from '../../domain/errors'
import { PaymentCreditCardParams } from '../../domain/models/checkout'
declare const window: any

export class IuguProvider implements CheckoutProvider {
  private script: any

  constructor(
    private readonly url: string,
    private readonly id: string,
    private readonly testMode: boolean,
  ) {}

  build(): void {
    try {
      const config = () => {
        if (window.Iugu) {
          const { Iugu } = window
          Iugu.setAccountID(this.id)
          Iugu.setTestMode(this.testMode)
          Iugu.setup()
        }
      }

      if (window.Iugu) {
        config()
        return
      }

      this.script = document?.createElement('script')
      this.script.src = this.url
      this.script.async = false
      this.script.id = 'iugu'
      this.script.onload = config

      window.document?.body?.appendChild(this.script)
    } catch {
      // console.error('Error on append the iugu script')
    }
  }

  destroy(): void {
    try {
      if (this.script) {
        window.document?.body?.removeChild(this.script)
      }
    } catch {
      // console.error('Error on remove the iugu script')
    }
  }

  createToken(
    params: PaymentCreditCardParams,
  ): Promise<CreatePaymentTokenResponse> {
    return new Promise((resolve, reject) => {
      const { first_name, last_name, number, month, year, cvv } = params

      const creditCardParams = window.Iugu?.CreditCard(
        number,
        month,
        year,
        first_name,
        last_name,
        cvv,
      )

      if (!creditCardParams?.valid()) {
        reject(new PaymentError())
        return
      }

      window.Iugu?.createPaymentToken(creditCardParams, (response: any) => {
        if (response.errors) {
          reject(response.errors)
          return
        }
        resolve(response)
      })
    })
  }
}
