import HttpStatusCode from 'http-status-codes'

import { UnexpectedError } from '../../../domain/errors'
import {
  LoadBillingStatus,
  LoadInvoices,
} from '../../../domain/usecases/checkout'
import { Utils } from '../../../utils'
import { HttpClient } from '../../protocols/http'

export type ICheckoutRepository = LoadBillingStatus & LoadInvoices

export class CheckoutRepository implements ICheckoutRepository {
  constructor(private readonly httpClient: HttpClient) {}

  public async loadBillingStatus(
    proposalId: string,
  ): Promise<LoadBillingStatus.Response> {
    const httpResponse = await this.httpClient.request({
      url: `/proposals/${proposalId}/billing-status`,
      method: 'get',
    })

    const response = httpResponse.data

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
        return response as LoadBillingStatus.Response
      default:
        throw new UnexpectedError()
    }
  }

  public async loadInvoices({
    proposalId,
    paymentMethod,
    status,
  }: LoadInvoices.Params): Promise<LoadInvoices.Response> {
    const params = Utils.queryString.formatQueryParams({
      status,
      payment_method: paymentMethod,
    })
    const filter = params ? `?${params}` : ''

    const httpResponse = await this.httpClient.request({
      url: `/proposals/${proposalId}/invoices${filter}`,
      method: 'get',
    })

    const response = httpResponse.data

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
        return response as LoadInvoices.Response
      default:
        throw new UnexpectedError()
    }
  }
}
