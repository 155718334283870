import { Dispatcher, Service } from '../../protocols'

export type ISelectCreditCardCashbackService = Service<void>

/**
 * Select creditcard cashback service
 *
 * @category Checkout
 */
export class SelectCreditCardCashbackService
  implements ISelectCreditCardCashbackService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   */
  execute(): void {
    this.dispatcher.execute(
      'B2C - Clicou no botão: Pagar com cartão no modal de cashback.',
    )
  }
}
