import {
  UpsellCopyShareContentService,
  UpsellDurationTimeService,
  UpsellGobackSharePage,
  UpsellSelectContractTypeService,
  UpsellSendReferralFormService,
  UpsellSharePageService,
  UpsellViewSelectPage,
} from '../../../../../../data/services'
import { makeMixpanelDispatcherEvent } from '../../../../infra'

export const makeGobackSharePageService = () =>
  new UpsellGobackSharePage(makeMixpanelDispatcherEvent())

export const makeUpsellSelectContractTypeService = () =>
  new UpsellSelectContractTypeService(makeMixpanelDispatcherEvent())

export const makeUpsellViewSelectPageService = () =>
  new UpsellViewSelectPage(makeMixpanelDispatcherEvent())

export const makeUpsellSendReferralFormService = () =>
  new UpsellSendReferralFormService(makeMixpanelDispatcherEvent())

export const makeUpsellDurationTimeService = () =>
  new UpsellDurationTimeService(makeMixpanelDispatcherEvent())

export const makeUpsellSharePageService = () =>
  new UpsellSharePageService(makeMixpanelDispatcherEvent())

export const makeUpsellCopyShareContentService = () =>
  new UpsellCopyShareContentService(makeMixpanelDispatcherEvent())
