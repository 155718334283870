import { SWRServices } from '@azos/core'
import { IUserRepository } from '@data/repositories'
import { UserManagementStatusUseCase } from '@domain/usecases'

type Params = UserManagementStatusUseCase.Params
type Response = UserManagementStatusUseCase.Response

export type IUserManagementStatusService = SWRServices<Params, Response>

export class UserManagementStatusService
  implements IUserManagementStatusService
{
  constructor(private readonly userRepository: IUserRepository) {}

  getUrl(): string {
    return `/user-management/status`
  }

  async execute(): Promise<UserManagementStatusUseCase.Response> {
    const response = await this.userRepository.getUserManagementStatus()

    return response
  }
}
