import { HttpClient } from '@azos/core'

import {
  CreateInsuredUserService,
  LoadUserProfileService,
} from '../../../data/services'
import { makeCreateInsuredUserMapper } from '../../../main'
import { makeInsuredUserRepository, makeUsersRepository } from '../repositories'

export const makeLoadUserProfileService = (httpClient?: HttpClient) =>
  new LoadUserProfileService(makeUsersRepository(httpClient))

export const makeCreateInsuredUserService = () =>
  new CreateInsuredUserService(
    makeInsuredUserRepository(),
    makeCreateInsuredUserMapper(),
  )
