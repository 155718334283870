import { ClickPoliciesTabs } from '../../../../../domain/models'
import { Dispatcher, Service } from '../../../../protocols'

export type IClickPoliciesTabsService = Service<ClickPoliciesTabs>

/**
 * Click Policies Tabs
 *
 * @category Policies
 */
export class ClickPoliciesTabsService implements IClickPoliciesTabsService {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   * @param {ClickPoliciesTabs} data
   */
  execute(data: ClickPoliciesTabs): void {
    this.dispatcher.execute(
      'Area logada web - Clique nas abas da tela de Apólices',
      data,
    )
  }
}
