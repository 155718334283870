import { Dispatcher, Service } from '../../../../protocols'

export type IViewPaymentDetailsModalService = Service<void>

/**
 * View Banner Change Payment
 *
 * @category Payment
 */
export class ViewPaymentDetailsModalService
  implements IViewPaymentDetailsModalService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  execute(): void {
    this.dispatcher.execute(
      'Area logada web - Visualização de modal com detalhe de pagamento',
    )
  }
}
