import { Dispatcher, Service } from '../../data/protocols'

export class FakeService implements Service {
  params: any

  execute(params?: any): void {
    this.params = params
  }
}

export class FakeInitializer {
  params!: Dispatcher

  async initialize(params?: any): Promise<Dispatcher> {
    return await this.params
  }
}

export class FakeLogout {
  logout(): void {
    return
  }
}
