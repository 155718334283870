import React from 'react'

const SvgComponent = (props: any) => (
  <svg
    width="30"
    height="20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.2154 0L0.345703 19.1362H6.16813L8.27758 15.5555H21.7226L23.832 19.1362H29.6545L17.7847 0H12.2154ZM15 12.3969C14.3117 12.3969 13.639 12.1986 13.0667 11.827C12.4945 11.4553 12.0484 10.9271 11.7851 10.3091C11.5217 9.69112 11.4528 9.01111 11.587 8.35503C11.7213 7.69898 12.0527 7.09635 12.5394 6.62333C13.026 6.15034 13.6461 5.82825 14.3211 5.69775C14.9961 5.56722 15.6958 5.63422 16.3317 5.89021C16.9675 6.14619 17.511 6.57969 17.8933 7.13584C18.2757 7.69204 18.4798 8.34594 18.4798 9.01485C18.4798 9.91183 18.1132 10.7721 17.4606 11.4063C16.808 12.0406 15.9229 12.3969 15 12.3969Z"
      fill="#00B000"
    />
  </svg>
)

export default SvgComponent
