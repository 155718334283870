import React from 'react'

import { Utils } from '../../../utils'
import { CheckboxProps } from './Checkbox.props'
import { Root } from './Checkbox.styles'

const Checkbox: React.VFC<CheckboxProps> = ({
  label,
  name = 'checkbox',
  className,
  parentName,
  innerRef,
  ...props
}) => {
  return (
    <Root>
      <input
        {...props}
        type="checkbox"
        className="checkbox__input"
        name={name}
        ref={innerRef}
        data-action={Utils.dataNames.renderDataName(name, parentName)}
      />
      {label}
    </Root>
  )
}

export default Checkbox
