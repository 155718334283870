export enum ClickPayment {
  CREDIT_CARD = 'crédito',
  PIX = 'pix',
}

/**
 * Click Payment Type
 *
 * @alias ClickPaymentType
 */
export type ClickPaymentType = {
  /**
   * Upsell payment type
   */
  type: ClickPayment
}
