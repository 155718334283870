import React from 'react'

import { Text } from '@azos/shared/src/components/v2'

import { OnboardingActionProps } from './OnboardingAction.props'
import { Root } from './OnboardingAction.styles'

const OnboardingAction: React.VFC<OnboardingActionProps> = ({
  action,
  isMainAction,
  locked = false,
  onClick,
}) => {
  const handleClick = () => {
    onClick(action.content.title, locked, !isMainAction ? action : undefined)
  }

  return (
    <Root onClick={handleClick}>
      <div className="onboarding-action__content">
        {locked ? (
          <i className="icon-padlock onboarding-action__content--icon__locked" />
        ) : action.executed ? (
          <i className=" icon-status-on onboarding-action__content--icon__active" />
        ) : (
          <i className="icon-status-off onboarding-action__content--icon" />
        )}
        <Text variant="body2" className="onboarding-action__content--text">
          {action.content.title}
        </Text>
      </div>

      <i className="icon-chevron-right onboarding-action__redirect--icon" />
    </Root>
  )
}

export default OnboardingAction
