import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled('div')(
  ({ theme }) => css`
    .help-center-modal {
      &__title {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &__title-content {
        display: block;
        margin: ${theme.spacing[4]}px 0 ${theme.spacing[3]}px;
      }

      &__description {
        @media (min-width: ${theme.breakpoints.sm}px) {
          display: block;
          margin-bottom: ${theme.spacing[4]}px;
        }
        display: none;
      }

      &__content {
        display: grid;
        gap: ${theme.spacing[2]}px;
        margin-bottom: ${theme.spacing[2]}px;
      }

      &__card-copy {
        background: ${theme.palette.neutral[100]};
        justify-content: space-between;
        padding: 14px;

        .card_copy_text__value {
          width: auto;
          flex: none;
        }
      }

      &__button-select {
        border: none;
        background: ${theme.palette.neutral[100]};

        .button-select__content {
          align-items: center;
        }

        i {
          font-size: 1.5rem;
        }
      }

      &__button {
        @media (min-width: ${theme.breakpoints.sm}px) {
          height: 48px;
          display: flex;
        }
        display: none;
      }
    }
  `,
)
