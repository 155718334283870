import React from 'react'

import { RadioProps } from './Radio.props'
import { Root } from './Radio.styles'

const Radio: React.ForwardRefRenderFunction<HTMLInputElement, RadioProps> = (
  { id, label = '', className, ...rest },
  ref,
) => (
  <Root $label={!!label} className={className}>
    <input ref={ref} {...rest} id={id} type="radio" />
    <label htmlFor={id}>{label}</label>
  </Root>
)

Radio.displayName = 'Radio'

export default React.forwardRef(Radio)
