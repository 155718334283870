import React from 'react'

import { SelectProps } from './Select.props'
import { ApolloSelect } from './Select.styles'

const Select: React.VFC<SelectProps> = ({
  helperText,
  fullWidth,
  ...props
}) => {
  return (
    <ApolloSelect
      {...props}
      helperText={helperText ? helperText : undefined}
      $fullWidth={fullWidth}
      fullWidth={fullWidth}
    />
  )
}

export default Select
