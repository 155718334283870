import { styled, css } from '../../styles'

export const Root = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;

    .menu_item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: ${theme.spacing[3]}px;
      cursor: pointer;

      i {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 56px;
        height: 56px;
        border-radius: 48px;
        margin-bottom: 12px;
        background: #ffff;
      }
      p {
        width: 74px;
        font-size: 10px;
        font-weight: 700;
        line-height: 16px;
        color: #090a0b;
        text-align: center;
      }

      &--render {
        display: flex;
        justify-content: space-between;
      }
      &--lastbutton {
        background: #ffff;
        padding: 12px 16px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        font-size: 14px;
        margin-top: ${theme.spacing[5]}px;
        color: #ff455b;
        font-weight: ${theme.typography.fontWeightBold};

        i {
          margin-right: 12px;
        }
      }
    }
  `,
)

export default Root
