export enum SimulateInsuranceClickSession {
  PRODUCTS = 'produtos',
  COVERAGE_QUESTIONS = 'como funcionam as coberturas',
  ABOUT_AZOS = 'o que faz a azos diferente',
  AZOS_MEDIA = 'azos na midia',
  GUARDIAN = 'guardiao',
}

export enum SimulateInsuranceClickType {
  LIFE_INSURANCE = 'seguro de vida',
  ACCIDENT_DISABILITY = 'invalidez',
  GENERAL_DISEASES = 'DG',
  GENERAL_DISEASES_10 = 'azos na midia',
  DIH = 'diaria de internação hospitalar',
  FUNERAL_ASSISTANCE = 'assistência funeral',
  INSURANCE_CONTRACT = 'o seguro de vida é para sempre?',
  COVERAGE_VALUE = 'o valor de uma cobertura aumenta?',
  INSURANCE_PAYMENT = 'como vou ter certeza que vou receber o valor?',
  OUR_MISSION = 'nossa missão',
  OUR_DIFFERENTIAL = 'nosso diferencial',
  INSURANCE_IS_FOR_ME = 'seguro é pra mim?',
  ABOUT_INSURANCE = 'não entende segurês?',
  AZOS_MEDIA = 'azos na midia',
  ABOUT_GUARDIAN = 'sobre guardiao',
  WHY_HAVE_A_GUARDIAN = 'porque ter um guardião',
  FAMILIAR_FUNERAL_ASSISTANCE = 'assistência funeral familiar',
  RIT = 'renda por incapacidade temporária',
  SURGERY = 'cirurgias',
  MAC = 'morte acidental',
  IPTA_COMPLEMENTAR = 'ipta estendida',
}

/**
 * Simulate Insurance Click
 *
 * @alias SimulateInsuranceClick
 */

export type SimulateInsuranceClick = {
  /**
   * Check if user is insured
   */
  isInsured: boolean
  /**
   * Check if user is new insured (with no policies yet)
   */
  isNotInsuredNorGuardian: boolean
  /**
   * Check if user is guardian
   */
  isGuardian: boolean
  /**
   * Session where insurance was clicked
   */
  sessao: SimulateInsuranceClickSession
  /**
   * Product type
   */
  tipo?: SimulateInsuranceClickType
}
