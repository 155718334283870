export const getContentByProgress = <T = string | { [key: string]: string }>(
  percentage: number,
  progressColors: {
    starting: T
    ongoing: T
    completing: T
  },
) => {
  if (percentage <= 25) {
    return progressColors.starting
  }

  if (percentage <= 75) {
    return progressColors.ongoing
  }

  return progressColors.completing
}
