import { css, styled } from '@azos/shared'

export const Root = styled('div')(
  ({ theme }) => css`
    .cancellation-validation {
      padding: 24px;
      height: 100%;
      max-height: 100vh;
      overflow: auto;
      padding-bottom: 180px;

      &__navigation {
        box-shadow: none;
        padding: 0;
        background: transparent;
        margin-bottom: 14px;
      }

      &__header {
        &--text {
          padding: 20px 0 20px 0;
          display: flex;
          flex-direction: column;
          margin-bottom: 20px;

          i {
            font-size: 40px;
          }

          h5 {
            margin: 16px 0 8px 0;
            color: ${theme.palette.neutral[500]};

            strong {
              color: ${theme.palette.primary[500]};
            }
          }

          p {
            color: ${theme.palette.neutral[400]};
          }
        }
      }

      &__user {
        &--data {
          :last-of-type {
            margin-top: 45px;
          }

          &__title {
            color: ${theme.palette.neutral[400]};
            margin: 24px 0 18px 0;
          }
        }
      }

      &__cancel {
        hr {
          border-top: 1px solid ${theme.palette.neutral[100]};
          margin: 32px 0 24px 0;
        }

        p {
          margin-bottom: 24px;
          font-weight: 500;
          font-size: 14px;
          color: ${theme.palette.neutral[500]};
        }
      }

      &__button {
        padding: 20px 24px;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: auto;
        background: ${theme.palette.neutral[50]};
        box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.08);
        border-top: 1px;
        button {
          margin-bottom: 8px;
          :last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  `,
)

export default Root
