import React from 'react'

import { makeStorageProvider } from '@azos/core'

type queryStringData = {
  [key: string]: string | string[] | null
}

type getQueryStringProps = {
  qs: queryStringData
}

const storageProvider = makeStorageProvider({}, { crossDomain: true })

export const useWebview = ({ qs }: getQueryStringProps): void => {
  React.useEffect(() => {
    const isWebview = Object.entries(qs).find(([key]) => key === 'webview')

    if (isWebview) {
      storageProvider.set('isWebview', true)
    }
  }, [qs])
}
