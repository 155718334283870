import React from 'react'

import { Button } from '@Azos-Seguros/apollo'
import { useAuth } from '@azos/account'
import analytics from '@azos/analytics'
import {
  GuardianUserType,
  SimulateInsuranceClickSession,
  SimulateInsuranceClickType,
} from '@azos/analytics/src/domain/models'
import { Modal, useTheme, withModal } from '@azos/shared'
import { Text } from '@azos/shared/src/components/v2'
import { env } from '@main/config/env'
import { useGuardian } from '@presentation/providers/GuardianProvider'
import { useRouter } from 'next/router'

import { GuardianInstructionsModalProps } from './GuardianInstructionsModal.props'
import { Root } from './GuardianInstructionsModal.styles'

const TOPICS = [
  {
    icon: 'icon-shield',
    title: 'Suporte para acionar o seguro',
    text: (
      <>
        Milhões de reais em seguros deixam de ser pagos por falta de contato. O
        Guardião é mais uma garantia do acionamento do seguro.
      </>
    ),
  },
  {
    icon: 'icon-hide',
    title: 'Sua privacidade garantida',
    text: (
      <>
        O guardião não terá acesso às informações do seu seguro, apenas o
        necessário para nos acionar em caso de imprevisto.
      </>
    ),
  },
  {
    icon: 'icon-heart',
    title: 'Ajuda nos momentos difíceis',
    text: (
      <>
        Sabemos que se acontecer algum imprevisto nossos segurados podem não ser
        capazes, física ou emocionalmente, de lidar com o processo de
        acionamento do seguro. O guardião(ã) pode oferecer auxílio durante o
        momento da perda.
      </>
    ),
  },
  {
    icon: 'icon-user-users',
    title: 'Um ombro amigo',
    text: (
      <>
        Escolha pessoas de sua confiança e convívio. Elas nos avisarão se algo
        acontecer com você, e também podem acionar o seu corretor ou corretora.
      </>
    ),
  },
  {
    icon: 'icon-briefcase',
    title: 'Corretor sempre ao seu lado',
    type: 'B2B',
    text: (
      <>
        Se você adquiriu o seu seguro Azos com corretores parceiros, o seu
        guardião pode entrar em contato com ele ou ela para ajudar a acionar a
        sua indenização.
      </>
    ),
  },
]

const GuardianInstructionsModal: React.VFC<GuardianInstructionsModalProps> = ({
  open,
  onClose,
}) => {
  const { user } = useAuth()
  const { isGuardian } = useGuardian()
  const router = useRouter()
  const theme = useTheme()

  const [topicsList, setTopicsList] = React.useState(TOPICS)

  const isBroker = React.useMemo(
    () => user?.isBroker || user?.isInsuredByBroker,
    [user],
  )

  React.useLayoutEffect(() => {
    if (!isBroker) {
      setTopicsList(TOPICS.filter(item => item.type !== 'B2B'))
    }
  }, [isBroker])

  React.useEffect(() => {
    analytics.dashboard.guardian.viewGuardianInstructions.execute({
      type: isBroker ? GuardianUserType.BROKER : GuardianUserType.B2C,
    })
  }, [isBroker])

  const handleSimulate = () => {
    analytics.dashboard.home.simulateInsuranceClick.execute({
      sessao: SimulateInsuranceClickSession.GUARDIAN,
      tipo: SimulateInsuranceClickType.WHY_HAVE_A_GUARDIAN,
      isInsured: !!user?.isInsured,
      isGuardian,
      isNotInsuredNorGuardian: !user?.isInsured && !isGuardian,
    })

    if (user?.isInsured) {
      router.push('/simular-novo-seguro')
    } else {
      router.push(env.SIMULATION_URL)
    }
  }

  return (
    <Modal
      variant="drawer"
      exitBgColor={theme.palette.neutral[100]}
      open={open}
      onClose={onClose}
      showHeader={false}
      noGutter
    >
      <Root>
        <div className="guardian-instructions-modal__header">
          <button
            type="button"
            onClick={onClose}
            className="guardian-instructions-modal__header--exit-button"
          >
            <i className="icon-exit" />
          </button>
          <i className="icon-guardian-duotone-24 icon-multicolor">
            <i className="path1" />
            <i className="path2" />
            <i className="path3" />
          </i>
          <Text
            variant="h5"
            className="guardian-instructions-modal__header--title"
          >
            Por que ter um <label>guardião</label>?
          </Text>
          <Text
            variant="body3"
            className="guardian-instructions-modal__header--description"
          >
            Veja aqui alguns benefícios de inserir o guardião ou guardiã. É
            rápido, simples e você pode mudar quando quiser!
          </Text>
        </div>

        <div className="guardian-instructions-modal__instructions">
          {topicsList.map((item, index) => (
            <div
              key={index}
              className="guardian-instructions-modal__instructions--item"
            >
              <i className={item.icon} />
              <Text variant="bodyBold2">{item.title}</Text>
              <Text
                variant="body3"
                className="guardian-instructions-modal__instructions--item-description"
              >
                {item.text}
              </Text>
            </div>
          ))}
        </div>

        <div className="guardian-instructions-modal__footer">
          <Button fullWidth onClick={handleSimulate}>
            Simule um novo seguro
          </Button>
        </div>
      </Root>
    </Modal>
  )
}

export default withModal<GuardianInstructionsModalProps>(
  GuardianInstructionsModal,
)
