import { HttpClient, makeAxiosHttpClient } from '@azos/core'
import {
  GetVindiCreditCardParams,
  GetVindiCreditCardResponse,
} from '@domain/models'

export const PoliciesAPI = (httpClient: HttpClient = makeAxiosHttpClient()) => {
  return {
    getVindiCreditCard: async (
      params: GetVindiCreditCardParams,
    ): Promise<GetVindiCreditCardResponse> => {
      return httpClient
        .request({
          url: `/policies/vindi/credit-card/${params.proposalId}`,
          method: 'get',
        })
        .then(response => response.data)
    },
  }
}
