import { Dispatcher, Service } from '../../../protocols'

export type ISaveGuardianService = Service<void>

/**
 * Save guardian service
 *
 * @category Dashboard
 */
export class SaveGuardianService implements ISaveGuardianService {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @param
   * @function
   */
  execute(): void {
    this.dispatcher.execute(
      'Area logada web - Clique para salvar novo guardião na tela de adicionar guardião',
    )
  }
}
