import React from 'react'
import ReactDOM from 'react-dom'
import { useTransition, animated } from 'react-spring'

import { cx } from '@emotion/css'

// Interface
import { INavigationTabs } from './NavigationTabs.props'

// Styles
import Styles from './NavigationTabs.styles'

const NavigationTabs = React.forwardRef<HTMLDivElement, INavigationTabs>(
  (
    {
      ariaLabel,
      disabled,
      tabs = [],
      content = [],
      contentWrapper,
      className,
      contentRef,
      onTabClick,
    },
    ref,
  ) => {
    const [active, setActive] = React.useState(0)

    const transition = useTransition(content[active], {
      key: active,
      from: { opacity: 0 },
      enter: { opacity: 1 },
      leave: { opacity: 0, position: 'absolute' },
      delay: 0,
      config: {
        mass: 1,
        tension: 210,
        friction: 26,
      },
    })

    const contentElements = transition(
      (style, item) =>
        item && <animated.div style={style}>{item}</animated.div>,
    )

    const ContentWrapperRender = React.memo(function contentWrapperRender() {
      return (
        <div className="content-wrapper">
          {contentWrapper ? contentWrapper(contentElements) : contentElements}
        </div>
      )
    })

    const ContentRender = React.memo(function contentRender() {
      if (contentRef?.current) {
        return ReactDOM.createPortal(
          <ContentWrapperRender />,
          contentRef.current,
        )
      }

      return <ContentWrapperRender />
    })

    return (
      <Styles ref={ref} aria-label={ariaLabel} className={className}>
        <div className="tabs-wrapper">
          {tabs.map((tab, index) =>
            typeof tab === 'string' ? (
              <button
                key={index}
                disabled={disabled}
                className={cx({ selected: index === active })}
                onClick={() => {
                  setActive(index)
                  onTabClick(index)
                }}
              >
                <span>{tab}</span>
              </button>
            ) : (
              <button
                key={index}
                disabled={disabled || tab?.disabled}
                className={cx({ selected: index === active })}
                onClick={() => {
                  setActive(index)
                  onTabClick(index)
                }}
              >
                {tab.icon && (
                  <span aria-label={tab.ariaLabel} className={tab.icon} />
                )}
                {tab.label && <span>{tab.label}</span>}
              </button>
            ),
          )}
        </div>

        <ContentRender />
      </Styles>
    )
  },
)

NavigationTabs.displayName = 'NavigationTabs'

export default NavigationTabs
