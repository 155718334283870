import { ViewPolicyCoverageDetails } from '../../../../../domain/models'
import { Dispatcher, Service } from '../../../../protocols'

export type IViewCoverageDetailsService = Service<ViewPolicyCoverageDetails>

/**
 * View Policy Coverage Details
 *
 * @category Policy
 */
export class ViewCoverageDetailsService implements IViewCoverageDetailsService {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   * @param {ViewPolicyCoverageDetails} data
   */
  execute(data: ViewPolicyCoverageDetails): void {
    this.dispatcher.execute(
      'Area logada web - Visualização das telas explicativas de produtos',
      data,
    )
  }
}
