import React from 'react'

import { SignUp } from '../../components'
import { Container } from '../../containers'

const SignUpView = () => {
  return (
    <Container>
      <SignUp />
    </Container>
  )
}

export default SignUpView
