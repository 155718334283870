import React from 'react'

import { IconAttributes } from '../components/SvgIcon'

const Icon = ({ as, style, ...props }: IconAttributes) => {
  return (
    <i
      className="icon-warning"
      style={{ fontSize: '1.5rem', ...style }}
      {...props}
    />
  )
}

export default Icon
