import { css, styled } from '../../styles'
import { Utils } from '../../utils'
import { BarProps } from './ProgressBar.props'

type ProgressBarProps = {
  $bar?: BarProps
}

export const FILL_CONFIG = {
  duration: 1000,
  tension: 210,
  friction: 20,
}

export const FILL_TRANSITION = (percentage: number) => ({
  from: { width: '0%' },
  to: { width: `${percentage}%` },
})

export const Root = styled(
  'div',
  Utils.transient.transientStyleProps,
)<ProgressBarProps>(
  ({ theme, $bar }) => css`
    position: relative;
    display: inline-block;
    background: ${$bar?.backgroundColor || '#D0D4DC'};
    height: 4px;
    border-radius: 6px;
    overflow: hidden;
    width: 100%;

    .progress-bar {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;

      background: ${$bar?.barColor || '#50C309'};
    }

    progress {
      opacity: 0;
    }
  `,
)
