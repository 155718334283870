import React from 'react'

import { RouterContext } from './RouterProvider'

export const useRouter = () => {
  const context = React.useContext(RouterContext)

  if (!context) {
    throw new Error('useRouterProvider must be used within a RouterProvider')
  }

  return context
}
