import { Dispatcher, Service } from '../../protocols'

export type ISaveEditedBeneficiaryModalService = Service<void>

/**
 * Save Edited Beneficiary modal
 *
 * @category Dashboard
 */
export class SaveEditedBeneficiaryModalService
  implements ISaveEditedBeneficiaryModalService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @param
   * @function
   */
  execute(): void {
    this.dispatcher.execute(
      'Area logada web - Clique para salvar beneficiário editado',
    )
  }
}
