import React from 'react'

import { Tooltip, useNotification } from '@Azos-Seguros/apollo'
import analytics from '@azos/analytics'
import { makeLoadKinshipService } from '@azos/core'
import { useNotify } from '@azos/shared'
import {
  Button,
  Input,
  InputBirthdate,
  Select,
  SelectOption,
  Checkbox,
} from '@azos/shared/src/components/v2'
import { Form, Formik } from 'formik'

import { BeneficiaryAddValues, initialValues } from './BeneficiaryAdd.data'
import { BeneficiaryAddProps } from './BeneficiaryAdd.props'
import { Root } from './BeneficiaryAdd.styles'
import { validationSchema } from './BeneficiaryAdd.validations'

const loadKinshipsService = makeLoadKinshipService()

const BeneficiaryAdd: React.VFC<BeneficiaryAddProps> = ({ onSubmit }) => {
  const { addNotification } = useNotification()

  const [kinships, setKinships] = React.useState<string[]>([])
  const [hasSocialName, setHasSocialName] = React.useState(false)

  const kinshipOptions = React.useMemo<SelectOption[]>(
    () => [
      {
        label: 'Selecione',
        value: '',
        disabled: true,
      },
      ...kinships.map(kinship => ({ label: kinship, value: kinship })),
    ],
    [kinships],
  )

  React.useEffect(() => {
    analytics.dashboard.addBeneficiaryModal.execute()
  }, [])

  React.useEffect(() => {
    loadKinshipsService
      .execute()
      .then(setKinships)
      .catch(() => {
        addNotification({
          description: 'Falha ao carregar opções de grau de parentescos',
          variant: 'error',
        })
      })
  }, [addNotification])

  const handleSubmit = ({ birthDate, ...e }: BeneficiaryAddValues) => {
    if (!!birthDate) {
      analytics.dashboard.saveNewBeneficiaryModal.execute()
      onSubmit({ ...e, birthDate, hasSocialName })
    }
  }

  return (
    <Root>
      <h2>
        Adicionar <strong>beneficiário</strong>
      </h2>

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ values, errors, touched, handleChange, setFieldValue }) => (
          <Form>
            <div>
              <Input
                fullWidth
                autoFocus
                id="fullName"
                name="fullName"
                label="Nome completo"
                error={touched.fullName && !!errors.fullName}
                helperText={touched.fullName && errors.fullName}
                value={values.fullName}
                onChange={handleChange}
              />
              <div className="tooltipWrapper">
                <Checkbox
                  id="hasSocialName"
                  name="hasSocialName"
                  label="Possui nome social?"
                  checked={hasSocialName}
                  onChange={event => setHasSocialName(event.target.checked)}
                />

                <Tooltip
                  title="Nome usado por pessoas que se identificam com um gênero diferente do sexo biológico, em vez do registrado no nascimento."
                  position="top"
                  maxWidth="300px"
                >
                  <i className="icon-circle-help tooltip" />
                </Tooltip>
              </div>

              {hasSocialName && (
                <Input
                  fullWidth
                  id="socialName"
                  name="socialName"
                  label="Nome social"
                  error={touched.socialName && !!errors.socialName}
                  helperText={touched.socialName && errors.socialName}
                  value={values.socialName || ''}
                  onChange={handleChange}
                />
              )}

              <InputBirthdate
                name="birthDate"
                label="Data de nascimento"
                value={values.birthDate}
                id="birthDate"
                error={touched.birthDate && !!errors.birthDate}
                helperText={
                  touched.birthDate ? (errors.birthDate as string) : undefined
                }
                fullWidth
                onChange={date => setFieldValue('birthDate', date, true)}
              />

              <Select
                id="kinship"
                name="kinship"
                label="Grau de parentesco"
                value={values.kinship}
                options={kinshipOptions}
                onChange={event =>
                  setFieldValue('kinship', event.target.value, true)
                }
                error={touched.kinship && !!errors.kinship}
                helperText={touched.kinship && errors.kinship}
                fullWidth
              />

              {(values.kinship === 'Amigo(a)' ||
                values.kinship === 'Outros') && (
                <>
                  <p className="description">
                    Descreva com detalhes qual é a relação com a pessoa e o
                    motivo de indicá-la como beneficiária, para que a Seguradora
                    possa avaliar a indicação.
                  </p>
                  <Input
                    name="kinshipDetails"
                    label=""
                    value={values.kinshipDetails}
                    onChange={handleChange}
                    error={touched.kinshipDetails && !!errors.kinshipDetails}
                    helperText={touched.kinshipDetails && errors.kinshipDetails}
                    fullWidth
                  />
                </>
              )}
            </div>

            <Button fullWidth type="submit">
              Salvar beneficiário
            </Button>
          </Form>
        )}
      </Formik>
    </Root>
  )
}

export default BeneficiaryAdd
