import Box from '@mui/material/Box'

import { styled, css } from '../../../../styles'

export const Container = styled(Box)(
  ({ theme }) => css`
    width: 100%;

    .container__error-message {
      margin-top: ${theme.spacing[3]}px;
      color: ${theme.palette.status.error.main};
    }

    & .container__option-list {
      display: grid;
      grid-template-columns: 1fr;
      gap: ${theme.spacing[2]}px;
    }

    & .container__item__helper-text {
      margin-top: 0.5rem;
      width: 100%;
    }
  `,
)
