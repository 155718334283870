import React from 'react'

import { Typography } from '@Azos-Seguros/apollo'
import analytics from '@azos/analytics'

import { Icon } from '../../../icons'
import { Utils } from '../../../utils'
import { ButtonSelectProps } from './ButtonSelect.props'
import { Description, Root } from './ButtonSelect.styles'

const ButtonSelect: React.VFC<ButtonSelectProps> = ({
  name = 'list-item',
  parentName,
  //
  startIcon,
  endIcon = <Icon.ChevronRight />,
  //
  title,
  description,
  onClick,
  //
  ...rest
}) => {
  const handleClick = (
    params: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    if (parentName) {
      analytics.custom.action.execute({
        dataAction: Utils.dataNames.renderDataName(name, parentName),
      })
    }

    onClick?.(params)
  }

  return (
    <Root
      data-action={Utils.dataNames.renderDataName(name, parentName)}
      title={title}
      onClick={handleClick}
      {...rest}
    >
      <div className="button-select__content">
        {startIcon}
        <div>
          <Typography variant="bodyBold3">{title}</Typography>
          {description && <Description>{description}</Description>}
        </div>
      </div>
      {endIcon}
    </Root>
  )
}

export default ButtonSelect
