import React from 'react'

import { Modal, ModalProps, withModal } from '@azos/shared'

import {
  BeneficiariesEdit,
  BeneficiariesEditProps,
} from '../../BeneficiariesEdit'

type Props = ModalProps & BeneficiariesEditProps

const CardEditBeneficiaries: React.VFC<Props> = ({
  onClose,
  open,
  ...props
}) => {
  return (
    <Modal showBackButton variant="drawer" open={open} onClose={onClose}>
      <BeneficiariesEdit {...props} open={open} />
    </Modal>
  )
}

export default withModal(CardEditBeneficiaries)
