import React from 'react'

interface WindowSize {
  height: number
  width: number
}

const useWindowSize = (): WindowSize => {
  const [windowSize, setWindowSize] = React.useState(() => {
    if (typeof window !== 'undefined') {
      return {
        width: window.innerWidth,
        height: window.innerHeight,
      }
    } else {
      return {
        width: 1200,
        height: 800,
      }
    }
  })

  function changeWindowSize() {
    setWindowSize({ width: window.innerWidth, height: window.innerHeight })
  }

  React.useEffect(() => {
    window.addEventListener('resize', changeWindowSize)
    changeWindowSize()

    return () => {
      window.removeEventListener('resize', changeWindowSize)
    }
  }, [])

  return windowSize
}

export default useWindowSize
