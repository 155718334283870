import { ViewOnboardingBeneficiaryContent } from '../../../../domain/models'
import { Dispatcher, Service } from '../../../protocols'

export type IViewOnboardingBeneficiaryContentService =
  Service<ViewOnboardingBeneficiaryContent>

/**
 * View onboarding beneficiary content
 *
 * @category Dashboard
 */
export class ViewOnboardingBeneficiaryContentService
  implements IViewOnboardingBeneficiaryContentService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @param {ViewOnboardingHomepage} data
   * @function
   */
  execute(data: ViewOnboardingBeneficiaryContent): void {
    this.dispatcher.execute(
      'Area logada web - Visualização do conteúdo de beneficiários do onboarding',
      data,
    )
  }
}
