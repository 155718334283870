import { PolicyCancelUserType } from './policy-cancel'

/**
 * Actions Reduce Value
 *
 * @alias ActionsReduceValue
 */

export enum ActionsReduceValueType {
  SOLICITAR = 'SOLICITAR',
  NAO_OBRIGADO = 'NAO_OBRIGADO',
}

export type ActionsReduceValue = {
  type: PolicyCancelUserType
  action: ActionsReduceValueType
}
