import { css, styled } from '@azos/shared'

export const Root = styled('div')(
  ({ theme }) => css`
    .reason-cancellation {
      &__navigation {
        box-shadow: none;
        padding: 0;
        background: transparent;
        margin: 24px 0 ${theme.spacing[6]}px 0;
        padding-left: 24px;
      }

      &__header {
        background-color: ${theme.palette.secondary[500]};
        height: 268px;
        width: 100%;
        position: absolute;
        top: 0;

        &--text {
          padding: 24px;
          margin-top: 64px;
          display: flex;
          flex-direction: column;

          h5 {
            margin: 16px 0 8px 0;
            color: ${theme.palette.primary[100]};
          }

          p {
            color: ${theme.palette.primary[100]};
          }
        }
      }

      &__content {
        padding-top: 130px;

        &--title {
          margin: 52px 0 24px 24px;
          color: ${theme.palette.neutral[400]};
          display: block;
        }

        &--radio {
          padding: 0 24px;
          width: 100%;

          &__style {
            background: ${theme.palette.neutral[100]};
            margin-bottom: 8px;
            padding: 16px;

            label {
              font-size: 14px;
              font-weight: 600;
              line-height: 20.16px;

              &:after {
                border: none;
                background: ${theme.palette.primary[50]};
              }

              &:before {
                border: 2px solid ${theme.palette.neutral[300]};
              }
            }

            input {
              &:checked {
                & + label {
                  &:before {
                    border: 4px solid ${theme.palette.primary[500]};
                  }
                }
              }
            }
          }
        }

        &--other-reason {
          margin: 16px 24px 12px 24px;
        }
      }

      &__button {
        padding: 24px;

        button {
          padding: 14px;

          :first-of-type {
            margin-bottom: 8px;
          }
        }
      }
    }
  `,
)

export default Root
