import React from 'react'

import { Typography } from '@Azos-Seguros/apollo'

import { SpecialistAvatar } from '../SpecialistAvatar'
import { ChatAvatarCardProps } from './ChatAvatarCard.props'
import Root from './ChatAvatarCard.styles'

const ChatAvatarCard = ({
  title,
  subtitle,
  description,
  buttons,
  className,
  specialist,
}: ChatAvatarCardProps) => {
  return (
    <Root className={className}>
      <div className="chat-avatar-card__wrapper">
        <SpecialistAvatar
          specialist={specialist}
          className="chat-avatar-card__specialist"
        />
        <div className="chat-avatar-card">
          <div className="chat-avatar-card__arrow" />
          <Typography className="chat-avatar-card__title" variant="bodyBold">
            {title}
          </Typography>
          <Typography className="chat-avatar-card__subtitle" variant="bodyBold">
            {subtitle}
          </Typography>
          <Typography className="chat-avatar-card__description">
            {description}
          </Typography>
          {buttons && buttons.length > 0 && (
            <div className="chat-avatar-card__button-group">{buttons}</div>
          )}
        </div>
      </div>
    </Root>
  )
}

export default ChatAvatarCard
