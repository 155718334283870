import { CheckoutPaymentFrequency, PaymentMethod } from '@azos/core'

export enum DiscountType {
  RECRUTING = 'recurring',
  ONE_SHOT = 'one_shot',
}

export interface DiscountByProposal {
  discountConfigurationId?: string
  paymentFrequency: CheckoutPaymentFrequency
  paymentMethod: Array<PaymentMethod>
  originalPrice: number
  discountPrice: number
  type: DiscountType
  percentage: number
}

export interface GetDiscountByProposalResponse {
  discounts: Array<DiscountByProposal>
}
