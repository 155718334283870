import React, { useEffect, useState } from 'react'

import { Utils } from '../../../utils'
import { Input } from '../Input'
import { EditableInputProps } from './EditableInput.props'

const EditableInput = ({
  value,
  //
  onChange,
  ...rest
}: EditableInputProps) => {
  const [inputValue, setInputValue] = useState<number | string>(value)

  useEffect(() => {
    if (inputValue !== value) {
      setInputValue(value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const handleInputBlur = (ev: React.FocusEvent<HTMLInputElement>) => {
    onChange(Number(ev.target.value.replace(/\D/g, '')))
    setInputValue(value)
  }

  const handleInputChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(ev.target.value)
  }

  return (
    <Input
      pattern="/d*/"
      inputMode="numeric"
      className="text-input"
      value={Utils.currency.formatWithoutCents(inputValue, '')}
      onBlur={handleInputBlur}
      onChange={handleInputChange}
      {...rest}
    />
  )
}

export default EditableInput
