export const extractYoutubeId = url => {
  const match = url.match(/v=([^&]+)/)
  return match ? match[1] : null
}

export const getYoutubeEmbedUrl = url => {
  const videoId = extractYoutubeId(url)

  return `https://www.youtube.com/embed/${videoId}`
}
