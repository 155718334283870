import React from 'react'

import { Text } from '@azos/shared/src/components/v2'

import { UserAccountModal } from '../../UserAccountModal'
import { Root } from './UserAccountCard.styles'

const UserAccountCard = () => {
  const [userAccoundOpen, setUserAccoundOpen] = React.useState(false)

  return (
    <Root>
      <div
        className="user-account-card__header"
        onClick={() => setUserAccoundOpen(true)}
        role="button"
      >
        <div className="user-account-card__background-icon">
          <i className="icon-user" />
        </div>
        <div className="user-account-card__info">
          <Text variant="bodyBold3">Sua conta</Text>
          <Text
            variant="caption"
            className="user-account-card__drawer-subtitle"
          >
            Mantenha seus dados atualizados.
          </Text>
        </div>
      </div>
      <UserAccountModal
        open={userAccoundOpen}
        onClose={() => setUserAccoundOpen(false)}
      />
    </Root>
  )
}

export default UserAccountCard
