export enum infoBanner {
  CHANGE_PAYMENT_TYPE = 'Alterar_meio_de_pagamento',
  ONBOARDING = 'onboarding',
  OPEN_INVOICE = 'open_invoice',
  GUARDIANS = 'adicionar_guardião',
  BENEFICIARIES = 'adicionar_beneficiarios',
  DOWNLOAD_APP = 'baixe_app_azos',
  READJUSTMENT_POLICY = 'reajuste apólice',
}

/**
 * Important Info Banner
 *
 * @alias ImportantInfoBanner
 */

export type ImportantInfoBanner = {
  /**
   * Action
   */
  type: infoBanner
}
