import { css, styled } from '../../styles'

export const Container = styled('div')(
  () => css`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
  `,
)
