import React from 'react'

import { SpinnerProps } from '.'
import { Root } from './Spinner.styles'

const Spinner: React.VFC<SpinnerProps> = ({
  size = 40,
  backgroundColor,
  borderSize = 4,
  color = 'primary',
  className,
}) => {
  return (
    <Root
      className={className}
      $size={size}
      $borderSize={borderSize}
      $backgroundColor={backgroundColor}
    />
  )
}

export default Spinner
