import {
  ClickContactBrokerHelpService,
  ClickContactHelpService,
  ClickRequestBrokerGuardianService,
  ClickRequestHelpGuardianRoleResponsabilityService,
  GuardianHomepageService,
  SaveEditGuardianService,
  SaveGuardianService,
  ViewAddGuardianService,
  ViewEditGuardianService,
  ViewGuardianHomepageService,
  ViewGuardianInstructionsService,
  ViewGuardianRoleResponsabilityService,
  ViewProtectedListHelpService,
  ViewProtectedService,
  ViewRequestBrokerProtectedHelp,
  ViewRequestProtectedHelp,
} from '../../../../../data/services'
import { makeMixpanelDispatcherEvent } from '../../../infra'

export const makeClickContactBrokerHelpService = () =>
  new ClickContactBrokerHelpService(makeMixpanelDispatcherEvent())

export const makeClickContactHelpService = () =>
  new ClickContactHelpService(makeMixpanelDispatcherEvent())

export const makeClickRequestBrokerGuardianService = () =>
  new ClickRequestBrokerGuardianService(makeMixpanelDispatcherEvent())

export const makeClickRequestHelpGuardianRoleResponsabilityService = () =>
  new ClickRequestHelpGuardianRoleResponsabilityService(
    makeMixpanelDispatcherEvent(),
  )

export const makeGuardianHomepageService = () =>
  new GuardianHomepageService(makeMixpanelDispatcherEvent())

export const makeSaveEditGuardianService = () =>
  new SaveEditGuardianService(makeMixpanelDispatcherEvent())

export const makeSaveGuardianService = () =>
  new SaveGuardianService(makeMixpanelDispatcherEvent())

export const makeViewAddGuardianService = () =>
  new ViewAddGuardianService(makeMixpanelDispatcherEvent())

export const makeViewEditGuardianService = () =>
  new ViewEditGuardianService(makeMixpanelDispatcherEvent())

export const makeViewGuardianHomepageService = () =>
  new ViewGuardianHomepageService(makeMixpanelDispatcherEvent())

export const makeViewGuardianInstructionsService = () =>
  new ViewGuardianInstructionsService(makeMixpanelDispatcherEvent())

export const makeViewGuardianRoleResponsabilityService = () =>
  new ViewGuardianRoleResponsabilityService(makeMixpanelDispatcherEvent())

export const makeViewProtectedListHelpService = () =>
  new ViewProtectedListHelpService(makeMixpanelDispatcherEvent())

export const makeViewProtectedService = () =>
  new ViewProtectedService(makeMixpanelDispatcherEvent())

export const makeViewRequestBrokerProtectedHelp = () =>
  new ViewRequestBrokerProtectedHelp(makeMixpanelDispatcherEvent())

export const makeViewRequestProtectedHelp = () =>
  new ViewRequestProtectedHelp(makeMixpanelDispatcherEvent())
