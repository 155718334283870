export enum ClickPolicyCoverageDetailsType {
  HOME = 'Início',
  POLICY_DETAILS = 'Detalhamento de apólices',
}

/**
 * Click Policies Tabs
 *
 * @alias ClickPolicyCoverageDetails
 */

export type ClickPolicyCoverageDetails = {
  /**
   * Coverage name
   */
  coverage: string
  /**
   * Coverage name
   */
  type: string
}
