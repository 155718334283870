import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled('div')(
  ({ theme }) => css`
    padding-top: ${theme.spacing[4]}px;

    button {
      height: 48px;
      border: none;
    }

    button + button {
      margin-top: ${theme.spacing[2]}px;
    }

    .user-edit-confirm-discard-modal {
      &__title-content {
        display: flex;
        justify-content: space-between;
        align-items: center;

        i {
          font-size: 1.5rem;
          margin-bottom: ${theme.spacing[2]}px;
        }
      }

      &__confirm-button {
        background: ${theme.palette.neutral[500]};

        &:hover {
          background: ${theme.palette.neutral[500]};
        }
      }

      &__description {
        display: block;
        margin: ${theme.spacing[3]}px 0 ${theme.spacing[4]}px;
      }
    }
  `,
)
