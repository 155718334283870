import {
  SimulationAnswerService,
  SimulationEditCoverageService,
  SimulationIncomeService,
  SimulationLeadService,
  SimulationStartService,
  SimulationSubmitService,
  SimulationSummaryService,
  ViewExperimentService,
} from '../../../data/services/simulation'
import { makeDispatcherEvent } from '../infra/dispatcher-event-factory'

export const makeStartSimulationService = () =>
  new SimulationStartService(makeDispatcherEvent())

export const makeSimulationIncomeService = () =>
  new SimulationIncomeService(makeDispatcherEvent())

export const makeSimulationSubmitService = () =>
  new SimulationSubmitService(makeDispatcherEvent())

export const makeSimulationSummaryService = () =>
  new SimulationSummaryService(makeDispatcherEvent())

export const makeSimulationLeadService = () =>
  new SimulationLeadService(makeDispatcherEvent())

export const makeSimulationEditCoverageService = () =>
  new SimulationEditCoverageService(makeDispatcherEvent())

export const makeSimulationAnswerService = () =>
  new SimulationAnswerService(makeDispatcherEvent())

export const makeViewExperimentService = () =>
  new ViewExperimentService(makeDispatcherEvent())
