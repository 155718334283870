import { Mapper, Utils } from '@azos/core'

export type CreditCardRequest = {
  ccname: string
  ccnumber: string
  exp_date: string
  cvv: string
}

export type CreditCardResponse = {
  number: string
  month: string
  year: string
  first_name: string
  last_name: string
  cvv: string
}

export class CreditCardMapper
  implements Mapper<CreditCardRequest, CreditCardResponse>
{
  map(request: CreditCardRequest): CreditCardResponse {
    return {
      number: Utils.sanitizer.onlyNumber(request.ccnumber),
      month: Utils.checkout.getMonth(request.exp_date),
      year: Utils.checkout.getYear(request.exp_date),
      first_name: Utils.checkout.getNames(request.ccname)[0],
      last_name: Utils.checkout.getNames(request.ccname)[1],
      cvv: request.cvv,
    }
  }

  mapCollection(request: CreditCardRequest[]): CreditCardResponse[] {
    return request.map(this.map.bind(this))
  }
}
