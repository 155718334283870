import { GuardianFlags } from '../../../../domain/models'
import { Dispatcher, Service } from '../../../protocols'

export type IViewRequestProtectedHelp = Service<GuardianFlags>

/**
 * View Request Protected Help
 *
 * @category Home
 */
export class ViewRequestProtectedHelp implements IViewRequestProtectedHelp {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   * @param {GuardianFlags} data
   */
  execute(data: GuardianFlags): void {
    this.dispatcher.execute(
      'Area logada web - Visualização de solicitar ajuda',
      data,
    )
  }
}
