import React from 'react'

import { InputMask } from '../InputMask'
import { InputCVVProps } from './InputCVV.props'

const InputCVV = ({ children, ...rest }: InputCVVProps) => {
  return (
    <InputMask
      label="CVV"
      placeholder="Senha de 3 dígitos em seu cartão"
      {...rest}
      mask="9999"
      type="tel"
    />
  )
}

export default InputCVV
