import { ClickHelpReadjustmentDetails } from '../../../../../domain/models'
import { Dispatcher, Service } from '../../../../protocols'

export type IClickHelpReadjustmentDetailsService =
  Service<ClickHelpReadjustmentDetails>

/**
 * Click Help Readjustment Detials
 *
 * @category Policies
 */
export class ClickHelpReadjustmentDetailsService
  implements IClickHelpReadjustmentDetailsService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   * @param {ClickHelpReadjustmentDetails} data
   */
  execute(data: ClickHelpReadjustmentDetails): void {
    this.dispatcher.execute(
      'Area logada web - Clique em "Dúvidas? Confira nossos contatos" em reajuste de apólice',
      data,
    )
  }
}
