import { ProposalAccepted } from '../../../domain/models'
import { Dispatcher, Service } from '../../protocols'

export type IProposalAcceptedService = Service<ProposalAccepted>

/**
 * ProposalAcceptedService
 *
 * @category Proposals
 */
export class ProposalAcceptedService implements IProposalAcceptedService {
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}
  /**
   * Register event
   *
   * @function
   * @param {ProposalAccepted} data
   */
  execute(data: ProposalAccepted): void {
    this.dispatcher.execute('premium_flat', data)
  }
}
