export enum ViewProductCoverageDetailsType {
  LIFE_INSURANCE = 'seguro de vida',
  FUNERAL_ASSISTANCE = 'assistência funeral',
  ACCIDENT_DISABILITY = 'invalidez',
  GENERAL_DISEASES = 'dg',
  GENERAL_DISEASES_10 = 'dg 10',
  DIH = 'dih',
}

/**
 * View Product Coverage Details
 *
 * @alias ViewProductCoverageDetails
 */

export type ViewProductCoverageDetails = {
  /**
   * Check if user is insured
   */
  isInsured: boolean
  /**
   * Product Coverage Details
   */
  tipo: ViewProductCoverageDetailsType
}
