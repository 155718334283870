import {
  CreateBeneficiariesService,
  LoadBeneficiariesV2Service,
  LoadKinshipsService,
} from '../../../data/services'
import { makeLoadBeneficiariesV2Mapper } from '../mappers'
import {
  makeBeneficiariesRepository,
  makeBeneficiariesV2Repository,
} from '../repositories'

export const makeLoadBeneficiariesV2Service = () =>
  new LoadBeneficiariesV2Service(
    makeBeneficiariesV2Repository(),
    makeLoadBeneficiariesV2Mapper(),
  )

export const makeCreateBeneficiariesService = () =>
  new CreateBeneficiariesService(makeBeneficiariesRepository())

export const makeLoadKinshipService = () =>
  new LoadKinshipsService(makeBeneficiariesRepository())
