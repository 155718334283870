import { LoadQuotationByIdUseCase } from '../../../../domain/usecases'
import { SWRServices } from '../../../protocols/service'

export type ILoadQuotationByIdService = SWRServices<
  string,
  LoadQuotationByIdUseCase.Response
>

export class LoadQuotationByIdService implements ILoadQuotationByIdService {
  constructor(
    private readonly quotationsRepository: LoadQuotationByIdUseCase,
  ) {}

  getUrl(quotationId: string): string {
    return `/quotations/${quotationId}`
  }

  async execute(
    quotationId: string,
  ): Promise<LoadQuotationByIdUseCase.Response> {
    return await this.quotationsRepository.loadById(quotationId)
  }
}
