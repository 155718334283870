import { Services } from '@azos/core'
import { IGuardiansRepository } from '@data/repositories'
import { UpsertGuardiansUseCase } from '@domain/usecases'

type Params = UpsertGuardiansUseCase.Params
type Response = UpsertGuardiansUseCase.Response

export type IUpsertGuardiansService = Services<Params, Response>

export class UpsertGuardiansService implements IUpsertGuardiansService {
  constructor(private readonly guardiansRepository: IGuardiansRepository) {}

  async execute(params: Params): Promise<Response> {
    const upsertGuardians = await this.guardiansRepository.upsertGuardians(
      params,
    )

    return upsertGuardians
  }
}
