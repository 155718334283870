import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    height: 100%;

    .tooltip {
      margin-left: 8px;
      font-size: 1.5rem;
      color: #a3aab9;
    }

    .description {
      font-size: 0.75rem;
      line-height: 18px;
      margin: 20px 0;
      display: block;
    }

    .tooltipWrapper {
      margin-top: 14px;
      display: flex;
      align-items: center;
      width: 100%;

      label {
        width: fit-content;
      }
    }

    h2 {
      font-size: 24px;
      font-weight: 600;
      margin: 0 0 32px;

      strong {
        color: ${theme.palette.primary[500]};
      }
    }

    form {
      height: 100%;
      display: flex;
      flex-direction: column;

      > div {
        flex: 1;

        & > div {
          display: block;
          width: 100%;

          & + div {
            margin-top: ${theme.spacing[3]}px;
          }
        }
      }
    }
  `,
)
