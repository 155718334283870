import { Dispatcher, Service } from '../../../../protocols'

export type IInteractBrokerContactCardService = Service<void>

/**
 * Interact Broker Contact Card
 *
 * @category Broker
 */
export class InteractBrokerContactCardService
  implements IInteractBrokerContactCardService
{
  /**
   * @param {Dispatcher} dispatcher
   */
  constructor(private readonly dispatcher: Dispatcher) {}

  execute(): void {
    this.dispatcher.execute(
      'Area logada web - clique em card com contato do corretor',
    )
  }
}
