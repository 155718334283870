import React from 'react'

import { ConfigContext } from './PolicyReadjustmentProvider'

export const usePolicyReadjustmentProvider = () => {
  const context = React.useContext(ConfigContext)

  if (!context) {
    throw new Error(
      'The usePolicyReadjustmentProvider should within PolicyReadjustmentProviderProvider',
    )
  }

  return context
}
