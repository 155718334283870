import { css, styled } from '@azos/shared'

export const Root = styled('div')(
  ({ theme }) => css`
    padding: ${theme.spacing[3]}px;
    background: ${theme.palette.neutral[50]};
    height: 100%;

    @media (min-width: ${theme.breakpoints.md}px) {
      padding: 44px ${theme.spacing[7]}px;
    }

    .policy-list-select-container {
      &__title {
        color: ${theme.palette.neutral[500]};
      }

      &__subtitle {
        margin-top: ${theme.spacing[3]}px;
        display: block;
        color: ${theme.palette.neutral[500]};
      }

      &__policy-list {
        margin-top: ${theme.spacing[4]}px;
        display: flex;
        flex-direction: column;

        div + div {
          margin-top: ${theme.spacing[3]}px;
        }

        &--item {
          background: ${theme.palette.neutral[50]};
          cursor: pointer;
          border: 1px solid ${theme.palette.neutral[200]};
          padding: 12px ${theme.spacing[3]}px;
          flex: 1;
          position: relative;

          display: flex;
          flex-direction: column;

          div {
            display: flex;
          }
        }
      }

      &__text-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        span {
          font-weight: ${theme.typography.fontWeightBold};
        }
      }

      &__label-content {
        display: flex;
        align-items: center;

        margin-right: 40px;

        button {
          border: none;
          margin-top: ${theme.spacing[1]}px;
          background: transparent;
          margin-left: 12px;
          position: absolute;
          top: 10px;
          right: 16px;
        }
      }

      &__coverages-text {
        margin-top: 12px;
        display: block;

        color: ${theme.palette.neutral[400]};
      }

      @media (min-width: ${theme.breakpoints.md}px) {
        &__title {
          font-size: 1.5rem;
        }
        &__subtitle {
          font-size: 1rem;
        }
        &__policy-list {
          margin-top: 26px;
        }
      }
    }
  `,
)
