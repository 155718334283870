import { Services } from '../../../data/protocols'

export class FakeServices<T = any, R = any> implements Services<T, R> {
  params: any
  response: any

  async execute(params?: any): Promise<any> {
    this.params = params
    return this.response
  }
}
