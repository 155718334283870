import { ILoadGuardiansService, IUpsertGuardiansService } from '@data/services'
import { Guardian } from '@domain/models'

export type GuardianModalProps = Omit<ModalGuardianComponentProps, 'title'>

export type ModalGuardianComponentProps = {
  open: boolean
  onClose: () => void
  loadGuardiansService: ILoadGuardiansService
  upsertGuardiansService: IUpsertGuardiansService
  hideShareLink?: boolean
}

export type GuardianFormValue = Guardian &
  Partial<{
    hasAddress: boolean
    guardianIndex: number
  }>

export const loadGuardiansMock = [
  {
    full_name: 'Patrícia Silva Souza',
    kinship: 'Cônjuge',
    city: 'Belo Horizonte',
  },
  {
    full_name: 'Roberto Augusto Pereira',
    kinship: 'Amiga(o)',
    city: 'Rio de Janeiro',
  },
]
