import TagManager from 'react-gtm-module'

import { GrowthBook } from '@growthbook/growthbook-react'

import { GTMConfig } from '../data/protocols'
import { setupGA, setupGrowthbook } from '../infra'
import { env } from './config/env'

export const setupAnalytics = (
  config: GTMConfig,
  growthbook?: GrowthBook<Record<string, any>>,
) => {
  const gtmIdCases: Record<string, string> = {
    production: config.gtmId.production,
    staging: config.gtmId.staging,
    development: config.gtmId.development,
    default: '',
  }

  if (env.environment && gtmIdCases[env.environment]) {
    const tagManagerConfig = {
      ...config,
      gtmId: gtmIdCases[env.environment],
    }

    TagManager.initialize(tagManagerConfig)

    if (growthbook) {
      setupGA(undefined, !env.isProduction)
        .then(({ clientId }) => setupGrowthbook(clientId, growthbook))
        .catch(null)
    }
  } else if (env.isDev) {
    console.log('Analytics configured!')
  }
}
