import { Services } from '@azos/core'
import { IProposalProducer } from '@data/adapters'
import { IQuotationsRepository } from '@data/repositories'
import { CreateQuotationUseCase } from '@domain/usecases'

type Params = CreateQuotationUseCase.Params
type Response = CreateQuotationUseCase.Response

export type ICreateQuotationService = Services<Params, Response>

export class CreateQuotationService implements ICreateQuotationService {
  constructor(
    private readonly quotationsRepository: IQuotationsRepository,
    private readonly proposalProducer: IProposalProducer,
  ) {}

  async execute(params: Params): Promise<Response> {
    const quotation = await this.quotationsRepository.create(params)

    const { proposalId, quotationId } = quotation

    this.proposalProducer.execute({ proposalId, quotationId })

    return quotation
  }
}
