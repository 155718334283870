import React from 'react'

export type OutsideClickProps = {
  ref: React.MutableRefObject<any>
  config?: {
    useEscapeKey: boolean
  }
  //
  handler: () => void
}

const useOutsideClick = ({ ref, config, handler }: OutsideClickProps): void => {
  React.useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      if (ref.current && !ref.current.contains(event.target)) {
        handler()
      }
    }

    const escapeListener = (event: KeyboardEvent) => {
      event.stopPropagation()

      if (event.key === 'Escape') {
        handler()
      }
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    if (config?.useEscapeKey) {
      document.addEventListener('keydown', escapeListener)
    }

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)

      if (config?.useEscapeKey) {
        document.removeEventListener('keydown', escapeListener)
      }
    }
  }, [ref, config, handler])
}

export default useOutsideClick
